export const locale = {
    header_title: 'Sajlha',
    TRANSLATOR: {
        SELECT: "Select your language"
    },
    MENU: {
        mobile_app_settings: 'Mobile App Settings',
        inventory_statistics_tracking: 'Inventory Statistics Traking',
        api_internal_configurations: 'Api Internal Configurations',
        export_import_suppliers: 'Export and Import Suppliers',
        item_configuration: 'Product configuration',
        item_label_management: 'Product label management',
        laundry_subscriptions: 'Laundry Subscriptions',
        NEW: "new",
        ACTIONS: "Actions",
        CREATE_POST: "Create New Post",
        PAGES: "Pages",
        FEATURES: "Features",
        APPS: "Apps",
        DASHBOARD: "Dashboard",
        USERS: "Users",
        Roles_Management: 'Roles Management',
        users_roles_management: 'ROLES & USERS',
        setting_management: 'Settings',
        units: 'Units',
        currencies: 'Currencies',
        taxes: 'Taxes',
        units_management: 'Units Management',
        Constants: 'Constants Management',
        code_setting: 'Code Setting',
        Countries: 'Countries',
        country_management: 'Country Management',
        Cities: 'Cities',
        banks_management: 'Banks Management',
        cities_management: 'City Management',
        company_profile: 'company profile',
        user_Management: 'User Management',
        tax_Management: 'Tax Management',
        payments_management: 'Payment Management',
        payments_details: 'Payment Details',
        Branches: 'Branches Management',
        smtp: 'SMTP Protocol',
        barcode_setting: 'Barcode Setting',
        Inventories: 'Inventories Management',
        Api_configurations: 'Api Configurations',
        Api_endpoint_configurations: 'Api Endpoint Configurations',
        key_generation: 'Key Generation',
        print_settings: 'Print settings',
        template_design: 'Template Design',
        brands_management: 'Brands Management',
        Manufacturers: 'Manufacturers',
        code_setting_management: 'Code Setting Management',
        Categories: 'Categories',
        IncomeCategories: 'Income Categories',
        Finances_management: 'Finances Management',
        customer_management: 'Customer Management',
        customers: 'Customers',
        customers_management: 'Customers Management',
        procedures: 'Procedures',
        procedure_management: 'Procedure Management',
        appointments: 'Appointments',
        appointments_management: 'Appointments Management',
        Items: 'Items',
        Items_management: 'Products Management',
        inventory_statistics_management: 'Inventory Statistics Management',
        inventory_statistics: 'Inventory Statistics',
        Prices_lists: 'Prices Lists',
        Inventory_transactions: 'Inventory Transactions',
        Inventory_monitoring: 'Inventory Monitoring',
        customers_status: 'Customers Status',
        customer_attributes: 'Customer Attributes',
        customers_features: 'Customer Features',
        suppliers_management: 'Suppliers Management',
        purchases: 'Purchases',
        purchases_invoices: 'Purchases Invoices',
        purchases_refunds: 'Purchases Refunds',
        purchase_configuration: "Purchase general configuration",
        estimations_management: 'Estimations Management',
        sales: "Sales",
        sales_invoices_management: "Sales Invoices",
        laundry_sales_invoices_management: "Laundries Invoices",
        sales_credits_management: "Sales Credits Management",
        sales_shipping_management: "Sales Shipping Management",
        sales_refund_management: "Sales Refund Management",
        sales_refund: "Sales Refund",
        purchase_refund: "Purchase Refund",
        sales_configuration_management: "Sales Configuration Management",
        expenses_categories: "Expenses Categories Management",
        expenses_management: "Expenses Management",
        incomes_management: "Incomes Management",
        treasuries_management: "Treasuries Management",
        subscriptions_management: "Subscriptions Management",
        payment_invoices: "Payment Invoices",
        payment_sales_invoices: "Sales Payments",
        payment_purchase_invoices: "Purchase Payments",
        payment_sales_credits: "Payment Sales Credits",
        payment_sales_debits: "Payment Sales Debits",
        payment_sales_refund: "Payment Sales Refund",
        payment_purchase_refund: "Payment Purchase Refund",
        payment_sales_invoices_details: "Sales Payments Details",
        employees_management: 'Employees Management',
        employees: 'Employees',
        purchases_requests: 'Purchases Requests',
        purchases_quotation_requests: 'Purchases Quotation Requests',
        purchase_quotations: 'Purchase Price Offers',
        purchases_orders: 'Purchases Orders',
        payable_cheques: 'Payable Cheques',
        cheques_cycle: 'Cheques Cycle',
        cheque_book: 'Cheque Books',
        receivable_cheques: 'Receivable Cheques',
        activity_log: 'Activity Log',
        logs: 'Logs',
        open_stock_items: 'Open Stock Items',
        open_quantity_history: 'open quantity history',
        POS: 'POS',
        pos_devices: 'POS Devices',
        pos_shifts: 'POS Shifts',
        pos_settings: 'POS Settings',
        accounting: 'Accounting',
        financial_years: 'Financial years',
        closed_periods: 'Closed periods',
        insurance_agents: 'Insurance Agents',
        points_credits: 'Points Credits',
        credit_types: 'Credit Type',
        credit_usages: 'Credit Usage',
        package_info: 'Package Info',
        sales_commissions: 'Sales Commissions',
        sales_target_commission: 'Sales Target Commission',
        sales_periods: 'Sales Periods',
        sales_commission_details: 'Sales Commission Details',
        chart_accounts: 'Chart Of Accounts',
        journal_entries: 'Journal Entries',
        assets_info: 'Assets Info',
        accounts_routing: 'Accounts Routing',
        cost_centers: 'Cost Centers',
        loyalty: 'Loyalty',
        clients_loyalty: 'Customers Loyalty',
        loyalty_rules: 'Loyalty Rules',
        installment: 'Installment',
        installments: 'Installments',
        schedule_appointments: 'Schedule Appointments',
        installment_agreements: 'Installment Agreements',
        installments_payments: 'Installments Payments',
        pay_installment: 'Pay Installment',
        add_to_cost_center: 'Add To Cost Center',
        add_sales_invoice_to_cost_center: 'Add Sales Invoice To Cost Center',
        add_sales_credit: 'Add Sales Credit',
        refund: 'Refund',
        add_sales_debit: 'Add Sales Debit',
        debit: 'Debit',
        add_notes_attachments: 'Add Notes / Attachments',
        packing_slip: 'Packing Slip',
        invoice_printing: 'Simple Invoice',
        invoice_printing_80_mm: 'Invoice 80MM',
        refund_receipt: 'Refund Receipt',
        point_of_sales: 'Point Of Sales',
        pos_sessions: 'POS Sessions',
        refund_invoice: 'Refund Invoice',
        sales_screen: 'Sales Screen',
        cash_movement: 'Cash Movement',
        refund_details: 'Refund Details',
        close_session: 'Close Session',
        invoice_details: 'Invoice Details',
        cost_transactions: 'Cost Transactions',
        accounts_without_cost_center: 'Accounts Without Cost Center',
        accounts_with_cost_center: 'Accounts With Cost Center',
        cost_center_transactions: 'Cost Center Transactions',
        change_email_password: 'Change Email & Password',
        daily_brief_report_invoices: 'Daily Brief Report To Invoices',
        daily_brief_report_payments: 'Daily Brief Report To Payments',
        stocks_report: 'Stocks Report',
        reports: 'Reports',
        print_setting: 'Print Settings',
        inventory_transaction_report: 'Inventory Transaction Report',
        inventory_transaction_details: 'Inventory Transaction Details',
        estimated_inventory: 'Estimated Inventory Report',
        summary_stock_balance: 'Summary Stock Balance',
        product_average_cost: 'Product Average Cost',
        daily_product_sales: 'Daily Product Sales',
        store_reports: 'Store Reports',
        clients_reports: 'Customers Reports',
        customer_appointment_report: 'Customer Appointments Report',
        customer_sales_report: 'Customer Sales Report',
        customer_payments_report: 'Customer Payments Report',
        customer_balance_report: 'Customer Balance Report',
        customer_installments: 'Customer Installments',
        aged_debtors: 'Aged Debtors',
        sales_by_client_report: 'Sales Report By Client',
        sales_reports: 'Sales Reports',
        sales_by_staff_report: 'Sales Report By Staff',
        sales_by_product_report: 'Daily Product Sales Report',
        sales_by_sales_person: 'Sales Report By Sales Person',
        payments_sales_report: 'Payments Report',
        purchases_reports: 'Purchases Reports',
        suppliers_reports: 'Suppliers Reports',
        suppliers_balance_reports: 'Suppliers Balance Reports',
        supplier_purchase_report: 'Suppliers Purchases Reports',
        purchases_by_staff_report: 'Purchases Report By Staff',
        purchases_by_supplier_report: 'Purchases Report By Supplier',
        purchases_invoices_segmented_Reports: 'Purchases Invoices Segmented Reports',
        product_purchase_report: 'Product Purchases Report',
        product_purchases_report_by_product: 'Product Purchases Report By Product',
        product_purchases_report_by_supplier: 'Product Purchases Report By Supplier',
        product_purchases_report_by_employee: 'Product Purchases Report By Stuff',
        purchases_payments_reports: 'Purchases Payments Reports',
        daily_purchases_payments_report: 'Daily Purchases Payments Report',
        weekly_purchases_payments_report: 'Weekly Purchases Payments Report',
        monthly_purchases_payments_report: 'Monthly Purchases Payments Report',
        yearly_purchases_payments_report: 'Yearly Purchases Payments Report',
        create_tree_chart_with_basic_info: 'Create Tree Chart With Basic Info',
        create_simple_tree_chart_with_basic_info: 'Create Simple Tree Chart With Basic Info',
        create_complex_tree_chart_with_basic_info: 'Create Complex Tree Chart With Basic Info',
        clone_tree_chart_with_basic_info: 'Clone Tree Chart With Basic Info',
        chart_account_setting: 'Chart Account Setting',
        document_type_setting: 'Document Type Setting',
        key_generations_management: 'Key Generations Management',
        insuranceagents: 'Insurance Agents',
        journals_setting: 'Journals Setting',
        currency_exchange: 'Currency Exchange Management',
        journal_transactions_reports: 'Journal Transactions Reports',
        accounting_reports: 'Accounting Reports',
        send_message_users: 'Send Message To Users',
        all_notifications: 'All Notifications',
        notifications_config: 'Notifications Config',
        custom_notifications: 'Custom Notification Settings',
        installment_payments: 'Installment Payments',
        treasury_checking: 'Treasuries Checking Balance',
        inventory_checking: 'Inventory Checking',
        treasury_checking_history: 'Treasury Checking History',
        inventory_checking_history: 'Inventory Checking History',
        profit_loss_report: 'Profit & Loss Report',
        income_report: 'Incomes report',
        expenses_report: 'Expenses report',
        balance_sheet: 'Balance sheet',
        chart_accounts_directory: 'Chart of account Directory',
        balance_balances_review: 'Balance Of Balances Review',
        tax_report: 'Taxes Report',
        financial_transactions_report: 'Financial Transactions Report',
        items_report: 'Products Report',
        activity_log_report: 'System Activity Log',
        supplier_checking: 'Supplier Balance Checking',
        supplier_transactions_report: 'Supplier Transactions Report',
        customer_transactions_report: 'Customer Transactions Report',
        items_transactions_report: 'Products Transactions Report',
        export_import_items: 'Export and Import Products',
        available_features: 'Available Features',
        available_sub_features: 'Available Sub Features',
        secondary_accounts_tree: 'Secondary Accounts Tree',
        groups_accounts: 'Accounts Groups',
        chart_account_list: 'Chart Accounts List',
        journals_entries_account: 'Journals Entries Of Account',
        branch_config: 'Branch Settings',
        workstation: 'Workstations',
        manufacturers: 'Manufacturers',
        quality_inspections: 'Quality Inspections',
        operation_management: 'Operations Management',
        routing_management: 'Routing Management',
        bill_of_material: 'Bill Of Material',
        material_list: 'Material List',
        work_order: 'Work Order',
        job_card: 'Job Card',
        general_group: "general group",
        composite_items: "Composite Items",
        aspects: "Aspects",
        collected_items: "Collected Items",
        bundle_and_device: "Bundle and Divide",
        bundle_divide: 'Bundle Divide',
        bundle_divide_list: 'Bundle Divide List',
        departments: 'Departments',
        module_data_access: 'Module Data Access',
        data_access_configuration: 'Data Access Configuration',
        default_data: 'Default Data',
        currencies_management: 'Currencies Management',
        pos_stores: 'Stores Management',
        invoice_printing_without_price: 'Invoice Printing Without Price',
        receipt_document: 'Receipt Document',
        general_sales: 'General Sales Invoices',
        expenses_income_transaction: 'Expenses and Income Transactions',
        bank_checking: 'Bank Balance Checking',
        bank_checking_history: 'Bank Balance Checking History',
        customer_checking: 'Customer Balance Checking',
        customer_checking_history: 'Customer Balance Checking History',
        supplier_checking_history: 'Supplier Balance Checking History',
        general_sales_refund: 'General Sales Refund',
        sales_quotations: 'Sales Price Offers',
        general_sales_quotations: 'General Sales Price Offers',
        sales_quotation_requests: 'Sales Quotation Requests',
        customer_permissions: 'Customer Permissions',
        supplier_permissions: 'Supplier Permissions',
        pos_permissions: 'POS Permissions',
        sales_tax_settings: 'Sales tax settings',
        purchase_tax_settings: 'Purchase tax settings',
        export_import_customers: 'Export and Import Customers',
        receipt_documents: 'Receipt Documents',
        payment_purchase_invoices_details: "Purchase Payments Details",
        taxes_rules: "Taxes Rules",
        delimiters: "Delimiters",
        inventories_management: "Inventories Management",
        orders_and_offers_purchases: "Orders and Offers Purchases",
        quotes_and_estimates: "Quotes and Estimates",
        sales_configuration: "Sales Configuration",
        sales_management: "Sales Management",
        customers_configurations: "Customers Configurations",
        cash_management: "Cash Management",
        financial_settings: "Financial Configurations",
        invoice_status: 'Invoice Status',
        import_quantities: 'Import quantities',
        general_settings: 'General Settings',
        cost_centers_management: 'Cost Centers Management',
        journals_management: 'Journals Management',
        main_settings: 'Main Settings',
        taxes_management: 'Taxes Management',
        countries_and_currencies: 'Countries and Currencies',
        advanced_settings: 'Advanced Settings',
        notifications_and_email: 'Notifications and Email',
        template_and_design: 'Template and Design',
        manufacturers_management: 'Manufacturers Management',
        laundry_services: 'Laundry Services',
        categories_list: 'Categories list',
        laundry_services_pricing: 'Laundry Services Pricing',
        price_lists_laundry_services: 'Price Lists Laundry Services',
        attached_reasons: 'Description of Parts Received',
        point_of_sales_laundry: 'Point Of Sales Laundry',
        sales_debits_management: "Sales Debits Management",
        general_sales_credits_management: "General Sales Credits Management",
        general_sales_debits_management: "General Sales Debits Management",
        laundry_services_colors: "Colors of Pieces Received",
        print_settings_details: "Print Settings Details",
        sales_via_interval_report: 'Sales Via Interval',
        cash_categories: 'Cash Categories',
        invoice_bond_configuration: 'Invoices and Bonds Configuration',
        invoices_bonds_management: 'Invoices and Bonds Management',
        cancel_partial_reservation: 'Cancel Partial Reservation',
        general_rent_invoices: 'General Rent Invoices',
        rent_invoices: 'Rent Invoices',
        basic_status_management: 'Basic Status Management',
        document_status_paths: 'Document Status Paths',
        status_mapping: 'Status Mapping',
        connected_printers: 'Connected Printers',
        cash_closing: 'Cash Closing',
        income_expenses_report: 'Incomes Expenses Report',
        tax_declaration_report: 'Tax Declaration',
        simple_customer_balance_report: 'Simple Customers Balance Report',
        fabric_problems: 'Fabric Problems',
        bonds_and_payments_details_report: 'Bonds and Payments Details Report',
        sales_profit_report: 'Sales Profit Report',
        profit_of_sales_item_report: 'Profit of Sales Item Report',
        supplier_aged_ledger: 'Supplier Aged Ledger Report',
        sales_revenue_report: 'Revenue Report',
        sales_report: 'Sales Report',
        sales_payments_report: 'Sales Payments Report',
        paid_purchases: 'Paid Purchases Report',
        simple_suppliers_balance_report: 'Simple Supplier Balance',
        detailed_stock_transactions_per_product: 'Detailed Stock Transactions Per Product',
        pos_reports: 'POS Reports',
        total_pos_product_sales_details_report: 'Total POS Product Sales Details Report',
        pos_shifts_sales_report: 'POS Shifts Sales Report',
        total_pos_categories_profits_report: 'Total POS Categories Profits Report',
        detailed_pos_shifts_transactions_report: 'Detailed POS Shifts Transactions Report',
        total_pos_products_profits_report: ' Total POS Products Profits Report',
        pos_shifts_profit_report: 'POS Shifts Profit Report',
        default_journalist_setting: 'Default Journal settings',
        sales_man_commission: 'Sales Man Commission',
        financial_list: 'Financial List',
        general_item_configurations: 'General Item Configurations',

    },
    AUTH: {
        GENERAL: {
            OR: "Or",
            SUBMIT_BUTTON: "Submit",
            NO_ACCOUNT: "Don't have an account?",
            SIGNUP_BUTTON: "Sign Up",
            FORGOT_BUTTON: "Forgot Password",
            BACK_BUTTON: "Back",
            PRIVACY: "Privacy",
            LEGAL: "Legal",
            CONTACT: "Contact"
        },
        LOGIN: {
            TITLE: "Login Account",
            BUTTON: "Sign In"
        },
        FORGOT: {
            TITLE: "Forgot Password?",
            DESC: "Enter your email to reset your password",
            SUCCESS: "Your account has been successfully reset."
        },
        REGISTER: {
            TITLE: "Sign Up",
            DESC: "Enter your details to create your account",
            SUCCESS: "Your account has been successfuly registered."
        },
        INPUT: {
            EMAIL: "Email",
            FULLNAME: "Fullname",
            PASSWORD: "Password",
            CONFIRM_PASSWORD: "Confirm Password",
            USERNAME: "Username"
        },
        VALIDATION: {
            INVALID: "{{name}} is not valid",
            REQUIRED: "{{name}} is required",
            MIN_LENGTH: "{{name}} minimum length is {{min}}",
            AGREEMENT_REQUIRED: "Accepting terms & conditions are required",
            NOT_FOUND: "The requested {{name}} is not found",
            INVALID_LOGIN: "The login detail is incorrect",
            REQUIRED_FIELD: "Required field",
            MIN_LENGTH_FIELD: "Minimum field length:",
            MAX_LENGTH_FIELD: "Maximum field length:",
            INVALID_FIELD: "Field is not valid"
        }
    },
    ECOMMERCE: {
        COMMON: {
            SELECTED_RECORDS_COUNT: "Selected records count: ",
            ALL: "All",
            SUSPENDED: "Suspended",
            ACTIVE: "Active",
            FILTER: "Filter",
            BY_STATUS: "by Status",
            BY_TYPE: "by Type",
            BUSINESS: "Business",
            INDIVIDUAL: "Individual",
            SEARCH: "Search",
            IN_ALL_FIELDS: "in all fields"
        },
        ECOMMERCE: "eCommerce",
        CUSTOMERS: {
            CUSTOMERS: "Customers",
            CUSTOMERS_LIST: "Customers list",
            NEW_CUSTOMER: "New Customer",
            DELETE_CUSTOMER_SIMPLE: {
                TITLE: "Customer Delete",
                DESCRIPTION: "Are you sure to permanently delete this customer?",
                WAIT_DESCRIPTION: "Customer is deleting...",
                MESSAGE: "Customer has been deleted"
            },
            DELETE_CUSTOMER_MULTY: {
                TITLE: "Customers Delete",
                DESCRIPTION: "Are you sure to permanently delete selected customers?",
                WAIT_DESCRIPTION: "Customers are deleting...",
                MESSAGE: "Selected customers have been deleted"
            },
            UPDATE_STATUS: {
                TITLE: "Status has been updated for selected customers",
                MESSAGE: "Selected customers status have successfully been updated"
            },
            EDIT: {
                UPDATE_MESSAGE: "Customer has been updated",
                ADD_MESSAGE: "Customer has been created"
            }
        }
    },
    client_id: 'Client ID',
    do_you_want_update_price_and_quantity: 'Do you want to update price and quantity?',
    update_out_taxes: 'Update out taxes',
    update_price_and_quantity: 'Update price and quantity',
    do_you_want_to_update_out_taxes: 'Do you want to update out taxes?',
    update_paid_amount: 'Update paid amount',
    do_you_want_update_paid_amount: 'Do you want to update the paid amount?',
    app_id: 'App ID',
    app_slug: 'App Slug',
    main_module: 'Main Module',
    sub_module: 'Sub Module',
    modules: 'Modules',
    add_mobile_settings: 'Add App settings',
    mobile_settings: 'Mobile App settings',
    expoenses: 'Expenses',
    paymentss: 'Payments',
    is_use_label_shortcut: 'Use custom names for product table columns',
    is_custom_product_size_column: 'Is custom product size column',
    product_size_column_val: 'Product size value',
    print_procedures: 'Printing procedures',
    print_session: 'Print session',
    print_80ml: 'Print 80ml',
    total_invoice_with_tax: 'Total invoice with tax',
    date_time: 'Date & Time',
    session_bills: 'Session bills',
    Basic_pos_session_data: 'Basic POS session data',
    add_discount: 'Add discount',
    discounts_management: 'Discounts Management',
    request_invoice: 'Request invoice',
    national_address: 'National Address',
    separator: 'Separator',
    qr_pattern_configuration: 'QR Pattern Configuration',
    product_serial_no: 'Product serial number',
    invoice_code: 'Invoice code',
    open_stock_quantity_total_before: 'Total quantity before',
    open_stock_quantity_total_after: 'Total quantity after',
    available_stock_qty_before: 'Available stock quantity before',
    available_stock_qty_after: 'Available stock quantity after',
    stock_on_hand_qty_before: 'Stock on hand quantity before',
    stock_on_hand_qty_after: 'Stock on hand quantity after',
    sales_booking_before: 'Sales booking before',
    sales_booking_after: 'Sales booking after',
    purchase_on_way_before: 'Purchase on way before',
    purchase_on_way_after: 'Purchase on way after',
    committed_qty_before: 'Committed quantity before',
    committed_qty_after: 'Committed quantity after',
    booked_before: 'Book a rental before',
    booked_after: 'Book a rental after',
    booking_before: 'Rental stone delivery before',
    booking_after: 'Rental stone delivery after',
    type_type: 'transaction type',
    type_id: 'transaction id',
    type_status_name: 'transaction status',
    more_details: 'More details',
    do_you_want_to_payment_rollback: 'Do you want to payment rollback?',
    item_table_settings: 'Item table settings',
    payment_rollback: 'Payment rollback',
    bonds: 'Bonds',
    the_payments: 'Payments',
    payment_details: 'Payment details',
    back_off_the_opening_balance: 'Retract from the opening balance',
    are_you_sure_that_you_want_to_retract_from_the_opening_balance: 'Are you sure that you want to retract from the opening balance?',
    next_item: 'Next item',
    previous_item: 'Previous item',
    generate_token: 'Generate token',
    security_key: 'Security key',
    method_name: 'Method name',
    method_type: 'Method type',
    main_link: 'Main link',
    add_api_internal_configurations: 'Add API Internal Configurations',
    operation_date: 'Operation date',
    add_quantities: 'Add quantities',
    price_type: 'Price type',
    treasury_or_bank: 'Treasury or Bank',
    subscripers: 'Subscribers',
    subscription_info: 'Subscription information',
    add_subscriber: 'Add subscriber',
    add_subscribers: 'Add subscribers',
    size: 'Predefined sizes',
    no_items_found: 'No items found',
    height: 'Height',
    width: 'Width',
    margin: 'Margin outside frame',
    padding: 'Margin inside frame',
    label_per_row: 'Label per row',
    unit_standard: 'Unit standard',
    min_price: 'Min price',
    undefined: 'Undefined',
    scale_type: 'Scale type',
    no_services: 'No services',
    laundry_subscription_view: 'Laundry subscription details',
    times_no: 'Times number',
    descriptions: 'Descriptions',
    add_laundry_subscription: 'Add laundry subscription',
    edit_laundry_subscription: 'Edit laundry subscription',
    end_date: 'End date',
    start_date: 'Start date',
    max_customers: 'Max customers',
    Do_you_want_delete_it: 'Do you want delete it?',
    save_add_quantity: 'Save and add quantity',
    Confirm: 'Confirm',
    Cancel: 'Cancel',
    ok: 'Ok',
    close: 'Close',
    save: 'Save',
    cancel: 'Cancel',
    actions: 'Actions',
    Something_went_wrong: 'Oops! Something went wrong',
    Filter: 'Filter',
    Search_query: 'Quick search',
    Search: 'Search',
    Loading: 'Loading',
    edit: 'Edit',
    delete: 'Delete',
    back: 'Back',
    records: 'Records',
    yes: 'Yes',
    no: 'No',
    all: 'All',
    advanced_filter: 'Advanced filter',
    reset_search: 'Reset search',
    main: 'Main',
    others: 'Others',
    active: 'Active',
    inactive: 'Inactive',
    client: 'Client',
    insurance_agent: 'Insurance Agent',
    status: 'Status',
    is_default: 'Is default',
    internal: 'Internal',
    external: 'External',
    created_at: 'Created at',
    upload_logo: 'Upload logo',
    add_more: 'Add more',
    add_more_items: 'Add more items',
    bring_all_items: 'Bring all items',
    from_date: 'From date',
    to_date: 'To date',
    export: 'Export',
    excel: 'Excel',
    generate_pdf: 'Generate PDF',
    custom_export: 'Custom export',
    select_all: 'Select all',
    add_new: 'Add New',
    change_status: 'Change status',
    Hi: 'Hi',
    User_Profile: 'User Profile',
    Sign_out: 'Sign out',
    not_selected: 'Not selected',
    more_actions: 'More actions',
    extra_options: 'Extra options',
    all_transactions: 'All Transactions',
    no_data_to_show: 'No data to show',
    clone: 'Clone',
    link: 'Link',
    finished: 'Finished',
    unfinished: 'Unfinished',
    must_last_serial_be_grater_than_first_serial: 'Must the last serial been greater than the first serial, and the difference between them be 150',
    Added_By: 'Added By',
    unpaid: 'Unpaid',
    paid: 'Paid',
    products: 'Products',
    clients: 'Clients',
    invoice: 'Invoice',
    search: 'Search',
    print_last_invoice: 'Print last invoice',
    session_validate: 'Session validate',
    product_information: 'Product information',
    payment: 'Payment',
    add_client: 'Add client',
    payment_method: 'Payment method',
    payment_amount: 'Payment amount',
    payment_list: 'Payment list',
    discount: 'Discount',
    discounts: 'Discounts',
    order_details: 'Order Details',
    cash_payment: 'Cash Payment',
    from: 'From',
    amount: 'Amount',
    paid_by: 'Paid By',
    received_by: 'Received By',
    number_no: 'No.',
    signature: 'Signature',
    do_you_want_make_it_as_draft: 'Do you want make it as draft?',
    do_you_want_to_close_session: 'Do you want to close the session?',
    no_transactions_found: 'No transactions found',
    no_invoices_found: 'No invoices found',
    no_refund_receipts_found: 'No refund receipts found',
    no_payments_found: 'No payments found',
    no_credit_found: 'No credit notes found',
    pos_client: 'POS Client',
    screen: 'Screen',
    print: 'Print',
    pdf: 'PDF',
    setup_installment_agreement: 'Setup installment agreement',
    create_credit_note: 'Credit note',
    assign_costs_centers: 'Assign costs centers',
    qty: 'Qty',
    accounts: 'Accounts',
    paginationLimit: 'Records',
    paginationCount: 'Count',
    paginationFirst: 'First',
    paginationLast: 'Last',
    paginationFilter: 'Filter',
    paginationPage: 'Page',
    paginationNoResult: 'No Result',
    paginationFilterBy: 'Filter By',
    paginationLoading: 'Loading',
    paginationDefaultOption: 'Default Option',
    No_sub_options: 'No sub-options.',
    No_options_available: 'No options available.',
    No_results_found: 'No results found...',
    Select: 'Select...',
    added_by: 'Added By',
    mark_as_draft: 'Mark as draft',
    add_payment: 'Add payment',
    customer_search: 'Customer search',
    customer_name: 'Customer name',
    start_session: 'Start session',
    create_invoice: 'Create invoice',
    pos_session_n: 'POS session',
    invoices: 'Invoices',
    clients_n: 'Clients',
    new_client: 'New client',
    sales: 'Sales',
    payments_summary: 'Payments Summary',
    profit_and_loss: 'Profit & Loss',
    income_and_expenses: 'Income & Expenses',
    recent_invoices: 'Recent Invoices',
    overdue_invoices: 'Overdue Invoices',
    payment_statistics: 'Payment Statistics',
    invoice_search: 'Invoice Search',
    invoice_number: 'Invoice Number',
    recent_payments: 'Recent Payments',
    low_stock_products: 'Low Stock Products',
    last_appointments: 'Last Appointments',
    view_all: 'View All',
    activity_logs: 'Activity Logs',
    num_movements: 'Number of movements',
    available: 'Available',
    out_of_stock: 'Out Of Stock',
    expense: 'Expenses',
    income: 'Income',
    refund: 'Refund',
    low_of_stock: 'Low Of Stock',
    to: 'To',
    details: 'Details',
    daily: 'Daily',
    weekly: 'Weekly',
    monthly: 'Monthly',
    yearly: 'Yearly',
    customer: 'Customer',
    employee: 'Employee',
    select_filter: 'Select filter',
    revenue_invoice: 'Revenue invoice',
    collected_by: 'Collected by',
    supplier: 'Supplier',
    brand: 'Brand',
    product: 'Product',
    show_draft: 'Show draft',
    staff: 'Staff',
    daily_products: 'Daily Products',
    summery: 'Summery',
    time: 'Time',
    group_by: 'Group by',
    due_date: 'Due date',
    order_by: 'Order by',
    asc: 'Ascending',
    desc: 'Descending',
    partial_paid: 'Partial paid',
    overdue: 'Overdue',
    summary: 'Summary',
    sales_summary: 'Sales Summary',
    sales_man: 'Sales man',
    daily_sales: 'Daily Sales',
    weekly_sales: 'Weekly Sales',
    monthly_sales: 'Monthly Sales',
    yearly_sales: 'Yearly Sales',
    payments_sales_by_client_reports: 'Payments Reports by Client',
    payments_sales_by_staff_reports: 'Payments Reports by Staff',
    payments_sales_by_payment_method_reports: 'Payments Reports by Payment Method',
    payments_sales_by_daily_reports: 'Payments Reports by Daily',
    payments_sales_by_weekly_reports: 'Payments Reports by Weekly',
    payments_sales_by_monthly_reports: 'Payments Reports by Monthly',
    payments_sales_by_yearly_reports: 'Payments Reports by Yearly',
    payments_periodic_reports: 'Payments Periodic Reports',
    payments_segmented_reports: 'Payments Segmented Reports',
    sales_periodic_reports: 'Sales Invoices Periodic Reports',
    sales_segmented_reports: 'Sales Invoices Segmented Reports',
    display_report: 'Display report',
    purchases_summary: 'Purchases summary',
    products_purchases_summary: 'Products purchases summary',
    prefix: 'Prefix',
    digits: 'Digits',
    enter: 'Enter',
    please_upload_valid_file: 'Please upload valid file',
    load_more: 'Load more',
    journal_details: 'Journal details',
    the_entries_should_contain_at_least_one_credit_and_one_debit: 'The entries should contain at least one credit and one debit',
    branch: 'Branch',
    inventory: 'Inventory',
    revert: 'Revert',
    exchange_rate: 'Exchange rate',
    confirm_revert: 'Are you sure you want to revert this transaction?',
    nothing: 'Nothing',
    user_send_message: 'User send message',
    all_users: 'Users',
    send_mail_to_customer: 'Send mail to customer',
    customer_mail: 'Customer mail',
    title: 'Title',
    body: 'Content',
    attachments: 'Attachments',
    send: 'Send',
    send_mail: 'Send mail',
    bcc: 'Bcc',
    cc: 'Cc',
    content: 'Content',
    additional_info: 'Additional info',
    customer_info: 'Customer info',
    company_info: 'Company info',
    make_all_read: 'Make all read',
    unread_notifications: 'Unread notification',
    create: 'Create',
    update: 'Update',
    do_you_want_make_it_approved: 'Do you want to approve the value?',
    approve: 'Approve',
    increase: 'Increase',
    decrease: 'Decrease',
    use_multi_currency: 'Use to create multiple currencies',
    import_status: 'Import details',
    no_errors: 'No errors',
    type_to_search: 'Type to search',
    download: 'Download',
    save_as_draft: 'Save as draft',
    save_as_confirm: 'Save as confirm',
    material_validation: 'Material validation',
    Do_you_want_start_this_operation: 'Do you want to start this operation?',
    Do_you_want_stop_this_operation: 'Do you want to stop this operation?',
    Do_you_want_start_work_order: 'Do you want to start this work order?',
    Do_you_want_stop_work_order: 'Do you want to stop this work order?',
    start: 'Start',
    stop: 'Stop',
    restart: 'Re-start',
    complete: 'Complete',
    import_data: 'Import data',
    composite_items: "Composite Items",
    generate_items: "Generate items",
    total_items: "Total items",
    delete_selected: "Delete selected",
    skip: 'Skip',
    copied: 'Copied',
    copy: 'Copy',
    save_without_permission: 'Save and add permissions later',
    save_with_permission: 'Save and add permissions',
    account_information: 'Account information',
    quick_actions: 'Quick actions',
    you_need_to_create_account_tree_to_use_this_module: 'You must create an active financial year, then create a chart of accounts, either a custom tree or a general tree created by the system.',
    accounting_tree: 'General accounts tree',
    select_time_after: 'Select time after',
    you_must_adjust_the_system_settings_before_starting_to_use_it: 'You must adjust the system settings before starting to use it, through the following:',
    type_at_least_three_letters: 'Please type at least three letters to begin your search',
    select_doc_type: 'Select document type',
    day: 'Day',
    month: 'Month',
    year: 'Year',
    browse: 'Browse',
    services_products: 'Services & Products',
    products_only: 'Products Only',
    services_only: 'Services Only',
    select_currencies_allowed_outer: 'Please select from the list of allowed currencies to display them.',
    you_must_choose_a_inventory_before_searching_for_products: 'You must choose a store before searching for items',
    settlement_quantities: 'Settlement of quantities',
    do_you_agree_to_the_settlement_process: 'Do you agree to the settlement process?',
    do_you_want_the_store_to_be_affected_by_new_products_and_quantities: 'Do you want the store to be affected by new products and quantities ?',
    no_balances: 'There are no balances',
    no_items: 'There are no items',
    mobile: 'Mobile',
    email: 'Email',
    currency: 'Currency',
    address: 'Address',
    country: 'Country',
    city: 'City',
    transfer_to_purchase_invoice: 'Transfer to a purchase invoice',
    type: 'Type',
    balance: 'Balance',
    amount_due: 'Amount due',
    select_inventory_to_get_qty: 'Choose a inventory to show the quantities based on the selected inventory',

    item: 'Item',
    qty_before: 'Quantity before',
    qty_after: 'Quantity after',
    stock_on_hand_qty: 'Stock on hand quantity',
    available_stock_qty: 'Available stock quantity',
    stock_change_control_log: 'Stock change control log',
    basic_information: 'Basic information',
    account_statement: 'Account statement',
    treasury: 'Treasury',
    previous: 'Previous',
    next: 'Next',
    please_choose_any_file: 'Please choose any file',
    please_select_a_valid_csv_file: 'Please select a valid csv file',
    the_data_import_process_is_in_progress: 'The data import process is in progress',
    the_data_import_process_is_complete: 'The data import process is complete',
    import_options: 'Import options',
    define_fields: 'Define fields',
    results: 'Results',
    import_again: 'Import again',
    export_template: 'Export template',
    do_you_want_to_delete_all_system_products: 'Do you want to delete all system products? Thus, you will not be able to restore it again.',
    delete_all_items_system: 'Delete all system products',
    business_record_attachment: 'Business record attachment',
    tax_no: 'Tax No.',
    business_record: 'Business record',
    invoice_type: 'Invoice type',
    sale_invoice: 'Sale invoice',
    sale_invoice_pos: 'Sale invoice POS',
    discount_and_settlement: 'Discount and settlement',
    delivery_information: 'Delivery information',
    deposit: 'Deposit',
    notes: 'Notes',
    price_list_and_accounts: 'Price list and accounts',
    credit: 'Credit',
    debit: 'Debit',
    export_invoice_pdf: 'Export invoice PDF',
    export_pdf: 'Export PDF',
    export_pdf_without_price: 'Export PDF without price',
    search_and_print: 'Search and print',
    search_and_export_pdf: 'Search and export PDF',
    customer_data_details: 'Customer data details',
    supplier_data_details: 'Supplier data details',
    employee_data_details: 'Employee data details',
    is_show_main_name_company_in_print: 'Add Sajlha identity to the invoices',
    is_show_setting_message: 'Show setting message',
    identity_name_for_invoice: 'Text Sajlha identity to the invoices',
    category_laundry: 'Laundry classifications',
    balances: 'Balances',
    settlement_treasury: 'Settlement treasury',
    not_specified: 'Not specified',
    shipping_address: 'Shipping address',
    customer_address: 'Customer address',
    add_new_customer_address: 'Add new customer address',
    are_you_sure_to_termination_of_receipt: 'Are you sure to termination of receipt this invoice?',
    tax_invoice_printing: 'Tax invoice',
    export_tax_invoice_pdf: 'Export tax invoice pdf',
    applicable_taxes: 'Applicable taxes',
    paid_amount: 'Paid amount',
    paid_amount_label: 'Paid',
    current_payment: 'Current payment',
    already_paid: 'Already paid',
    tax_invoice_printing_simplified: 'Simplified tax invoice',
    export_tax_invoice_pdf_simplified: 'Export simplified tax invoice pdf',
    invoice_and_procedures: 'Invoice and procedures',
    shipping_and_delivery: 'Shipping and delivery',
    return_and_payment: 'Return and payment',
    refund_fully_invoice: 'Full refund',
    this_invoice_cant_be_editing: 'this invoice can\'t be editing',
    add_payment_type: 'Add a payment type',
    payment_type: 'Payment type',
    payment_type_multi: 'Multi',
    unified: 'One',
    you_cannot_change_the_status_Please_check_with_your_system_administrator: 'You cannot change the status. Please check with your system administrator',
    confirm_invoice: 'Confirm invoice',
    name_holder_of_check: 'Name of the holder of the check',
    check_number: 'Check number',
    bank: 'Bank',
    orders: 'Orders',
    pieces: 'Pieces',
    refresh_orders: 'Refresh orders',
    change_the_delivery_date: 'Change the delivery date',
    delivery_date: 'Delivery date',
    received_and_delivery_dates: 'Received and delivery dates',
    received: 'Received',
    delivery: 'Delivery',
    arrange_orders: 'Arrange orders',
    add_urgent_order_time: 'Determine the urgent time',
    the_value_of_the_entered_amount_is_higher_than_the_value_of_the_requested_amount: 'The value of the entered amount is higher than the value of the requested amount',
    discount_must_be_less_than_or_equal_total_invoice: 'The discount must be less than or equal to the total of the invoice',
    fetch_all_items: 'Fetch all items',
    is_apply_taxes_invoice: 'Apply tax to the invoice',
    minimum: 'Minimum',
    maximum: 'Maximum',
    shipping: 'Shipping',
    delivery_and_received: 'Delivery and received',
    pricing_information: 'Pricing information',
    remove_all: 'Remove all',
    reset_default_values: 'Reset default values',
    reset_default_values_alert_message: 'Do you want to reset default values?',
    you_should_select_supplier_to_display_the_report: 'You should select supplier to display the report',
    subtotal_before_tax: 'Total before tax',
    please_select_customer: 'Please select customer',
    edit_qty: 'Edit quantity',
    quantity_details: 'Quantity details',
    deleted_invoices: 'Cancelled invoices',
    deferred: 'Deferred',
    cash: 'Cash',
    invoice_is_tax: 'Invoice is tax',
    invoice_is_non_tax: 'Invoice is non-tax',
    show: 'Show',
    this_is_item_selected_before: 'This item has already been selected',
    row_numbers: 'Number of lines',
    adjustment_customer_statement: 'Adjustment customer statement',
    adjust: 'Adjust',
    adjustment: 'Adjustment',
    barcode: 'Barcode',
    rollback_the_customer_receipt: 'Rollback of the customer\'s receipt',
    rollback_the_supplier_receipt: 'Rollback of the supplier\'s receipt',
    rollback_the_customer_expense: 'Rollback of customer\'s exchange bond',
    rollback_the_supplier_expense: 'Rollback of supplier\'s exchange bond',
    do_you_want_rollback: 'Do you want rollback?',
    adjust_payment: 'Adjust payment',
    are_you_sure_that_you_want_to_adjust_customer_balance: 'Are you sure that you want to adjust customer balance?',
    customer_balance_adjustment: 'Customer balance adjustment',
    zero_quantities_cannot_be_accepted: 'Zero quantities cannot be accepted',
    quantity_must_be_greater_than_0: 'The quantity must be greater than 0',
    unit_price_must_be_greater_than_0: 'The unit price must be greater than 0',
    treatment: 'Treatment',
    rollback_after_confirm: 'Rollback after confirm',
    id: 'ID',
    save_and_create_new: 'Save & Create new',
    save_and_redirect_to_main_page: 'Save & Redirect to the main page',
    taxes: 'Taxes',
    general_tax: 'General tax',
    send_mail_share: 'Send Email | Share',
    minimum_unit: 'Minimum unit',
    filter_status: 'Filter status',
    invoice_date: 'Invoice date',
    status_to_be_converted_to: 'Status to be converted to',
    unable_to_send_due_to_data_entry_error: 'Unable to send due to data entry error',
    the_item_field_must_be_required: 'Item field is required',
    the_unit_price_field_must_be_required: 'Unit price field is required',
    the_unit_field_must_be_required: 'Unit field is required',
    the_description_field_must_be_required: 'Description field is required',
    the_value_must_not_be_less_than_0: 'The value must be greater than 0',
    the_number_field_value_of_unit_with_is_min: 'The value must be between 1.001 and 999999.999',
    the_number_field_value_of_unit: 'The value must be between 0.001 and 999999.999',
    the_number_field_value_of_unit_should_not_be_1: 'The value must not be 1',
    the_quantity_field_must_be_less_or_equal_remaining_quantity: 'Quantity must be less than or equal to remaining quantity',
    export_all: 'Export all',
    the_inventory_field_must_be_required: 'Inventory field is required',
    the_date_field_must_be_required: 'Date field is required',
    the_field_must_be_required: '{field_name} field is required',
    units_number: 'Units number',
    confirm_request_invoice: 'Confirm request invoice',
    confirm_with_update: 'Confirm with update',
    number: 'Number',
    type_name: 'Type name',
    cash_movements_in: 'Cash movements in',
    cash_movements_out: 'Cash movements out',
    cash_category_name: 'Cash category name',
    total_before_taxes: 'Total invoice before tax',
    report: 'Report',
    from_documentation: 'From documentation',
    to_documentation: 'To documentation',
    user_sales: 'User sales',
    credit_card: 'Credit card',
    type_of_payment_methods: 'Type of payment methods',
    VAT: 'VAT',
    total_paid: 'Total paid',
    total_refund_amount: 'Total refund amount',
    discount_granted: 'Discount granted',
    tax_collected: 'Tax collected',
    total_taxable:'Total taxable',
    tax_label:'Tax',
    total_total:'Total',
    week:'Week',
    all_data: 'All data',
    only_invoices: 'Only invoices',
    action_label_payment: 'Payment',
    filter_by: 'Filter by',
    invoice_details: 'Invoice details',
    partial_refund: 'Partial refund',
    font_size: 'Font size',
    main_invoice_code: 'Basic invoice code',
    main_invoice_date: 'Basic invoice date',
    updated_at: 'Updated at',
    full_paid: 'Fully paid',
    branch_label: 'Branch',
    preview: 'Preview',
    barcode_settings: 'Barcode settings',
    pixel: 'Pixel',
    please_select_supplier: 'Please select supplier',
    predefined: 'Predefined size',
    custom: 'Custom',
    type_size: 'Type size',
    height_label: 'Height',
    barcode_font_size: 'Barcode number size',
    margin_top: 'Upper outer margin',
    roll_back_received_document: 'Roll back of receipt document',
    supported_stores: 'Supported stores',
    roll_back: 'Roll back',
    do_you_want_to_roll_back_this_operation: 'Do you want to roll back this operation?',
    is_reference_date: 'Filter by invoice date',
    do_you_want_to_make_adjust_supplier_statement: 'Do you want adjust supplier statement?',
    adjust_supplier_statement: 'Adjust supplier statement',
    do_you_want_to_make_adjust_supplier_balance: 'Do you want adjust supplier balance?',
    adjust_supplier_balance: 'Adjust supplier balance',
    is_show_image_in_repeater: 'Show product image in product table for documents',
    allowed_file_types: 'Allowed file types',
    columns: 'Columns',
    are_you_sure_that_you_want_to_rollback_payment_for_this_document: 'Are you sure that you want to rollback payment for this document?',
    roll_back_of_payment: 'Roll back of payment',
    invoice_info: 'Invoice info',
    sales_booking: 'Sales booking',
    item_code: 'Item code',
    do_you_want_adjust_available_and_stock_item_quantity: 'Do you want adjust available and stock item quantity?',
    adjust_available_and_stock_item_quantity: 'adjust available and stock item quantity',
    normal_size: 'Normal size',
    full_size: 'Screen size',
    terminated: 'Terminated',
    sale_price_field_is_require: 'The sale price field is required',
    full_screen: 'Full screen',
    recalculation_of_inventories_quantities: 'Recalculation of inventories quantities',
    customers_label: 'Customers',

    global: {
        actions: 'Actions',
        yes: 'Yes',
        no: 'No',
        all: 'All',
    },
    roles: {
        roles_management_section: 'Roles management section',
        role_name: 'Role name',
        description: 'Description',
        status: 'Status',
        created_at: 'Created at',
        new_role: 'New role',
        role: 'Role',
        edit_role: 'Edit role',
    },
    users: {
        users_management: 'Users management',
        users: 'Users',
        user_name: 'User name',
        name: 'Name',
        phone: 'Phone',
        email: 'Email',
        password: 'Password',
        status: 'Status',
        created_at: 'Created at',
        new_user: 'New user',
        user: 'User',
        edit_user: 'Edit user',
        roles: 'Roles',
        description: 'Description',
        first_name: 'First name',
        last_name: 'Last name',
        countries: 'Countries',
        cities: 'Cities',
        confirm_password: 'Confirm password',
        active: 'Active',
        inactive: 'Inactive',
        add_user: 'Add user',
        pl_first_name: 'Enter first name',
        pl_last_name: 'Enter last name',
        pl_email: 'Enter email',
        pl_password: 'Enter password',
        pl_confirm_password: 'Enter confirm password',
        pl_phone: 'Enter phone',
        pl_role: 'Enter roles',
        pl_username: 'Enter username',
        inventory: 'Inventory',
        branch: 'Branch',
    },
    units: {
        units_management: 'Units management section',
        units: 'Units',
        name: 'Unit name',
        notes: 'Notes',
        status: 'Status',
        created_at: 'Created at',
        new_unit: 'New unit',
        unit: 'Unit',
        is_active: 'status',
        edit_unit: 'Edit unit',
        abbreviation: 'Abbreviation',
        sub_value_total: 'Sub value total',
        active: 'Active',
        inactive: 'Inactive',
        default: 'Default',
        the_main: 'The main',

    },
    currencies: {
        currencies_management: 'Currencies management',
        name: 'Name',
        code: 'Currency code',
        currency: 'Currency',
        default: 'Default',
        active: 'Active',
        inactive: 'Inactive',
        is_active: 'status',
        edit_currency: 'Edit currency',
        new_currency: 'New currency',
        status: 'Status',
        created_at: 'Created at',
    },
    constants: {
        constants_section: 'Constants section',
        constants: 'Constants',
        new_constant: 'New constant',
        name: 'Name',
        key: 'Key',
        the_main: 'The main',
        constant: 'Constant',
        value: 'Value',
    },
    code_setting: {
        code_setting: 'Code setting',
        type: 'Type',
        code_prefix: 'Code prefix',
        code_method: 'Code method',
        automatic: 'Automatic',
        manual: 'Manual',
        code_start: 'Start from',
        code_type: 'Code type',
        digit_no: 'Number of digit',
        char_no: 'Number of letter',
        after_update_prefix: 'When change prefix',
        new_code_setting: 'New code setting',
    },
    procedure: {
        procedure: 'Procedure',
        new_procedure: 'New procedure',
        document: 'Document',
    },
    countries: {
        country_management: 'Country Management',
        countries: 'Countries',
        country: 'Country',
        phone_code: 'Phone code',
        en_name: 'English name',
        ar_name: 'Arabic name',
        code2: 'Code 2',
        code3: 'Code 3',
    },
    cities: {
        new_city: 'New city',
        cities: 'Cities',
        city_management: 'City Management',
        city: 'City',
        en_name: 'English name',
        ar_name: 'Arabic name',
        code2: 'Code 2',
        code3: 'Code 3',
        status: 'Status',
    },
    header: {
        my_profile: 'My profile',
        account_settings_and_more: 'Account settings and more',
        change_email_password: 'Change Email & Password',
        renewal_of_plan_subscription: 'Renewal of plan subscription',
        plan_upgrade: 'Plan upgrade',
    },
    company: {
        debit_note: 'Debit Note',
        company_profile: 'Company profile',
        basic_information: 'Basic information',
        business_name: 'Business name',
        business_name_en: 'Business name (english)',
        business_type: 'Business type',
        currency: 'Currency',
        language: 'Language',
        time_zone: 'Time zone',
        date_format: 'Date format',
        printing_method: 'Printing method',
        upload_logo: 'Upload logo',
        address_information: 'Address information',
        country: 'Country',
        city: 'City',
        postal_code: 'Postal code',
        district: 'District',
        address_1: 'Address 1',
        address_2: 'Address 2',
        mobile: 'Mobile',
        phone: 'Phone',
        email: 'Email',
        first_name: 'First name',
        last_name: 'Last name',
        contact_information: 'Contact information',
        account_holder_name: 'Account holder name',
        account_holder_number: 'Account holder number',
        iban: 'IBAN',
        bank_name: 'Bank name',
        bank_branch: 'Bank branch',
        banking_data: 'Banking data',
        company_category: 'Company category',
        company_settings: 'Company settings',
        account_settings: 'Account settings',
    },
    branches: {
        branches: 'Branches',
        basic_information: 'Basic information',
        branch_name: 'Branch name',
        address_information: 'Address information',
        country: 'Country',
        city: 'City',
        postal_code: 'Postal code',
        district: 'District',
        address_1: 'Address 1',
        address_2: 'Address 2',
        mobile: 'Mobile',
        phone: 'Phone',
        email: 'Email',
        fax: 'Fax',
        contact_information: 'Contact information',
        manager_name: 'Manager name',
        branches_section: 'Branches section',
        new_branch: 'New branch',
        branch: 'Branch',
        no_employee: 'No of employee',
        no_inventories: 'No of inventories',
        state: 'State',
    },
    smtp: {
        sender_name: 'Sender name',
        email: 'Email',
        main_password: 'Main password',
        smtp_user_name: 'SMTP User name',
        smtp_service_provider: 'SMTP Service provider',
        smtp_port: 'SMTP Port',
        ssl_required: 'SSL Required',
        use_smtp: 'Use SMTP',
        SMTP_Setting: 'SMTP Protocol',
    },

    barcode_setting: {
        type: 'Type',
        number_parts: 'Barcode fields',
        number_symbols: 'Number of symbols',
        remove_zero_item_code: 'Remove zero item code',

        number_part: 'Part number',
        symbols_type: 'Part type',
        symbols_number: 'Code digits',
        another_value_symbols: 'Other part value',
        number_symbols_boxes: 'Number of fractional digits',
        details: 'Details',
    },

    template_design: {
        template_design: 'Template design',
        upload_logo: 'Upload logo',
        color: 'Color',
        dark_mode: 'Dark mode',
    },
    tax: {
        taxes: 'Taxes',
        tax_management: 'Tax Management',
        tax_name: 'Tax name',
        value_rate: 'Rate value',
        include_tax: 'Include unit price',
        add_tax: 'Add tax',
        status: 'Status',
        tax: 'Tax',
        edit_tax: 'Edit tax',
        created_at: 'Created',
        type: 'Type',
        description: 'Description',
        tax_rule: 'Tax rule',
        rule_option_value: 'Delimiter',
    },
    key_generation: {
        key_generation: 'Key generation',
        add_key_generation: 'Add key generation',
        name: 'Name',
        identity_no: 'Identity no',
        key: 'Key',
    },

    print_setting: {
        print_setting: 'Print setting',
        add_print_setting: 'Add print setting',
        serial_number: 'Serial number',
        name: 'Name',
        type: 'Type',
        upload_attachment: 'Upload attachment',
        print_settings_details: 'Print settings details',
        template: 'Template',
        select_fields_showing: 'Choose the fields you want to show',
        header_height: 'Header height',
        is_multi_language_label: 'Use multi language for label',
        is_use_watermark: 'Use watermark',
        is_banking_details: 'Show banking details',
        is_payment_details: 'Show payments details',
        is_break_before_footer: 'Show break before footer',
        footer_font_color: 'Color of break before footer',
        usage_policy: 'Usage policy',
        is_repeated_header: 'Repeat header in every page',
        is_repeated_footer: 'Repeat footer in every page',
        is_empty_row: 'Add empty rows for items table',
        is_signature: 'Show signatures',
        is_show_contact: 'Show contact',
        is_show_customer_details: 'Show customer details',
        is_show_terms_and_conditions: 'Show terms and conditions',
        print_size: 'Print size',
        mm: 'mm',
        description: 'Description arabic',
        description_en: 'Description english',
        products_template_design: 'Products template design',
        card_design: 'Card design',
        table_design: 'Table design',
        subtotal_before_tax: 'Subtotal before tax',
        subtotal_after_tax: 'Subtotal inclusive of tax',
        subtotal_before_discount: 'Subtotal before discount',
        subtotal_after_discount: 'Subtotal after discount',
        amount_tax: 'Tax value',
        tax_applied: 'Tax rate',
        discount_calc: 'Discount',
        unit: 'Unit',
        product_table_details: 'Products table details',
        item: 'Item',
        qty: 'quantity',
        unit_price: 'Unit price',
        customer_branch: 'Customer branch',
        customize_the_product_table_column_names: 'Customize the product table column names',
        reset_all_fields: 'Reset all fields',
        reset: 'Reset',
        column_name_in_arabic: 'Column name in Arabic',
        column_name_in_english: 'Column name in English',
        sku_code: 'SKU code',
    },

    payments: {
        payment_method_management: 'Payment method management',
        new_payment: 'New payment',
        payment_methods: 'Payment methods',
        cash: 'Cash',
        minimum_amount: 'Minimum amount',
        active_for_online_clients: 'Is active online',
        fees_rate: 'Fees rate',
        fees_fixed: 'Fees fixed',
        status: 'Status',
        main: 'Main',
        others: 'Others',
        payment: 'Payment',
        payment_type: 'Payment type',
        verified: 'Verified',
        api_login_id: 'API Login ID',
        transaction_key: 'Transaction Key',
        account_number: 'Account Number',
        approved_url: 'Approved Url',
        paytabs: 'Paytabs',
        authorize_net: 'Authorize.net',
        checkout: 'Checkout',
        details: 'Details',
        effect_type: 'The effect is automatic',
    },
    inventories: {
        inventory: 'Inventory',
        basic_information: 'Basic information',
        name: 'Name',
        branch: 'Branch',
        type: 'Type',
        inventory_class: 'Inventory class',
        content_type: 'Content type',
        num_employee: 'Number of employees',
        num_work_hours: 'Number of work hours',
        start_work_time: 'Start work time',
        end_work_time: 'End work time',
        address_information: 'Address information',
        country: 'Country',
        city: 'City',
        postal_code: 'Postal code',
        district: 'District',
        address_1: 'Address',
        address_2: 'Alternative address',
        mobile: 'Mobile',
        phone: 'Phone',
        email: 'Email',
        fax: 'Fax',
        contact_information: 'Contact information',
        inventories_section: 'Inventories section',
        new_inventory: 'New inventory',
        products: 'Products',
        inventories: 'Inventories',
        manager: 'Inventory manager',
    },
    brands: {
        brands: 'Brands',
        new_brand: 'New brand',
        brand: 'Brand',
        name: 'Name',
        notes: 'Notes',
    },
    inventory_statistics: {
        type_status: 'Type status',
        inventory_statistics: 'Inventory statistics',
        inventory: 'Inventory',
        product: 'Product',
        financial_year: 'Financial year',
        sales_booking: 'Sales booking',
        available_stock_qty: 'Available stock qty',
        open_stock_quantity_total: 'Open stock quantity',
        stock_on_hand_qty: 'Total actual quantities',
        purchase_on_way: 'Purchases on way',
        max_quantity: 'Maximum quantity',
        min_quantity: 'Minimum quantity',
        committed_qty: 'Committed quantities',
        booked: 'Booked',
        booking: 'Booking',
        invoice_code: 'Invoice code',
        doc_type: 'Document type',
        type_code: 'Document code',
        order_by: 'Order by',
        created_at: 'Created at',
        transaction_type: 'Transaction type',
    },
    inventory_class_list: {
        main_inventory: 'Main inventory',
        factory_inventory: 'Factory inventory',
        custom_inventory: 'Custom inventory',
        shipping_inventory: 'Shipping inventory',
        maintenance_inventory: 'Maintenance inventory',
        temp_inventory: 'Temp inventory',
    },
    content_type_list: {
        raw_materials: 'Raw materials',
        salable_items: 'Salable items',
        mixed: 'Mixed',
        samples: 'Samples',
        maintenance_materials: 'Maintenance materials',
    },
    revenue_invoices: {
        id: 'number',
        date: 'Date',
        customer: 'Customer',
        employee: 'Employee',
        paid: 'Paid',
        unpaid: 'Unpaid',
        refund: 'Refund',
        total: 'Total',
        payment_method: 'Payment method',
        payment_id: 'Payment ID',
        invoice_code: 'Invoice code',
        ref_no: 'Ref No.',
        invoice_number: 'Invoice no.',
    },
    api_configurations: {
        base_url: 'Base URL',
        service_provider: 'Service provider',
        service_type: 'Service type',
        slug: 'Slug',
        credentials: 'Credentials',
        api_configurations_section: 'Api configurations section',
        user: 'User',
        new_api_configuration: 'New api configuration',
        attribute: 'Attribute',
        value: 'Value',
        api_configurations: 'Api configuration',
        api_endpoint_configurations_section: 'Api endpoint configurations section',
        protocol: 'Protocol',
        new_api_endpoint_configurations: 'New api endpoint configuration',
        api_endpoint_configurations: 'Api endpoint configuration',
        endpoint_url: 'Endpoint url',
        param: 'Param',
        endpoint: 'Endpoint',
    },
    service_types_list: {
        payment: 'Payment',
        shipment: 'Shipment',
        call: 'Call',
        sms: 'Sms',
        email: 'Email',
        advertisement: 'Advertisement',
    },
    manufacturers: {
        manufacturers: 'Manufacturers',
        manufacturers_section: 'Manufacturers section',
        new_manufacturer: 'New manufacturer',
        name: 'Name',
        manufacturer: 'Manufacturer',
        upload_image: 'Upload image',
        notes: 'Notes',
        user: 'User',
        category: 'Category',
    },
    categories: {
        categories: 'Categories',
        add_new_category: 'Add new category',
        add_new_sub_category: 'Add new sub category',
        name: 'Name',
        description: 'Description',
        upload_image: 'Upload image',
        printers: 'Printers',
        parent_category: 'Main category',
        unit: 'Unit',
        return_days: 'Number of days for return',

        services: 'Services',
        tax: 'Tax',
        service: 'Service',
        quantity: 'Quantity',
        price: 'Price',
        is_laundries_services: 'Category for laundries services',
    },

    banks: {
        add_bank: 'Add bank',
        balance: 'Balance',
        name: 'Name',
        bank_no: 'Swift code',
        notes: 'Notes',
        is_active: 'Is active',
        banks: 'Banks',
        permission: 'Permission',
        withdraw: 'Withdraw',
        deposit: 'Deposit',
        created_at: 'Created date',
        code: 'Code',
        from_date: 'From date',
        to_date: 'To date',
        bank_number: 'Bank number',
        banks_section: 'Banks section',
        add_open_balance: 'Add open balance',
        open_balance: 'Open balance',
        account_no: 'Account No.',
        currency: 'Currency',
        balances: 'Balances',
        currencies_allowed: 'Currencies allowed',
        last_validated_date: 'Last validated date',
        iban_no: 'IBAN number',
        adjust_balance: 'Adjust balance',
    },
    income_categories: {
        income_categories: 'Income Categories',
        add_new_income_category: 'Add new income category',
        add_new_sub_income_category: 'Add new sub income category',
        name: 'Name',
        description: 'Description',
        upload_image: 'Upload image',
    },
    customers: {
        buisnessno: 'Buisness no',
        supplier_code: 'Supplier code',
        taxno: 'Tax no',
        customers: 'Customers',
        add_customer: 'Add customer',
        customers_management: 'Customers management',
        cities: 'Cities',
        countries: 'Countries',
        currencies: 'Currencies',
        languages: 'Languages',
        categories: 'Categories',
        full_name: 'Full name',
        email: 'Email',
        phone: 'Phone',
        mobile: 'Mobile',
        is_default: 'Is default',
        code: 'Code',
        address: 'Address',
        city: 'City',
        country: 'Country',
        postcode: 'Post code',
        notes: 'Notes',
        price_list: 'Price list',
        state: 'State',
        customer: 'Customer',
        address_1: 'Address 1',
        address_2: 'Address 2',
        basic_information: 'Basic information',
        address_info: 'Customer addresses',
        postal_code: 'Postal code',
        type: 'Type',
        is_active: 'Is active',
        first_name: 'First name',
        last_name: 'Last name',
        telephone: 'Telephone',
        contact_list: 'Contact list',
        accounting_information: 'Accounting information',
        add_new_customer: 'Add new customer',
        update_customer: 'Update customer',
        business_name: 'Business name',
        opening_balance: 'Opening balance',
        amount: 'Amount',
        date: 'Date',
        currency: 'Currency',
        notes_attachments: 'Notes / attachments',
        time: 'Time',
        action: 'Action',
        status: 'Status',
        attachments: 'Attachments',
        upload_attachment: 'Upload attachment',
        file: 'File',
        file_link: 'File link',
        share_with_client: 'Share with client',

        attachment: 'Attachment',
        payment_status: 'Payment status',
        user_id_collected_by: 'Collected by',
        reference_no: 'Reference no',
        payment_details: 'Payment details',
        receipt_note: 'Receipt note',
        payment_credit: 'Payment credit',
        payment_method: 'Payment method',
        sales_man: 'Sales man',
        create_special_account_in_tree_accounting: 'Create special account in tree accounting',
        account: 'Account',
        classification: 'Customer classification',
        add_open_balance: 'Add open balance',
        balance: 'Balance',
        open_balance: 'Open balance',
        balances: 'Balances',
        last_validated_date: 'Last validated date',
        credit: 'Credit',
        debit: 'Debit',
        user_name: 'Added by',

        branch: 'Branch',
        customer_type: 'Customer account type',
        commercial_register: 'Commercial register',
        tax_register: 'Tax register',
        contact_information: 'Contact information',
        address_information: 'Address information',
        individual: 'Individual',
        business: 'Business',

        fullname: 'Full name',
        country_code: 'Country',
        city_id: 'City',
        customer_code: 'Code',
        currency_id: 'Currency',
        category: 'Category',
        branch_id: 'Branch',
        related_customers: 'Related customers',
        is_related_customers: 'Related customers',
        credit_and_debit: 'Credit and debit',
        minimum_credit: 'Minimum credit',
        maximum_credit: 'Maximum credit',
        minimum_debit: 'Minimum debit',
        maximum_debit: 'Maximum debit',

        name_on_invoice: 'Name on invoice',
        customer_branches: 'Customer branches',
    },

    appointments: {
        appointments: 'Appointments',
        add_appointment: 'Add appointment',
        appointments_management: 'Appointments management',
        customer: 'Customer',
        date: 'Date',
        start_time: 'Start time',
        interval: 'Interval',
        procedures: 'Procedure',
        share_with_client: 'Share with client',
        is_recurring: 'Is recurring',
        recurring_type: 'Recurring type',
        recurring_date: 'Recurring date',
        notes: 'Notes',
        is_active: 'Is active',
        start_at: 'Start at',
        appintment_date: 'Appintment date',
    },
    items: {
        wholesale_price: 'Wholesale price',
        half_wholesale_price: 'Half wholesale price',
        branch_price: 'Branch price',
        upload_image: 'Upload image',
        name: 'Name',
        id: 'ID',
        sku_code: 'SKU code',
        description: 'Description',
        category: 'Category',
        brand: 'Brand',
        barcode: 'Barcode',
        re_order: 'Re order',
        special_description: 'Special description',
        tags: 'Tags',
        is_tracking: 'Is tracking?',
        item: 'Item',
        items_section: 'Products section',
        new_item: 'New item',
        basic_information: 'Basic information',
        financial_information: 'Financial information',
        purchase_price: 'Purchase price',
        sale_price: 'Sale price',
        min_sale_price: 'Min sale price',
        profit_margin: 'Profit margin',
        discount: 'Discount',
        discount_type: 'Discount type',
        taxes: 'Taxes',
        tax: 'Tax',
        generate_barcode: 'Generate barcode',
        save_barcode: 'Save / Download as image',
        percentage: 'Percentage',
        initial_stock_level: 'Initial stock level',
        low_stock_threshold: 'Low stock threshold',
        inventory_details: 'Inventory details',
        add_new_item: 'Add new item',
        sum_available_qty: 'Total quantity available for sale',
        sum_stock_on_hand_qty: 'Sum stock on hand quantity',
        with_reserved_quantities_for_sales: 'With reserved quantities for sales',
        minimum_qty: 'Minimum quantity',
        item_details: 'Product details',
        is_manufacturing_item: 'Manufacturing item',
        raw_material_price: 'Raw material price',
        purchase_price_last_3_invoice: 'Purchase price for last 3 invoices',
        purchase_price_last_6_invoice: 'Purchase price for last 6 invoices',
        purchase_price_last_10_invoice: 'Purchase price for last 10 invoices',
        add_composite_item: "Add composite item",
        sub_items: "Sub items",
        qty: "quantity",
        sku: 'Sku',
        sell_price: 'Sell price',
        open_stock_value: 'Open stock value',
        open_stock_quantity: 'Open stock quantity',
        add_collected_items: "Add collected item",
        group_items: 'Group items',
        add_collected_item: 'Add collected item',
        composite_item: "Composite item",
        collected_item: "Collected item",
        values: "Values",
        you_must_select_category_to_add_group_items: "You must select category to add group items",
        add_aspects_for_category: "add aspects item for category through:",
        add_aspects: "Add aspects item",
        through_this_form_you_can_add_only_simple_item: "Through this form you can add only simple item",
        total_taxes: "Total tax rates",
        category_id: "Category",
        brand_id: "Brand",
        item_type: "Item type",
        tax_1: "Tax 1",
        tax_2: "Tax 2",
        mrp: "Max sale price",
        unit: "Unit",
        unit_id: "Unit",
        related_items: "Related items",
        is_active: "Is active",
        is_service: "Is service",
        is_tax_inclusive_price: "Is tax inclusive price",
        apply_priority_tax: "Apply priority",
        laundry_services: "Laundry services",
        other_barcodes: "Other barcodes",
        return_days: "Number of days for return",
        barcode_number: "Barcode number",
        units: 'Units',
        number: 'Number',
        available_quantity: 'Available quantity',
        item_is_available_for_sale_in_all_stores: 'Item is available for sale in all stores',
        stores: 'Stores',
        store: 'Store',
        multi_units: 'Multi units',
        inventory_and_quantities: 'Inventory and quantities',
        more_information: 'More information',
        sub_unit_sale_price: 'Price',
    },
    discount_type: {
        percentage: 'Percentage',
        value: 'Value',
    },
    price_lists: {
        price_list: "Price list",
        name: "Name",
        notes: "Notes",
        prices: "Prices",
        product: "Product",
        sale_price: "Sale price",
        new_price_list: "New price list",
        Prices_lists_section: "Prices lists section",
        item: "Product",
    },
    inventory_transactions: {
        transaction_code: "Transaction code",
        transaction_type: "Transaction type",
        inventory: "Inventory",
        date: "Date",
        inventory_transactions_section: "Inventory transactions section",
        from_date: "From date",
        to_date: "To date",
        inventory_transaction: "Inventory transaction",
        new_inventory_transaction: "New inventory transaction",
        transaction_reference: "Transaction reference",
        transaction_date: "Transaction date",
        notes: "Notes",
        product: "Product",
        purchase_price: "Purchase price",
        quantity: "Quantity",
        unit: "Unit",
        total: "Total",
        products: "Products",
        item_inventory_qty_after: "Quantity after",
        item_inventory_qty_before: "Quantity before",
        status: "Status",
        change_status: "Change status",
        transfer_from_inventory: "Transfer from inventory",
        transfer_to_inventory: "Transfer to inventory",
    },
    inventory_monitoring: {
        date: "Date",
        product: "Product",
        unit: "Unit",
        products: "Products",
        from_date: "From date",
        to_date: "To date",
        inventory: "Inventory",
        notes: "Notes",
        time: "Time",
        inventory_monitoring_section: "Inventory monitoring section",
        new_inventory_monitoring: "New inventory monitoring",
        inventory_monitoring: "Inventory monitoring",
        code: "Code",
        actual_quantity: "Actual quantity",
        expected_quantity: "System quantity",
        settlement_status: "Settlement status",
    },
    customers_status: {
        customers_status: "Customers status",
        is_default: "Is default",
        is_active: "Is active",
        color: "Color",
        customer: "Customer",
        statuses: "Statuses",
    },
    transaction_types: {
        add_quantity: "Add quantity",
        decrease_quantity: "Decrease quantity",
        transfer_quantity: "Transfer quantity",
    },
    status_list: {
        pending: "Pending",
        confirm: "Confirm",
    },
    customer_attributes: {
        name: "Name",
        data_type: "Data type",
        is_unique: "Is unique",
        is_nullable: "Is nullable",
        customer_attributes: "Customer attributes",
        attributes: "Attributes",
    },
    customer_feature_data_type: {
        number: "Number",
        char: "Char",
    },
    customers_feature: {
        customers_feature: "Customers feature",
        customer_type: "Customer type",
        customer_advance_feature: "Customer advance feature",
        feature: "Feature",
        status: "Status",
    },
    customer_type: {
        company: "Company",
        individual: "Individual",

    },
    suppliers: {
        suppliers_management: 'Suppliers management',
        suppliers_section: 'Suppliers section',
        add_supplier: 'Add supplier',
        name: 'Name',
        supplier_code: 'Supplier code',
        email: 'Email',
        mobile: 'Mobile',
        phone: 'Phone',
        country: 'Country',
        postal_code: 'Postal code',
        address: 'Address',
        basic_information: 'Basic information',
        business_name: 'Business name',
        full_name: 'Full name',
        first_name: 'First name',
        last_name: 'Last name',
        telephone: 'Telephone',
        city: 'City',
        state: 'State',
        address_1: 'Street address 1',
        address_2: 'Street address 2',
        is_active: 'Is active',
        default_currency: 'Default currency',
        open_balance: 'Open balance',
        open_balance_date: 'Open stock date',
        notes: 'Notes',
        accounting_information: 'Accounting information',
        address_info: 'Address information',
        contact_info: 'Contact information',
        is_default: 'Is Default',
        contact_list: 'Contact list',
        add_new_supplier: 'Add new supplier',
        activity_log: 'Activity log',
        create_special_account_in_tree_accounting: 'Create special account in tree accounting',
        account: 'Account',
        balances: 'Balances',
        balance: 'Balance',
        currency: 'Currency',
        last_validated_date: 'Last validated date',
        currencies_allowed: 'Currencies allowed',
        buisnessno: 'Business no',
        taxno: 'Tax no',
        add_open_balance: 'Add open balance',
        credit: 'Credit',
        debit: 'Debit',
        contact_information: 'Contact information',
        address_information: 'Address information',
        credit_and_debit: 'Credit and debit',
        minimum_credit: 'Minimum credit',
        maximum_credit: 'Maximum credit',
        minimum_debit: 'Minimum debit',
        maximum_debit: 'Maximum debit',
    },
    customer_types: {
        billing: 'Billing',
        shipping: 'Shipping',
        both: 'Both',
    },
    validation: {
        numeric_digits: 'Numeric digits',
        lowercase_hex_numbers: 'Lowercase hex numbers',
        uppercase_hex_numbers: 'Uppercase hex numbers',
        lowercase_letters: 'Lowercase letters',
        uppercase_letters: 'Uppercase letters',
        lowercase_letters_followed_by_numeric_digits: 'Lowercase letters followed by numeric digits',
        uppercase_letters_followed_by_numeric_digits: 'Uppercase letters followed by numeric digits',
    },
    purchases_invoices: {
        purchases_invoices: 'Purchases invoices',
        title: 'Title',
        supplier: 'Supplier',
        purchase_code: 'Purchase code',
        purchase_date: 'Purchase date',
        payment_terms: 'Payment terms',
        day_s: 'Day/s',
        currency: 'Currency',
        shipping_fees: 'Shipping fees',
        inventory: 'Inventory',
        attachment: 'Attachment',
        notes: 'Notes',
        items: 'Items',
        item: 'Item',
        description: 'Description',
        unit_price: 'Unit price',
        qty: 'Quantity',
        tax: 'Tax',
        discount_type: 'Discount type',
        discount_value: 'Discount value',
        subtotal: 'Sub total',
        purchases_invoices_section: 'Purchases invoices section',
        add_purchases_invoice: 'Add purchases invoice',
        from_date: 'From date',
        to_date: 'To date',
        added_by: 'Added by',
        items_total: 'Products total',
        total_tax: 'Total tax',
        total: 'Total',
        create_date: 'Create date',
        status: 'Status',
        change_status: 'Change status',
        amount_tax: 'Amount tax',
        subtotal_before_tax: 'Subtotal before tax',
        invoice_total_discount: 'Total discount',
        reference_number: 'Reference number',
        unit: 'Unit',
        termination_of_receipt: 'Termination of receipt',
        is_paid_label: 'Payment status',
        paid_amount_label: 'Paid amount',
    },
    purchases_refunds: {
        purchases_refunds: 'Purchases refunds',
        title: 'Title',
        supplier: 'Supplier',
        purchase_refund_code: 'Refund code',
        purchase_invoice_code: 'Purchase code',
        purchase_refund_date: 'Refund date',
        currency: 'Currency',
        shipping_fees: 'Shipping fees',
        inventory: 'Inventory',
        attachment: 'Attachment',
        notes: 'Notes',
        items: 'Items',
        item: 'Item',
        description: 'Description',
        unit_price: 'Unit price',
        qty: 'Refund quantity',
        tax: 'Tax',
        discount_type: 'Discount type',
        discount_value: 'Discount value',
        payment_type: 'Payment method',
        payment_ref: 'Reference no',
        subtotal: 'Sub total',
        purchases_refunds_section: 'Purchases refunds section',
        add_purchases_refund: 'Add purchases refund',
        from_date: 'From date',
        to_date: 'To date',
        added_by: 'Added by',
        items_total: 'Products total',
        total_tax: 'Total tax',
        total: 'Total',
        create_date: 'Create date',
        tax_2: 'Tax 2',
        branch: 'Branch',
        deposit_type: 'Deposit type',
        deposit_value: 'Deposit value',
        status: 'Status',
        total_amount_refunf_supplier: 'The total amount of the returned items has been refunded from supplier',
        payment_method: 'Payment method',
        original_qty: 'Original quantity',
        amount_tax: 'Amount tax',
        subtotal_before_tax: 'Subtotal before tax',
        invoice_total_discount: 'Total discount',
        unit: 'Unit',
    },
    discount_types: {
        none: 'None',
        percentage: 'Percentage',
        value: 'val',
    },
    purchase_configuration: {
        purchase_configuration: "Purchase general configuration",
        purchase_configuration_section: "Purchase general configuration section",
        update_product_prices: "Update product prices after purchase invoice",
        auto_pay_supplier_invoice: "Auto pay purchase invoices if the supplier has available credit",
        mark_invoice_default_paid: "Mark Purchase Invoices As Already Paid By Default",
        purchase_invoice_manual_status: "Purchase Invoice Manual Statuses",
        custom_jornal_description: "Custom Journal Description",
    },
    estimations: {
        estimations: 'Sales estimations',
        name: 'Name',
        customer: 'Customer',
        estimate_code: 'Estimate No.',
        estimate_date: 'Estimate date',
        currency: 'Currency',
        shipping_fees: 'Shipping fees',
        attachment: 'Attachment',
        notes: 'Notes',
        items: 'Items',
        item: 'Item',
        description: 'Description',
        unit_price: 'Unit price',
        qty: 'Quantity',
        tax: 'Tax',
        discount_type: 'Discount type',
        discount_value: 'Discount value',
        subtotal: 'Sub total',
        unit: 'Unit',
        estimations_section: 'Sales estimations section',
        add_estimations: 'Add Sales estimations',
        from_date: 'From date',
        to_date: 'To date',
        added_by: 'Added by',
        create_date: 'Create date',
        estimate_subtotal: 'Total Subtotal',
        total_after_discount: 'Total discount',
        total_after_shipping: 'Total shipping',
        total_tax: 'Total tax',
        total: 'Total',
        from_quantity: 'From quantity',
        to_quantity: 'To quantity',
        basic_information: 'Basic information',
        attachments: 'Attachments',
        shipping_details: 'Shipping details',
        adjustment: 'Adjustment',
        upload_attachment: 'Upload Attachment',
    },
    sales_invoices: {
        sales_invoices: 'Sales invoices',
        name: 'Name',
        customer: 'Customer',
        invoice_code: 'Invoice No.',
        invoice_date: 'Invoice date',
        currency: 'Currency',
        shipping_fees: 'Shipping fees',
        attachment: 'Attachment',
        notes: 'Notes',
        items: 'Items',
        item: 'Item',
        description: 'Description',
        unit_price: 'Unit price',
        qty: 'Quantity',
        tax: 'Tax',
        tax2: 'Tax 2',
        discount_type: 'Discount type',
        discount_value: 'Discount value',
        subtotal: 'Sub total',
        unit: 'Unit',
        sales_invoices_section: 'Sales invoices section',
        add_sales_invoices: 'Add sales invoice',
        from_date: 'From date',
        to_date: 'To date',
        added_by: 'Added by',
        create_date: 'Create date',
        invoice_subtotal: 'Total Subtotal',
        total_discount: 'Total discount',
        total_after_shipping: 'Total shipping',
        total_tax: 'Total tax',
        total: 'Total',
        from_quantity: 'From quantity',
        to_quantity: 'To quantity',
        basic_information: 'Basic information',
        attachments: 'Attachments',
        shipping_details: 'Shipping details',
        adjustment: 'Adjustment',
        upload_attachment: 'Upload Attachment',
        deposite_value: 'Deposit value',
        payment_terms: 'Payment terms',
        issue_date: 'Issue date',
        total_commission: 'Total commission',
        accounts: 'Accounts',
        inventory: 'Inventory',
        amount_tax: 'Amount tax',
        subtotal_before_tax: 'Subtotal before tax',
        invoice_total_discount: 'Total discount',
        add_general_sales: 'Add general sales invoice',
        country: 'Country',
        city: 'City',
        address: 'Address',
        shipping_address: 'Shipping address',
        billing_address: 'Billing address',
        price_list: 'Prices list',
        reference_number: 'Reference number',
        total_amount_before_discount: 'Total amount before discount',
        total_amount_after_discount: 'Total amount after discount',
        total_before_discount: 'Total before discount',
        total_after_discount: 'Total after discount',
        final_total: 'Final total',
        payment_status: 'Payment status',
        payment_status_label: 'Payment',
        add_sales_laundries: 'Add laundries invoice',
        service: 'Service',
        received_and_delivery_appointment: 'Received and delivery appointment',
        received: 'Received',
        delivery: 'Delivery',
        user_assigned_to_invoice: 'User assigned to invoice',
        user: 'user',
        zero_sales_invoice: 'Zero sales invoice',
        deferred_invoice: 'Invoice type (Deferred - Cash)',
        amount_in_letters: 'Amount in letters',
        word_invoice_total: 'Total invoice in arabic letters',
        word_invoice_total_en: 'Total invoice in english letters',
        from_amount: 'From amount',
        to_amount: 'To amount',
        save_invoice_as: 'Save invoice as',
        additional_discount: 'Additional discount',
        additional_discount_type: 'Additional discount type',
        additional_discount_rate: 'Additional discount rate',
        additional_discount_value: 'Additional discount value',
        total_before_additional_discount: 'Total before additional discount',
        transaction_type: 'Transaction type',
        transaction_code: 'Transaction code',
        transaction_date: 'Transaction date',
        transaction_status: 'Transaction status',
        refund_invoice_infromation: 'Refund invoice infromation',
    },
    sales_refund: {
        sales_refund: 'Sales refunds',
        sales_refund_section: 'Sales refunds section',
        refund_info: 'Refund info',
        basic_information: 'Basic information',
        attachments: 'Attachments',
        customer: 'Customer',
        invoice_code: 'Invoice Code.',
        receipt_no: 'Receipt No.',
        receipt_date: 'Receipt date',
        shipping_details: 'Shipping details',
        shipping_fees: 'Shipping fees',
        discount_type: 'Discount type',
        discount_value: 'Discount value',
        issue_date: 'Issue date',
        adjustment: 'Adjustment',
        sales_man: 'Sales Man',
        notes: 'Notes',
        ref_no: 'Ref No.',
        payment_method: 'Payment Method',
        items: 'Items',
        item: 'Item',
        description: 'Description',
        unit_price: 'Unit price',
        qty: 'Quantity',
        tax: 'Tax',
        subtotal: 'Sub total',
        unit: 'Unit',
        invoice_subtotal: 'Total Subtotal',
        total_after_discount: 'Total discount',
        total_after_shipping: 'Total shipping',
        total_tax: 'Total tax',
        total: 'Total',
        from_quantity: 'From quantity',
        to_quantity: 'To quantity',
        tax1: 'Tax',
        tax2: 'Tax 2',
        client_info: 'Customer info',
        refund_receipt_details: 'Refund receipt details',
        code_refund_receipt: 'Refund receipt code',
        customer_code: 'Customer code',
        customer_name: 'Customer name',
        currency: 'Currency',
        amount: 'Amount',
        from_date: 'From date',
        to_date: 'To date',
        added_by: 'Added by',
        payment_status: 'Payment status',
        collected_by: 'Collected by',
        refund_receipt_date: 'Refund receipt date',
        from_amount: 'From amount',
        to_amount: 'To amount',
        inventory: 'Inventory',
        amount_tax: 'Amount tax',
        subtotal_before_tax: 'Subtotal before tax',

    },
    credits: {
        credits: 'Sales credits',
        name: 'Name',
        customer: 'Customer',
        credit_code: 'Credit No.',
        credit_date: 'Credit date',
        currency: 'Currency',
        shipping_fees: 'Shipping fees',
        attachment: 'Attachment',
        notes: 'Notes',
        items: 'Items',
        item: 'Item',
        description: 'Description',
        unit_price: 'Unit price',
        qty: 'Quantity',
        tax: 'Tax',
        discount_type: 'Discount type',
        discount_value: 'Discount value',
        subtotal: 'Sub total',
        unit: 'Unit',
        credits_section: 'Sales credit section',
        add_sales_credits: 'Add sales credit',
        add_sales_credits_general: 'Add general sales credit',
        from_date: 'From date',
        to_date: 'To date',
        added_by: 'Added by',
        create_date: 'Create date',
        invoice_subtotal: 'Total Subtotal',
        total_after_discount: 'Total discount',
        total_after_shipping: 'Total shipping',
        total_tax: 'Total tax',
        total: 'Total',
        from_quantity: 'From quantity',
        to_quantity: 'To quantity',
        basic_information: 'Basic information',
        attachments: 'Attachments',
        shipping_details: 'Shipping details',
        adjustment: 'Adjustment',
        upload_attachment: 'Upload Attachment',
        deposite_value: 'Deposit value',
        payment_terms: 'Payment terms',
        issue_date: 'Issue date',
        credit_subtotal: 'Subtotal',
        day_s: 'Day/s',
        inventory: 'Inventory',
        amount_tax: 'Amount tax',
        subtotal_before_tax: 'Subtotal before tax',
        invoice_total_discount: 'Total discount',
        original_qty: 'Original quantity',
    },
    sales_shipping: {
        name: 'Name',
        fees: 'Fees',
        tax: 'Tax',
        currency: 'Currency',
        order: 'Order',
        notes: 'Notes',
        type: 'Type',
        sales_shipping: 'Sales shipping',
        add_sales_shipping: 'Add sales shipping',
        sales_shipping_section: 'Sales shipping section',
    },
    sales_configuration: {
        convert_delivery_without_available_qty: 'Convert delivery without available quantity',
        convert_delivery_from_pending: 'convert from pending to delivery directly',
        sales_configuration: 'Sales configuration',
        add_sales_configuration: 'Add sales configuration',
        sales_configuration_section: 'Sales configuration section',
        Enable_Changing_Invoice_Number_For_Each_Invoice: 'Enable Changing Invoice Number For Each Invoice',
        billing_method: 'Billing method',
        discount_option: 'Discount option',
        zero_fraction_appearing: 'Zero fraction appearing',
        Disable_Invoice_Items_Edit: 'Disable Invoice Products Edit',
        Disable_Estimates_Module: 'Disable Estimates Module',
        Enable_Invoice_Manual_Statuses: 'Enable Invoice Manual Statuses',
        Enable_Estimate_Manual_Statuses: 'Enable Estimate Manual Statuses',
        Disable_Shipping_Options: 'Disable Shipping Options',
        Enable_Maximum_Discount: 'Enable Maximum Discount',
        Mark_Invoices_As_Already_Paid_By_Default: 'Mark Invoices As Already Paid By Default',
        Auto_Pay_Invoices_if_the_Client_Has_Available_Credit: 'Auto Pay Invoices if the Customer Has Available Credit',
        Send_Invoices_Estimates_Via_Whats_App: 'Send Invoices & Estimates Via-Whats-App',
        Display_the_Invoice_Profit: 'Display the Invoice Profit',
        Custom_Journal_Description: 'Custom Journal Description',
        pos_policy: 'Usage policy',
        is_company_has_tax_no: 'Company has tax no.',
        is_apply_taxes_setting: 'Applying tax to invoices',
        is_apply_tax_quotation: 'Applying tax to price quotations',
        is_apply_tax_debit: 'Applying tax to debit',
        average_cost: 'Average cost',
        sales_invoice_date_setting: 'Sales invoice date settings',
        save_invoice_setting: 'Sales invoice saving settings',
    },
    expenses_category: {
        expenses_category: 'Expenses categories',
        add_new_category: 'Add new category',
        add_new_sub_category: 'Add new sub category',
        name: 'Name',
        description: 'Description',
    },
    expenses: {
        id: 'ID',
        expenses: 'Expenses management',
        expenses_section: 'Expenses management section',
        code: 'Code',
        from_date: 'From date',
        to_date: 'To date',
        currency: 'Currency',
        add_expenses: 'Add expenses',
        category: 'Category',
        name: 'Name',
        date: 'Date',
        amount: 'Amount',
        tax: 'Tax',
        attachment: 'Attachment',
        status: 'Status',
        notes: 'Notes',
        taxes: 'Taxes',
        tax_type: 'Tax type',
        tax_val: 'Tax value',
        is_recurring: 'Is recurring',
        frequency: 'Frequency',
        end_date: 'End date',
        payment_method: 'Payment method',
        treasury: 'Treasury',
        inventory: 'Inventory',
        bank: 'Bank',
        is_included_tax: 'Included tax',
    },
    incomes: {
        incomes: 'Incomes management',
        incomes_section: 'Incomes management section',
        code: 'Code',
        from_date: 'From date',
        to_date: 'To date',
        currency: 'Currency',
        add_incomes: 'Add income',
        category: 'Category',
        name: 'Name',
        date: 'Date',
        amount: 'Amount',
        tax: 'Tax',
        attachment: 'Attachment',
        status: 'Status',
        notes: 'Notes',
        taxes: 'Taxes',
        tax_type: 'Tax type',
        tax_val: 'Tax value',
        is_recurring: 'Is recurring',
        frequency: 'Frequency',
        end_date: 'End date',
        payment_method: 'Payment method',
        treasury: 'Treasury',
        inventory: 'Inventory',
        bank: 'Bank',
    },
    treasuries: {
        treasuries: 'Treasuries management',
        treasuries_section: 'Treasuries management section',
        add_treasuries: 'Add treasury',
        from_date: 'From date',
        to_date: 'To date',
        name: 'Name',
        balance: 'Balance',
        notes: 'Notes',
        deposit: 'Deposit',
        withdraw: 'Withdraw',
        created_date: 'Created date',
        balances: 'Balances',
        currency: 'Currency',
        currencies_allowed: 'Allowed currencies',
        last_validated_date: 'Last validated date',
        add_open_balance: 'Add open balance',
        open_balance: 'Open balance',
        manager: 'Manager',
        adjust_balance: 'Adjust balance',
    },
    treasury_checking: {
        treasury_checking: 'Treasuries balance checking ',
        add_treasury_checking: 'Add treasury balance checking ',
        treasury: 'Treasury',
        from_date: 'From date',
        to_date: 'To date',
        last_validated_date: 'Last validated date',
        currency: 'Currency',
        validator_user: 'Validator user',
        current_amount: 'Current amount',
        actual_amount: 'Actual amount',
        cash: 'Cash',
        currency_name: 'Currency name',
        last_validate_date: 'Last validate date',
        last_val: 'Last value',
        old_balance: 'Old balance',
        new_balance: 'New balance',
        created_date: 'Created date',
        confirmed_date: 'Confirmed date',
    },
    subscriptions: {
        subscriptions: 'Subscriptions',
        basic_information: 'Basic information',
        subscription_name: 'Subscription name',
        generate_invoice_every: 'Generate invoice every',
        occurrences: 'Occurrences',
        first_invoice_date: 'First invoice date',
        issue_invoice_before: 'Issue invoice before',
        day_s: 'Days',
        customer: 'Customer',
        send_copy_flag: 'Send me a copy of the new generated invoices',
        display_dates_flag: 'Display (From) and (To) dates in the invoice',
        automatic_payment_flag: 'Enable automatic payment for this invoice',
        is_active: 'Is active',
        shipping_details: 'Shipping details',
        shipping_fees: 'Shipping fees',
        discount_type: 'Discount type',
        discount_value: 'Discount value',
        adjustment: 'Adjustment',
        notes: 'Notes',
        items: 'Items',
        item: 'Item',
        description: 'Description',
        unit_price: 'Unit price',
        qty: 'Quantity',
        unit: 'Unit',
        tax_1: 'Tax',
        tax_1_value: 'Tax value',
        tax_2: 'Tax 2',
        tax_2_value: 'Tax 2 value',
        subtotal: 'Subtotal',
        tax: 'Tax',
        total_subtotal: 'Total subtotal',
        total_tax: 'Total tax',
        total: 'Total',
        attachments: 'Attachments',
        name: 'Name',
        attachment: 'Attachment',
        upload_attachment: 'Upload attachment',
        payment_terms: 'Payment terms',
        from_date: 'From date',
        to_date: 'To date',
        from_amount: 'From amount',
        to_amount: 'To amount',
        every: "Every",
        subscriptions_section: "Subscriptions section",
        add_subscription: "Add subscription",
        generated: "Generated",
    },
    payment_sales_invoices: {
        payment_sales_invoices: 'Sales and purchase payments',
        payment_method: 'Payment method',
        amount: 'Amount',
        banks: 'Banks',
        payment_date: 'Payment date',
        payment_status: 'Payment status',
        collected_by: 'Collected by',
        ref_no: 'Ref no.',
        payment_details: 'Payment details',
        receipt_notes: 'Receipt notes',
        attachment: 'Attachment',
        client_info: 'Customer info',
        invoice_code: 'Invoice code',
        from_date: 'From date',
        to_date: 'To date',
        from_amount: 'From amount',
        to_amount: 'To amount',
        customer: 'Customer',
        invoiced_by: 'Invoiced by',
        payment_sales_invoices_section: 'Payment sales',
        payment_purchase_invoices_section: 'Payment purchase',
        type: 'Invoice type',
        customer_code: 'Customer code',
        customer_name: 'Customer name',
        supplier_code: 'Supplier code',
        supplier_name: 'Supplier name',
        sales_invoice: 'Sales invoice',
        general_sales: 'General sales invoice',
        general_sales_refund: 'General sales refund',
        purchase_invoice: 'Purchase invoice',
        sales_refund: 'Sales refund',
        purchase_refund: 'Purchase refund',
        details: 'Details',
        treasuries: 'Treasuries',
        currency: 'Currency',
        sales_credit: 'Sales credit',
        financial_payment: 'Financial payment',
        code: 'Code',
    },
    payment_sales_invoices_details: {
        Client_Info: 'Customer Info',
        Payment_Details: 'Payment Details',
        Client_Name: 'Customer Name',
        Invoice_No: 'Invoice No.',
        Street_Address: 'Street Address',
        Payment_Method: 'Payment Method',
        City: 'City',
        Amount: 'Amount',
        State: 'State',
        Transaction_ID: 'Transaction ID',
        Postal_Code: 'Postal Code',
        Status: 'Status',
        Telephone: 'Telephone',
        Date: 'Date',
        Country: 'Country',
        Added_By: 'Added By',
        Currency: 'Currency',
        Details: 'Details',
        Receipt_Notes: 'Receipt Notes',
        delete: 'Delete',
        receipt: 'Receipt',
        edit: 'Edit',
    },
    employees: {
        employees_management: 'Employees management',
        basic_information: 'Basic information',
        accounting_information: 'Accounting information',
        information_account: 'Account information',
        financial_info: 'Financial information',
        attachments: 'Attachments',
        notes: 'Notes',
        upload_image: 'Upload image',
        first_name: 'First name',
        second_name: 'Second name',
        surname: 'Surname',
        nationality: 'Nationality',
        mobile: 'Mobile',
        email: 'Email',
        identity_type: 'Identity type',
        identity_no: 'Identity no',
        employment_type: 'Employment type',
        employment_level: 'Level',
        employee_code: 'Employee code',
        country: 'Country',
        city: 'City',
        state: 'State',
        postal_code: 'Postal code',
        address1: 'Address 1',
        address2: 'Address 2',
        password: 'Password',
        roles: 'Roles',
        allow_access_system: 'Allow access to the system',
        send_credetial_email: 'Send credentials to employee on email',
        display_language: 'Display language',
        start_date: 'Start date',
        expired_date: 'Expired date',
        start_salary: 'Start salary',
        increase_every: 'Increase every',
        current_salary: 'Current salary',
        currency: 'Currency',
        increase_percentage: 'Increase percentage',
        house_alternative: 'House alternative',
        travel_alternative: 'Travel alternative',
        health_alternative: 'Health alternative',
        title: 'Title',
        name: 'Name',
        attachment: 'Attachment',
        upload_attachment: 'Upload attachment',
        employees: 'Employees',
        add_employee: 'Add employee',
        from_date: 'From date',
        to_date: 'To date',
        full_name: 'Full name',
        level: 'Level',
        type: 'Type',
        address_info: 'Employee addresses',
        inventory: 'Inventory',
        branch: 'Branch',
        job_title: 'Job title',
        special: 'Special',
        general: 'General',
        account_type: 'Account type',
        manager: 'Manager',
        is_sales_man: 'Is sales man',
        commission_type: 'Commission type',
        commission_value: 'Commission value',
        none: 'None',
        percentage: 'Percentage',
        fixed_value: 'Fixed value',
    },
    purchases_requests: {
        purchases_requests: 'Purchases requests',
        title: 'Title',
        purchase_request_code: 'Request code',
        purchase_request_date: 'Request date',
        purchase_request_due_date: 'Request due date',
        branch: 'Branch',
        attachment: 'Attachment',
        notes: 'Notes',
        items: 'Items',
        item: 'Item',
        qty: 'Quantity',
        purchases_requests_section: 'Purchases requests section',
        add_purchases_request: 'Add purchases request',
        from_date: 'From date',
        to_date: 'To date',
        added_by: 'Added by',
        create_date: 'Create date',
    },
    purchases_quotation_requests: {
        purchases_quotation_requests: 'Purchases quotation requests',
        title: 'Title',
        purchase_quotation_request_code: 'Quotation request code',
        purchase_quotation_request_date: 'Quotation request date',
        purchase_request_due_date: 'Quotation request due date',
        branch: 'Branch',
        attachment: 'Attachment',
        notes: 'Notes',
        items: 'Items',
        item: 'Item',
        qty: 'Quantity',
        purchases_quotation_requests_section: 'Purchases quotation requests section',
        add_purchases_quotation_requests: 'Add purchases quotation request',
        from_date: 'From date',
        to_date: 'To date',
        added_by: 'Added by',
        create_date: 'Create date',
        supplier: 'Supplier',
    },
    purchases_orders: {
        purchases_orders: 'Purchases orders',
        title: 'Title',
        supplier: 'Supplier',
        purchase_code: 'Purchase code',
        purchase_date: 'Purchase date',
        payment_terms: 'Payment terms',
        day_s: 'Day/s',
        currency: 'Currency',
        shipping_fees: 'Shipping fees',
        inventory: 'Inventory',
        attachment: 'Attachment',
        notes: 'Notes',
        items: 'Items',
        item: 'Item',
        description: 'Description',
        unit_price: 'Unit price',
        qty: 'Quantity',
        tax: 'Tax',
        discount_type: 'Discount type',
        discount_value: 'Discount value',
        subtotal: 'Sub total',
        purchases_orders_section: 'Purchases orders section',
        add_purchases_order: 'Add purchases order',
        from_date: 'From date',
        to_date: 'To date',
        added_by: 'Added by',
        items_total: 'Products total',
        total_tax: 'Total tax',
        total: 'Total',
        create_date: 'Create date',
        valid_until: 'Valid until',
        tax_1: 'Tax',
        tax_2: 'Tax 2',
        branch: 'Branch',
        total_taxes: 'Total taxes',
        convert_to_purchase_invoice: 'Convert to purchase invoice',
        do_you_want_convert_to_purchase_invoice: 'Do you want convert to purchase invoice?',
    },
    cheque_book: {
        cheque_book: 'Cheque book',
        bank: 'Bank',
        bank_booking_no: 'Cheque Book Number',
        first_serial: 'First serial',
        last_serial: 'Last serial',
        currency: 'Currency',
        branch: 'Branch',
        notes: 'Notes',
        cheque_book_section: 'Cheque book section',
        add_cheque_book: 'Add cheque book',
        from_date: 'From date',
        to_date: 'To date',
        added_by: 'Added by',
        used: 'Used',
        created_date: 'Created date',
        bank_name: 'Bank name',
        used_number: 'Used number',
    },
    payable_cheques: {
        payable_cheques: 'Payable cheques',
        bank: 'Bank',
        bank_booking_no: 'Cheque Book Number',
        first_serial: 'First serial',
        last_serial: 'Last serial',
        currency: 'Currency',
        branch: 'Branch',
        notes: 'Notes',
        payable_cheques_section: 'Payable cheques section',
        add_payable_cheques: 'Add payable cheque',
        from_date: 'From date',
        to_date: 'To date',
        added_by: 'Added by',
        created_date: 'Created date',
        bank_name: 'Bank name',
        used_number: 'Used number',

        amount: 'Amount',
        cheque_book_number: 'Cheque book number',
        cheques_no: 'Cheques number',
        issue_date: 'Issue date',
        due_date: 'Due date',
        received_account: 'Received account',
        cheques_name: 'Cheques on name',
        description: 'Description',
        status: 'Status',
        attachment: 'Attachment',
        upload_attachment: 'Upload attachment',
        booking_no: 'Booking number',
        from_issue_date: 'From issue date',
        to_issue_date: 'To issue date',
        from_due_date: 'From due date',
        to_due_date: 'To due date',
        book_number: 'Book number',
        collect_date: 'Collect date',
        bank_account: 'Bank account',
    },
    receivable_cheques: {
        receivable_cheques: 'Receivable cheques',
        bank: 'Bank',
        bank_booking_no: 'Cheque Book Number',
        first_serial: 'First serial',
        last_serial: 'Last serial',
        currency: 'Currency',
        branch: 'Branch',
        notes: 'Notes',
        receivable_cheques_section: 'Receivable cheques section',
        add_receivable_cheques: 'Add receivable cheque',
        from_date: 'From date',
        to_date: 'To date',
        added_by: 'Added by',
        created_date: 'Created date',
        bank_name: 'Bank name',
        used_number: 'Used number',
        amount: 'Amount',
        cheque_book_number: 'Cheque book number',
        cheques_no: 'Cheques number',
        issue_date: 'Issue date',
        due_date: 'Due date',
        received_account: 'Received account',
        cheques_name: 'Cheques on name',
        description: 'Description',
        status: 'Status',
        attachment: 'Attachment',
        upload_attachment: 'Upload attachment',
        booking_no: 'Booking number',
        from_issue_date: 'From issue date',
        to_issue_date: 'To issue date',
        from_due_date: 'From due date',
        to_due_date: 'To due date',
        book_number: 'Book number',
        collected_account: 'Collected account',
        endorse: 'Endorse',
        endorse_name: 'Endorse name',
        to_amount: 'To amount',
        from_amount: 'From amount',
    },
    activity_log: {
        activity_log: 'Activity log',
        basic_information: 'Basic information',
    },
    open_stock_items: {
        open_stock_items: 'Open stock items',
        inventory: 'Inventory',
        item: 'Item',
        quantity: 'Quantity',
        min_quantity: 'Min quantity for order',
        max_quantity: 'Max quantity for order',
        applied_date: 'Applied date',
        status: 'Status',
        new_open_stock_items: 'New open stock items',
        financial_year_name: 'Financial year',
        from_quantity: 'From quantity',
        to_quantity: 'To quantity',
        from_date: 'From date',
        to_date: 'To date',
        change_status: 'Change status',
        items: 'Items',
        created_at: 'Created at',
    },
    pos_devices: {
        pos_devices: 'POS Devices',
        name: 'Name',
        store: 'Store',
        parent_category: 'Parent category',
        image: 'Image',
        upload_image: 'Upload image',
        description: 'Description',
        pos_devices_section: 'POS devices section',
        new_pos_devices: 'New POS devices',
        from_date: 'From date',
        to_date: 'To date',
        added_by: 'Added by',
        created_at: 'Created at',
    },
    pos_shifts: {
        pos_shifts: 'POS Shifts',
        name: 'Name',
        store: 'Store',
        parent_category: 'Parent category',
        image: 'Image',
        upload_image: 'Upload image',
        description: 'Description',
        pos_shifts_section: 'POS shifts section',
        new_pos_shifts: 'New POS shifts',
        from_date: 'From date',
        to_date: 'To date',
        added_by: 'Added by',
        created_at: 'Created at',
    },
    pos_settings: {
        pos_settings: 'POS settings',
        default_client: 'Default client',
        default_inventory: 'Default inventory',
        default_layout: 'Default layout',
        enabled_payment: 'Enabled payment',
        default_payment: 'Default payment method',
        allowed_categories: 'Allowed categories',
        product_category: 'Product category',
        enable_num_pad: 'Enable Num Pad',
        show_product_images: 'Show Product Images',
        accounting_System_per_invoice: 'Accounting System Per Invoice',
        enable_auto_adjustment: 'Enable Auto Adjustment',
        profit_account: 'Profit Account',
        loss_account: 'Loss Account',
        default_bank: 'Default bank',
        default_cash_treasury: 'Default cash treasury',
        default_card_treasury: 'Default card treasury',
        default_currency: 'Default currency',
        is_allow_skip_close_cash: 'Is allow skip close cash',
        is_foreign_currency: 'Is foreign currency',

    },
    financial_years: {
        financial_years: 'Financial years',
        start_date: 'Start date',
        end_date: 'End date',
        description: 'Description',
        financial_years_section: 'Financial years section',
        new_financial_year: 'New financial year',
        from_date: 'From date',
        to_date: 'To date',
        added_by: 'Added by',
        created_at: 'Created at',
    },
    closed_periods: {
        closed_periods: 'Closed periods',
        description: 'Description',
        closed_periods_section: 'Closed periods section',
        new_closed_period: 'New Closed period',
        to_date: 'To date',
        added_by: 'Added by',
        created_at: 'Created at',
    },
    insurance_agents: {
        insurance_agents: 'Insurances Agent',
        insurance_agents_section: 'Insurances Agent section',
        name: 'Company Name',
        website: 'Website',
        email: 'Email',
        telephone: 'Telephone',
        image: 'Image',
        upload_image: 'Upload image',
        added_by: 'Added by',
        created_at: 'Created date',
        new_insurance_agent: 'New insurance agent',
        from_date: 'From date',
        to_date: 'To date',
        classes: 'Classes',
    },
    insurance_agents_class: {
        name: 'Name',
        notes: 'Notes',
        class_details: 'Class details',
        from_date: 'From date',
        to_date: 'To date',
        classes: 'Classes',

        categories: 'Categories',
        discount: 'Discount',
        company_copayment: 'Company Copayment',
        client_copayment: 'Customer Copayment',
        max_copayment_type: 'Max Copayment Type',
    },
    credit_types: {
        credit_types: 'Credit types',
        name: 'Name',
        unit: 'Unit',
        description: 'Description',
        credit_types_section: 'Credit types section',
        new_credit_type: 'New Credit type',
        from_date: 'From date',
        to_date: 'To date',
        added_by: 'Added by',
        created_at: 'Created at',
        allow_decimal: 'Allow Decimal',
    },
    credit_usages: {
        credit_usages: 'Credit usages',
        client: 'client',
        client_name: 'Customer name',
        credit_type: 'Credit type',
        usage_amount: 'Usage amount',
        usage_date: 'Usage date',

        description: 'Description',
        credit_usages_section: 'Credit usages section',
        new_credit_usage: 'New Credit usage',
        from_date: 'From date',
        to_date: 'To date',
        added_by: 'Added by',
        created_at: 'Created at',
        allow_decimal: 'Allow Decimal',
    },
    package_info: {
        package_info: 'Package info',
        name: 'Name',
        price: 'Price',
        period_val: 'Period',
        period_type: 'Period type',
        description: 'Description',
        package_info_section: 'Package info section',
        new_package_info: 'New Package info',
        from_date: 'From date',
        to_date: 'To date',
        added_by: 'Added by',
        created_at: 'Created at',
        credit_type: 'Credit type',
        credit_amount: 'Credit amount',
        package_details: 'Package details',
    },
    sales_commission: {
        sales_commission: 'Sales commission',
        commission_name: 'Commission name',
        commission_period: 'Commission period',
        commission_calculation: 'Commission calculation',
        employee: 'Employees',
        currency: 'Currency',
        target_type: 'Target type',
        target_revenue: 'Target revenue',
        target_volume: 'Target volume',
        target_value: 'Target value',
        notes: 'Notes',
        applied_to_the_following_items: 'Applied to the following items',
        type: 'Type',
        type_name: 'Type name',
        commission_percent: 'commission percent',
        category: 'Category',
        item: 'Item',
        add_sales_commission: 'Add sales commission',
        sales_commission_section: 'Sales commissions section',
    },
    sales_periods: {
        sales_periods: 'Sales periods',
        from_date: 'From date',
        to_date: 'To date',
        employee: 'Employees',
        sales_commission: 'Sales commission',
        sales_periods_section: 'Sales periods section',
        add_sales_period: 'Add sales period',
        commission_period: 'Commission period',
        employee_selection: 'Employee selection',
        rules_selection: 'Rules selection',
        total_commission: 'Total commission',
    },
    sales_commission_details: {
        id: 'ID',
        sales_commission_details: 'Sales commission details',
        sales_amount: 'Sales amount',
        invoice: 'Invoice',
        sales_quantity: 'Sales quantity',
        transaction_date: 'Transaction date',
        total_commission: 'Total commission',
        sales_commission: 'Sales commission',
        sales_period: 'Sales period',
        list_of_the_items: 'List of the items',
        item: 'Item',
        unit_price: 'Unit price',
        qty: 'Quantity',
        subtotal: 'Subtotal',
        total: 'Total',
        total_quantity: 'Total quantity',
        sales_commission_details_section: 'Sales commission details section',
        employee: 'Employee',
        from_date: 'From date',
        to_date: 'To date',
        transaction_type: 'Transaction type',
        sales_invoices: 'Sales invoices',
        sales_credit: 'Sales credit',
        transaction: 'Transaction',
    },
    chart_accounts: {
        chart_accounts: 'Chart of accounts',
        name: 'Name',
        account_parent: 'Account parent',
        serial_from: 'Serial from',
        serial_to: 'Serial to',
        natural_account: 'Natural account',
        final_account: 'Final account',
        type_account: 'Type account',
        currency: 'Currency',
        custom_type: 'Custom type',
        custom_type_name: 'Custom type name',
        amount: 'Amount',
        notes: 'Notes',
        the_account_is_empty: 'The account is empty',
        new_chart_account: 'New chart account',
        main: 'Main',
        the_old_account_contains_a_value: 'The old account contains a value. When confirming the creation of the new account, the value will be transferred from the old account to the new account',
        code: 'Code',
        chart_accounts_settings: 'Chart of accounts settings',
        inventory: 'Inventory',
        branch: 'Branch',

    },
    journal_entries: {
        journal_entries: 'Journal entries',
        journal_date: 'Journal date',
        currency: 'Currency',
        serial_no: 'Serial no',
        upload_attachment: 'Upload attachment',
        notes: 'Notes',
        is_active: 'Is active',
        journals_details: 'Journals details',
        account_name: 'Account name',
        description: 'Description',
        debit: 'Debit',
        credit: 'Credit',
        total: 'Total',
        the_result_of_subtracting_credit_and_debit_should_be: 'The result of subtracting credit and debit should be 0',
        document_type: 'Document type',
        transaction_id: 'Transaction ID',
        group_account: 'Accounts group',
        secondary_account: 'Secondary account',
        operation: 'Operation',
        debit_credit: 'Debit / Credit',
        amount: 'Amount',
        local_credit: 'Local credit',
        local_debit: 'Local debit',
        after_amount: 'After amount',
        journal_type: 'Journal type',
        the_complex_type_use_3_row_at_lest: 'The complex type use 3 row at lest',
        the_opening_type_use_1_row_at_lest: 'The opening type use 1 row at lest',
        the_simple_type_use_only_2_rows: 'The simple type use only 2 rows',
        accounts_must_be_entered_in_the_entries_details: 'Accounts must be entered in the entries details',
        currency_must_be_entered_in_the_entries_details: 'Currency must be entered in the entries details',
        branch: 'Branch',
    },
    assets_info: {
        assets_info: 'Assets info',
        asset_details: 'Asset details',
        name: 'Name',
        purchase_info: 'Purchase info',
        status: 'Status',
        purchase_date: 'Purchase date',
        in_service_date: 'In service date',
        category_account: 'Category account',
        location: 'Location',
        useful_life: 'Useful life',
        employee: 'Employee',
        notes: 'Notes',
        purchase_amount: 'Purchase amount',
        cash_account: 'Cash account',
        tax_1: 'Tax',
        tax_2: 'Tax 2',
        salvage_value: 'Salvage value',
        depreciation_method: 'Depreciation method',
        straight_line: 'Straight line',
        fixed_depreciation_value: 'Fixed depreciation value',
        declining_balance: 'Declining balance',
        period: 'Period',
        period_type: 'Period type',
        depreciation_percentage: 'Depreciation percentage',
        unit_of_production: 'Unit of production',
        cost_per_unit: 'Cost per unit',
        unit_name: 'Unit name',
        depricated_end_date: 'Depreciated end date',
        assest_total_unit: 'Assets total unit',
        assets_info_section: 'Assets info section',
        from_date: 'From date',
        to_date: 'To date',
        id: 'ID',
        account_name: 'Account name',
        amount: 'Amount',
        currency: 'Currency',
        refund_receipt_details: 'Refund receipt details',
        add_assets_info: 'Add assets info',
    },
    accounts_routing: {
        accounts_routing: 'Accounts routing',
        purchases_accounts_routing: 'Purchases accounts routing',
        account: 'Account',
        routing_type: 'Routing type',
        suppliers: 'Suppliers',
        purchases: 'Purchases',
        return_purchase: 'Return purchase',
        sales_accounts_routing: 'Sales accounts routing',
        clients: 'Clients',
        sales: 'Sales',
        returns: 'Returns',
        discount_allowed: 'Discount allowed',
        product_sales_account: 'Product sales account',
        adjustment_routing: 'Adjustment routing',
        inventory_accounts_routing: 'Inventory accounts routing',
        treasury_accounts_routing: 'Treasury accounts routing',
        payable_cheque_account: 'Payable cheque account',
        receivable_cheque_account: 'Receivable cheque account',
        requisition_journal_settings: 'Requisition Journal Settings',
        inbound_requisition: 'Inbound Requisition',
        outbound_requisition: 'Outbound Requisition',
        stocktaking_outbound: 'Stocktaking Outbound',
        stocktaking_inbound: 'Stocktaking Inbound',
        invoice: 'Invoice',
        refund_receipt: 'Refund Receipt',
        credit_note: 'Credit Note',
        pos_shift_outbound: 'POS Shift Outbound',
        pos_shift_inbound: 'POS Shift Inbound',
        expenses: 'Expenses',
        incomes: 'Incomes',
        financial_accounts_routing: 'Financial accounts routing',
        sales_credit: 'Sales credit',
        banks_account: 'Bank account',
        incomes_expenses_account: 'Expenses and Income Transactions',
        tax_sales: 'Sales tax',
        tax_purchase: 'Purchase tax',
        discount_purchase: 'Purchase discounts',
    },
    cost_centers: {
        cost_centers: 'cost centers',
        main: 'Main',
        this_parent_cost_center_is_empty_without_childs: 'This parent cost center is empty without childs',
        new_chart_account: 'New cost center',
        name: 'Name',
        code: 'Code',
        debit: 'Debit',
        credit: 'Credit',
        sales_invoice: 'Sales invoice',
        parent_cost_center: 'Parent cost center',
        is_parent_cost_center: 'Is Parent Cost Center?',
    },
    clients_loyalty: {
        clients_loyalty: 'Customers loyalty',
        credit_types: 'Credit type',
        currency: 'Currency',
        conversion_factor: 'Conversion factor',
    },
    loyalty_rules: {
        loyalty_rules: 'Loyalty rules',
        name: 'Name',
        categories: 'Categories',
        proiority: 'Priority',
        collection_factor: 'Collection factor',
        minimum_total_spent: 'Minimum total spent',
        currency: 'Currency',
        expired_after: 'Expired after',
        interval_type: 'Interval type',
        loyalty_rules_section: 'Loyalty rules section',
        new_loyalty_rule: 'New loyalty rule',
        from_minimum_total_spent: 'From minimum total spent',
        to_minimum_total_spent: 'To minimum total spent',
    },
    installment: {
        installment: 'Installment',
        client_no: 'Customer no',
        client_name: 'Customer name',
        client_email: 'Customer email',
        invoice_no: 'Invoice no',
        installment_agreement_amount: 'Installment agreement amount',
        installment_account: 'Installment count',
        installment_amount: 'Installment amount',
        currency: 'Currency',
        period_installment: 'Period installment',
        installment_start_date: 'Installment start date',
        notes: 'Notes',
        installment_agreements_section: 'Installment agreements section',
        installment_id: 'Installment ID',
        from_installment_due_date: 'From installment due date',
        to_installment_due_date: 'To installment due date',
        client: 'Client',
        invoice: 'Invoice',
        id_serial_no: 'ID serial no',
        client_info: 'Customer info',
        payment_details: 'Payment details',
        due_date: 'Due date',
        installments_list: 'Installments list',
        installment_agreements: 'Installment Agreements',
        paid_amount: 'Paid amount',
        installment_pay: 'Pay installment',
        payment_method: 'Payment method',
        amount: 'Amount',
        payment_date: 'Payment date',
        payment_status: 'Payment status',
        collected_by: 'Collected by',
        ref_no: 'Ref no',
        receipt_notes: 'Receipt notes',
        attachment: 'Attachment',
        treasury: 'Treasury',
        pay: 'Pay',
        unpaid_amount: 'Unpaid amount',
        installment_payments: 'Installment payments',
        installment_payments_section: 'Installments payments section',
    },
    cost_center: {
        add_sales_invoice_to_cost_center: 'Add sales invoice to cost center',
        account_id: 'Account ID',
        account_name: 'Account name',
        debit: 'Debit',
        credit: 'Credit',
        cost_center: 'Cost center',
        percentage: 'Percentage',
        amount: 'Amount',
        total: 'Total',
    },
    attachment_notes: {
        attachment_notes: 'Add Notes / Attachments',
        note_date: 'Note date',
        note_time: 'Note time',
        action_perform: 'Action perform',
        notes: 'Notes',
        share_with_client: 'Share with client',
        upload_attachment: 'Upload attachment',
        file: 'File',
        date: 'Date',
        name: 'Name',
    },
    invoice_sales_print: {
        simplified_tax_invoice: 'Simplified invoice',
        invoice_to: 'Invoice to',
        invoice_number: 'Invoice number',
        invoice_date: 'Invoice date',
        item: 'Item',
        price: 'Price',
        quantity: 'Quantity',
        total: 'Total',
        sub_total: 'Sub total',
        value_added: 'Value added',
        zero: 'Zero',
        balance_due: 'Balance due',
        credit_note: 'Credit note',
        to: 'To',
        credit_note_number: 'Credit note number',
        credit_note_date: 'Credit note date',
        estimate_price: 'Estimate price',
        estimate_number: 'Estimate note number',
        estimate_date: 'Estimate note date',
        sales_refund: 'Sales refund',
        invoice_refund_no: 'Refund number',
        invoice_refund_date: 'Refund date',
        total_discount: 'Total Discount',
        total_shipping: 'Total Shipping',
        payment_amount: 'Payment Amount',
        invoice_total_before_taxes: 'Total before taxes',
        invoice_total_taxes: 'Total tax',
        discount: 'Discount',
        tax_rate: 'Tax rate',
        tax_value: 'Tax value',
        the_total_includes_VAT: 'The total includes VAT',
        VAT_registration_number: 'VAT registration number',
        VAT: 'VAT',
        price_including_tax: 'Price including tax',
        notes: 'Notes',
        issue_date: 'Issue date',
        invoice_code: 'Invoice code',
        unit: 'Unit',
    },
    invoice_sales_packing: {
        invoice_number: 'Invoice',
        item: 'Item',
        description: 'Description',
        quantity: 'Quantity',
        total: 'Total',
        ship_to: 'Ship to',
        bill_to: 'Bill To',
    },
    invoice_purchase: {
        purchase_order: 'Purchase order',
        purchase_refund: 'Purchase refund',
        refund_date: 'Refund date',
        refund_number: 'Refund Number',
        supplier: 'Supplier',
        due_date: 'Due date',
        item: 'Item',
        description: 'Description',
        unit_price: 'Unit price',
        quantity: 'Quantity',
        subtotal: 'Subtotal',
        total: 'Total',
        value_added: 'Value added',
        shipping: 'Shipping',
        purchase_invoice: 'Purchase invoice',
        paid: 'Paid',
        balance_due: 'Balance due',
        p_o_invoice: 'P.O number',
    },
    pos_session: {
        total_credit: 'Total credit notes',
        total_invoices: 'Total invoices',
        total_refund: 'Total refund',
        total_cash_movements_in: 'Total cash movements (add)',
        total_cash_movements_out: 'Total cash movements (take)',
        cash_categories: 'Cash categories',
        treasury_name: 'Treasury name',
        pos_session: 'POS session',
        session: 'Session',
        cashier: 'User',
        POS_information: 'POS information',
        shift: 'Shift',
        device: 'Device',
        client: 'Client',
        currency: 'Currency',
        session_information: 'Session information',
        opened_date: 'Opened date',
        closed_date: 'Closed date',
        pos_sessions: 'POS sessions',
        pos_sessions_section: 'POS sessions section',
        new_pos_session: 'New pos session',
        pos_device: 'POS device',
        pos_shift: 'POS shift',
        opened_from_date: 'Opened from date',
        opened_to_date: 'Opened to date',
        closed_from_date: 'Closed from date',
        closed_to_date: 'Closed to date',
        name: 'Name',
        shift_cashier: 'Shift cashier',
        opening_closing: 'Opening closing',
        session_sales_amount: 'Session sales amount',
        payment_method: 'Payment method',
        received_amount: 'Received amount',
        expected_amount: 'Expected amount',
        difference: 'Difference',
        received_type: 'Received type',
        employee: 'Employee',
        treasury: 'Treasury',
        please_select_shift: 'Please select shift',
        invoice_info: 'Invoice info',
        create_date: 'Create date',
        amount: 'Amount',
        invoices: 'Invoices',
        session_id: 'Session ID',
        staff: 'Staff',
        total: 'Total',
        print: 'Print',
        pdf: 'PDF',
        summary_by_payment_method: 'Summary by payment method',
        sales_details_by_payment_method: 'Sales details by payment method',
        sales: 'Sales',
        refunds: 'Refunds',
        net: 'Net',
        cash_movements: 'Cash Movements',
        time: 'Time',
        from_to: 'From/To',
        note: 'Note',
        notes: 'Notes',
        from_type: 'From type',
        cash_movement: 'Cash movement',
        date_time: 'Date time',
        add_cash_in: 'Add cash in',
        add_cash_out: 'Add cash out',
        take_cash_out: 'Take cash out',
        cash_movement_section: 'Cash movement section',
        client_payment: 'Customer payment',
        invoice: 'Invoice',
        payment_receipt: 'Payment receipt',
        payment_info: 'Payment info',
        amount_currency: 'Amount/Currency',
        summary_by_payment_methods: 'Summary by Payment Methods',
        payment_for: 'Payment for',
        cash_amount: 'Cash amount',
        transaction_date: 'Transaction date',
        added_by: 'By',
        balance: 'Balance',
        balances: 'Balances',
        confirmed_amount: 'Confirmed amount',
        details_currency: 'Details currency',
        details_other_currencies: 'Details other currencies',
        cash_category: 'Cash category',
        cash_category_no: 'Cash category number',
        cash_category_amount: 'Cash category amount',
        store: 'Store',
        user: 'User',
        statistics: 'Statistics',
        payments: 'Payments',
        refunds_credit_note: 'Refunds and Credit note',
        count: 'Count',
        total_total: 'Total',
        credit_note: 'Credit notes',
        total_discounts: 'Total discounts',
        cash_movements_in: 'Cash movements in',
        cash_movements_out: 'Cash movements out',
        payment_details: 'Payment details',
        cash: 'Cash',
        card: 'Card',
        bank: 'Bank',
        total_tax: 'Total taxes',
        invoice_code_from: 'First invoice code',
        invoice_code_to: 'Last invoice code',
    },
    point_of_sales: {
        payment: 'Payment',
        add_cash_in: 'Add cash in',
        add_cash_out: 'Add cash out',
        subTotal: 'SubTotal',
        refund_discount: 'Refund Discount',
        discount: 'Discount',
        shipping: 'Shipping',
        tax: 'Tax',
        refund_rotal: 'Total refund amount',
        total: 'Total',
        qty: 'Qty',
        dis: 'Dis',
        price: 'Price',
        remove: 'Remove',
        return: 'Return',

        zero: 'Zero',
        recovery: 'Recovery',
        net_payable: 'Net Payable',
        paid: 'Paid',
        change: 'Refund',
        with_discount: 'With Discount',
        view_session: 'View session',
        previous_invoice_total: 'Previous Invoice Total',
        total_current_bill_amount: 'Total current invoice amount',
        total_amount_before_return: 'Total amount before refund',
        new_order: 'New order',
        cleaning: 'In progress',
        ready: 'Collected orders',
        pickups: 'Ready orders',
        search: 'Search',
        invoices: 'Invoices',
        express: 'Express',
        notes: 'Notes',
        save: 'Save',
        quick_drop: 'Quick drop',
        retail: 'Retail',
        submit_wed: 'Submit',
        edit_item_notes: 'Edit Item Notes',
        color: 'Color',
        damage: 'Damage',
        stains: 'Stains',
        upcharge: 'Upcharge',
        pricing: 'Pricing',
        pricing_information: 'Pricing information',
        confirm: 'Confirm',
        received: 'Received',
        delivery: 'Delivery',
        item: 'Item',
        refund_amount: 'Refund amount',
        details: 'Details',
        customer_name: 'Customer name',
        mobile: 'Mobile',
        status: 'Status',
        invoice_code: 'Invoice code',
        quantity: 'Quantity',
        invoice_created_date: 'Invoice created date',
        invoice_received_date: 'Invoice received date',
        filter_for_details: 'Filter for details',
        customer: 'Customer',
        by_date: 'By date',
        the_most_recent: 'The most recent',
        the_oldest: 'The oldest',
        today: 'Today',
        last_3_days: 'Last 3 days',
        last_month: 'Last month',
        code: 'Code',
        attached_reasons: 'Attached reasons',
        fabrics_problems: 'Fabrics problems',
        reason: 'Reason',
        fabric_problem: 'Fabric problem',
        type: 'Type',
        item_label: 'Item',

    },
    pos_invoice: {
        invoice_info: 'Invoice info',
        date_time: 'Date time',
        amount_currency: 'Amount/currency',
        pos_client: 'POS client',
        added_payment: 'Added payment',
        amount: 'Amount',
        currency: 'Currency',
        attachment: 'Attachment',
        add_payment: 'Add payment',
        pdf: 'PDF',
        image: 'Image',
        view: 'View',
        payment_info: 'Payment info',
        amount_and_status: 'Amount / status',
        invoice_receipt: 'Invoice Receipt',
        pos_shift: 'POS shift',
        payments_for_invoice: 'Payments for invoice',
        payment_summary: 'Payment Summary',

        invoice_no: 'Invoice No',
        invoice_total: 'Invoice Total',
        refunded: 'Refunded',
        paid_amount: 'Paid Amount',
        unpaid_amount: 'Unpaid Amount',
        invoice_id: 'Invoice ID',
    },
    pos_refund: {
        refund_info: 'Refund info',
        date_time: 'Date time',
        amount_currency: 'Amount/currency',
        pos_client: 'POS client',
        added_payment: 'Added payment',
        amount: 'Amount',
        currency: 'Currency',
        attachment: 'Attachment',
        add_payment: 'Add payment',
        pdf: 'PDF',
        image: 'Image',
        view: 'View',
        payment_info: 'Payment info',
        amount_and_status: 'Amount / status',
        refund_receipt: 'Refund Receipt',
        pos_shift: 'POS shift',
        payments_for_invoice: 'Payments for invoice',
        payment_summary: 'Payment Summary',

        invoice_no: 'Invoice No',
        invoice_total: 'Invoice Total',
        refunded: 'Refunded',
        paid_amount: 'Paid Amount',
        unpaid_amount: 'Unpaid Amount',
    },

    payment_print: {
        receipt: 'Receipt',
    },
    cost_transactions: {
        transactions: 'Transactions',
        transaction: 'Transaction',
        transactions_section: 'Transactions section',
        debit: 'Debit',
        credit: 'Credit',
        accounts: 'Accounts',
        cost_centers: 'Cost centers',
        from_date: 'From date',
        to_date: 'To date',
    },
    accounts_without_cost_center: {
        accounts_without_cost_center: 'Accounts without cost center',
        assign_cost_centers: 'Assign cost centers',
        cost_center: 'Cost center',
        percentage: 'Percentage',
        auto: 'Auto',
        accounts_without_cost_center_section: 'Accounts without cost center section',
        account: 'Account',
        assign_cost_center: 'Assign cost center',
        id: 'ID',
        name: 'Name',
        code: 'Code',
    },
    accounts_with_cost_center: {
        accounts_with_cost_center_section: 'Accounts with cost center section',
        journal_account_id: 'Journal account id',
        journal_account: 'Journal account',
        journal_account_code: 'Journal account code',
        cost_center: 'Cost center',
        center_code: 'Center code',
        percentage: 'Percentage',
    },
    cost_center_transactions: {
        cost_center_transactions: 'Cost center transactions',
        transaction: 'Transaction',
        cost_center_transactions_section: 'Cost center transactions section',
        debit: 'Debit',
        credit: 'Credit',
        accounts: 'Accounts',
        cost_centers: 'Cost centers',
        cost_center: 'Cost center',
        from_date: 'From date',
        to_date: 'To date',
        amount: 'Amount',
        summary: 'Summary',
        code: 'Code',
        parent_cost_centers: 'Parent cost centers',
        secondary_cost_centers: 'Secondary cost centers',
        balance: 'Balance',
    },
    user_settings: {
        change_email_password: 'Change email & password',
        change_email: 'Change email',
        current_password: 'Current password',
        email: 'Email',
        new_password: 'New password',
        confirm_password: 'Confirm password',
        change_password: 'Change password',
    },
    daily_brief_report_invoices: {
        daily_brief_report_invoices: 'Daily brief report to invoices',
        daily_brief_report_payments: 'Daily brief report to payments',
        customer: 'Customer',
        from_date: 'From date',
        to_date: 'to date',
        added_by: 'added by',
        show_report: 'show report',
        summary: 'Summary',
        date: 'Date',
        paid: 'Paid',
        unpaid: 'Unpaid',
        refund: 'Refund',
        total: 'Total',
        details: 'Details',
        number: 'Number',
        employee: 'Employee',
        ref_no: 'Ref no.',
        collected_by: 'Collected by',
        invoice_number: 'Invoice no.',
        payment_method: 'Payment method',
    },
    stocks_report: {
        stocks_report: 'Stocks report',
        group_by: 'Group by',
        category: 'Category',
        brand: 'Brand',
        order_by: 'Order by',
        product_name: 'Product name',
        stock_level_descending: 'Stock level descending',
        stock_level_ascending: 'Stock level ascending',
        hide_zero_values: 'Hide zero values for suppliers who do not have a balance',
        total_count: 'Total count',
        generate_sheet: 'Generate sheet',
        code: 'Code',
        name: 'Name',
        software_count: 'Software count',
        barcode: 'Barcode',
        warehouse: 'Warehouse',
    },
    inventory_transaction_report: {
        inventory_transaction_report: 'Inventory transaction report',
        product_name: 'Product name',
        from_date: 'From date',
        to_date: 'To date',
        type: 'Type',
        refund_receipt: 'Refund receipt',
        sales_invoice: 'Sales invoice',
        purchase_invoice: 'Purchase invoice',
        inventory: 'Inventory',
        product: 'Product',
        primary_warehouse_transactions_summary: 'Primary warehouse transactions summary',
        inward: 'Inward',
        outward: 'Outward',
        purchase_invoices: 'Purchase invoices',
        refund_receipts: 'Refund receipts',
        transfer: 'Transfer',
        manual: 'Manual',
        total: 'Total',
        sales_invoices: 'Sales invoices',
        purchase_refund: 'Purchase refund',
        total_transactions: 'Total transactions',
        sales_refund: 'Sales refund',
        credit_note: 'Credit note',
        inventory_transaction_details: 'Inventory transaction details',
        inventory_detailed_transactions: 'Inventory Detailed Transactions',
        time: 'Time',
        warehouse: 'Warehouse',
        stock_level_after: 'Stock level after',
        inventory_transactions: 'Inventory transactions',
        receipt_document: 'Receipt document',
        sku_code: 'SKU code',
        branch: 'Branch',
        category: 'Category',
        stock_level_before: 'Stock level before',
    },

    estimated_inventory: {
        estimated_inventory: 'Estimated inventory value',
        code: 'Code',
        product_name: 'Product name',
        qty: 'Quantity',
        current_retail_price: 'Current Retail Price',
        average_retail_price: 'Average Buy Price',
        expected_total_retail_price: 'Expected sales value',
        total_buy_price: 'Purchase value',
        expected_profit: 'Expected Profit',
        calculate_using: 'Calculate using',
        average_buy_price: 'Average Buy Price',
        buy_price: 'Buy Price',
    },

    summary_stock_balance: {
        summary_stock_balance: 'Summary stock balance',
        code: 'Code',
        product_name: 'Product name',
        category: 'Category',
        brand: 'Brand',
        total: 'Total',
    },
    product_average_cost: {
        product_average_cost: 'Product average cost',
        barcode: 'Barcode',
        code: 'Code',
        product_name: 'Product name',
        qty: 'Quantity',
        before_quantity_available_warehouse: 'Before the quantity available in the warehouse at the beginning of the term',
        inward_quantity_entering_warehouses: 'Inward Quantity Entering Warehouses',
        outward_quantity_coming_warehouses: 'Outward Quantity Coming Out of Warehouses',
        next_entrance_exit: 'Next Entrance - Exit',
        inventory: 'Inventory',
        before: 'Before',
        incoming: 'Incoming',
        the_outgoing: 'The outgoing',
        the_net: 'The net',
    },
    daily_product_sales: {
        daily_product_sales: 'Daily product sales',
        daily_product_sales_details: 'Daily product sales details',
        day: 'Day',
        qty: 'Quantity',
        discount: 'Discount',
        price: 'Price',
        SalesInvoice: 'Sales Invoice',
        SalesInvoiceRefund: 'Sales Invoice Refund',
        show_draft: 'Show Draft',
        proudect_code: 'Product Code',
        sold_by: 'Sold By',
        invoice_no: 'Invoice No',
        client: 'Client',
        unit_price: 'Unit Price',
        number: 'Number',
        code: 'Code',
        branch: 'Branch',
        inventory: 'Inventory',
        quantity: 'Quantity',
        total_amount: 'Total amount',
        subtotal_after_tax: 'Subtotal after tax',
        subtotal_before_tax: 'Subtotal before tax',
        total_tax: 'Total tax',
        item: 'Item',
        sales_qty: 'Sales quantity',
        sales_price: 'Sales price',
        refund_qty: 'Refund quantity',
        refund_price: 'Refund price',
        total_price: 'Total price',
    },
    store_reports: {
        daily_payments: 'Daily Payments',
        inventory_stocktaking_sheet: 'Inventory Stocktaking Sheet',
        inventory_transactions: 'Inventory Transactions',
        weekly_payments: 'Weekly Payments',
        inventory_detailed_transactions: 'Inventory Detailed Transactions',
        monthly_payments_details: 'Monthly Payments details',
        yearly_payments_details: 'Yearly Payments details',
        inventory_value: 'Inventory Value',
        summary_of_stock_balance: 'Summary of stock balance',
        product_average_cost: 'Product average cost',
        product_sales_Periodic_Reports: 'Product sales Periodic Reports',
        detailed_stock_transactions_per_product: 'Detailed Stock Transactions Per Product',
    },

    client_reports: {
        client_sales: 'Customer Sales',
        aged_debtors: 'Aged Debtors',
        clients_list: 'Customers List',
        clients_balance: 'Customers Balance',
        clients_sales: 'Customers Sales',
        clients_payments: 'Customers Payments',
        clients_statement: 'Customers Statement',
        clients_appointments: 'Customers Appointments',
        clients_installments: 'Customers Installments',
    },
    customer_appointment_report: {
        customer_appointment_report: 'Customer appointments report',
        customer: 'Client',
        date: 'Date',
        start_time: 'Start time',
        interval: 'Interval',
        procedure: 'Procedure',
        recurring_type: 'Recurring type',
        recurring_date: 'Recurring date',
        notes: 'Notes',
        status: 'Status',
        start_at: 'Start at',
        appintment_date: 'Appointment date',
        assigned_staff: 'Assigned staff',
        appointments_report_group_by_all: 'Appointments Report - Group By All',
    },

    customer_installment_report: {
        clients_installments_group_by_client: 'Customers installments group by client',
        client: 'Client',
        email: 'Email',
        address: 'Address',
        mobile: 'Mobile',
        phone: 'Phone',
        due_date: 'Due date',
        status: 'Status',
        total_amount: 'Total amount',
        paid_amount: 'Paid amount',
        remaining_amount: 'Remaining amount',
    },
    customer_sales_report: {
        customer_sales_report: 'Customer sales report',
        customer: 'Client',
        from_date: 'From date',
        to_date: 'To date',
        invoice: 'Invoice',
        date: 'Date',
        type: 'Type',
        name: 'Name',
        document_number: 'Document number',
        shipping_fees: 'Shipping fees',
        value: 'Value',
        discounts: 'Discounts',
        taxes: 'Taxes',
        total: 'Total',
    },
    customer_payments_report: {
        customer_payments_report: 'Customer payments report',
        customer: 'Client',
        from_date: 'From date',
        to_date: 'To date',
        date: 'Date',
        type: 'Type',
        name: 'Name',
        document_number: 'Document number',
        total: 'Total',
        id: 'ID',
        client_number: 'Customer Code',
        client_name: 'Customer name',
        payment_method: 'Payment method',
        treasury: 'Treasury',
        treasury_bank: 'Treasury / Bank',
        amount: 'Amount',
        assigned_staff: 'Assigned staff',
        employee: 'Employee',
        currency: 'Currency',
    },
    customer_balance_report: {
        customer_balance_report: 'Customer balance report',
        customer: 'Client',
        assigned_staff: 'Assigned staff',
        balances_report_group_by_all: 'Balances Report - Group By All',
        code: 'Code',
        account_no: 'Account no',
        name: 'Name',
        manual_status: 'Manual status',
        debit_before: 'Debit before',
        debit_after: 'Debit after',
        balance_after: 'Balance after',
        balance_before: 'Balance before',
        total_sales: 'Total sales',
        total_refund: 'Total refund',
        net_sales: 'Net sales',
        total_payments: 'Total payments',
        balance: 'Balance',
        phone: 'Phone',
        address: 'Address',
        category: 'Category',
        hide_zero_balance: 'Hide zero balance',
        show_details: 'Show details',
        employee: 'Employee',
        settlements: 'Settlements',
    },
    aged_debtors: {
        aged_debtors: 'Aged debtors',
        customer: 'Customer',
        from_date: 'From date',
        client: 'Client',
        not_overdue: 'Not overdue',
        f_0_to_30_day: '0 to 30 day',
        f_31_to_60_day: '31 to 60 day',
        f_61_to_90_day: '61 to 90 day',
        f_91_to_120_day: '91 to 120 day',
        more_than_121_day: '121+ day',
        sum_over_due: 'Total arrears',
        total: 'Total',
    },
    sales_by_client_report: {
        sales_by_client_report: 'Sales report',
        sales_revenue_report: 'Revenue report',
        customer: 'Customer',
        from_date: 'From date',
        to_date: 'To date',
        added_by: 'Added by',
        show_report: 'Show report',
        date: 'Date',
        paid: 'Paid',
        unpaid: 'Unpaid',
        refund: 'Refund',
        total: 'Total',
        number: 'Number',
        employee: 'Employee',
        invoice_number: 'Invoice no.',
        sales_man: 'Sales man',
        day: 'Day',
        week: 'Week',
        month: 'Month',
        year: 'Year',
        tax: 'Tax',
        branch: 'Branch',
        payment_status: 'Payment status',
        invoice_type: 'Document type',
        ref: 'reference',
    },
    payments_sales_report: {
        class_name: 'Document type',
        branch: 'Branch',
        payments_sales_report: 'Payments report',
        customer: 'Customer',
        from_date: 'From date',
        to_date: 'To date',
        added_by: 'Added by',
        show_report: 'Show report',
        date: 'Date',
        paid: 'Paid',
        unpaid: 'Unpaid',
        refund: 'Refund',
        total: 'Total',
        number: 'Number',
        employee: 'Employee',
        invoice_number: 'Invoice no.',
        sales_man: 'Sales man',
        day: 'Day',
        week: 'Week',
        month: 'Month',
        year: 'Year',
        payment_method: 'Payment method',
        collected_by: 'Collected by',
        id: 'number',
        payment_id: 'Payment ID',
        invoice_code: 'Invoice code',
        ref_no: 'Ref No.',
        label: 'Label',
        payment_code: 'Payment code',
        paid_amount: 'Paid amount',
    },
    suppliers_reports: {
        suppliers_reports: 'Suppliers reports',
        country: 'Country',
        city: 'City',
        supplier_code: 'Supplier code',
        full_name: 'Full name',
        address: 'Address',
        mobile: 'Mobile',
        phone: 'Phone',
        credit_min: 'Minimum credit',
        credit_max: 'Maximum credit',
        debit_min: 'Minimum debit',
        debit_max: 'Maximum debit',
        supplier_balance: 'Supplier balance',
        hide_zero_values: 'Hide zero values for suppliers who do not have a balance',
        branch: 'Branch',
        business_no: 'Business No.',
        net_purchase: 'Net purchase',
    },
    supplier_balance_report: {
        supplier_balance_report: 'Supplier balance report',
        supplier: 'Supplier',
        assigned_staff: 'Assigned staff',
        balances_report_group_by_all: 'Balances Report - Group By Supplier',
        code: 'Code',
        account_no: 'Account no',
        name: 'Name',
        balance_before: 'Balance before',
        total_purchase: 'Total purchase',
        total_refund: 'Total refund',
        net_purchase: 'Net purchase',
        total_payments: 'Total payments',
        balance: 'Balance',
        phone: 'Phone',
        address: 'Address',
        settlements: 'Settlements',

        supplier_code: 'Supplier code',
        branch: 'Branch',
        supplier_balance: 'Supplier balance',
    },
    supplier_purchase_report: {
        supplier_purchase_report: 'Supplier purchase report',
        supplier: 'Supplier',
        from_date: 'From date',
        to_date: 'To date',
        invoice: 'Invoice',
        date: 'Date',
        type: 'Type',
        name: 'Name',
        document_number: 'Document number',
        value: 'Value',
        discounts: 'Discounts',
        taxes: 'Taxes',
        total: 'Total',
        currency: 'Currency',
        branch: 'Branch',
        total_total: 'Total',
        document_type: 'Document type',
        document_date: 'Document date',
        net: 'Net',
        paid: 'Paid',
        remaining: 'Remaining',
        refund: 'Refund',
    },
    purchase_report: {
        purchase_report: 'Purchases report',
        supplier: 'Supplier',
        from_date: 'From date',
        to_date: 'To date',
        added_by: 'Added by',
        show_report: 'Show report',
        date: 'Date',
        paid: 'Paid',
        unpaid: 'Unpaid',
        refund: 'Refund',
        total: 'Total',
        number: 'Number',
        employee: 'Employee',
        user: 'Employee',
        invoice_number: 'Invoice no.',
        day: 'Day',
        week: 'Week',
        month: 'Month',
        year: 'Year',
        suppliers: 'Suppliers',
        document: 'Document',
        payment_status: 'Document payment status',
    },
    product_purchase_report: {
        product_purchase_report: 'Product purchase report',
        supplier: 'Supplier',
        from_date: 'From date',
        to_date: 'To date',
        invoice: 'Invoice',
        date: 'Date',
        type: 'Type',
        name: 'Name',
        document_number: 'Document number',
        value: 'Value',
        discounts: 'Discounts',
        taxes: 'Taxes',
        total: 'Total',
        currency: 'Currency',
        id: 'ID',
        item: 'Item',
        product_code: 'Product code',
        added_by: 'Employee',
        unit_price: 'Unit price',
        total_taxes: 'Total taxes',
        quantity: 'Quantity',
        product: 'Product',
        employee: 'Employee',
        description: 'Description',
        invoice_type: 'Invoice type',
        invoice_date: 'Invoice date',
        invoice_code: 'Invoice code',
        user: 'Employee',
        day: 'Day',
        week: 'Week',
        month: 'Month',
        year: 'Year',
    },
    purchases_payments_periodic_reports: {
        purchases_payments_periodic_reports: 'Purchases payments periodic reports',
        supplier: 'Supplier',
        from_date: 'From date',
        to_date: 'To date',
        added_by: 'Employee',
        show_report: 'Show report',
        date: 'Date',
        total: 'Total',
        number: 'Number',
        employee: 'Employee',
        invoice_number: 'Invoice no.',
        day: 'Day',
        week: 'Week',
        month: 'Month',
        year: 'Year',
        payment_method: 'Payment method',
        id: 'ID',
        ref: 'Ref',
        payment_code: 'Payment code',
        user: 'Employee',
    },
    document_type_setting: {
        document_type_setting: 'Document type setting',
        document_type_setting_section: 'Document Type Setting section',
        document_type: 'Document type',
        document_name: 'Document name',
        document_slug: 'Document slug',
        status_accounting: 'Status accounting',
        columns: 'Columns',
        new_document_type_setting: 'New document type setting',
        no_journal: 'No of journal',
        column: 'Column',
        description_ar: 'Description arabic',
        description_en: 'Description english',
        description_columns: 'Description columns',
        related_tables: 'Tables',
        table: 'Table',
        table_slug: 'Table slug',
        column_slug: 'Column slug',
        account_routing: 'Account routing',
        account_slug: 'Account slug',
        is_predefined: 'Is predefined',
        is_active: 'Status',
    },
    login_page: {
        Discover_Amazing_Sajlha_with_great_build_tools: "Discover Amazing Sajlha <br />with great build tools",
        Welcome_to_Sajlha: 'Welcome to Sajlha',
        Email: 'Email',
        Password: 'Password',
        Sign_In: 'Sign In',
        Sign_Up: 'Sign Up',
        Enter_your_details_to_create_your_account: 'Enter your details to create your account',
        Submit: 'Submit',
        Cancel: 'Cancel',
        Forgotten_Password: 'Forgotten Password ?',
        Enter_your_email_to_reset_your_password: 'Enter your email to reset your password',
        Terms: 'Terms and conditions',
        Plans: 'Plans',
        Contact_Us: 'Contact Us',
        Username_is_required: 'Username is required',
        Password_is_required: 'Password is required',
        Full_name_is_required: 'Full name is required',
        Email_is_required: 'Email is required',
        The_value_is_not_a_valid_email_address: 'The value is not a valid email address',
        Confirm_password_is_required: 'Confirm password is required',
        The_password_and_its_confirm_are_not_the_same: 'The password and its confirm are not the same',
        You_should_agree_terms_and_conditions: 'You should agree terms and conditions',
        Please_provide_correct_data: 'Please, provide correct data!',
        unlimited_possibilities_to_manage_your_business: 'Unlimited possibilities to manage your business',
        we_share_your_journey: 'We share your journey',
        and_we_help_you_with_all_your_procedures: 'and we help you with all your procedures',
    },
    errors: {
        the_page_not_found: 'The page not found',
        you_dont_have_permission: 'You don\'t have permission',
    },
    journals_setting: {
        journals_setting: 'Journals setting',
        document_name: 'Document name',
        journal_title: 'Journal title',
        journal_type: 'Journal type',
        status: 'Status',
        is_active: 'Is active',
        journal: 'Journal',
        column: 'Column',
        general_account: 'General account',
        type: 'Type',
        depend_on: 'Depend on',
        special_account: 'Special account',
        columns: 'Columns',
        branch: 'Branch',
        inventory: 'Inventory',
        customer: 'Customer',
        vendor: 'Vendor',
        simple: 'Simple',
        complex: 'Complex',
        none: 'None',
        decrease: 'Decrease',
        increase: 'Increase',
        new_journals_setting: 'New journal setting',
        credit: 'Credit',
        debit: 'Debit',
        account_type: 'Account type',
        item: 'Item',
        currency: 'Currency',
    },
    currency_exchange: {
        add_currency_exchange: 'Add currency exchange',
        currency_exchange: 'Currency exchange',
        system_currency: 'System currency',
        cannot_added_currency_conversion: 'It cannot be added to currency conversion because there is no default currency for the system. Please go to the settings of the personal page and choose the currency for the system',
        currency: 'Currency',
        standard_val: 'Standard value',
        custom_val: 'Custom value',
    },
    journal_transactions: {
        source: 'Source',
        journal_account: 'Journal account',
        from_date: 'From date',
        to_date: 'To date',
        journal_transactions: 'Journal transactions',
        date: 'Date',
        number: 'Number',
        account: 'Account',
        description: 'Description',
        debit: 'Debit',
        credit: 'Credit',
        total: 'Total',
        before_amount: 'Before amount',
        after_amount: 'After amount',
        branch: 'Branch',
        journal: 'Journal',
        journal_id: 'Journal ID',
    },
    notifications: {
        user_notifications: 'User notifications',
        no_new_notifications: 'No new notifications',
    },
    notifications_config: {
        notifications_config: 'Notifications config',
        document_type: 'Document type',
        document_slug: 'Document slug',
        status: 'Status',
        action: 'Action',
        columns: 'Columns',
        interested_roles: 'Interested roles',
        interested_users: 'Interested users',
        content: 'Content',
        is_active: 'Is active',
        new_notification_config: 'New notification config',
        added_by: 'Added by',
        created_at: 'Created at',
        customer: 'Customer',
        supplier: 'Supplier',
        transaction_id: 'Transaction ID',
    },
    custom_notifications: {
        custom_notifications: 'Custom notification settings',
        item_min_qty: 'Product quantity arrived minimum quantity',
        installment_due_date: 'Due date of installment  equal current date',
        interested_roles: 'Interested roles',
    },
    inventory_checking: {
        inventory: 'Inventory',
        product: 'Product',
        available_stock_qty: 'Available quantity',
        last_validated_date: 'Last validated date',
        last_value: 'Last value',
        actual_qty: 'Actual quantity',
        qty: 'Quantity',
        inventory_checking: 'Inventory checking',
        sys_qty: 'System quantity',
        confirm_qty: 'Confirm quantity',
    },
    treasury_checking_history: {
        treasury_checking_history: 'Treasury checking history',
        treasury: 'Treasury',
        from_date: 'From date',
        to_date: 'To date',
        last_validated_date: 'Last validated date',
        currency: 'Currency',
        validator_user: 'Validator user',
        current_amount: 'Current amount',
        actual_amount: 'Actual amount',
        cash: 'Cash',
        currency_name: 'Currency name',
        last_validate_date: 'Last validate date',
        last_val: 'Last value',
        treasury_checking_amount: 'Checking amount for treasury',
        confirmed_date: 'Confirmed date',
    },
    inventory_checking_history: {
        inventory: 'Inventory',
        product: 'Product',
        available_stock_qty: 'Available quantity',
        last_validated_date: 'Last validated date',
        last_value: 'Last value',
        actual_qty: 'Actual quantity',
        qty: 'Quantity',
        inventory_checking: 'Inventory checking',
        sys_qty: 'System quantity',
        confirm_qty: 'Confirm quantity',
        from_date: 'From date',
        to_date: 'To date',
    },
    profit_loss_report: {
        period: 'Period',
        from_date: 'From date',
        to_date: 'To date',
        profit_loss_report: 'Profit loss report',
        total: 'Total',
        income: 'Income',
        sales_invoices: 'Sales invoices',
        income_total: 'Income total',
        expenses: 'Expenses',
        refund_receipts: 'Refund receipts',
        purchase_invoices: 'Purchase invoices',
        expenses_total: 'Expenses total',
        profit: 'Profit',
        monthly: 'Monthly',
        yearly: 'Yearly',
        quarterly: 'Quarterly',
    },
    income_report: {
        collected_by: 'Collected by',
        treasury: 'Treasury',
        currency: 'Currency',
        from_date: 'From date',
        to_date: 'To date',
        user: 'ُEmployee',
        income_report: 'Incomes report',
        expenses_report: 'Expenses report',
        date: 'Date',
        code: 'Code',
        category: 'Category',
        vendor: 'Vendor',
        account: 'Account',
        staff: 'Staff',
        notes: 'Notes',
        amount: 'Amount',
        total: 'Total',
        daily: 'Daily',
        weekly: 'Weekly',
        monthly: 'Monthly',
        yearly: 'Yearly',
        customer: 'Customer',
        supplier: 'Supplier',
        branch: 'Branch',
        payment_channel : 'Payment channel',
        doc_type: 'Transaction type',
        type: 'Type',
        expenses_date: 'Expenses date',
    },
    balance_sheet: {
        financial_year: 'Financial year',
        balance_sheet: 'Balance sheet',
        subtotal: 'Subtotal',
        label: 'Label',
        amount: 'Amount',
        total: 'Total',
    },
    chart_accounts_directory: {
        account_level: 'Account level',
        account_normal: 'Account type',
        account_order: 'Account order',
        chart_accounts_directory: 'Chart of account Directory',
        account_code: 'Account code',
        account_name: 'Account name',
        account_type: 'Account type',
        main_account: 'Main account',
        sub_account: 'Sub account',
        asc: 'Asc',
        desc: 'Desc',
        debit: 'Debit',
        credit: 'Credit',
        customer: 'Customer',
        supplier: 'Supplier',
        treasury: 'Treasury',
        inventory: 'Inventory',
        account_expects: 'Accounts except',
        cost_center: 'Cost center',
        consolidated: 'Consolidated',
        simplified: 'Simplified',
    },
    balance_balances_review: {
        financial_year: 'Financial year',
        account: 'Account',
        balance_balances_review: 'Balance of balances review',
        name: 'Name',
        code: 'Code',
        debit: 'Debit',
        credit: 'Credit',
        subtotal: 'Subtotal',
    },
    tax_report: {
        tax: 'Tax',
        from_date: 'From date',
        to_date: 'To date',
        tax_report: 'Tax report',
        sales: 'Sales',
        modification: 'Modification',
        due_tax: 'Due tax',
        total: 'Total',
        purchase: 'Purchase',
        others: 'Others',
        settlement_completed: 'Settlement completed',

        taxable_amount: 'Taxable amount',
        taxes: 'Taxes',
        id: 'ID',
        financier: 'Financier',
        date: 'Date',
        item: 'Item',
        description: 'Description',
        total_tax: 'Total tax',
        branch: 'Branch',
        invoice: 'Invoice',
        commercial_register: 'Commercial register',
        tax_register: 'Tax register',
    },
    financial_transactions_report: {
        currency: 'Currency',
        from_date: 'From date',
        to_date: 'To date',
        financial_transactions_report: 'Financial transactions report',
        date: 'Date',
        description: 'Description',
        amount: 'Amount',
        balance_after: 'Balance after',
        balance_beginning_period: 'The balance at the beginning of the period',
        total_revenue: 'Total revenue',
        total_expenses: 'Total expenses',
        balance_end_period: 'The end balance of the period',
    },
    items_report: {
        inventory: 'Inventory',
        product: 'Product',
        inventory_name: 'Inventory',
        item_name: 'Product',
        available_stock_qty: 'Available quantity',
        qty: 'Quantity',
        from_date: 'From date',
        to_date: 'To date',
        quantity: 'Quantity',
        trans_affect_name: 'Transaction affect',
        transaction_id: 'Transaction ID',
        items_report: 'Products report',
    },

    activity_log_report: {
        action: 'Action',
        from_date: 'From date',
        to_date: 'To date',
        user: 'User',
        activity_log_report: 'System activity log',

        create_invoice: 'Create invoice',
        add_invoice_payment: 'Add invoice payment',
        add_expense: 'Add expense',
        add_income: 'Add income',
        add_client: 'Add client',
        add_product: 'Add product',
        print_reports: 'Print reports',
        update_system_settings: 'Update system settings',
        login: 'Login',
        add_sold_stock: 'Add sold stock',
        manual_stock_adjustment: 'Manual stock adjustment',
    },
    supplier_checking: {
        supplier_checking: 'Supplier balance checking',
        add_supplier_checking: 'Add supplier balance checking',
        supplier: 'Supplier',
        from_date: 'From date',
        to_date: 'To date',
        last_validated_date: 'Last validated date',
        currency: 'Currency',
        validator_user: 'Validator user',
        credit: 'Current amount credit',
        debit: 'Current amount debit',
        actual_amount: 'Actual amount',
        cash: 'Cash',
        currency_name: 'Currency name',
        last_validate_date: 'Last validate date',
        last_val: 'Last value',
        new_credit: 'New amount credit',
        new_debit: 'New amount debit',
        created_date: 'Created date',
        confirmed_date: 'Confirmed date',
    },

    supplier_transactions_report: {
        supplier: 'Supplier',
        amount: 'Amount',
        exchange_rate: 'Exchange rate',
        doc_type: 'Doc type',
        doc_title: 'Doc title',
        balance_before: 'Balance before',
        balance_after: 'Balance after',
        wanted_amount: 'Wanted amount',
        paid_amount: 'Paid amount',
        currency: 'Currency',
        from_date: 'From date',
        to_date: 'To date',
        supplier_transactions_report: 'Supplier transactions report',
        doc_created_by: 'Created by',
        doc_created_at: 'Created at',
    },
    customer_transactions_report: {
        customer: 'Customer',
        amount: 'Amount',
        exchange_rate: 'Exchange rate',
        doc_type: 'Doc type',
        doc_title: 'Doc title',
        doc_number: 'Doc number',
        balance_before: 'Balance before',
        balance_after: 'Balance after',
        wanted_amount: 'Wanted amount',
        paid_amount: 'Paid amount',
        currency: 'Currency',
        from_date: 'From date',
        to_date: 'To date',
        customer_transactions_report: 'Customer transactions report',
        customers: 'Customers',
        branches: 'Branches',
        users: 'Users',
        invoice_type: 'Invoice type',
        code: 'Code',
        status: 'Status',
        all_customers: 'All customers',
        all_branches: 'All branches',
        all_items: 'All items',
        payment_method: 'Payment method',
        interval_type: 'Time period',
        interval_no: 'Number',
        current_date: 'Today',
        current_week: 'Current week',
        current_month: 'Current month',
        current_year: 'Current year',
        financial_year: 'Financial year',
        branch: 'Branch',
        date_time: 'Date and time',
        employee: 'Employee',
        status_paid: 'Status paid',
        inventories: 'Inventories',
        inventory: 'Inventory',
        yesterday: 'Yesterday',
        last_week: 'Last week',
        last_month: 'Last month',
        last_year: 'Last year',
    },
    items_transactions_report: {
        inventory: 'Inventory',
        product: 'Product',
        inventory_name: 'Inventory',
        item_name: 'Product',
        available_stock_qty: 'Available quantity',
        qty: 'Quantity',
        from_date: 'From date',
        to_date: 'To date',
        quantity: 'Quantity',
        trans_affect_name: 'Transaction affect',
        transaction_id: 'Transaction ID',
        items_transactions_report: 'Products transactions report',
        quantity_after: 'Quantity after',
        quantity_before: 'Quantity before',
        transaction_type: 'Transaction type',
    },
    export_import: {
        export_template: 'Export of template',
        export: 'Export',
        import_data: 'Import of data',
        upload_file: 'Upload file',
        row: 'Row',
        attribute: 'Attribute',
        errors: 'Errors',
        values: 'Values',
        select_fields: 'Select fields',
        field_in_the_system: 'Field in the system',
        corresponding_field_in_the_file: 'Corresponding field in the file',
        import_the_first_row_of_the_file: 'Import the first row of the file',
        the_file_to_be_imported: 'The file to be imported',
        specified: 'Specified',
    },
    available_features: {
        new_available_feature: 'New feature',
        new_available_sub_feature: 'New sub feature',
        name: 'Name',
        key: 'Key',
        available_features: 'Available features',
        available_sub_features: 'Available sub features',
        description: 'Description',
        sub_features: 'Sub features',
    },
    secondary_accounts_tree: {
        secondary_accounts_tree: 'Secondary accounts tree',
        add_new_account: 'Add new secondary account',
        add_new_sub_account: 'Add new sub secondary account',
        name: 'Name',
        description: 'Description',
    },
    groups_accounts: {
        name: 'Name',
        secondary_accounts: 'Secondary accounts',
        created_at: 'Created at',
        groups_accounts: 'Accounts groups',
        add_new_group: 'Add a new account group',
    },

    chart_account_list: {
        name: 'Name',
        code: 'Code',
        parent_name: 'Parent',
        amount: 'Amount',
        currency: 'Currency',
        type_account: 'Type account',
        natural_account: 'Natural account',
        custom_type: 'Custom type',
    },
    journals_entries_account: {
        date: 'Date',
        from_date: 'From date',
        to_date: 'To date',
        operation: 'Operation',
        debit: 'Debit',
        credit: 'Credit',
        balance_after: 'Balance after',
    },
    branch_config: {
        branch_config: 'Branch settings',
        type: 'Type',
        type_selected: 'Type selected',
        is_active: 'Status',
    },
    workstation: {
        add_workstation: 'Add workstation',
        name: 'Name',
        manager: 'Manager',
        from_date: 'From date',
        to_date: 'To date',
        created_at: 'Created at',
        workstation: 'Workstation',
        electricity_cost_per_hour: 'Electricity cost per hour',
        rent_cost_per_hour: 'Rent cost per hour',
        consumable_cost_per_hour: 'Consumable cost per hour',
        wages_per_hour: 'Wages per hour',
        net_rate_per_hour: 'Net rate per hour',
        work_days: 'Work days',
        is_active: 'Is active',
        working_hours: 'Working hours',
        start_time: 'Start time',
        end_time: 'End time',
    },
    quality_inspections: {
        add_quality_inspections: 'Add quality inspection',
        name: 'Name',
        from_date: 'From date',
        to_date: 'To date',
        created_at: 'Created at',
        quality_inspections: 'Quality inspections',
        quality_inspection: 'Quality inspection',
        is_active: 'Is active',
        validator_user: 'Validator user',
        user_validator: 'User validator',
        validation_date: 'Validation date',
        description: 'Description',
        attachment: 'Attachment',
        upload_attachment: 'Upload attachment',
        title: 'Title',
    },
    operation_management: {
        operation_management: 'Operation management',
        name: 'Name',
        default_workstation: 'Default workstation',
        available_workstation: 'Available workstation',
        batch_size: 'Batch size',
        quality_inspection: 'Quality inspection',
        description: 'Description',
        is_batch_size: 'Create Job Card based on Batch Size',
        is_active: 'Is active',
        is_corrective: 'Is corrective',
        operation: 'Operation',
        operation_time_minutes: 'Operation time minutes',
        add_operation: 'Add operation',
        user: 'User',
        from_date: 'From date',
        to_date: 'To date',
        created_at: 'Created at',
        sub_operations: 'Sub operations',
    },
    routing_management: {
        routing_management: 'Routing management',
        name: 'Name',
        workstation: 'Workstation',
        description: 'Description',
        is_active: 'Is active',
        operation: 'Operation',
        operations: 'Operations',
        operation_time_hour: 'Operation time per hour',
        add_routing: 'Add routing',
        user: 'User',
        from_date: 'From date',
        to_date: 'To date',
        created_at: 'Created at',
        sequence_id: 'Sequence id',
    },
    bill_of_material: {
        bill_of_material: 'Bill of material',
        add_bill_of_material: 'Add bill of material',
        item: 'Item',
        from_date: 'From date',
        to_date: 'To date',
        user: 'User',
        created_at: 'Created at',
        basic_information: 'Basic information',
        scrap: 'Scrap',
        cost: 'Cost',
        operation_and_materials: 'Operation and materials',
        item_details: 'Item details',
        allow_alternative_item: 'Allow alternative item',
        is_default: 'Is default',
        is_active: 'Is active',
        unit: 'Unit',
        quantity: 'Quantity',
        description: 'Description',
        rate_sub_assembly_based_bom: 'Set Rate of sub assembly based on  Bom',
        allow_multi_inventory: 'Allow multi inventory',
        company: 'Company',
        inventory: 'Inventory',
        branch: 'Branch',
        currency: 'Currency',
        with_operation: 'With operation , manage cost of operation',
        rate_material: 'Rate of material based on',
        routing: 'Routing',
        operations: 'Operations',
        sequence_id: 'Sequence ID',
        operation: 'Operation',
        workstation: 'Workstation',
        operation_time_minutes: 'Operation time in minutes',
        transfer_manual: 'Transfer manual',

        origin_quantity: 'Origin quantity',
        required_quantity: 'Required quantity',
        reserved: 'Reserved',
        price: 'Price',
        amount: 'Amount',
        materials: 'Materials',
        scrap_and_process_loss: 'Scrap and Process Loss',
        process_loss_rate: 'Process loss rate (Bill currency)',
        process_loss_quantity: 'Process loss quantity',
        is_waste: 'Is waste',
        process_loss_rate_system: 'Process loss rate (System currency)',
        details: 'Details',
        type: 'Type',
        title: 'Title',
        notes: 'Notes',
        operation_cost: 'Operation cost',
        material_cost: 'Material cost',
        workstation_cost: 'Workstation cost',
        scrap_waste_cost: 'Scrap waste cost',
        scrap_not_waste_cost: 'Scrap not waste cost',
        total_cost: 'Total cost',
        extra_cost: 'Extra cost',
        net_total_cost: 'Net total cost',
        status: 'Status',
        name: 'Name',
        actual_quantity: 'Actual quantity',
        operation_price_per_hour: 'Operation price per hour',
    },
    material_list: {
        item: 'Item',
        from_date: 'From date',
        to_date: 'To date',
        user: 'User',
        created_at: 'Created at',
        add_material: 'Add material',
        material_list: 'Material list',
        description: 'Description',
        sub_items: 'Sub items',
        item_details: 'Item details',
        quantity: 'Quantity',
        is_active: 'Is active',
        unit: 'Unit',
        alternatives: 'Alternatives',
        validate_on_inventory: 'Validate on inventory',
        main_inventory: 'Main inventory',
        sub_inventory: 'Sub inventory',
        origin_quantity: 'Origin quantity',
        inventory: 'Inventory',
        available_quantity: 'Available quantity',
        supposed: 'Supposed',
        reserved: 'Reserved',

    },
    work_order: {
        work_order: 'Work order',
        title: 'Title',
        expected_qty: 'Expected quantity',
        item: 'Item',
        bill_of_material: 'Bill of material',
        sales_no: 'Sales no',
        project_no: 'Project no',
        status: 'Status',
        actual_quantity: 'Actual quantity',
        in_progress_inventory: 'In progress inventory',
        target_inventory: 'Target inventory',
        scrap_inventory: 'Scrap inventory',
        skip_material_transfer: 'Skip material transfer',
        use_multilevel_bom: 'Use multilevel bom',
        notes: 'Notes',
        add_work_order: 'Add work order',
        from_date: 'From date',
        to_date: 'To date',
        created_at: 'Created at',
        origin_quantity: 'Origin quantity',
        required_quantity: 'Required quantity',
        inventory: 'Inventory',
        item_details: 'Item details',
        materials: 'Materials',
        planned_operation_cost: 'Planned operation cost',
        total_operation_cost: 'Total operation cost',
        actual_operation_cost: 'Actual operation cost',
        additional_operation_cost: 'Additional operation cost',
        start_date_time: 'Start date and time',
        expected_delivery_date_time: 'Expected delivery date and time',
        operations: 'Operations',
        operation: 'Operation',
        workstation: 'Workstation',
        operation_time: 'Operation time per minutes',
        output_quantity: 'Output quantity',
        operation_progress: 'Operation progress',
    },
    job_card: {
        job_card_code: 'Job card code',
        job_card_no: 'Job card no',
        work_order: 'Work order',
        bill_material: 'Bill of material',
        operation: 'Operation',
        company: 'Company',
        posting_date: 'Posting date',
        in_progress_inventory: 'In progress inventory',
        quantity: 'Quantity',
        employee: 'Employee',
        status: 'Status',
        notes: 'Notes',
        item: 'Item',
        from_time: 'From time',
        to_time: 'To time',
        time_in_minutes: 'Time in minutes',
        complete_quantity: 'Complete quantity',
        job_card: 'Job card',
        work_order_no: 'Work order no',
        bom_no: 'Bill of material no',
        time_log: 'Time log',
        the_sum_qty_in_time_log_must_be_equal_main_qty: 'The sum of the quantities in the time log must equal the base quantity',
    },
    general_group: {
        new_general_group: "New general group",
        general_group: "General group",
        name: "Name",
        notes: "Notes",
        type: "Type",
        type_name: "Type",
    },
    aspects: {
        name: 'Name',
        aspects: 'Aspects',
        value_type: 'Value type',
        attribute_style: 'Attribute style',
        category: 'Category',
        notes: 'Notes',
        aspect_values: 'Aspect values',
        new_aspect: 'Add aspect',
    },
    bundle_and_device: {
        bundle_and_device: 'Bundle and Divide',
        item_details: 'Item details',
        item: 'Item',
        from_inventory: 'From inventory',
        target_inventory: 'Target inventory',
        bundle_code: 'Bundle code',
        bundle_quantity: 'Bundle quantity',
        bundle_date: 'Bundle date',
        notes: 'notes',
        associated_quantity: 'Associated quantity',
        origin_quantity: 'Origin quantity',
        required_quantity: 'Required quantity',
        inventory: 'Inventory',
        available_quantity: 'Available quantity',
        add_bundle_and_device: 'Add bundle and Divide',
        from_date: 'From date',
        to_date: 'To date',
        from_quantity: 'From quantity',
        to_quantity: 'To quantity',
    },
    bundle_divide: {
        bundle_divide: 'Bundle divide',
        item_details: 'Item details',
        item: 'Item',
        from_inventory: 'From inventory',
        target_inventory: 'Target inventory',
        return_quantity: 'Return quantity',
        notes: 'notes',
        associated_quantity: 'Associated quantity',
        origin_quantity: 'Origin quantity',
        required_quantity: 'Required quantity',
        bundle_no: 'Bundle No.',
        divide_no: 'Divide No.',
        reason: 'Reason',
        lost_quantity: 'Lost quantity',
        from_date: 'From date',
        to_date: 'To date',
        add_bundle_divide: 'Add bundle divide',

    },
    departments: {
        name: 'Name',
        departments: 'Departments',
        manager: 'Manager',
        no_employee: 'Employee No.',
        description: 'Description',
        new_department: 'New department',
    },
    module_data_access: {
        module_data_access: 'Module data access',
        module: 'Module',
        notes: 'Notes',
        attributes: 'Attributes',
        attribute_name: 'Attribute name',
        is_mandatory: 'Is mandatory',
        new_module_data_access: 'New module data access',
        from_date: 'From date',
        to_date: 'To date',
        module_name: 'Module name',
        created_at: 'Created at',
    },
    data_access_configuration: {
        user: 'User',
        level: 'Level',
        inventories: 'Inventories',
        branches: 'Branches',
        categories: 'Categories',
        departments: 'Departments',
        banks: 'Banks',
        special: 'Special',
        general: 'General',
        custom: 'Custom',
    },
    default_data: {
        inventory: 'Inventory',
        branch: 'Branch',
        department: 'Department',
        country: 'Country',
        city: 'City',
        currency: 'Currency',
        workstation: 'Workstation',
        apply_default: 'Apply default',
        unit: 'Unit',
        tax: 'Tax',
    },
    settings_initial: {
        employees: 'Employees',
        suppliers: 'Suppliers',
        workstations: 'Workstations',
        taxes: 'Taxes',
        units: 'Units',
        brands: 'Brands',
        branches: 'Branches',
        countries: 'Countries',
        currencies: 'Currencies',
        categories: 'Categories',
        inventories: 'Inventories',
        manufacturers: 'Manufacturers',
    },
    pos_stores: {
        name: 'Name',
        address: 'Address',
        manager: 'Manager',
        mobile: 'Mobile',
        mobile_2: 'Mobile 2',
        description: 'Description',
        new_store: 'New store',
        from_date: 'From date',
        to_date: 'To date',
        created_at: 'Created at',
        pos_store: 'Store',
        devices_list: 'Devices list',
        device: 'Device',
        customer: 'Customer',
        account: 'Account',
        default_bank: 'Default bank',
        default_cash_treasury: 'Default cash treasury',
        default_card_treasury: 'Default card treasury',
        default_customer: 'Default customer',
        default_inventory: 'Default inventory',
        default_currency: 'Default currency',
        default_payment_method: 'Default payment method',
        is_allow_invoice_return: 'Allows to return the invoice',
        no_of_day_return_invoice: 'Number of days allowed for returns',
        payment_methods_list: 'Payment methods list',
        payment_method: 'Payment method',
        treasury_or_back: 'Treasury or back',
        mm80: '80 mm',
        a4: 'A4',
        default_print_invoice_size: 'Pos invoice print size',
        default_print_refund_size: 'Pos Refund print size',
        branch: 'Branch',
        display_products_through: 'Display products through',

    },
    receipt_document: {
        name: 'Name',
        receipt_date: 'Receipt date',
        receipt_number: 'Receipt number',
        notes: 'Notes',
        items: 'Items',
        item: 'Item',
        origin_qty: 'Origin quantity',
        received_qty: 'Received quantity',
        from_date: 'From date',
        to_date: 'To date',
        purchase_code: 'Purchase code',
    },
    expenses_income_transaction: {
        id: 'ID',
        expenses_income_transaction: 'Expenses and income transaction',
        code: 'Code',
        from_date: 'From date',
        to_date: 'To date',
        currency: 'Currency',
        add_expenses_income_transaction: 'Add expenses or income transaction',
        name: 'Title',
        date: 'Date',
        amount: 'Amount',
        tax: 'Tax',
        attachment: 'Attachment',
        status: 'Status',
        notes: 'Notes',
        taxes: 'Taxes',
        tax_type: 'Tax type',
        tax_val: 'Tax rate',
        total_tax: 'Tax value',
        is_recurring: 'Is recurring',
        frequency: 'Frequency',
        end_date: 'End date',
        payment_method: 'Payment method',
        treasury: 'Treasury',
        inventory: 'Inventory',
        bank: 'Bank',
        doc_in: 'In',
        doc_out: 'Out',
        doc_type: 'Transaction type',
        class: 'Transaction classification',
        customer: 'customer',
        supplier: 'supplier',
        employee: 'employee',
        others: 'others',
        doc_type_lable: 'Transaction',
        type_name: 'Type',
        type: 'Type for',
        branch: 'Branch',
        payment_channel : 'Payment channel',
        total : 'Total',
    },
    expenses_incomes_print: {
        expenses: 'Expense',
        number: 'Number',
        disburse_to_mr: 'Disburse to Mr.',
        amount_of: 'Amount of',
        on_the_date: 'on the date',
        and_that_for: 'And that for',
        recipient_signature: 'Recipient signature',
        signature_of_the_treasurer: 'Cashier signature',
        signature_of_the_manager: 'Manager signature',
        print_transaction: 'Print transaction',
        received_from_mr: 'Received from Mr.',
        date: 'Date',
        received_voucher: 'Received Voucher',
        on: 'on',
        payment_method: 'Payment method',
        treasury: 'Treasury',
        bank: 'Bank',
        created_date: 'Created data',
    },
    purchase_quotations: {
        purchase_quotations: 'Purchase price offers',
        title: 'Title',
        purchase_quotation_request_code: 'Request code',
        purchase_quotation_request_date: 'Request date',
        purchase_request_due_date: 'Due date for the request',
        branch: 'Branch',
        attachment: 'Attachment',
        notes: 'Notes',
        items: 'Items',
        item: 'Item',
        qty: 'Quantity',
        purchase_quotations_section: 'Purchase price offers section',
        add_purchase_quotations: 'Add purchase price offers',
        convert_to_purchase_quotations: 'Convert to purchase price offers',
        convert_to_purchase_invoice: 'Convert to purchase invoice',
        from_date: 'From date',
        to_date: 'To date',
        added_by: 'Added by',
        create_date: 'Create date',
        supplier: 'Supplier',
        currency: 'Currency',
        subtotal: 'Subtotal',
        total: 'Total',
        company: 'Company',
        shipping_with: 'Shipping options',
        shipping_fees: 'Shipping fees',
        unit_price: 'Unit price',
    },
    supplier_view: {
        supplier_details: 'Supplier details',
        personal_file: 'Personal file',
        quick_details: 'Quick details',
        number_of_purchase_invoices: 'Number of purchase invoices',
        number_of_unpaid_purchase_invoices: 'Number of unpaid purchase invoices',
        last_purchase_invoice: 'Last purchase invoice',
        last_purchase_invoices: 'Last purchase invoices',
        balances: 'Balances',
        balance: 'Balance',
        debit: 'Debit',
        currency: 'Currency',
        last_validated_date: 'Last validated date',
        last_purchase_invoices_for: 'Last purchase invoices for',
        id: 'ID',
        name: 'Name',
    },
    customer_view: {
        customer_details: 'Customer details',
        name: 'Name',
        personal_file: 'Personal file',
        quick_details: 'Quick details',
        number_of_sales_invoices: 'Number of sales invoices',
        number_of_unpaid_sales_invoices: 'Number of unpaid sales invoices',
        last_sales_invoice: 'Last sales invoice',
        last_sales_invoices: 'Last sales invoices',
        balances: 'Balances',
        balance: 'Balance',
        debit: 'Debit',
        currency: 'Currency',
        last_validated_date: 'Last validated date',
        last_sales_invoices_for: 'Last sales invoices for',
        id: 'ID',
    },
    account_statement_print: {
        account_statement_for: 'Account statement for',
        account_summary: 'Account summary',
        opening_balance: 'Opening balance',
        total: 'Total',
        paid_to_date: 'Paid to date',
        deserved_amount: 'Deserved amount',
        account_transactions: 'Account transactions',
        the_date: 'The date',
        operation_type: 'Operation type',
        the_amount: 'The amount',
        the_currency: 'The currency',
        exchange_rate: 'Exchange rate',
        balance_before: 'Balance before',
        balance_after: 'Balance after',
        amount_due_before: 'Amount due before',
        the_amount_due_after: 'The amount due after',
        from: 'from',
        to: 'to',
        total_refund: 'Total refund',
        total_expenses: 'Total expenses',
        transactions_of_related_customers_accounts: 'Transactions of related customers accounts',
        balance: 'Balance',
    },
    bank_checking: {
        bank_checking: 'Bank balance checking',
        add_bank_checking: 'Add bank balance checking',
        bank: 'Bank',
        from_date: 'From date',
        to_date: 'To date',
        last_validated_date: 'Last validated date',
        currency: 'Currency',
        validator_user: 'Validator user',
        current_amount: 'Current amount',
        actual_amount: 'Actual amount',
        cash: 'Cash',
        currency_name: 'Currency name',
        last_validate_date: 'Last validate date',
        last_val: 'Last value',
        old_balance: 'Old balance',
        new_balance: 'New balance',
        created_date: 'Created date',
        confirmed_date: 'Confirmed date',
    },
    bank_checking_history: {
        bank_checking_history: 'Bank checking history',
        bank: 'Bank',
        from_date: 'From date',
        to_date: 'To date',
        last_validated_date: 'Last validated date',
        currency: 'Currency',
        validator_user: 'Validator user',
        current_amount: 'Current amount',
        actual_amount: 'Actual amount',
        cash: 'Cash',
        currency_name: 'Currency name',
        last_validate_date: 'Last validate date',
        last_val: 'Last value',
        bank_checking_amount: 'Checking amount for bank',
        confirmed_date: 'Confirmed date',
    },

    customer_checking: {
        customer_checking: 'Customer balance checking',
        add_customer_checking: 'Add customer balance checking',
        customer: 'Customer',
        from_date: 'From date',
        to_date: 'To date',
        last_validated_date: 'Last validated date',
        currency: 'Currency',
        validator_user: 'Validator user',
        current_amount: 'Current amount',
        credit: 'Current amount credit',
        debit: 'Current amount debit',
        actual_amount: 'Actual amount',
        cash: 'Cash',
        currency_name: 'Currency name',
        last_validate_date: 'Last validate date',
        last_val: 'Last value',
        new_credit: 'New amount credit',
        new_debit: 'New amount debit',
        created_date: 'Created date',
        confirmed_date: 'Confirmed date',
    },
    customer_checking_history: {
        customer_checking_history: 'Customer checking history',
        customer: 'Customer',
        customer_name: 'Customer',
        from_date: 'From date',
        to_date: 'To date',
        last_validated_date: 'Last validated date',
        currency: 'Currency',
        validator_user: 'Validator user',
        current_amount: 'Current amount',
        actual_amount: 'Actual amount',
        cash: 'Cash',
        currency_name: 'Currency name',
        last_validate_date: 'Last validate date',
        last_val: 'Last value',
        customer_checking_amount: 'Checking amount for customer',
        confirmed_date: 'Confirmed date',
    },
    supplier_checking_history: {
        supplier_checking_history: 'Supplier checking history',
        supplier: 'Supplier',
        from_date: 'From date',
        to_date: 'To date',
        last_validated_date: 'Last validated date',
        currency: 'Currency',
        validator_user: 'Validator user',
        current_amount: 'Current amount',
        actual_amount: 'Actual amount',
        cash: 'Cash',
        currency_name: 'Currency name',
        last_validate_date: 'Last validate date',
        last_val: 'Last value',
        supplier_checking_amount: 'Checking amount for supplier',
        confirmed_date: 'Confirmed date',
    },
    sales_printing: {
        package_label: 'Package label',
        print_package_label: 'Package label',
        receive_list: 'Receive list',
        print_receive_list: 'Receive list',
        delivery_label: 'Delivery label',
        print_delivery_label: 'Delivery label',
        invoice: 'Invoice',
        shipping_for: 'Shipping for',
        invoice_for: 'Invoice for',
        item: 'Item',
        description: 'Description',
        quantity: 'Quantity',
        total: 'Total',
        inventory: 'Inventory',
        from: 'From',

        pdf_package_label: 'Export package label PDF',
        pdf_receive_list: 'Export receive list PDF',
        pdf_delivery_label: 'Export delivery label PDF',
        print_packing_slip: 'Packing slip',
        pdf_packing_slip: 'Export packing slip PDF',
    },

    sales_quotations: {
        sales_quotations: 'Sales price offers',
        title: 'Title',
        sales_quotation_code: 'Request code',
        sales_quotation_date: 'Request date',
        sales_due_date: 'Due date for the request',
        branch: 'Branch',
        attachment: 'Attachment',
        notes: 'Notes',
        items: 'Items',
        item: 'Item',
        qty: 'Quantity',
        sales_quotations_section: 'Sales price offers section',
        add_sales_quotations: 'Add sales price offers',
        convert_to_sales_quotations: 'Convert to sales price offers',
        convert_to_sales_invoice: 'Convert to sales invoice',
        from_date: 'From date',
        to_date: 'To date',
        added_by: 'Added by',
        create_date: 'Create date',
        customer: 'Customer',
        currency: 'Currency',
        subtotal: 'Subtotal',
        total: 'Total',
        company: 'Company',
        shipping_with: 'Shipping options',
        shipping_fees: 'Shipping fees',
        unit_price: 'Unit price',
    },

    general_sales_quotations: {
        sales_quotations: 'General Sales price offers',
        title: 'Title',
        sales_quotation_code: 'Request code',
        sales_quotation_date: 'Request date',
        sales_due_date: 'Due date for the request',
        branch: 'Branch',
        attachment: 'Attachment',
        notes: 'Notes',
        items: 'Items',
        item: 'Item',
        qty: 'Quantity',
        sales_quotations_section: 'General Sales price offers section',
        add_sales_quotations: 'Add General sales price offers',
        convert_to_sales_quotations: 'Convert to sales price offers',
        convert_to_sales_invoice: 'Convert to sales invoice',
        convert_to_general_sales_invoice: 'Convert to general sales invoice',
        from_date: 'From date',
        to_date: 'To date',
        added_by: 'Added by',
        create_date: 'Create date',
        customer: 'Customer',
        currency: 'Currency',
        subtotal: 'Subtotal',
        total: 'Total',
        company: 'Company',
        shipping_with: 'Shipping options',
        shipping_fees: 'Shipping fees',
        unit_price: 'Unit price',
    },

    sales_quotation_requests: {
        sales_quotation_requests: 'Sales quotation requests',
        title: 'Title',
        sales_quotation_request_code: 'Quotation request code',
        sales_quotation_request_date: 'Quotation request date',
        sales_request_due_date: 'Quotation request due date',
        branch: 'Branch',
        attachment: 'Attachment',
        notes: 'Notes',
        items: 'Items',
        item: 'Item',
        qty: 'Quantity',
        sales_quotation_requests_section: 'Sales quotation requests section',
        add_sales_quotation_requests: 'Add sales quotation request',
        from_date: 'From date',
        to_date: 'To date',
        added_by: 'Added by',
        create_date: 'Create date',
        customer: 'Customer',
    },
    customer_permissions: {
        customer_permissions: 'Customer permissions',
        customers_created_by_the_user: 'Customers created by the user',
        customers_belonging_to_the_branches_that_the_user_has_access_to: 'Customers belonging to the branches that the user has access to',
        branches: 'Branches',
        customers_belonging_to_the_groups_that_the_user_has_access_to: 'Customers belonging to the groups that the user has access to',
        groups: 'Groups',
        customers_belonging_to_the_users_that_the_user_has_access_to: 'Customers of users that the user has access to',
        users: 'Users',
    },
    supplier_permissions: {
        supplier_permissions: 'Supplier permissions',
        suppliers_created_by_the_user: 'Suppliers created by the user',
        suppliers_belonging_to_the_branches_that_the_user_has_access_to: 'Suppliers belonging to the branches that the user has access to',
        branches: 'Branches',
        suppliers_belonging_to_the_groups_that_the_user_has_access_to: 'Suppliers belonging to the groups that the user has access to',
        groups: 'Groups',
        suppliers_belonging_to_the_users_that_the_user_has_access_to: 'Suppliers of users that the user has access to',
        users: 'Users',
    },
    pos_permissions: {
        pos_permissions: 'POS permissions',
        invoices_for_sessions_that_the_user_has_created: 'Invoices for sessions that the user has created',
        stores_that_the_user_has_access_to: 'Stores that the user has access to',
        stores: 'Stores',
        is_other_sessions_return: 'Return invoice from other session',
        users: 'Users'
    },

    tax_settings: {
        the_application_of_the_first_tax: 'The application of the first tax',
        apply_the_tax_on_the_principal_amount_before_deduction: 'Apply the tax on the principal amount before the discount',
        apply_the_tax_on_the_principal_amount_after_deduction: 'Apply the tax on the principal amount after the discount',
        the_application_of_the_second_tax: 'The application of the second tax',
        apply_the_tax_on_the_principal_amount_the_first_tax_value_before_deduction: 'Apply the tax on the principal amount + the first tax value before the discount',
        apply_the_tax_on_the_principal_amount_the_first_tax_value_after_deduction: 'Apply the tax on the principal amount + the first tax value after the discount',
        tax_free_product: 'Tax free product',
        application_of_tax_on_the_value_of_profit: 'Application of tax on the value of profit',
        apply_the_tax_on_the_principal_amount_before_the_discount_tax_2: 'Apply the tax to the principal amount before the discount',
        apply_the_tax_on_the_principal_amount_after_the_discount_tax_2: 'Apply the tax on the principal amount after the discount',
    },
    sales_quotations_printing: {
        sales_quotations: 'Sales quotation',
        reference: 'Reference',
        customer_information: 'Customer information',
        item: 'Item',
        unit_price: 'Unit price',
        quantity: 'Quantity',
        subtotal: 'Subtotal',
        total: 'Total',
        sales_quotation_date: 'Request date',
        sales_due_date: 'Due date for the request',
    },
    payment_purchase_invoices: {
        payment_purchase_invoices: 'Purchase payments',
        payment_method: 'Payment method',
        amount: 'Amount',
        banks: 'Banks',
        payment_date: 'Payment date',
        payment_status: 'Payment status',
        collected_by: 'Collected by',
        ref_no: 'Ref no.',
        payment_details: 'Payment details',
        receipt_notes: 'Receipt notes',
        attachment: 'Attachment',
        client_info: 'Customer info',
        invoice_code: 'Invoice code',
        from_date: 'From date',
        to_date: 'To date',
        from_amount: 'From amount',
        to_amount: 'To amount',
        supplier: 'Supplier',
        invoiced_by: 'Invoiced by',
        payment_purchase_invoices_section: 'Payment purchase',
        type: 'Invoice type',
        supplier_code: 'Supplier code',
        supplier_name: 'Supplier name',
        purchase_invoice: 'Purchase invoice',
        purchase_refund: 'Purchase refund',
        details: 'Details',
        treasuries: 'Treasuries',
        currency: 'Currency',
        purchase_credit: 'Purchase credit',
        code: 'Code',
        supplier_info: 'Supplier info',
    },
    payment_purchase_invoices_details: {
        Supplier_Info: 'Supplier Info',
        Payment_Details: 'Payment Details',
        Supplier_Name: 'Supplier Name',
        Invoice_No: 'Invoice No.',
        Street_Address: 'Street Address',
        Payment_Method: 'Payment Method',
        City: 'City',
        Amount: 'Amount',
        State: 'State',
        Transaction_ID: 'Transaction ID',
        Postal_Code: 'Postal Code',
        Status: 'Status',
        Telephone: 'Telephone',
        Date: 'Date',
        Country: 'Country',
        Added_By: 'Added By',
        Currency: 'Currency',
        Details: 'Details',
        Receipt_Notes: 'Receipt Notes',
        delete: 'Delete',
        receipt: 'Receipt',
        edit: 'Edit',
    },
    taxes_rules: {
        new_tax_rule: 'New tax rule',
        tax_rule: 'Tax rule',
        name: 'Name',
        description: 'Description',
        example: 'Example',
        type: 'Type',
        sales: 'Sales',
        purchase: 'Purchase',
        both: 'Both',
        auto_apply: 'Auto apply',
        key: 'Key',
    },
    delimiters: {
        name: 'Name',
        description: 'Description',
        example: 'Example',
        tax_rule: 'Tax rule',
        options: 'Options',
        value: 'Value',
        attachment: 'Attachment',
        new_delimiter: 'New delimiter',
    },
    invoice_status: {
        statuses: 'Statuses',
        invoice: 'Invoice',
        invoice_no: 'Invoice No.',
        description: 'Description',
        details: 'Details',
        no_data: 'No data',
    },
    laundry_services: {
        add_laundry_service: 'Add laundry service',
        laundry_service: 'Laundry service',
        name: 'name',
        notes: 'Description',
        tax: 'Tax',
        order: 'Order',
        symbol: 'Symbol',
    },
    laundry_services_pricing: {
        service_name: 'Service name',
        price: 'Price',
        number_of_pieces: 'Number of pieces',
        tax: 'Tax',
        notes: 'Notes',
        status: 'Status',
        special_operations: 'Special operations',
        operation: 'Operation',
        quantity: 'Quantity',
    },

    price_lists_laundry_services: {
        name: "Name",
        notes: "Notes",
        prices: "Prices",
        service_price: "Service price",
        new_price_list_laundry_service: "New price list laundry service",
        service_name: "Service name",
        user_name: "User",
        quantity: "Quantity",
    },

    attached_reasons: {
        new_attached_reason: 'New Description of parts received',
        attached_reason: 'Description of parts received',
        name: 'Name',
        applied_services: 'Applied services',
        status: 'Status',
        created_at: 'Created at',
    },
    debits: {
        debits: 'Sales debits',
        name: 'Name',
        customer: 'Customer',
        debit_code: 'Debit code',
        debit_date: 'Debit date',
        currency: 'Currency',
        shipping_fees: 'Shipping fees',
        attachment: 'Attachment',
        notes: 'Notes',
        items: 'Items',
        item: 'Item',
        description: 'Description',
        unit_price: 'Unit price',
        qty: 'Quantity',
        tax: 'Tax',
        discount_type: 'Discount type',
        discount_value: 'Discount value',
        subtotal: 'Sub total',
        unit: 'Unit',
        debits_section: 'Sales debit section',
        add_sales_debits: 'Add sales debit',
        add_sales_debits_general: 'Add general sales debit',
        from_date: 'From date',
        to_date: 'To date',
        added_by: 'Added by',
        create_date: 'Create date',
        invoice_subtotal: 'Total Subtotal',
        total_after_discount: 'Total discount',
        total_after_shipping: 'Total shipping',
        total_tax: 'Total tax',
        total: 'Total',
        from_quantity: 'From quantity',
        to_quantity: 'To quantity',
        basic_information: 'Basic information',
        attachments: 'Attachments',
        shipping_details: 'Shipping details',
        adjustment: 'Adjustment',
        upload_attachment: 'Upload Attachment',
        deposite_value: 'Deposit value',
        payment_terms: 'Payment terms',
        issue_date: 'Issue date',
        credit_subtotal: 'Subtotal',
        day_s: 'Day/s',
        inventory: 'Inventory',
        amount_tax: 'Amount tax',
        subtotal_before_tax: 'Subtotal before tax',
        invoice_total_discount: 'Total discount',
        original_qty: 'Original quantity',
    },
    extend_days_plan: {
        your_subscription_will_expire: 'Your subscription will expire on {date}. You have {days} as free days',
        get_extended_days: 'Get extension days',
        ignore: 'Ignore',
    },
    renewal_plan: {
        your_subscription_will_expire: 'Your subscription will expire on {date}',
        renewal_subscription: 'Subscription renewal',
    },

    laundry_services_colors: {
        laundry_services_colors: "Colors of pieces received",
        is_default: "Is default",
        is_active: "Is active",
        color: "Color",
        colors: "Colors",
        name: "Name",
        statuses: "Statuses",
    },
    cleaning: {
        cleaning: 'In progress',
        cleaned: 'Cleaned',
        add_rack_location: 'Add rack location',
        rack: 'Rack',
        confirm: 'Confirm',
        invoice_code: 'Invoice code',
        ready_by: 'Ready by',
        customer: 'Customer',
        order: 'Order',
        Pcs: 'PCS',
        notes: 'Notes',
        total: 'Total',
        id: 'ID',
        ready_date: 'Ready date',
        invoice_date: 'Invoice date',
    },
    ready: {
        ready: 'Collected orders',
        collected: 'Collected',
        rack: 'Rack',
        confirm: 'Confirm',
        invoice_code: 'Invoice code',
        ready_by: 'Ready by',
        customer: 'Customer',
        order: 'Order',
        Pcs: 'PCS',
        notes: 'Notes',
        total: 'Total',
        payment_method: 'Payment method',
        id: 'ID',
        ready_date: 'Ready date',
        invoice_date: 'Invoice date',
        payment: 'Payment',
        are_you_sure_make_it_collected: 'Are you sure make it collected?',
        order_status: 'Order status',
        are_you_sure_make_it_completed: 'Are you sure make it completed?',
        change_rack_location: 'Change rack location',
        completed_order: 'Order completed',
    },
    pickups: {
        pickups: 'Ready orders',
        collected: 'Collected',
        rack: 'Rack',
        confirm: 'Confirm',
        invoice_code: 'Invoice code',
        ready_by: 'Ready by',
        customer: 'Customer',
        order: 'Order',
        Pcs: 'PCS',
        notes: 'Notes',
        total: 'Total',
        payment_method: 'Payment method',
        id: 'ID',
        ready_date: 'Ready date',
        invoice_date: 'Invoice date',
        payment: 'Payment',
        are_you_sure_make_it_collected: 'Are you sure make it collected?',
        address: 'Connection',
    },
    sales_via_interval_report: {
        sales_via_interval_report: 'Sales via interval',
        customer: 'Customer',
        from_date: 'From date',
        to_date: 'To date',
        show_report: 'Show report',
        date: 'Date',
        total: 'Total',
        number: 'Number',
        invoice_number: 'Invoice no.',
    },
    details_laundry: {
        details: 'Details',
        cleaned: 'Cleaned',
        add_rack_location: 'Add rack location',
        change_rack_location: 'Change rack location',
        rack: 'Rack',
        confirm: 'Confirm',
        invoice_code: 'Invoice code',
        ready_by: 'Ready by',
        customer: 'Customer',
        order: 'Order',
        Pcs: 'PCS',
        notes: 'Notes',
        total: 'Total',
        id: 'ID',
        ready_date: 'Ready date',
        invoice_date: 'Invoice date',
        order_details: 'Order details',
        construction: 'Construction',
        payment: 'Payment',
        cleaning: 'Cleaning',
        item: 'Item',
        note_or_problem: 'Note or problem',
    },
    cash_category: {
        cash_category: 'Cash category',
        new_cash_category: 'New cash category',
        change_status: 'Change status',
        name: 'Name',
        currency: 'Currency',
        value: 'Value',
        status: 'Status',
        image: 'Image',
        description: 'Description',
        upload_image: 'Upload image',
    },
    invoice_bond_configuration: {
        name: 'Name',
        type: 'Type',
        description: 'Description',
        debit_config_customer: 'Configuration debit to customer',
        credit_config_customer: 'Configuration credit to customer',
        debit_config_supplier: 'Configuration debit to supplier',
        credit_config_supplier: 'Configuration credit to supplier',
    },
    expired_days_plan: {
        your_subscription_expired: 'Your subscription has expired. Please contact the administration or extend the subscription',
    },
    invoices_bonds_management: {
        invoice_bond: 'Invoice / Bond',
        credit: 'Credit',
        debit: 'Debit',
        customer_credit: 'Customer credit',
        customer_debit: 'Customer debit',
        supplier_credit: 'Supplier credit',
        supplier_debit: 'Supplier debit',
        max: 'maximum',
        min: 'minimum',
    },
    basic_status_management: {
        basic_status_management: 'Basic status management',
        basic_information: 'Basic information',
        name: 'Name',
        slug: 'Slug',
        module_type: 'Module type',
        order: 'Order',
        Detailed_data_documents: 'Detailed data documents',
        doc_type: 'Document type',
        Detailed_data_users_and_permissions: 'Detailed data users and permissions',
        Detailed_data_permissions: 'Permissions',
        Detailed_data_users: 'Users',
        user: 'User',
        is_registered: 'Is registered?',
        registered: 'Registered',
        unregistered: 'Unregistered',
        permission: 'Permission',
        is_required: 'Is required?',
    },

    document_status_paths: {
        document_status_paths: 'Document status paths',
        name: 'Name',
        module: 'Module',
        module_slug: 'Module slug',
        document: 'Document',
        document_slug: 'Document slug',
        description: 'Description',
        is_main: 'Is main',
        status: 'Status',
        is_active: 'Is active',
        status_list: 'Status list',
        applied_roles: 'Applied roles',
        removed_roles: 'Removed roles',
    },
    status_mapping: {
        status_mapping: 'Status mapping',
        name: 'Name',
        module: 'Module',
        module_slug: 'Module slug',
        document: 'Document',
        document_slug: 'Document slug',
        notes: 'notes',
        is_required: 'Is required',
        status: 'status',
        is_active: 'Is active',
        default_initial_status: 'Default initial status',
        default_final_status: 'Default final status',
        required_status: 'Required status',
        optional_status: 'Optional status',
        multi_initial_status: 'Multi initial status',
        multi_final_status: 'Multi final status',
        event_status: 'Event status',
        from_status: 'From status',
        to_status: 'To status',
        event: 'Event',
        disallowed_convert_status: 'Disallowed convert status',
    },

    connected_printers: {
        connected_printers: 'Connected printers',
        add_connected_printer: 'Add connected printer',
        name: 'Name',
        type: 'Type',
        notes: 'Notes',
    },
    tax_declaration_report: {
        sales: 'Sales',
        purchases: 'Purchases',
        tax: 'Tax',
        tax_due: 'Tax Due',
        edits: 'Edits',
        payable_tax: 'Payable Tax',
    },
    simple_customer_balance_report: {
        type: 'Operation',
        type_id: 'Operation No.',
        date: 'Date',
        debit_after: 'Debit',
        credit_after: 'Credit',
        amount: 'Balance',
        currency: 'Currency',
        notes: 'Notes',
        customer: 'Customer',
        address: 'Address',
        mobile: 'Mobile',
        account_statement: 'Statement of account',
        net_movement: 'Net movement',
        closing_balance: 'Closing balance',
        opening_balance: 'Opening balance',
        total_bills: 'Total bills',
        amount_paid: 'Amount paid',
        amount_due: 'Amount due',
    },
    report_main: {
        from_date: 'From date',
        to_date: 'To date',
        issued_from: 'Issued from',
        commercial_register: 'Commercial register',
        tax_number: 'Tax number',
        address: 'Address',
        issued_to: 'Issued to',
    },
    fabric_problems: {
        new_fabric_problems: 'New fabric problem',
        fabric_problems: 'Fabric problems',
        name: 'Name',
        ordering: 'Ordering',
        status: 'Status',
        description: 'Description',
    },
    doc_payment_details: {
        doc_code: 'Document code',
        doc_type: 'Document type',
        amount: 'Amount',
        currency: 'Currency',
        create_date: 'Create date',
        invoices: 'Invoices',
        invoice_code: 'Invoice code',
        invoice_type: 'Invoice type',
        balance: 'Balance',
        type: 'Type',
        direction: 'Direction',
        payment_details: 'Payment details',
    },
    payment_sales_details: {
        amount: 'Amount',
        currency: 'Currency',
        create_date: 'Create date',
        invoices: 'Invoices',
        invoice_code: 'Invoice code',
        invoice_type: 'Invoice type',
        balance: 'Balance',
        type: 'Type',
        direction: 'Direction',
        payment_details: 'Payment details',
        invoice_id: 'Invoice ID',
        payment_code: 'Payment code',
        payment_no: 'Payment NO.',
        main_invoice: 'Main invoice',
        attached_invoices: 'Attached invoices',
    },
    payment_purchase_details: {
        amount: 'Amount',
        currency: 'Currency',
        create_date: 'Create date',
        invoices: 'Invoices',
        invoice_code: 'Invoice code',
        invoice_type: 'Invoice type',
        balance: 'Balance',
        type: 'Type',
        direction: 'Direction',
        payment_details: 'Payment details',
        invoice_id: 'Invoice ID',
        payment_code: 'Payment code',
        payment_no: 'Payment NO.',
        main_invoice: 'Main invoice',
        attached_invoices: 'Attached invoices',
    },
    extra_discount: {
        extra_discount: 'Extra discount',
        customer: 'Customer',
        invoice: 'Invoice',
        discount_date: 'Discount date',
        invoice_total: 'Invoice total',
        discount_val: 'Discount value',
        discount_type: 'Discount type',
        request_responsible_user: 'Request responsible user',
        response_responsible_user: 'Response responsible user',
        status: 'Status',
        description: 'Description',
    },
    invoices_types_trans: {
        sales_invoice: 'Sales invoice',
        general_sales_invoice: 'General sales invoice',
        pos: 'POS',
        sales_refund: 'Sales refund',
        sales_credit: 'Sales credit',
        sales_debit: 'Sales debit',
        general_sales_refund: 'General sales refund',
        general_sales_credit: 'General sales credit',
        general_sales_debit: 'General sales debit',
        sales_payment: 'Sales payment',
        expenses_income_transaction: 'Expenses income transaction',
    },
    bonds_and_payments_details_report: {
        branch_name: 'Branch',
        code: 'Code',
        customer_name: 'Customer',
        document: 'Document',
        id: 'ID',
        inventory_name: 'Inventory',
        model: 'Model',
        amount:'Amount',
        total:'Total',
        payment_method_name:'Payment method',
        inventories:'Inventories',
        payments: 'Payments',
        incomes: 'Incomes',
        expenses: 'Expenses',
    },
    sales_profit_report:{
        invoice_code: 'Invoice code',
        user: 'User',
        customer_name: 'Customer',
        customers: 'Customers',
        item_name: 'Item',
        item_qty: 'Quantity',
        purchase_price: 'Purchase unit price',
        price_per_unit: 'Sales unit price',
        purchase_sub_total: 'Purchase total',
        sub_total: 'Sales total',
        discount_value: 'Discount',
        profit: 'Profit',
        day: 'Day',
        week: 'Week',
        month: 'Month',
        year: 'Year',
        item: 'Item',
        employee: 'Employee',
        customer: 'Customer',
        document: 'Document',
        payment_status: 'Payment status',
    },
    profit_of_sales_item_report:{
        invoice_code: 'Invoice code',
        user: 'User',
        customer_name: 'Customer',
        customers: 'Customers',
        item_name: 'Item',
        quantity: 'Quantity',
        value: 'Value',
        total_cost: 'Total cost',
        sales: 'Sales',
        return: 'Return',
        profit: 'Profit',
        profit_rate: 'Profit rate',
        percentage: 'Percentage',
        to_sales: 'To sales',
        to_cost: 'To the cost',
        net_sales: 'Net sales',
        items: 'Items',
    },
    supplier_aged_ledger:{
        supplier_aged_ledger: 'Supplier Aged Ledger',
        supplier: 'Supplier',
        supplier_code: 'Supplier code',
        from_date: 'From date',
        to_date: 'To date',
        not_overdue: 'Not overdue',
        f_1_to_30_day: '1 to 30 day',
        f_31_to_60_day: '31 to 60 day',
        f_61_to_90_day: '61 to 90 day',
        f_91_to_120_day: '91 to 120 day',
        more_than_121_day: '120+ day',
        sum_over_due: 'Total arrears',
        today: 'Today',
        total: 'Total',
        branches: 'Branches',
        branch: 'Branch',
        business_no: 'Business No.',
    },
    sales_for_sales_report: {
        sales_report: 'Sales report',
        qty: 'Quantity',
        discount: 'Discount',
        price: 'Price',
        item_code: 'Item code',
        invoice: 'Invoice',
        customer: 'Customer',
        unit_price: 'Unit Price',
        number: 'Number',
        code: 'Code',
        branch: 'Branch',
        quantity: 'Quantity',
        total_tax: 'Total tax',
        item: 'Item',
        sales_qty: 'Sales quantity',
        total: 'Total',
        item_id: 'Item ID',
        item_name: 'Item name',
        date: 'Date',
        employee: 'Employee',
        day: 'Day',
        week: 'Week',
        month: 'Month',
        year: 'Year',
    },

    simple_suppliers_balance_report: {
        type: 'Operation',
        type_id: 'Operation No.',
        date: 'Date',
        debit_after: 'Debit',
        credit_after: 'Credit',
        amount: 'Balance',
        currency: 'Currency',
        notes: 'Notes',
        supplier: 'Supplier',
        address: 'Address',
        mobile: 'Mobile',
        account_statement: 'Statement of account',
        net_movement: 'Net movement',
        closing_balance: 'Closing balance',
        opening_balance: 'Opening balance',
        total_bills: 'Total bills',
        amount_paid: 'Amount paid',
        amount_due: 'Amount due',
    },

    detailed_stock_transactions_per_product: {
        invoice_code: 'Invoice code',
        user: 'User',
        customer_name: 'Customer',
        customers: 'Customers',
        item_name: 'Item',
        item_qty: 'Quantity',
        purchase_price: 'Purchase unit price',
        price_per_unit: 'Sales unit price',
        purchase_sub_total: 'Purchase total',
        sub_total: 'Sales total',
        discount_value: 'Discount',
        profit: 'Profit',
        day: 'Day',
        week: 'Week',
        month: 'Month',
        year: 'Year',
        item: 'Item',
        employee: 'Employee',
        customer: 'Customer',
        document: 'Document',
        payment_status: 'Payment status',
        currency: 'Currency',

        operation: 'Operation',
        source: 'Source',
        inventory: 'Inventory',
        quantity: 'Quantity',
        unit_price: 'Unit price',
        stock_after: 'Stock quantity after',
        average_cost_price: 'Average cost price',
        total_transaction_value: 'Total transaction value',
        total_price: 'Total price',
        stock_value_after: 'Stock value after',
        stock_before: 'Stock before',
    },
    total_pos_product_sales_details: {
        inventory_transaction_report: 'Inventory transaction report',
        session_id: 'Session id',
        product_name: 'Product name',
        from_date: 'From date',
        to_date: 'To date',
        type: 'Type',
        refund_receipt: 'Refund receipt',
        sales_invoice: 'Sales invoice',
        purchase_invoice: 'Purchase invoice',
        inventory: 'Inventory',
        product: 'Product',
        primary_warehouse_transactions_summary: 'Primary warehouse transactions summary',
        inward: 'Inward',
        outward: 'Outward',
        purchase_invoices: 'Purchase invoices',
        refund_receipts: 'Refund receipts',
        transfer: 'Transfer',
        manual: 'Manual',
        total: 'Total',
        sales_invoices: 'Sales invoices',
        purchase_refund: 'Purchase refund',
        total_transactions: 'Total transactions',
        sales_refund: 'Sales refund',
        credit_note: 'Credit note',
        inventory_transaction_details: 'Inventory transaction details',
        inventory_detailed_transactions: 'Inventory Detailed Transactions',
        time: 'Time',
        warehouse: 'Warehouse',
        stock_level_after: 'Stock level after',
        inventory_transactions: 'Inventory transactions',
        receipt_document: 'Receipt document',
        sku_code: 'SKU code',
        branch: 'Branch',
        category: 'Category',
        stock_level_before: 'Stock level before',
        summation: 'Total',

        product_code: 'Product code',
        sales: 'Sales',
        refunds: 'Refunds',
        net: 'Net',
        average: 'Average',
        taxes: 'Taxes',
        discount: 'Discount',
        total_total: 'Total',
        cost: 'Cost',
        profit: 'Profit',
        profitability_ratio: 'Profitability ratio',
        sales_non_taxes: 'Sales non-taxes',
        refunds_non_taxes: 'Refunds non-taxes',
        net_total_no_tax: 'Net non-taxes',
        profit_rate: 'Profit rate',
    },
    pos_shifts_sales_report: {
        session_id: 'Session id',
        product_name: 'Product name',
        from_date: 'From date',
        to_date: 'To date',
        type: 'Type',
        refund_receipt: 'Refund receipt',
        sales_invoice: 'Sales invoice',
        purchase_invoice: 'Purchase invoice',
        inventory: 'Inventory',
        product: 'Product',
        primary_warehouse_transactions_summary: 'Primary warehouse transactions summary',
        inward: 'Inward',
        outward: 'Outward',
        purchase_invoices: 'Purchase invoices',
        refund_receipts: 'Refund receipts',
        transfer: 'Transfer',
        manual: 'Manual',
        total: 'Total',
        sales_invoices: 'Sales invoices',
        purchase_refund: 'Purchase refund',
        total_transactions: 'Total transactions',
        sales_refund: 'Sales refund',
        credit_note: 'Credit note',
        inventory_transaction_details: 'Inventory transaction details',
        inventory_detailed_transactions: 'Inventory Detailed Transactions',
        time: 'Time',
        warehouse: 'Warehouse',
        stock_level_after: 'Stock level after',
        inventory_transactions: 'Inventory transactions',
        receipt_document: 'Receipt document',
        sku_code: 'SKU code',
        branch: 'Branch',
        category: 'Category',
        stock_level_before: 'Stock level before',
        summation: 'Total',
        product_code: 'Product code',
        sales: 'Sales',
        refunds: 'Refunds',
        net: 'Net',
        average: 'Average',
        taxes: 'Taxes',
        discount: 'Discount',

        shift: 'Shift',
        treasury_user: 'Treasury user',
        session_user: 'Session user',
        session: 'Session',
        session_code:'Session code',
        session_start_time:'Session start time',
        session_end_time:'Session end time',
        treasury_employee:'Treasury employee',
        confirmed_by:'Confirmed by',
        store:'Store',
    },
    total_pos_categories_profits_report: {
        session_id: 'Session id',
        product_name: 'Product name',
        from_date: 'From date',
        to_date: 'To date',
        type: 'Type',
        refund_receipt: 'Refund receipt',
        sales_invoice: 'Sales invoice',
        purchase_invoice: 'Purchase invoice',
        inventory: 'Inventory',
        product: 'Product',
        primary_warehouse_transactions_summary: 'Primary warehouse transactions summary',
        inward: 'Inward',
        outward: 'Outward',
        purchase_invoices: 'Purchase invoices',
        refund_receipts: 'Refund receipts',
        transfer: 'Transfer',
        manual: 'Manual',
        total: 'Total',
        sales_invoices: 'Sales invoices',
        purchase_refund: 'Purchase refund',
        total_transactions: 'Total transactions',
        sales_refund: 'Sales refund',
        credit_note: 'Credit note',
        inventory_transaction_details: 'Inventory transaction details',
        inventory_detailed_transactions: 'Inventory Detailed Transactions',
        time: 'Time',
        warehouse: 'Warehouse',
        stock_level_after: 'Stock level after',
        inventory_transactions: 'Inventory transactions',
        receipt_document: 'Receipt document',
        sku_code: 'SKU code',
        branch: 'Branch',
        category: 'Category',
        stock_level_before: 'Stock level before',
        summation: 'Total',
        product_code: 'Product code',
        sales: 'Sales',
        refunds: 'Refunds',
        net: 'Net',
        average: 'Average',
        taxes: 'Taxes',
        discount: 'Discount',

        shift: 'Shift',
        treasury_user: 'Treasury user',
        session_user: 'Session user',
        session: 'Session',
        session_code:'Session code',
        session_start_time:'Session start time',
        session_end_time:'Session end time',
        treasury_employee:'Treasury employee',
        confirmed_by:'Confirmed by',
        device:'Device',
        cost:'Cost',
        category_id:'Category ID',
        profit:'Profit',
        profit_rate:'Profit rate',
    },
    detailed_pos_shifts_transactions_report: {
        session_id: 'Session id',
        product_name: 'Product name',
        from_date: 'From date',
        to_date: 'To date',
        type: 'Type',
        refund_receipt: 'Refund receipt',
        sales_invoice: 'Sales invoice',
        purchase_invoice: 'Purchase invoice',
        inventory: 'Inventory',
        product: 'Product',
        primary_warehouse_transactions_summary: 'Primary warehouse transactions summary',
        inward: 'Inward',
        outward: 'Outward',
        purchase_invoices: 'Purchase invoices',
        refund_receipts: 'Refund receipts',
        transfer: 'Transfer',
        manual: 'Manual',
        total: 'Total',
        sales_invoices: 'Sales invoices',
        purchase_refund: 'Purchase refund',
        total_transactions: 'Total transactions',
        sales_refund: 'Sales refund',
        credit_note: 'Credit note',
        inventory_transaction_details: 'Inventory transaction details',
        inventory_detailed_transactions: 'Inventory Detailed Transactions',
        time: 'Time',
        warehouse: 'Warehouse',
        stock_level_after: 'Stock level after',
        inventory_transactions: 'Inventory transactions',
        receipt_document: 'Receipt document',
        sku_code: 'SKU code',
        branch: 'Branch',
        category: 'Category',
        stock_level_before: 'Stock level before',
        summation: 'Total',
        product_code: 'Product code',
        sales: 'Sales',
        refunds: 'Refunds',
        net: 'Net',
        average: 'Average',
        taxes: 'Taxes',
        discount: 'Discount',

        shift: 'Shift',
        treasury_user: 'Treasury user',
        session_user: 'Session user',
        session: 'Session',
        session_code:'Session code',
        session_start_time:'Session start time',
        session_end_time:'Session end time',
        treasury_employee:'Treasury employee',
        confirmed_by:'Confirmed by',
        device:'Device',
        cost:'Cost',
        category_id:'Category ID',
        profit:'Profit',
        profit_rate:'Profit rate',
        store:'Store',
        net_cash: 'Net cash',
        net_non_cash: 'Net non-cash',
        forward: 'Forward',
        total_cash_receipt: 'Total cash receipt',
        total_cash_disbursement: 'Total cash expense',
        total_cash_received: 'Total cash received',
        total_non_cash_received: 'Total non cash received',
        total_received: 'Total received',
    },
    chart_account_setting: {
        no_of_main_section: 'Number of sections of the account tree',
        is_general_code: 'General code settings',
        is_serial: 'Adopting serial numbers',
    },
    default_journalist_setting: {
        is_apply_sales_invoice_journalist: 'Is apply sales invoice journalist',
        is_apply_sales_refund_journalist: 'Is apply sales refund journalist',
        is_apply_sales_credit_note_journalist: 'Is apply sales credit note journalist',
        is_apply_purchase_invoice_journalist: 'Is apply purchase invoice journalist',
        is_apply_purchase_refund_journalist: 'Is apply purchase refund journalist',
        is_apply_open_stock_qty_journalist: 'Is apply open stock quantity journalist',
        is_apply_inventory_transactions_journalist: 'Is apply inventory transactions journalist',
        is_apply_open_balance_supplier_journalist: 'Is apply open balance supplier journalist',
        is_apply_open_balance_customer_journalist: 'Is apply open balance customer journalist',
        is_apply_outcome_journalist: 'Is apply outcome journalist',
        is_apply_income_journalist: 'Is apply income journalist',
        is_apply_expenses_journalist: 'Is apply expenses journalist',
        is_apply_revenue_journalist: 'Is apply revenue journalist',
    },
    sales_man_commission:{
        from_date: 'From date',
        to_date: 'To date',
        invoice_code: 'Invoice code',
        invoice_date: 'Invoice date',
        applied_commission_information: 'Applied commission information',
        sales_commission_value: 'Sales commission value',
        currency: 'Currency',
        total_number_of_sales_invoices: 'Total number of sales invoices',
        total_amount_commission_values: 'Total amount of sales commission values ',
    },
    financial_list:{
        financial_list: 'Financial list',
        add_financial_list: 'Add financial list',
        name: 'Name',
        type: 'Type',
        slug: 'Slug',
        is_custom_interval: 'Is custom interval',
        users: 'Users',
        description: 'Description',
        from_date: 'From date',
        to_date: 'To date',
    },
    general_item_configurations: {
        inventory_validation: 'Inventory validation',
        cost_method: 'Cost method',
        average_start_date: 'Average start date',
        average_end_date: 'Average end date',
    },
    reset_or_recalculate_quantity: {
        reset_or_recalculate_quantity: 'Reset or recalculate quantity',
        inventory: 'Inventory',
        items: 'Items',
        quantity_type: 'Quantity type',
        reset_type: 'Reset type',
    },
}
