<template>
  <div>

    <div class="card-toolbar mb-5">

      <router-link v-if="$can('expenses_incomes.create')" class="btn btn-primary font-weight-bolder"
                   to="/finances/expenses-income-transaction/create">
        <v-icon>mdi-plus</v-icon>
        {{ $t('expenses_income_transaction.add_expenses_income_transaction') }}
      </router-link>
      <button class="btn btn-outline-primary font-weight-bolder ml-5" @click="openAdvancedSearch">
        <span><i class="la la-filter"></i> <span>{{ $t('advanced_filter') }}</span></span>
      </button>
    </div>

    <div v-if="showAdvancedSearch" class="card card-custom mb-5">
      <div class="card-body">
        <div class="m-form m-form--fit m--margin-bottom-20">
          <div class="row mb-5">
            <div class="form-group col-md-6 mt-2 mb-2">
              <label>{{ $t('expenses_income_transaction.doc_type') }}</label>
              <select id="status" v-model="filters.doc_type" @change="changeDocTypeSelect" class="custom-select" name="">
                <option v-for="row in doc_type_list" :key="row.id" :value="row.id">{{ row.title }}</option>
              </select>
            </div>
            <div v-if="filters.doc_type == 'Customer'" class="form-group col-md-6 mt-2 mb-2">
              <label>{{ $t('expenses_income_transaction.customer') }}<span class="text-danger">*</span></label>
              <multiselect
                  id="customer_id"
                  v-model="doc_type_obj"
                  :multiple="false"
                  :options="customers"
                  :placeholder="$t('expenses_income_transaction.customer')"
                  :show-labels="false"
                  :show-no-options="false"
                  :show-no-results="false"
                  :taggable="false"
                  label="fullname"
                  track-by="id"
                  @input="setCustomerId($event)"
                  @search-change="getCustomers($event)">
              </multiselect>
            </div>
            <div v-if="filters.doc_type == 'Supplier'" class="form-group col-md-6 mt-2 mb-2">
              <label>{{ $t('expenses_income_transaction.supplier') }}<span class="text-danger">*</span></label>
              <multiselect
                  v-model="doc_type_obj"
                  :multiple="false"
                  :options="suppliers"
                  :placeholder="$t('expenses_income_transaction.supplier')"
                  :show-labels="false"
                  :show-no-options="false"
                  :show-no-results="false"
                  :taggable="false"
                  label="name"
                  track-by="id"
                  @input="setSupplierId($event)"
                  @search-change="getSuppliers($event)">
              </multiselect>
              <span class="form-text text-muted" style="text-align:end">{{
                  $t('type_at_least_three_letters') + ' ' + $t('expenses_income_transaction.supplier')
                }}.</span>

            </div>

            <div class="form-group col-md-6 mt-2 mb-2">
              <label>{{ $t('expenses_income_transaction.class') }}<span class="text-danger">*</span></label>
              <div class="input-group">
                <b-form-group v-slot="{ ariaDescribedby }" :label="''">
                  <b-form-radio-group>
                    <b-form-radio v-model="filters.class" :aria-describedby="ariaDescribedby" name="inventories-type"
                                  value="1">{{ $t('expenses_income_transaction.doc_in') }}
                    </b-form-radio>
                    <b-form-radio v-model="filters.class" :aria-describedby="ariaDescribedby" name="inventories-type"
                                  value="2">{{ $t('expenses_income_transaction.doc_out') }}
                    </b-form-radio>
                  </b-form-radio-group>
                </b-form-group>
              </div>
            </div>
            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="code">{{ $t('expenses_income_transaction.code') }}</label>
              <input id="code" v-model="filters.code" class="form-control" type="text">
            </div>
            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="from_date">{{ $t('expenses_income_transaction.from_date') }}</label>
              <input id="from_date" v-model="filters.from_date" class="form-control" type="date">
            </div>
            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="to_date">{{ $t('expenses_income_transaction.to_date') }}</label>
              <input id="to_date" v-model="filters.to_date" class="form-control" type="date">
            </div>

            <div class="form-group col-md-6 mt-2 mb-2">
              <label>{{ $t('expenses_income_transaction.currency') }}</label>
              <select id="currency_id" v-model="filters.currency_id" class="custom-select" name="">
                <option v-for="row in currencies" :key="row.id" :value="row.id">
                  {{ row.name }}
                </option>
              </select>
            </div>
            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="f_status">{{ $t('status') }}</label>
              <select id="f_status" v-model="filters.status" class="custom-select" name="" type="text">
                <option value="">{{ $t('all') }}</option>
                <option v-for="(row, index) in status_list" :key="index" :value="row.id">{{ row.title }}</option>
              </select>
            </div>


            <div class="form-group d-inline-flex col-md-6 mt-6 mb-0">
              <button id="m_search" class="btn btn-primary mt-auto mr-2" type="button" @click="doFilter">
                <span><i class="fas fa-search"></i>
                  <span>{{ $t('search') }}</span>
                </span>
              </button>
              <button id="m_reset" class="btn btn-danger mt-auto mr-2" type="button" @click="resetFilter">
                <span><i class="fas fa-trash-restore"></i>
                  <span>{{ $t('reset_search') }}</span>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--begin::customer-->
    <div class="card card-custom">

      <div class="card-body">
        <div class="d-flex justify-content-end mb-5">
          <custom-export-data v-if="$can('taxes.export_data')" :data-list.sync="dataList" :fields="json_fields"
                              :file-name="fileName"></custom-export-data>
        </div>
        <v-server-table ref="table" :class="'dataTable table-row-dashed'" :columns="columns" :options="options">


          <template slot="doc_type_name" slot-scope="props">
            <p>{{ props.row.doc_type_name }}</p>
            <p>{{ props.row.obj_name }}</p>
            <p>{{ props.row.obj_code }}</p>
          </template>
          <template slot="status" slot-scope="props">
            <!--                      v-if="$can('expenses_incomes.update')"-->
            <button v-if="$can('expenses_incomes.change_status')" class="btn btn-secondary m-btn m-btn--icon w-auto"
                    @click="changeStatus(props.row.id, props.row.status)">
              {{ $t('change_status') }} ({{ props.row.status_name }})
            </button>
            <button v-else class="btn btn-secondary m-btn m-btn--icon w-auto">
              {{ $t('change_status') }} ({{ props.row.status_name }})
            </button>
          </template>
          <template slot="actions" slot-scope="props">
            <!--               -->
            <v-btn v-if="$can('expenses_incomes.update')"
                   :to="`/finances/expenses-income-transaction/edit/${props.row.id}`" color="pink"
                   icon>
              <v-icon v-b-tooltip.hover :title="$t('edit')" class="mr-2 text-info" color="blue darken-2" small>
                mdi-pencil
              </v-icon>
            </v-btn>
            <!--               -->
            <v-icon v-if="$can('expenses_incomes.delete') && props.row.status == 1" v-b-tooltip.hover :title="$t('delete')" class="text-danger"
                    color="red darken-2" small @click="deleteItem(props.row)">mdi-delete
            </v-icon>

            <!--                        <v-btn v-b-tooltip.hover :title="$t('view')" icon color="pink" :to="`/finances/expenses-income-transaction/view/${props.row.id}`">-->
            <!--                            <v-icon small class="mr-2">mdi-eye</v-icon>-->
            <!--                        </v-btn>-->

            <b-dropdown :id="'dropdown-offset_'+props.row.id" :text="$t('more_actions') " class="m-2"
                        variant="outline-primary">
              <router-link v-if="$can('expenses_incomes.payment_details')"
                           :to="`/finances/expenses-income-transaction/doc-payment-details/${props.row.id}`"
                           class="dropdown-item">
                {{ $t('doc_payment_details.payment_details') }}
              </router-link>

              <router-link v-if="$can('expenses_incomes.printing_export_pdf') && props.row.class ==1"
                           :to="`/print/print-incomes-transactions/${props.row.id}/print`"
                           class="dropdown-item" target="_blank">
                {{ $t('expenses_incomes_print.print_transaction') }}
              </router-link>
              <router-link v-if="$can('expenses_incomes.printing_export_pdf') && props.row.class ==1"
                           :to="`/print/print-incomes-transactions/${props.row.id}/pdf`"
                           class="dropdown-item" target="_blank">
                {{ $t('export_pdf') }}
              </router-link>
              <router-link v-if="$can('expenses_incomes.printing_export_pdf') && props.row.class ==2"
                           :to="`/print/print-expenses-transactions/${props.row.id}/print`"
                           class="dropdown-item" target="_blank">
                {{ $t('expenses_incomes_print.print_transaction') }}
              </router-link>
              <router-link v-if="$can('expenses_incomes.printing_export_pdf') && props.row.class ==2"
                           :to="`/print/print-expenses-transactions/${props.row.id}/pdf`"
                           class="dropdown-item" target="_blank">
                {{ $t('export_pdf') }}
              </router-link>
              <a v-if="props.row.class == 1 && props.row.is_rollback == 0 && (props.row.doc_type_label == 'Customer' || props.row.doc_type_label == 'Supplier')"
                 class="dropdown-item"
                 href="javascript:;"
                 @click="rollbackDocument(props.row)">
                <span v-if="props.row.doc_type_label == 'Customer'">{{ $t('rollback_the_customer_receipt') }}</span>
                <span v-else-if="props.row.doc_type_label == 'Supplier'">{{
                    $t('rollback_the_supplier_receipt')
                  }}</span>
              </a>

              <a v-if="props.row.class == 2 && props.row.is_rollback == 0 && (props.row.doc_type_label == 'Customer' || props.row.doc_type_label == 'Supplier')"
                 class="dropdown-item"
                 href="javascript:;"
                 @click="rollbackDocumentExpense(props.row)">
                <span v-if="props.row.doc_type_label == 'Customer'">{{ $t('rollback_the_customer_expense') }}</span>
                <span v-else-if="props.row.doc_type_label == 'Supplier'">{{
                    $t('rollback_the_supplier_expense')
                  }}</span>
              </a>
            </b-dropdown>

          </template>
        </v-server-table>

      </div>
    </div>
    <!--end::customer-->
    <b-modal ref="status-modal" :title="$t('change_status')" hide-footer>
      <change-status-form :current-id="innerId"
                          :current-status="statusId"
                          :status-list="status_list"
                          :url="routeChangeStatus"
                          @hide-modal="hideModal()"
                          :ignore-selected-status="ignore_status_from_list[statusId]"
                          @handling-data="getDataAfterChangeStatus">
      </change-status-form>
    </b-modal>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import ChangeStatusFrom from "@/view/content/forms/ChangeStatusForm";

export default {
  name: "index-expenses",
  components: {'change-status-form': ChangeStatusFrom,},
  data() {
    return {
      mainRoute: 'finances/expenses_incomes',
      subMainRoute: 'finances/expenses_income',
      subMainRouteExpense: '/finances/expense',
      routeChangeStatus: 'finances/expenses_income/change-status',
      mainRouteDependency: 'base/dependency',

      showAdvancedSearch: false,

      filter: {
        sortBy: 'id',
      },
      filters: {
        name: null,
        code: null,
        currency_id: null,
        from_date: null,
        to_date: null,
        status: null,
        class: null,
        doc_type: null,
        doc_type_id: null,
      },
      status_list: [],
      columns: ['id', 'name', 'code', 'class_name', 'doc_type_name', 'expenses_date', 'amount', 'currency_name', 'status', 'actions'],
      data: [],
      currencies: [],
      expenses_categories: [],
      doc_type_obj: null,
      innerId: null,
      statusId: null,
      doc_type_list: [],
      dataList: [],
      suppliers: [],
      customers: [],
      ignore_status_from_list:{
        2: [1,5,6], //confirm
        3: [1,2,5,6],//paid
        5: [1,2,3,6],//cancelled
        1:[6],//pending
        4:[6],//closed
        6:[6],//revert
      }
    }
  },
  computed: {
    json_fields: function () {
      let fields = {};
      fields[this.$t('expenses_income_transaction.id')] = 'id';
      fields[this.$t('expenses_income_transaction.name')] = 'name';
      fields[this.$t('expenses_income_transaction.code')] = 'code';
      fields[this.$t('expenses_income_transaction.date')] = 'expenses_date';
      fields[this.$t('expenses_income_transaction.amount')] = 'amount';
      fields[this.$t('expenses_income_transaction.currency')] = 'currency_name';
      fields[this.$t('expenses_income_transaction.class')] = 'class_name';
      fields[this.$t('expenses_income_transaction.doc_type')] = 'doc_type_name';
      return fields;
    },
    fileName: function () {
      return this.$t('MENU.expenses_income_transaction');
    },
    options: function () {
      let that = this;
      return {
        texts: {
          loadingError: that.$t('Something_went_wrong'),
          filter: "",
          filterBy: that.$t('Filter') + ' {column}',
          count: ' ',
          filterPlaceholder: that.$t('Search_query'),
          loading: that.$t('Loading') + "...",
        },
        headings: {
          id: that.$t('expenses_income_transaction.id'),
          name: that.$t('expenses_income_transaction.name'),
          code: that.$t('expenses_income_transaction.code'),
          expenses_date: that.$t('expenses_income_transaction.date'),
          amount: that.$t('expenses_income_transaction.amount'),
          currency_name: that.$t('expenses_income_transaction.currency'),
          class_name: that.$t('expenses_income_transaction.class'),
          doc_type_name: that.$t('expenses_income_transaction.doc_type'),
          status: that.$t('status'),
          actions: that.$t('actions'),
        },
        sortable: ['name'],
        filterByColumn: false,
        filterable: false,
        customFilters: [{
          name: 'alphabet',

        }],
        orderBy: {'column': 'id'},

        alwaysShowPerPageSelect: false,
        perPage: 10,
        pagination: {chunk: 5, dropdown: false},
        skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
        sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
        perPageValues: [5, 10, 15, 25],

        requestFunction(data) {
          console.log(data);
          let _params = {
            ascending: data.ascending,
            byColumn: data.byColumn,
            perPage: data.limit,
            orderBy: data.orderBy,
            page: data.page,
            filter: data.query,
            ...that.filters,
          }
          return ApiService.query(`${that.mainRoute}`, {..._params});

        },
        responseAdapter(resp) {
          that.dataList = resp.data.data.data;
          return {
            data: resp.data.data.data,
            count: resp.data.data.total,
          }
        }
      }

    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.expenses_income_transaction")}]);
    this.docTypeListInit();
    this.getCurrencies();
    this.getStatusList();
    this.getCategories();
  },
  methods: {
    getSuppliers: _.debounce(async function (filter) {
      if (filter && filter.length >= 3)
        await ApiService.get(this.mainRouteDependency + "/suppliers", {params: {filter: filter}}).then((response) => {
          this.suppliers = response.data.data;
        });
    }, 500),
    getCustomers: _.debounce(async function (filter) {
      if (filter && filter.length >= 3) {
        await ApiService.get(`${this.mainRouteDependency}/customers`, {params: {filter: filter}}).then((response) => {
          this.customers = response.data.data;
        });
      }
    }, 500),
    changeDocTypeSelect() {
      this.doc_type_obj = null;
    },
    setCustomerId(event) {
      this.filters.doc_type_id = event.id;
    },
    setSupplierId(event) {
      this.filters.doc_type_id = event.id;
    },
    docTypeListInit() {
      this.doc_type_list = [];
      if (this.$can('customers.list')) {
        this.doc_type_list.push({id: 'Customer', title: this.$t('expenses_income_transaction.customer')})
      }
      if (this.$can('suppliers.list')) {
        this.doc_type_list.push({id: 'Supplier', title: this.$t('expenses_income_transaction.supplier')})
      }
      if (this.$can('employees.list')) {
        this.doc_type_list.push({id: 'Employee', title: this.$t('expenses_income_transaction.employee')})
      }

      this.doc_type_list.push({id: 'Others', title: this.$t('expenses_income_transaction.others')})


    },
    changeStatus(id, status) {
      this.innerId = id;
      this.statusId = status;
      this.showModal();
    },
    openAdvancedSearch() {
      this.showAdvancedSearch = !this.showAdvancedSearch;
    },
    doFilter() {
      this.$refs.table.refresh();
    },
    resetFilter() {
      this.filters.name = null;
      this.filters.currency_id = null;
      this.filters.code = null;
      this.filters.from_date = null;
      this.filters.to_date = null;
      this.filters.status = null;
      this.filters.class = null;
      this.filters.doc_type = null;
      this.filters.doc_type_id = null;

      this.$refs.table.refresh();
    },
    getFetch() {
      this.$refs.table.refresh();
    },

    deleteItem(item) {
      this.$confirmAlert('', this.actionDelete, item);
    },

    rollbackDocument(item) {
      this.$confirmAlert(this.$t('do_you_want_rollback'), this.actionRollbackDocument, item);
    },

    rollbackDocumentExpense(item) {
      this.$confirmAlert(this.$t('do_you_want_rollback'), this.actionRollbackDocumentExpense, item);
    },
    getCurrencies() {
      ApiService.get(this.mainRouteDependency + "/currencies").then((response) => {
        this.currencies = response.data.data;
      });
    },
    getStatusList() {
      ApiService.get(this.mainRouteDependency + "/exp_status").then((response) => {
        this.status_list = response.data.data;
      });
    },
    actionDelete(item) {
      ApiService.delete(`${this.mainRoute}/${item.id}`).then((response) => {
        this.getFetch();
        this.$successAlert(response.data.message)
      }).catch((error) => {
        this.$errorAlert(error);
      })
    },
    actionRollbackDocument(item) {
      let _id = item.id ? item.id : null;
      let _doc_type = item.doc_type_label ? item.doc_type_label.toLowerCase() : null;

      if (_id && _doc_type) {
        ApiService.patch(`${this.subMainRoute}/${_id}/${_doc_type}/revert`).then((response) => {
          this.$successAlert(response.data.message)
          if (item.doc_type_label == 'Customer'){
            this.adjustCustomerBalance(_id);
          }else if (item.doc_type_label == 'Supplier'){
            this.adjustSupplierBalance(_id);
          }else{
            this.getFetch();
          }
        }).catch((error) => {
          this.$errorAlert(error);
        })
      }

    },
    actionRollbackDocumentExpense(item) {
      let _id = item.id ? item.id : null;
      let _doc_type = item.doc_type_label ? item.doc_type_label.toLowerCase() : null;

      if (_id && _doc_type) {
        ApiService.patch(`${this.subMainRouteExpense}/${_id}/${_doc_type}/revert`).then((response) => {
          this.$successAlert(response.data.message)
          if (item.doc_type_label == 'Customer'){
            this.adjustCustomerBalance(_id);
          }else if (item.doc_type_label == 'Supplier'){
            this.adjustSupplierBalance(_id);
          }else{
            this.getFetch();
          }
          // this.$successAlert(response.data.message)
        }).catch((error) => {
          this.$errorAlert(error);
        })
      }

    },

    /**
     * this is function call after actionRollbackDocumentExpense completed
     * for supplier
     * @param id
     */
    adjustSupplierBalance(id) {
      ApiService.post(`purchases/suppliers/${id}/adjust-balance-statement`)
          .then((response) => {
            this.getFetch();
            this.$successAlert(response.data.message);
          })
          .catch((error) => {
            this.$errorAlert(error);
          });
    },
    /**
     * this is function call after actionRollbackDocumentExpense completed
     * for customer
     * @param id
     */
    adjustCustomerBalance(id) {
      ApiService.post(`customers/customers/${id}/adjust-balance`)
          .then((response) => {
            this.getFetch();
            this.$successAlert(response.data.message);
          })
          .catch((error) => {
            this.$errorAlert(error);
          });
    },

    getCategories() {
      ApiService.get(this.mainRouteDependency + "/expenses_categories").then((response) => {
        this.expenses_categories = response.data.data;
      });
    },


    showModal() {
      this.$refs['status-modal'].show()
    },
    hideModal() {
      this.$refs['status-modal'].hide();
    },
    getDataAfterChangeStatus() {
      this.innerId = null;
      this.statusId = null;
      this.doFilter();
    },
  },
};
</script>
