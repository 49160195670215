<template>
  <div>
    <div class="card-toolbar mb-5">
      <router-link v-if="$can('general_sales.create')" to="/sales/general_sales/create" class="btn btn-primary font-weight-bolder">
        <v-icon>mdi-plus</v-icon>
        {{ $t('sales_invoices.add_general_sales') }}
      </router-link>
      <button @click="openAdvancedSearch" class="btn btn-outline-primary font-weight-bolder ml-5">
        <span><i class="la la-filter"></i> <span>{{ $t('advanced_filter') }}</span></span>
      </button>
    </div>

    <div class="card card-custom mb-5" v-if="showAdvancedSearch">
      <div class="card-body">
        <div class="m-form m-form--fit m--margin-bottom-20">
          <div class="row mb-5">

            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="customer_id">{{ $t('sales_invoices.customer') }}</label>
              <multiselect
                  id="customer_id"
                  v-model="customer"
                  :placeholder="$t('sales_invoices.customer')"
                  label="fullname"
                  track-by="id"
                  :options="customers"
                  :multiple="false"
                  :taggable="false"
                  :show-labels="false"
                  :show-no-options="false"
                  :show-no-results="false"
                  @search-change="getCustomers($event)">
              </multiselect>
            </div>

            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="invoice_code">{{ $t('sales_invoices.invoice_code') }}</label>
              <input v-model="filters.invoice_code" type="text" id="invoice_code" class="form-control">
            </div>
            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="from_date">{{ $t('sales_invoices.from_date') }}</label>
              <input v-model="filters.from_date" type="date" id="from_date" class="form-control">
            </div>
            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="to_date">{{ $t('sales_invoices.to_date') }}</label>
              <input v-model="filters.to_date" type="date" id="to_date" class="form-control">
            </div>

            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="from_amount">{{ $t('sales_invoices.from_amount') }}</label>
              <input v-model="filters.from_amount" type="number" id="from_amount" class="form-control">
            </div>
            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="to_amount">{{ $t('sales_invoices.to_amount') }}</label>
              <input v-model="filters.to_amount" type="number" id="to_amount" class="form-control">
            </div>

            <div class="form-group col-md-6 mt-2 mb-2">
              <label>{{ $t('sales_invoices.currency') }}</label>
              <select name="" id="currency_id" v-model="filters.currency_id" class="custom-select">
                <option v-for="row in currencies" :value="row.id" :key="row.id">
                  {{ row.name }}
                </option>
              </select>
            </div>

            <div class="form-group col-md-6 mt-2 mb-2">
              <label>{{ $t('sales_invoices.added_by') }}</label>
              <multiselect
                  v-model="user"
                  :placeholder="$t('sales_invoices.added_by')"
                  label="name"
                  track-by="id"
                  :options="users"
                  :multiple="false"
                  :taggable="false"
                  :show-labels="false"
                  :show-no-options="false"
                  :show-no-results="false"
                  @search-change="getUsers($event)">
              </multiselect>
            </div>

            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="f_status">{{ $t('status') }}</label>
              <select name="" id="f_status" v-model="filters.status" type="text" class="custom-select">
                <option value="">{{ $t('all') }}</option>
                <option v-for="(row, index) in status_list" :value="row.id" :key="index">{{ row.title }}</option>
              </select>
            </div>

            <div class="form-group d-inline-flex col-md-6 mt-10">
              <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                              <span><i class="fas fa-search"></i>
                                  <span>{{ $t('search') }}</span>
                              </span>
              </button>
              <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="m_reset">
                              <span><i class="fas fa-trash-restore"></i>
                                  <span>{{ $t('reset_search') }}</span>
                              </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--begin::sales-->
    <div class="card card-custom">

      <div class="card-body">

        <!-- Filter -->
        <div class="mb-4">
          <button class="btn mr-2 ml-2 btn-sm" :class="{'btn-primary': (filters.status == null && filters.is_deleted == 0), 'btn-outline-primary': (filters.status != null || filters.is_deleted == 1)}" @click="selectStatus(null)">{{$t('all')}}</button>
          <button class="btn mr-2 ml-2 btn-sm" v-for="(row, index) in filters_status_list" :key="'status_filter'+index" :class="{[row.class_name_active]: filters.status == row.id, [row.class_name]: filters.status != row.id}" @click="selectStatus(row.id)">{{row.title}}</button>
          <button class="btn mr-2 ml-2 btn-sm" :class="{'btn-danger': filters.is_deleted == 1, 'btn-outline-danger': filters.is_deleted == 0}" @click="selectIsDeleted">{{$t('deleted_invoices')}}</button>

        </div>
        <!-- End Filter -->

        <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">
          <template slot="#" slot-scope="props">
            <span>{{props.index}}</span>
          </template>
          <template slot="status" slot-scope="props">
            <button v-if="$can('general_sales.change_status') && !props.row.deleted_at" @click="changeStatus(props.row.id, props.row.status)" class="btn btn-secondary m-btn m-btn--icon w-auto">
              {{ props.row.status_name }}
            </button>
            <button v-else class="btn btn-secondary m-btn m-btn--icon w-auto">
              {{ props.row.status_name }}
            </button>
          </template>
          <template slot="actions" slot-scope="props" v-if="!props.row.deleted_at">

            <div class="d-flex justify-content-center">
              <router-link v-if="$can('general_sales.clone') && props.row.status != 4" v-b-tooltip.hover :title="$t('clone')" class="mr-2 text-h-center" :to="`/sales/general_sales/clone/${props.row.id}`">
                <i class="fa fa-copy text-info"></i>
              </router-link>
              <router-link  v-if="$can('general_sales.view')"  v-b-tooltip.hover :title="$t('view')" class="mr-2 text-h-center" :to="`/sales/general_sales/view/${props.row.id}`">
                <i class="fa fa-eye text-info"></i>
              </router-link>
              <v-btn v-b-tooltip.hover v-if="$can('general_sales.update') && props.row.status != 4" :title="$t('edit')" icon color="pink" :to="`/sales/general_sales/edit/${props.row.id}`">
                <v-icon small class="text-info">mdi-pencil</v-icon>
              </v-btn>


              <v-icon small class="text-danger" v-if="$can('general_sales.delete') && props.row.status == 1" v-b-tooltip.hover :title="$t('delete')" @click="deleteItem(props.row)">mdi-delete</v-icon>

              <router-link v-if="$can('general_sales.invoice_status') && props.row.status != 4" v-b-tooltip.hover :title="$t('MENU.invoice_status')" class="mr-2 text-h-center" :to="`/sales/invoice-status/${props.row.id}/GeneralSales`">
                <i class="fa fa-file-invoice text-info"></i>
              </router-link>

              <a href="javascript:;" @click="showRelatedRefundModal(props.row.id)" v-if="props.row.number_of_returns > 0" class="m-2 text-h-center"> 
                <i class="fas fa-bezier-curve"></i>
              </a>

              <b-dropdown v-if="props.row.status != 4" :id="'dropdown-offset_'+props.row.id" :text="$t('return_and_payment') " variant="outline-primary" class="m-2">
<!--                <router-link class="dropdown-item" v-if="$can('general_sales.payment_sales_invoices') && (props.row.status == 2 || props.row.status == 5) && props.row.is_paid != 1" :to="`/sales/payment_sales_invoices/create/${props.row.id}/invoice`">-->
                <a href="javascript:;" class="dropdown-item" v-if="isSystemUser" @click="adjustmentPayment(props.row)">
                  {{ $t('adjust_payment') }}
                </a>
                <router-link class="dropdown-item" v-if="$can('general_sales.payment_sales_invoices') && props.row.status != 1 && props.row.final_remaining_amount != 0" :to="`/sales/payment_sales_invoices/create/${props.row.id}/invoice`">
                  {{ $t('MENU.payment_sales_invoices') }}
                </router-link>
                <router-link class="dropdown-item" v-if="$can('general_sales.sales_refund') && props.row.status == 5  && !props.row.is_apply_taxes_invoice" :to="`/sales/general_sales_refund/create/${props.row.id}`">
                  {{ $t('MENU.sales_refund') }}
                </router-link>

                <router-link  v-if="$can('general_sales.installments') && props.row.is_paid != 1" class="dropdown-item" :to="`/installment/installment/create/${props.row.id}?type_type=GeneralSales`">
                  {{ $t('MENU.installment') }}
                </router-link>

                <router-link v-if="$can('general_sales.add_to_cost_center')" class="dropdown-item" :to="`/sales/cost-center/create/${props.row.id}?type_type=GeneralSales`">
                  {{ $t('MENU.add_to_cost_center') }}
                </router-link>

                <router-link v-if="$can('general_sales.add_sales_credit') && props.row.is_apply_taxes_invoice && props.row.status == 5" class="dropdown-item" :to="`/sales/general-credits/create/${props.row.id}`">
                  {{ $t('MENU.add_sales_credit') }} ({{ $t('MENU.refund') }})
                </router-link>

                <router-link v-if="$can('general_sales.add_sales_debit') && props.row.status == 2" class="dropdown-item" :to="`/sales/general-debits/create/${props.row.id}`">
                  {{ $t('MENU.add_sales_debit') }} ({{ ($t('MENU.debit')) }})
                </router-link>
                <router-link v-if="$can('sales_invoices.payment_details') && isPaid(props.row.is_paid)" class="dropdown-item" :to="`/sales/general_sales/payment_details/${props.row.id}`">
                  {{ $t('payment_details') }}
                </router-link>
              </b-dropdown>

              <b-dropdown v-if="props.row.status != 4" :id="'dropdown-offset_'+props.row.id" :text="$t('invoice_and_procedures') " variant="outline-primary" class="m-2">
                <template v-if="props.row.is_apply_taxes_invoice">
                  <div class="dropdown-item d-flex justify-content-between" v-if="$can('general_sales.pdf_print_tax_invoice')">
                    <label>{{ $t('tax_invoice_printing') }}</label>
                    <div class="d-flex justify-content-between">
                      <router-link target="_blank" class="ml-3 mr-3" v-b-tooltip.hover :title="$t('print')" :to="`/print/general-sales-tax-invoice/${props.row.id}/print`">
                        <i class="fas fa-print"></i>
                      </router-link>
                      <router-link target="_blank" class="ml-3 mr-3" v-b-tooltip.hover :title="$t('pdf')" :to="`/print/general-sales-tax-invoice/${props.row.id}/pdf`">
                        <i class="fas fa-file-pdf"></i>
                      </router-link>
                    </div>
                  </div>
                </template>

                <template v-else>
                  <div class="dropdown-item d-flex justify-content-between" v-if="$can('general_sales.printing_export_pdf')">
                    <label>{{ $t('MENU.invoice_printing') }}</label>
                    <div class="d-flex justify-content-between">
                      <router-link target="_blank" class="ml-3 mr-3" v-b-tooltip.hover :title="$t('print')" :to="`/print/general-sales/${props.row.id}/print`">
                        <i class="fas fa-print"></i>
                      </router-link>
                      <router-link target="_blank" class="ml-3 mr-3" v-b-tooltip.hover :title="$t('pdf')" :to="`/print/general-sales/${props.row.id}/pdf`">
                        <i class="fas fa-file-pdf"></i>
                      </router-link>
                    </div>
                  </div>
                </template>


                <!-- <div class="dropdown-item d-flex justify-content-between" v-if="$can('general_sales.pdf_print_tax_invoice_simplified')">
                  <label> {{ $t('tax_invoice_printing_simplified') }}</label>
                  <div class="d-flex justify-content-between">
                    <router-link target="_blank" class="ml-3 mr-3" v-b-tooltip.hover :title="$t('print')" :to="`/print/general-sales-tax-invoice/${props.row.id}/print/simplified`">
                      <i class="fas fa-print"></i>
                    </router-link>
                    <router-link target="_blank" class="ml-3 mr-3" v-b-tooltip.hover :title="$t('pdf')" :to="`/print/general-sales-tax-invoice/${props.row.id}/pdf/simplified`">
                      <i class="fas fa-file-pdf"></i>
                    </router-link>
                  </div>
                </div> -->

<!--                <div class="dropdown-item d-flex justify-content-between" v-if="$can('general_sales.printing_export_pdf')">-->
<!--                  <label>{{ $t('MENU.invoice_printing_80_mm') }}</label>-->
<!--                  <div class="d-flex justify-content-between">-->
<!--                    <router-link target="_blank" class="ml-3 mr-3" v-b-tooltip.hover :title="$t('print')" :to="`/print/general-sales-80mm-invoice/${props.row.id}/print`">-->
<!--                      <i class="fas fa-print"></i>-->
<!--                    </router-link>-->
<!--                    <router-link target="_blank" class="ml-3 mr-3" v-b-tooltip.hover :title="$t('pdf')" :to="`/print/general-sales-80mm-invoice/${props.row.id}/pdf`">-->
<!--                      <i class="fas fa-file-pdf"></i>-->
<!--                    </router-link>-->
<!--                  </div>-->
<!--                </div>-->

                <!-- <router-link v-if="$can('general_sales.send_mail')" class="dropdown-item" :to="`/settings/custom/email/${props.row.id}/GeneralSales`">
                  {{ $t('send_mail') }}
                </router-link> -->
                <div class="dropdown-item d-flex justify-content-between" v-if="$can('sales_invoices.send_mail')">
                  <label>{{ $t('send_mail_share') }}</label>
                  <div class="d-flex justify-content-between">
                    <router-link target="_blank" class="ml-3 mr-3" v-b-tooltip.hover :title="$t('send_mail')" :to="`/settings/custom/email/${props.row.id}/GeneralSales`">
                      <i class="far fa-envelope"></i>
                    </router-link>
<!--                    <router-link target="_blank" class="ml-3 mr-3" v-b-tooltip.hover :title="$t('share')" :to="`/print/general-sales-80mm-invoice/${props.row.id}/pdf_share`">-->
<!--                      <i class="fab fa-whatsapp"></i>-->
<!--                    </router-link>-->
                  </div>
                </div>
                <button v-if="canPaymentRollback(props.row.is_paid)" class="dropdown-item"
                        @click="paymentRollbackItem(props.row.id)">
                  {{ $t('payment_rollback') }}
                </button>
                <router-link v-if="$can('general_sales.attachment_notes')" class="dropdown-item" :to="`/sales/attachment-notes/create/${props.row.id}/${'GeneralSales'}`">
                  {{ $t('MENU.add_notes_attachments') }}
                </router-link>
                <router-link v-if="$can('general_sales.schedule_appointment')" class="dropdown-item" :to="`/sales/appointment/create/${props.row.id}/${'GeneralSales'}`">
                  {{ $t('MENU.schedule_appointments') }}
                </router-link>

              </b-dropdown>

              <b-dropdown v-if="props.row.status != 4" :id="'dropdown-offset_'+props.row.id" :text="$t('shipping_and_delivery') " variant="outline-primary" class="m-2">


                <div class="dropdown-item d-flex justify-content-between" v-if="$can('general_sales.pdf_print_packing_slip')">
                  <label> {{ $t('sales_printing.print_packing_slip') }}</label>
                  <div class="d-flex justify-content-between">
                    <router-link target="_blank" class="ml-3 mr-3" v-b-tooltip.hover :title="$t('print')" :to="`/print/packing-general-sales/${props.row.id}/print`">
                      <i class="fas fa-print"></i>
                    </router-link>
<!--                    <router-link target="_blank" class="ml-3 mr-3" v-b-tooltip.hover :title="$t('pdf')" :to="`/print/packing-general-sales/${props.row.id}/pdf`">-->
<!--                      <i class="fas fa-file-pdf"></i>-->
<!--                    </router-link>-->
                  </div>
                </div>

                <div class="dropdown-item d-flex justify-content-between" v-if="$can('general_sales.pdf_package')">
                  <label> {{ $t('sales_printing.print_package_label') }}</label>
                  <div class="d-flex justify-content-between">
                    <router-link target="_blank" class="ml-3 mr-3" v-b-tooltip.hover :title="$t('print')" :to="`/print/sales/package-label-print-general-sales/${props.row.id}/print`">
                      <i class="fas fa-print"></i>
                    </router-link>
<!--                    <router-link target="_blank" class="ml-3 mr-3" v-b-tooltip.hover :title="$t('pdf')" :to="`/print/sales/package-label-print-general-sales/${props.row.id}/pdf`">-->
<!--                      <i class="fas fa-file-pdf"></i>-->
<!--                    </router-link>-->
                  </div>
                </div>

                <div class="dropdown-item d-flex justify-content-between" v-if="$can('general_sales.print_pdf_receive')">
                  <label> {{ $t('sales_printing.print_receive_list') }}</label>
                  <div class="d-flex justify-content-between">
                    <router-link target="_blank" class="ml-3 mr-3" v-b-tooltip.hover :title="$t('print')" :to="`/print/sales/receive-list-print-general-sales/${props.row.id}/print`">
                      <i class="fas fa-print"></i>
                    </router-link>
<!--                    <router-link target="_blank" class="ml-3 mr-3" v-b-tooltip.hover :title="$t('pdf')" :to="`/print/sales/receive-list-print-general-sales/${props.row.id}/pdf`">-->
<!--                      <i class="fas fa-file-pdf"></i>-->
<!--                    </router-link>-->
                  </div>
                </div>

                <div class="dropdown-item d-flex justify-content-between" v-if="$can('general_sales.print_pdf_delivery')">
                  <label> {{ $t('sales_printing.print_delivery_label') }}</label>
                  <div class="d-flex justify-content-between">
                    <router-link target="_blank" class="ml-3 mr-3" v-b-tooltip.hover :title="$t('print')" :to="`/print/sales/delivery-label-print-general-sales/${props.row.id}/print`">
                      <i class="fas fa-print"></i>
                    </router-link>
<!--                    <router-link target="_blank" class="ml-3 mr-3" v-b-tooltip.hover :title="$t('pdf')" :to="`/print/sales/delivery-label-print-general-sales/${props.row.id}/pdf`">-->
<!--                      <i class="fas fa-file-pdf"></i>-->
<!--                    </router-link>-->
                  </div>
                </div>
                <!--                <router-link v-if="$can('general_sales.printing_export_pdf')" target="_blank" class="dropdown-item" :to="`/print/general-sales/${props.row.id}/print`">-->
                <!--                  {{ $t('MENU.invoice_printing') }}-->
                <!--                </router-link>-->
                <!--                <router-link v-if="$can('general_sales.printing_export_pdf')" target="_blank" class="dropdown-item" :to="`/print/general-sales/${props.row.id}/pdf`">-->
                <!--                  {{ $t('export_invoice_pdf') }}-->
                <!--                </router-link>-->

                <!--                <router-link v-if="$can('general_sales.pdf_print_tax_invoice')" target="_blank" class="dropdown-item" :to="`/print/general-sales-tax-invoice/${props.row.id}/print`">-->
                <!--                  {{ $t('tax_invoice_printing') }}-->
                <!--                </router-link>-->
                <!--                <router-link v-if="$can('general_sales.pdf_print_tax_invoice')" target="_blank" class="dropdown-item" :to="`/print/general-sales-tax-invoice/${props.row.id}/pdf`">-->
                <!--                  {{ $t('export_tax_invoice_pdf') }}-->
                <!--                </router-link>-->

                <!--                <router-link v-if="$can('general_sales.pdf_print_tax_invoice_simplified')" target="_blank" class="dropdown-item" :to="`/print/general-sales-tax-invoice/${props.row.id}/print/simplified`">-->
                <!--                  {{ $t('tax_invoice_printing_simplified') }}-->
                <!--                </router-link>-->
                <!--                <router-link v-if="$can('general_sales.pdf_print_tax_invoice_simplified')" target="_blank" class="dropdown-item" :to="`/print/general-sales-tax-invoice/${props.row.id}/pdf/simplified`">-->
                <!--                  {{ $t('export_tax_invoice_pdf_simplified') }}-->
                <!--                </router-link>-->


                <!--                <router-link  v-if="$can('general_sales.pdf_print_packing_slip')" target="_blank" class="dropdown-item" :to="`/print/packing-general-sales/${props.row.id}/print`">-->
                <!--                  {{ $t('sales_printing.print_packing_slip') }}-->
                <!--                </router-link>-->
                <!--                <router-link v-if="$can('general_sales.pdf_print_packing_slip')" target="_blank" class="dropdown-item" :to="`/print/packing-general-sales/${props.row.id}/pdf`">-->
                <!--                  {{ $t('sales_printing.pdf_packing_slip') }}-->
                <!--                </router-link>-->

                <!--                <router-link v-if="$can('general_sales.pdf_package')" target="_blank" class="dropdown-item" :to="`/print/sales/package-label-print-general-sales/${props.row.id}/print`">-->
                <!--                  {{ $t('sales_printing.print_package_label') }}-->
                <!--                </router-link>-->
                <!--                <router-link v-if="$can('general_sales.pdf_package')" target="_blank" class="dropdown-item" :to="`/print/sales/package-label-print-general-sales/${props.row.id}/pdf`">-->
                <!--                  {{ $t('sales_printing.pdf_package_label') }}-->
                <!--                </router-link>-->

                <!--                <router-link v-if="$can('general_sales.print_pdf_receive')" target="_blank" class="dropdown-item" :to="`/print/sales/receive-list-print-general-sales/${props.row.id}/print`">-->
                <!--                  {{ $t('sales_printing.print_receive_list') }}-->
                <!--                </router-link>-->
                <!--                <router-link v-if="$can('general_sales.print_pdf_receive')" target="_blank" class="dropdown-item" :to="`/print/sales/receive-list-print-general-sales/${props.row.id}/pdf`">-->
                <!--                  {{ $t('sales_printing.pdf_receive_list') }}-->
                <!--                </router-link>-->


                <!--                <router-link v-if="$can('general_sales.print_pdf_delivery')" target="_blank" class="dropdown-item" :to="`/print/sales/delivery-label-print-general-sales/${props.row.id}/print`">-->
                <!--                  {{ $t('sales_printing.print_delivery_label') }}-->
                <!--                </router-link>-->
                <!--                <router-link v-if="$can('general_sales.print_pdf_delivery')" target="_blank" class="dropdown-item" :to="`/print/sales/delivery-label-print-general-sales/${props.row.id}/pdf`">-->
                <!--                  {{ $t('sales_printing.pdf_delivery_label') }}-->
                <!--                </router-link>-->

              </b-dropdown>


            </div>

          </template>
        </v-server-table>

      </div>
    </div>
    <!--end::customer-->
    <b-modal ref="status-modal" size="md" hide-footer :title="$t('change_status')">
      <change-status-form @hide-modal="hideModal()"
                          :status-list="status_list"
                          :url="routeChangeStatus"
                          :current-id="innerId"
                          :current-status="statusId"
                          :ignore-selected-status="ignore_status_from_list[statusId]? ignore_status_from_list[statusId] :[6,7]"
                          @handling-data="getDataAfterChangeStatus">
      </change-status-form>
    </b-modal>

    <b-modal ref="related-refund-modal" size="md" hide-footer :title="$t('sales_invoices.refund_invoice_infromation')">
      <div class="row">
        <div class="col-md-12">
          <table class="table">
            <thead>
              <tr>
                <th>{{ $t('sales_invoices.transaction_type') }}</th>
                <th>{{ $t('sales_invoices.transaction_code') }}</th>
                <th>{{ $t('sales_invoices.transaction_date') }}</th>
                <th>{{ $t('sales_invoices.transaction_status') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(_transaction, _index) in transaction_data" :key="'_transaction'+_index">
                <td>{{ _transaction.type_name ? _transaction.type_name : null }}</td>
                <td>
                  <router-link v-if="$can('general_credits.update') && _transaction.type ==	'SalesCredit'" :to="`/sales/general-credits/edit/${_transaction.id}`">
                    {{ _transaction.code ? _transaction.code  : null }}
                  </router-link>
                  <router-link v-else-if="$can('general_sales_refund.update') && _transaction.type ==	'SalesInvoiceRefund'" :to="`/sales/general_sales_refund/edit/${_transaction.id}`">
                    {{ _transaction.code ? _transaction.code  : null }}
                  </router-link>
                  <span v-else>{{ _transaction.code ? _transaction.code  : null }}</span>
                </td>
                <td>{{ _transaction.created_at ? _transaction.created_at : null }}</td>
                <td>{{ _transaction.status_name ? _transaction.status_name : null }}</td>
              </tr>
            </tbody>
        </table>
        </div>
        <div class="col-md-12">
          <button class="btn btn-secondary" @click="hideRelatedRefundModal">{{ $t('close') }}</button>
        </div>
      </div>
    </b-modal>
    
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import ChangeStatusFrom from "@/view/content/forms/ChangeStatusForm";
import {mapGetters} from "vuex";


export default {
  name: "index-general-sales-order",
  components: {'change-status-form': ChangeStatusFrom,},
  data() {
    return {
      mainRoute: 'sales/general_sales',
      mainRouteInformationsCreditRefunds: 'sales/general_sales',
      mainRouteAdjustBalance: 'sales/sales_invoices',
      routeChangeStatus: 'sales/general_sale/change-status',
      mainRouteDependency: 'base/dependency',

      showAdvancedSearch: false,

      filter: {
        sortBy: 'id',
      },
      filters: {
        customer_id: null,
        invoice_code: this.$route.query.invoice_number ? this.$route.query.invoice_number : '',
        from_date: null,
        to_date: null,
        currency_id: null,
        // status: null,
        from_amount: null,
        to_amount: null,
        user_id: null,
        is_deleted: 0,
      },
      status_list: [],
      //'currency_name',
      columns: ['#','invoice_code', 'invoice_date', 'customer_name', 'invoice_total', 'total_paid_amount', 'is_paid_status_name', 'status', 'actions'],
      data: [],
      currencies: [],
      customers: [],

      innerId: null,
      statusId: null,
      customer: null,
      user: null,
      users: [],
      filters_status_list: [],
      ignore_status_from_list:{
        1: [5,6,7],
        2: [4,6,7],
      },
      transaction_data: [],
    }
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
    isSystemUser: function(){
      return this.currentUserPersonalInfo.is_system_user
    },
    options: function () {
      let that = this;
      return {
        texts: {
          loadingError: that.$t('Something_went_wrong'),
          filter: "",
          filterBy: that.$t('Filter') + ' {column}',
          count: ' ',
          filterPlaceholder: that.$t('Search_query'),
          loading: that.$t('Loading') + "...",
        },
        headings: {
          customer_name: that.$t('sales_invoices.customer'),
          invoice_date: that.$t('sales_invoices.invoice_date'),
          invoice_code: that.$t('sales_invoices.invoice_code'),
          invoice_total: that.$t('sales_invoices.total'),
          currency_name: that.$t('sales_invoices.currency'),
          total_commission: that.$t('sales_invoices.total_commission'),
          is_paid_status_name: that.$t('sales_invoices.payment_status_label'),
          total_paid_amount: that.$t('paid_amount_label'),
          // employee_user_name: that.$t('user'),
          status: that.$t('status'),
          actions: that.$t('actions'),
        },
        sortable: ['name'],
        filterByColumn: false,
        filterable: false,
        customFilters: [{
          name: 'alphabet',

        }],
        orderBy: {'column': 'id'},

        alwaysShowPerPageSelect: false,
        perPage: 10,
        pagination: {chunk: 5, dropdown: false},
        skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
        sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
        perPageValues: [5, 10, 15, 25],

        requestFunction(data) {
          let _params = {
            ascending: data.ascending,
            byColumn: data.byColumn,
            perPage: data.limit,
            orderBy: data.orderBy,
            page: data.page,
            filter: data.query,
            ...that.filters,
          }
          return ApiService.query(`${that.mainRoute}`, {..._params});

        },
        responseAdapter(resp) {
          return {
            data: resp.data.data.data,
            count: resp.data.data.total,
          }
        }
      }

    },
  },
  watch: {
    user: function (val) {
      if (val) {
        this.filters.user_id = val.id;
      } else {
        this.filters.user_id = null;
      }
    },
    customer: function (val) {
      if (val) {
        this.filters.customer_id = val.id;
      } else {
        this.filters.customer_id = null;
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.general_sales")}]);
    if (this.filters.invoice_code) {
      this.showAdvancedSearch = true;
    }
    this.getCurrencies();
    // this.getCustomers();
    // this.getUsers();
    this.getStatusList();
  },
  methods: {
    isPaid(is_paid){
      return (is_paid == 2 || is_paid == 1);
    },
    canPaymentRollback(is_paid){
      return !!(is_paid === 2 || is_paid === 1);
    },
    paymentRollbackItem(id) {
      this.$confirmAlert(this.$t('do_you_want_to_payment_rollback'), this.paymentRollback, id);
    },
    paymentRollback(id) {
      if (id)
        ApiService.get(`${this.mainRoute}/${id}/reverse_payment`).then((response) => {
          this.$successAlert(response.data.message);
          this.$refs.table.refresh();
        }).catch((error) => {
          this.$errorAlert(error)
        })
    },
    getStatusList() {
      ApiService.get(this.mainRouteDependency + "/sales_status").then((response) => {
        this.status_list = response.data.data;
        let _ids = [1,2,5];
        this.filters_status_list = response.data.data.filter((row) => _ids.includes(row.id));
        this.filters_status_list = this.filters_status_list.map((row)=>{
          switch (row.id){
            case 1:
              row.class_name = 'btn-outline-warning';
              row.class_name_active = 'btn-warning';
              break;
            case 2:
              row.class_name = 'btn-outline-primary';
              row.class_name_active = 'btn-primary';
              break;
            case 5:
              row.class_name = 'btn-outline-dark';
              row.class_name_active = 'btn-dark';
              break;
          }

          return row;
        })
      });
    },

    showModal() {
      this.$refs['status-modal'].show()
    },
    hideModal() {
      this.$refs['status-modal'].hide();
    },

    showRelatedRefundModal(id) {
      this.getInformationOfCreditRefund(id)
      this.$refs['related-refund-modal'].show()
    },
    hideRelatedRefundModal() {
      this.transaction_data = [];
      this.$refs['related-refund-modal'].hide();
    },


    changeStatus(id, status) {
      this.innerId = id;
      this.statusId = status;
      this.showModal();
    },
    getDataAfterChangeStatus() {
      this.innerId = null;
      this.statusId = null;
      this.doFilter();
    },

    openAdvancedSearch() {
      this.showAdvancedSearch = !this.showAdvancedSearch;
    },
    reloadSelectStatus(){
      let _filters_status_list = [];
      _filters_status_list = [...this.filters_status_list];
      this.filters_status_list = _filters_status_list;
    },
    selectStatus(id){
      this.filters.status = null;
      this.reloadSelectStatus();
      this.filters.status = id;
      this.filters.is_deleted = 0;
      this.doFilter();
    },
    selectIsDeleted(){
      this.filters.status = null;
      this.reloadSelectStatus();
      this.filters.is_deleted = 1;
      this.doFilter();
    },
    doFilter() {
      this.$refs.table.refresh();
    },
    resetFilter() {
      this.filters.customer_id = null;
      this.filters.invoice_code = null;
      this.filters.from_date = null;
      this.filters.to_date = null;
      this.filters.currency_id = null;
      this.filters.user_id = null;
      this.filters.status = null;
      this.filters.from_amount = null;
      this.filters.to_amount = null;
      this.user = null;
      this.customer = null;
      this.filters.is_deleted = 0;

      this.$refs.table.refresh();
    },
    getFetch() {
      this.$refs.table.refresh();
    },

    deleteItem(item) {
      this.$confirmAlert('', this.actionDelete, item);
    },
    getCurrencies() {
      ApiService.get(this.mainRouteDependency + "/currencies").then((response) => {
        this.currencies = response.data.data;
      });
    },
    getCustomers(filter) {
      if (filter && filter.length >= 3) {
        ApiService.get(`${this.mainRouteDependency}/customers`, {params: {filter: filter}}).then((response) => {
          this.customers = response.data.data;
        });
      } else {
        this.customers = [];
      }
    },
    getInformationOfCreditRefund(id) {
      ApiService.get(this.mainRouteDependency + `/${id}/credits-and-refunds`).then((response) => {
        this.transaction_data = response.data.data;
      });
    },
    getUsers(filter) {
      if (filter && filter.length >= 3) {
        ApiService.get(`${this.mainRouteDependency}/users`, {params: {filter: filter}}).then((response) => {
          this.users = response.data.data;
        });
      }
    },
    actionDelete(item) {
      ApiService.delete(`${this.mainRoute}/${item.id}`).then((response) => {
        this.getFetch();
        this.$successAlert(response.data.message)
      }).catch((error) => {
        this.$errorAlert(error);
      })
    },
    adjustmentPayment(item) {
      ApiService.get(`${this.mainRouteAdjustBalance}/${item.id}/adjust_balance`).then((response) => {
        this.getFetch();
        this.$successAlert(response.data.message)
      }).catch((error) => {
        this.$errorAlert(error);
      })
    },
  },
};
</script>
