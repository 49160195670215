import ApiService from "@/core/services/api.service";
import Vue from "vue";
import i18n from "@/core/plugins/vue-i18n";

let handleImageForEditor =  (file, Editor, cursorLocation, resetUploader) => {
    // An example of using FormData
    // NOTE: Your key could be different such as:
    // formData.append('file', file)

    let file_name = file.name;

    let extension = file_name.substr((file_name.lastIndexOf('.') + 1)).toLowerCase();


    let extensions = ["jpg", "jpeg", "png"];

    if (!extensions || extensions.indexOf(extension) < 0) {
        Vue.prototype.$errorAlertMessage(i18n.t('please_upload_valid_file'));
        return false;
    }


    let formData = new FormData();
    formData.append('file', file);
    formData.append('upload', 'companies');

    ApiService.post('base/uploads/upload-file', formData)
        .then((response) => {
            Vue.prototype.$postStatus = true;
            let file = {
                path: response.data.path,
                name: response.data.name,
                pathDB: response.data.location,
                upload_date_time: response.data.upload_date_time,
            };
            const url = response.data.path; // Get url from response
            Editor.insertEmbed(cursorLocation, "image", url);
            resetUploader();
        }).catch(err => {
        Vue.prototype.$postStatus = true;
        this.$errorAlert(err);
        });
}

export default {handleImageForEditor}