<template>
  <div>
    <!--begin::User-->
    <div>
          <h3 class="card-label">
            {{ $t('journal_entries.journal_entries') }}
            <span class="text-muted pt-2 font-size-sm d-block"></span>
          </h3>

      <div class="row">
        <div class="col-lg-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-12 mb-5">
                <label>{{ $t('journal_entries.journal_date') }}<span class="text-danger">*</span></label>
                <input type="datetime-local" v-model="data.journal_date" class="form-control" :class="validation && validation.journal_date ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.journal_date" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.journal_date[0] }}
                            </span>
              </div>

            </div>
          </div>
          <div class="card card-custom mt-5">
            <div class="card-body row">
              <div class="col-lg-6 mb-5">
                <label>{{ $t('journal_entries.group_account') }}</label>
                <multiselect
                    id="group_account_id"
                    v-model="group_account"
                    :placeholder="$t('journal_entries.group_account')"
                    label="name"
                    track-by="id"
                    :options="groups_accounts_list"
                    :multiple="false"
                    :taggable="false"
                    :show-labels="false"
                    :show-no-options="false"
                    :show-no-results="false"
                    @search-change="getGroupsAccountList($event)">
                </multiselect>
                <span v-if="validation && validation.group_account_id" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.group_account_id[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('journal_entries.secondary_account') }}</label>
                <treeselect
                    :options="secondary_accounts_list"
                    :load-options="loadOptions"
                    :value="data.secondary_account_id"
                    @input="updateValueSecondaryAccount"
                    :searchable="true"
                    :class="validation && validation.secondary_account_id ? 'is-invalid' : ''"
                    :show-count="true"
                    :multiple="false"
                    :no-children-text="$t('No_sub_options')"
                    :no-options-text="$t('No_options_available')"
                    :no-results-text="$t('No_results_found')"
                    :placeholder="$t('Select')">
                </treeselect>
                <span v-if="validation && validation.secondary_account_id" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.secondary_account_id[0] }}
                            </span>
              </div>

            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-6 mb-5">
                <label>{{ $t('journal_entries.serial_no') }}</label>
                <input type="text" v-model="serial_no" class="form-control" :class="validation && validation.serial_no ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.serial_no" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.serial_no[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('journal_entries.journal_type') }}</label>
                <select name="journal_type" id="journal_type" v-model="data.journal_type" class="custom-select" :class="validation && validation.journal_type ? 'is-invalid' : ''">
                  <option v-for="row in repeater_types" :value="row.id" :key="row.id">{{ row.title }}</option>
                </select>
                <span v-if="validation && validation.journal_type" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.journal_type[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('journal_entries.branch') }}</label>
                <multiselect v-model="branch"
                             :placeholder="$t('journal_entries.branch')"
                             label="name"
                             track-by="id"
                             :options="branches"
                             :multiple="false"
                             @input="setBranchId"
                             :taggable="false"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false">
                </multiselect>
                <span v-if="validation && validation.branch_id" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.branch_id[0] }}
                            </span>
              </div>
            </div>
          </div>
          <div class="card card-custom mt-5" v-if="document_type">
            <div class="card-body row">
              <div class="col-lg-6 mb-5" v-if="document_type">
                <label>{{ $t('journal_entries.document_type') }}</label>
                <select name="document_type" id="document_type" disabled v-model="document_type" class="custom-select" :class="validation && validation.document_type ? 'is-invalid' : ''">
                  <option v-for="row in document_type_list" :value="row.slug" :key="row.id">{{ row.title }}</option>
                </select>
                <span v-if="validation && validation.document_type" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.document_type[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5" v-if="transaction_id">
                <label>{{ $t('journal_entries.transaction_id') }}</label>
                <input type="text" :value="transaction_id" disabled class="form-control" :class="validation && validation.transaction_id ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.transaction_id" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.transaction_id[0] }}
                            </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col-md-12">
          <div class="bg-FFB803 repeater p-3 d-flex justify-content-between">
            <h6 class="mt-2">{{ $t('journal_entries.journals_details') }}</h6>
            <button type="button" class="btn btn-primary" v-if="is_allowed_add_more" @click="addItemRowToList">{{ $t('add_more') }}</button>
          </div>
          <div class="bg-white" style="overflow-x: scroll;">
            <table class="table table-row-bordered w-max-content" @keyup.alt.enter="addItemRowToList" @keyup.alt.46="removeItemRowFromList(0)">
              <thead>
              <tr>
                <th width="300px">{{ $t('journal_entries.account_name') }}</th>
                <th width="180px">{{ $t('journal_entries.description') }}</th>
                <th width="120px">{{ $t('journal_entries.credit') }}</th>
                <th width="120px">{{ $t('journal_entries.debit') }}</th>

                <th width="100px">{{ $t('journal_entries.currency') }}</th>
                <th width="120px">{{ $t('exchange_rate') }}</th>
                <th width="120px">{{ $t('journal_entries.local_credit') }}</th>
                <th width="120px">{{ $t('journal_entries.local_debit') }}</th>
                <th width="120px">{{ $t('journal_entries.after_amount') }}</th>
                <th>{{ $t('actions') }}</th>
                <th></th>
              </tr>

              </thead>
              <tbody>
              <tr v-for="(row, index) in jornal_details" :key="index">
                <td>

                  <multiselect
                      v-model="row.account"
                      @input="updateValue(index, $event)"
                      label="label"
                      track-by="id"
                      :placeholder="$t('journal_entries.account_name')"
                      :options="accounts_leaf_list"
                      :multiple="false"
                      :taggable="false"
                      :show-labels="false"
                      :show-no-options="false"
                      :show-no-results="false"
                      @search-change="getAccounts($event)">
                  </multiselect>
                  <span v-if="validation && validation[`jornal_details.${index}.account_id`]" class="fv-plugins-message-container invalid-feedback">
                                                {{ validation[`jornal_details.${index}.account_id`][0] }}
                                            </span>
                </td>
                <td>
                <textarea v-model="row.description" class="form-control"></textarea>
                </td>
                <td><input v-model="row.credit" @input="changeValues(index, 'credit')" type="number" class="form-control"></td>
                <td><input v-model="row.debit" @input="changeValues(index, 'debit')" type="number" class="form-control"></td>
                <td>
                  <select name="" v-model="row.currency_id" class="custom-select" @change="changeCurrency(index)">
                    <option v-for="row in currencies" :value="row.id" :key="row.id">
                      {{ row.code }}
                    </option>
                  </select>
                  <span v-if="validation && validation[`jornal_details.${index}.currency_id`]" class="fv-plugins-message-container invalid-feedback">
                                                {{ validation[`jornal_details.${index}.currency_id`][0] }}
                                            </span>
                </td>
                <td><input v-model="row.exchange_rate" :disabled="true" type="number" class="form-control"></td>
                <td><input v-model="row.local_credit" :disabled="true" type="number" class="form-control"></td>
                <td><input v-model="row.local_debit" :disabled="true" type="number" class="form-control"></td>
                <td><input v-model="row.after_amount" :disabled="true" type="number" class="form-control"></td>
                <td>
                  <v-icon style="color: #dc3545;" small v-if="jornal_details.length > 1" @click="removeItemRowFromList(index)">mdi-delete</v-icon>
                </td>
              </tr>
              </tbody>
              <tfoot>
              <tr>
                <td colspan="1"></td>
                <th>{{ $t('journal_entries.total') }}</th>
                <th><span>{{ credit_total }}</span></th>
                <th><span>{{ debit_total }}</span></th>
                <th></th>
                <th></th>
                <th><span>{{ local_credit_total }}</span></th>
                <th><span>{{ local_debit_total }}</span></th>
                <th></th>
                <th></th>
              </tr>
              </tfoot>
            </table>

          </div>
        </div>
      </div>


      <div class="mt-5">
        <b-tabs content-class="mt-3" class="nav-custom-link">
          <b-tab :title="$t('notes')">
            <div class="card card-custom">
              <div class="card-body row">
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('journal_entries.notes') }}</label>
                  <textarea type="text" v-model="data.notes" class="form-control" :class="validation && validation.notes ? 'is-invalid' : ''"></textarea>
                  <span v-if="validation && validation.notes" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.notes[0] }}
                            </span>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('journal_entries.upload_attachment') }}</label>
                  <div class="form-group">
                    <upload-file
                        @file="listenerAttachment"
                        :inner-id="'attachment'"
                        :placeholder="$t('journal_entries.upload_attachment')"
                        :upload="dir_upload"
                        :start-link="'base'"
                        v-bind:valueprop="{name:'image',path:attachment_url}" v-if="reloadUploadAttachment">
                    </upload-file>
                  </div>
                </div>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>


      <div class="pr-0 pl-0">
        <div class="row">
          <div class="col-lg-6 mt-10">
            <button type="button" class="btn btn-primary mr-2" :disabled="document_type || transaction_id" @click="save">{{ $t('save') }}</button>
          </div>
        </div>
      </div>

    </div>
    <!--end::User-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import {mapGetters, mapState} from "vuex";
import timeZoneStructure from "@/core/config/mix/timeZoneStructure";

export default {
  name: "from-journal-entries",
  data() {
    return {
      mainRoute: 'accounting/jornal-entries',
      mainRouteDependency: 'base/dependency',

      dir_upload: 'accounting',

      idEdit: this.$route.params.id ? this.$route.params.id : null,

      data: {
        journal_date: null,
        currency_id: null,
        exchange_rate: null,
        status: 2,
        notes: null,
        attachment: null,
        secondary_account_id: null,
        group_account_id: null,
        journal_type: 1,
        branch_id: null,
      },
      serial_no: null,
      isEditing: false,
      reloadUploadAttachment: true,
      validation: null,
      attachment_url: null,
      group_account: null,

      groups_accounts_list: [],
      secondary_accounts_list: [],
      accounts_list: [],
      accounts_leaf_list: [],
      currencies: [],
      status_list: [],
      document_type_list: [],
      repeater_types: [],
      branches: [],

      jornal_details: [],
      journals_details_form: {account_id: null, description: null, credit: null, debit: null, currency_id: null, exchange_rate: null, local_credit: null, local_debit: null, after_amount: null},
      credit_total: 0,
      debit_total: 0,
      local_credit_total: 0,
      local_debit_total: 0,
      document_type: null,
      transaction_id: null,
      is_allowed_add_more: true,
      default_currency_id: null,
      branch: null,
    };
  },
  computed: {
    ...mapState({user_personal_info: state => state.profile.user_personal_info}),
    ...mapGetters(["currentUserPersonalInfo"]),

  },
  watch: {

    jornal_details: {
      handler(val) {
        if (val.length > 0) {

          this.credit_total = this.$_.sumBy(this.jornal_details, function (row) {
            return row.credit ? parseFloat(row.credit) : 0;
          });
          this.debit_total = this.$_.sumBy(this.jornal_details, function (row) {
            return row.debit ? parseFloat(row.debit) : 0;
          });
          this.local_credit_total = this.$_.sumBy(this.jornal_details, function (row) {
            return row.local_credit ? parseFloat(row.local_credit) : 0;
          });
          this.local_debit_total = this.$_.sumBy(this.jornal_details, function (row) {
            return row.local_debit ? parseFloat(row.local_debit) : 0;
          });
        }
      },
      deep: true
    },
    "data.journal_type": function () {
      this.getAllowNumberOfRows();
    },

    group_account: function (val) {
      if (val) {
        this.data.group_account_id = val.id;
        this.getSecondaryAccountList(val.id);
      }
    }
  },
  methods: {
    ...timeZoneStructure,
    setBranchId(){
      this.data.branch_id = this.branch ? this.branch.id : null;
    },
    save() {
      let _type = this.data.journal_type;
      let _length = this.jornal_details.length;
      let _data_check = false;
      let _data_check_currency = false;

      this.jornal_details.forEach((row) => {
        if (!row.account_id) {
          _data_check = true;
        }
        if (!row.currency_id) {
          _data_check_currency = true;
        }
      });
      if (_data_check) {
        this.$warningMessage(this.$t('journal_entries.accounts_must_be_entered_in_the_entries_details'), 5000);
        return false;

      }
      if (_data_check_currency) {
        this.$warningMessage(this.$t('journal_entries.currency_must_be_entered_in_the_entries_details'), 5000);
        return false;

      }

      if (_type == 1 && _length != 2) { //simple
        this.$warningMessage(this.$t('journal_entries.the_simple_type_use_only_2_rows'), 5000);
        return false;
      }

      if (_type == 2 && _length < 3) { //complex
        this.$warningMessage(this.$t('journal_entries.the_complex_type_use_3_row_at_lest'), 5000);
        return false;
      }
      if (_type == 3 && _length < 1) { //opening
        this.$warningMessage(this.$t('journal_entries.the_opening_type_use_1_row_at_lest'), 5000);
        return false;
      }

      // if (!this.document_type && !this.transaction_id) {
      let _calc = this.credit_total - this.debit_total;
      if (_calc != 0 && _type == 1) { //simple
        this.$warningMessage(this.$t('journal_entries.the_result_of_subtracting_credit_and_debit_should_be'), 5000);
        return false;
      }


      if (this.isEditing) {
        this.update();
      } else {
        this.create();
      }
      // }

    },

    create() {
      ApiService.post(this.mainRoute, {
        jornal_details: this.jornal_details,
        ...this.data,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.$router.push('/accounting/journal-entries');
      }).catch((error) => {
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },

    update() {
      ApiService.put(this.mainRoute + '/' + this.idEdit, {
        jornal_details: this.jornal_details,
        ...this.data,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.$router.push('/accounting/journal-entries');
      }).catch((error) => {
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },
    updateValue(index, value) {

      let id = value ? value.id : null;
      this.jornal_details[index].account_id = null;

      let _list = [];
      this.jornal_details.map((row) => {
        _list.push(row.account_id);
      });

      if (_list.includes(id)) {
        this.jornal_details[index].account_id = null;
        this.jornal_details[index].account = null;

      } else if (id)
        this.jornal_details[index].account_id = id;
    },
    updateValueSecondaryAccount(value) {
      this.data.secondary_account_id = value;
    },

    getData() {
      this.reloadUploadAttachment = false;
      ApiService.get(this.mainRoute + '/' + this.idEdit).then((response) => {
        this.isEditing = true;
        this.data.journal_date = response.data.data.journal_date;
        this.data.currency_id = response.data.data.currency_id;
        // this.data.status = response.data.data.status;
        this.data.notes = response.data.data.notes;
        this.data.attachment = response.data.data.attachment;
        this.serial_no = response.data.data.serial_no;
        this.attachment_url = response.data.data.attachment_url;
        this.jornal_details = response.data.data.jornal_details;
        this.data.exchange_rate = response.data.data.exchange_rate;
        this.data.journal_type = response.data.data.journal_type;
        this.group_account = response.data.data.group_account;


        this.data.group_account_id = response.data.data.group_account_id;
        this.data.secondary_account_id = response.data.data.secondary_account_id;

        this.document_type = response.data.data.document_type ? response.data.data.document_type : null;
        this.transaction_id = response.data.data.transaction_id ? response.data.data.transaction_id : null;

        this.data.branch_id = response.data.data.branch_id ? response.data.data.branch_id : null;
        this.branch = response.data.data.branch ? response.data.data.branch : null;

        this.reloadUploadAttachment = true;

        if (response.data.data.jornal_details && response.data.data.jornal_details.length <= 0) {
          this.addItemRowToList();
        }
      });
    },

    listenerAttachment(event) {
      if (event) {
        this.attachment_url = event.pathDB;
        this.data.attachment = event.name;
      }
    },
    loadOptions() {
    },
    getCurrencies() {
      ApiService.get(this.mainRouteDependency + "/currencies").then((response) => {
        this.currencies = response.data.data;
      });
    },
    getAccountParents() {
      ApiService.get(`${this.mainRouteDependency}/${'account_parents'}`).then((response) => {
        this.accounts_list = response.data.data;
      });
    },
    getAccounts(filter) {
      if (filter && filter.length >= 3)
        return ApiService.get(this.mainRouteDependency + `/account_parents_leaf_nodes`, {params: {name: filter}}).then((response) => {
          this.accounts_leaf_list = response.data.data.map((row) => {
            return {id: row.id, label: row.label};
          });
        });
    },
    getStatusList() {
      ApiService.get(this.mainRouteDependency + "/status").then((response) => {
        this.status_list = response.data.data;
      });
    },
    getDocumentTypeList() {
      ApiService.get(this.mainRouteDependency + "/doc_type_list").then((response) => {
        this.document_type_list = response.data.data;
      });
    },
    getGroupsAccountList(filter) {
      if (filter && filter.length >= 3)
        ApiService.get(this.mainRouteDependency + "/groups-account", {params: {filter: filter}}).then((response) => {
          this.groups_accounts_list = response.data.data;
        });
    },
    getSecondaryAccountList(id) {
      if (id)
        ApiService.get(this.mainRouteDependency + "/secondary-accounts-group/" + id).then((response) => {
          this.secondary_accounts_list = response.data.data;
        });
    },
    addItemRowToList() {
      this.journals_details_form.currency_id = this.default_currency_id
      this.jornal_details.unshift(this.journals_details_form);
      this.journals_details_form = {account_id: null, description: null, credit: null, debit: null, currency_id: this.default_currency_id, exchange_rate: null, local_credit: null, local_debit: null, after_amount: null};
      this.getAllowNumberOfRows();
    },
    removeItemRowFromList(index) {
      if (this.jornal_details.length > 1) {
        this.jornal_details.splice(index, 1);
      }
      this.getAllowNumberOfRows();
    },
    changeCurrency(index) {
      ApiService.get(`${this.mainRouteDependency}/exchange_rate/` + this.jornal_details[index].currency_id).then((response) => {
        this.jornal_details[index].exchange_rate = response.data.data.initial_val;
        this.jornal_details[index].local_credit = response.data.data.initial_val * this.jornal_details[index].credit;
        this.jornal_details[index].local_debit = response.data.data.initial_val * this.jornal_details[index].debit;
      });
    },
    changeValues(index, type) {
      if (type == 'credit') {
        this.jornal_details[index].debit = 0;
      }
      if (type == 'debit') {
        this.jornal_details[index].credit = 0;
      }
      this.jornal_details = this.jornal_details.map((row) => {
        row.local_credit = ((row.credit ? parseFloat(row.credit) : 0) * (row.exchange_rate ? parseFloat(row.exchange_rate) : 0)).toFixed(2);
        row.local_debit = ((row.debit ? parseFloat(row.debit) : 0) * (row.exchange_rate ? parseFloat(row.exchange_rate) : 0)).toFixed(2);
        return row;
      });
    },
    getRepeaterTypes() {
      ApiService.get(this.mainRouteDependency + "/journal_entry_type").then((response) => {
        this.repeater_types = response.data.data;
      });
    },
    getBranches() {
      ApiService.get(this.mainRouteDependency + "/branches").then((response) => {
        this.branches = response.data.data;
      });
    },
    getAllowNumberOfRows() {
      let _type = this.data.journal_type;
      let _length = this.jornal_details.length;
      if (_type == 1 && _length >= 2) { //simple
        this.is_allowed_add_more = false;
      } else { //complex or opening
        this.is_allowed_add_more = true;
      }
    },

    async defaultDataForUser(){
      await ApiService.get(this.mainRouteDependency +"/default_data_for_user").then((response) => {
        this.default_currency_id = response.data.data.currency_id;
        if (this.jornal_details[0]) {
          this.jornal_details[0].unit = response.data.data.unit;
          this.jornal_details[0].tax = response.data.data.tax;
        }
      });
    }
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.journal_entries"), route: '/accounting/journal-entries'}, {title: (this.idEdit ? this.$t('update') : this.$t('create'))}]);


    this.getCurrencies();
    this.getAccountParents();
    this.getStatusList();
    this.getDocumentTypeList();
    this.getRepeaterTypes();
    this.getBranches();

    if (this.idEdit) {
      this.getData();
    } else {
      // this.data.journal_date = new Date().toISOString().slice(0, 10);
      this.getTimeZone('date_time').then((res)=>{
        this.data.journal_date = res;
      });
      let promise = this.defaultDataForUser();
      Promise.all([promise]).then(()=>{
        if (this.jornal_details.length <= 0) {
          this.addItemRowToList();
        }
      })

    }

  },
};
</script>


