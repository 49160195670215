<template>
  <div>


    <div class="card-toolbar mb-5 d-flex justify-content-between">
      <div>
        <router-link v-if="$can('customer_checking.create')" to="/customers/customer-checking/create" class="btn btn-primary font-weight-bolder">
            <v-icon>mdi-plus</v-icon>
            {{ $t('customer_checking.add_customer_checking')}}
        </router-link>
        <button @click="openAdvancedSearch" class="btn btn-outline-primary font-weight-bolder ml-5">
          <span><i class="la la-filter"></i> <span>{{ $t('advanced_filter') }}</span></span>
        </button>
      </div>
      <div>
        <router-link v-if="$can('customer_checking.list')" to="/customers/customer-checking-history" class="btn btn-primary font-weight-bolder h-100">
          {{ $t('MENU.customer_checking_history') }}
        </router-link>
      </div>
    </div>

    <div class="card card-custom mb-5" v-if="showAdvancedSearch">
      <div class="card-body">
        <div class="m-form m-form--fit m--margin-bottom-20">
          <div class="row mb-5">

            <div class="col-lg-6 mb-5">
              <label>{{ $t('customer_checking.customer') }}</label>
              <!-- <multiselect v-model="customer"
                           :placeholder="$t('customer_checking.customer')"
                           label="name"
                           track-by="id"
                           :options="customers"
                           :multiple="false"
                           :taggable="false"
                           :show-labels="false"
                           :show-no-options="false"
                           :show-no-results="false">
              </multiselect> -->
              <multiselect
                  id="customer_id"
                  v-model="customer"
                  :placeholder="$t('customer_checking.customer')"
                  label="fullname"
                  track-by="id"
                  :options="customers"
                  :multiple="false"
                  :taggable="false"
                  :show-labels="false"
                  :show-no-options="false"
                  :show-no-results="false"
                  @search-change="getCustomers($event)">
              </multiselect>
            </div>

            <div class="form-group col-md-6 mb-5">
              <label for="from_date">{{ $t('customer_checking.from_date') }}</label>
              <input v-model="filters.from_date" type="date" id="from_date" class="form-control">
            </div>
            <div class="form-group col-md-6 mb-5">
              <label for="to_date">{{ $t('customer_checking.to_date') }}</label>
              <input v-model="filters.to_date" type="date" id="to_date" class="form-control">
            </div>
<!--            <div class="form-group col-md-6 mt-2 mb-2">-->
<!--              <label for="last_validated_date">{{ $t('customer_checking.last_validated_date') }}</label>-->
<!--              <input v-model="filters.last_validated_date" type="date" id="last_validated_date" class="form-control">-->
<!--            </div>-->

            <div class="form-group col-md-6 mb-5">
              <label>{{ $t('customer_checking.currency') }}</label>
              <select name="" id="currency_id" v-model="filters.currency_id" class="custom-select">
                <option v-for="row in currencies" :value="row.id" :key="row.id">{{ row.name }}</option>
              </select>
            </div>

            <div class="form-group d-inline-flex col-md-6 mt-6 mb-0">
              <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                            <span><i class="fas fa-search"></i>
                                <span>{{ $t('search') }}</span>
                            </span>
              </button>
              <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="m_reset">
                            <span><i class="fas fa-trash-restore"></i>
                                <span>{{ $t('reset_search') }}</span>
                            </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--begin::customer-->
    <div class="card card-custom">
      <div class="card-body">

        <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">


          <template slot="status" slot-scope="props">
            <button v-if="$can('customer_checking.change_status')" @click="showModal(props.row.id, props.row.status)" :key="props.row.id" class="btn btn-secondary m-btn m-btn--icon w-auto">
              {{ $t('change_status') }} ({{ props.row.status_name }})
            </button>
            <button v-else disabled class="btn btn-secondary m-btn m-btn--icon w-auto">
              {{ $t('change_status') }} ({{ props.row.status_name }})
            </button>

          </template>

          <template slot="actions" slot-scope="props">
            <template v-if="props.row.status != 2">
              <v-btn icon color="pink" v-if="$can('customer_checking.update')" :to="`/customers/customer-checking/edit/${props.row.id}`">
                <v-icon small class="mr-2 text-info" v-b-tooltip.hover :title="$t('edit')">mdi-pencil</v-icon>
              </v-btn>
            </template>

          </template>
        </v-server-table>

      </div>
    </div>
    <!--end::customer-->
<!--    <b-modal ref="increase-decrease" size="md" hide-footer :title="$t(this.is_increase_decrease == 1?'increase':'decrease')">-->
<!--      <div>-->
<!--        <div class="form-group row">-->
<!--          <div class="col-lg-12 mb-5">-->
<!--            <label for="amount">{{ $t('amount') }}</label>-->
<!--            <input name="amount" id="amount" type="number" min="0" v-model="amount" class="form-control" :class="validation && validation.amount ? 'is-invalid' : ''">-->
<!--            <span v-if="validation && validation.amount" class="fv-plugins-message-container invalid-feedback">-->
<!--                            {{ validation.amount[0] }}-->
<!--                        </span>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="card-footer pl-0 pr-0 pb-0">-->
<!--          <div class="row">-->
<!--            <div class="col-lg-6">-->
<!--              <button type="reset" class="btn btn-primary mr-2" @click="saveIncreaseDecrease">{{ $t('save') }}</button>-->
<!--              <button type="reset" class="btn btn-secondary" @click="hideModal">{{ $t('cancel') }}</button>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </b-modal>-->

    <b-modal ref="modal" size="md" hide-footer :title="$t('change_status')">
      <change-status-form @hide-modal="hideModal()"
                          :status-list="status_list"
                          :url="routeChangeStatus"
                          :current-id="innerId"
                          :current-status="statusId"
                          :ignore-selected-status="ignore_status_from_list[statusId]"
                          @handling-data="getDataAfterChangeStatus">
      </change-status-form>
    </b-modal>

  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import ChangeStatusFrom from "@/view/content/forms/ChangeStatusForm.vue";

export default {
  name: "index-customer-checking",
  components: {'change-status-form': ChangeStatusFrom},
  data() {
    return {
      mainRoute: 'customers/customer-checking',
      subMainRoute: 'customers/customer-check',
      mainRouteDependency: 'base/dependency',
      routeChangeStatus: 'customers/customer-check/change-status',

      showAdvancedSearch: false,

      filter: {
        sortBy: 'id',
      },
      filters: {
        customer_id: this.$route.query.customer_id ? this.$route.query.customer_id : null,
        currency_id: null,
        from_date: null,
        to_date: null,
      },
      // status_list: [],
      // columns: ['customer_name', 'credit', 'debit', 'currency_name', 'balance_last_validated_date',  'actual_amount', 'actions'],
      columns: ['customer_name', 'old_credit', 'old_debit', 'credit', 'debit', 'currency_name', 'created_at', 'confirmed_date', 'status', 'actions'],
      data: [],
      customer: null,
      user: null,
      customers: [],
      currencies: [],
      users: [],

      amount: null,
      is_increase_decrease: null, // 1 => increase, 2 => decrease
      customer_validated_id: null,
      validation: null,

      status_list: [],
      innerId: null,
      statusId: null,
      ignore_status_from_list:{
        2: [1,4],
      }

    }
  },
  watch: {
    customer: function (val) {
      if (val) {
        this.filters.customer_id = val.id;
      } else {
        this.filters.customer_id = null;
      }
    },

  },
  computed: {
    options: function () {
      let that = this;
      return {
        texts: {
          loadingError: that.$t('Something_went_wrong'),
          filter: "",
          filterBy: that.$t('Filter') + ' {column}',
          count: ' ',
          filterPlaceholder: that.$t('Search_query'),
          loading: that.$t('Loading') + "...",
        },
        headings: {
          customer_name: that.$t('customer_checking.customer'),
          old_credit: that.$t('customer_checking.credit'),
          old_debit: that.$t('customer_checking.debit'),
          credit: that.$t('customer_checking.new_credit'),
          debit: that.$t('customer_checking.new_debit'),
          currency_name: that.$t('customer_checking.currency'),
          created_at: that.$t('customer_checking.created_date'),
          confirmed_date: that.$t('customer_checking.confirmed_date'),
          status: that.$t('status'),
          actions: that.$t('actions'),
        },
        sortable: ['name'],
        filterByColumn: false,
        filterable: false,
        customFilters: [{
          name: 'alphabet',

        }],
        orderBy: {'column': 'id'},

        alwaysShowPerPageSelect: false,
        perPage: 10,
        pagination: {chunk: 5, dropdown: false},
        skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
        sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
        perPageValues: [5, 10, 15, 25],

        requestFunction(data) {
          let _params = {
            ascending: data.ascending,
            byColumn: data.byColumn,
            limit: data.perPage,
            orderBy: data.orderBy,
            page: data.page,
            filter: data.query,
            ...that.filters,
          }
          return ApiService.query(`${that.mainRoute}`, {..._params});

        },
        responseAdapter(resp) {
          return {
            data: resp.data.data.data,
            count: resp.data.data.total,
          }
        }
      }

    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.customer_checking")}]);
    this.getCurrencies();
    this.getStatus();
  },
  methods: {
    openAdvancedSearch() {
      this.showAdvancedSearch = !this.showAdvancedSearch;
    },
    doFilter() {
      this.$refs.table.refresh();
    },
    resetFilter() {
      this.filters.customer_id = null;
      this.filters.currency_id = null;
      this.filters.from_date = null;
      this.filters.to_date = null;
      this.customer = null;

      this.doFilter();
    },
    getFetch() {
      this.$refs.table.refresh();
    },
    getCurrencies() {
      ApiService.get(this.mainRouteDependency + "/currencies").then((response) => {
        this.currencies = response.data.data;
      });
    },
    // getCustomers() {
    //   ApiService.get(this.mainRouteDependency + "/customers").then((response) => {
    //     this.customers = response.data.data;
    //     if (this.filters.customer_id) {
    //       this.customer = response.data.data.find((row) => row.id == this.filters.customer_id);
    //     }
    //   });
    // },
    getCustomers(filter) {
      if (filter && filter.length >= 3 && (filter.length != 0 || filter != null)) {
        ApiService.get(`${this.mainRouteDependency}/customers`, {params: {filter: filter}}).then((response) => {
          this.customers = response.data.data;
        });
      } else {
        this.customers = [];
      }
    },
    // getUsers(filter) {
    //   if (filter && filter.length >= 3)
    //     ApiService.get(this.mainRouteDependency + "/users", {params: {filter: filter}}).then((response) => {
    //       this.users = response.data.data;
    //     });
    // },
    goToEdit(customer_id, currency_id) {
      this.$router.push({
        name: 'customer-checking.edit', query: {
          customer_id: customer_id,
          currency_id: currency_id,
        }
      });
    },

    getDataAfterChangeStatus() {
      this.innerId = null;
      this.statusId = null;
      this.doFilter();
    },
    showModal(id, status) {
      this.innerId = id;
      this.statusId = status;
      this.$refs['modal'].show();
    },
    hideModal() {
      this.$refs['modal'].hide();
      this.innerId = null;
      this.statusId = null;
    },

    getStatus() {
      ApiService.get(this.mainRouteDependency + "/status").then((response) => {
        this.status_list = response.data.data;
      });
    },
    // makeApprove(customer_validation_id) {
    //   this.$confirmAlert(this.$t('do_you_want_make_it_approved'), this.actionApprove, customer_validation_id);
    // },
    //
    // actionApprove(customer_validation_id) {
    //   ApiService.patch(`${this.subMainRoute}/approved/${customer_validation_id}`).then((response) => {
    //     this.getFetch();
    //     this.$successAlert(response.data.message)
    //   }).catch((error) => {
    //     this.$errorAlert(error);
    //   })
    // },
    // actionIncreaseDecrease(customer_validated_id, is_increase_decrease, amount) {
    //   this.amount = amount;
    //   this.customer_validated_id = customer_validated_id;
    //   this.is_increase_decrease = is_increase_decrease;
    //   this.showModal();
    // },
    // showModal() {
    //   this.$refs['increase-decrease'].show();
    // },
    // hideModal() {
    //   this.$refs['increase-decrease'].hide();
    //   this.amount = null;
    //   this.customer_validated_id = null;
    //   this.is_increase_decrease = null;
    // },
    // saveIncreaseDecrease() {
    //   ApiService.patch(`${this.subMainRoute}/update_amount/${this.customer_validated_id}`, {
    //     amount: this.amount,
    //     is_increase_decrease: this.is_increase_decrease,
    //   }).then((response) => {
    //     this.getFetch();
    //     this.hideModal();
    //     this.getFetch();
    //     this.$successAlert(response.data.message)
    //   }).catch((error) => {
    //     this.$errorAlert(error);
    //   })
    // },
  },
};
</script>
