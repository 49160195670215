<template>
    <div>
        <div class="card-toolbar mb-5 d-flex justify-content-between">
          <div>
            <router-link v-if="$can('item_open_stock.create')" to="/items/open-stock-items/create"  class="btn btn-primary font-weight-bolder">
              <v-icon>mdi-plus</v-icon>
              {{ $t('open_stock_items.new_open_stock_items') }}
            </router-link>
            <button @click="openAdvancedSearch" class="btn btn-outline-primary font-weight-bolder ml-5" >
              <span><i class="la la-filter"></i> <span>{{$t('advanced_filter')}}</span></span>
            </button>
          </div>
          <div>
            <router-link v-if="$can('item_open_stock.open_quantity_history')" to="/items/open-quantity-history" class="btn btn-primary font-weight-bolder h-100">
              {{ $t('MENU.open_quantity_history') }}
            </router-link>
          </div>

        </div>

        <div class="card card-custom mb-5" v-if="showAdvancedSearch">
            <div class="card-body">
                <div class="m-form m-form--fit m--margin-bottom-20">
                    <div class="row">
                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label>{{$t('open_stock_items.inventory')}}</label>
                            <multiselect v-model="inventory"
                                :placeholder="$t('open_stock_items.inventory')"
                                label="name"
                                track-by="id"
                                :options="inventories"
                                :multiple="false"
                                :taggable="false"
                                :show-labels="false"
                                :show-no-options="false"
                                :show-no-results="false">
                            </multiselect>
                        </div>
                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label>{{$t('open_stock_items.item')}}</label>
                            <multiselect v-model="item"
                                :placeholder="$t('open_stock_items.item')"
                                label="name"
                                track-by="id"
                                :options="items"
                                :multiple="false"
                                :taggable="false"
                                :show-labels="false"
                                :show-no-options="false"
                                :show-no-results="false"
                                @search-change="getItems($event)">
                            </multiselect>
                        </div>

                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="from_qty">{{$t('open_stock_items.from_quantity')}}</label>
                            <input v-model="filters.from_quantity" type="number" id="from_qty" class="form-control">
                        </div>
                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="to_qty">{{$t('open_stock_items.to_quantity')}}</label>
                            <input v-model="filters.to_quantity" type="number" id="to_qty" class="form-control">
                        </div>

                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="from_date">{{$t('open_stock_items.from_date')}}</label>
                            <input v-model="filters.from_date" type="date" id="from_date" class="form-control">
                        </div>
                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="to_date">{{$t('open_stock_items.to_date')}}</label>
                            <input v-model="filters.to_date" type="date" id="to_date" class="form-control">
                        </div>

                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="f_status">
                                {{$t('status')}}
                            </label>
                            <select name="" id="f_status" v-model="filters.status" type="text" class="custom-select">
                                <option value="">{{$t('all')}}</option>
                                <option v-for="(row, index) in status_list" :value="row.id" :key="index">{{ row.title }}</option>
                            </select>
                        </div>
                        <div class="form-group d-inline-flex col-md-6 mt-10">
                            <b-button class="mt-auto mr-2" variant="primary" @click="doFilter"><i class="fas fa-search"></i> {{$t('search')}}</b-button>
                            <b-button class="mt-auto mr-2" variant="danger" @click="resetFilter"><i class="fas fa-trash-restore"></i> {{$t('reset_search')}}</b-button>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--begin::User-->
        <div class="card card-custom">
            
            <div class="card-body">
                <div>
                    <div class="row justify-content-end p-4">
                        <custom-export-data v-if="$can('item_open_stock.export_data')" :data-list="dataList" :fields="json_fields" :file-name="$t('MENU.open_stock_items')"></custom-export-data>
                    </div>
                </div>

                <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">

                    <template slot="status" slot-scope="props">
                        <b-btn v-if="$can('item_open_stock.change_status')" variant="secondary" @click="showModal(props.row.id, props.row.status)" :key="props.row.id">
                            {{$t('open_stock_items.change_status')}}
                            ({{props.row.status_name}}) 
                            <i v-if="props.row.added_inv_statistics > 0 && props.row.status == 2" class="fa fa-genderless text-danger icon-xl"></i>
                        </b-btn>
                        <b-btn v-else variant="secondary">
                            {{$t('open_stock_items.change_status')}}
                            ({{props.row.status_name}})
                            <i v-if="props.row.added_inv_statistics > 0 && props.row.status == 2" class="fa fa-genderless text-danger icon-xl"></i>
                        </b-btn>
                    </template>
                    <template slot="actions" slot-scope="props">
                        <v-icon small class="mr-2 text-info" v-b-tooltip.hover :title="$t('edit')" v-if="$can('item_open_stock.update')" color="blue darken-2" @click="editItem(props.row)">mdi-pencil</v-icon>
                        <v-icon small class="text-danger" v-b-tooltip.hover :title="$t('delete')" v-if="$can('item_open_stock.delete')" color="red darken-2" @click="deleteItem(props.row)">mdi-delete</v-icon>

                      <b-dropdown :id="'dropdown-offset_'+props.row.id" :text="$t('more_actions') "  variant="outline-primary" class="m-2">
                        <router-link class="dropdown-item" v-if="$can('item_open_stock.import_quantities')" :to="{path: '/items/import-open-stock-items', query:{inventory_id: props.row.inventory_id, open_stock_id: props.row.id}}">
                          {{ $t('MENU.import_quantities') }}
                        </router-link>
                        <a href="javascript:;" class="dropdown-item" v-if="props.row.status == 2" @click="rollBackOpenStockItem(props.row.id)">
                          {{ $t('roll_back_received_document') }}
                        </a>
                      </b-dropdown>
                    </template>
                </v-server-table>


                <!--end: Datatable-->
            </div>
        </div>
        <!--end::User-->

<!--        <b-modal ref="modal" hide-footer :title="$t('open_stock_items.change_status')">-->
<!--            <div class="col-lg-12 mb-5">-->
<!--                <label>{{$t('open_stock_items.status')}}</label>-->
<!--                <select name="status" id="status" v-model="status_changed" class="custom-select">-->
<!--                    <option v-for="row in status_list" :value="row.id" :key="row.id">{{row.title}}</option>-->
<!--                </select>-->
<!--            </div>-->


<!--            <div class="d-flex justify-content-end">-->
<!--                <b-button class="mt-2 mr-1" variant="danger" @click="hideModal">{{$t('close')}}</b-button>-->
<!--                <b-button class="mt-2" variant="success" @click="changeStatus">{{$t('save')}}</b-button>-->
<!--            </div>-->
<!--        </b-modal>-->
        <b-modal ref="modal" size="md" hide-footer :title="$t('change_status')">
            <change-status-form @hide-modal="hideModal()"
                                :status-list="status_list"
                                :url="routeChangeStatus"
                                :current-id="id_changed"
                                :current-status="status_changed"
                                @handling-data="getDataAfterChangeStatus">
            </change-status-form>
        </b-modal>
    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";
    import ChangeStatusFrom from "@/view/content/forms/ChangeStatusForm";

    export default {
        name: "index-open-stock-items",
        components: {'change-status-form': ChangeStatusFrom,},
        data() {
            return {
                mainRoute: 'items/item-open-stocks',
                subMainRoute: 'items/item-open-stock',
                routeChangeStatus: 'items/item-open-stock/change-status',
              routeRollBack: 'v2/items/item-open-stock/rollback',
                mainRouteDependency: 'base/dependency',
                showAdvancedSearch: false,
                dataList: [],
                filters: {
                    inventory_id: '',
                    item_id: '',
                    transaction_type: '',
                    from_date: '',
                    to_date: '',
                    status: '',
                },
                inventory: null,
                status_list: [],
                inventories: [],
                items: [],
                item: null,

                columns: ['inventory_name', 'financial_year_name', 'applied_date', 'status', 'actions'],

                id_changed: null,
                status_changed: null,
            }
        },
        computed: {
            json_fields: function () {
                let fields = {};
                fields[this.$t('open_stock_items.inventory')] = 'inventory_name';
                fields[this.$t('open_stock_items.financial_year_name')] = 'financial_year_name';
                fields[this.$t('open_stock_items.applied_date')] = 'applied_date';

                return fields;
            },
            options: function () {
                let that = this;
                return {
                    texts: {
                        loadingError: that.$t('Something_went_wrong'),
                        filter: "",
                        limit: that.$t('records'),
                        filterBy: that.$t('Filter') + ' {column}',
                        count: ' ',
                        filterPlaceholder: that.$t('Search_query'),
                        loading: that.$t('Loading') + "...",
                    },
                    headings: {
                        inventory_name: that.$t('open_stock_items.inventory'),
                        financial_year_name: that.$t('open_stock_items.financial_year_name'),
                        applied_date: that.$t('open_stock_items.applied_date'),

                        status: that.$t('status'),
                        actions: that.$t('actions'),

                    },
                    sortable: ['name'],
                    filterByColumn: false,
                    filterable: false,
                    customFilters: [{
                        name: 'alphabet',

                    }],
                    orderBy: {'column': 'id'},

                    alwaysShowPerPageSelect: true,
                    perPage: 10,
                    pagination: {chunk: 5, dropdown: false},
                    skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
                    sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
                    perPageValues: [5, 10, 15, 25],

                    requestFunction(data) {
                        let _params = {
                            ascending: 0,
                            byColumn: data.byColumn,
                            limit: data.limit,
                            orderBy: data.orderBy,
                            page: data.page,
                            filter: data.query,
                            ...that.filters,
                        }
                        return ApiService.query(that.mainRoute, {..._params});

                    },
                    responseAdapter(resp) {
                        that.dataList = resp.data.data.data;
                        return {
                            data: resp.data.data.data,
                            count: resp.data.data.total,
                        }
                    },

                }

            },
        },
        watch:{
            inventory: function (val) {
                if (val){
                    this.filters.inventory_id = val.id;
                }else {
                    this.filters.inventory_id = null;
                }
            },
            item: function (val) {
                if (val){
                    this.filters.item_id = val.id;
                }else {
                    this.filters.item_id = null;
                }
            },
        },
        mounted() {
            // let that = this;
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.open_stock_items")}]);
            this.getInventories();
            // this.getItems();
            this.getStatusList();
        },
        methods: {
            getFetch() {
                this.$refs.table.refresh();
            },
            openAdvancedSearch() {
                this.showAdvancedSearch = !this.showAdvancedSearch;
            },
            doFilter() {
                this.$refs.table.refresh();
            },
            resetFilter() {
                this.inventory = null;
                this.item = null;
                this.filters.inventory_id = null;
                this.filters.item_id = null;
                this.filters.from_quantity = null;
                this.filters.to_quantity = null;
                this.filters.from_date = null;
                this.filters.to_date = null;
                this.filters.status = null;


                this.$refs.table.refresh();
            },
            actionDelete(item) {
                ApiService.delete(this.mainRoute + "/" + item.id).then((response) => {
                    this.getFetch();
                    this.$successAlert(response.data.message)
                }).catch((error) => {
                    this.$errorAlert(error);
                })
            },
            editItem(item) {
                this.$router.push({name: 'open-stock-items.edit', params: {id: item.id}});
            },
            deleteItem(item) {
                this.$confirmAlert('', this.actionDelete, item);
            },
            getInventories() {
                ApiService.get(this.mainRouteDependency + "/inventories").then((response) => {
                    this.inventories = response.data.data;
                });
            },

            // changeStatus() {
            //     ApiService.patch(this.subMainRoute + '/change-status/' + this.id_changed, {
            //         status: this.status_changed,
            //     }).then(response => {
            //         this.$refs.table.refresh();
            //         this.$successAlert(response.data.message);
            //         this.hideModal();
            //     }).catch(error => {
            //         this.$errorAlert(error);
            //     });
            // },
            showModal(id,status) {
                this.$refs['modal'].show();
                this.id_changed = id;
                this.status_changed = status;
            },
            hideModal() {
                this.$refs['modal'].hide();
                this.id_changed = null;
                this.status_changed = null;
            },
            toggleModal() {
                this.$refs['modal'].toggle('#toggle-btn');
            },
            
            getItems(filter) {
                if(filter && filter.length >= 3){
                    ApiService.get(`${this.mainRouteDependency}/items`,{params:{filter: filter}}).then((response) => {
                        this.items = response.data.data;
                    });
                }else{
                    this.items = [];
                }
            },
            getStatusList() {
                ApiService.get(this.mainRouteDependency + "/status").then((response) => {
                    this.status_list = response.data.data;
                });
            },
            getDataAfterChangeStatus() {
                this.id_changed = null;
                this.status_changed = null;
                this.doFilter();
            },
          rollBackOpenStockItem(id) {
            ApiService.patch(this.routeRollBack+ '/' + id).then(response => {
              this.doFilter();
              this.$successAlert(response.data.message);
            }).catch(error => {
              this.$errorAlert(error);
            });
          },
        },
    };
</script>
