<template>
  <div>
    <b-card no-body class="mb-5">
      <b-card-header header-tab="header" class="p-1" role="tab">
        <b-button block v-b-toggle.accordion-2 variant="link"
                  class="text-left font-weight-bold text-dark pl-8 pr-8 fs-102 collapsed-with-icon">
          {{ $t('advanced_filter') }}
        </b-button>
      </b-card-header>

      <b-collapse id="accordion-2" visible accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <div class="row mb-5">
            <div class="form-group col-md-4 mt-2 mb-2">
              <label for="inventory_id">{{ $t('product') }}</label>
              <div class="d-flex search-item-group">
                <select v-model="f_by" class="custom-select" name="" style="width: 100px;">
                  <option selected value="name">{{ $t('items.name') }}</option>
                  <option value="sku_code">{{ $t('items.sku_code') }}</option>
                </select>
                <multiselect v-model="item"
                             :placeholder="$t('product')"
                             label="name"
                             track-by="id"
                             :options="items"
                             :multiple="false"
                             :taggable="false"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false"
                             :internal-search="false"
                             :label="f_by?f_by:'sku_code'"
                             @search-change="getItems(f_by, $event)">
                </multiselect>
              </div>
            </div>
            <div class="form-group col-md-4 mt-2 mb-2">
              <label>{{ $t('items.category') }}</label>
              <treeselect
                  :options="categories"
                  :load-options="loadOptions"
                  :multiple="false"
                  :value="filters.category_id"
                  @input="updateValue"
                  :searchable="true"
                  :show-count="true"
                  :no-children-text="$t('No_sub_options')"
                  :no-options-text="$t('No_options_available')"
                  :no-results-text="$t('No_results_found')"
                  :placeholder="$t('Select')">
              </treeselect>
            </div>

            <div class="form-group col-md-4 mt-2 mb-2">
              <label for="inventory_id">{{ $t('stocks_report.warehouse') }}</label>
              <multiselect v-model="inventory"
                           :placeholder="$t('stocks_report.warehouse')"
                           label="name"
                           track-by="id"
                           :options="inventories"
                           :multiple="false"
                           :taggable="true"
                           :show-labels="false"
                           :show-no-options="false"
                           :show-no-results="false">
              </multiselect>
            </div>

            <div class="form-group col-md-4 mt-2 mb-2">
              <label for="status">{{ $t('status') }}</label>
              <select name="" id="status" v-model="filters.status_balance" type="text" class="custom-select">
                <option v-for="row in status_list" :value="row.id" :key="row.id">
                  {{ row.title }}
                </option>
              </select>

            </div>


            <div class="form-group d-inline-flex col-md-4 mt-10">
              <b-button class="mt-auto mr-2" variant="primary" @click="doFilter"> {{ $t('display_report') }}</b-button>
              <b-button class="mt-auto mr-2" variant="danger" @click="resetFilter"><i class="fas fa-trash-restore"></i>
                {{ $t('reset_search') }}
              </b-button>

            </div>
          </div>
        </b-card-body>
      </b-collapse>
    </b-card>

    <!--begin::customer-->
    <div class="card card-custom mt-5">

      <div class="card-body">

        <div>
          <div class="m-form m-form--fit m--margin-bottom-20">
            <div class="row">
              <div class="col-12 mt-10" id="summaryTableTitle">
                <h4 class="text-center">{{ $t('summary_stock_balance.summary_stock_balance') }}</h4>
                <h5 class="text-center">
                  <span v-if="filters.from_date">{{ $t('from') }} {{ filters.from_date }}</span>
                  <span v-if="filters.to_date">{{ $t('to') }} {{ filters.to_date }}</span>
                  <!-- <span v-if="filters.status">{{ $t('status') }} {{filters.status.text}}</span> -->
                </h5>
              </div>
              <div class="col-12 mt-10 d-flex justify-content-end">
                <b-dropdown right :text="$t('export')" class="mr-2 ml-2">
                  <download-excel
                      class="dropdown-item"
                      :fetch="dataReport"
                      :fields="json_fields"
                      :name="$t('summary_stock_balance.summary_stock_balance')+'.xls'">
                    <i class="la la-file-excel"></i>{{ $t('excel') }}
                  </download-excel>

                  <button class="dropdown-item" @click="printData('summaryTable')">
                    <span><i class="fa fa-print"></i> <span>{{ $t('print') }}</span></span>
                  </button>
                </b-dropdown>
              </div>
              <div class="col-12 mt-10">
                <div class="table-responsive">
                  <table class="table table-bordered text-center" id="summaryTable">
                    <thead>
                    <!-- <tr>
                        <th></th>
                        <th colspan="5">{{$t('inventory_transaction_report.inward')}}</th>
                        <th colspan="5">{{$t('inventory_transaction_report.outward')}}</th>
                        <th></th>
                    </tr> -->
                    <tr>
                      <th>{{ $t('summary_stock_balance.code') }}</th>
                      <th>{{ $t('summary_stock_balance.product_name') }}</th>
                      <th>{{ $t('summary_stock_balance.category') }}</th>
                      <th>{{ $t('summary_stock_balance.brand') }}</th>
                      <th>{{ $t('summary_stock_balance.total') }}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(row, index) in data" :key="index">
                      <td>{{ row.code }}</td>
                      <td>{{ row.product_name }}</td>
                      <td>{{ row.category_name }}</td>
                      <td>{{ row.brand_name }}</td>
                      <td>{{ row.total }}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>

                <div class="col-12 text-center" v-if="page">
                  <button class="btn btn-warning" @click="loadMore">
                    <p class="mb-0">
                      <b-icon icon="arrow-counterclockwise" font-scale="1"></b-icon>
                      {{ $t('load_more') }}
                    </p>
                  </button>
                </div>

              </div>
            </div>
          </div>
        </div>
        <!-- End Filter -->

      </div>
    </div>
    <!--end::customer-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "index-summary-stock-balance",
  data() {
    return {
      mainRoute: '/reports/summary-stock-balance-report',
      mainRouteDependency: 'base/dependency',
      inventories: [],
      data: [],
      categories: [],
      items: [],

      filters: {
        item_id: null,
        category_id: null,
        inventory_id: null,
        status_balance: null,
      },
      selected: {},
      brand_id: null,
      item: null,
      inventory: null,
      status_list: [],
      json_meta: [
        [{
          key: "charset",
          value: "utf-8",
        },],
      ],
      page: 0,
      f_by: 'name',
    }
  },
  computed: {

    json_fields: function () {
      let fields = {};
      fields[this.$t('summary_stock_balance.code')] = 'code';
      fields[this.$t('summary_stock_balance.product_name')] = 'product_name';
      fields[this.$t('summary_stock_balance.category')] = 'category_name';
      fields[this.$t('summary_stock_balance.brand')] = 'brand_name';
      fields[this.$t('summary_stock_balance.total')] = 'total';

      return fields;
    },


  },
  watch: {
    brand_id: function (val) {
      if (val) {
        this.filters.brand_id = val.id;
      } else {
        this.filters.brand_id = null;
      }
    },
    item: function (val) {
      if (val && val.id) {
        this.filters.item_id = val.id;
      } else {
        this.filters.item_id = null;
      }
    },
    inventory: function (val) {
      if (val && val.id) {
        this.filters.inventory_id = val.id;
      } else {
        this.filters.inventory_id = null;
      }
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{
      title: this.$t("MENU.store_reports"),
      route: '/store/reports'
    }, {title: this.$t("MENU.summary_stock_balance")}]);

    this.getCategories();
    this.getInventories();
    this.getStatusList();
  },
  methods: {
    loadMore() {
      this.page = this.page ? (this.page + 1) : 1;
      this.getData();
    },
    getStatusList() {
      ApiService.get(this.mainRouteDependency + "/summary_stock_status").then((response) => {
        this.status_list = response.data.data;
      });
    },
    doFilter() {
      this.page = 1;
      this.getData();
    },
    resetFilter() {

      this.filters.category_id = null;
      this.filters.inventory_id = null;
      this.filters.status_balance = null;
      this.filters.item_id = null;
      this.inventory = null;
      this.item = null;

      this.getData();

    },
    dataReport() {
      this.setReportLog('excel', 'summary stock balance');
      return this.data;
    },
    getData() {
      let _page = (this.page ? this.page : 1);
      let _limit = (this.page ? this.page : 1) * 25;
      ApiService.get(this.mainRoute, {
        params: {
          ...this.filters,
          page: _page,
          limit: _limit,
        }
      }).then((response) => {
        this.data = response.data.data;
      });
    },

    loadOptions() {
    },
    updateValue(value) {
      this.filters.category_id = value
    },
    getCategories() {
      ApiService.get(this.mainRouteDependency + "/categories").then((response) => {
        this.categories = response.data.data;
      });
    },
    getItems(f_by = null, filter = null) {
      if (filter && filter.length > 3 && f_by)
        ApiService.get(this.mainRouteDependency + "/items", {params: {[f_by]: filter}}).then((response) => {
          this.items = response.data.data;
        });
    },
    getInventories() {
      ApiService.get(this.mainRouteDependency + "/inventories").then((response) => {
        this.inventories = response.data.data;
      });
    },

    printData(tableId) {
      this.setReportLog('pdf', 'summary stock balance');
      let divToPrint = document.getElementById(tableId);
      let divTitleToPrint = document.getElementById(tableId + 'Title');
      let newWin = window.open("");
      newWin.document.write("<link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css'>" + divTitleToPrint.outerHTML + divToPrint.outerHTML);
      setTimeout(() => {
        newWin.print();
        newWin.close();
      }, 100)
    },

    /**
     * register log
     */
    setReportLog(key, description) {
      ApiService.post("reports/system_activity_log/log_type", {
        key: key,
        description: description,
      });
    }
  },
};
</script>
