<template>
  <div>
    <!--begin::customer-->
    <b-card no-body class="mb-5">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-button block v-b-toggle.accordion-2 variant="link" class="text-left font-weight-bold text-dark pl-8 pr-8 fs-102 collapsed-with-icon">
          {{ $t('advanced_filter') }}
        </b-button>
      </b-card-header>
      <b-collapse id="accordion-2" visible accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <div class="m-form m-form--fit m--margin-bottom-20">
            <div class="row mb-5">

              <div class="form-group col-md-6 mt-2 mb-2">
                <label>{{ $t('sales_by_client_report.customer') }}</label>
                <multiselect v-model="customers_ids"
                             :placeholder="$t('sales_by_client_report.customer')"
                             label="fullname"
                             track-by="id"
                             :options="customers"
                             :multiple="true"
                             @input="getIds('customers')"
                             :taggable="false"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false"
                             @search-change="getCustomers($event)">
                </multiselect>
              </div>
              <div class="form-group col-md-6 mt-2 mb-2">
                <label>{{ $t('sales_by_client_report.branch') }}</label>
                <multiselect v-model="branches_ids"
                             :multiple="true"
                             :options="branches"
                             :placeholder="$t('sales_by_client_report.branch')"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false"
                             :taggable="false"
                             label="name"
                             track-by="id"
                             @input="getIds('branches')">
                </multiselect>
              </div>
              <div class="form-group col-md-6 mt-2 mb-2">
                <label>{{ $t('sales_by_client_report.employee') }}</label>
                <multiselect v-model="user"
                             :placeholder="$t('sales_by_client_report.employee')"
                             label="name"
                             track-by="id"
                             :options="users"
                             :multiple="false"
                             @input="getIds('users')"
                             :taggable="false"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false"
                             @search-change="getUsers($event)">
                </multiselect>
              </div>

              <div class="form-group col-md-6 mt-2 mb-2">
                <label>{{ $t('sales_by_client_report.invoice_type') }}</label>
                <select id="invoice_type" v-model="filters.invoice_type" class="custom-select" name="">
                  <option :value="null">{{ $t('all') }}</option>
                  <option v-for="row in invoice_type_list" :key="row.key" :disabled="row.disabled" :value="row.key">
                    {{ row.name }}
                  </option>
                </select>
              </div>
              <div class="form-group col-md-6 mt-2 mb-2">
                <label>{{ $t('sales_by_client_report.payment_status') }}</label>
                <select id="invoice_type" v-model="filters.payment_status" class="custom-select" name="">
                  <option v-for="row in payment_status_list" :key="row.id" :disabled="row.disabled" :value="row.id">
                    {{ row.name }}
                  </option>
                </select>
              </div>

              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="from_date">{{ $t('sales_by_client_report.from_date') }}</label>
                <input v-model="filters.from_date" type="date" id="from_date" class="form-control">
              </div>
              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="to_date">{{ $t('sales_by_client_report.to_date') }}</label>
                <input v-model="filters.to_date" type="date" id="to_date" class="form-control">
              </div>
              <div class="col-md-6 mt-2 mb-2 pt-10">
                <div>
                  <b-button-group>
                    <b-button  @click="changeIntervalType('current_day')" :variant="filters.period=='current_day'? 'primary' : 'outline-primary'">{{$t('customer_transactions_report.current_date')}}</b-button>
                    <b-button  @click="changeIntervalType('current_month')" :variant="filters.period=='current_month'? 'primary' : 'outline-primary'">{{$t('customer_transactions_report.current_month')}}</b-button>
                    <b-button  @click="changeIntervalType('current_year')" :variant="filters.period=='current_year'? 'primary' : 'outline-primary'">{{$t('customer_transactions_report.current_year')}}</b-button>
                    <b-button  @click="changeIntervalType('financial_year')" :variant="filters.period=='financial_year'? 'primary' : 'outline-primary'">{{$t('customer_transactions_report.financial_year')}}</b-button>
                    <!--                    <b-button  @click="changeFinancialYear" :variant="filters.financial_year==1? 'primary' : 'outline-primary'">{{$t('customer_transactions_report.financial_year')}}</b-button>-->
                  </b-button-group>

                </div>
                <div class="mt-5">
                  <b-button-group>
                    <b-button  @click="changeIntervalType('yesterday')" :variant="filters.period=='yesterday'? 'primary' : 'outline-primary'">{{$t('customer_transactions_report.yesterday')}}</b-button>
                    <b-button  @click="changeIntervalType('last_month')" :variant="filters.period=='last_month'? 'primary' : 'outline-primary'">{{$t('customer_transactions_report.last_month')}}</b-button>
                    <b-button  @click="changeIntervalType('last_year')" :variant="filters.period=='last_year'? 'primary' : 'outline-primary'">{{$t('customer_transactions_report.last_year')}}</b-button>
                  </b-button-group>

                </div>
              </div>



              <div class="form-group d-inline-flex col-md-6 mt-10">
                <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                  {{ $t('display_report') }}
                </button>
                <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="resetFilter">
                  <i class="fas fa-trash-restore"></i> {{ $t('reset_search') }}
                </button>
              </div>
            </div>
          </div>
        </b-card-body>
      </b-collapse>
    </b-card>
    <!-- End Filter -->
    <div class="card card-custom">
      <div class="card-body">

        <!-- Filter -->
        <div>

          <div class="m-form m-form--fit m--margin-bottom-20">

            <b-tabs content-class="mt-3">
              <b-tab :title="$t('summery')" :active="tab == 'summery' || tab == null">
                <div class="row mb-10">
                  <div class="col-md-12 mt-10" id="summaryTableTitle">
                    <h4 class="text-center">{{ $t('sales_by_client_report.sales_revenue_report') }}</h4>
                    <h5 class="text-center" v-if="filters.from_date && filters.to_date">{{ $t('from') }} {{ filters.from_date }} {{ $t('to') }} {{ filters.to_date }}</h5>
                  </div>
                </div>
                <div class="row mb-10">
                  <div class="col-12" v-if="data_report_summery">
                    <div class="table-responsive">
                      <div class="row justify-content-end p-4">
                        <b-dropdown right :text="$t('export')" class="mr-2 ml-2">

                          <button class="dropdown-item" @click="convertTableToExcelReport('summaryTable')">
                          <span><i class="fa fa-file-pdf"></i> <span>{{ $t('excel') }}</span></span>
                        </button>
                          <button class="dropdown-item" @click="printData('summaryTable')">
                            <span><i class="fa fa-print"></i> <span>{{ $t('print') }}</span></span>
                          </button>
                          <button class="dropdown-item" @click="printPDFData('summaryTable', 'pdf')">
                            <span><i class="fa fa-file-pdf"></i> <span>{{ $t('export_pdf') }}</span></span>
                          </button>
                          <button class="dropdown-item" @click="getAllDataForExportSummery('sectionForExportSummary', 'excel')">
                          <span><i class="fa fa-file-excel"></i> <span>{{ $t('all_data') }}</span></span>
                        </button>

                        </b-dropdown>
                      </div>
                      <table class="table table-bordered" id="summaryTable">
                        <thead>
                        <tr>
                          <th>{{ $t('sales_by_client_report.' + periodLabel) }}</th>
                            <th>{{ $t('sales_by_client_report.paid') }}</th>
                            <th>{{ $t('sales_by_client_report.unpaid') }}</th>
                            <th>{{ $t('sales_by_client_report.refund') }}</th>
                          <th>{{ $t('sales_by_client_report.total') }}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(row2, index2) in data_report_summery" :key="'a'+getRandom()+index2">
                          <td>{{ row2.label }}</td>
                            <td>{{ row2.total_paid_amount }}</td>
                            <td>{{ row2.total_unpaid_amount }}</td>
                            <td>{{ row2.total_refund_amount }}</td>
                          <td>{{ row2.total }}</td>
                        </tr>
                        </tbody>
                        <tfoot>
                        <tr>
                          <td><b>{{ $t('sales_by_client_report.total') }}</b></td>
                            <th>{{ summery_total_sum.paid_amount ? summery_total_sum.paid_amount : 0 }}</th>
                            <th>{{ summery_total_sum.unpaid_amount ? summery_total_sum.total_unpaid_amount : 0 }}</th>
                          <th>{{ summery_total_sum.refund_amount ? summery_total_sum.refund_amount : 0 }}</th>
                          <th>{{ summery_total_sum.total ? summery_total_sum.total : 0 }}</th>
                        </tr>
                        </tfoot>
                      </table>
                    </div>

                  </div>
                  <div class="col-12 text-center" v-if="page_summery">
                    <button class="btn btn-warning" @click="loadMoreSummery">
                      <p class="mb-0">
                        <b-icon icon="arrow-counterclockwise" font-scale="1"></b-icon>
                        {{ $t('load_more') }}
                      </p>
                    </button>
                  </div>

                </div>
              </b-tab>

              <b-tab :title="$t('details')" :active="tab == 'details'">
                <div class="mt-3">
                  <div class="row mb-10">
                    <div class="col-md-12 mt-10" id="summaryTableDetailsTitle">
                      <h4 class="text-center">{{ $t('sales_by_client_report.sales_revenue_report') }}</h4>
                      <h5 class="text-center" v-if="filters.from_date && filters.to_date">{{ $t('from') }} {{ filters.from_date }} {{ $t('to') }} {{ filters.to_date }}</h5>
                    </div>
                  </div>
                  <div class="row mb-10">
                    <div class="col-12" v-if="data_report_details">
                      <div class="table-responsive">
                        <div class="row justify-content-end p-4">
                          <b-dropdown right :text="$t('export')" class="mr-2 ml-2">
                            <button class="dropdown-item" @click="convertTableToExcelReport('summaryTableDetails')">
                          <span><i class="fa fa-file-pdf"></i> <span>{{ $t('excel') }}</span></span>
                        </button>
                            <button class="dropdown-item" @click="printData('summaryTableDetails')">
                              <span><i class="fa fa-print"></i> <span>{{ $t('print') }}</span></span>
                            </button>
                            <button class="dropdown-item" @click="printPDFData('summaryTableDetails', 'pdf')">
                              <span><i class="fa fa-file-pdf"></i> <span>{{ $t('export_pdf') }}</span></span>
                            </button>
                            <button class="dropdown-item" @click="getAllDataForExport('sectionForExportDetails', 'excel')">
                          <span><i class="fa fa-file-excel"></i> <span>{{ $t('all_data') }}</span></span>
                        </button>
                          </b-dropdown>
                        </div>
                        <table class="table table-bordered" id="summaryTableDetails">
                          <thead>
                          <tr>
                            <th>{{ $t('sales_by_client_report.ref') }}</th>
                            <th>{{ $t('sales_by_client_report.date') }}</th>
                            <th>{{ $t('sales_by_client_report.customer') }}</th>
                            <th>{{ $t('sales_by_client_report.employee') }}</th>
                            <th>{{ $t('sales_by_client_report.paid') }}</th>
                            <th>{{ $t('sales_by_client_report.unpaid') }}</th>
                            <th>{{ $t('sales_by_client_report.refund') }}</th>
                            <th>{{ $t('sales_by_client_report.total') }}</th>
                          </tr>
                          </thead>
                          <tbody>
                          <template v-for="(row, index) in data_report_details">
                            <tr>
                              <td colspan="10">{{ row.label }}</td>
                            </tr>
                            <tr v-for="(row2, index2) in row.details" :key="'a'+getRandom()+index2">
                              <td>{{ row2.ref }}</td>
                              <td>{{ row2.date }}</td>
                              <td>{{ row2.customer_name }}</td>
                              <td>{{ row2.employee_name }}</td>
                              <td>{{ row2.paid_amount }}</td>
                              <td>{{ row2.unpaid_amount }}</td>
                              <td>{{ row2.refund_amount }}</td>
                              <td>{{ row2.total }}</td>
                            </tr>
                            <tr>
                              <td colspan="4">{{ $t('sales_by_client_report.total') }}</td>
                              <td>{{ row.total_paid_amount }}</td>
                              <td>{{ row.total_unpaid_amount }}</td>
                              <td>{{ row.total_refund_amount }}</td>
                              <td>{{ row.total }}</td>
                            </tr>
                          </template>

                          </tbody>
                          <tfoot>
                          <tr>
                            <td colspan="4"><b>{{ $t('sales_by_client_report.total') }}</b></td>
                            <th>{{ details_total_sum.total_paid_amount ? details_total_sum.total_paid_amount : 0 }}</th>
                            <th>{{ details_total_sum.total_unpaid_amount ? details_total_sum.total_unpaid_amount : 0 }}</th>
                            <th>{{ details_total_sum.total_refund_amount ? details_total_sum.total_refund_amount : 0 }}</th>
                            <th>{{ details_total_sum.total ? details_total_sum.total : 0 }}</th>
                          </tr>
                          </tfoot>
                        </table>
                      </div>

                    </div>
                  </div>
                  <div class="col-12 text-center" v-if="page">
                    <button class="btn btn-warning" @click="loadMore">
                      <p class="mb-0">
                        <b-icon icon="arrow-counterclockwise" font-scale="1"></b-icon>
                        {{ $t('load_more') }}
                      </p>
                    </button>
                  </div>

                </div>
              </b-tab>
              <template #tabs-end>
                <li class="nav-item  d-flex my-auto">
                  <select name="" id="" v-model="filters.filter_by" class="custom-select border-0 pt-0 pb-0 h-auto shadow-none">
                    <option :value="null" :disabled="true">{{ $t('select_filter') }}</option>
                    <option value="daily">{{ $t('daily') }}</option>
                    <option value="weekly">{{ $t('weekly') }}</option>
                    <option value="monthly">{{ $t('monthly') }}</option>
                    <option value="yearly">{{ $t('yearly') }}</option>
                    <option value="customer">{{ $t('customer') }}</option>
                    <option value="employee">{{ $t('employee') }}</option>
                  </select>

                </li>
              </template>
              <!-- <b-tab> -->
              <!-- </b-tab> -->
            </b-tabs>

          </div>
        </div>
      </div>
    </div>
    <div class="export-container">
      <div class="section-for-export">

        <table class="table table-bordered" id="sectionForExportSummary">
          <thead>
          <tr>
            <th>{{ $t('sales_by_client_report.' + periodLabel) }}</th>
            <th>{{ $t('sales_by_client_report.paid') }}</th>
            <th>{{ $t('sales_by_client_report.unpaid') }}</th>
            <th>{{ $t('sales_by_client_report.refund') }}</th>
            <th>{{ $t('sales_by_client_report.total') }}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(row2, index2) in dataListSummery" :key="'abr'+getRandom()+index2">
            <td>{{ row2.label }}</td>
            <td>{{ row2.total_paid_amount }}</td>
            <td>{{ row2.total_unpaid_amount }}</td>
            <td>{{ row2.total_refund_amount }}</td>
            <td>{{ row2.total }}</td>
          </tr>
          </tbody>
          <tfoot>
          <tr>
            <td><b>{{ $t('sales_by_client_report.total') }}</b></td>
            <th>{{ dataList_total_sumSummery.paid_amount ? dataList_total_sumSummery.paid_amount : 0 }}</th>
            <th>{{ dataList_total_sumSummery.unpaid_amount ? dataList_total_sumSummery.unpaid_amount : 0 }}</th>
            <th>{{ dataList_total_sumSummery.refund_amount ? dataList_total_sumSummery.refund_amount : 0 }}</th>
            <th>{{ dataList_total_sumSummery.total ? dataList_total_sumSummery.total : 0 }}</th>
          </tr>
          </tfoot>
        </table>

        <table class="table table-bordered" id="sectionForExportDetails">
          <thead>
          <tr>
            <th>{{ $t('sales_by_client_report.ref') }}</th>
            <th>{{ $t('sales_by_client_report.date') }}</th>
            <th>{{ $t('sales_by_client_report.customer') }}</th>
            <th>{{ $t('sales_by_client_report.employee') }}</th>
            <th>{{ $t('sales_by_client_report.paid') }}</th>
            <th>{{ $t('sales_by_client_report.unpaid') }}</th>
            <th>{{ $t('sales_by_client_report.refund') }}</th>
            <th>{{ $t('sales_by_client_report.total') }}</th>
          </tr>
          </thead>
          <tbody>
          <template v-for="(row, index) in dataList">
            <tr>
              <td colspan="10">{{ row.label }}</td>
            </tr>
            <tr v-for="(row2, index2) in row.details" :key="'a'+getRandom()+index2">
              <td>{{ row2.ref }}</td>
              <td>{{ row2.date }}</td>
              <td>{{ row2.customer_name }}</td>
              <td>{{ row2.employee_name }}</td>
              <td>{{ row2.paid_amount }}</td>
              <td>{{ row2.unpaid_amount }}</td>
              <td>{{ row2.refund_amount }}</td>
              <td>{{ row2.total }}</td>
            </tr>
            <tr>
              <td colspan="4">{{ $t('sales_by_client_report.total') }}</td>
              <td>{{ row.total_paid_amount }}</td>
              <td>{{ row.total_unpaid_amount }}</td>
              <td>{{ row.total_refund_amount }}</td>
              <td>{{ row.total }}</td>
            </tr>
          </template>
          </tbody>
          <tfoot>
          <tr>

            <td colspan="4"><b>{{ $t('sales_by_client_report.total') }}</b></td>
            <th>{{ dataList_total_sum.total_paid_amount ? dataList_total_sum.total_paid_amount : 0 }}</th>
            <th>{{ dataList_total_sum.total_unpaid_amount ? dataList_total_sum.total_unpaid_amount : 0 }}</th>
            <th>{{ dataList_total_sum.total_refund_amount ? dataList_total_sum.total_refund_amount : 0 }}</th>
            <th>{{ dataList_total_sum.total ? dataList_total_sum.total : 0 }}</th>
          </tr>
          </tfoot>
        </table>

      </div>
    </div>
    <div class="export-container">
      <div class="section-for-export" id="sectionForExportHeader">
        <report-main-header :print-type="'SalesRevenueReports'"></report-main-header>
      </div>
    </div>
    <div class="selector-export" id="selectorExport"></div>
    <!--end::customer-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import exportPDFInnerAction from "@/core/plugins/export-jspdf-inner-action";
import {mapGetters} from "vuex";
import cssStypeForPrintReport from "@/core/config/mix/cssStypeForPrintReport";
import TableToExcel from "@linways/table-to-excel";
import ReportMainHeader from "@/view/content/printing-heads/ReportMainHeader.vue";

export default {
  name: "index-sales-revenue-report",
  components: {ReportMainHeader},
  data() {
    return {
      // mainRoute: '/reports/sales',
      mainRoute: '/v2/reports/sales/revenue',
      mainRouteDependency: 'base/dependency',

      currency_name: null,

      data_report_details: [],
      details_total_sum: [],

      data_report_summery: [],
      summery_total_sum: [],

      dataList: [],
      dataList_total_sum: [],

      dataListSummery: [],
      dataList_total_sumSummery: [],

      filters: {
        customers_ids: [],
        branches_ids: [],
        user_id: null,
        from_date: null,
        to_date: null,
        is_paid: null,

        payment_status: null,
        filter_by: 'daily',
        invoice_type: null,

        period: null,
        range: null,
        f_time_period: 0,

      },

      payment_status_list: [
        {id: null, name: this.$t('all')},
        {id: 1, name: this.$t('full_paid')},
        {id: 2, name: this.$t('partial_paid')},
        {id: 0, name: this.$t('unpaid')},
        // {id: 4, title: this.$t('refund')},
        // {id: 5, title: this.$t('overdue')},
      ],
      invoice_type_list: [
        {key: 'sales_invoice', name: this.$t('invoices_types_trans.sales_invoice'), disabled: false},
        {key: 'general_sales_invoice', name: this.$t('invoices_types_trans.general_sales_invoice'), disabled: false},
        {key: 'pos', name: this.$t('invoices_types_trans.pos'), disabled: false},
        {key: 'sales_refund', name: this.$t('invoices_types_trans.sales_refund'), disabled: false},
        {key: 'sales_credit', name: this.$t('invoices_types_trans.sales_credit'), disabled: false},
        // {key: 'sales_debit', name: this.$t('invoices_types_trans.sales_debit'), disabled: false},
        {key: 'general_sales_refund', name: this.$t('invoices_types_trans.general_sales_refund'), disabled: false},
        {key: 'general_sales_credit', name: this.$t('invoices_types_trans.general_sales_credit'), disabled: false},
        // {key: 'general_sales_debit', name: this.$t('invoices_types_trans.general_sales_debit'), disabled: false},
        // {key: 'sales_payment', name: this.$t('invoices_types_trans.sales_payment'), disabled: false},
        // {key: 'expenses_income_transaction', name: this.$t('invoices_types_trans.expenses_income_transaction'), disabled: false},
      ],
      data: [],
      customers: [],
      users: [],
      branches: [],
      customers_ids: [],
      branches_ids: [],
      user: null,

      isDisabled: true,
      isPeriod: false,
      periodLabel: 'day',

      tab: this.$route.query.tab ? this.$route.query.tab : null,
      json_meta: [
        [{
          key: "charset",
          value: "utf-8",
        },],
      ],
      page: 1,
      page_summery: 1,
    }
  },
  watch: {
    "filters.payment_status": function (val) {
      this.filters.is_paid = val;
    },
    "filters.filter_by": function (val) {
      if (val)
        this.doFilter();
      switch (val) {
        case 'daily':
          this.isPeriod = true;
          this.periodLabel = 'day';
          break;
        case 'weekly':
          this.isPeriod = true;
          this.periodLabel = 'week';
          break;
        case 'monthly':
          this.isPeriod = true;
          this.periodLabel = 'month';
          break;
        case 'yearly':
          this.isPeriod = true;
          this.periodLabel = 'year';
          break;
        case 'customer':
          this.isPeriod = true;
          this.periodLabel = 'customer';
          break;
        case 'employee':
          this.isPeriod = true;
          this.periodLabel = 'employee';
          break;
        default:
          this.isPeriod = false;
          this.periodLabel = '';
          break;
      }
    },

  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
    isSystemUser: function () {
      return this.currentUserPersonalInfo.is_system_user
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.sales_report"), route: '/reports/sales-reports-links'}, {title: this.$t("MENU.sales_revenue_report")}]);
    this.getBranches();

  },
  methods: {
    ...cssStypeForPrintReport,

    getRandom() {
      return Math.floor(Math.random() * 10000);
    },

    doFilter() {
      this.page_summery = 1;
      this.page = 1;
      this.getReportInvoiceSummery();
      this.getReportInvoice();

    },
    resetFilter() {
      this.filters.customers_ids = [];
      this.filters.branches_ids = [];
      this.filters.user_id = null;
      this.filters.invoice_type = null;
      this.filters.from_date = null;
      this.filters.to_date = null;
      this.filters.period = null;
      this.filters.payment_status = null;
      this.filters.is_paid = null;
      this.filters.f_time_period = 0;
      this.customers_ids = [];
      this.branches_ids = [];
      this.user = null;

      this.doFilter();
    },
    changeIntervalType(action_type, range = null){
      if (this.filters.period == action_type){
        this.filters.period = null;
        this.filters.f_time_period = 0;
      }else{
        this.filters.period = action_type;
        this.filters.f_time_period = 1;
      }

    },
    getIds(related_with) {

      switch (related_with) {
        case 'customers':
          this.filters.customers_ids = this.customers_ids.map((row) => row.id);
          break;
        case 'branches':
          this.filters.branches_ids = this.branches_ids.map((row) => row.id);
          break;
        case 'users':
          this.filters.user_id = this.user.id;
          break;
      }
    },

    async getCustomers(filter) {
      if (filter && filter.length >= 3)
        await ApiService.get(this.mainRouteDependency + "/customers", {params: {filter: filter}}).then((response) => {
          this.customers = response.data.data;
        });
    },
    getUsers(filter) {
      if (filter && filter.length >= 3)
        ApiService.get(this.mainRouteDependency + "/users", {params: {filter: filter}}).then((response) => {
          this.users = response.data.data;
        });
    },
    getBranches() {
      ApiService.get(this.mainRouteDependency + "/branches").then((response) => {
        this.branches = response.data.data;
      });
    },


    loadMore() {
      this.page = this.page ? (this.page + 1) : 1;
      this.getReportInvoice();
    },
    loadMoreSummery() {
      this.page_summery = this.page_summery ? (this.page_summery + 1) : 1;
      this.getReportInvoiceSummery();
    },
    /*****
     * details
     */

    getReportInvoice() {
      let _limit = (this.page ? this.page : 1) * 15
      ApiService.get(this.mainRoute, {params: {...this.filters, limit: _limit}}).then((response) => {
        this.data_report_details = response.data.data.items;
        this.details_total_sum = response.data.data.total;

      });
    },

    getReportInvoiceSummery() {
      let _limit = (this.page_summery ? this.page_summery : 1) * 5
      ApiService.get(`${this.mainRoute}/summary`, {params: {...this.filters, limit: _limit}}).then((response) => {
        this.data_report_summery = response.data.data.items;
        this.summery_total_sum = response.data.data.total;

      });
    },


    getAllDataForExport(tableId, _export) {
      ApiService.get(this.mainRoute, {params: {...this.filters, limit: 999999999999}}).then((response) => {
        this.dataList = response.data.data.items;
        this.dataList_total_sum = response.data.data.total;
        this.convertTableToExcelReport(tableId);
      });
    },
    getAllDataForExportSummery(tableId, _export) {
      ApiService.get(`${this.mainRoute}/summary`, {params: {...this.filters, limit: 999999999999}}).then((response) => {
        this.dataListSummery = response.data.data.items;
        this.dataList_total_sumSummery = response.data.data.total;
        this.convertTableToExcelReport(tableId);
      });
    },
    convertTableToExcelReport(tableId) {
      this.setReportLog('excel', 'sales revenue report');
      setTimeout(() => {
        TableToExcel.convert(document.getElementById(tableId), {
          name: this.$t('MENU.sales_revenue_report') + '.xlsx',
          sheet: {
            name: 'Sheet 1'
          }
        });
      }, 100)
    },
    printData(tableId) {
      this.setReportLog('pdf', 'sales revenue report');

      let divToPrint = document.getElementById(tableId);
      let divTitleToPrint = document.getElementById('sectionForExportHeader');
      let newWin = window.open("");
      newWin.document.write('<meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">');
      newWin.document.write("<link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css'>");
      newWin.document.write('<style>');
      newWin.document.write(this.getCssStyleForPrintReport());
      newWin.document.write('</style>');
      newWin.document.write(divTitleToPrint.outerHTML + divToPrint.outerHTML);
      setTimeout(() => {
        newWin.print();
        // newWin.close();
      }, 100)
    },

    printPDFData(tableId, type) {
      this.setReportLog('pdf', 'sales revenue report');
      exportPDFInnerAction(tableId, type, this.$t('MENU.sales_revenue_report'), this.$t('MENU.sales_revenue_report'));

    },
    /**
     * register log
     */
    setReportLog(key, description) {
      ApiService.post("reports/system_activity_log/log_type", {
        key: key,
        description: description,
      });
    }
  },
};
</script>
<style>
.html2pdf__overlay {
  opacity: 0 !important;
  z-index: -1000;

}

.selector-export {
  opacity: 0 !important;
  z-index: -1000 !important;
  width: 0.0001px !important;
  height: 0.0001px !important;
  overflow: auto;
}
</style>

