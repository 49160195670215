<template>
  <div>
    <!--begin::customer-->
    <div class="card card-custom">
      <div class="card-header flex-wrap border-0 pt-6 pb-0">
        <div class="card-title">
          <h3 class="card-label">
            {{ $t('supplier_view.supplier_details')}}
            <span class="text-muted pt-2 font-size-sm d-block"></span>
          </h3>
        </div>
      </div>

      <div class="card-body">
        <b-tabs content-class="mt-3">
          <b-tab :title="$t('supplier_view.personal_file')" active>

            <div class="row mt-5">
              <div class="col-12">
                <h6>{{ $t('supplier_view.quick_details')}}</h6>
                <hr>
              </div>

               <div class="col-6">
                <p>{{ $t('supplier_view.name')}}: {{data.name}}</p>
              </div>

              <div class="col-6">
                <p>{{ $t('supplier_view.number_of_purchase_invoices')}}: {{data.number_of_invoices}}</p>
              </div>
              <div class="col-6">
                <p>{{ $t('supplier_view.number_of_unpaid_purchase_invoices')}}: {{data.number_of_unpaid_invoices}}</p>
              </div>
              <div class="col-6">
                <p>
                  {{ $t('supplier_view.last_purchase_invoice')}}: #{{last_invoice.id}} <span class="mr-5 ml-5">{{last_invoice.invoice_total}} {{last_invoice.currency_name}}</span>
                  <router-link v-if="$can('purchases_invoices.printing_export_pdf') && last_invoice.id" target="_blank" class="btn btn-link" :to="`/print/purchaseInvoice/${last_invoice.id}/print`">
                    {{ $t('MENU.invoice_printing') }}
                  </router-link>
                </p>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-12">
                <div class="bg-FFB803 repeater p-3 d-flex justify-content-between">
                  <h6 class="my-auto text-white">{{$t('supplier_view.balances')}}</h6>
                </div>
                <div class="table-responsive">
                  <table class="table table-row-bordered">
                    <thead>
                    <tr>
                      <th>{{$t('supplier_view.balance')}}</th>
                      <th>{{$t('supplier_view.debit')}}</th>
                      <th>{{$t('supplier_view.currency')}}</th>
                      <th>{{$t('supplier_view.last_validated_date')}}</th>
                    </tr>

                    </thead>
                    <tbody>
                    <template v-if="balances_list.length > 0" >
                      <tr v-for="(row, index) in balances_list" :key="index">

                        <td>{{row.credit}}</td>
                        <td>{{row.debit}}</td>
                        <td>{{row.currency_name}}</td>
                        <td>
                          {{row.last_validated_date}}
                        </td>

                      </tr>
                    </template>
                    <tr v-else>
                      <td colspan="4">{{$t('no_balances')}}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </b-tab>
          <b-tab :title="$t('supplier_view.last_purchase_invoices')+' ('+data.last_invoices+')'">
            <div class="row mt-5">
              <div class="col-12">
                <h4>{{$t('supplier_view.last_purchase_invoices_for')}} {{data.name}}</h4>
              </div>
              <div class="col-12">
                <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">
                  <template slot="actions" slot-scope="props">
                    <b-dropdown id="dropdown-offset" :text="$t('more_actions')" variant="outline-primary" class="m-2">
                      <router-link v-if="$can('purchases_invoices.printing_export_pdf')" target="_blank" class="dropdown-item" :to="`/print/purchaseInvoice/${props.row.id}/print`">
                        {{ $t('MENU.invoice_printing') }}
                      </router-link>
                    </b-dropdown>
                  </template>
                </v-server-table>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </div>
    <!--end::customer-->
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";

export default {
  name: "View-Suppliers",
  data() {
    return {
      mainRoute: 'purchases/supplier/more-details',
      mainRouteForInvoices: 'purchases/purchases-invoices',
      id: this.$route.params.id? this.$route.params.id : null,
      filters:{
        supplier_id: this.$route.params.id? this.$route.params.id : null,
      },
      key: 'Suppliers',
      data: {
        name: null,
        last_invoices: 0,
        number_of_invoices: 0,
        number_of_unpaid_invoices: 0,
      },
      last_invoice:{
        id: null,
        invoice_total: null,
        currency_name: null,
      },
      balances_list: [],

      columns: ['id', 'purchase_date', 'invoice_total', 'currency_name', 'status_name', 'actions'],

    };
  },
  computed: {
    options: function () {
      let that = this;
      return {
        texts: {
          loadingError: that.$t('Something_went_wrong'),
          filter: "",
          limit: that.$t('records'),
          filterBy: that.$t('Filter') + ' {column}',
          count: ' ',
          filterPlaceholder: that.$t('Search_query'),
          loading: that.$t('Loading') + "...",
        },
        headings: {
          id: that.$t('supplier_view.id'),
          purchase_date: that.$t('purchases_invoices.purchase_date'),
          invoice_total: that.$t('purchases_invoices.subtotal_before_tax'),
          currency_name: that.$t('purchases_invoices.currency'),
          status_name: that.$t('status'),
          actions: that.$t('actions'),
        },
        sortable: ['name'],
        filterByColumn: false,
        filterable: false,
        customFilters: [{
          name: 'alphabet',

        }],
        orderBy: {'column': 'id'},

        alwaysShowPerPageSelect: false,
        perPage: 10,
        pagination: {chunk: 5, dropdown: false},
        skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
        sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
        perPageValues: [5, 10, 15, 25],

        requestFunction(data) {
          let _params = {
            ascending: data.ascending,
            byColumn: data.byColumn,
            limit: data.perPage,
            orderBy: data.orderBy,
            page: data.page,
            filter: data.query,
            ...that.filters,
          }
          return ApiService.query(`${that.mainRouteForInvoices}`, {..._params});

        },
        responseAdapter(resp) {
          that.data.last_invoices = resp.data.data.total
          return {
            data: resp.data.data.data,
            count: resp.data.data.total,
          }
        }
      }

    },
  },
  methods: {
    async getData() {
      if (this.id)
      ApiService.get(`${this.mainRoute}/${this.id}`).then((response) => {
        this.data.name = response.data.data.name;
        this.last_invoice = response.data.data.last_invoice;
        this.data.number_of_invoices = response.data.data.number_of_invoices ? response.data.data.number_of_invoices : '0';
        this.data.number_of_unpaid_invoices = response.data.data.number_of_unpaid_invoices ? response.data.data.number_of_unpaid_invoices : '0';

        this.balances_list = response.data.data.balances_list;
      });
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.suppliers_management"),route:'/purchases/suppliers'}, {title: this.$t('view')}]);

    if (this.id) {
      this.getData();
    }

  },
}
</script>

<style scoped>

</style>