<template>
  <div class="d-flex justify-content-between">
    <div class="d-flex" style="line-height: 1.5;">
      <div>
        <img id="rel_logo" v-if="rel_logo" :src="rel_logo" class="logo-w" style="margin-left: auto;" alt="">
      </div>

    </div>

<!--    <div v-if="innerData.status && innerData.status == 1 && titleType == 'normal'" class="text-center">-->
<!--      <p class="title-1 space-word-15" style="line-height: 1.5;">فاتورة مبدئية</p>-->
<!--      <p class="title-2">Proforma Invoice</p>-->
<!--    </div>-->
<!--    <div v-else-if="innerData.is_apply_taxes_invoice && titleType == 'normal'" class="text-center">-->
<!--      <template v-if="customer_type == 1">-->
<!--        <p class="title-1 space-word-15" style="line-height: 1.5;">فاتورة ضريبية مبسطة</p>-->
<!--        <p class="title-2">Simple Tax Invoice</p>-->
<!--      </template>-->
<!--      <template v-else>-->
<!--        <p class="title-1 space-word-15" style="line-height: 1.5;">فاتورة ضريبية</p>-->
<!--        <p class="title-2">Tax Invoice</p>-->
<!--      </template>-->
<!--    </div>-->
<!--    <div v-else-if="showQRCode && titleType == 'normal'" class="text-center">-->
<!--        <p class="title-1 space-word-15" style="line-height: 1.5;">فاتورة</p>-->
<!--        <p class="title-2">Invoice</p>-->
<!--    </div>-->
    <div v-if="titleType == 'normal'" class="text-center">
        <p class="title-1 space-word-15 m-0" style="line-height: 1.5;">{{innerData ? innerData.invoice_title_ar: ''}}</p>
        <p class="title-2 m-0">{{innerData ? innerData.invoice_title_en : '' }}</p>
    </div>
    <div v-else class="text-center">
        <p class="title-1 space-word-15 m-0" style="line-height: 1.5;">{{titleAR}}</p>
        <p class="title-2 m-0">{{titleEN}}</p>
    </div>
    <template v-if="innerTemplate.type == 'SalesInvoice' || innerTemplate.type == 'GeneralSales'">
      <div class="d-flex justify-content-end" v-if="showQRCode && innerData.status != 1">
        <div class="text-center" style="width: 220px;height: 220px;">
          <qr-code v-if="innerData.data_qr_code" :text="innerData.data_qr_code" :size="size" :error-level="'L'"></qr-code>
        </div>
      </div>
    </template>
    <div class="d-flex justify-content-end" v-else-if="showQRCode && innerData.data_qr_code" >
      <div class="text-center" style="width: 220px;height: 220px;">
        <qr-code v-if="innerData.data_qr_code" :text="innerData.data_qr_code" :size="size" :error-level="'L'"></qr-code>
      </div>
    </div>


  </div>

</template>

<script>
import VueQRCodeComponent from 'vue-qrcode-component';
import imageS3Url from "@/core/config/mix/imageS3Url";

export default {
  name: "PdfHeader",
  components: {
    'qr-code': VueQRCodeComponent
  },
  props: {innerData: {}, innerTemplate: {}, showQRCode:{type: Boolean, default: true}, titleType:{type: String, default: 'normal'}},
  data() {
    return {
      rel_logo: null,
      data: [],
      show_watermark: false,
      customer_type: null,
      size: 200,
    }
  },
  computed:{
    titleAR: function (){
      switch (this.titleType){
        case 'quotation':
          return 'عرض سعر';
        default:
          return 'فاتورة';
      }
    },
    titleEN: function (){
      switch (this.titleType){
        case 'quotation':
          return 'Sales Quotation';
        default:
          return 'Invoice';
      }
    },
  },
  methods: {
    ...imageS3Url
  },
  created() {
    if (this.innerData && this.innerData.company && this.innerData.company.logo_url) {
      // this.rel_logo = this.getImageFromS3(this.innerData.company.logo_url);
      this.rel_logo = this.innerData.company.logo_url;
      // this.getBase64Image(this.innerData.company.logo_url).then((res) => {
      // this.getImageFromS3(this.innerData.company.logo_url).then(dataUrl => {
      //   console.log('RESULT:', dataUrl)
      //   this.rel_logo = dataUrl;
      // });
      // })
    } else {
      // this.rel_logo = "https://upload.wikimedia.org/wikipedia/commons/e/eb/Creeperasha_Linux_test_image_upload.png";
      this.rel_logo = null;
    }
    if (this.innerData && this.innerData.customer) {
      this.customer_type = this.innerData.customer.customer_type;
    }
  }
}
</script>

<style scoped>
@import '~quill/dist/quill.core.css';
.watermark {
  position: absolute;
  top: 0;
  bottom: 0;
  opacity: 0.08;
  z-index: 0;
  color: white;
  width: 579px;
  left: 0;
  right: 0;
  margin: auto;
}
</style>