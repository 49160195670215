<template>
  <div>
    <b-card no-body class="mb-5">
      <b-card-header header-tab="header" class="p-1" role="tab">
        <b-button block v-b-toggle.accordion-2 variant="link" class="text-left font-weight-bold text-dark pl-8 pr-8 fs-102 collapsed-with-icon">
          {{ $t('advanced_filter') }}
        </b-button>
      </b-card-header>

      <b-collapse id="accordion-2" visible accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <div class="m-form m-form--fit m--margin-bottom-20">
            <div class="row mb-5">

              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="from_date">
                  {{ $t('sales_man_commission.from_date') }}
                </label>
                <input type="date" id="from_date" v-model="filters.f_from_date" class="form-control">
              </div>
              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="to_date">{{ $t('sales_man_commission.to_date') }}</label>
                <input type="date" id="to_date" v-model="filters.f_to_date" class="form-control">
              </div>
              <div class="form-group d-inline-flex col-md-6 mt-10">
                <b-button class="mt-auto mr-2" variant="primary" @click="doFilter"> {{ $t('display_report') }}</b-button>
                <b-button class="mt-auto mr-2" variant="danger" @click="resetFilter"><i class="fas fa-trash-restore"></i> {{ $t('reset_search') }}</b-button>

              </div>
            </div>
          </div>
        </b-card-body>
      </b-collapse>
    </b-card>

    <div class="card card-custom">
      <div class="card-body">
        <div>
          <div class="m-form m-form--fit m--margin-bottom-20">
            <div class="row">
              <div class="col-12 mt-10" id="summaryTableTitle">
                <h4 class="text-center">{{ $t('MENU.sales_man_commission') }} <span v-if="employee_name">({{employee_name}})</span></h4>
              </div>

              <div class="col-12 mt-10">
                <div class="table-responsive">
                  <table class="table table-bordered text-center">
                    <thead>
                    <tr>
                      <th>#</th>
                      <th>{{ $t('sales_man_commission.invoice_code') }}</th>
                      <th>{{ $t('sales_man_commission.invoice_date') }}</th>
                      <th>{{ $t('sales_man_commission.applied_commission_information') }}</th>
                      <th>{{ $t('sales_man_commission.sales_commission_value') }}</th>
                      <th>{{ $t('sales_man_commission.currency') }}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(row, index) in dataList" :key="index">
                      <td>{{ index+1 }}</td>
                      <td>{{ row.invoice_code }}</td>
                      <td>{{ row.invoice_date }}</td>
                      <td>{{ row.commission_value }} {{row.commission_type == 1 ?'%':''}}</td>
                      <td>{{ row.sales_claculated_commission_amount }}</td>
                      <td>{{ row.currency_name }}</td>
                    </tr>
                    </tbody>
                    <tfoot>
                    <tr>
                      <td colspan="5" style="text-align: start;"><b>{{$t('sales_man_commission.total_number_of_sales_invoices')}}</b></td>
                      <th>{{total.invoices_count ? total.invoices_count : 0}}</th>
                    </tr>
                    <tr>
                      <td colspan="5" style="text-align: start;"><b>{{$t('sales_man_commission.total_amount_commission_values')}}</b></td>
                      <th>{{total.total_sales_claculated_commission_amount  ? total.total_sales_claculated_commission_amount : 0}}</th>
                    </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- End Filter -->

      </div>
    </div>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
export default {
  name: 'sales-man-commission',
  data() {
    return {
      mainRoute: 'sales/sales_invoices/employees/commissions',
      mainRouteDependency: 'base/dependency',
      dataList: [],
      total: [],
      employee_name: this.$route.query.employee_name ? this.$route.query.employee_name : null,

      filters: {
        f_employee_id: this.$route.query.employee_id ? this.$route.query.employee_id : null,
        f_from_date: null,
        f_to_date: null,
      },
      page: 0,

    }
  },

  methods:{
    doFilter() {
      this.page = 1;
      this.getData();
    },
    resetFilter() {
      this.filters.f_from_date = null;
      this.filters.f_to_date = null;
      this.getData();

    },
    getData() {
      ApiService.get(this.mainRoute, {params: {...this.filters}}).then((response) => {
        this.dataList = response.data.data[0] && response.data.data[0].invoices ? response.data.data[0].invoices : [];

        this.total = {
          total_sales_claculated_commission_amount : response.data.data[0] && response.data.data[0].total_sales_claculated_commission_amount
              ? response.data.data[0].total_sales_claculated_commission_amount : 0,

          invoices_count : response.data.data[0] && response.data.data[0].invoices_count ? response.data.data[0].invoices_count : 0,
        };
      }).catch((error) => {
        this.$errorAlert(error)
      });
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{
      title: this.$t("MENU.employees_management"),
      route: '/employees/employees'
    }, {title: this.$t("MENU.sales_man_commission")}]);
  },
}
</script>

<style scoped>

</style>