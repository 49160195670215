let parseToShortFloat = (val) =>{
    if (val && parseFloat(val)){
        return parseFloat(val).toFixed(2)
    }
    return 0;
}
let parseToShortFloat3 = (val) =>{
    if (val && parseFloat(val)){
        return parseFloat(val).toFixed(3)
    }
    return 0;
}
let positiveNumber = (event) =>{
    let _keys = ['-', '+', 'e'];
    return  _keys.includes(event.key) ? event.preventDefault() : null
}
let parseToShortFloatFixed = (val, digits = 3) =>{
    if (val && parseFloat(val)){
        // if (!digits) digits = 3;
        // const pow = 10 ** digits
        // return (Math.trunc(val * pow) / pow).toString();
        let _value = val.toString().match(/^-?\d+(?:\.\d{0,3})?/)[0]
        return _value
    }
    return 0;
}
let defaultValueAfterDot = () =>{
    return '0.001';
}
export let onlyNumber = (val)=>{
    if (val) {
        return val.replace(/[a-zA-Z`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '')
    }
    return '';
}
let onlyFloatNumber = (val)=>{
    if (val) {
        return val.replace(/[a-zA-Z`~!@#$%^&*()_|+\-=?;:'",<>\{\}\[\]\\\/]/gi, '')
    }
    return '';
}
export default {parseToShortFloat, positiveNumber, parseToShortFloatFixed, defaultValueAfterDot, onlyNumber, onlyFloatNumber, parseToShortFloat3};