import Vue from "vue";
import Router from "vue-router";
import Permissions from "@/core/services/permissions.service";
import store from "@/core/services/store";
import {VERIFY_AUTH} from "@/core/services/store/auth.module";
import {RESET_LAYOUT_CONFIG} from "@/core/services/store/config.module";

Vue.use(Router);

const route = new Router({
    mode: 'history',
    routes: [{
        path: "/",
        redirect: "/dashboard",
        component: () =>
            import ("@/view/layout/Layout"),
        children: [{
            path: "/dashboard",
            name: "dashboard",
            component: () =>
                import ("@/view/pages/modules/dashboard/Index.vue"),
        },
            // ****************************** Begin Users *************************************************
            {
                path: "/users/users",
                name: "users.index",
                component: () =>
                    import ("@/view/pages/modules/users/Index.vue"),
                meta: {role: ['users.list']}
            },
            {
                path: "/users/users/create",
                name: "users.create",
                component: () =>
                    import ("@/view/pages/modules/users/Form.vue"),
                meta: {role: ['users.list']}
            },
            {
                path: "/users/users/edit/:id",
                name: "users.edit",
                component: () =>
                    import ("@/view/pages/modules/users/Form.vue"),
                meta: {role: ['users.update']}
            },
            {
                path: "/users/send/message",
                name: "users.sendMessage",
                component: () =>
                    import ("@/view/pages/modules/users/SendMessage.vue"),
                meta: {role: ['send_message_users.update']}
            },
            {
                path: "/users/customer-permissions/:user_id",
                name: "customer-permissions.list",
                component: () =>
                    import ("@/view/pages/modules/users/customerPermissions/Form.vue"),
                meta: {role: ['users.customer_permissions']}
            },
            {
                path: "/users/supplier-permissions/:user_id",
                name: "supplier-permissions.list",
                component: () =>
                    import ("@/view/pages/modules/users/supplierPermissions/Form.vue"),
                meta: {role: ['users.supplier_permissions']}
            },
            {
                path: "/users/pos-permissions/:user_id",
                name: "pos-permissions.list",
                component: () =>
                    import ("@/view/pages/modules/users/posPermissions/Form.vue"),
                meta: {role: ['users.pos_permissions']}
            },
            {
                path: "/users/invoices-bonds-management/:user_id",
                name: "invoices-bonds-management.list",
                component: () =>
                    import ("@/view/pages/modules/users/invoicesBondsManagement/Form.vue"),
                meta: {role: ['users.invoices_bonds_management']}
            },

            // ****************************** End Users ***************************************************

            // ****************************** Begin Roles *************************************************
            {
                path: "/users/roles",
                name: "roles.index",
                component: () =>
                    import ("@/view/pages/modules/roles/Index.vue"),
                meta: {role: ['roles.list']}
            },
            {
                path: "/users/roles/edit/:id",
                name: "roles.edit",
                component: () =>
                    import ("@/view/pages/modules/roles/Form.vue"),
                meta: {role: ['roles.update']}
            },
            // ****************************** End Roles *************************************************

            // ****************************** Begin Constants *******************************************
            {
                path: "/settings/constants",
                name: "constants.index",
                component: () =>
                    import ("@/view/pages/modules/settings/constants/Index.vue"),
                meta: {role: ['constants.list']}
            },
            // ****************************** End Constants ***********************************************

            // ****************************** Begin Units *************************************************
            {
                path: "/settings/units",
                name: "units.index",
                component: () =>
                    import ("@/view/pages/modules/settings/units/Index.vue"),
                meta: {role: ['units.list']}
            },
            // ****************************** End Units ************************************************************

            // ****************************** Begin Currencies *************************************************
            {
                path: "/settings/currencies",
                name: "currencies.index",
                component: () =>
                    import ("@/view/pages/modules/settings/currencies/Index.vue"),
                meta: {role: ['currencies.list']}
            },
            // ****************************** End Currencies ************************************************************

            // ****************************** Begin Code Setting *************************************************
            {
                path: "/settings/code-setting",
                name: "code_setting.index",
                component: () =>
                    import ("@/view/pages/modules/settings/codeSetting/Index.vue"),
            },
            // ****************************** End Units ************************************************************

            // ****************************** Begin Code Setting *************************************************
            {
                path: "/settings/procedures",
                name: "procedures.index",
                component: () =>
                    import ("@/view/pages/modules/settings/procedures/Index.vue"),
                meta: {role: ['procedures.list']}

            },
            // ****************************** End Units ************************************************************

            // ****************************** Begin key_generation *************************************************
            {
                path: "/settings/key-generation",
                name: "key_generation.index",
                component: () =>
                    import ("@/view/pages/modules/settings/key_generation/Index.vue"),
                meta: {role: ['key_generations.list']}
            },

            {
                path: "/settings/print-settings",
                name: "print_setting.index",
                component: () =>
                    import ("@/view/pages/modules/settings/printSettings/Index.vue"),
                meta: {role: ['print_setting.list']}
            },
            {
                path: "/settings/print-settings-details/:id/:slug",
                name: "print_setting.details",
                component: () =>
                    import ("@/view/pages/modules/settings/printSettings/Details.vue"),
                meta: {role: ['print_setting.list']}
            },
            // ****************************** End key_generation *************************************************

            // ****************************** Begin payment *************************************************
            {
                path: "/settings/payments",
                name: "payments.index",
                component: () =>
                    import ("@/view/pages/modules/settings/payments/Index.vue"),
                meta: {role: ['payments.list']}
            },

            // ****************************** End payment *************************************************

            // ****************************** Begin Tax *************************************************
            {
                path: "/settings/taxes",
                name: "taxes.index",
                component: () =>
                    import ("@/view/pages/modules/settings/taxes/Index.vue"),
                meta: {role: ['taxes.list']}
            },
            {
                path: "/settings/taxes/create",
                name: "taxes.create",
                component: () =>
                    import ("@/view/pages/modules/settings/taxes/Form.vue"),
                meta: {role: ['taxes.create']}
            },
            {
                path: "/settings/taxes/edit/:id",
                name: "taxes.edit",
                component: () =>
                    import ("@/view/pages/modules/settings/taxes/Form.vue"),
                meta: {role: ['taxes.update']}
            },
            // ****************************** End Tax *************************************************

            // ****************************** Begin Countries & Cities **********************************
            {
                path: "/settings/countries",
                name: "countries.index",
                component: () =>
                    import ("@/view/pages/modules/settings/countries/Index.vue"),
                meta: {role: ['countries.list']}
            },
            {
                path: "/settings/countries/edit/:id",
                name: "countries.edit",
                component: () =>
                    import ("@/view/pages/modules/settings/countries/Form.vue"),
                meta: {role: ['countries.update']}
            },

            {
                path: "/settings/cities/:code2",
                name: "cities.index",
                component: () =>
                    import ("@/view/pages/modules/settings/cities/Index.vue"),
                meta: {role: ['cities.list']}
            },
            // ****************************** End Countries ***************************************************

            // ****************************** Begin Users *****************************************************
            {
                path: "/company-configuration",
                name: "company-configuration.index",
                component: () =>
                    import ("@/view/pages/modules/configuration/company/Form.vue"),
                 meta:{role:['companies.company_profile']}
            },
            {
                path: "/user-settings",
                name: "user-settings.index",
                component: () =>
                    import ("@/view/pages/modules/configuration/user/Form.vue"),
                // meta:{role:['company-configuration.index']}
            },

            // ****************************** End Users ***************************************************

            // ****************************** Begin Branches *************************************************
            {
                path: "/settings/branches",
                name: "branches.index",
                component: () =>
                    import ("@/view/pages/modules/settings/branches/Index.vue"),
                meta: {role: ['branches.list']}
            },
            {
                path: "/settings/branches/create",
                name: "branches.create",
                component: () =>
                    import ("@/view/pages/modules/settings/branches/Form.vue"),
                meta: {role: ['branches.create']}
            },

            {
                path: "/settings/branches/edit/:id",
                name: "branches.edit",
                component: () =>
                    import ("@/view/pages/modules/settings/branches/Form.vue"),
                meta: {role: ['branches.update']}
            },
            // ****************************** End Branches ***************************************************

            // ****************************** Begin Smtp ***************************************************
            {
                path: "/settings/smtp",
                name: "smtp.index",
                component: () =>
                    import ("@/view/pages/modules/settings/smtp/Form.vue"),
                meta: {role: ['smtps.update']}
            },
            {
                path: "/settings/barcode-setting",
                name: "barcode_setting.index",
                component: () =>
                    import ("@/view/pages/modules/settings/barcodeSetting/Form.vue"),
                meta: {role: ['barcode_setting.update']}
            },
            // ****************************** End Smtp *****************************************************

            // ****************************** Begin template_design ***************************************************
            {
                path: "/settings/template_design",
                name: "template_design.index",
                component: () =>
                    import ("@/view/pages/modules/settings/template_design/Form.vue"),
                meta: {role: ['template_design.update']}
            },
            // ****************************** End template_design *****************************************************

            // ****************************** Begin InventoryStatistics *************************************************
            {
                path: "/items/inventory_statistics",
                name: "inventory_statistics.index",
                component: () =>
                    import ("@/view/pages/modules/settings/inventoryStatistics/Index.vue"),
                meta: {role: ['items.inventory_statistics']}
            },
            {
                path: "items/inventory_statistics/view/:id",
                name: "inventory_statistics.view",
                component: () =>
                    import ("@/view/pages/modules/settings/inventoryStatistics/View.vue"),
                meta: {role: ['items.inventory_statistics']}
            },
            {
                path: "/items/inventory_statistics_tracking",
                name: "inventory_statistics.index",
                component: () =>
                    import ("@/view/pages/modules/settings/inventoryStatisticsTraking/Index.vue"),
                meta: {role: ['items.inventory_statistics']}
            },
            // ****************************** End InventoryStatistics ************************************************************

            // ****************************** Begin Inventories *************************************************
            {
                path: "/settings/inventories",
                name: "inventories.index",
                component: () =>
                    import ("@/view/pages/modules/settings/inventories/Index.vue"),
                meta: {role: ['inventories.list']}
            },
            {
                path: "/settings/inventories/create",
                name: "inventories.create",
                component: () =>
                    import ("@/view/pages/modules/settings/inventories/Form.vue"),
                meta: {role: ['inventories.create']}
            },

            {
                path: "/settings/inventories/edit/:id",
                name: "inventories.edit",
                component: () =>
                    import ("@/view/pages/modules/settings/inventories/Form.vue"),
                meta: {role: ['inventories.update']}
            },
            // ****************************** End Inventories ***************************************************

            // ****************************** Begin Brands *************************************************
            {
                path: "/settings/brands",
                name: "brands.index",
                component: () =>
                    import ("@/view/pages/modules/settings/brands/Index.vue"),
                meta: {role: ['brands.list']}
            },
            // ****************************** End Brands ****************************************************
            // ****************************** Begin ApiConfiguration *************************************************
            {
                path: "/settings/api-configurations",
                name: "apiConfigurations.index",
                component: () =>
                    import ("@/view/pages/modules/settings/apiConfiguration/Index.vue"),
                meta: {role: ['api-configurations.list']}
            },
            {
                path: "/settings/api-configurations/create",
                name: "apiConfigurations.create",
                component: () =>
                    import ("@/view/pages/modules/settings/apiConfiguration/Form.vue"),
                meta: {role: ['api-configurations.create']}
            },

            {
                path: "/settings/api-configurations/edit/:id",
                name: "apiConfigurations.edit",
                component: () =>
                    import ("@/view/pages/modules/settings/apiConfiguration/Form.vue"),
                meta: {role: ['api-configurations.update']}
            },

            // ****************************** End ApiConfiguration ***************************************************

            // ****************************** Begin api internal configurations *************************************************

            {
                path: "/settings/api-internal-configurations",
                name: "apiInternalConfigurations.index",
                component: () =>
                    import ("@/view/pages/modules/settings/apiInternalConfigurations/Index.vue"),
                meta: {role: ['api_internal_configurations.list']}
            },
            {
                path: "/settings/api-internal-configurations/create",
                name: "apiInternalConfigurations.create",
                component: () =>
                    import ("@/view/pages/modules/settings/apiInternalConfigurations/Form.vue"),
                meta: {role: ['api_internal_configurations.create']}
            },
            {
                path: "/settings/api-internal-configurations/edit/:id",
                name: "apiInternalConfigurations.edit",
                component: () =>
                    import ("@/view/pages/modules/settings/apiInternalConfigurations/Form.vue"),
                meta: {role: ['api_internal_configurations.update']}
            },

            // ****************************** End api internal configurations ***************************************************

            // ****************************** Begin ApiEndpointConfiguration *************************************************
            {
                path: "/settings/api-endpoint-configurations/:provider_id",
                name: "apiEndpointConfigurations.index",
                component: () =>
                    import ("@/view/pages/modules/settings/apiConfiguration/apiEndpointConfiguration/Index.vue"),
                meta: {role: ['api-endpoint-configurations.list']}
            },
            {
                path: "/settings/api-endpoint-configurations/:provider_id/create",
                name: "apiEndpointConfigurations.create",
                component: () =>
                    import ("@/view/pages/modules/settings/apiConfiguration/apiEndpointConfiguration/Form.vue"),
                meta: {role: ['api-endpoint-configurations.create']}
            },

            {
                path: "/settings/api-endpoint-configurations/:provider_id/edit/:id",
                name: "apiEndpointConfigurations.edit",
                component: () =>
                    import ("@/view/pages/modules/settings/apiConfiguration/apiEndpointConfiguration/Form.vue"),
                meta: {role: ['api-endpoint-configurations.update']}
            },
            // ****************************** End ApiEndpointConfiguration ***************************************************

            // ****************************** Begin Manufacturers *******************************************
            {
                path: "/settings/manufacturers",
                name: "manufacturers.index",
                component: () =>
                    import ("@/view/pages/modules/settings/manufacturers/Index.vue"),
                meta: {role: ['manufacturers.list']}
            },
            // ****************************** End Manufacturers ***********************************************

            // ****************************** Begin Categories *******************************************
            {
                path: "/settings/categories",
                name: "categories.index",
                component: () =>
                    import ("@/view/pages/modules/settings/categories/Form.vue"),
                meta: {role: ['categories.list']}
            },
            // ****************************** End Categories ***********************************************
            // ****************************** Begin Categories list *******************************************
            {
                path: "/settings/categories/list",
                name: "categories-list.index",
                component: () =>
                    import ("@/view/pages/modules/settings/categories/Index.vue"),
                meta: {role: ['categories.list']}
            },
            // ****************************** End Categories list ***********************************************
            // ****************************** Begin Categories list *******************************************
            {
                path: "/settings/laundry-services-prices/:category_id",
                name: "laundry-services-prices.index",
                component: () =>
                    import ("@/view/pages/modules/settings/laundryServicesPricing/Form.vue"),
                meta: {role: ['categories.list']}
            },
            // ****************************** End Categories list ***********************************************

            // ****************************** Begin income Categories *******************************************
            {
                path: "/finances/income_categories",
                name: "income_categories.index",
                component: () =>
                    import ("@/view/pages/modules/finances/incomeCategories/Form.vue"),
                meta: {role: ['income_categories.list']}
            },
            // ****************************** End income Categories ***********************************************

            // ****************************** Begin treasury-checking *************************************************
            {
                path: "finances/treasury-checking",
                name: "treasury-checking.index",
                component: () =>
                    import ("@/view/pages/modules/finances/treasuryChecking/Index.vue"),
                meta: {role: ['treasury_checking.list']}
            },
            {
                path: "finances/treasury-checking/create",
                name: "treasury-checking.create",
                component: () =>
                    import ("@/view/pages/modules/finances/treasuryChecking/Form.vue"),
                meta: {role: ['treasury_checking.create']}
            },
            {
                path: "finances/treasury-checking/edit/:id?",
                name: "treasury-checking.edit",
                component: () =>
                    import ("@/view/pages/modules/finances/treasuryChecking/Form.vue"),
                meta: {role: ['treasury_checking.update']}
            },

            {
                path: "finances/treasury-checking-history",
                name: "treasury-checking-history.index",
                component: () =>
                    import ("@/view/pages/modules/finances/treasuryCheckingHistory/Index.vue"),
                meta: {role: ['treasury_checking.treasury_checking_history']}
            },
            // ******************************* End treasury-checking **************************************************

            // ****************************** Begin inventory-checking *************************************************
            {
                path: "items/inventory-checking",
                name: "inventory-checking.index",
                component: () =>
                    import ("@/view/pages/modules/items/inventoryChecking/Index.vue"),
                meta: {role: ['inventory_checking.list']}
            },
            {
                path: "items/inventory-checking/edit/:id?",
                name: "inventory-checking.edit",
                component: () =>
                    import ("@/view/pages/modules/items/inventoryChecking/Form.vue"),
                meta: {role: ['inventory_checking.update']}
            },

            {
                path: "items/inventory-checking-history",
                name: "inventory-checking-history.index",
                component: () =>
                    import ("@/view/pages/modules/items/inventoryCheckingHistory/Index.vue"),
                meta: {role: ['inventory_checking.inventory_checking_history']}
            },
            // ******************************* End inventory-checking **************************************************

            // ****************************** Begin Items *************************************************
            // {
            //     path: "/items/items/:previous_page_id?",
            //     query: {previous_page_id: null},
            //     name: "items.index",
            //     component: () =>
            //         import ("@/view/pages/modules/items/items/Index.vue"),
            //     meta: {role: ['items.list']}
            // },
            {
                path: "/items/items",
                query: {previous_page_id: null},
                name: "items.index",
                component: () =>
                    import ("@/view/pages/modules/items/items/Index.vue"),
                meta: {role: ['items.list']}
            },
            {
                path: "/items/items/create",
                name: "items.create",
                component: () =>
                    import ("@/view/pages/modules/items/items/Form.vue"),
                meta: {role: ['items.create']}
            },

            {
                path: "/items/items/edit/:id/:previous_page_id?",
                name: "items.edit",
                component: () =>
                    import ("@/view/pages/modules/items/items/Form.vue"),
                meta: {role: ['items.update']}
            },
            // ****************************** End Items ***************************************************
            // ****************************** Begin compositeItems *************************************************
            {
                path: "/items/composite-items",
                name: "composite-items.index",
                component: () =>
                    import ("@/view/pages/modules/items/compositeItems/Index.vue"),
                meta: {role: ['items.list']}
            },
            {
                path: "/items/composite-items/create",
                name: "composite-items.create",
                component: () =>
                    import ("@/view/pages/modules/items/compositeItems/Form.vue"),
                meta: {role: ['items.create']}
            },

            {
                path: "/items/composite-items/edit/:id",
                name: "composite-items.edit",
                component: () =>
                    import ("@/view/pages/modules/items/compositeItems/Form.vue"),
                meta: {role: ['items.update']}
            },
            // ****************************** End compositeItems ***************************************************
            // ****************************** Begin bundle-and-device *************************************************
            {
                path: "/items/bundle-and-device",
                name: "bundle-and-device.index",
                component: () =>
                    import ("@/view/pages/modules/items/bundleAndDevice/Index.vue"),
                meta: {role: ['bundle_and_device.list']}
            },
            {
                path: "/items/bundle-and-device/create",
                name: "bundle-and-device.create",
                component: () =>
                    import ("@/view/pages/modules/items/bundleAndDevice/Form.vue"),
                meta: {role: ['bundle_and_device.create']}
            },

            {
                path: "/items/bundle-and-device/edit/:id",
                name: "bundle-and-device.edit",
                component: () =>
                    import ("@/view/pages/modules/items/bundleAndDevice/Form.vue"),
                meta: {role: ['bundle_and_device.update']}
            },
            // ****************************** End bundle-and-device ***************************************************
            // ****************************** Begin bundle-divide *************************************************
            {
                path: "/items/bundle-divide/:bundle_id?",
                name: "bundle-divide.index",
                component: () =>
                    import ("@/view/pages/modules/items/bundleDivide/Index.vue"),
                meta: {role: ['divide_bundle.list', 'bundle_and_device.bundle_device']}
            },
            {
                path: "/items/bundle-divide/create/:bundle_id",
                name: "bundle-divide.create",
                component: () =>
                    import ("@/view/pages/modules/items/bundleDivide/Form.vue"),
                meta: {role: ['divide_bundle.create', 'bundle_and_device.add_bundle_and_device']}
            },

            {
                path: "/items/bundle-divide/edit/:id",
                name: "bundle-divide.edit",
                component: () =>
                    import ("@/view/pages/modules/items/bundleDivide/Form.vue"),
                meta: {role: ['divide_bundle.update', 'bundle_and_device.bundle_device']}
            },
            // ****************************** End bundle-divide ***************************************************

            // ****************************** Begin collected-items *************************************************
            {
                path: "/items/collected-items",
                name: "collected-items.index",
                component: () =>
                    import ("@/view/pages/modules/items/collectedItems/Index.vue"),
                meta: {role: ['collected_items.list']}
            },
            {
                path: "/items/collected-items/create",
                name: "collected-items.create",
                component: () =>
                    import ("@/view/pages/modules/items/collectedItems/Form.vue"),
                meta: {role: ['collected_items.create']}
            },

            {
                path: "/items/collected-items/edit/:id",
                name: "collected-items.edit",
                component: () =>
                    import ("@/view/pages/modules/items/collectedItems/Form.vue"),
                meta: {role: ['collected_items.update']}
            },
            // ****************************** End collected-items ***************************************************
            // ****************************** Begin aspects *************************************************
            {
                path: "/items/aspects",
                name: "aspects.index",
                component: () =>
                    import ("@/view/pages/modules/items/aspects/Index.vue"),
                meta: {role: ['aspect.list']}
            },
            {
                path: "/items/aspects/create",
                name: "aspects.create",
                component: () =>
                    import ("@/view/pages/modules/items/aspects/Form.vue"),
                meta: {role: ['aspect.create']}
            },

            {
                path: "/items/aspects/edit/:id",
                name: "aspects.edit",
                component: () =>
                    import ("@/view/pages/modules/items/aspects/Form.vue"),
                meta: {role: ['aspect.update']}
            },
            // ****************************** End aspects ***************************************************

            // ****************************** Begin Items *************************************************
            {
                path: "/items/price-lists",
                name: "price-lists.index",
                component: () =>
                    import ("@/view/pages/modules/items/priceList/Index.vue"),
                meta: {role: ['price-list.list']}
            },
            {
                path: "/items/price-lists/create",
                name: "price-lists.create",
                component: () =>
                    import ("@/view/pages/modules/items/priceList/Form.vue"),
                meta: {role: ['price-list.create']}
            },

            {
                path: "/items/price-lists/edit/:id",
                name: "price-lists.edit",
                component: () =>
                    import ("@/view/pages/modules/items/priceList/Form.vue"),
                meta: {role: ['price-list.update']}
            },
            // ****************************** End Items ***************************************************

            // ****************************** Begin Customers Module ***********************************************

            // ****************************** Begin Customers ***********************************************
            {
                path: "/customers/customers",
                name: "customers.index",
                component: () =>
                    import ("@/view/pages/modules/customers/customers/Index.vue"),
                meta: {role: ['customers.list']}
            },
            {
                path: "/customers/customers/create",
                name: "customers.create",
                component: () =>
                    import ("@/view/pages/modules/customers/customers/Form.vue"),
                meta: {role: ['customers.create']}
            },
            {
                path: "/customers/customers/edit/:id",
                name: "customers.edit",
                component: () =>
                    import ("@/view/pages/modules/customers/customers/Form.vue"),
                meta: {role: ['customers.update']}
            },
            {
                path: "/customers/customers/view/:id",
                name: "customers.view",
                component: () =>
                    import ("@/view/pages/modules/customers/customers/View.vue"),
                meta: {role: ['customers.list']}
            },
            {
                path: "/customer/send/mail/:id",
                name: "customer.sendMail",
                component: () =>
                    import ("@/view/pages/modules/customers/customers/SendMail.vue"),
                meta: {role: ['customers.update']}
            },

            // ****************************** End Customers ****************************************************
            // ****************************** Begin Customers status ****************************************************
            {
                path: "/customers/customers-status",
                name: "customers-status.index",
                component: () =>
                    import ("@/view/pages/modules/customers/customersStatus/Form.vue"),
                meta: {role: ['customer-status.update']}
            },
            // ****************************** End Customers status ****************************************************
            // ****************************** Begin Customers attributes ****************************************************
            {
                path: "/customers/customer-attributes",
                name: "customer-attributes.index",
                component: () =>
                    import ("@/view/pages/modules/customers/customerAttributes/Form.vue"),
                meta: {role: ['customer-attributes.update']}
            },
            // ****************************** End Customers attributes ****************************************************
            // ****************************** Begin Customers features ****************************************************
            {
                path: "/customers/customer*-features",
                name: "customer-features.index",
                component: () =>
                    import ("@/view/pages/modules/customers/customersFeature/Form.vue"),
                meta: {role: ['customer-features.update']}
            },
            // ****************************** End Customers features ****************************************************
            // ****************************** Begin Appointments ***********************************************
            {
                path: "/customers/appointments",
                name: "appointments.index",
                component: () =>
                    import ("@/view/pages/modules/customers/appointments/Index.vue"),
                meta: {role: ['appointments.list']}
            },
            {
                path: "/customers/appointments/create/:sale_invoice_id?",
                name: "appointments.create",
                component: () =>
                    import ("@/view/pages/modules/customers/appointments/Form.vue"),
                meta: {role: ['appointments.create']}
            },
            {
                path: "/customers/appointments/edit/:id",
                name: "appointments.edit",
                component: () =>
                    import ("@/view/pages/modules/customers/appointments/Form.vue"),
                meta: {role: ['appointments.update']}
            },

            // ****************************** Begin Appointments ***********************************************

            // ****************************** End Customers Module **************************************************
            // ****************************** Begin Inventory transactions **************************************************
            {
                path: "/items/inventory-transactions",
                name: "inventory-transactions.index",
                component: () =>
                    import ("@/view/pages/modules/items/inventoryTransactions/Index.vue"),
                meta: {role: ['inventory-transactions.list']}
            },
            {
                path: "/items/inventory-transactions/create",
                name: "inventory-transactions.create",
                component: () =>
                    import ("@/view/pages/modules/items/inventoryTransactions/Form.vue"),
                meta: {role: ['inventory-transactions.create']}
            },
            {
                path: "/items/inventory-transactions/edit/:id",
                name: "inventory-transactions.edit",
                component: () =>
                    import ("@/view/pages/modules/items/inventoryTransactions/Form.vue"),
                meta: {role: ['inventory-transactions.update']}
            },
            // ****************************** End Inventory transactions **************************************************
            // ****************************** Begin Item configuration **************************************************
            {
                path: "/items/item_label_configuration",
                name: "item_label_configuration.index",
                component: () =>
                    import ("@/view/pages/modules/items/configuration/Form.vue"),
                meta: {role: ['item_configuration.update']}
            },
            {
                path: "/items/item_table_settings",
                name: "item_table_settings.index",
                component: () =>
                    import ("@/view/pages/modules/items/configuration/TableSettings.vue"),
                meta: {role: ['item_configuration.update']}
            },
            // ****************************** End Item configuration **************************************************
            // ****************************** Begin Inventory transactions **************************************************
            {
                path: "/items/inventory-monitoring",
                name: "inventory-monitoring.index",
                component: () =>
                    import ("@/view/pages/modules/items/inventoryMonitoring/Index.vue"),
                meta: {role: ['inventory-monitoring.list']}
            },
            {
                path: "/items/inventory-monitoring/create",
                name: "inventory-monitoring.create",
                component: () =>
                    import ("@/view/pages/modules/items/inventoryMonitoring/Form.vue"),
                meta: {role: ['inventory-monitoring.create']}
            },
            {
                path: "/items/inventory-monitoring/edit/:id",
                name: "inventory-monitoring.edit",
                component: () =>
                    import ("@/view/pages/modules/items/inventoryMonitoring/Form.vue"),
                meta: {role: ['inventory-monitoring.update']}
            },
            // ****************************** End Inventory transactions **************************************************

            // ****************************** Begin suppliers ***********************************************
            {
                path: "purchases/suppliers",
                name: "suppliers.index",
                component: () =>
                    import ("@/view/pages/modules/purchases/suppliers/Index.vue"),
                meta: {role: ['suppliers.list']}
            },
            {
                path: "purchases/suppliers/create",
                name: "suppliers.create",
                component: () =>
                    import ("@/view/pages/modules/purchases/suppliers/Form.vue"),
                meta: {role: ['suppliers.create']}
            },

            {
                path: "purchases/suppliers/edit/:id",
                name: "suppliers.edit",
                component: () =>
                    import ("@/view/pages/modules/purchases/suppliers/Form.vue"),
                meta: {role: ['suppliers.update']}
            },

            {
                path: "purchases/suppliers/view/:id",
                name: "suppliers.view",
                component: () =>
                    import ("@/view/pages/modules/purchases/suppliers/View.vue"),
                meta: {role: ['suppliers.list']}
            },
            {
                path: "suppliers/export-import-suppliers",
                name: "export-import-suppliers.index",
                component: () =>
                    import ("@/view/pages/modules/purchases/suppliers/importSuppliers/Index.vue"),
                meta: {role: ['suppliers.export_import_suppliers']}
            },

            // ****************************** End suppliers **************************************************
            // ****************************** Begin Purchases Invoices ***********************************************
            {
                path: "purchases/purchases-invoices",
                name: "purchases-invoices.index",
                component: () =>
                    import ("@/view/pages/modules/purchases/purchaseInvoice/Index.vue"),
                meta: {role: ['purchases_invoices.list']}
            },
            {
                path: "purchases/purchases-invoices/payment_details/:id",
                name: "purchases_invoices.payment_details",
                component: () =>
                    import ("@/view/pages/modules/purchases/purchaseInvoice/PaymentDetails.vue"),
                meta: {role: ['purchases_invoices.payment_details']}
            },
            {
                path: "purchases/purchases-invoices/create/:purchase_request_id?/:quotation_type?",
                name: "purchases-invoices.create",
                component: () =>
                    import ("@/view/pages/modules/purchases/purchaseInvoice/Form.vue"),
                meta: {
                    role: ['purchases_invoices.create', 'purchase_quotations.convert_to_purchase_invoice',
                        'purchase_orders.convert_to_purchase_invoice', 'purchases_requests.transfer_to_purchase_invoice']
                }
            },
            {
                path: "purchases/purchases-invoices/edit/:id",
                name: "purchases-invoices.edit",
                component: () =>
                    import ("@/view/pages/modules/purchases/purchaseInvoice/Form.vue"),
                meta: {role: ['purchases_invoices.update']}
            },
            {
                path: "purchases/purchases-invoices/view/:id",
                name: "purchases-invoices.view",
                component: () =>
                    import ("@/view/pages/modules/purchases/purchaseInvoice/View.vue"),
                meta: {role: ['purchases_invoices.list']}
            },
            // ****************************** End Purchases Invoices **************************************************

            // ****************************** Begin Purchases Refunds *************************************************
            {
                path: "purchases/purchases-refunds",
                name: "purchases-refunds.index",
                component: () =>
                    import ("@/view/pages/modules/purchases/purchaseRefund/Index.vue"),
                meta: {role: ['purchases_refunds.list']}
            },
            {
                path: "purchases/purchases-refunds/payment-details/:id",
                name: "purchases_invoices.payment_details",
                component: () =>
                    import ("@/view/pages/modules/purchases/purchaseRefund/PaymentDetails.vue"),
                meta: {role: ['purchases_refunds.payment_details']}
            },

            {
                path: "purchases/purchases-refunds/create/:purchase_invoice_id?",
                name: "purchases-refunds.create",
                component: () =>
                    import ("@/view/pages/modules/purchases/purchaseRefund/Form.vue"),
                meta: {role: ['purchases_refunds.create', 'purchases_invoices.purchase_refund']}
            },
            {
                path: "purchases/purchases-refunds/edit/:id",
                name: "purchases-refunds.edit",
                component: () =>
                    import ("@/view/pages/modules/purchases/purchaseRefund/Form.vue"),
                meta: {role: ['purchases_refunds.update']}
            },
            {
                path: "purchases/purchases-refunds/view/:id",
                name: "purchases-refunds.view",
                component: () =>
                    import ("@/view/pages/modules/purchases/purchaseRefund/View.vue"),
                meta: {role: ['purchases_refunds.list']}
            },
            // ******************************* End Purchases Refunds **************************************************
            // ****************************** Begin Purchases configuration *************************************************
            {
                path: "purchases/purchase-configuration",
                name: "purchase-configuration.index",
                component: () =>
                    import ("@/view/pages/modules/purchases/purchaseConfiguration/Index.vue"),
                meta: {role: ['purchases-settings.update']}
            },
            // ******************************* End Purchases configuration **************************************************

            // ****************************** Begin Sales estimations *************************************************
            {
                path: "sales/estimations",
                name: "estimations.index",
                component: () =>
                    import ("@/view/pages/modules/sales/estimations/Index.vue"),
                meta: {role: ['estimations.list']}
            },
            {
                path: "sales/estimations/create",
                name: "estimations.create",
                component: () =>
                    import ("@/view/pages/modules/sales/estimations/Form.vue"),
                meta: {role: ['estimations.create']}
            },
            {
                path: "sales/estimations/edit/:id",
                name: "estimations.edit",
                component: () =>
                    import ("@/view/pages/modules/sales/estimations/Form.vue"),
                meta: {role: ['estimations.update']}
            },
            {
                path: "sales/estimations/clone/:clone_id",
                name: "estimations.clone",
                component: () =>
                    import ("@/view/pages/modules/sales/estimations/Form.vue"),
                meta: {role: ['estimations.clone']}
            },
            {
                path: "sales/attachment-notes/create/:type_id/:type",
                name: "attachment-notes.create",
                component: () =>
                    import ("@/view/pages/modules/sales/attachmentNotes/Form.vue"),
                meta: {role: ['attachment_notes.create', 'sales_invoices.attachment_notes', 'estimations.attachment_notes', 'employees.attachment_notes', 'general_sales.attachment_notes']}
            },
            {
                path: "sales/estimations/create/:type_id/:type",
                name: "appointment.create",
                component: () =>
                    import ("@/view/pages/modules/customers/appointments/Form.vue"),
                // meta: { role: ['attachment_notes.create'] }
            },
            // ******************************* End Sales estimations **************************************************

            // ****************************** Begin Sales invoices *************************************************
            {
                path: "sales/sales_invoices",
                name: "sales_invoices.index",
                component: () =>
                    import ("@/view/pages/modules/sales/invoices/Index.vue"),
                meta: {role: ['sales_invoices.list']}
            },
            {
                path: "sales/sales_invoice/payment_details/:id",
                name: "sales_invoices.payment_details",
                component: () =>
                    import ("@/view/pages/modules/sales/invoices/PaymentDetails.vue"),
                meta: {role: ['sales_invoices.payment_details']}
            },
            {
                path: "sales/sales_invoices/create/:quotation_requests_id?/:quotation_type?",
                name: "sales_invoices.create",
                component: () =>
                    import ("@/view/pages/modules/sales/invoices/Form.vue"),
                meta: {role: ['sales_invoices.create', 'sales_quotations.convert_to_sales_invoice']}
            },
            {
                path: "sales/sales_invoices/edit/:id",
                name: "sales_invoices.edit",
                component: () =>
                    import ("@/view/pages/modules/sales/invoices/Form.vue"),
                meta: {role: ['sales_invoices.update']}
            },
            {
                path: "sales/sales_invoices/view/:id",
                name: "sales_invoices.view",
                component: () =>
                    import ("@/view/pages/modules/sales/invoices/View.vue"),
                meta: {role: ['sales_invoices.view']}
            },
            {
                path: "sales/sales_invoices/clone/:clone_id",
                name: "sales_invoices.clone",
                component: () =>
                    import ("@/view/pages/modules/sales/invoices/Form.vue"),
                meta: {role: ['sales_invoices.clone']}
            },
            {
                path: "sales/cost-center/create/:sales_invoice_id",
                name: "cost-center.create",
                component: () =>
                    import ("@/view/pages/modules/sales/costCenter/Form.vue"),
                meta: {role: ['add_to_cost_center.create', 'sales_invoices.add_to_cost_center']}
            },
            {
                path: "sales/attachment-notes/create/:type_id/:type",
                name: "attachment-notes.create",
                component: () =>
                    import ("@/view/pages/modules/sales/attachmentNotes/Form.vue"),
                meta: {role: ['attachment_notes.create']}
            },
            {
                path: "sales/appointment/create/:type_id/:type",
                name: "appointment.create",
                component: () =>
                    import ("@/view/pages/modules/customers/appointments/Form.vue"),
                meta: {role: ['schedule_appointment.create', 'sales_invoices.schedule_appointment', 'estimations.schedule_appointment', 'general_sales.schedule_appointment']}
            },
            // path: "purchases/purchases-refunds/create/:purchase_invoice_id?",
            {
                path: "settings/custom/email/:type_id/:type",
                name: "custom.email",
                component: () =>
                    import ("@/view/pages/modules/settings/customEmail/Form.vue"),
                meta: {role: ['sales_invoices.send_mail', 'payment_sales_invoices.send_mail', 'payment_purchase_invoices.send_mail', 'payable_cheques.send_mail', 'receivable_cheques.send_mail', 'general_sales.send_mail']}
            },

            // ******************************* End Sales invoices **************************************************
            // ****************************** Begin Sales credits *************************************************
            {
                path: "sales/credits",
                name: "credits.index",
                component: () =>
                    import ("@/view/pages/modules/sales/credits/Index.vue"),
                meta: {role: ['credits.list']}
            },
            {
                path: "sales/credits/create/:sale_invoice_id?",
                name: "credits.create",
                component: () =>
                    import ("@/view/pages/modules/sales/credits/Form.vue"),
                meta: {role: ['credits.create', 'sales_invoices.add_sales_credit']}
            },
            {
                path: "sales/credits/edit/:id",
                name: "credits.edit",
                component: () =>
                    import ("@/view/pages/modules/sales/credits/Form.vue"),
                meta: {role: ['credits.update']}
            },
            // ******************************* End Sales credits **************************************************
            // ****************************** Begin General Sales credits *************************************************
            {
                path: "sales/general-credits",
                name: "general-credits.index",
                component: () =>
                    import ("@/view/pages/modules/sales/generalCredits/Index.vue"),
                meta: {role: ['general_credits.list']}
            },
            {
                path: "sales/general-credits/create/:sale_invoice_id?",
                name: "general-credits.create",
                component: () =>
                    import ("@/view/pages/modules/sales/generalCredits/Form.vue"),
                meta: {role: ['general_credits.create', 'general_sales.add_sales_credit']}
            },
            {
                path: "sales/general-credits/edit/:id",
                name: "general-credits.edit",
                component: () =>
                    import ("@/view/pages/modules/sales/generalCredits/Form.vue"),
                meta: {role: ['general_credits.update']}
            },
            // ******************************* End General Sales credits **************************************************


            // ****************************** Begin Sales shipping ***********************************************************
            {
                path: "sales/sales_shipping",
                name: "sales_shipping.index",
                component: () =>
                    import ("@/view/pages/modules/sales/shipping/Index.vue"),
                // meta: { role: ['sales_shipping.list'] }
            },
            {
                path: "sales/sales_shipping/create",
                name: "sales_shipping.create",
                component: () =>
                    import ("@/view/pages/modules/sales/shipping/Form.vue"),
                meta: {role: ['sales_shipping.create']}
            },
            {
                path: "sales/sales_shipping/edit/:id",
                name: "sales_shipping.edit",
                component: () =>
                    import ("@/view/pages/modules/sales/shipping/Form.vue"),
                meta: {role: ['sales_shipping.update']}
            },
            // ******************************* End Sales shipping ************************************************************
            // ****************************** Begin Sales configuration ******************************************************
            {
                path: "sales/sales_configuration",
                name: "sales_configuration.index",
                component: () =>
                    import ("@/view/pages/modules/sales/configuration/Form.vue"),
                meta: {role: ['sales_configuration.update']}
            },
            // ******************************* End Sales configuration *********************************************************

            // ****************************** Begin Sales Refund ***********************************************************
            {
                path: "sales/sales_refund",
                name: "sales_refund.index",
                component: () =>
                    import ("@/view/pages/modules/sales/refund/Index.vue"),
                meta: {role: ['sales_refund.list']}
            },
            {
                path: "sales/sales_refund/create/:sales_invoice_id",
                name: "sales_refund.create",
                component: () =>
                    import ("@/view/pages/modules/sales/refund/Form.vue"),
                meta: {role: ['sales_refund.create', 'sales_invoices.sales_refund']}
            },
            {
                path: "sales/sales_refund/edit/:id",
                name: "sales_refund.edit",
                component: () =>
                    import ("@/view/pages/modules/sales/refund/Form.vue"),
                meta: {role: ['sales_refund.update']}
            },
            // ******************************* End Sales Refund ************************************************************

            // ****************************** Begin finances expenses_category *************************************************
            {
                path: "finances/expenses_category",
                name: "expenses_category.index",
                component: () =>
                    import ("@/view/pages/modules/finances/expencesCategory/Form.vue"),
                meta: {role: ['expenses_category.list']}
            },
            // ******************************* End finances expenses_category **************************************************
            // ****************************** Begin expenses *******************************************************************
            {
                path: "finances/expenses",
                name: "expenses.index",
                component: () =>
                    import ("@/view/pages/modules/finances/expenses/Index.vue"),
                meta: {role: ['expenses.list']}
            },
            {
                path: "finances/expenses/create",
                name: "expenses.create",
                component: () =>
                    import ("@/view/pages/modules/finances/expenses/Form.vue"),
                meta: {role: ['expenses.create']}
            },
            {
                path: "finances/expenses/edit/:id",
                name: "expenses.edit",
                component: () =>
                    import ("@/view/pages/modules/finances/expenses/Form.vue"),
                meta: {role: ['expenses.update']}
            },
            {
                path: "finances/expenses/view/:id",
                name: "expenses.view",
                component: () =>
                    import ("@/view/pages/modules/finances/expenses/View.vue"),
                meta: {role: ['expenses.list']}
            },
            // ******************************* End expenses **************************************************

            // ****************************** Begin incomes *************************************************
            {
                path: "finances/incomes",
                name: "incomes.index",
                component: () =>
                    import ("@/view/pages/modules/finances/incomes/Index.vue"),
                meta: {role: ['incomes.list']}
            },
            {
                path: "finances/incomes/create",
                name: "incomes.create",
                component: () =>
                    import ("@/view/pages/modules/finances/incomes/Form.vue"),
                meta: {role: ['incomes.create']}
            },
            {
                path: "finances/incomes/view/:id",
                name: "incomes.view",
                component: () =>
                    import ("@/view/pages/modules/finances/incomes/View.vue"),
                meta: {role: ['incomes.list']}
            },
            {
                path: "finances/incomes/edit/:id",
                name: "incomes.edit",
                component: () =>
                    import ("@/view/pages/modules/finances/incomes/Form.vue"),
                meta: {role: ['incomes.update']}
            },
            // ******************************* End incomes **************************************************

            // ****************************** Begin Bank *************************************************
            {
                path: "finances/banks",
                name: "banks.index",
                component: () =>
                    import ("@/view/pages/modules/finances/banks/Index.vue"),
                meta: {role: ['banks.list']}
            },
            {
                path: "finances/banks/create",
                name: "banks.create",
                component: () =>
                    import ("@/view/pages/modules/finances/banks/Form.vue"),
                meta: {role: ['banks.create']}
            },
            {
                path: "finances/banks/edit/:id",
                name: "banks.edit",
                component: () =>
                    import ("@/view/pages/modules/finances/banks/Form.vue"),
                meta: {role: ['banks.update']}
            },
            {
                path: "finances/banks/view/:id",
                name: "banks.view",
                component: () =>
                    import ("@/view/pages/modules/finances/banks/View.vue"),
                meta: {role: ['banks.list']}
            },
            // ******************************* End Bank **************************************************
            // ****************************** Begin Bank *************************************************
            {
                path: "finances/treasuries",
                name: "treasuries.index",
                component: () =>
                    import ("@/view/pages/modules/finances/treasuries/Index.vue"),
                meta: {role: ['treasuries.list']}
            },
            {
                path: "finances/treasuries/create",
                name: "treasuries.create",
                component: () =>
                    import ("@/view/pages/modules/finances/treasuries/Form.vue"),
                meta: {role: ['treasuries.create']}
            },
            {
                path: "finances/treasuries/view/:id",
                name: "treasuries.view",
                component: () =>
                    import ("@/view/pages/modules/finances/treasuries/View.vue"),
                meta: {role: ['treasuries.list']}
            },
            {
                path: "finances/treasuries/edit/:id",
                name: "treasuries.edit",
                component: () =>
                    import ("@/view/pages/modules/finances/treasuries/Form.vue"),
                meta: {role: ['treasuries.update']}
            },
            // ******************************* End Bank **************************************************
            // ****************************** Begin Purchases Subscriptions *************************************************
            {
                path: "purchases/subscriptions",
                name: "subscriptions.index",
                component: () =>
                    import ("@/view/pages/modules/purchases/subscriptions/Index.vue"),
                meta: {role: ['subscriptions.list']}
            },
            {
                path: "purchases/subscriptions/create",
                name: "subscriptions.create",
                component: () =>
                    import ("@/view/pages/modules/purchases/subscriptions/Form.vue"),
                meta: {role: ['subscriptions.create']}
            },
            {
                path: "purchases/subscriptions/edit/:id",
                name: "subscriptions.edit",
                component: () =>
                    import ("@/view/pages/modules/purchases/subscriptions/Form.vue"),
                meta: {role: ['subscriptions.update']}
            },
            // ******************************* End Purchases Subscriptions **************************************************
            // ****************************** Begin Payment Sales Invoices *************************************************
            {
                path: "sales/payment_sales_invoices",
                name: "payment_sales_invoices.index",
                component: () =>
                    import ("@/view/pages/modules/sales/paymentSalesInvoices/Index.vue"),
                meta: {role: ['payment_sales_invoices.list']}
            },
            {
                path: "sales/payment_sales_invoices/create/:sales_invoice_id/:type?",
                name: "payment_sales_invoices.create",
                component: () =>
                    import ("@/view/pages/modules/sales/paymentSalesInvoices/Form.vue"),
                meta: {
                    role: ['payment_sales_invoices.create', 'sales_invoices.payment_sales_invoices',
                        'general_sales.payment_sales_invoices', 'sales_refund.payment_sales_invoices',
                        'sales_debit.payment_sales_invoices', 'credits.payment_sales_invoices',
                        'sales_rent.payment_sales_invoices', 'general_sales_rent.payment_sales_invoices']
                }
            },
            {
                path: "sales/payment_sales_invoices/edit/:id/:type?",
                name: "payment_sales_invoices.edit",
                component: () =>
                    import ("@/view/pages/modules/sales/paymentSalesInvoices/Form.vue"),
                meta: {role: ['payment_sales_invoices.update']}
            },
            {
                path: "sales/payment_sales_invoices/details/:id",
                name: "payment_sales_invoices.details",
                component: () =>
                    import ("@/view/pages/modules/sales/paymentSalesInvoices/Details.vue"),
                meta: {role: ['payment_sales_invoices.details']}
            },
            {
                path: "sales/payment_sales_invoices/payment-sales-details/:id",
                name: "payment_sales_invoices.payment_sales_details",
                component: () =>
                    import ("@/view/pages/modules/sales/paymentSalesInvoices/paymentSalesDetails.vue"),
                meta: {role: ['payment_sales_invoices.details']}
            },
            // ******************************* End Payment Sales Invoices **************************************************
            // ****************************** Begin Employees *************************************************
            {
                path: "employees/employees",
                name: "employees.index",
                component: () =>
                    import ("@/view/pages/modules/employees/employees/Index.vue"),
                meta: {role: ['employees.list']}
            },
            {
                path: "employees/employees/create",
                name: "employees.create",
                component: () =>
                    import ("@/view/pages/modules/employees/employees/Form.vue"),
                meta: {role: ['employees.create']}
            },
            {
                path: "employees/employees/edit/:id",
                name: "employees.edit",
                component: () =>
                    import ("@/view/pages/modules/employees/employees/Form.vue"),
                meta: {role: ['employees.update']}
            },
            {
                path: "employees/attachment-notes/create/:type_id/:type",
                name: "attachment-notes.create",
                component: () =>
                    import ("@/view/pages/modules/sales/attachmentNotes/Form.vue"),
                meta: {role: ['attachment_notes.create']}
            },
            {
                path: "employees/sales-man-commission",
                name: "sales-man-commission.create",
                component: () =>
                    import ("@/view/pages/modules/employees/salesManCommissions/Index.vue"),
                // meta: {role: ['employees.sales_man_commission']}
            },

            // ******************************* End Employees ***********************************************************
            // ****************************** Begin departments *************************************************
            {
                path: "employees/departments",
                name: "departments.index",
                component: () =>
                    import ("@/view/pages/modules/employees/departments/Index.vue"),
                meta: {role: ['departments.list']}
            },


            // ******************************* End departments ***********************************************************
            // ****************************** Begin Purchases Requests *************************************************
            {
                path: "purchases/purchases-requests",
                name: "purchases-requests.index",
                component: () =>
                    import ("@/view/pages/modules/purchases/request/Index.vue"),
                meta: {role: ['purchases_requests.list']}
            },
            {
                path: "purchases/purchases-requests/create",
                name: "purchases-requests.create",
                component: () =>
                    import ("@/view/pages/modules/purchases/request/Form.vue"),
                meta: {role: ['purchases_requests.create']}
            },
            {
                path: "purchases/purchases-requests/edit/:id",
                name: "purchases-requests.edit",
                component: () =>
                    import ("@/view/pages/modules/purchases/request/Form.vue"),
                meta: {role: ['purchases_requests.update']}
            },
            {
                path: "purchases/purchases-requests/view/:id",
                name: "purchases-requests.view",
                component: () =>
                    import ("@/view/pages/modules/purchases/request/View.vue"),
                meta: {role: ['purchases_requests.list']}
            },
            // ******************************* End Purchases Requests **************************************************
            // ****************************** Begin Purchases Quotation Request *************************************************
            {
                path: "purchases/quotation-requests",
                name: "quotation-requests.index",
                component: () =>
                    import ("@/view/pages/modules/purchases/quotationRequest/Index.vue"),
                meta: {role: ['quotations_requests.list']}
            },
            {
                path: "purchases/quotation-requests/create",
                name: "quotation-requests.create",
                component: () =>
                    import ("@/view/pages/modules/purchases/quotationRequest/Form.vue"),
                meta: {role: ['quotations_requests.create']}
            },
            {
                path: "purchases/quotation-requests/edit/:id",
                name: "quotation-requests.edit",
                component: () =>
                    import ("@/view/pages/modules/purchases/quotationRequest/Form.vue"),
                meta: {role: ['quotations_requests.update']}
            },
            {
                path: "purchases/quotation-requests/view/:id",
                name: "quotation-requests.view",
                component: () =>
                    import ("@/view/pages/modules/purchases/quotationRequest/View.vue"),
                meta: {role: ['quotations_requests.list']}
            },
            // ******************************* End Purchases Quotation Request **************************************************
            // ****************************** Begin Purchases Orders *************************************************
            {
                path: "purchases/purchases-orders",
                name: "purchases-orders.index",
                component: () =>
                    import ("@/view/pages/modules/purchases/purchaseOrder/Index.vue"),
                meta: {role: ['purchase_orders.list']}
            },
            {
                path: "purchases/purchases-orders/create",
                name: "purchases-orders.create",
                component: () =>
                    import ("@/view/pages/modules/purchases/purchaseOrder/Form.vue"),
                meta: {role: ['purchase_orders.create']}
            },
            {
                path: "purchases/purchases-orders/edit/:id",
                name: "purchases-orders.edit",
                component: () =>
                    import ("@/view/pages/modules/purchases/purchaseOrder/Form.vue"),
                meta: {role: ['purchase_orders.update']}
            },
            {
                path: "purchases/purchases-orders/view/:id",
                name: "purchases-orders.view",
                component: () =>
                    import ("@/view/pages/modules/purchases/purchaseOrder/View.vue"),
                meta: {role: ['purchase_orders.list']}
            },
            // ******************************* End Purchases Orders **************************************************
            // ****************************** Begin Cheques Books *************************************************
            {
                path: "cheques-cycle/cheque-book",
                name: "cheque-book.index",
                component: () =>
                    import ("@/view/pages/modules/chequesCycle/chequesBooks/Index.vue"),
                meta: {role: ['book_cheques.list']}
            },
            {
                path: "cheques-cycle/cheque-book/create",
                name: "cheque-book.create",
                component: () =>
                    import ("@/view/pages/modules/chequesCycle/chequesBooks/Form.vue"),
                meta: {role: ['book_cheques.create']}
            },
            {
                path: "cheques-cycle/cheque-book/edit/:id",
                name: "cheque-book.edit",
                component: () =>
                    import ("@/view/pages/modules/chequesCycle/chequesBooks/Form.vue"),
                meta: {role: ['book_cheques.update']}
            },
            {
                path: "cheques-cycle/cheque-book/view/:id",
                name: "cheque-book.view",
                component: () =>
                    import ("@/view/pages/modules/chequesCycle/chequesBooks/View.vue"),
                meta: {role: ['book_cheques.list']}
            },
            // ******************************* End Cheques Books **************************************************
            // ****************************** Begin Payable Cheques *************************************************
            {
                path: "cheques-cycle/payable-cheques",
                name: "payable-cheques.index",
                component: () =>
                    import ("@/view/pages/modules/chequesCycle/payableCheques/Index.vue"),
                meta: {role: ['payable_cheques.list']}
            },
            {
                path: "cheques-cycle/payable-cheques/create",
                name: "payable-cheques.create",
                component: () =>
                    import ("@/view/pages/modules/chequesCycle/payableCheques/Form.vue"),
                meta: {role: ['payable_cheques.create']}
            },
            {
                path: "cheques-cycle/payable-cheques/edit/:id",
                name: "payable-cheques.edit",
                component: () =>
                    import ("@/view/pages/modules/chequesCycle/payableCheques/Form.vue"),
                meta: {role: ['payable_cheques.update']}
            },
            {
                path: "cheques-cycle/payable-cheques/view/:id",
                name: "payable-cheques.view",
                component: () =>
                    import ("@/view/pages/modules/chequesCycle/payableCheques/View.vue"),
                meta: {role: ['payable_cheques.list']}
            },
            // ******************************* End Payable Cheques **************************************************
            // ****************************** Begin Receivable Cheques *************************************************
            {
                path: "cheques-cycle/receivable-cheques",
                name: "receivable-cheques.index",
                component: () =>
                    import ("@/view/pages/modules/chequesCycle/receivableCheques/Index.vue"),
                meta: {role: ['receivable_cheques.list']}
            },
            {
                path: "cheques-cycle/receivable-cheques/create",
                name: "receivable-cheques.create",
                component: () =>
                    import ("@/view/pages/modules/chequesCycle/receivableCheques/Form.vue"),
                meta: {role: ['receivable_cheques.create']}
            },
            {
                path: "cheques-cycle/receivable-cheques/edit/:id",
                name: "receivable-cheques.edit",
                component: () =>
                    import ("@/view/pages/modules/chequesCycle/receivableCheques/Form.vue"),
                meta: {role: ['receivable_cheques.update']}
            },
            {
                path: "cheques-cycle/receivable-cheques/view/:id",
                name: "receivable-cheques.view",
                component: () =>
                    import ("@/view/pages/modules/chequesCycle/receivableCheques/View.vue"),
                meta: {role: ['receivable_cheques.list']}
            },
            // ******************************* End Receivable Cheques **************************************************
            // ****************************** Begin open stock items *************************************************
            {
                path: "items/open-stock-items",
                name: "open-stock-items.index",
                component: () =>
                    import ("@/view/pages/modules/items/openStockItems/Index.vue"),
                meta: {role: ['item_open_stock.list']}
            },

            {
                path: "items/open-quantity-history",
                name: "open-quantity-history.index",
                component: () =>
                    import ("@/view/pages/modules/items/openStockItems/IndexHistory.vue"),
                meta: {role: ['item_open_stock.list']}
            },

            {
                path: "items/open-stock-items/create",
                name: "open-stock-items.create",
                component: () =>
                    import ("@/view/pages/modules/items/openStockItems/Form.vue"),
                meta: {role: ['item_open_stock.create']}
            },
            {
                path: "items/open-stock-items/edit/:id",
                name: "open-stock-items.edit",
                component: () =>
                    import ("@/view/pages/modules/items/openStockItems/Form.vue"),
                meta: {role: ['item_open_stock.update']}
            },
            {
                path: "items/open-stock-items/view/:id",
                name: "open-stock-items.view",
                component: () =>
                    import ("@/view/pages/modules/items/openStockItems/View.vue"),
                meta: {role: ['item_open_stock.list']}
            },
            // ******************************* End open stock items **************************************************
            // ****************************** Begin laundry subscriptions  **************************************
            {
                path: "pos/laundry-subscriptions",
                name: "laundry-subscriptions.index",
                component: () =>
                    import ("@/view/pages/modules/pos/laundrySubscriptions/Index.vue"),
                meta: {role: ['laundry_subscriptions.list']}
            },
            {
                path: "pos/laundry-subscriptions/create",
                name: "laundry-subscriptions.create",
                component: () =>
                    import ("@/view/pages/modules/pos/laundrySubscriptions/Form.vue"),
                meta: {role: ['laundry_subscriptions.create']}
            },
            {
                path: "pos/laundry-subscriptions/edit/:id",
                name: "laundry-subscriptions.edit",
                component: () =>
                    import ("@/view/pages/modules/pos/laundrySubscriptions/Form.vue"),
                meta: {role: ['laundry_subscriptions.update']}
            },
            {
                path: "/pos/laundry-subscriptions/view/:id",
                name: "laundry-subscriptions.view",
                component: () =>
                    import ("@/view/pages/modules/pos/laundrySubscriptions/View.vue"),
                meta: {role: ['laundry_subscriptions.list']}
            },
            {
                path: "/pos/laundry-subscriptions/add-subscriber/:id",
                name: "laundry-subscriptions.add-subscriber",
                component: () =>
                    import ("@/view/pages/modules/pos/laundrySubscriptions/AddSubscriber.vue"),
                meta: {role: ['laundry_subscriptions.customers']}
            },
            // ****************************** End laundry subscriptions  **************************************
            // ****************************** Begin pos-devices *****************************************************
            {
                path: "pos/pos-devices",
                name: "pos-devices.index",
                component: () =>
                    import ("@/view/pages/modules/pos/posDevices/Index.vue"),
                meta: {role: ['pos_devices.list']}
            },
            {
                path: "pos/pos-devices/create",
                name: "pos-devices.create",
                component: () =>
                    import ("@/view/pages/modules/pos/posDevices/Form.vue"),
                meta: {role: ['pos_devices.create']}
            },
            {
                path: "pos/pos-devices/edit/:id",
                name: "pos-devices.edit",
                component: () =>
                    import ("@/view/pages/modules/pos/posDevices/Form.vue"),
                meta: {role: ['pos_devices.update']}
            },
            // ******************************* End pos-devices **************************************************
            // ****************************** Begin pos-stores *****************************************************
            {
                path: "pos/pos-stores",
                name: "pos-stores.index",
                component: () =>
                    import ("@/view/pages/modules/pos/posStores/Index.vue"),
                meta: {role: ['pos_stores.list']}
            },
            {
                path: "pos/pos-stores/create",
                name: "pos-stores.create",
                component: () =>
                    import ("@/view/pages/modules/pos/posStores/Form.vue"),
                meta: {role: ['pos_stores.create']}
            },
            {
                path: "pos/pos-stores/edit/:id",
                name: "pos-stores.edit",
                component: () =>
                    import ("@/view/pages/modules/pos/posStores/Form.vue"),
                meta: {role: ['pos_stores.update']}
            },
            // ******************************* End pos-stores **************************************************
            // ****************************** Begin pos-shifts *************************************************
            {
                path: "pos/pos-shifts",
                name: "pos-shifts.index",
                component: () =>
                    import ("@/view/pages/modules/pos/posShifts/Index.vue"),
                meta: {role: ['pos_shifts.list']}
            },
            {
                path: "pos/pos-shifts/create",
                name: "pos-shifts.create",
                component: () =>
                    import ("@/view/pages/modules/pos/posShifts/Form.vue"),
                meta: {role: ['pos_shifts.create']}
            },
            {
                path: "pos/pos-shifts/edit/:id",
                name: "pos-shifts.edit",
                component: () =>
                    import ("@/view/pages/modules/pos/posShifts/Form.vue"),
                meta: {role: ['pos_shifts.update']}
            },
            // ******************************* End pos-devices ***************************************************
            // ****************************** Begin pos-settings *************************************************
            {
                path: "pos/pos-settings",
                name: "pos-settings.index",
                component: () =>
                    import ("@/view/pages/modules/pos/posSettings/Form.vue"),
                meta: {role: ['pos_settings.update']}
            },
            // ******************************* End pos-settings *****************************************************
            // ****************************** Begin financial-years *************************************************
            {
                path: "accounting/financial-years",
                name: "financial-years.index",
                component: () =>
                    import ("@/view/pages/modules/accounting/financialYears/Index.vue"),
                meta: {role: ['financial_years.list']}
            },
            {
                path: "accounting/financial-years/create",
                name: "financial-years.create",
                component: () =>
                    import ("@/view/pages/modules/accounting/financialYears/Form.vue"),
                meta: {role: ['financial_years.create']}
            },
            {
                path: "accounting/financial-years/edit/:id",
                name: "financial-years.edit",
                component: () =>
                    import ("@/view/pages/modules/accounting/financialYears/Form.vue"),
                meta: {role: ['financial_years.update']}
            },
            {
                path: "accounting/financial-years/view/:id",
                name: "financial-years.view",
                component: () =>
                    import ("@/view/pages/modules/accounting/financialYears/View.vue"),
                meta: {role: ['financial_years.list']}
            },

            // ******************************* End financial-years **************************************************

            // ****************************** Begin Closed Periods *************************************************
            {
                path: "accounting/closed-periods",
                name: "closed-periods.index",
                component: () =>
                    import ("@/view/pages/modules/accounting/closedPeriods/Index.vue"),
                meta: {role: ['closed_periods.list']}
            },
            {
                path: "accounting/closed-periods/create",
                name: "closed-periods.create",
                component: () =>
                    import ("@/view/pages/modules/accounting/closedPeriods/Form.vue"),
                meta: {role: ['closed_periods.create']}
            },
            {
                path: "accounting/closed-periods/edit/:id",
                name: "closed-periods.edit",
                component: () =>
                    import ("@/view/pages/modules/accounting/closedPeriods/Form.vue"),
                meta: {role: ['closed_periods.update']}
            },
            {
                path: "accounting/closed-periods/view/:id",
                name: "closed-periods.view",
                component: () =>
                    import ("@/view/pages/modules/accounting/closedPeriods/View.vue"),
                meta: {role: ['closed_periods.list']}
            },

            // ******************************* End Closed Periods **************************************************

            // ****************************** Begin insurance Agents *************************************************
            {
                path: "insuranceagents/insurance-agents",
                name: "insurance-agents.index",
                component: () =>
                    import ("@/view/pages/modules/insuranceagents/insuranceAgents/Index.vue"),
                meta: {role: ['insurance_agents.list']}
            },
            {
                path: "insuranceagents/insurance-agents/create",
                name: "insurance-agents.create",
                component: () =>
                    import ("@/view/pages/modules/insuranceagents/insuranceAgents/Form.vue"),
                meta: {role: ['insurance_agents.create']}
            },
            {
                path: "insuranceagents/insurance-agents/edit/:id",
                name: "insurance-agents.edit",
                component: () =>
                    import ("@/view/pages/modules/insuranceagents/insuranceAgents/Form.vue"),
                meta: {role: ['insurance_agents.update']}
            },
            {
                path: "insuranceagents/insurance-agents/view/:id",
                name: "insurance-agents.view",
                component: () =>
                    import ("@/view/pages/modules/insuranceagents/insuranceAgents/View.vue"),
                meta: {role: ['insurance_agents.list']}
            },

            {
                path: "insuranceagents/insurance-agents/classes/:id",
                name: "insurance-agents.classes",
                component: () =>
                    import ("@/view/pages/modules/insuranceagents/insuranceAgents/Classes.vue"),
                meta: {role: ['insurance_agents.classes']}
            },

            // ******************************* End insurance Agents **************************************************

            // ****************************** Begin credit-types *************************************************
            {
                path: "points-credits/credit-types",
                name: "credit-types.index",
                component: () =>
                    import ("@/view/pages/modules/pointsCredits/creditTypes/Index.vue"),
                meta: {role: ['credit_type.list']}
            },
            {
                path: "points-credits/credit-types/create",
                name: "credit-types.create",
                component: () =>
                    import ("@/view/pages/modules/pointsCredits/creditTypes/Form.vue"),
                meta: {role: ['credit_type.create']}
            },
            {
                path: "points-credits/credit-types/edit/:id",
                name: "credit-types.edit",
                component: () =>
                    import ("@/view/pages/modules/pointsCredits/creditTypes/Form.vue"),
                meta: {role: ['credit_type.update']}
            },
            {
                path: "points-credits/credit-types/view/:id",
                name: "credit-types.view",
                component: () =>
                    import ("@/view/pages/modules/pointsCredits/creditTypes/View.vue"),
                meta: {role: ['credit_type.list']}
            },

            // ******************************* End credit-types **************************************************

            // ****************************** Begin credit-usages *************************************************
            {
                path: "points-credits/credit-usages",
                name: "credit-usages.index",
                component: () =>
                    import ("@/view/pages/modules/pointsCredits/creditUsages/Index.vue"),
                meta: {role: ['credit_usage.list']}
            },
            {
                path: "points-credits/credit-usages/create",
                name: "credit-usages.create",
                component: () =>
                    import ("@/view/pages/modules/pointsCredits/creditUsages/Form.vue"),
                meta: {role: ['credit_usage.create']}
            },
            {
                path: "points-credits/credit-usages/edit/:id",
                name: "credit-usages.edit",
                component: () =>
                    import ("@/view/pages/modules/pointsCredits/creditUsages/Form.vue"),
                meta: {role: ['credit_usage.update']}
            },
            {
                path: "points-credits/credit-usages/view/:id",
                name: "credit-usages.view",
                component: () =>
                    import ("@/view/pages/modules/pointsCredits/creditUsages/View.vue"),
                meta: {role: ['credit_usage.list']}
            },

            // ******************************* End credit-usages **************************************************

            // ****************************** Begin package-info *************************************************
            {
                path: "points-credits/package-info",
                name: "package-info.index",
                component: () =>
                    import ("@/view/pages/modules/pointsCredits/packageInfo/Index.vue"),
                meta: {role: ['package_info.list']}
            },
            {
                path: "points-credits/package-info/create",
                name: "package-info.create",
                component: () =>
                    import ("@/view/pages/modules/pointsCredits/packageInfo/Form.vue"),
                meta: {role: ['package_info.create']}
            },
            {
                path: "points-credits/package-info/edit/:id",
                name: "package-info.edit",
                component: () =>
                    import ("@/view/pages/modules/pointsCredits/packageInfo/Form.vue"),
                meta: {role: ['package_info.update']}
            },
            {
                path: "points-credits/package-info/view/:id",
                name: "package-info.view",
                component: () =>
                    import ("@/view/pages/modules/pointsCredits/packageInfo/View.vue"),
                meta: {role: ['package_info.list']}
            },

            // ******************************* End package-info **************************************************
            // ****************************** Begin sales-commissions *************************************************
            {
                path: "salestargetcommission/sales-commissions",
                name: "sales-commissions.index",
                component: () =>
                    import ("@/view/pages/modules/salesTargetCommission/commissionRule/Index.vue"),
                meta: {role: ['sales_commission.list']}
            },
            {
                path: "salestargetcommission/sales-commissions/create",
                name: "sales-commissions.create",
                component: () =>
                    import ("@/view/pages/modules/salesTargetCommission/commissionRule/Form.vue"),
                meta: {role: ['sales_commission.create']}
            },
            {
                path: "salestargetcommission/sales-commissions/edit/:id",
                name: "sales-commissions.edit",
                component: () =>
                    import ("@/view/pages/modules/salesTargetCommission/commissionRule/Form.vue"),
                meta: {role: ['sales_commission.update']}
            },
            {
                path: "salestargetcommission/sales-commissions/view/:id",
                name: "sales-commissions.view",
                component: () =>
                    import ("@/view/pages/modules/salesTargetCommission/commissionRule/View.vue"),
                meta: {role: ['sales_commission.list']}
            },

            // ******************************* End sales-commissions **************************************************
            // ****************************** Begin sales-periods *************************************************
            {
                path: "salestargetcommission/sales-periods",
                name: "sales-periods.index",
                component: () =>
                    import ("@/view/pages/modules/salesTargetCommission/salesPeriods/Index.vue"),
                meta: {role: ['sales_period.list']}
            },
            {
                path: "salestargetcommission/sales-periods/create",
                name: "sales-periods.create",
                component: () =>
                    import ("@/view/pages/modules/salesTargetCommission/salesPeriods/Form.vue"),
                meta: {role: ['sales_period.create']}
            },
            {
                path: "salestargetcommission/sales-periods/edit/:id",
                name: "sales-periods.edit",
                component: () =>
                    import ("@/view/pages/modules/salesTargetCommission/salesPeriods/Form.vue"),
                meta: {role: ['sales_period.update']}
            },
            {
                path: "salestargetcommission/sales-periods/view/:id",
                name: "sales-periods.view",
                component: () =>
                    import ("@/view/pages/modules/salesTargetCommission/salesPeriods/View.vue"),
                meta: {role: ['sales_period.list']}
            },

            // ******************************* End sales-periods **************************************************
            // ****************************** Begin sales-commission-details *************************************************
            {
                path: "salestargetcommission/sales-commission-details",
                name: "sales-commission-details.index",
                component: () =>
                    import ("@/view/pages/modules/salesTargetCommission/salesCommissionDetails/Index.vue"),
                meta: {role: ['sales_commission_details.list']}
            },
            {
                path: "salestargetcommission/sales-commission-details/view/:id",
                name: "sales-commission-details.view",
                component: () =>
                    import ("@/view/pages/modules/salesTargetCommission/salesCommissionDetails/View.vue"),
                meta: {role: ['sales_commission_details.list']}
            },

            // ******************************* End sales-commission-details **************************************************
            // ****************************** Begin chart-accounts *************************************************
            {
                path: "accounting/chart-accounts",
                name: "chart-accounts.index",
                component: () =>
                    import ("@/view/pages/modules/accounting/chartAccounts/Index.vue"),
                meta: {role: ['chart_accounts.list']}
            },
            {
                path: "accounting/chart-accounts/create/:parent_id?",
                name: "chart-accounts.create",
                component: () =>
                    import ("@/view/pages/modules/accounting/chartAccounts/Form.vue"),
                meta: {role: ['chart_accounts.create']}
            },
            {
                path: "accounting/chart-accounts/edit/:id",
                name: "chart-accounts.edit",
                component: () =>
                    import ("@/view/pages/modules/accounting/chartAccounts/Form.vue"),
                meta: {role: ['chart_accounts.update']}
            },

            {
                path: "accounting/chart-account-setting",
                name: "chart-accounts.setting.index",
                component: () =>
                    import ("@/view/pages/modules/accounting/chartAccounts/Setting.vue"),
                meta: {role: ['chart_accounts_settings.update']}
            },
            // ******************************* End chart-accounts **************************************************
            // ****************************** Begin journal-entries *************************************************
            {
                path: "accounting/journal-entries",
                name: "journal-entries.index",
                component: () =>
                    import ("@/view/pages/modules/accounting/journalEntries/Index.vue"),
                meta: {role: ['jornal_entries.list']}
            },
            {
                path: "accounting/journal-entries/create",
                name: "journal-entries.create",
                component: () =>
                    import ("@/view/pages/modules/accounting/journalEntries/Form.vue"),
                // meta: { role: ['jornal_entries.create'] }
            },
            {
                path: "accounting/journal-entries/edit/:id",
                name: "journal-entries.edit",
                component: () =>
                    import ("@/view/pages/modules/accounting/journalEntries/Form.vue"),
                // meta: { role: ['jornal_entries.update'] }
            },

            // ******************************* End journal-entries **************************************************
            // ****************************** Begin assets-info *************************************************
            {
                path: "accounting/assets-info",
                name: "assets-info.index",
                component: () =>
                    import ("@/view/pages/modules/accounting/assetsInfo/Index.vue"),
                meta: {role: ['assets_info.list']}
            },
            {
                path: "accounting/assets-info/create",
                name: "assets-info.create",
                component: () =>
                    import ("@/view/pages/modules/accounting/assetsInfo/Form.vue"),
                meta: {role: ['assets_info.create']}
            },
            {
                path: "accounting/assets-info/edit/:id",
                name: "assets-info.edit",
                component: () =>
                    import ("@/view/pages/modules/accounting/assetsInfo/Form.vue"),
                meta: {role: ['assets_info.update']}
            },

            // ******************************* End assets-info **************************************************
            // ****************************** Begin accounts_routing *************************************************

            {
                path: "accounting/accounts-routing",
                name: "accounts-routing.create",
                component: () =>
                    import ("@/view/pages/modules/accounting/accountsRouting/Form.vue"),
                meta: {role: ['accounts_routing.update']}
            },


            // ******************************* End assets-info **************************************************
            // ****************************** Begin chart-accounts *************************************************
            {
                path: "accounting/cost-centers",
                name: "cost-centers.index",
                component: () =>
                    import ("@/view/pages/modules/accounting/costCenters/Index.vue"),
                meta: {role: ['cost_centers.list']}
            },
            {
                path: "accounting/cost-centers/create/:parent_id?",
                name: "cost-centers.create",
                component: () =>
                    import ("@/view/pages/modules/accounting/costCenters/Form.vue"),
                meta: {role: ['cost_centers.create']}
            },
            {
                path: "accounting/cost-centers/edit/:id",
                name: "cost-centers.edit",
                component: () =>
                    import ("@/view/pages/modules/accounting/costCenters/Form.vue"),
                meta: {role: ['cost_centers.update']}
            },

            {
                path: "accounting/cost-centers/transactions",
                name: "cost-centers.transactions",
                component: () =>
                    import ("@/view/pages/modules/accounting/costCenters/Transactions.vue"),
                meta: {role: ['cost_centers.all_transactions']}
            },

            // ******************************* End chart-accounts **************************************************
            // ****************************** Begin clients-loyalty *************************************************
            {
                path: "loyalty/clients-loyalty",
                name: "clients-loyalty.list",
                component: () =>
                    import ("@/view/pages/modules/loyalty/clientsLoyalty/Form.vue"),
                meta: {role: ['clients_loyalty.update']}
            },

            {
                path: "loyalty/loyalty-rules",
                name: "loyalty-rules.list",
                component: () =>
                    import ("@/view/pages/modules/loyalty/loyaltyRules/Index.vue"),
                meta: {role: ['loyalty_rules.list']}
            },

            {
                path: "loyalty/loyalty-rules/create",
                name: "loyalty-rules.create",
                component: () =>
                    import ("@/view/pages/modules/loyalty/loyaltyRules/Form.vue"),
                meta: {role: ['loyalty_rules.create']}
            },

            {
                path: "loyalty/loyalty-rules/edit/:id",
                name: "loyalty-rules.edit",
                component: () =>
                    import ("@/view/pages/modules/loyalty/loyaltyRules/Form.vue"),
                meta: {role: ['loyalty_rules.update']}
            },

            // ******************************* End chart-accounts **************************************************
            // ****************************** Begin installment *************************************************

            {
                path: "installment/installment",
                name: "installment.list",
                component: () =>
                    import ("@/view/pages/modules/installment/installment/Index.vue"),
                meta: {role: ['installments.list']}
            },
            {
                path: "installment/installment/create/:invoice_id",
                name: "installment.create",
                component: () =>
                    import ("@/view/pages/modules/installment/installment/Form.vue"),
                meta: {role: ['installments.create', 'sales_invoices.installments']}
            },
            {
                path: "installment/installment/edit/:id",
                name: "installment.edit",
                component: () =>
                    import ("@/view/pages/modules/installment/installment/Form.vue"),
                meta: {role: ['installments.update']}
            },
            {
                path: "installment/installment/view/:id",
                name: "installment.view",
                component: () =>
                    import ("@/view/pages/modules/installment/installment/View.vue"),
                meta: {role: ['installments.list']}
            },
            {
                path: "installment/installment/pay/:details_id/:installment_id",
                name: "installment.pay",
                component: () =>
                    import ("@/view/pages/modules/installment/installment/Pay.vue"),
                meta: {role: ['installments.pay']}
            },
            // ******************************* End installment **************************************************
            // ****************************** Begin installment payments *************************************************

            {
                path: "installment/payments",
                name: "installment-payments.list",
                component: () =>
                    import ("@/view/pages/modules/installment/payments/Index.vue"),
                meta: {role: ['installment_payments.list']}
            },
            {
                path: "installment/payments/view/:id",
                name: "installment-payments.view",
                component: () =>
                    import ("@/view/pages/modules/installment/payments/View.vue"),
                meta: {role: ['installment_payments.list']}
            },

            // ******************************* End installment payments **************************************************
            // ****************************** Begin pos-sessions *****************************************************
            {
                path: "pos/pos-session",
                name: "pos-session.index",
                component: () =>
                    import ("@/view/pages/modules/pos/posSessions/Index.vue"),
                meta: {role: ['pos_session.list']}
            },
            {
                path: "pos/pos-session/create",
                name: "pos-session.create",
                component: () =>
                    import ("@/view/pages/modules/pos/posSessions/Form.vue"),
                meta: {role: ['pos_session.create']}
            },
            {
                path: "pos/pos-session/view/:id/:current_tab?",
                name: "pos-session.view",
                component: () =>
                    import ("@/view/pages/modules/pos/posSessions/View.vue"),
                meta: {role: ['pos_session.details']}
            },
            {
                path: "pos/cash-closing/:id",
                name: "pos-session.cash-closing",
                component: () =>
                    import ("@/view/pages/modules/pos/posSessions/cashClosing.vue"),
                meta: {role: ['pos_session.cash_closing']}
            },
            {
                path: "pos/pos-session/invoice/view/:id",
                name: "pos-session-invoice.view",
                component: () =>
                    import ("@/view/pages/modules/pos/posSessions/invoice/View.vue"),
                meta: {role: ['pos_session.list']}
            },
            {
                path: "pos/pos-session/refund/view/:id",
                name: "pos-session-refund.view",
                component: () =>
                    import ("@/view/pages/modules/pos/posSessions/refund/View.vue"),
                meta: {role: ['pos_refund.view']}
            },
            {
                path: "pos/cash-movement/:id",
                name: "cash-movement.list",
                component: () =>
                    import ("@/view/pages/modules/pos/cashMovement/Index.vue"),
                meta: {role: ['cash_movement.list']}
            },
            // {
            //     path: "pos/pos-session/edit/:id",
            //     name: "pos-session.edit",
            //     component: () =>
            //         import ("@/view/pages/modules/pos/posSessions/Form.vue"),
            //     // meta: { role: ['pos_session.update'] }
            // },
            // ******************************* End pos-sessions **************************************************

            // ****************************** Begin accounts-without-cost-center *************************************************

            {
                path: "accounting/accounts-without-cost-center",
                name: "accounts-without-cost-center.list",
                component: () =>
                    import ("@/view/pages/modules/accounting/accountsWithoutCostCenter/Index.vue"),
                meta: {role: ['accounts_without_cost_center.list']}
            },
            {
                path: "accounting/accounts-without-cost-center/:id",
                name: "accounts-without-cost-center.create",
                component: () =>
                    import ("@/view/pages/modules/accounting/accountsWithoutCostCenter/Form.vue"),
                meta: {role: ['accounts_without_cost_center.create', 'accounts_without_cost_center.assign_cost_center']}
            },

            // ******************************* End accounts-without-cost-center **************************************************
            // ****************************** Begin accounts-with-cost-center *************************************************

            {
                path: "accounting/accounts-with-cost-center",
                name: "accounts-with-cost-center.list",
                component: () =>
                    import ("@/view/pages/modules/accounting/accountsWithCostCenter/Index.vue"),
                meta: {role: ['accounts_with_cost_center.list']}
            },

            // ******************************* End accounts-with-cost-center **************************************************
            // ****************************** Begin cost-center-transactions *************************************************

            {
                path: "accounting/cost-center-transactions",
                name: "cost-center-transactions.list",
                component: () =>
                    import ("@/view/pages/modules/accounting/costCenterTransactions/Index.vue"),
                meta: {role: ['cost_center_transactions.list']}
            },

            // ******************************* End accounts-with-cost-center **************************************************
            // ****************************** Begin default-journalist-setting *************************************************

            {
                path: "accounting/default-journalist-setting",
                name: "default-journalist-setting.list",
                component: () =>
                    import ("@/view/pages/modules/accounting/defaultJournalistSetting/Form.vue"),
                meta: {role: ['default_journalist_setting.list']}
            },

            // ******************************* End default-journalist-setting **************************************************
            // ****************************** Begin daily-brief-report-invoices *************************************************

            {
                path: "daily-brief-report-invoices",
                name: "daily-brief-report-invoices.list",
                component: () =>
                    import ("@/view/pages/modules/statistics/dailyBriefReportInvoices/Index.vue"),
                meta: {role: ['report_daily_brief_invoices.list']}
            },

            // ******************************* End daily-brief-report-invoices **************************************************
            // ****************************** Begin daily-brief-report-payments *************************************************

            {
                path: "daily-brief-report-payments",
                name: "daily-brief-report-payments.list",
                component: () =>
                    import ("@/view/pages/modules/statistics/dailyBriefReportPaymants/Index.vue"),
                meta: {role: ['report_stores.daily_brief_report_payments']}
            },

            // ******************************* End daily-brief-report-payments **************************************************
            // ****************************** Begin stocks-report *************************************************
            {
                path: "reports/stocks-report",
                name: "stocks-report.list",
                component: () =>
                    import ("@/view/pages/modules/reports/stocksReport/Index.vue"),
                meta: {role: ['report_stores.report_stocks']}
            },
            // ******************************* End stocks-report **************************************************
            // ****************************** Begin stocks-report *************************************************
            {
                path: "reports/inventory-transaction-report",
                name: "inventory-transaction-report.list",
                component: () =>
                    import ("@/view/pages/modules/reports/inventoryTransactionReport/Index.vue"),
                meta: {role: ['report_stores.report_inventory_transaction']}
            },
            {
                path: "reports/inventory-transaction-details",
                name: "inventory-transaction-details.list",
                component: () =>
                    import ("@/view/pages/modules/reports/inventoryTransactionReport/Details.vue"),
                meta: {role: ['report_stores.report_inventory_transaction_details']}
            },
            {
                path: "reports/sales-profit-report",
                name: "sales-profit-report.list",
                component: () =>
                    import ("@/view/pages/modules/reports/salesProfitReport/Index.vue"),
                meta: {role: ['report_sales.sales_profit_report']}
            },
            {
                path: "reports/profit-of-sales-item-report",
                name: "profit-of-sales-item-report.list",
                component: () =>
                    import ("@/view/pages/modules/reports/profitOfSalesItemReport/Index.vue"),
                meta: {role: ['report_sales.profit_of_sales_item_report']}
            },
            {
                path: "reports/estimated-inventory",
                name: "estimated-inventory.list",
                component: () =>
                    import ("@/view/pages/modules/reports/estimatedInventory/Index.vue"),
                meta: {role: ['report_stores.report_estimated_inventory']}
            },
            {
                path: "reports/summary-stock-balance",
                name: "summary-stock-balance.list",
                component: () =>
                    import ("@/view/pages/modules/reports/summaryStockBalance/Index.vue"),
                meta: {role: ['report_stores.report_summary_stock_balance']}
            },
            {
                path: "reports/product-average-cost",
                name: "product-average-cost.list",
                component: () =>
                    import ("@/view/pages/modules/reports/productAverageCost/Index.vue"),
                meta: {role: ['report_stores.report_product_average_cost']}
            },
            {
                path: "reports/daily-product-sales",
                name: "daily-product-sales.list",
                component: () =>
                    import ("@/view/pages/modules/reports/dailyProductSales/Index.vue"),
                meta: {role: ['report_stores.report_daily_product_sales']}
            },
            {
                path: "reports/sales-for-sales-report",
                name: "sales-for-sales-report.list",
                component: () =>
                    import ("@/view/pages/modules/reports/salesForSalesReport/Index.vue"),
                meta: {role: ['report_sales.sales_for_sales_report']}
            },

            {
                path: "store/reports",
                name: "store-reports",
                component: () =>
                    import ("@/view/pages/modules/reports/storeReports/Index.vue"),
                meta: {role: ['report_stores.list']}
            },
            {
                path: "reports/pos-reports-links",
                name: "pos-reports",
                component: () =>
                    import ("@/view/pages/modules/reports/pos/posReports/Index.vue"),
                meta: {role: ['report_pos.list']}
            },

            {
                path: "/clients/reports",
                name: "clients-reports",
                component: () =>
                    import ("@/view/pages/modules/reports/ClientsReports/Index.vue"),
                meta: {role: ['report_clients.list']}
            },

            {
                path: "reports/clients",
                name: "clients-reports.list",
                component: () =>
                    import ("@/view/pages/modules/reports/Clients/Index.vue"),
                meta: {role: ['report_clients.clients_list']}
            },
            {
                path: "reports/clients-appointments-report",
                name: "clients-appointments-report.list",
                component: () =>
                    import ("@/view/pages/modules/reports/ClientsAppointmentsReport/Index.vue"),
                meta: {role: ['report_clients.report_clients_appointments']}
            },
            {
                path: "reports/clients-installment-report",
                name: "clients-installment-report.list",
                component: () =>
                    import ("@/view/pages/modules/reports/ClientsInstallmentsReport/Index.vue"),
                meta: {role: ['report_clients.report_clients_installment']}
            },
            {
                path: "reports/clients-sales-report",
                name: "clients-sales-report.list",
                component: () =>
                    import ("@/view/pages/modules/reports/ClientsSalesReport/Index.vue"),
                meta: {role: ['report_clients.report_clients_sales']}
            },

            {
                path: "reports/clients-payments-report",
                name: "clients-payments-report.list",
                component: () =>
                    import ("@/view/pages/modules/reports/ClientsPaymentsReport/Index.vue"),
                meta: {role: ['report_clients.report_clients_payments']}
            },
            {
                path: "reports/clients-balance-report",
                name: "clients-balance-report.list",
                component: () =>
                    import ("@/view/pages/modules/reports/ClientsBalanceReport/Index.vue"),
                meta: {role: ['report_clients.report_clients_balance']}
            },
            {
                path: "reports/simple-clients-balance-report",
                name: "simple-clients-balance-report.list",
                component: () =>
                    import ("@/view/pages/modules/reports/SimpleClientsBalanceReport/Index.vue"),
                meta: {role: ['report_clients.report_simple_customers_statment']}
            },
            {
                path: "reports/simple-suppliers-balance-report",
                name: "simple-suppliers-balance-report.list",
                component: () =>
                    import ("@/view/pages/modules/reports/SimpleSuppliersBalanceReport/Index.vue"),
                meta: {role: ['report_purchase.simple_supplier_balance']}
            },
            {
                path: "reports/clients-debtors-report",
                name: "clients-debtors-report.list",
                component: () =>
                    import ("@/view/pages/modules/reports/agedDebtors/Index.vue"),
                meta: {role: ['report_clients.report_clients_debtors']}
            },
            {
                path: "reports/supplier-aged-ledger",
                name: "supplier-aged-ledger.list",
                component: () =>
                    import ("@/view/pages/modules/reports/supplierAgedLedger/Index.vue"),
                meta: {role: ['report_purchase.supplier_aged_ledger']}
            },
            {
                path: "reports/sales-reports-links",
                name: "sales-reports-links.list",
                component: () =>
                    import ("@/view/pages/modules/reports/salesReports/Index.vue"),
                meta: {role: ['report_sales.list']}
            },
            {
                path: "reports/sales-reports",
                name: "sales-reports.list",
                component: () =>
                    import ("@/view/pages/modules/reports/salesByClient/Index.vue"),
                meta: {role: ['report_sales.sales_segmented_periodic']}
            },
            {
                path: "reports/sales-revenue-reports",
                name: "sales-revenue-reports.list",
                component: () =>
                    import ("@/view/pages/modules/reports/salesRevenue/Index.vue"),
                meta: {role: ['report_sales.revenue']}
            },
            {
                path: "reports/sales-by-product-report",
                name: "sales-by-product-report.list",
                component: () =>
                    import ("@/view/pages/modules/reports/salesByProduct/Index.vue"),
                meta: {role: ['report_sales.sales_segmented_periodic']}
            },
            {
                path: "reports/sales-via-interval",
                name: "sales-via-interval-report.list",
                component: () =>
                    import ("@/view/pages/modules/reports/salesViaInterval/Index.vue"),
                meta: {role: ['report_sales.sales_via_interval']}
            },
            {
                path: "reports/payments-sales-reports",
                name: "payments-sales-reports.list",
                component: () =>
                    import ("@/view/pages/modules/reports/paymentsSalesReport/Index.vue"),
                meta: {role: ['report_sales.report_payments_sales']}
            },
            {
                path: "reports/sales-payments-report",
                name: "sales-payments-report.list",
                component: () =>
                    import ("@/view/pages/modules/reports/SalesPaymentsReport/Index.vue"),
                meta: {role: ['report_sales.sales_payment']}
            },
            {
                path: "reports/bonds-payments-details",
                name: "bonds-payments-details.list",
                component: () =>
                    import ("@/view/pages/modules/reports/bondsPaymentsDetails/Index.vue"),
                meta: {role: ['report_sales.bonds_payments_details']}
            },
            {
                path: "reports/suppliers-bonds-payments-details",
                name: "suppliers-bonds-payments-details.list",
                component: () =>
                    import ("@/view/pages/modules/reports/suppliersBondsPaymentsDetails/Index.vue"),
                meta: {role: ['report_purchase.report_payments_purchases']}
            },
            {
                path: "reports/purchase-reports-links",
                name: "purchase-reports-links.list",
                component: () =>
                    import ("@/view/pages/modules/reports/purchaseReports/Index.vue"),
                meta: {role: ['report_purchase.list']}
            },
            {
                path: "reports/suppliers-reports",
                name: "suppliers-reports.list",
                component: () =>
                    import ("@/view/pages/modules/reports/suppliersReports/Index.vue"),
                meta: {role: ['report_purchase.suppliers_reports']}
            },
            {
                path: "reports/suppliers-balance-reports",
                name: "suppliers-balance-reports.list",
                component: () =>
                    import ("@/view/pages/modules/reports/suppliersBalanceReport/Index.vue"),
                meta: {role: ['report_purchase.report_suppliers_balance']}
            },
            {
                path: "reports/suppliers-purchase-reports",
                name: "suppliers-purchase-reports.list",
                component: () =>
                    import ("@/view/pages/modules/reports/suppliersPurchaseReports/Index.vue"),
                meta: {role: ['report_purchase.report_suppliers_purchase']}
            },
            {
                path: "reports/paid-purchases-reports",
                name: "paid-purchases-reports.list",
                component: () =>
                    import ("@/view/pages/modules/reports/paidPurchasesReports/Index.vue"),
                meta: {role: ['report_purchase.paid_purchases']}
            },
            {
                path: "reports/purchases-segmented-reports",
                name: "purchases-segmented-reports.list",
                component: () =>
                    import ("@/view/pages/modules/reports/purchasesSegmentedReports/Index.vue"),
                meta: {role: ['report_purchase.report_purchases_segmented']}
            },
            {
                path: "reports/product-purchases-reports",
                name: "product-purchases-reports.list",
                component: () =>
                    import ("@/view/pages/modules/reports/productsPurchaseReports/Index.vue"),
                meta: {role: ['report_purchase.report_product_purchases']}
            },
            {
                path: "reports/payments-purchases-reports",
                name: "payments-purchases-reports.list",
                component: () =>
                    import ("@/view/pages/modules/reports/purchasesPaymentsPeriodicReports/Index.vue"),
                meta: {role: ['report_purchase.report_payments_purchases']}
            },

            {
                path: "reports/accounting-reports",
                name: "accounting-reports.list",
                component: () =>
                    import ("@/view/pages/modules/reports/accounting/links/Index.vue"),
                meta: {role: ['report_accounting.list']}
            },
            {
                path: "reports/journal-transactions",
                name: "journal-transactions.list",
                component: () =>
                    import ("@/view/pages/modules/reports/accounting/journalTransactions/Index.vue"),
                meta: {role: ['report_accounting.report_journal_transactions']}
            },
            {
                path: "reports/profit-loss-report",
                name: "profit-loss-report.list",
                component: () =>
                    import ("@/view/pages/modules/reports/accounting/ProfitLossReport/Index.vue"),
                meta: {role: ['report_accounting.report_profit_loss']}
            },
            {
                path: "reports/income-report",
                name: "income-report.list",
                component: () =>
                    import ("@/view/pages/modules/reports/accounting/IncomeReport/Index.vue"),
                meta: {role: ['report_accounting.report_income']}
            },
            {
                path: "reports/expenses-report",
                name: "expenses-report.list",
                component: () =>
                    import ("@/view/pages/modules/reports/accounting/ExpensesReport/Index.vue"),
                meta: {role: ['report_accounting.report_expense']}
            },
            {
                path: "reports/balance-sheet",
                name: "balance-sheet.list",
                component: () =>
                    import ("@/view/pages/modules/reports/accounting/BalanceSheet/Index.vue"),
                meta: {role: ['report_accounting.report_balance_sheet']}
            },
            {
                path: "reports/chart-accounts-directory",
                name: "chart-accounts-directory.list",
                component: () =>
                    import ("@/view/pages/modules/reports/accounting/ChartAccounts/Index.vue"),
                meta: {role: ['report_accounting.report_chart_accounts']}
            },
            {
                path: "reports/balance-balances-review",
                name: "balance-balances-review.list",
                component: () =>
                    import ("@/view/pages/modules/reports/accounting/BalanceOfBalancesReview/Index.vue"),
                meta: {role: ['report_accounting.report_account_movements']}
            },
            {
                path: "reports/taxes-report",
                name: "tax-report.list",
                component: () =>
                    import ("@/view/pages/modules/reports/accounting/TaxReport/Index.vue"),
                meta: {role: ['report_accounting.report_tax']}
            },
            {
                path: "reports/income-expenses-report",
                name: "income-expenses-report.list",
                component: () =>
                    import ("@/view/pages/modules/reports/accounting/IncomeExpensesReport/Index.vue"),
                meta: {role: ['report_accounting.report_income_expenses']}
            },
            {
                path: "reports/tax-declaration-report",
                name: "tax-declaration-report.list",
                component: () =>
                    import ("@/view/pages/modules/reports/accounting/TaxDeclaration/Index.vue"),
                meta: {role: ['report_accounting.report_tax_declaration']}
            },
            {
                path: "reports/financial-transactions-report",
                name: "financial-transactions-report.list",
                component: () =>
                    import ("@/view/pages/modules/reports/FinancialTransactions/Index.vue"),
                meta: {role: ['report_financial_transactions.list']}
            },
            {
                path: "reports/items-report",
                name: "items-report.list",
                component: () =>
                    import ("@/view/pages/modules/reports/items/ItemsReport/Index.vue"),
                meta: {role: ['report_items.report_menu_items']}
            },
            {
                path: "reports/system-activity-log",
                name: "system-activity-log.list",
                component: () =>
                    import ("@/view/pages/modules/reports/ActivityLogReport/Index.vue"),
                meta: {role: ['report_system_activity_log.list']}
            },
            {
                path: "reports/supplier-transactions-report",
                name: "supplier-transactions-report.list",
                component: () =>
                    import ("@/view/pages/modules/reports/SupplierTransactionsReport/Index.vue"),
                meta: {role: ['report_purchase.report_supplier_transactions']}
            },
            {
                path: "reports/customer-transactions-report",
                name: "customer-transactions-report.list",
                component: () =>
                    import ("@/view/pages/modules/reports/customerTransactionsReport/Index.vue"),
                meta: {role: ['report_sales.report_customer_transactions']}
            },
            {
                path: "reports/items-reports-links",
                name: "items-reports-links.list",
                component: () =>
                    import ("@/view/pages/modules/reports/items/links/Index.vue"),
                meta: {role: ['report_items.list']}
            },
            {
                path: "reports/items-transactions-report",
                name: "items-transactions-report.list",
                component: () =>
                    import ("@/view/pages/modules/reports/items/ItemsTransactionsReport/Index.vue"),
                meta: {role: ['report_items.report_items_transactions']}
            },
            {
                path: "reports/detailed-stock-transactions-per-product",
                name: "detailed-stock-transactions-per-product.list",
                component: () =>
                    import ("@/view/pages/modules/reports/detailedStockTransactionsPerProduct/Index.vue"),
                meta: {role: ['report_stores.detailed_stock_transactions_per_product']}
            },
            // ******************************* End stocks-report **************************************************
            // ******************************* Start pos-report **************************************************
            {
                path: "reports/total-pos-product-sales-details",
                name: "total-pos-product-sales-details.list",
                component: () =>
                    import ("@/view/pages/modules/reports/pos/totalPosProductSalesDetails/Index.vue"),
                meta: {role: ['report_pos.total_product_sales_details']}
            },
            {
                path: "reports/pos-shifts-sales-report",
                name: "pos-shifts-sales-report.list",
                component: () =>
                    import ("@/view/pages/modules/reports/pos/posShiftsSalesReport/Index.vue"),
                meta: {role: ['report_pos.shifts_sales']}
            },
            {
                path: "reports/total-pos-categories-profits-report",
                name: "total-pos-categories-profits-report.list",
                component: () =>
                    import ("@/view/pages/modules/reports/pos/totalPOSCategoriesProfitsReport/Index.vue"),
                meta: {role: ['report_pos.total_categories_profits']}
            },
            {
                path: "reports/detailed-pos-shifts-transactions-report",
                name: "detailed-pos-shifts-transactions-report.list",
                component: () =>
                    import ("@/view/pages/modules/reports/pos/detailedPosShiftsTransactions/Index.vue"),
                meta: {role: ['report_pos.detailed_pos_shifts_transactions']}
            },
            {
                path: "reports/total-pos-products-profits-report",
                name: "total-pos-products-profits-report.list",
                component: () =>
                    import ("@/view/pages/modules/reports/pos/totalPOSProductsProfits/Index.vue"),
                meta: {role: ['report_pos.total_pos_products_profits']}
            },
            {
                path: "reports/pos-shifts-profit-report",
                name: "pos-shifts-profit-report.list",
                component: () =>
                    import ("@/view/pages/modules/reports/pos/posShiftsProfit/Index.vue"),
                meta: {role: ['report_pos.pos_shifts_profit']}
            },
            // ******************************* End pos-report **************************************************
            // ******************************* Begin document-type-setting **************************************************
            {
                path: "accounting/document-type-setting",
                name: "document-type-setting.list",
                component: () =>
                    import ("@/view/pages/modules/settings/documentTypeSetting/Index.vue"),
                meta: {role: ['document_type_setting.list']}
            },
            {
                path: "accounting/document-type-setting/create",
                name: "document-type-setting.create",
                component: () =>
                    import ("@/view/pages/modules/settings/documentTypeSetting/Form.vue"),
                meta: {role: ['document_type_setting.create']}
            },
            {
                path: "accounting/document-type-setting/edit/:id",
                name: "document-type-setting.edit",
                component: () =>
                    import ("@/view/pages/modules/settings/documentTypeSetting/Form.vue"),
                meta: {role: ['document_type_setting.update']}
            },
            // ******************************* End document-type-setting **************************************************
            // ******************************* Begin journals_setting **************************************************
            {
                path: "accounting/journals-setting",
                name: "journals-setting.list",
                component: () =>
                    import ("@/view/pages/modules/settings/journalsSetting/Index.vue"),
                meta: {role: ['journal_settings.list']}
            },
            {
                path: "accounting/journals-setting/create",
                name: "journals-setting.create",
                component: () =>
                    import ("@/view/pages/modules/settings/journalsSetting/Form.vue"),
                meta: {role: ['journal_settings.create']}
            },
            {
                path: "accounting/journals-setting/edit/:id",
                name: "journals-setting.edit",
                component: () =>
                    import ("@/view/pages/modules/settings/journalsSetting/Form.vue"),
                meta: {role: ['journal_settings.update']}
            },
            // ******************************* End journals_setting **************************************************
            // ****************************** Begin currency-exchange *******************************************
            {
                path: "/settings/currency-exchange",
                name: "currency-exchange.index",
                component: () =>
                    import ("@/view/pages/modules/settings/currencyExchange/Index.vue"),
                meta: {role: ['currencies_convert.list']}
            },
            // ****************************** End currency-exchange ***********************************************
            // ****************************** Begin mobile app settings *******************************************
            {
                path: "/settings/mobile-app-settings",
                name: "mobile-app-settings.index",
                component: () =>
                    import ("@/view/pages/modules/settings/mobileApp/Index.vue"),
                meta: {role: ['mobile_app_setting.list']}
            },
            {
              path: "/settings/mobile-app-settings/create",
                name: "mobile-app-settings.create",
                component: () =>
                    import ("@/view/pages/modules/settings/mobileApp/Form.vue"),
                meta: {role: ['mobile_app_setting.create']}
            },
            // ****************************** End mobile app settings ***********************************************
            // ****************************** Begin notifications-page *******************************************
            {
                path: "/notifications",
                name: "notifications.index",
                component: () =>
                    import ("@/view/pages/modules/users/Notifications.vue"),
                // meta: { role: ['notifications.list'] }
            },
            // ****************************** End notifications-page ***********************************************

            // ****************************** Begin notifications-config *************************************************
            {
                path: "settings/notifications-config",
                name: "notifications-config.index",
                component: () =>
                    import ("@/view/pages/modules/settings/notificationsConfig/Index.vue"),
                meta: {role: ['notification_config.list']}
            },
            {
                path: "settings/notifications-config/create",
                name: "notifications-config.create",
                component: () =>
                    import ("@/view/pages/modules/settings/notificationsConfig/Form.vue"),
                meta: {role: ['notification_config.create']}
            },
            {
                path: "settings/notifications-config/edit/:id",
                name: "notifications-config.edit",
                component: () =>
                    import ("@/view/pages/modules/settings/notificationsConfig/Form.vue"),
                meta: {role: ['notification_config.update']}
            },
            {
                path: "settings/custom-notifications",
                name: "notifications-config.list",
                component: () =>
                    import ("@/view/pages/modules/settings/notificationsConfig/Custom.vue"),
                meta: {role: ['notification_config.create']}
            },
            // ******************************* End notifications-config **************************************************
            // ****************************** Begin supplier-checking *************************************************
            {
                path: "purchases/supplier-checking",
                name: "supplier-checking.index",
                component: () =>
                    import ("@/view/pages/modules/purchases/supplierChecking/Index.vue"),
                meta: {role: ['supplier_checking.list']}
            },
            {
                path: "purchases/supplier-checking/create",
                name: "supplier-checking.create",
                component: () =>
                    import ("@/view/pages/modules/purchases/supplierChecking/Form.vue"),
                meta: {role: ['supplier_checking.create']}
            },
            {
                path: "purchases/supplier-checking/edit/:id?",
                name: "supplier-checking.edit",
                component: () =>
                    import ("@/view/pages/modules/purchases/supplierChecking/Form.vue"),
                meta: {role: ['supplier_checking.update']}
            },
            {
                path: "purchases/supplier-checking-history",
                name: "supplier-checking-history.edit",
                component: () =>
                    import ("@/view/pages/modules/purchases/supplierCheckingHistory/Index.vue"),
                meta: {role: ['supplier_checking.supplier_checking_history']}
            },
            // ****************************** Begin supplier-checking *************************************************
            // ****************************** Begin export-import-items *************************************************
            {
                path: "items/export-import-items",
                name: "export-import-items.index",
                component: () =>
                    import ("@/view/pages/modules/items/exportImport/Index.vue"),
                meta: {role: ['export_import_items.list']}
            },
            // ******************************* End export-import-items **************************************************
            // ****************************** Begin available-features *************************************************
            {
                path: "settings/available-features",
                name: "available-features.index",
                component: () =>
                    import ("@/view/pages/modules/settings/availableFeatures/Index.vue"),
                meta: {role: ['available_features.list']}
            },
            // ******************************* End available-features **************************************************
            // ****************************** Begin available-sub-features *************************************************
            {
                path: "settings/available-sub-features/:id",
                name: "available-sub-features.index",
                component: () =>
                    import ("@/view/pages/modules/settings/availableFeatures/subFeature/Index.vue"),
                meta: {role: ['available_sub_features.list']}
            },
            // ******************************* End available-sub-features **************************************************
            // ****************************** Begin secondary-accounts-tree *******************************************
            {
                path: "/accounting/secondary-accounts-tree",
                name: "secondary-accounts-tree.index",
                component: () =>
                    import ("@/view/pages/modules/accounting/secondaryAccountsTree/Form.vue"),
                meta: {role: ['secondary_accounts.list']}
            },
            // ****************************** End secondary-accounts-tree ***********************************************
            // ****************************** Begin secondary-accounts-tree *******************************************
            {
                path: "/accounting/groups-accounts",
                name: "groups-accounts.index",
                component: () =>
                    import ("@/view/pages/modules/accounting/groupsAccounts/Index.vue"),
                meta: {role: ['groups_accounts.list']}
            },
            {
                path: "/accounting/groups-accounts/create",
                name: "groups-accounts.create",
                component: () =>
                    import ("@/view/pages/modules/accounting/groupsAccounts/Form.vue"),
                meta: {role: ['groups_accounts.create']}
            },
            {
                path: "/accounting/groups-accounts/edit/:id",
                name: "groups-accounts.edit",
                component: () =>
                    import ("@/view/pages/modules/accounting/groupsAccounts/Form.vue"),
                meta: {role: ['groups_accounts.update']}
            },
            // ****************************** End secondary-accounts-tree ***********************************************
            // ****************************** Begin chart-accounts-list *******************************************

            {
                path: "/accounting/chart-accounts-list/:parent_id?",
                name: "chart-accounts-list.index",
                component: () =>
                    import ("@/view/pages/modules/accounting/chartAccountsList/Index.vue"),
                meta: {role: ['chart_accounts.list']}
            },
            // ****************************** End chart-accounts-list ***********************************************
            // ****************************** Begin journals-entries-account *******************************************

            {
                path: "/accounting/journals-entries-account/:account_id",
                name: "journals-entries-account.index",
                component: () =>
                    import ("@/view/pages/modules/accounting/chartAccounts/journalEntriesAccount.vue"),
                meta: {role: ['chart_accounts.journals_entries_account']}
            },
            // ****************************** End journals-entries-account ***********************************************
            // ****************************** Begin branch-config *******************************************
            {
                path: "/settings/branch-config/:id",
                name: "branch-config.index",
                component: () =>
                    import ("@/view/pages/modules/settings/branchesConfig/Form.vue"),
                meta: {role: ['branches.branch_config']}
            },
            // ****************************** End branch-config ***********************************************

            // ****************************** Begin manufactories ***********************************************
            // ****************************** Begin workstation ***********************************************
            {
                path: "/manufactories/workstation",
                name: "workstation.index",
                component: () =>
                    import ("@/view/pages/modules/manufactories/workstation/Index.vue"),
                meta: {role: ['workstation.list']}
            },
            {
                path: "/manufactories/workstation/create",
                name: "workstation.create",
                component: () =>
                    import ("@/view/pages/modules/manufactories/workstation/Form.vue"),
                meta: {role: ['workstation.create']}
            },
            {
                path: "/manufactories/workstation/edit/:id",
                name: "workstation.edit",
                component: () =>
                    import ("@/view/pages/modules/manufactories/workstation/Form.vue"),
                meta: {role: ['workstation.update']}
            },
            // ****************************** End workstation ***********************************************
            // ****************************** Begin quality-inspections ***********************************************
            {
                path: "/manufactories/quality-inspections",
                name: "quality-inspections.index",
                component: () =>
                    import ("@/view/pages/modules/manufactories/qualityInspection/Index.vue"),
                meta: {role: ['quality_inspection.list']}
            },
            {
                path: "/manufactories/quality-inspections/create",
                name: "quality-inspections.create",
                component: () =>
                    import ("@/view/pages/modules/manufactories/qualityInspection/Form.vue"),
                meta: {role: ['quality_inspection.create']}
            },
            {
                path: "/manufactories/quality-inspections/edit/:id",
                name: "quality-inspections.edit",
                component: () =>
                    import ("@/view/pages/modules/manufactories/qualityInspection/Form.vue"),
                meta: {role: ['quality_inspection.update']}
            },
            // ****************************** End quality-inspections ***********************************************
            // ****************************** Begin operation-management ***********************************************
            {
                path: "/manufactories/operation-management",
                name: "operation-management.index",
                component: () =>
                    import ("@/view/pages/modules/manufactories/operationManagement/Index.vue"),
                meta: {role: ['operation.list']}
            },
            {
                path: "/manufactories/operation-management/create",
                name: "operation-management.create",
                component: () =>
                    import ("@/view/pages/modules/manufactories/operationManagement/Form.vue"),
                meta: {role: ['operation.create']}
            },
            {
                path: "/manufactories/operation-management/edit/:id",
                name: "operation-management.edit",
                component: () =>
                    import ("@/view/pages/modules/manufactories/operationManagement/Form.vue"),
                meta: {role: ['operation.update']}
            },
            // ****************************** End operation-management ***********************************************
            // ****************************** Begin routing-management ***********************************************
            {
                path: "/manufactories/routing-management",
                name: "routing-management.index",
                component: () =>
                    import ("@/view/pages/modules/manufactories/routingManagement/Index.vue"),
                meta: {role: ['routing_management.list']}
            },
            {
                path: "/manufactories/routing-management/create",
                name: "routing-management.create",
                component: () =>
                    import ("@/view/pages/modules/manufactories/routingManagement/Form.vue"),
                meta: {role: ['routing_management.create']}
            },
            {
                path: "/manufactories/routing-management/edit/:id",
                name: "routing-management.edit",
                component: () =>
                    import ("@/view/pages/modules/manufactories/routingManagement/Form.vue"),
                meta: {role: ['routing_management.update']}
            },
            // ****************************** End routing-management ***********************************************
            // ****************************** Begin bill-of-material ***********************************************
            {
                path: "/manufactories/bill-of-material",
                name: "bill-of-material.index",
                component: () =>
                    import ("@/view/pages/modules/manufactories/billOfMaterial/Index.vue"),
                meta: {role: ['bill_material.list']}
            },
            {
                path: "/manufactories/bill-of-material/create",
                name: "bill-of-material.create",
                component: () =>
                    import ("@/view/pages/modules/manufactories/billOfMaterial/Form.vue"),
                meta: {role: ['bill_material.create']}
            },
            {
                path: "/manufactories/bill-of-material/edit/:id",
                name: "bill-of-material.edit",
                component: () =>
                    import ("@/view/pages/modules/manufactories/billOfMaterial/Form.vue"),
                meta: {role: ['bill_material.update']}
            },
            {
                path: "/manufactories/bill-of-material/edit/:id/:is_clone?",
                name: "bill-of-material.clone",
                component: () =>
                    import ("@/view/pages/modules/manufactories/billOfMaterial/Form.vue"),
                meta: {role: ['bill_material.clone']}
            },
            // ****************************** End bill-of-material ***********************************************
            // ****************************** Begin material-list ***********************************************
            {
                path: "/manufactories/material-list",
                name: "material-list.index",
                component: () =>
                    import ("@/view/pages/modules/manufactories/materialList/Index.vue"),
                meta: {role: ['material.list']}
            },
            {
                path: "/manufactories/material-list/create",
                name: "material-list.create",
                component: () =>
                    import ("@/view/pages/modules/manufactories/materialList/Form.vue"),
                meta: {role: ['material.create']}
            },
            {
                path: "/manufactories/material-list/edit/:id",
                name: "material-list.edit",
                component: () =>
                    import ("@/view/pages/modules/manufactories/materialList/Form.vue"),
                meta: {role: ['material.update']}
            },
            // ****************************** End material-list ***********************************************
            // ****************************** Begin work-order ***********************************************
            {
                path: "/manufactories/work-order",
                name: "work-order.index",
                component: () =>
                    import ("@/view/pages/modules/manufactories/workOrder/Index.vue"),
                meta: {role: ['work_order.list']}
            },
            {
                path: "/manufactories/work-order/create/:bill_id?",
                name: "work-order.create",
                component: () =>
                    import ("@/view/pages/modules/manufactories/workOrder/Form.vue"),
                meta: {role: ['work_order.create', 'bill_material.add_work_order']}
            },
            {
                path: "/manufactories/work-order/edit/:id",
                name: "work-order.edit",
                component: () =>
                    import ("@/view/pages/modules/manufactories/workOrder/Form.vue"),
                meta: {role: ['work_order.update']}
            },
            {
                path: "/manufactories/work-order/operations/:id",
                name: "work-order.operations",
                component: () =>
                    import ("@/view/pages/modules/manufactories/workOrder/Operations.vue"),
                meta: {role: ['work_order.operations']}
            },
            {
                path: "/manufactories/work-order/edit/:id/:is_clone?",
                name: "work-order.clone",
                component: () =>
                    import ("@/view/pages/modules/manufactories/workOrder/Form.vue"),
                meta: {role: ['work_order.clone']}
            },
            // ****************************** End work-order ***********************************************
            // ****************************** Begin work-order ***********************************************
            {
                path: "/manufactories/job-card",
                name: "job-card.index",
                component: () =>
                    import ("@/view/pages/modules/manufactories/jobCard/Index.vue"),
                meta: {role: ['job_card.list']}
            },

            {
                path: "/manufactories/job-card/edit/:id",
                name: "job-card.edit",
                component: () =>
                    import ("@/view/pages/modules/manufactories/jobCard/Form.vue"),
                meta: {role: ['job_card.update']}
            },
            // ****************************** End work-order ***********************************************
            // ****************************** Begin general_group ***********************************************
            {
                path: "/settings/general-group",
                name: "general-group.index",
                component: () =>
                    import ("@/view/pages/modules/settings/generalGroup/Index.vue"),
                meta: {role: ['general_group.list']}
            },

            // ****************************** End general_group ***********************************************

            // ****************************** End manufactories ***********************************************
            // ******************************* Begin module_data_access **************************************************
            {
                path: "settings/module-data-access",
                name: "module-data-access.list",
                component: () =>
                    import ("@/view/pages/modules/settings/moduleDataAccess/Index.vue"),
                meta: {role: ['modules_setting.list']}
            },
            {
                path: "settings/module-data-access/create",
                name: "module-data-access.create",
                component: () =>
                    import ("@/view/pages/modules/settings/moduleDataAccess/Form.vue"),
                meta: {role: ['modules_setting.create']}
            },
            {
                path: "settings/module-data-access/edit/:id",
                name: "module-data-access.edit",
                component: () =>
                    import ("@/view/pages/modules/settings/moduleDataAccess/Form.vue"),
                meta: {role: ['modules_setting.update']}
            },
            // ******************************* End module_data_access **************************************************
            // ******************************* Begin data-access-configuration **************************************************

            {
                path: "users/data-access-configuration",
                name: "data-access-configuration.create",
                component: () =>
                    import ("@/view/pages/modules/users/dataAccess/Form.vue"),
                // meta: { role: ['data_access_configuration.create'] }
            },

            // ******************************* End data-access-configuration **************************************************
            // ******************************* Begin default-data **************************************************

            {
                path: "users/default-data/:id",
                name: "default-data.create",
                component: () =>
                    import ("@/view/pages/modules/users/defaultData/Form.vue"),
                meta: {role: ['users.default_data']}
            },

            // ******************************* End default-data **************************************************

            // ****************************** Begin receipt documents ***********************************************
            {
                path: "purchases/receipt-documents",
                name: "receipt-documents.index",
                component: () =>
                    import ("@/view/pages/modules/purchases/receiptDocument/Index.vue"),
                meta: {role: ['receipt_documents.list']}
            },
            {
                path: "purchases/receipt-documents/create/:purchase_invoice_id",
                name: "receipt-documents.create",
                component: () =>
                    import ("@/view/pages/modules/purchases/receiptDocument/Form.vue"),
                meta: {role: ['receipt_documents.create', 'purchases_invoices.receipt_document']}
            },
            {
                path: "purchases/receipt-documents/edit/:id",
                name: "receipt-documents.edit",
                component: () =>
                    import ("@/view/pages/modules/purchases/receiptDocument/Form.vue"),
                meta: {role: ['receipt_documents.update']}
            },

            // ****************************** End receipt documents **************************************************

            // ****************************** Begin general sales ***********************************************
            {
                path: "sales/general_sales",
                name: "general-sales.index",
                component: () =>
                    import ("@/view/pages/modules/sales/generalSales/Index.vue"),
                meta: {role: ['general_sales.list']}
            },
            {
                path: "sales/general_sales/payment_details/:id",
                name: "general_sales.payment_details",
                component: () =>
                    import ("@/view/pages/modules/sales/invoices/PaymentDetails.vue"),
                meta: {role: ['sales_invoices.payment_details']}
            },
            {
                path: "sales/general_sales/create",
                name: "general-sales.create",
                component: () =>
                    import ("@/view/pages/modules/sales/generalSales/Form.vue"),
                meta: {role: ['general_sales.create']}
            },
            {
                path: "sales/general_sales/edit/:id",
                name: "general-sales.edit",
                component: () =>
                    import ("@/view/pages/modules/sales/generalSales/Form.vue"),
                meta: {role: ['general_sales.update']}
            },
            {
                path: "sales/general_sales/view/:id",
                name: "general-sales.view",
                component: () =>
                    import ("@/view/pages/modules/sales/generalSales/View.vue"),
                meta: {role: ['general_sales.view']}
            },
            {
                path: "sales/general_sales/clone/:clone_id",
                name: "general-sales.clone",
                component: () =>
                    import ("@/view/pages/modules/sales/generalSales/Form.vue"),
                meta: {role: ['general_sales.clone']}
            },
            // ****************************** End general sales **************************************************


            // ****************************** Begin rent general sales ***********************************************
            {
                path: "sales/general_sales_rent",
                name: "general_sales_rent.index",
                component: () =>
                    import ("@/view/pages/modules/sales/generalSalesRent/Index.vue"),
                meta: {role: ['general_sales_rent.list']}
            },
            {
                path: "sales/general_sales_rent/create",
                name: "general_sales_rent.create",
                component: () =>
                    import ("@/view/pages/modules/sales/generalSalesRent/Form.vue"),
                meta: {role: ['general_sales_rent.create']}
            },
            {
                path: "sales/general_sales_rent/edit/:id",
                name: "general_sales_rent.edit",
                component: () =>
                    import ("@/view/pages/modules/sales/generalSalesRent/Form.vue"),
                meta: {role: ['general_sales_rent.update']}
            },
            {
                path: "sales/general_sales_rent/clone/:clone_id",
                name: "general_sales_rent.clone",
                component: () =>
                    import ("@/view/pages/modules/sales/generalSalesRent/Form.vue"),
                meta: {role: ['general_sales_rent.clone']}
            },
            // ****************************** End rent general sales **************************************************
            // ****************************** Begin   rent invoices ***********************************************
            {
                path: "sales/sales_rent",
                name: "sales_rent.index",
                component: () =>
                    import ("@/view/pages/modules/sales/salesRent/Index.vue"),
                meta: {role: ['sales_rent.list']}
            },
            {
                path: "sales/sales_rent/create",
                name: "sales_rent.create",
                component: () =>
                    import ("@/view/pages/modules/sales/salesRent/Form.vue"),
                meta: {role: ['sales_rent.create']}
            },
            {
                path: "sales/sales_rent/edit/:id",
                name: "sales_rent.edit",
                component: () =>
                    import ("@/view/pages/modules/sales/salesRent/Form.vue"),
                meta: {role: ['sales_rent.update']}
            },
            {
                path: "sales/sales_rent/clone/:clone_id",
                name: "sales_rent.clone",
                component: () =>
                    import ("@/view/pages/modules/sales/salesRent/Form.vue"),
                meta: {role: ['sales_rent.clone']}
            },

            {
                path: "sales/cancel-partial-reservation/:id",
                name: "sales_rent.clone",
                component: () =>
                    import ("@/view/pages/modules/sales/salesRent/CancelPartialReservation.vue"),
            },
            // ****************************** End rent  sales **************************************************


            // ****************************** Begin Expenses Income Transaction *******************************************************************
            {
                path: "finances/expenses-income-transaction",
                name: "expenses-income-transaction.index",
                component: () =>
                    import ("@/view/pages/modules/finances/expensesIncomeTransaction/Index.vue"),
                meta: {role: ['expenses_incomes.list']}
            },
            {
                path: "finances/expenses-income-transaction/create",
                name: "expenses-income-transaction.create",
                component: () =>
                    import ("@/view/pages/modules/finances/expensesIncomeTransaction/Form.vue"),
                meta: {role: ['expenses_incomes.create']}
            },
            {
                path: "finances/expenses-income-transaction/edit/:id",
                name: "expenses-income-transaction.edit",
                component: () =>
                    import ("@/view/pages/modules/finances/expensesIncomeTransaction/Form.vue"),
                meta: {role: ['expenses_incomes.update']}
            },
            {
                path: "finances/expenses-income-transaction/view/:id",
                name: "expenses.view",
                component: () =>
                    import ("@/view/pages/modules/finances/expensesIncomeTransaction/View.vue"),
                meta: {role: ['expenses_incomes.list']}
            },
            {
                path: "finances/expenses-income-transaction/doc-payment-details/:id",
                name: "doc-payment-details.view",
                component: () =>
                    import ("@/view/pages/modules/finances/expensesIncomeTransaction/paymentDetails.vue"),
                meta: {role: ['expenses_incomes.payment_details']}
            },
            // ******************************* End Expenses Income Transaction **************************************************
            // ****************************** Begin purchase price offers *******************************************************************
            {
                path: "purchases/purchase-quotations",
                name: "purchase-quotations.index",
                component: () =>
                    import ("@/view/pages/modules/purchases/purchaseQuotations/Index.vue"),
                meta: {role: ['purchase_quotations.list']}
            },
            {
                path: "purchases/purchase-quotations/create/:quotation_requests_id?",
                name: "purchase-quotations.create",
                component: () =>
                    import ("@/view/pages/modules/purchases/purchaseQuotations/Form.vue"),
                meta: {role: ['purchase_quotations.create', 'purchase_quotations.convert_to_purchase_quotations']}
            },
            {
                path: "purchases/purchase-quotations/edit/:id",
                name: "purchase-quotations.edit",
                component: () =>
                    import ("@/view/pages/modules/purchases/purchaseQuotations/Form.vue"),
                meta: {role: ['purchase_quotations.update']}
            },
            {
                path: "purchases/purchase-quotations/view/:id",
                name: "purchase-quotations.view",
                component: () =>
                    import ("@/view/pages/modules/purchases/purchaseQuotations/View.vue"),
                meta: {role: ['purchase_quotations.list']}
            },
            // ******************************* End purchase price offers **************************************************
            // ****************************** Begin bank checking *************************************************
            {
                path: "finances/bank-checking",
                name: "bank-checking.index",
                component: () =>
                    import ("@/view/pages/modules/finances/bankChecking/Index.vue"),
                meta: {role: ['bank_checking.list']}
            },
            {
                path: "finances/bank-checking/create",
                name: "bank-checking.create",
                component: () =>
                    import ("@/view/pages/modules/finances/bankChecking/Form.vue"),
                meta: {role: ['bank_checking.create']}
            },
            {
                path: "finances/bank-checking/edit/:id?",
                name: "bank-checking.edit",
                component: () =>
                    import ("@/view/pages/modules/finances/bankChecking/Form.vue"),
                meta: {role: ['bank_checking.update']}
            },

            {
                path: "finances/bank-checking-history",
                name: "bank-checking-history.index",
                component: () =>
                    import ("@/view/pages/modules/finances/bankCheckingHistory/Index.vue"),
                meta: {role: ['bank_checking.bank_checking_history']}
            },
            // ******************************* End bank checking **************************************************
            // ****************************** Begin customer checking *************************************************
            {
                path: "customers/customer-checking",
                name: "customer-checking.index",
                component: () =>
                    import ("@/view/pages/modules/customers/customerChecking/Index.vue"),
                meta: {role: ['customer_checking.list']}
            },
            {
                path: "customers/customer-checking/create",
                name: "customer-checking.create",
                component: () =>
                    import ("@/view/pages/modules/customers/customerChecking/Form.vue"),
                meta: {role: ['customer_checking.create']}
            },
            {
                path: "customers/customer-checking/edit/:id?",
                name: "customer-checking.edit",
                component: () =>
                    import ("@/view/pages/modules/customers/customerChecking/Form.vue"),
                meta: {role: ['customer_checking.update']}
            },

            {
                path: "customers/customer-checking-history",
                name: "customer-checking-history.index",
                component: () =>
                    import ("@/view/pages/modules/customers/customerCheckingHistory/Index.vue"),
                meta: {role: ['customer_checking.customer_checking_history']}
            },
            // ******************************* End customer checking **************************************************
            // ****************************** Begin general Sales Refund ***********************************************************
            {
                path: "sales/general_sales_refund",
                name: "general_sales_refund.index",
                component: () =>
                    import ("@/view/pages/modules/sales/generalRefund/Index.vue"),
                meta: {role: ['general_sales_refund.list']}
            },
            {
                path: "sales/general_sales_refund/create/:general_sales_invoice_id",
                name: "general_sales_refund.create",
                component: () =>
                    import ("@/view/pages/modules/sales/generalRefund/Form.vue"),
                meta: {role: ['general_sales_refund.create', 'general_sales.sales_refund']}
            },
            {
                path: "sales/general_sales_refund/edit/:id",
                name: "general_sales_refund.edit",
                component: () =>
                    import ("@/view/pages/modules/sales/generalRefund/Form.vue"),
                meta: {role: ['general_sales_refund.update']}
            },
            // ******************************* End general Sales Refund ************************************************************
            // ****************************** Begin import-items *************************************************
            {
                path: "items/import-items",
                name: "import-items.index",
                component: () =>
                    import ("@/view/pages/modules/items/importItems/Index.vue"),
                meta: {role: ['items.export_import_items']}
            },
            // ******************************* End import-items **************************************************
            // ****************************** Begin sales quotations *******************************************************************
            {
                path: "sales/sales-quotations",
                name: "sales-quotations.index",
                component: () =>
                    import ("@/view/pages/modules/sales/salesQuotations/Index.vue"),
                meta: {role: ['sales_quotations.list']}
            },
            {
                path: "sales/sales-quotations/create/:quotation_requests_id?",
                name: "sales-quotations.create",
                component: () =>
                    import ("@/view/pages/modules/sales/salesQuotations/Form.vue"),
                meta: {role: ['sales_quotations.create', 'sales_quotation_request.convert_to_sales_quotations']}
            },
            {
                path: "sales/sales-quotations/edit/:id",
                name: "sales-quotations.edit",
                component: () =>
                    import ("@/view/pages/modules/sales/salesQuotations/Form.vue"),
                meta: {role: ['sales_quotations.update']}
            },
            {
                path: "sales/sales-quotations/view/:id",
                name: "sales-quotations.view",
                component: () =>
                    import ("@/view/pages/modules/sales/salesQuotations/View.vue"),
                meta: {role: ['sales_quotations.list']}
            },
            // ******************************* End sales quotations **************************************************

            // ****************************** Begin General sales quotations *******************************************************************
            {
                path: "sales/general-sales-quotations",
                name: "general-sales-quotations.index",
                component: () =>
                    import ("@/view/pages/modules/sales/generalSalesQuotations/Index.vue"),
                meta: {role: ['general_sales_quotations.list']}
            },
            {
                // path: "sales/general-sales-quotations/create/:quotation_requests_id?",
                path: "sales/general_sales/create/:quotation_requests_id?/:quotation_type?",
                name: "general-sales.create",
                component: () =>
                    import ("@/view/pages/modules/sales/generalSales/Form.vue"),
                meta: {role: ['general_sales_quotations.create', 'general_sales_quotations.convert_to_sales_quotations']}
            },
            {
                path: "sales/general-sales-quotations/create/:quotation_requests_id?",
                name: "general-sales-quotations.create",
                component: () =>
                    import ("@/view/pages/modules/sales/generalSalesQuotations/Form.vue"),
                meta: {role: ['general_sales_quotations.create', 'general_sales_quotations.convert_to_sales_quotations']}
            },

            {
                path: "sales/general-sales-quotations/edit/:id",
                name: "general-sales-quotations.edit",
                component: () =>
                    import ("@/view/pages/modules/sales/generalSalesQuotations/Form.vue"),
                meta: {role: ['general_sales_quotations.update']}
            },
            {
                path: "sales/general-sales-quotations/view/:id",
                name: "general-sales-quotations.view",
                component: () =>
                    import ("@/view/pages/modules/sales/generalSalesQuotations/View.vue"),
                meta: {role: ['general_sales_quotations.list']}
            },
            // ******************************* End General sales quotations **************************************************

            // ****************************** Begin sales Quotation Request *************************************************
            {
                path: "sales/sales-quotation-requests",
                name: "sales-quotation-requests.index",
                component: () =>
                    import ("@/view/pages/modules/sales/salesQuotationRequest/Index.vue"),
                meta: {role: ['sales_quotation_request.list']}
            },
            {
                path: "sales/sales-quotation-requests/create",
                name: "sales-quotation-requests.create",
                component: () =>
                    import ("@/view/pages/modules/sales/salesQuotationRequest/Form.vue"),
                meta: {role: ['sales_quotation_request.create']}
            },
            {
                path: "sales/sales-quotation-requests/edit/:id",
                name: "sales-quotation-requests.edit",
                component: () =>
                    import ("@/view/pages/modules/sales/salesQuotationRequest/Form.vue"),
                meta: {role: ['sales_quotation_request.update']}
            },
            {
                path: "sales/sales-quotation-requests/view/:id",
                name: "sales-quotation-requests.view",
                component: () =>
                    import ("@/view/pages/modules/sales/salesQuotationRequest/View.vue"),
                meta: {role: ['sales_quotation_request.list']}
            },
            // ******************************* End sales Quotation Request **************************************************

            // ******************************* Begin sales tax settings **************************************************
            {
                path: "sales/sales-tax-settings",
                name: "sales-tax-settings.list",
                component: () =>
                    import ("@/view/pages/modules/sales/salesTaxSettings/Form.vue"),
                meta: {role: ['tax_setting_sales.update']}
            },
            // ******************************* End sales tax settings **************************************************
            // ******************************* Begin purchase tax settings **************************************************
            {
                path: "purchases/purchase-tax-settings",
                name: "purchase-tax-settings.list",
                component: () =>
                    import ("@/view/pages/modules/purchases/purchaseTaxSettings/Form.vue"),
                meta: {role: ['tax_setting_purchase.update']}
            },
            // ******************************* End sales tax settings **************************************************
            // ****************************** Begin export-import-customers *************************************************
            {
                path: "customers/export-import-customers",
                name: "export-import-customers.index",
                component: () =>
                    import ("@/view/pages/modules/customers/importCustomers/Index.vue"),
                meta: {role: ['customers.export_import_customers']}
            },
            // ******************************* End export-import-customers **************************************************
            // ****************************** Begin Payment purchase Invoices *************************************************
            {
                path: "purchases/payment_purchase_invoices",
                name: "payment_purchase_invoices.index",
                component: () =>
                    import ("@/view/pages/modules/purchases/paymentPurchaseInvoices/Index.vue"),
                meta: {role: ['payment_purchase_invoices.list']}
            },
            {
                path: "purchases/payment_purchase_invoices/create/:purchase_invoice_id/:type?",
                name: "payment_purchase_invoices.create",
                component: () =>
                    import ("@/view/pages/modules/purchases/paymentPurchaseInvoices/Form.vue"),
                meta: {role: ['payment_purchase_invoices.create', 'purchases_invoices.payment_purchases_invoices', 'purchases_refunds.payment_purchase_invoices']}
            },
            {
                path: "purchases/payment_purchase_invoices/edit/:id/:type?",
                name: "payment_purchase_invoices.edit",
                component: () =>
                    import ("@/view/pages/modules/purchases/paymentPurchaseInvoices/Form.vue"),
                meta: {role: ['payment_purchase_invoices.update']}
            },
            {
                path: "purchases/payment_purchase_invoices/details/:id",
                name: "payment_purchase_invoices.details",
                component: () =>
                    import ("@/view/pages/modules/purchases/paymentPurchaseInvoices/Details.vue"),
                meta: {role: ['payment_purchase_invoices.details']}
            },{
                path: "purchases/payment_purchase_invoices/payment_purchase_details/:id",
                name: "payment_purchase_invoices.payment_purchase_details",
                component: () =>
                    import ("@/view/pages/modules/purchases/paymentPurchaseInvoices/paymentPurchaseDetails.vue"),
                meta: {role: ['payment_purchase_invoices.details']}
            },
            // ******************************* End Payment purchase Invoices **************************************************
            // ****************************** Begin taxes_rules *************************************************
            {
                path: "/settings/taxes-rules",
                name: "taxes-rules.index",
                component: () =>
                    import ("@/view/pages/modules/settings/taxesRules/Index.vue"),
                meta: {role: ['taxes_rules.list']}
            },
            // ****************************** End taxes_rules ************************************************************
            // ****************************** Begin delimiters *************************************************
            {
                path: "/settings/delimiters",
                name: "delimiters.index",
                component: () =>
                    import ("@/view/pages/modules/settings/delimiters/Index.vue"),
                meta: {role: ['delimiters.list']}
            },
            {
                path: "/settings/delimiters/create",
                name: "delimiters.create",
                component: () =>
                    import ("@/view/pages/modules/settings/delimiters/Form.vue"),
                meta: {role: ['delimiters.create']}
            },

            {
                path: "/settings/delimiters/edit/:id",
                name: "delimiters.edit",
                component: () =>
                    import ("@/view/pages/modules/settings/delimiters/Form.vue"),
                meta: {role: ['delimiters.update']}
            },

            // ****************************** End delimiters ***************************************************
            // ****************************** Begin invoice-status *************************************************
            {
                path: "sales/invoice-status/:id/:type?",
                name: "invoice-status.index",
                component: () =>
                    import ("@/view/pages/modules/sales/invoiceStatus/Index.vue"),
                meta: {role: ['sales_invoices.invoice_status', 'general_sales.invoice_status']}
            },
            // ****************************** End invoice-status ***************************************************
            // ****************************** Begin import-open-stock-items *************************************************
            {
                path: "/items/import-open-stock-items",
                name: "import-open-stock-items.index",
                component: () =>
                    import ("@/view/pages/modules/items/importOpenStockItems/Index.vue"),
                meta: {role: ['item_open_stock.import_quantities']}
            },
            // ****************************** End import-open-stock-items ***************************************************
            // ****************************** Begin laundry_services *************************************************
            {
                path: "/settings/laundry-services",
                name: "laundry_services.index",
                component: () =>
                    import ("@/view/pages/modules/settings/laundryServices/Index.vue"),
                meta: {role: ['laundry_services.create']}
            },
            {
                path: "/settings/laundry-services/create",
                name: "laundry_services.create",
                component: () =>
                    import ("@/view/pages/modules/settings/laundryServices/Form.vue"),
                meta: {role: ['laundry_services.create']}
            },
            {
                path: "/settings/laundry-services/edit/:id",
                name: "laundry_services.edit",
                component: () =>
                    import ("@/view/pages/modules/settings/laundryServices/Form.vue"),
                meta: {role: ['laundry_services.update']}
            },
            // ****************************** End laundry_services ***************************************************

            // ****************************** Begin price-lists-laundry-services *************************************************
            {
                path: "/items/price-lists-laundry-services",
                name: "price-lists-laundry-services.index",
                component: () =>
                    import ("@/view/pages/modules/items/laundryServicesPriceList/Index.vue"),
                meta: {role: ['price_list_laundry_services.list']}
            },
            {
                path: "/items/price-lists-laundry-services/create",
                name: "price-lists-laundry-services.create",
                component: () =>
                    import ("@/view/pages/modules/items/laundryServicesPriceList/Form.vue"),
                meta: {role: ['price_list_laundry_services.create']}
            },

            {
                path: "/items/price-lists-laundry-services/edit/:id",
                name: "price-lists-laundry-services.edit",
                component: () =>
                    import ("@/view/pages/modules/items/laundryServicesPriceList/Form.vue"),
                meta: {role: ['price_list_laundry_services.update']}
            },
            // ****************************** End price-lists-laundry-services ***************************************************
            // ****************************** Begin attached_reasons *************************************************
            {
                path: "/settings/attached-reasons",
                name: "attached-reasons.index",
                component: () =>
                    import ("@/view/pages/modules/settings/attachedReasons/Index.vue"),
                meta: {role: ['attached_reasons.create']}
            },
            // ****************************** End attached_reasons ***************************************************

            // ****************************** Begin Sales debits *************************************************
            {
                path: "sales/debits",
                name: "debits.index",
                component: () =>
                    import ("@/view/pages/modules/sales/debits/Index.vue"),
                meta: {role: ['sales_debit.list']}
            },
            {
                path: "sales/debits/create/:sale_invoice_id?",
                name: "debits.create",
                component: () =>
                    import ("@/view/pages/modules/sales/debits/Form.vue"),
                meta: {role: ['sales_debit.create', 'sales_invoices.add_sales_debit']}
            },
            {
                path: "sales/debits/edit/:id",
                name: "debits.edit",
                component: () =>
                    import ("@/view/pages/modules/sales/debits/Form.vue"),
                meta: {role: ['sales_debit.update']}
            },
            // ******************************* End Sales debits **************************************************
            // ****************************** Begin General Sales debits *************************************************
            {
                path: "sales/general-debits",
                name: "general-debits.index",
                component: () =>
                    import ("@/view/pages/modules/sales/generalDebits/Index.vue"),
                meta: {role: ['general_debit.list']}
            },
            {
                path: "sales/general-debits/create/:sale_invoice_id?",
                name: "general-debits.create",
                component: () =>
                    import ("@/view/pages/modules/sales/generalDebits/Form.vue"),
                meta: {role: ['general_debit.create', 'general_sales.add_sales_debit']}
            },
            {
                path: "sales/general-debits/edit/:id",
                name: "general-debits.edit",
                component: () =>
                    import ("@/view/pages/modules/sales/generalDebits/Form.vue"),
                meta: {role: ['general_debit.update']}
            },
            // ******************************* End Sales debits **************************************************
            // ****************************** Begin laundry Services Colors ****************************************************
            {
                path: "/settings/laundry-services-colors",
                name: "laundry-services-colors.index",
                component: () =>
                    import ("@/view/pages/modules/settings/laundryServicesColors/Form.vue"),
                meta: {role: ['laundry_services_colors.update']}
            },
            // ****************************** End laundry Services Colors ****************************************************
            // ****************************** Begin discount setting ****************************************************
            {
                path: "/settings/discount",
                name: "discounts-setting.index",
                component: () =>
                    import ("@/view/pages/modules/settings/discount/Index.vue"),
                meta: {role: ['discounts_setting.list']}
            },
            {
                path: "/settings/discount/create",
                name: "discounts-setting.create",
                component: () =>
                    import ("@/view/pages/modules/settings/discount/Form.vue"),
                meta: {role: ['discounts_setting.create']}
            },
            // ****************************** End discount setting ****************************************************
            // ****************************** Begin Sales laundries *************************************************
            {
                path: "sales/sales-laundries",
                name: "sales_laundries.index",
                component: () =>
                    import ("@/view/pages/modules/sales/laundrySales/Index.vue"),
                meta: {role: ['sales_laundry_services.list']}
            },
            {
                path: "sales/sales-laundries/create/:quotation_requests_id?/:quotation_type?",
                name: "sales_laundries.create",
                component: () =>
                    import ("@/view/pages/modules/sales/laundrySales/Form.vue"),
                meta: {role: ['sales_laundry_services.create', 'sales_quotations.convert_to_sales_invoice']}
            },
            {
                path: "sales/sales-laundries/edit/:id",
                name: "sales_laundries.edit",
                component: () =>
                    import ("@/view/pages/modules/sales/laundrySales/Form.vue"),
                meta: {role: ['sales_laundry_services.update']}
            },
            {
                path: "sales/sales-laundries/clone/:clone_id",
                name: "sales_laundries.clone",
                component: () =>
                    import ("@/view/pages/modules/sales/laundrySales/Form.vue"),
                meta: {role: ['sales_laundry_services.clone']}
            },

            {
                path: "settings/cash-category",
                name: "cash_category.index",
                component: () =>
                    import ("@/view/pages/modules/settings/cashCategory/Index.vue"),
                meta: {role: ['cash_category.list']}
            },


            {
                path: "settings/invoice-bond-configuration",
                name: "invoice-bond-configuration.index",
                component: () =>
                    import ("@/view/pages/modules/settings/invoicesBondsConfiguration/Index.vue"),
                meta: {role: ['invoice_bond_configuration.list']}
            },
            {
                path: "settings/invoice-bond-configuration/edit/:id",
                name: "invoice-bond-configuration.edit",
                component: () =>
                    import ("@/view/pages/modules/settings/invoicesBondsConfiguration/Form.vue"),
                meta: {role: ['invoice_bond_configuration.update']}
            },


            {
                path: "settings/basic-status-management",
                name: "basic-status-management.index",
                component: () =>
                    import ("@/view/pages/modules/settings/basicStatusManagement/Index.vue"),
                meta: {role: ['basic_status_management.list']}
            },
            {
                path: "settings/basic-status-management/edit/:id",
                name: "basic-status-management.edit",
                component: () =>
                    import ("@/view/pages/modules/settings/basicStatusManagement/Form.vue"),
                meta: {role: ['basic_status_management.update']}
            },


            {
                path: "settings/document-status-paths",
                name: "document-status-paths.index",
                component: () =>
                    import ("@/view/pages/modules/settings/documentStatusPaths/Index.vue"),
                meta: {role: ['document_status_paths.list']}
            },
            {
                path: "settings/document-status-paths/create",
                name: "document-status-paths.create",
                component: () =>
                    import ("@/view/pages/modules/settings/documentStatusPaths/Form.vue"),
                meta: {role: ['document_status_paths.create']}
            },
            {
                path: "settings/document-status-paths/edit/:id",
                name: "document-status-paths.edit",
                component: () =>
                    import ("@/view/pages/modules/settings/documentStatusPaths/Form.vue"),
                meta: {role: ['document_status_paths.update']}
            },

            {
                path: "settings/status-mapping",
                name: "status-mapping.index",
                component: () =>
                    import ("@/view/pages/modules/settings/statusMapping/Index.vue"),
                meta: {role: ['status_mapping.list']}
            },
            {
                path: "settings/status-mapping/edit/:id",
                name: "status-mapping.edit",
                component: () =>
                    import ("@/view/pages/modules/settings/statusMapping/Form.vue"),
                meta: {role: ['status_mapping.update']}
            },


            {
                path: "settings/connected-printers",
                name: "connected-printers.index",
                component: () =>
                    import ("@/view/pages/modules/settings/connectedPrinters/Index.vue"),
                meta: {role: ['connected_printers.list']}
            },
            {
                path: "settings/connected-printers/create",
                name: "connected-printers.create",
                component: () =>
                    import ("@/view/pages/modules/settings/connectedPrinters/Form.vue"),
                meta: {role: ['connected_printers.create']}
            },
            {
                path: "settings/connected-printers/edit/:id",
                name: "connected-printers.edit",
                component: () =>
                    import ("@/view/pages/modules/settings/connectedPrinters/Form.vue"),
                meta: {role: ['connected_printers.update']}
            },


            // {
            //     path: "sales/cost-center/create/:sales_invoice_id",
            //     name: "cost-center.create",
            //     component: () =>
            //         import ("@/view/pages/modules/sales/costCenter/Form.vue"),
            //     meta: { role: ['add_to_cost_center.create', 'sales_invoices.add_to_cost_center'] }
            // },
            // {
            //     path: "sales/attachment-notes/create/:type_id/:type",
            //     name: "attachment-notes.create",
            //     component: () =>
            //         import ("@/view/pages/modules/sales/attachmentNotes/Form.vue"),
            //     meta: { role: ['attachment_notes.create'] }
            // },
            // {
            //     path: "sales/appointment/create/:type_id/:type",
            //     name: "appointment.create",
            //     component: () =>
            //         import ("@/view/pages/modules/customers/appointments/Form.vue"),
            //     meta: { role: ['schedule_appointment.create', 'sales_invoices.schedule_appointment', 'estimations.schedule_appointment','general_sales.schedule_appointment'] }
            // },
            // {
            //     path: "settings/custom/email/:type_id/:type",
            //     name: "custom.email",
            //     component: () =>
            //         import ("@/view/pages/modules/settings/customEmail/Form.vue"),
            //     meta: { role: ['sales_invoices.send_mail','payment_sales_invoices.send_mail','payment_purchase_invoices.send_mail','payable_cheques.send_mail', 'receivable_cheques.send_mail','general_sales.send_mail'] }
            // },

            // ******************************* End Sales laundries **************************************************
            // ****************************** Begin fabric_problems *************************************************
            {
                path: "/settings/fabric-problems",
                name: "fabric-problems.index",
                component: () =>
                    import ("@/view/pages/modules/settings/fabricProblems/Index.vue"),
                meta: {role: ['fabric_problems.list']}
            },
            // ****************************** End fabric_problems ***************************************************
            // ****************************** Begin main-reports *************************************************
            {
                path: "/reports/main-reports",
                name: "main-reports.index",
                component: () =>
                    import ("@/view/pages/modules/reports/reportsTabs/Index.vue"),
                // meta: {role: ['main_reports.list']}
            },
            // ****************************** End main-reports ***************************************************
            // ****************************** Begin sales-payment-details *************************************************
            {
                path: "sales/credits/payment-details/:id",
                name: "credits.payment_details",
                component: () =>
                    import ("@/view/pages/modules/sales/credits/PaymentDetails.vue"),
                meta: {role: ['credits.payment_details']}
            },
            {
                path: "sales/general-credits/payment-details/:id",
                name: "general-credits.payment_details",
                component: () =>
                    import ("@/view/pages/modules/sales/generalCredits/PaymentDetails.vue"),
                meta: {role: ['general_credits.payment_details']}
            },
            {
                path: "sales/sales-refund/payment-details/:id",
                name: "sales_refund.payment_details",
                component: () =>
                    import ("@/view/pages/modules/sales/refund/PaymentDetails.vue"),
                meta: {role: ['sales_refund.payment_details']}
            },
            {
                path: "sales/general_sales_refund/payment-details/:id",
                name: "general_sales_refund.payment_details",
                component: () =>
                    import ("@/view/pages/modules/sales/generalRefund/PaymentDetails.vue"),
                meta: {role: ['general_sales_refund.payment_details']}
            },

            // ****************************** End sales-payment-details ***************************************************

            // ****************************** Begin financial-list *************************************************
            {
                path: "accounting/financial-list",
                name: "financial-list.index",
                component: () =>
                    import ("@/view/pages/modules/accounting/financialList/Index.vue"),
                meta: {role: ['financial_list.list']}
            },
            {
                path: "accounting/financial-list/create",
                name: "financial-list.create",
                component: () =>
                    import ("@/view/pages/modules/accounting/financialList/Form.vue"),
                meta: {role: ['financial_list.create']}
            },
            {
                path: "accounting/financial-list/edit/:id?",
                name: "financial-list.edit",
                component: () =>
                    import ("@/view/pages/modules/accounting/financialList/Form.vue"),
                meta: {role: ['financial_list.update']}
            },
            // ****************************** End financial-list ***************************************************
            // ****************************** End general-item-configurations ***************************************************
            {
                path: "items/general-item-configurations",
                name: "financial-list.edit",
                component: () =>
                    import ("@/view/pages/modules/items/generalConfiguration/Form.vue"),
                meta: {role: ['general_items_configuration.update']}
            },
            // ****************************** End general-item-configurations ***************************************************

        ]
    },
        {
            redirect: "/dashboard",
            path: "/print",
            component: () =>
                import ("@/view/layout/LayoutForPrint"),
            children: [{
                path: "sales/:sales_invoice_id/:type?",
                name: "print-sales.list",
                component: () =>
                    import ("@/view/pages/modules/sales/invoices/Print.vue"),
                meta: {role: ['sales_invoice_print.create', 'sales_invoices.printing_export_pdf', 'pos_session.printing_export_pdf']}
            },
                {
                    path: "packing/:sales_invoice_id/:type?",
                    name: "packing-sales.list",
                    component: () =>
                        import ("@/view/pages/modules/sales/invoices/packingSlip.vue"),
                    meta: {role: ['sales_invoices.pdf_print_packing_slip']}
                },
                {
                    path: "packing-general-sales/:sales_invoice_id/:type?",
                    name: "packing-general-sales.list",
                    component: () =>
                        import ("@/view/pages/modules/sales/generalSales/packingSlip.vue"),
                    meta: {role: ['general_sales.pdf_print_packing_slip']}
                },
                {
                    path: "sales-credit/:sales_invoice_id/:type?",
                    name: "print-sales-credits.list",
                    component: () =>
                        import ("@/view/pages/modules/sales/credits/Print.vue"),
                    meta: {role: ['credits.printing_export_pdf']}
                },
                {
                    path: "general-sales-credit/:sales_invoice_id/:type?",
                    name: "print-general-sales-credits.list",
                    component: () =>
                        import ("@/view/pages/modules/sales/generalCredits/Print.vue"),
                    meta: {role: ['general_credits.printing_export_pdf']}
                },
                {
                    path: "sales-debit/:sales_invoice_id/:type?",
                    name: "print-sales-debits.list",
                    component: () =>
                        import ("@/view/pages/modules/sales/debits/Print.vue"),
                    meta: {role: ['sales_debit.printing_export_pdf']}
                },
                {
                    path: "general-sales-debit/:sales_invoice_id/:type?",
                    name: "print-general-sales-debits.list",
                    component: () =>
                        import ("@/view/pages/modules/sales/generalDebits/Print.vue"),
                    meta: {role: ['general_debit.printing_export_pdf']}
                },
                {
                    path: "sales-estimate/:sales_invoice_id/:type?",
                    name: "print-sales-estimate.list",
                    component: () =>
                        import ("@/view/pages/modules/sales/estimations/Print.vue"),
                    meta: {role: ['estimations.printing_export_pdf']}
                },
                {
                    path: "sales-refund-80/:sales_invoice_id/:type?",
                    name: "print-sales-refund.list",
                    component: () =>
                        import ("@/view/pages/modules/sales/refund/Print80MM.vue"),
                    meta: {role: ['sales_refund.printing_export_pdf']}
                },
                {
                    path: "general-sales-refund-80/:general_sales_invoice_id/:type?",
                    name: "print-general-sales-refund.list",
                    component: () =>
                        import ("@/view/pages/modules/sales/generalRefund/Print80MM.vue"),
                    meta: {role: ['general_sales_refund.printing_export_pdf']}
                },
                {
                    path: "general-sales/:sales_invoice_id/:type?",
                    name: "print-general-sales.list",
                    component: () =>
                        import ("@/view/pages/modules/sales/generalSales/Print.vue"),
                    meta: {role: ['general_sales.printing_export_pdf']}
                },
                {
                    path: "purchase-order/:purchase_invoice_id/:type?",
                    name: "print-purchase-order.list",
                    component: () =>
                        import ("@/view/pages/modules/purchases/purchaseOrder/Print.vue"),
                    meta: {role: ['purchase_orders.printing_export_pdf']}
                },
                {
                    path: "purchase-invoice/:purchase_invoice_id/:type?",
                    name: "print-purchase-invoice.list",
                    component: () =>
                        import ("@/view/pages/modules/purchases/purchaseInvoice/Print.vue"),
                    meta: {role: ['purchases_invoices.printing_export_pdf']}
                },
                {
                    path: "purchaseInvoice/:purchase_invoice_id/:type?",
                    name: "print-purchase-invoice.list",
                    component: () =>
                        import ("@/view/pages/modules/purchases/purchaseInvoice/Print2.vue"),
                    meta: {role: ['purchases_invoices.printing_export_pdf']}
                },
                {
                    path: "purchaseRefund/:purchase_invoice_id/:type?",
                    name: "print-purchase-refund.list",
                    component: () =>
                        import ("@/view/pages/modules/purchases/purchaseRefund/Print2.vue"),
                    meta: {role: ['purchases_refunds.printing_export_pdf']}
                },
                {
                    path: "purchase-refund/:purchase_invoice_id/:type?",
                    name: "print-purchase-refund.list",
                    component: () =>
                        import ("@/view/pages/modules/purchases/purchaseRefund/Print.vue"),
                    meta: {role: ['purchases_refunds.printing_export_pdf']}
                },
                {
                    path: "point-sale-invoice",
                    name: "point-sale-invoice.list",
                    component: () =>
                        import ("@/view/pages/modules/pos/pointOfSales/Print.vue"),
                    // meta: { role: ['packing_slip.create'] }
                },
                {
                    path: "point-request-sale-invoice",
                    name: "point-request-sale-invoice.list",
                    component: () =>
                        import ("@/view/pages/modules/pos/pointOfSales/PrintRequest.vue"),
                    // meta: { role: ['packing_slip.create'] }
                },
                {
                    path: "point-laundry-sale-invoice",
                    name: "point-laundry-sale-invoice.list",
                    component: () =>
                        import ("@/view/pages/modules/pos/pointOfSalesLaundry/Print.vue"),
                    // meta: { role: ['packing_slip.create'] }
                },
                {
                    path: "point-sale-invoice-refund",
                    name: "point-sale-invoice-refund.list",
                    component: () =>
                        import ("@/view/pages/modules/pos/pointOfSales/PrintRefund.vue"),
                    // meta: { role: ['packing_slip.create'] }
                },
                {
                    path: "point-sale-credit/:id",
                    name: "point-sale-credit.list",
                    component: () =>
                        import ("@/view/pages/modules/pos/pointOfSales/PrintCredit.vue"),
                    // meta: { role: ['point-sale-credit.create'] }
                },
                {
                    path: "pos-sessions/:id/:action",
                    name: "pos-sessions.print",
                    component: () =>
                        import ("@/view/pages/modules/pos/posSessions/Print.vue"),
                    // meta: { role: ['pos_session.create'] }
                },
                {
                    path: "pos-sessions/pos-refund/:id/:action",
                    name: "pos-refund.print",
                    component: () =>
                        import ("@/view/pages/modules/pos/posSessions/refund/Print.vue"),
                    meta: {role: ['pos_refund.printing_export_pdf']}
                },
                {
                    path: "sales/payment/:id/:action",
                    name: "payment.print",
                    component: () =>
                        import ("@/view/pages/modules/sales/paymentSalesInvoices/Print.vue"),
                },
                {
                    path: "purchases/payment/:id/:action",
                    name: "payment_purchases.print",
                    component: () =>
                        import ("@/view/pages/modules/purchases/paymentPurchaseInvoices/Print.vue"),
                },
                {
                    path: "summary-report",
                    name: "summary-report.export",
                    component: () =>
                        import ("@/view/pages/modules/statistics/dailyBriefReportInvoices/components/SummaryPdf.vue"),
                },
                {
                    path: "inventory-transactions/:id/:type?",
                    name: "print-inventory-transactions.list",
                    component: () =>
                        import ("@/view/pages/modules/items/inventoryTransactions/Print.vue"),
                    meta: {role: ['inventory-transactions.printing_export_pdf', 'inventory-transactions.printing_export_pdf_without_price']}
                },
                {
                    path: "print-expenses-transactions/:id/:type?",
                    name: "print-expenses-transactions.list",
                    component: () =>
                        import ("@/view/pages/modules/finances/expensesIncomeTransaction/expensesPrint.vue"),
                    meta: {role: ['expenses_incomes.printing_export_pdf']}
                },
                {
                    path: "print-incomes-transactions/:id/:type?",
                    name: "print-incomes-transactions.list",
                    component: () =>
                        import ("@/view/pages/modules/finances/expensesIncomeTransaction/incomePrint.vue"),
                    meta: {role: ['expenses_incomes.printing_export_pdf']}
                },
                {
                    path: "supplier-account-statement/:id",
                    name: "supplier-account-statement.list",
                    component: () =>
                        import ("@/view/pages/modules/purchases/suppliers/accountStatement.vue"),
                    meta: {role: ['suppliers.account_statement']}
                },
                {
                    path: "customer-account-statement/:id/:type?",
                    name: "customer-account-statement.list",
                    component: () =>
                        import ("@/view/pages/modules/customers/customers/accountStatement.vue"),
                    meta: {role: ['customers.account_statement']}
                },

                {
                    path: "sales/package-label-print/:id/:type?",
                    name: "package-label-print.list",
                    component: () =>
                        import ("@/view/pages/modules/sales/invoices/packageLabelPrint.vue"),
                    meta: {role: ['sales_invoices.pdf_package']}
                },

                {
                    path: "sales/package-label-print-general-sales/:id/:type?",
                    name: "package-label-print-general-sales.list",
                    component: () =>
                        import ("@/view/pages/modules/sales/generalSales/packageLabelPrint.vue"),
                    meta: {role: ['general_sales.pdf_package']}
                },


                {
                    path: "sales/receive-list-print/:id/:type?",
                    name: "receive-list-print.list",
                    component: () =>
                        import ("@/view/pages/modules/sales/invoices/receiveListPrint.vue"),
                    meta: {role: ['sales_invoices.print_pdf_receive']}
                },
                {
                    path: "sales/receive-list-print-general-sales/:id/:type?",
                    name: "receive-list-print-general-sales.list",
                    component: () =>
                        import ("@/view/pages/modules/sales/generalSales/receiveListPrint.vue"),
                    meta: {role: ['general_sales.print_pdf_receive']}
                },

                {
                    path: "sales/delivery-label-print/:id/:type?",
                    name: "delivery-label-print.list",
                    component: () =>
                        import ("@/view/pages/modules/sales/invoices/deliveryLabelPrint.vue"),
                    meta: {role: ['sales_invoices.print_pdf_delivery']}
                },

                {
                    path: "sales/delivery-label-print-general-sales/:id/:type?",
                    name: "delivery-label-print-general-sales.list",
                    component: () =>
                        import ("@/view/pages/modules/sales/generalSales/deliveryLabelPrint.vue"),
                    meta: {role: ['general_sales.print_pdf_delivery']}
                },
                {
                    path: "cash-movement/:id/:type?",
                    name: "print-cash-movement.list",
                    component: () =>
                        import ("@/view/pages/modules/pos/cashMovement/Print.vue"),
                    meta: {role: ['cash_movement.printing_export_pdf']}
                },

                {
                    path: "print-treasury-checking-history/:type",
                    name: "print-treasury-checking-history.list",
                    component: () =>
                        import ("@/view/pages/modules/finances/treasuryCheckingHistory/Print.vue"),
                    // meta: { role: ['expenses_incomes.create'] }
                },

                {
                    path: "print-bank-checking-history/:type",
                    name: "print-bank-checking-history.list",
                    component: () =>
                        import ("@/view/pages/modules/finances/bankCheckingHistory/Print.vue"),
                    // meta: { role: ['expenses_incomes.create'] }
                },

                {
                    path: "print-sales-quotations/:id/:type?",
                    name: "print-sales-quotations.list",
                    component: () =>
                        import ("@/view/pages/modules/sales/salesQuotations/Print.vue"),
                    meta: {role: ['sales_quotations.printing_export_pdf']}
                },
                {
                    path: "sales-tax-invoice/:sales_invoice_id/:type?/:status?",
                    name: "print-sales-tax-invoice.list",
                    component: () =>
                        // import ("@/view/pages/modules/sales/invoices/PrintTaxInvoice.vue"),
                        import ("@/view/pages/modules/sales/invoices/PrintTaxInvoice/PrintTaxInvoice.vue"),
                    meta: {role: ['sales_invoices.pdf_print_tax_invoice', 'sales_invoices.pdf_print_tax_invoice_simplified']}
                },

                {
                    path: "sales-80mm-invoice/:sales_invoice_id/:type?",
                    name: "print-sales-80mm-invoice.list",
                    component: () =>
                        import ("@/view/pages/modules/sales/invoices/Print80MM.vue"),
                    meta: {role: ['sales_invoices.printing_export_pdf']}
                },

                {
                    path: "general-sales-tax-invoice/:sales_invoice_id/:type?/:status?",
                    name: "print-general-sales-tax-invoice.list",
                    component: () =>
                        import ("@/view/pages/modules/sales/generalSales/PrintTaxInvoice.vue"),
                    meta: {role: ['sales_invoices.pdf_print_tax_invoice', 'general_sales.pdf_print_tax_invoice_simplified']}
                },

                {
                    path: "print-general-sales-quotations/:id/:type?",
                    name: "print-general-sales-quotations.list",
                    component: () =>
                        import ("@/view/pages/modules/sales/generalSalesQuotations/Print.vue"),
                    meta: {role: ['general_sales_quotations.printing_export_pdf']}
                },

                {
                    path: "general-sales-tax-invoice-refund/:sales_invoice_id/:type?/:status?",
                    name: "print-general-sales-tax-invoice-refund.list",
                    component: () =>
                        import ("@/view/pages/modules/sales/generalRefund/PrintTaxInvoice.vue"),
                    meta: {role: ['general_sales_refund.pdf_print_tax_invoice', 'general_sales_refund.pdf_print_tax_invoice_simplified']}
                },

                {
                    path: "general-sales-80mm-invoice/:sales_invoice_id/:type?",
                    name: "print-general-sales-80mm-invoice.list",
                    component: () =>
                        import ("@/view/pages/modules/sales/generalSales/Print80MM.vue"),
                    meta: {role: ['general_sales.printing_export_pdf']}
                },

                {
                    path: "laundry-sales-tax-invoice/:sales_invoice_id/:type?/:status?",
                    name: "print-laundry-sales-tax-invoice.list",
                    component: () =>
                        import ("@/view/pages/modules/sales/laundrySales/PrintTaxInvoice.vue"),
                    meta: {role: ['sales_laundry_services.pdf_print_tax_invoice', 'sales_laundry_services.pdf_print_tax_invoice_simplified']}
                },
                {
                    path: "credits-tax-invoice/:sales_invoice_id/:type?/:status?",
                    name: "print-credits-tax-invoice.list",
                    component: () =>
                        import ("@/view/pages/modules/sales/credits/PrintTaxInvoice.vue"),
                    meta: {role: ['credits.pdf_print_tax_invoice', 'credits.pdf_print_tax_invoice_simplified']}
                },
                {
                    path: "general-credits-tax-invoice/:sales_invoice_id/:type?/:status?",
                    name: "print-general-credits-tax-invoice.list",
                    component: () =>
                        import ("@/view/pages/modules/sales/generalCredits/PrintTaxInvoice.vue"),
                    meta: {role: ['general_credits.pdf_print_tax_invoice', 'general_credits.pdf_print_tax_invoice_simplified']}
                },
                {
                    path: "debits-tax-invoice/:sales_invoice_id/:type?/:status?",
                    name: "print-debits-tax-invoice.list",
                    component: () =>
                        import ("@/view/pages/modules/sales/debits/PrintTaxInvoice.vue"),
                    meta: {role: ['sales_debit.pdf_print_tax_invoice', 'sales_debit.pdf_print_tax_invoice_simplified']}
                },
                {
                    path: "general-debits-tax-invoice/:sales_invoice_id/:type?/:status?",
                    name: "print-general-debits-tax-invoice.list",
                    component: () =>
                        import ("@/view/pages/modules/sales/generalDebits/PrintTaxInvoice.vue"),
                    meta: {role: ['general_debit.pdf_print_tax_invoice', 'general_debit.pdf_print_tax_invoice_simplified']}
                },
                {
                    path: "sales-tax-invoice-refund/:sales_invoice_id/:type?/:status?",
                    name: "print-sales-tax-invoice-refund.list",
                    component: () =>
                        import ("@/view/pages/modules/sales/refund/PrintTaxInvoice.vue"),
                    meta: {role: ['sales_refund.pdf_print_tax_invoice', 'sales_refund.pdf_print_tax_invoice_simplified']}
                },

                {
                    path: "print-incomes-transactions-customer/:id/:type?",
                    name: "print-incomes-transactions-customer.list",
                    component: () =>
                        import ("@/view/pages/modules/customers/customers/incomePrint.vue"),
                    meta: {role: ['customers.update']}
                },
                {
                    path: "sales-rent-tax-invoice/:sales_invoice_id/:type?/:status?",
                    name: "print-sales-rent-tax-invoice.list",
                    component: () =>
                        import ("@/view/pages/modules/sales/salesRent/PrintTaxInvoice.vue"),
                    meta: {role: ['sales_invoices.pdf_print_tax_invoice', 'sales_rent.pdf_print_tax_invoice_simplified']}
                },

                {
                    path: "general-sales-rent-tax-invoice/:sales_invoice_id/:type?/:status?",
                    name: "print-general-sales-rent-tax-invoice.list",
                    component: () =>
                        import ("@/view/pages/modules/sales/generalSalesRent/PrintTaxInvoice.vue"),
                    meta: {role: ['sales_invoices.pdf_print_tax_invoice', 'general_sales_rent.pdf_print_tax_invoice_simplified']}
                },
                {
                    path: "payment-invoice/:id/:type?",
                    name: "print-payment-invoice.list",
                    component: () =>
                        import ("@/view/pages/modules/sales/paymentSalesInvoices/PrintPayment.vue"),
                    meta: {role: ['payment_sales_invoices.printing_export_pdf_financial']}
                },

                {
                    path: "pos-laundry-sales-80mm-invoice/:sales_invoice_id/:type?",
                    name: "pos-laundry-sales-80mm-invoice.list",
                    component: () =>
                        import ("@/view/pages/modules/pos/pointOfSalesLaundry/Print.vue"),
                    // meta: { role: ['sales_invoices.printing_export_pdf'] }
                },

                {
                    path: "print-outside/pos/pos-sales-invoice-80mm/:sales_invoice_id/:type?",
                    name: "pos-sales-invoice-80mm.list",
                    component: () =>
                        import ("@/view/pages/modules/printing/pos/Print80MM.vue"),
                },
                {
                    path: "print-outside/pos/pos-credit-invoice-80mm/:id/:type?",
                    name: "pos-credit-80mm.list",
                    component: () =>
                        import ("@/view/pages/modules/printing/pos/PrintCredit.vue"),
                },
                {
                    path: "print-outside/pos/pos-refund-invoice-80mm/:id/:type?",
                    name: "pos-refund-80mm.list",
                    component: () =>
                        import ("@/view/pages/modules/printing/pos/PrintRefund.vue"),
                },

                {
                    path: "pos/session-details/:id/:action?",
                    name: "pos-session-invoice.list",
                    component: () =>
                        import ("@/view/pages/modules/pos/posSessions/Print.vue"),
                },

                {
                    path: "pos/session-details-80mm/:id/:action?",
                    name: "pos-session-invoice-80mm.list",
                    component: () =>
                        import ("@/view/pages/modules/pos/posSessions/Print80MM.vue"),
                },

                /////////////////////////////START print-outside A4 //////////////////////////////////////
                {
                    path: "print-outside/sales/sales-invoice-a4-tax/:sales_invoice_id/:type?",
                    name: "sales-invoice-a4-tax.list",
                    component: () =>
                        import ("@/view/pages/modules/printing/sales/salesInvoice/PrintTaxInvoiceA4/PrintTaxInvoice.vue"),
                },
                {
                    path: "print-outside/sales/sales-invoice-a4/:sales_invoice_id/:type?",
                    name: "sales-invoice-a4-tax.list",
                    component: () =>
                        import ("@/view/pages/modules/printing/sales/salesInvoice/PrintA4WithoutTax.vue"),
                },
                {
                    path: "print-outside/sales/general-sales-invoice-a4-tax/:sales_invoice_id/:type?",
                    name: "general-sales-invoice-a4-tax.list",
                    component: () =>
                        import ("@/view/pages/modules/printing/sales/generalSales/PrintTaxInvoiceA4.vue"),
                },
                {
                    path: "print-outside/sales/general-sales-invoice-a4/:sales_invoice_id/:type?",
                    name: "general-sales-invoice-a4.list",
                    component: () =>
                        import ("@/view/pages/modules/printing/sales/generalSales/PrintA4WithoutTax.vue"),
                },
                {
                    path: "print-outside/sales/sales-quotation-a4-tax/:id/:type?",
                    name: "sales-quotation-a4-tax.list",
                    component: () =>
                        import ("@/view/pages/modules/printing/sales/salesQuotation/PrintA4.vue"),
                },
                {
                    path: "print-outside/sales/general-sales-quotation-a4-tax/:id/:type?",
                    name: "general-sales-quotation-a4-tax.list",
                    component: () =>
                        import ("@/view/pages/modules/printing/sales/generalSalesQuotation/PrintA4.vue"),
                },
                /////////////////////////////END print-outside A4 //////////////////////////////////////

                {
                    path: "sales-rent-80mm-invoice/:sales_invoice_id/:type?",
                    name: "print-sales-rent-80mm-invoice.list",
                    component: () =>
                        import ("@/view/pages/modules/sales/salesRent/Print80MM.vue"),
                    meta: {role: ['sales_rent.printing_export_pdf']}
                },
            ]
        },
        {
            redirect: "/dashboard",
            path: "/point-of-sales",
            component: () =>
                import ("@/view/layout/LayoutForPointOfSales"),
            children: [
                // ******************************* Begin point-of-sales **************************************************

                {
                    path: "point-of-sales",
                    name: "point-of-sales.index",
                    component: () =>
                        import ("@/view/pages/modules/pos/pointOfSales/IndexTabs.vue"),
                    meta: {role: ['point_sales.list']}
                },
                // {
                //     path: "point-of-sales",
                //     name: "point-of-sales.index",
                //     component: () =>
                //         import ("@/view/pages/modules/pos/pointOfSales/Index.vue"),
                //     // meta: { role: ['point_of_sales.list'] }
                // },

                // ******************************* End point-of-sales **************************************************

            ]
        },
        {
            redirect: "/dashboard",
            path: "/point-of-sales",
            component: () =>
                import ("@/view/layout/LayoutForPointOfSales"),
            children: [
                // ******************************* Begin point-of-sales **************************************************

                {
                    path: "point-of-sales-laundry",
                    name: "point-of-sales-laundry.index",
                    component: () =>
                        import ("@/view/pages/modules/pos/pointOfSalesLaundry/IndexTabs.vue"),
                    meta: {role: ['point_sales_laundry.list']}
                },
                // {
                //     path: "point-of-sales",
                //     name: "point-of-sales.index",
                //     component: () =>
                //         import ("@/view/pages/modules/pos/pointOfSales/Index.vue"),
                //     // meta: { role: ['point_of_sales.list'] }
                // },

                // ******************************* End point-of-sales **************************************************

            ]
        },
        {
            path: "/",
            component: () => import ("@/view/pages/auth/login_pages/Login-1"),
            children: [
                {
                    name: "login",
                    path: "/login",
                    component: () => import ("@/view/pages/auth/login_pages/Login-1")
                },
            ]
        },
        {
            path: "/",
            component: () => import ("@/view/pages/auth/ProfileSettings"),
            children: [
                {
                    name: "profile-settings",
                    path: "/profile-settings",
                    component: () => import ("@/view/pages/auth/ProfileSettings")
                },
            ]
        },

        {
            path: "*",
            redirect: "/404"
        },
        {
            // the 404 route, when none of the above matches
            path: "/404",
            name: "404",
            component: () =>
                import ("@/view/pages/error/Error-404.vue")
        },
        {
            // the 404 route, when none of the above matches
            path: "/403",
            name: "403",
            component: () =>
                import ("@/view/pages/error/Error-403.vue")
        }
    ]
});
route.beforeEach((to, from, next) => {
    let _verify_auth = [];
    if (to.path.includes('print')) {
        _verify_auth = [];
    } else {
        _verify_auth = store.dispatch(VERIFY_AUTH);
    }
    Promise.all([_verify_auth]).then(() => {

        store.dispatch(RESET_LAYOUT_CONFIG);
        const role = to.meta;
        const currentUserRoles = Permissions.getPermission();
        let _is_has_role = false;
        // if (role && role.role) {
        //     let _role = role.role[0];
        //     console.log(role.role);
        //     if (!currentUserRoles.includes(_role)) {
        //         return next({ path: "/403" });
        //     }
        // }
        // next();
        if (role && role.role) {
            role.role.forEach((_role) => {
                // let _role = role.role[0];
                if (currentUserRoles.includes(_role)) {
                    _is_has_role = true;
                }
            })
            if (_is_has_role) {
                return next();
            } else {
                // return next();
                return next({path: "/403"});
            }
        }
        next();

    });
});

export default route;
