<template>
  <div>
    <div class="card-toolbar mb-5">
      <router-link to="/items/inventory-monitoring/create" class="btn btn-primary font-weight-bolder">
        <v-icon>mdi-plus</v-icon>
        {{ $t('inventory_monitoring.new_inventory_monitoring') }}
      </router-link>
      <button @click="openAdvancedSearch" class="btn btn-outline-primary font-weight-bolder ml-5">
        <span><i class="la la-filter"></i> <span>{{ $t('advanced_filter') }}</span></span>
      </button>
    </div>

    <div class="card card-custom mb-5" v-if="showAdvancedSearch">
      <div class="card-body">
        <div class="m-form m-form--fit m--margin-bottom-20">
          <div class="row">
            <div class="form-group col-md-6 mt-2 mb-2">
              <label>{{ $t('inventory_monitoring.inventory') }}</label>
              <multiselect v-model="inventory"
                           :placeholder="$t('inventory_monitoring.inventory')"
                           label="name"
                           track-by="id"
                           :options="inventories"
                           :multiple="false"
                           :taggable="true"
                           :show-labels="false"
                           :show-no-options="false"
                           :show-no-results="false">
              </multiselect>
            </div>
            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="from_date">{{ $t('inventory_monitoring.from_date') }}</label>
              <input v-model="filters.from_date" type="date" id="from_date" class="form-control">
            </div>
            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="to_date">{{ $t('inventory_monitoring.to_date') }}</label>
              <input v-model="filters.to_date" type="date" id="to_date" class="form-control">
            </div>

            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="f_status">
                {{ $t('status') }}
              </label>
              <select name="" id="f_status" v-model="filters.status" type="text" class="custom-select">
                <option value="">{{ $t('all') }}</option>
                <option v-for="(row, index) in status_list" :value="row.id" :key="index">{{ row.text }}</option>
              </select>
            </div>
            <div class="form-group d-inline-flex col-md-6 mt-10">
              <b-button class="mt-auto mb-0 mr-2" variant="primary" @click="doFilter"><i class="fas fa-search"></i> {{ $t('search') }}</b-button>
              <b-button class="mt-auto mb-0 mr-2" variant="danger" @click="resetFilter"><i class="fas fa-trash-restore"></i> {{ $t('reset_search') }}</b-button>

            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card card-custom">

      <div class="card-body">
        <div>
          <div class="row justify-content-end p-4">
            <custom-export-data v-if="$can('inventory-monitoring.export_data')" :data-list="dataList" :fields="json_fields" :file-name="$t('MENU.Inventory_monitoring')"></custom-export-data>
          </div>
        </div>

        <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">

          <template slot="status" slot-scope="props">

            <button v-if="$can('inventory-monitoring.change_status')" @click="changeStatus(props.row.id, props.row.status)" class="btn btn-secondary m-btn m-btn--icon w-auto">
              {{$t('change_status')}} ({{props.row.status_name}})
            </button>

            <button v-else class="btn btn-secondary m-btn m-btn--icon w-auto">
              {{$t('change_status')}} ({{props.row.status_name}})
            </button>
          </template>
<!--          <template slot="status" slot-scope="props">-->
<!--            <b-form-checkbox v-if="$can('inventory-monitoring.update')" size="lg" @change="changeStatus(props.row.id, props.row.status)" v-model="props.row.status" :name="'check-button'+props.row.id" switch :key="props.row.id"></b-form-checkbox>-->
<!--          </template>-->
          <template slot="actions" slot-scope="props">
            <v-icon small class="mr-2 text-info" v-b-tooltip.hover :title="$t('edit')" v-if="$can('inventory-monitoring.update')" color="blue darken-2" @click="editItem(props.row)">mdi-pencil</v-icon>
            <v-icon small class="text-danger" v-b-tooltip.hover :title="$t('delete')" v-if="$can('inventory-monitoring.delete')" color="red darken-2" @click="deleteItem(props.row)">mdi-delete</v-icon>

<!--            <button class="btn btn-dark btn-sm ml-3 mr-3" @click="settlementQuantitiesItem(props.row)">{{ $t('settlement_quantities') }}</button>-->

            <button class="btn btn-secondary m-btn m-btn--icon w-auto" v-if="$can('inventory-monitoring.settlement_status')" @click="changeSettlementStatus(props.row.id, props.row.settlement_status)" :key="props.row.id">
              {{$t('inventory_monitoring.settlement_status')}}
              ({{props.row.settlement_status_name}})
            </button>
            <button class="btn btn-secondary m-btn m-btn--icon w-auto" v-else :key="props.row.id">
              {{$t('inventory_monitoring.settlement_status')}}
              ({{props.row.settlement_status_name}})
            </button>
          </template>
        </v-server-table>


        <!--end: Datatable-->
      </div>
    </div>
    <!--end::User-->

    <b-modal ref="settlement-status-modal" size="md" hide-footer :title="$t('change_status')">
      <change-status-form @hide-modal="hideModalSettlement()"
                          :status-list="settlement_status_list"
                          :url="routeSettlementChangeStatus"
                          :current-id="innerId"
                          :current-status="statusId"
                          :ignore-selected-status="settlement_status_ignore[statusId]"
                          @handling-data="getDataAfterSettlementChangeStatus">
      </change-status-form>
    </b-modal>
    <b-modal ref="status-modal" size="md" hide-footer :title="$t('change_status')">
      <change-status-form @hide-modal="hideModal()"
                          :status-list="status_list"
                          :url="routeChangeStatus"
                          :current-id="innerId"
                          :current-status="statusId"
                          @handling-data="getDataAfterChangeStatus">
      </change-status-form>
    </b-modal>

  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import ChangeStatusFrom from "@/view/content/forms/ChangeStatusForm";

export default {
  name: "index-inventory-monitoring",
  components: {'change-status-form': ChangeStatusFrom,},
  data() {
    return {
      mainRoute: 'items/inventory-monitorings',
      subMainRoute: 'items/inventory-monitoring',
      routeSettlementChangeStatus: 'items/inventory-monitoring/settlement-quantities',
      routeChangeStatus: 'items/inventory-monitoring/change-status',
      mainRouteDependency: 'base/dependency',
      showAdvancedSearch: false,
      dataList: [],
      filters: {
        inventory_id: '',
        transaction_code: '',
        transaction_type: '',
        from_date: '',
        to_date: '',
        status: '',
      },
      inventory: null,
      status_list: [],
      inventories: [],

      columns: ['inventory_name', 'monitor_date', 'monitor_time', 'status', 'actions'],
      settlement_status_list: [],
      innerId: null,
      statusId: null,
      // f settlement_status = 1 , display "تأكيد التسوية", hide "التراجع عن التسوية"
      //
      // if settlement_status = 2 , display "التراجع عن التسوية", hide  "تأكيد التسوية"
      //
      // if settlement_status = 3 , hide "التراجع عن التسوية", hide  "تأكيد التسوية"
      settlement_status_ignore: {
        1: [3],
        2: [2],
        3: [2,3],
      },
    }
  },
  computed: {
    json_fields: function () {
      let fields = {};
      fields[this.$t('inventory_monitoring.inventory')] = 'inventory_name';
      fields[this.$t('inventory_monitoring.date')] = 'monitor_date';
      fields[this.$t('inventory_monitoring.time')] = 'monitor_time';
      fields[this.$t('inventory_monitoring.code')] = 'code';
      fields[this.$t('inventory_monitoring.notes')] = 'notes';

      return fields;
    },
    options: function () {
      let that = this;
      return {
        texts: {
          loadingError: that.$t('Something_went_wrong'),
          filter: "",
          limit: that.$t('records'),
          filterBy: that.$t('Filter') + ' {column}',
          count: ' ',
          filterPlaceholder: that.$t('Search_query'),
          loading: that.$t('Loading') + "...",
        },
        headings: {
          inventory_name: that.$t('inventory_monitoring.inventory'),
          monitor_date: that.$t('inventory_monitoring.date'),
          monitor_time: that.$t('inventory_monitoring.time'),
          status: that.$t('status'),
          actions: that.$t('actions'),

        },
        sortable: ['name'],
        filterByColumn: false,
        filterable: false,
        customFilters: [{
          name: 'alphabet',

        }],
        orderBy: {'column': 'monitor_date'},

        alwaysShowPerPageSelect: true,
        perPage: 10,
        pagination: {chunk: 5, dropdown: false},
        skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
        sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
        perPageValues: [5, 10, 15, 25],

        requestFunction(data) {
          let _params = {
            ascending: 0,
            byColumn: data.byColumn,
            limit: data.limit,
            orderBy: data.orderBy,
            page: data.page,
            filter: data.query,
            ...that.filters,
          }
          return ApiService.query(that.mainRoute, {..._params});

        },
        responseAdapter(resp) {
          that.dataList = resp.data.data.data;
          return {
            data: resp.data.data.data,
            count: resp.data.data.total,
          }
        }
      }

    },
  },
  watch: {
    inventory: function (val) {
      if (val) {
        this.filters.inventory_id = val.id;
      } else {
        this.filters.inventory_id = null;
      }
    }
  },
  mounted() {
    // let that = this;
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.Inventory_monitoring")}]);
    this.getSettlementStatus();
    this.getInventories();
    this.getStatusList();
  },
  methods: {
    getFetch() {
      this.$refs.table.refresh();
    },
    openAdvancedSearch() {
      this.showAdvancedSearch = !this.showAdvancedSearch;
    },
    doFilter() {
      this.$refs.table.refresh();
    },
    resetFilter() {
      this.filters.inventory_id = '';
      this.filters.transaction_code = '';
      this.filters.transaction_type = '';
      this.filters.from_date = '';
      this.filters.to_date = '';
      this.filters.status = '';
      this.inventory = null;
      this.$refs.table.refresh();
    },
    actionDelete(item) {
      ApiService.delete(this.mainRoute + "/" + item.id).then((response) => {
        this.getFetch();
        this.$successAlert(response.data.message)
      }).catch((error) => {
        this.$errorAlert(error);
      })
    },
    editItem(item) {
      this.$router.push({name: 'inventory-monitoring.edit', params: {id: item.id}});
    },
    deleteItem(item) {
      this.$confirmAlert('', this.actionDelete, item);
    },


    getInventories() {
      ApiService.get(this.mainRouteDependency + "/inventories").then((response) => {
        this.inventories = response.data.data;
      });
    },

    // actionSettlementQuantities(item) {
    //   ApiService.patch(this.subMainRoute + "/settlement-quantities/" + item.id).then((response) => {
    //     this.getFetch();
    //     this.$successAlert(response.data.message)
    //   }).catch((error) => {
    //     this.$errorAlert(error);
    //   })
    // },
    // settlementQuantitiesItem(item) {
    //   this.$confirmAlert(this.$t('do_you_agree_to_the_settlement_process'), this.actionSettlementQuantities, item);
    // },


    getSettlementStatus() {
      ApiService.get(this.mainRouteDependency + "/settlement-status").then((response) => {
        this.settlement_status_list = response.data.data;
      });
    },

    showModal() {
      this.$refs['status-modal'].show()
    },
    hideModal() {
      this.$refs['status-modal'].hide();
    },
    changeStatus(id, status) {
      this.innerId = id;
      this.statusId = status;
      this.showModal();
    },
    getDataAfterChangeStatus() {
      this.innerId = null;
      this.statusId = null;
      this.doFilter();
    },

    showModalSettlement() {
      this.$refs['settlement-status-modal'].show()
    },
    hideModalSettlement() {
      this.$refs['settlement-status-modal'].hide();
    },
    changeSettlementStatus(id, status) {
      this.innerId = id;
      this.statusId = status;
      this.showModalSettlement();
    },
    getDataAfterSettlementChangeStatus() {
      this.innerId = null;
      this.statusId = null;
      this.doFilter();
    },
    getStatusList() {
      ApiService.get(this.mainRouteDependency + "/inventory_monitoring_status").then((response) => {
        this.status_list = response.data.data;
      });
    },
  },
};
</script>


