/**
 * PurchaseInvoice
 * PurchaseRefund
 * SalesInvoice
 * SalesCredit
 * SalesInvoiceRefund
 * GeneralSales
 * GeneralSalesInvoiceRefund
 *
 * **** Types not handled yet ****
 *
 * SalesDebit
 * PaymentSales
 * Income
 * Expenses
 * ExpensesIncomeTransactions
 * CustomerValidation
 * SupplierChecking
 * BankOpenBalance
 * TreasuryOpenBalance
 * ItemOpenStock
 * CustomerOpenBalance
 * SupplierOpenBalance
 *
 * @param type
 * @param id
 * @returns {string}
 */
const getPageLinkDependentOnType = (type, id) => {
    let _link = '';
    switch (type) {
        case 'PurchaseInvoice':
            _link = '/purchases/purchases-invoices/edit'
            break;
        case 'PurchaseRefund':
            _link = '/purchases/purchases-refunds/edit'
            break;

        case 'SalesInvoice':
            _link = '/sales/sales_invoices/edit'
            break;
        case 'SalesCredit':
            _link = '/sales/credits/edit'
            break;
        case 'SalesInvoiceRefund':
            _link = '/sales/sales_refund/edit'
            break;

        case 'GeneralSales':
            _link = '/sales/general_sales/edit'
            break;
        case 'GeneralSalesInvoiceRefund':
            _link = '/sales/general_sales_refund/edit'
            break;

    }
    if (_link){
        _link = _link+'/'+id;
    }
    return _link;
}

export default {getPageLinkDependentOnType}