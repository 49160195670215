<template>
  <div>
    <!--begin::customer-->
    <div>
      <!--      <div class="card-header flex-wrap border-0 pt-6 pb-0">-->
      <!--        <div class="card-title m-0">-->
      <h3 class="card-label">
        {{ $t('general_sales_quotations.sales_quotations') }}
        <span class="text-muted pt-2 font-size-sm d-block"></span>
      </h3>
      <!--        </div>-->
      <!--      </div>-->


      <div class="row">
        <div class="col-md-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-12 mb-5">
                <label>{{ $t('general_sales_quotations.title') }}<span class="text-danger">*</span></label>
                <input type="text" v-model="data.title" class="form-control" :class="validation && validation.title ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.title" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.title[0] }}
                            </span>
              </div>
              <div class="col-lg-12 mb-5">
                <label>{{ $t('general_sales_quotations.customer') }}<span class="text-danger">*</span></label>
                <div class="form-group row">
                  <div class="col-sm-8">
                    <multiselect
                        id="customer_id"
                        v-model="customer"
                        :placeholder="$t('general_sales_quotations.customer')"
                        label="fullname"
                        track-by="id"
                        :options="customers"
                        :multiple="false"
                        :taggable="false"
                        :show-labels="false"
                        :show-no-options="false"
                        :show-no-results="false"
                        @search-change="getCustomers($event)">
                    </multiselect>
                    <span class="form-text text-muted" style="text-align:end">{{ $t('type_at_least_three_letters') + ' ' + $t('general_sales_quotations.customer') }}.</span>
                    <span v-if="validation && validation.customer_id" class="fv-plugins-message-container invalid-feedback">
                                                {{ validation.customer_id[0] }}
                                            </span>
                  </div>
                  <div class="col-sm-4">
                    <button class="btn btn-primary" @click="showModal">{{ $t('add_new') }}</button>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <b-card no-body v-if="customer" class="mt-5">
            <b-card-header header-tab="header" class="p-1" role="tab">
              <b-button block v-b-toggle.accordion-50 variant="link" class="text-left font-weight-bold text-dark pl-8 pr-8 fs-102 collapsed-with-icon">
                {{ $t('customer_data_details') }}
              </b-button>
            </b-card-header>

            <b-collapse id="accordion-50" accordion="my-accordion-1" role="tabpanel">
              <b-card-body class="row">
                <div class="col-lg-12 mb-5">
                  <p><b>{{ $t('name') }}: </b> {{ customer.fullname }}</p>
                  <p><b>{{ $t('mobile') }}: </b> {{ customer.mobile }}</p>
                  <p><b>{{ $t('email') }}: </b> {{ customer.email }}</p>
                  <p><b>{{ $t('tax_no') }}: </b> {{ customer.tax_register }}</p>
                </div>
                <div class="col-md-12 mb-5">
                  <div class="bg-FFB803 repeater p-3 d-flex justify-content-between">
                    <h6 class="my-auto text-white">{{ $t('customers.balances') }}</h6>
                  </div>
                  <table class="table table-row-bordered">
                    <thead>
                    <tr>
                      <th>{{ $t('customers.balance') }}</th>
                      <th>{{ $t('customers.debit') }}</th>
                      <th>{{ $t('customers.currency') }}</th>
                      <th>{{ $t('customers.last_validated_date') }}</th>
                      <th></th>
                    </tr>

                    </thead>
                    <tbody>
                    <template v-if="customer.balances && customer.balances.length > 0">
                      <tr v-for="(row, index) in customer.balances" :key="index">
                        <td>{{ row.credit }}</td>
                        <td>{{ row.debit }}</td>
                        <td>{{ row.currency_name }}</td>
                        <td>{{ row.last_validated_date }}</td>
                      </tr>
                    </template>
                    <tr v-else>
                      <td colspan="4">{{ $t('no_balances') }}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
        <div class="col-md-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-6 mb-5">
                <label>{{ $t('general_sales_quotations.sales_quotation_code') }}</label>
                <input type="text" v-model="data.sales_quotation_code" class="form-control" :class="validation && validation.sales_quotation_code ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.sales_quotation_code" class="fv-plugins-message-container invalid-feedback">
                                    {{ validation.sales_quotation_code[0] }}
                                </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('general_sales_quotations.sales_quotation_date') }}<span class="text-danger">*</span></label>
                <input type="date" v-model="data.sales_quotation_date" class="form-control" :class="validation && validation.sales_quotation_date ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.sales_quotation_date" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.sales_quotation_date[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('general_sales_quotations.sales_due_date') }}<span class="text-danger">*</span></label>
                <input type="date" v-model="data.sales_due_date" class="form-control" :class="validation && validation.sales_due_date ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.sales_due_date" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.sales_due_date[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('general_sales_quotations.branch') }}</label>
                <!-- <select name="" id="branch" v-model="data.branch_id" type="text" class="custom-select" :class="validation && validation.branch_id ? 'is-invalid' : ''">
                    <option v-for="(row, index) in branches" :value="row.id" :key="index">{{ row.name }}</option>
                </select> -->
                <div class="input-group mb-3">
                  <multiselect v-model="branch"
                               :placeholder="$t('general_sales_quotations.branch')"
                               label="name"
                               track-by="id"
                               :options="branches"
                               :multiple="false"
                               :taggable="false"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false">
                  </multiselect>
                  <div class="input-group-prepend">
                    <a class="btn btn-primary" href="/settings/branches/create" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                  </div>
                </div>
                <span v-if="validation && validation.branch_id" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.branch_id[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('general_sales_quotations.currency') }}</label>
                <div class="input-group mb-3">
                  <select name="" id="currency_id" v-model="data.currency_id" class="custom-select" :class="validation && validation.currency_id ? 'is-invalid' : ''">
                    <option v-for="row in currencies" :value="row.id" :key="row.id">{{ row.name }}</option>
                  </select>
                  <div class="input-group-prepend">
                    <a class="btn btn-primary" href="/settings/currencies" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                  </div>
                </div>
                <span v-if="validation && validation.currency_id" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.currency_id[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('exchange_rate') }}</label>
                <input type="number" min="0" v-model="data.exchange_rate" class="form-control" :class="validation && validation.exchange_rate ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.exchange_rate" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.exchange_rate[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('status') }}</label>
                <select name="" id="f_status" v-model="data.status" type="text" class="custom-select" :class="validation && validation.status ? 'is-invalid' : ''">
                  <option v-for="(row, index) in status_list" :value="row.id" :key="index">{{ row.title }}</option>
                </select>
                <span v-if="validation && validation.status" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.status[0] }}
                                    </span>
              </div>

              <div class="col-lg-6 mb-5">
                <label>{{ $t('is_apply_taxes_invoice') }}:</label>
                <b-form-checkbox size="lg" :disabled="true" v-model="data.is_apply_taxes_quotation" name="check-button" switch></b-form-checkbox>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div class="row mt-5">

        <div class="col-md-12">
          <div class="bg-f3f3f3 d-flex justify-content-between mt-2 p-2">
            <button type="button" class="btn btn-primary" @click="addItemRowToList"><i class="fa fa-plus-square mb-1"></i> <span>{{ $t('add_more') }}</span></button>
          </div>
        </div>
        <div class="col-md-12">
          <div class="table-responsive bg-white pb-5">
            <table class="table table-row-bordered table-custom-padding" @keyup.alt.enter="addItemRowToList" @keyup.alt.46="removeItemRowFromList">
              <thead>
              <tr>
                <th width="250px">{{ $t('sales_invoices.description') }}</th>
                <th width="120px">{{ $t('general_sales_quotations.unit_price') }}</th>
                <th width="120px">{{ $t('sales_invoices.unit') }}</th>
                <th width="80px">{{ $t('general_sales_quotations.qty') }}</th>
                <th width="140px" v-if="isApplyTaxesInvoice">{{ $t('sales_invoices.tax') }}</th>
                <th width="120px">{{ $t('discount') }}</th>
                <th v-if="isApplyTaxesInvoice" width="120px">{{ $t('sales_invoices.amount_tax') }}</th>
                <!--                <th>{{ $t('general_sales_quotations.subtotal') }}</th>-->
                <th width="80px">{{ $t('sales_invoices.total_amount_before_discount') }}</th>
                <th width="80px">{{ $t('sales_invoices.total_amount_after_discount') }}</th>
                <th width="10px"></th>
              </tr>

              </thead>
              <tbody>
              <tr v-for="(row, index) in items_list" :key="index">
                <td>
                  <!-- <input v-model="row.description" type="text" class="form-control"> -->
                  <textarea v-model="row.description" class="form-control" rows="3" cols="15"></textarea>
                </td>
                <td><input v-model="row.unit_price" @input="setValue(index)" min="0" type="number" class="form-control"></td>
                <td>
                  <multiselect v-model="row.unit" v-b-tooltip.hover.leftbottom :title="$t('type_at_least_three_letters') + ' ' + $t('sales_invoices.unit')"
                               :placeholder="$t('sales_invoices.unit')"
                               label="name"
                               track-by="id"
                               :options="units"
                               :multiple="false"
                               :taggable="false"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false"
                               @input="setValue(index)"
                               :class="validation && validation[`items_list.${index}.unit`] ? 'is-invalid' : ''"
                               >
                  </multiselect>
                  <span v-if="validation && validation[`items_list.${index}.unit`]" class="fv-plugins-message-container invalid-feedback">
                            {{ validation[`items_list.${index}.unit`][0] }}
                          </span>

                </td>
                <td><input v-model="row.qty" @input="setValue(index)" min="0" type="number" class="form-control"></td>
                <td v-if="isApplyTaxesInvoice">
                  <!--                  <template>-->
                  <!--                    <div class="d-flex mb-2" v-for="(_row, _index) in row.tax_applied">-->
                  <multiselect v-model="row.tax_applied" v-b-tooltip.hover.leftbottom :title="$t('type_at_least_three_letters') + ' ' + $t('sales_invoices.tax')"
                               :placeholder="$t('sales_invoices.tax')"
                               label="name"
                               track-by="id"
                               :options="taxes"
                               :multiple="true"
                               :taggable="false"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false"
                               @input="changeRepeaterValue(index)">
                    <!--                                   @search-change="getTaxes($event)">-->
                  </multiselect>
                  <!--                      <a href="javascript:;" @click="removeTaxFromOneRow(index, _index)">-->
                  <!--                        <i class="fa fa-trash text-danger m-2"></i>-->
                  <!--                      </a>-->

                  <!--                      <a v-if="_index ==  row.tax_applied.length - 1" href="javascript:;" @click="addMoreTaxesInOneRow(index)">-->
                  <!--                        <i class="fa fa-plus text-primary m-2"></i>-->
                  <!--                      </a>-->

                  <!--                    </div>-->

                  <!--                  </template> -->
                </td>
                <td>
                  <div class="d-flex">
                    <select name="" id="discount_type" @change="changeDiscountInRepeater(index)" v-model="row.discount_type" class="custom-select" style="width: 83px;">
                      <option v-for="row in discount_types" :value="row.id" :key="row.id">{{ row.name }}</option>
                    </select>
                    <input v-model="row.discount_value" @input="changeDiscountInRepeater(index)" type="number" class="form-control">

                  </div>
                </td>
                <td v-if="isApplyTaxesInvoice">{{ row.amount_tax }}</td>
                <!--                <td>{{ row.subtotal }}</td>-->
                <td>{{ row.subtotal_before_discount && row.subtotal_before_discount > 0 ? parseToShortFloat(row.subtotal_before_discount) : 0 }}</td>
                <td>{{ row.subtotal_after_discount && row.subtotal_after_discount > 0 ? parseToShortFloat(row.subtotal_after_discount) : 0 }}</td>

                <td>
                  <v-icon style="color: #dc3545;" small v-if="items_list.length > 1" @click="removeItemRowFromList(index)">mdi-delete</v-icon>
                </td>
              </tr>
              </tbody>
              <tfoot>

              <tr>
                <td :colspan="isApplyTaxesInvoice ? '6' : '4'"></td>
                <th colspan="2">{{ $t('sales_invoices.total_amount_before_discount') }}</th>
                <th colspan="2"><span>{{ data.total_amount_before_discount && data.total_amount_before_discount > 0 ? parseToShortFloat(data.total_amount_before_discount) : '0' }} {{ currency_code }}</span></th>
              </tr>
              <tr>
                <td class="border-0" :colspan="isApplyTaxesInvoice ? '6' : '4'"></td>
                <th colspan="2">{{ $t('sales_invoices.total_amount_after_discount') }}</th>
                <th colspan="2"><span>{{ data.total_amount_after_discount && data.total_amount_after_discount > 0 ? parseToShortFloat(data.total_amount_after_discount) : '0' }} {{ currency_code }}</span></th>
              </tr>
              <tr>
                <td class="border-0" :colspan="isApplyTaxesInvoice ? '6' : '4'"></td>
                <th colspan="2">{{ $t('sales_invoices.total_discount') }}</th>
                <th colspan="2"><span>{{ data.invoice_total_discount ? data.invoice_total_discount : '0' }} {{ currency_code }}</span></th>
              </tr>
              <tr>
                <td class="border-0" :colspan="isApplyTaxesInvoice ? '6' : '4'"></td>
                <th colspan="2">{{ $t('sales_invoices.total_after_shipping') }}</th>
                <th colspan="2"><span>{{ data.shipping_fees ? data.shipping_fees : '0' }} {{ currency_code }}</span></th>
              </tr>

              <tr v-if="isApplyTaxesInvoice">
                <td class="border-0" :colspan="isApplyTaxesInvoice ? '6' : '4'"></td>
                <th colspan="2">{{ $t('sales_invoices.total_tax') }}</th>
                <th colspan="2"><span>{{ data.invoice_total_taxes ? data.invoice_total_taxes : '0' }} {{ currency_code }}</span></th>
              </tr>

              <tr>
                <td class="border-0" :colspan="isApplyTaxesInvoice? '6': '4'"></td>
                <th colspan="2">{{ $t('general_sales_quotations.total') }}</th>
                <th colspan="2"><span>{{ data.total ? data.total : '0' }} {{ currency_code }}</span></th>
              </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>

      <div class="mt-5">
        <b-tabs content-class="mt-3" class="nav-custom-link">
<!--          <b-tab :title="$t('discount_and_settlement')">-->
<!--            <div class="card card-custom">-->
<!--              <div class="card-body row">-->
<!--                <div class="col-lg-3 mb-5">-->
<!--                  <label>{{ $t('sales_invoices.discount_type') }}</label>-->
<!--                  <select name="" id="discount_types" v-model="data.discount_type" @input="reCalcGeneralDiscount" class="custom-select" :class="validation && validation.discount_type ? 'is-invalid' : ''">-->
<!--                    <option v-for="row in discount_types" :value="row.id" :key="row.id">{{ row.name }}</option>-->
<!--                  </select>-->
<!--                  <span v-if="validation && validation.discount_type" class="fv-plugins-message-container invalid-feedback">-->
<!--                          {{ validation.discount_type[0] }}-->
<!--                      </span>-->
<!--                </div>-->
<!--                <div class="col-lg-3 mb-5">-->
<!--                  <label>{{ $t('sales_invoices.discount_value') }}</label>-->
<!--                  <input type="number" v-model="data.discount_value" @input="reCalcGeneralDiscount" class="form-control" :class="validation && validation.discount_value ? 'is-invalid' : ''"/>-->
<!--                  <span v-if="validation && validation.discount_value" class="fv-plugins-message-container invalid-feedback">-->
<!--                                        {{ validation.discount_value[0] }}-->
<!--                                    </span>-->
<!--                  <span v-if="is_show_validation_dis_value" class="fv-plugins-message-container invalid-feedback">-->
<!--                                        {{ $t('discount_must_be_less_than_or_equal_total_invoice') }}-->
<!--                                    </span>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </b-tab>-->
          <b-tab :title="$t('delivery_information')">
            <div class="card card-custom">
              <div class="card-body row">
                <div class="col-lg-3 mb-5">
                  <label>{{ $t('general_sales_quotations.shipping_with') }}</label>
                  <select name="" id="currency_id" v-model="data.shipping_with" @input="onChangeShipping"  class="custom-select" :class="validation && validation.shipping_with ? 'is-invalid' : ''">
                    <option v-for="row in shipping_with_list" :value="row.id" :key="row.id">{{ row.title }}</option>
                  </select>

                  <span v-if="validation && validation.shipping_with" class="fv-plugins-message-container invalid-feedback">
                  {{ validation.shipping_with[0] }}
              </span>
                </div>
                <div class="col-lg-3 mb-5" v-if="data.shipping_with == 1">
                  <label>{{ $t('general_sales_quotations.shipping_fees') }}</label>
                  <input type="number" min="0" v-model="data.shipping_fees" @input="onChangeShipping"  class="form-control" :class="validation && validation.shipping_fees ? 'is-invalid' : ''"/>
                  <span v-if="validation && validation.shipping_fees" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.shipping_fees[0] }}
                            </span>
                </div>
              </div>
            </div>
          </b-tab>
          <b-tab :title="$t('notes')">
            <div class="card card-custom">
              <div class="card-body row">
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('general_sales_quotations.attachment') }}</label>
                  <div class="form-group">
                    <upload-file
                        @file="listenerAttachment"
                        :inner-id="'attachment'"
                        :placeholder="$t('general_sales_quotations.attachment')"
                        :upload="dir_upload"
                        :start-link="'base'"
                        v-bind:valueprop="{name:'attachment',path:attachment_url}" v-if="reloadUploadAttachment">
                    </upload-file>
                  </div>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('general_sales_quotations.notes') }}</label>
<!--                  <textarea name="" id="notes" v-model="data.notes" class="form-control" :class="validation && validation.notes ? 'is-invalid' : ''"></textarea>-->
                  <EditorTextarea ref="editor" :value-data.sync="data.notes" :dir-upload="'sales'"></EditorTextarea>
                  <span v-if="validation && validation.notes" class="fv-plugins-message-container invalid-feedback">
                                    {{ validation.notes[0] }}
                                </span>
                </div>
              </div>
            </div>
          </b-tab>

        </b-tabs>
      </div>

      <div class="pl-0 pr-0">
        <div class="row">
          <div class="col-lg-6 mt-10">
            <button type="reset" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
            <button type="reset" class="btn btn-secondary">{{ $t('cancel') }}</button>
          </div>
        </div>
      </div>
    </div>
    <!--end::customer-->
    <b-modal ref="modal" size="lg" hide-footer :title="$t('customers.add_new_customer')">
      <customer-form @hide-modal="hideModal()" @handling-data="getDataAfterCreateNewCustomer"></customer-form>
    </b-modal>


    <b-modal ref="modalItem" size="lg" hide-footer :title="$t('items.add_new_item')">
      <item-form @hide-modal="hideItemModal()" @handling-data="getDataAfterCreateNewItem"></item-form>
    </b-modal>
  </div>
</template>
<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import CustomerForm from "@/view/content/forms/CustomerForm";
import ItemForm from "@/view/content/forms/ItemForm";
import {mapGetters, mapState} from "vuex";
import dataTypeNumbers from "@/core/config/mix/dataTypeNumbers";
import timeZoneStructure from "@/core/config/mix/timeZoneStructure";
import EditorTextarea from "@/view/content/widgets/inputs/EditorTextarea.vue";

export default {
  name: "form-general-sales-quotation",
  components: {EditorTextarea, 'customer-form': CustomerForm, 'item-form': ItemForm},
  data() {
    return {
      mainRoute: 'sales/general_sales_quotations',
      mainRouteQuotationRequests: 'sales/general_sales_quotations',
      mainRouteDependency: 'base/dependency',
      mainTaxSettingsRoute: 'sales/tax_setting_sales',
      dir_upload: 'sales',

      data: {
        title: null,
        sales_quotation_code: null,
        attachment: null,
        status: 1,
        sales_quotation_date: null,
        customer_id: null,
        branch_id: null,
        sales_due_date: null,
        notes: null,

        currency_id: null,
        is_general: 1,
        shipping_with: null,
        shipping_fees: null,
        exchange_rate: null,
        is_apply_taxes_quotation: false,
        total: 0,

        invoice_total_taxes: 0,

        invoice_total_discount: 0,
        total_amount_before_discount: 0,
        total_amount_after_discount: 0,
        discount_type: 1,
        discount_value: null,

        invoice_total_before_taxes: 0,
        invoice_total_after_taxes: 0,

      },
      idEditing: this.$route.params.id,
      isEditing: false,
      reloadUploadAttachment: true,
      attachment_url: '',

      status_list: [],
      shipping_with_list: [
        {id: 1, title: this.$t('general_sales_quotations.customer')},
        {id: 2, title: this.$t('general_sales_quotations.company')},
      ],
      discount_types: [
        {id: 1, name: '-'},
        {id: 2, name: '%'},
        {id: 3, name: this.$t('discount_types.value')},
      ],
      validation: null,
      items_list_form: {id: null, item: null, qty: null, unit_price: null, subtotal: 0, tax: null, amount_tax: 0,
        tax_applied: [], unit: null, discount_type: 1, discount_value: null, subtotal_before_discount: null,
        subtotal_after_discount: null, subtotal_before_tax: null, subtotal_after_tax: null, amount_excluded_tax: 0, amount_included_tax: 0,},
      items_list: [],
      items: [],
      branches: [],
      customers: [],
      customer: null,
      branch: null,
      currency: null,
      currencies: [],
      is_allow_tax_calc: false,
      taxes: [],
      currency_code: null,
      tax_default: null,
      default_unit: [],
      units: [],
      is_show_validation_dis_value: false,
    };
  },
  computed: {
    ...mapState({user_personal_info: state => state.profile.user_personal_info}),
    ...mapGetters(["currentUserPersonalInfo"]),
    isApplyTaxesInvoice() {
      let _status = this.data.is_apply_taxes_quotation;
      // if (_status){
      //   this.setTaxForEveryItem()
      // }else{
      //   this.removeTaxFromEveryItem()
      // }
      // this.calcItemListForTotal();
      return _status;
    },
  },
  watch: {
    "data.currency_id": function (val) {
      if (val) {
        this.getExchangeRate(val);
        let _currency = this.currencies.find((row) => row.id == val);
        if (_currency) {
          this.currency_code = _currency.code;
        } else {
          this.currency_code = null;
        }
      } else {
        this.data.exchange_rate = null;
        this.currency_code = null;
      }
    },
    customer: function (val) {
      if (val) {
        this.data.customer_id = val.id;
      }
    },
    branch: function (val) {
      if (val) {
        this.data.branch_id = val.id;
      }
    },
    'data.shipping_with': function (val) {
      if (val != 1) {
        this.data.shipping_fees = null;
      }
    },
  },
  methods: {
    ...dataTypeNumbers,
    ...timeZoneStructure,
    onChangeShipping: _.debounce(function (){
      if (this.data.shipping_with != 1) {
        this.data.shipping_fees = null;
      }
      this.calcItemListForTotal();
    }, 100),
    changeRepeaterValue(index) {
      this.setValue(index);
      this.reCalcGeneralDiscount();
    },
    setValue(index) {
      this.zeroForNegative();
      let _promise = this.getItemTax(index);
      Promise.all([_promise]).then(() => {
        // let _qty = this.items_list[index].qty ? parseFloat(this.items_list[index].qty) : 0;
        // if (_qty < 0) {
        //   this.items_list[index].qty = null;
        // }
        // let _price = this.items_list[index].unit_price ? parseFloat(this.items_list[index].unit_price) : 0;
        // if (_price < 0) {
        //   this.items_list[index].unit_price = null;
        // }

        let _subtotal = (this.items_list[index].unit_price ? this.items_list[index].unit_price : 0) * (this.items_list[index].qty ? this.items_list[index].qty : 0);
        this.items_list[index].subtotal_before_tax = (_subtotal - parseFloat(this.items_list[index].amount_included_tax)).toFixed(2);
        this.items_list[index].subtotal_after_tax = (_subtotal + parseFloat(this.items_list[index].amount_excluded_tax)).toFixed(2);

        let _discount = 0;
        if (this.items_list[index].discount_type == 1 || !this.items_list[index].discount_type) {
          _discount = 0;
        } else {
          _discount = ((this.items_list[index].discount_type == 2 && this.items_list[index].discount_value) ? (parseFloat(this.items_list[index].discount_value) / 100) * _subtotal : (this.items_list[index].discount_value ? parseFloat(this.items_list[index].discount_value) : 0));
        }

        /**
         *
         * calc data subtotal before discount  = unit price * quantity
         * calc data subtotal after discount  = (unit price * quantity) - discount
         */
        this.items_list[index].subtotal_before_discount = _subtotal;
        this.items_list[index].subtotal_after_discount = _subtotal - _discount;

        this.items_list[index].subtotal_before_discount = this.items_list[index].subtotal_before_discount ? (this.items_list[index].subtotal_before_discount).toFixed(2) : 0;
        this.items_list[index].subtotal_after_discount = this.items_list[index].subtotal_after_discount ? (this.items_list[index].subtotal_after_discount).toFixed(2) : 0;


        this.items_list[index].subtotal = (_subtotal).toFixed(2);

        this.calcItemListForTotal();
      })
    },
    zeroForNegative() {
      this.items_list = this.items_list.map((row) => {
        if (String(row.unit_price).length > 12) {
          row.unit_price = String(row.unit_price).slice(0, 12);
        }
        if (String(row.qty).length > 9) {
          row.qty = String(row.qty).slice(0, 9);
        }
        if(String(row.discount_value).length > 9){
          row.discount_value = String(row.discount_value).slice(0, 9);
        }

        if (isNaN(row.unit_price)) {
          row.unit_price = 0;
        } else if (parseFloat(row.unit_price) < 0) {
          row.unit_price = 0;
        }
        if (isNaN(row.qty)) {
          row.qty = 0;
        } else if (parseFloat(row.qty) < 0) {
          row.qty = 0;
        }

        if ((row.discount_type == 1 || !row.discount_type) && (row.discount_value != 0 && row.discount_value != null)) {
          row.discount_type = 2;
        }
        if (isNaN(row.discount_value)) {
          row.discount_value = 0;
        }else if (parseFloat(row.discount_value) < 0) {
          row.discount_value = 0;
        }

        if (row.discount_type == 2) {
          if (parseFloat(row.discount_value) > 100) {
            row.discount_value = 100;
          }
        } else if (row.discount_type == 3 && parseFloat(row.discount_value) > 0 && parseFloat(row.subtotal_before_discount) > 0) {
          if (parseFloat(row.discount_value) > parseFloat(row.subtotal_before_discount)) {
            row.discount_value = row.subtotal_before_discount;
          }
        }

        return row;

      });
    },

    calcItemListForTotal() {
      if (this.items_list && this.items_list.length > 0) {

        // this.data.invoice_total_taxes = this.$_.sumBy(this.items_list, (row) => {
        //   return row.amount_excluded_tax ? parseFloat(row.amount_excluded_tax) : 0;
        // });

        /**
         * sum amount before discount
         */
        this.data.total_amount_before_discount = this.$_.sumBy(this.items_list, (row) => {
          return (row.subtotal_before_discount ? parseFloat(row.subtotal_before_discount) : 0);
        });
        /**
         * sum amount after discount
         */
        this.data.total_amount_after_discount = this.$_.sumBy(this.items_list, (row) => {
          return (row.subtotal_after_discount ? parseFloat(row.subtotal_after_discount) : 0);
        });
        let _discount_value = this.$_.sumBy(this.items_list, (row) => {
          if (!row.discount_type || row.discount_type == 1) {
            return 0;
          }
          // let _subtotal = row.subtotal_before_tax ? parseFloat(row.subtotal_before_tax) : 0;
          let _subtotal = ((row.unit_price ? row.unit_price : 0) * (row.qty ? row.qty : 0));
          return ((row.discount_type == 2 && row.discount_value) ? (parseFloat(row.discount_value) / 100) * _subtotal : (row.discount_value ? parseFloat(row.discount_value) : 0));

        });

        this.data.invoice_total_discount = parseFloat(_discount_value).toFixed(2);

        this.data.invoice_total_before_taxes = this.$_.sumBy(this.items_list, (row) => {
          return row.subtotal_before_tax ? parseFloat(row.subtotal_before_tax) : 0;
        });

        this.data.invoice_total_after_taxes = this.$_.sumBy(this.items_list, (row) => {
          return row.subtotal_after_tax ? parseFloat(row.subtotal_after_tax) : 0;
        });

        let _amount_excluded_tax = this.$_.sumBy(this.items_list, (row) => {
          return row.amount_excluded_tax ? parseFloat(row.amount_excluded_tax) : 0;
        });

        this.data.invoice_total_taxes = this.$_.sumBy(this.items_list, (row) => {
          return row.amount_tax ? parseFloat(row.amount_tax) : 0;
        });

        // this.data.total = this.$_.sumBy(this.items_list, (row) => {
        //   return row.subtotal ? parseFloat(row.subtotal) : 0;
        // });

        let _shipping_fees = this.data.shipping_fees ? parseFloat(this.data.shipping_fees) : 0;
        // this.data.total = this.data.total + _amount_excluded_tax;
        this.data.total = (this.data.total_amount_before_discount + parseFloat(_shipping_fees) - parseFloat(this.data.invoice_total_discount) + parseFloat(_amount_excluded_tax));

        this.data.invoice_total_taxes = this.data.invoice_total_taxes ? this.data.invoice_total_taxes.toFixed(2) : 0;
        this.data.total_amount_before_discount = this.data.total_amount_before_discount ? this.data.total_amount_before_discount.toFixed(2) : 0;
        this.data.total_amount_after_discount = this.data.total_amount_after_discount ? this.data.total_amount_after_discount.toFixed(2) : 0;
        this.data.invoice_total_before_taxes = this.data.invoice_subtotal ? this.data.invoice_total_before_taxes.toFixed(2) : 0;
        this.data.invoice_total_after_taxes = this.data.invoice_subtotal ? this.data.invoice_total_after_taxes.toFixed(2) : 0;
        this.data.total = this.data.total ? (this.data.total).toFixed(2) : 0;
      }
    },
    //recalculation discount for general
    reCalcGeneralDiscount: _.debounce(function () {
      this.calcItemListForTotal();
      this.is_show_validation_dis_value = false;
      let _dis_type = this.data.discount_type;
      let _all_dis_value = this.data.discount_value ? parseFloat(this.data.discount_value) : 0;
      let _invoice_total = this.data.total ? parseFloat(this.data.total) : 0;
      let _total_amount_before_discount = this.data.total_amount_before_discount ? parseFloat(this.data.total_amount_before_discount) : 0;
      let _dis_value = this.data.discount_value ? parseFloat(this.data.discount_value) : 0;
      if (_dis_value <= 0) {
        return false;
      }
      if (_all_dis_value > _invoice_total && _dis_type == 3) {
        this.is_show_validation_dis_value = true;
        return false;
      } else if (_dis_value > 100 && _dis_type == 2) {
        this.is_show_validation_dis_value = true;
        this.data.discount_value = 100;
        _dis_value = 100;
        return false;
      }
      this.items_list = this.items_list.map((row, index) => {
        row.discount_type = _dis_type;
        if (_dis_type == 1) {
          row.discount_value = 0;
        } else if (_dis_type == 3) {
          let _subtotal_before_discount = row.subtotal_before_discount ? parseFloat(row.subtotal_before_discount) : 0;
          let _dis = parseFloat((_subtotal_before_discount / _total_amount_before_discount) * parseFloat(_dis_value)).toFixed(2);
          if (_subtotal_before_discount){
            row.discount_value = parseFloat(_dis).toFixed(2);
          }
          // row.discount_value = _dis_value ? parseFloat((parseFloat(_dis_value)).toFixed(2)) : 0;
        } else {
          row.discount_value = _dis_value ? _dis_value : 0;
        }

        this.setValue(index);
        return row;
      });

      this.calcItemListForTotal();
    }, 100),
    save() {
      if (this.isEditing) {
        this.update();
      } else {
        this.create();
      }
    },

    create() {
      let _items_list = [...this.items_list];
      _items_list = _items_list.filter((row) => (parseFloat(row.qty) > 0 && parseFloat(row.unit_price) > 0));


      ApiService.post(`${this.mainRoute}`, {
        items_list: _items_list,
        ...this.data,
      })
          .then((response) => {
            this.validation = null;
            this.$successAlert(response.data.message);
            this.$router.push({name: 'general-sales-quotations.index'});
          })
          .catch((error) => {
            this.$errorAlert(error);
            this.validation = error.response ? error.response.data.errors : null;
          });
    },


    update() {
      let _items_list = [...this.items_list];
      _items_list = _items_list.filter((row) => (parseFloat(row.qty) > 0 && parseFloat(row.unit_price) > 0));

      ApiService.put(`${this.mainRoute}/${this.$route.params.id}`, {
        items_list: _items_list,
        ...this.data,
      })
          .then((response) => {
            this.validation = null;
            this.$router.push({name: 'general-sales-quotations.index'});
            this.$successAlert(response.data.message);
          })
          .catch((error) => {
            this.$errorAlert(error);
            this.validation = error.response ? error.response.data.errors : null;
          });
    },

    async getData() {
      this.reloadUploadAttachment = false;
      ApiService.get(`${this.mainRoute}/${this.$route.params.id}`).then((response) => {
        this.isEditing = true;
        this.data.title = response.data.data.title;
        this.data.sales_quotation_code = response.data.data.sales_quotation_code;
        this.data.attachment = response.data.data.attachment;
        this.data.status = response.data.data.status;
        this.data.sales_quotation_date = response.data.data.sales_quotation_date;
        this.data.customer_id = response.data.data.customer_id;
        this.data.branch_id = response.data.data.branch_id;
        this.data.sales_due_date = response.data.data.sales_due_date;
        this.data.notes = response.data.data.notes;
        this.data.invoice_total_taxes = response.data.data.invoice_total_taxes;
        this.data.total = response.data.data.total;
        this.data.invoice_total_discount = response.data.data.invoice_total_discount;
        this.data.total_amount_before_discount = response.data.data.total_amount_before_discount;
        this.data.total_amount_after_discount = response.data.data.total_amount_after_discount;
        this.data.discount_type = response.data.data.discount_type;
        this.data.discount_value = response.data.data.discount_value;
        this.data.invoice_total_before_taxes = response.data.data.invoice_total_before_taxes ? response.data.data.invoice_total_before_taxes : 0;
        this.data.invoice_total_after_taxes = response.data.data.invoice_total_after_taxes ? response.data.data.invoice_total_after_taxes : 0;
        // this.data.total_out_tax = response.data.data.total_out_tax ? response.data.data.total_out_tax : 0;

        this.data.is_apply_taxes_quotation = response.data.data.is_apply_taxes_quotation;

        this.customer = response.data.data.customer;
        this.branch = response.data.data.branch;

        this.data.currency_id = response.data.data.currency_id;
        this.data.shipping_with = response.data.data.shipping_with;
        this.data.shipping_fees = response.data.data.shipping_fees;
        this.data.exchange_rate = response.data.data.exchange_rate;

        this.attachment_url = response.data.data.attachment_url;
        this.items_list = response.data.data.items_list;

        if (response.data.data.items_list && response.data.data.items_list.length <= 0) {
          this.addItemRowToList();
        }
        this.reloadUploadAttachment = true;
      });
    },

    async getDataForQuotationRequests() {
      this.reloadUploadAttachment = false;
      if (this.$route.params.quotation_requests_id)
        ApiService.get(`${this.mainRouteQuotationRequests}/${this.$route.params.quotation_requests_id}`).then((response) => {
          this.data.title = response.data.data.title;
          this.data.attachment = response.data.data.attachment;
          this.data.sales_quotation_date = response.data.data.sales_quotation_request_date;
          this.data.customer_id = response.data.data.customer_id;
          this.data.branch_id = response.data.data.branch_id;
          this.data.sales_due_date = response.data.data.sales_request_due_date;
          this.data.notes = response.data.data.notes;

          this.customer = response.data.data.customer;
          this.branch = response.data.data.branch;

          this.attachment_url = response.data.data.attachment_url;


          if (response.data.data.items_list && response.data.data.items_list.length <= 0) {
            this.addItemRowToList();
          } else {
            this.items_list = response.data.data.items_list.map((row) => {
              return {id: null, description: row.description, qty: row.qty, unit_price: row.unit_price, subtotal: row.subtotal};
            });
          }
          this.reloadUploadAttachment = true;
        });
    },
    getStatus() {
      ApiService.get(this.mainRouteDependency + "/status", {params: {type: 'purchases_quotation_requests'}}).then((response) => {
        this.status_list = response.data.data;
      });
    },
    getItems(f_by = null, filter = null) {
      let _f_by = f_by ? f_by : 'name';
      if (filter && filter.length >= 3 && _f_by)
        ApiService.get(this.mainRouteDependency + "/items", {params: {[_f_by]: filter, 'item_type': 1}}).then((response) => {
          this.items = response.data.data;
        });
    },
    getBranches() {
      ApiService.get(this.mainRouteDependency + "/branches").then((response) => {
        this.branches = response.data.data;
      });
    },
    async getCustomers(filter) {
      if (filter && filter.length >= 3) {
        await ApiService.get(`${this.mainRouteDependency}/customers`, {params: {filter: filter}}).then((response) => {
          this.customers = response.data.data;
        });
      } else {
        this.customers = [];
      }
    },
    loadOptions() {
    },
    // addMoreTaxesInOneRow(index){
    //   this.items_list[index].tax_applied.push({});
    // },
    // removeTaxFromOneRow(parent_index, index){
    //   if (this.items_list[parent_index].tax_applied.length > 1) {
    //     this.items_list[parent_index].tax_applied.splice(index, 1);
    //   }
    // },
    changeDiscountInRepeater(index) {
      // this.data.discount_type = 1;
      // this.data.discount_value = 0;
      this.setValue(index);
    },
    addItemRowToList() {
      this.items_list_form.unit = this.default_unit;
      if (this.tax_default) {
        this.items_list_form.tax_applied.push(this.tax_default);
      }

      this.items_list.unshift(this.items_list_form);
      this.items_list_form = {id: null, item: null, qty: null, unit_price: null, subtotal: 0, tax: null, amount_tax: 0,
        tax_applied: [], unit: null, discount_type: 1, discount_value: null, subtotal_before_discount: null, subtotal_after_discount: null,
        subtotal_before_tax: null, subtotal_after_tax: null,};
    },
    removeItemRowFromList(index = 0) {
      if (this.items_list.length > 1) {
        this.items_list.splice(index, 1);
        this.calcItemListForTotal();
      }
      this.reCalcGeneralDiscount();
    },
    listenerAttachment(event) {
      if (event) {
        this.attachment_url = event.pathDB;
        this.data.attachment = event.name;
      }
    },

    showItemModal() {
      this.$refs['modalItem'].show()
    },
    hideItemModal() {
      this.$refs['modalItem'].hide();
    },

    getDataAfterCreateNewItem(object) {
      let promise = this.getItems();
      Promise.all([promise]).then(() => {
        if (object) {
          this.items_list[0].item = object;
        }
      })
    },

    showModal() {
      this.$refs['modal'].show()
    },
    hideModal() {
      this.$refs['modal'].hide();
    },
    getDataAfterCreateNewCustomer(object) {
      let promise = this.getCustomers();
      Promise.all([promise]).then(() => {
        this.data.customer_id = object.id;
      })
    },
    getCode() {
      ApiService.get(this.mainRouteDependency + "/code-setting/20").then((response) => {
        this.data.sales_quotation_code = response.data.data.code;
      });
    },
    async getCurrencies() {
      await ApiService.get(this.mainRouteDependency + "/currencies").then((response) => {
        this.currencies = response.data.data;
      });
    },
    async getDefaultCurrency() {
      await ApiService.get(this.mainRouteDependency + "/default-currency").then((response) => {
        this.data.currency_id = response.data.data.id;
      });
    },
    getExchangeRate(id) {
      ApiService.get(`${this.mainRouteDependency}/exchange_rate/` + id).then((response) => {
        this.data.exchange_rate = response.data.data.initial_val;
      });
    },
    getTaxSettings() {
      ApiService.get(this.mainTaxSettingsRoute).then((response) => {
        this.is_allow_tax_calc = response.data.data.is_apply_tax_quotation;
        this.data.is_apply_taxes_quotation = response.data.data.is_apply_tax_quotation ? response.data.data.is_apply_tax_quotation : false;
      });
    },
    // getTaxes(filter) {
    //   if (filter && filter.length >= 3) {
    //     ApiService.get(`${this.mainRouteDependency}/taxes?type=4`, {params: {filter: filter}}).then((response) => {
    //       this.taxes = response.data.data;
    //     });
    //   } else {
    //     this.taxes = [];
    //   }
    // },
    getTaxes() {
      ApiService.get(`${this.mainRouteDependency}/taxes?type=4`).then((response) => {
        this.taxes = response.data.data;
      });
    },
    getItemTax(index) {

      let tax_ids = (this.items_list[index].tax_applied && this.items_list[index].tax_applied.length > 0) ? this.items_list[index].tax_applied.map(row => row.id) : [];
      let quantity = this.items_list[index].qty ? this.items_list[index].qty : null;
      let unit_price = this.items_list[index].unit_price ? this.items_list[index].unit_price : null;
      let discount = this.items_list[index].discount_value ? this.items_list[index].discount_value : null;
      let discount_type = this.items_list[index].discount_type ? this.items_list[index].discount_type : null;

      if (this.isApplyTaxesInvoice) {
        if (tax_ids.length > 0 && quantity && unit_price)
          ApiService.get(`/v2/items/calculate-subtotal-by-taxes`,
              {
                params: {
                  tax_ids: tax_ids,
                  quantity: quantity, sale_price: unit_price,
                  discount: discount, discount_type: discount_type, row_index: index
                }
              }).then((response) => {
            this.items_list[response.data.data.row_index].amount_tax = response.data.data.tax_total;
            this.items_list[response.data.data.row_index].tax_applied = response.data.data.applied_taxes;
            this.items_list[response.data.data.row_index].amount_excluded_tax = response.data.data.excluded_taxes_total ? this.parseToShortFloat(response.data.data.excluded_taxes_total) : 0;
            this.items_list[response.data.data.row_index].amount_included_tax = response.data.data.included_taxes_total ? this.parseToShortFloat(response.data.data.included_taxes_total) : 0;

            this.calcItemListForTotal();

          });
      }else{
        this.items_list[index].amount_tax = 0;
        this.items_list[index].tax_applied = [];
        this.items_list[index].amount_excluded_tax = 0;
        this.items_list[index].amount_included_tax = 0;

        this.calcItemListForTotal();
      }

    },
    async defaultDataForUser() {
      await ApiService.get(this.mainRouteDependency + "/default_data_for_user").then((response) => {
        this.tax_default = response.data.data.tax;
        this.default_unit = response.data.data.unit;
      });
    },
    getUnits() {
        ApiService.get(`${this.mainRouteDependency}/units`).then((response) => {
          this.units = response.data.data;
        });

    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.general_sales_quotations"), route: '/sales/general-sales-quotations'}, {title: (this.idEditing ? this.$t('update') : this.$t('create'))}]);

    // this.getItems();
    this.getTaxes();
    let promise1 = this.getCurrencies();
    let promise = this.getDefaultCurrency();
    let promise2 = this.defaultDataForUser();
    this.getStatus();
    this.getBranches();
    this.getTaxSettings();
    this.getUnits();

    Promise.all([promise1, promise, promise2]).then(() => {
      if (this.idEditing) {
        this.getData();
      } else if (this.$route.params.quotation_requests_id) {
        this.getCode();
        this.getTimeZone().then((res)=>{
          this.data.sales_quotation_date = res;
          this.data.sales_due_date = res;
        });
        // this.data.sales_quotation_date = new Date().toISOString().slice(0, 10);
        // this.data.sales_due_date = new Date().toISOString().slice(0, 10);
        this.getDataForQuotationRequests();

      } else {
        if (this.items_list.length <= 0) {
          this.addItemRowToList();
        }
        this.getCode();
        this.getTimeZone().then((res)=>{
          this.data.sales_quotation_date = res;
          this.data.sales_due_date = res;
        });
        // this.data.sales_quotation_date = new Date().toISOString().slice(0, 10);
        // this.data.sales_due_date = new Date().toISOString().slice(0, 10);

      }
    });


  },
};
</script>