<template>
  <div>

    <div class="card-toolbar mb-5 d-flex justify-content-between">
      <div>
        <router-link v-if="$can('supplier_checking.create')" to="/purchases/supplier-checking/create" class="btn btn-primary font-weight-bolder">
          <v-icon>mdi-plus</v-icon>
          {{ $t('supplier_checking.add_supplier_checking')}}
        </router-link>

        <button @click="openAdvancedSearch" class="btn btn-outline-primary font-weight-bolder ml-5">
          <span><i class="la la-filter"></i> <span>{{ $t('advanced_filter') }}</span></span>
        </button>
      </div>
      <div>
        <router-link v-if="$can('supplier_checking.supplier_checking_history')"
                     to="/purchases/supplier-checking-history" class="btn btn-primary font-weight-bolder h-100">
          {{ $t('MENU.supplier_checking_history') }}
        </router-link>
      </div>

    </div>

    <div class="card card-custom mb-5" v-if="showAdvancedSearch">
      <div class="card-body">
        <div class="m-form m-form--fit m--margin-bottom-20">
          <div class="row mb-5">

            <div class="col-lg-6 mb-5">
              <label>{{ $t('supplier_checking.supplier') }}</label>
              <multiselect v-model="supplier"
                           :placeholder="$t('supplier_checking.supplier')"
                           label="name"
                           track-by="id"
                           :options="suppliers"
                           :multiple="false"
                           :taggable="false"
                           :show-labels="false"
                           :show-no-options="false"
                           :show-no-results="false"
                           @search-change="getSuppliers($event)">
              </multiselect>
            </div>

            <div class="form-group col-md-6 mb-5">
              <label for="from_date">{{ $t('supplier_checking.from_date') }}</label>
              <input v-model="filters.from_date" type="date" id="from_date" class="form-control">
            </div>
            <div class="form-group col-md-6 mb-5">
              <label for="to_date">{{ $t('supplier_checking.to_date') }}</label>
              <input v-model="filters.to_date" type="date" id="to_date" class="form-control">
            </div>


            <div class="form-group d-inline-flex col-md-6 mt-6 mb-0">
              <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                            <span><i class="fas fa-search"></i>
                                <span>{{ $t('search') }}</span>
                            </span>
              </button>
              <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="m_reset">
                            <span><i class="fas fa-trash-restore"></i>
                                <span>{{ $t('reset_search') }}</span>
                            </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--begin::customer-->
    <div class="card card-custom">
      <div class="card-body">

        <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">


          <template slot="status" slot-scope="props">
            <button v-if="$can('supplier_checking.change_status')" @click="showModal(props.row.id, props.row.status)" :key="props.row.id" class="btn btn-secondary m-btn m-btn--icon w-auto">
              {{ $t('change_status') }} ({{ props.row.status_name }})
            </button>
            <button v-else disabled class="btn btn-secondary m-btn m-btn--icon w-auto">
              {{ $t('change_status') }} ({{ props.row.status_name }})
            </button>
          </template>

          <template slot="actions" slot-scope="props">
            <template v-if="props.row.status != 2">
              <v-btn icon color="pink" v-if="$can('supplier_checking.update')" :to="`/purchases/supplier-checking/edit/${props.row.id}`">
                <v-icon small class="mr-2 text-info" v-b-tooltip.hover :title="$t('edit')">mdi-pencil</v-icon>
              </v-btn>
            </template>

          </template>
        </v-server-table>

      </div>
    </div>
    <!--end::customer-->
    <b-modal ref="modal" size="md" hide-footer :title="$t('change_status')">
      <change-status-form @hide-modal="hideModal()"
                          :status-list="status_list"
                          :url="routeChangeStatus"
                          :current-id="innerId"
                          :current-status="statusId"
                          :ignore-selected-status="ignore_status_from_list[statusId]"
                          @handling-data="getDataAfterChangeStatus">
      </change-status-form>
    </b-modal>


  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import ChangeStatusFrom from "@/view/content/forms/ChangeStatusForm.vue";

export default {
  name: "index-supplier-checking",
  components: {'change-status-form': ChangeStatusFrom},
  data() {
    return {
      mainRoute: 'purchases/supplier-checking',
      subMainRoute: 'purchases/supplier-check',
      mainRouteDependency: 'base/dependency',
      routeChangeStatus: 'purchases/supplier-check/change-status',

      showAdvancedSearch: false,

      filter: {
        sortBy: 'id',
      },
      filters: {
        supplier_id: this.$route.query.supplier_id ? this.$route.query.supplier_id : null,
        from_date: null,
        to_date: null,
      },
      // status_list: [],
      //   columns: ['supplier_name', 'credit','debit',   'currency_name', 'last_validated_date',  'actual_amount', 'actions'],
      columns: ['supplier_name', 'old_credit', 'old_debit', 'credit', 'debit', 'created_at', 'confirmed_date', 'status', 'actions'],

      data: [],
      supplier: null,
      suppliers: [],

      status_list: [],
      innerId: null,
      statusId: null,
      ignore_status_from_list:{
        2: [1,4],
      }

    }
  },
  watch: {
    supplier: function (val) {
      if (val) {
        this.filters.supplier_id = val.id;
      } else {
        this.filters.supplier_id = null;
      }
    },
  },
  computed: {
    options: function () {
      let that = this;
      return {
        texts: {
          loadingError: that.$t('Something_went_wrong'),
          filter: "",
          filterBy: that.$t('Filter') + ' {column}',
          count: ' ',
          filterPlaceholder: that.$t('Search_query'),
          loading: that.$t('Loading') + "...",
        },
        headings: {
          // supplier_name: that.$t('supplier_checking.supplier'),
          // credit: that.$t('supplier_checking.credit'),
          // debit: that.$t('supplier_checking.debit'),
          // currency_name: that.$t('supplier_checking.currency_name'),
          // actual_amount: that.$t('supplier_checking.actual_amount'),
          // last_validated_date: that.$t('supplier_checking_history.last_validate_date'),
          supplier_name: that.$t('supplier_checking.supplier'),
          old_credit: that.$t('supplier_checking.credit'),
          old_debit: that.$t('supplier_checking.debit'),
          credit: that.$t('supplier_checking.new_credit'),
          debit: that.$t('supplier_checking.new_debit'),
          created_at: that.$t('supplier_checking.created_date'),
          confirmed_date: that.$t('supplier_checking.confirmed_date'),
          status: that.$t('status'),
          actions: that.$t('actions'),
        },
        sortable: ['name'],
        filterByColumn: false,
        filterable: false,
        customFilters: [{
          name: 'alphabet',

        }],
        orderBy: {'column': 'id'},

        alwaysShowPerPageSelect: false,
        perPage: 10,
        pagination: {chunk: 5, dropdown: false},
        skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
        sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
        perPageValues: [5, 10, 15, 25],

        requestFunction(data) {
          let _params = {
            ascending: data.ascending,
            byColumn: data.byColumn,
            limit: data.perPage,
            orderBy: data.orderBy,
            page: data.page,
            filter: data.query,
            ...that.filters,
          }
          return ApiService.query(`${that.mainRoute}`, {..._params});

        },
        responseAdapter(resp) {
          return {
            data: resp.data.data.data,
            count: resp.data.data.total,
          }
        }
      }

    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.supplier_checking")}]);
    this.getStatus();
  },
  methods: {
    openAdvancedSearch() {
      this.showAdvancedSearch = !this.showAdvancedSearch;
    },
    doFilter() {
      this.$refs.table.refresh();
    },
    resetFilter() {
      this.filters.supplier_id = null;
      this.filters.from_date = null;
      this.filters.to_date = null;
      this.supplier = null;

      this.$refs.table.refresh();
    },
    getFetch() {
      this.$refs.table.refresh();
    },

    async getSuppliers(filter) {
      if (filter && filter.length >= 3)
        await ApiService.get(this.mainRouteDependency + "/suppliers", {params: {filter: filter}}).then((response) => {
          this.suppliers = response.data.data;
        });
    },

    goToEdit(supplier_id, currency_id) {
      this.$router.push({
        name: 'supplier-checking.edit', query: {
          supplier_id: supplier_id,
          currency_id: currency_id,
        }
      });
    },
    getDataAfterChangeStatus() {
      this.innerId = null;
      this.statusId = null;
      this.doFilter();
    },
    showModal(id, status) {
      this.innerId = id;
      this.statusId = status;
      this.$refs['modal'].show();
    },
    hideModal() {
      this.$refs['modal'].hide();
      this.innerId = null;
      this.statusId = null;
    },

    getStatus() {
      ApiService.get(this.mainRouteDependency + "/status").then((response) => {
        this.status_list = response.data.data;
      });
    },

    // makeApprove(supplier_validation_id) {
    //   this.$confirmAlert(this.$t('do_you_want_make_it_approved'), this.actionApprove, supplier_validation_id);
    // },
    //
    // actionApprove(supplier_validation_id) {
    //   ApiService.patch(`${this.subMainRoute}/approved/${supplier_validation_id}`).then((response) => {
    //     this.getFetch();
    //     this.$successAlert(response.data.message)
    //   }).catch((error) => {
    //     this.$errorAlert(error);
    //   })
    // },

  },
};
</script>
