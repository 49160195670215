<template>
  <div>
    <!--begin::User-->
    <div>
      <h3 class="card-label">
        {{ $t('financial_years.financial_years') }}
        <span class="text-muted pt-2 font-size-sm d-block"></span>
      </h3>

      <div class="row">
        <div class="col-lg-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-12 mb-5">
                <label>{{ $t('financial_years.start_date') }}<span class="text-danger">*</span></label>
                <input type="date" v-model.lazy="data.start_date" @input="checkStartDateToLessThan(data.start_date, $event)" class="form-control" :class="validation && validation.start_date ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.start_date" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.start_date[0] }}
                            </span>
              </div>
              <div class="col-lg-12 mb-5">
                <label>{{ $t('financial_years.end_date') }}<span class="text-danger">*</span></label>
                <input type="date" v-model.lazy="data.end_date" @input="checkEndDateToGraterThan(data.end_date, $event)" class="form-control" :class="validation && validation.end_date ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.end_date" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.end_date[0] }}
                            </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-12 mb-5">
                <b-form-checkbox size="lg" v-model="data.status" :class="validation && validation.status ? 'is-invalid' : ''" name="check-button" switch>{{ $t('status') }}</b-form-checkbox>
                <span v-if="validation && validation.status" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.status[0] }}
                            </span>
              </div>
              <div class="col-lg-12 mb-5">
                <label>{{ $t('financial_years.description') }}</label>
                <textarea type="text" v-model="data.description" style="height: 78px;" class="form-control" :class="validation && validation.description ? 'is-invalid' : ''"></textarea>
                <span v-if="validation && validation.description" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.description[0] }}
                            </span>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="pr-0 pl-0">
        <div class="row">
          <div class="col-lg-6 mt-10">
            <button type="button" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
          </div>
        </div>
      </div>
    </div>


    <!--end::User-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "from-financial-years",
  data() {
    return {
      mainRoute: 'accounting/financial-years',
      mainRouteDependency: 'base/dependency',


      idEdit: this.$route.params.id ? this.$route.params.id : null,
      is_clone: this.$route.params.is_clone ? this.$route.params.is_clone : null,
      data: {
        start_date: null,
        end_date: null,
        description: null,
        status: false,
      },
      isEditing: false,
      validation: null,
      current_end_date: null,

    };
  },

  methods: {
    /**
     * used to check end date be
     * 1- grater than start
     * 2- in edit the end date be grater than current end date
     */
    checkEndDateToGraterThan(current_date, event){
      let _new_end_date = event.target.value ? event.target.value : null;
      if (this.current_end_date && _new_end_date && this.idEdit){
        if (this.$moment(_new_end_date).isBefore(this.current_end_date) ){
          this.data.end_date = this.current_end_date;
          event.target.value = this.current_end_date;
        }
      }
      if (_new_end_date){
        if (this.$moment(_new_end_date).isBefore(this.data.start_date)){
          this.data.end_date = null;
          event.target.value = null;
        }
      }
    },
    checkStartDateToLessThan(current_date, event){
      let _new_end_date = event.target.value ? event.target.value : null;
      if (_new_end_date && this.data.end_date){
        if (this.$moment(_new_end_date).isAfter(this.data.end_date)){
          this.data.start_date = null;
          event.target.value = null;
        }
      }
    },
    save() {
      if (this.isEditing) {
        this.update();
      } else {
        this.create();
      }
    },

    create() {
      // let is_clone = this.is_clone;
      ApiService.post(this.mainRoute, {
        // is_clone : is_clone,
        ...this.data,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.$router.push('/accounting/financial-years');
      }).catch((error) => {
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },

    update() {
      ApiService.put(this.mainRoute + '/' + this.idEdit, {
        ...this.data,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.$router.push('/accounting/financial-years');
      }).catch((error) => {
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },

    getData() {
      ApiService.get(this.mainRoute + '/' + this.idEdit).then((response) => {
        this.isEditing = true;
        this.data.start_date = response.data.data.start_date;
        this.data.end_date = response.data.data.end_date;
        this.current_end_date = response.data.data.end_date;
        this.data.description = response.data.data.description;
        this.data.status = response.data.data.status;
      });
    },

  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.financial_years"), route: '/accounting/financial-years'}, {title: (this.idEdit ? this.$t('update') : this.$t('create'))}]);

    if (this.idEdit) {
      this.getData();
    }

  },
};
</script>


