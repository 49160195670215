<template>
  <div>
    <div id="body-pos-screen" class="card card-custom">
      <div class="card-header flex-wrap pt-2 pb-0 mb-5">
        <div class="card-title">
          <div class="d-flex justify-content-between">

            <router-link to="/pos/pos-session" class="btn btn-warning">
                            <span class="svg-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                     class="bi bi-house-door" viewBox="0 0 16 16">
                                    <path
                                        d="M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146zM2.5 14V7.707l5.5-5.5 5.5 5.5V14H10v-4a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v4H2.5z"></path>
                                </svg>
                            </span>{{ $t('MENU.DASHBOARD') }}
            </router-link>
          </div>
          <h3 class="card-label ml-2">{{ $t('MENU.point_of_sales') }}
            <span class="text-muted pt-2 font-size-sm d-block">{{ $t('MENU.point_of_sales') }}</span>
          </h3>

          <button class="btn btn-outline-primary mx-5" @click="ToggleFullScreen">
            <i class="fas " :class="{'fa-expand': !is_full_screen, 'fa-compress': is_full_screen}"></i>
            <span> {{ $t('full_screen') }} </span>

          </button>
        </div>
        <div class="card-title">
          <button id="printInvoice" class="btn btn-primary mr-2" @click="printInvoice" :disabled="isDisabled"
                  ref="printInvoice">
            <i class="flaticon2-print mr-2 ml-2"></i>
            {{ $t('print_last_invoice') }}
            <!-- <i class="fa fa-spinner fa-spin" v-show="loader"></i> -->
          </button>
          <div class="topbar-item">
            <b-dropdown variant="link" class="link" toggle-class="text-decoration-none dropdown-toggle-no-caret">
              <template #button-content>
                <div class="btn btn-icon btn-icon-mobile w-auto btn-clean d-flex align-items-center btn-lg px-2 mr-1"
                     id="kt_quick_user_toggle">
                                    <span class="symbol symbol-lg-35 symbol-25 symbol-light-success">
                                        <span class="symbol-label font-size-h5 font-weight-bold">
                                            {{ user.first_name ? user.first_name.substring(0, 1) : '' }}
                                        </span>
                                    </span>
                  <div class="d-flex flex-column ml-2">
                    <p class="text-dark font-weight-bold font-size-base d-md-inline mb-0 text-left">{{
                        user.first_name
                      }} {{ user.last_name }}</p>
                    <p class="text-muted font-weight-bolder font-size-base d-md-inline mb-0">{{ date_now }}</p>
                  </div>
                </div>
              </template>
              <b-dropdown-item :href="'/pos/pos-session/view/'+session_id+'/cash_movements'">
                {{ $t('point_of_sales.add_cash_in') }}
              </b-dropdown-item>
              <b-dropdown-item :href="'/pos/pos-session/view/'+session_id+'/cash_movement_out'">
                {{ $t('point_of_sales.add_cash_out') }}
              </b-dropdown-item>
              <b-dropdown-item :href="`/pos/pos-session/view/${session_id}`">
                {{ $t('point_of_sales.view_session') }}
              </b-dropdown-item>
              <b-dropdown-item @click="onLogout">
                {{ $t('Sign_out') }}
              </b-dropdown-item>
            </b-dropdown>

          </div>

        </div>
      </div>
      <div class="card-body pt-2 pb-1 screens-as-tabs">
        <b-tabs v-model="tabActive" @input="selectTab">
          <!-- Render Tabs, supply a unique `key` to each tab -->
          <b-tab v-for="i in tabs" :id="'dyn-tab-' + i" :key="'dyn-tab-' + i" :title="$t('screen')+' ' + (i+1)">
            <template #title>
              <span class="d-flex justify-content-between min-w-105">
                  <b>{{ $t('screen') + ' ' + (i + 1) }}</b>
                  <a v-if="tabs.length > 1" @click="closeTab(i)" href="javascript:;"><i
                      class="fas fa-times fs-14 close-hove text-danger"></i></a>
              </span>
            </template>
            <keep-alive>
              <pos-screen :is-enabled="tabIndex==i" :key="'screen'+i" v-if="is_show_screen" :ref="'screen'+i"
                          :index-screen="i" :props-data.sync="props_data" :currency-id="currency_id"
                          :currency-code="currency_code" :inner-currency="currency" :inner-user="user"
                          :is-disabled="isDisabled" :session-id="session_id"
                          :is-allow-invoice-return="is_allow_invoice_return"
                          @handling-data="callBackToGetUrlInvoice"
                          @set-type-invoice="setTypeInvoiceScreen"
                          @print-showing-payed-invoice="printEleShowComponent"></pos-screen>
            </keep-alive>
          </b-tab>

          <!-- New Tab Button (Using tabs-end slot) -->
          <template #tabs-end>
            <b-nav-item id="newTab" role="presentation" @mouseup.prevent="newTab" href="#"><i
                class="fas fa-plus fs-19"></i></b-nav-item>
          </template>

        </b-tabs>

      </div>
    </div>

    <!--    <template>-->
    <!--      <div style="height:0; width:0">-->
    <!--        <iframe v-if="this.invoice_url"-->
    <!--                id="invoice"-->
    <!--                name="invoice"-->
    <!--                :src.sync="this.invoice_url"-->
    <!--                width="100%"-->
    <!--                height="1200"-->
    <!--                frameborder="0">-->
    <!--        </iframe>-->
    <!--      </div>-->
    <!--    </template>-->
    <!--    <Print id="printEle" ref="printEle" class="d-none" @print-ele-show="printInvoice" v-if="printEleShow"/>-->

  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {LOGOUT} from "@/core/services/store/auth.module";
import ApiService from "@/core/services/api.service";
import POSScreen from "./Screen";

export default {
  name: "index-point-of-sales",
  components: {'pos-screen': POSScreen},

  data() {
    return {
      mainRouteDependency: 'base/dependency',
      mainRouteInvoice: 'sales/sales_invoices',

      lang: this.$i18n.locale,
      isDisabled: true,

      data: {
        name: null,
        type: 1,
        user_id: null,
      },

      invoice_url: null,
      user: [],
      currency: 'SAR',
      currency_id: null,
      currency_code: null,
      date_now: '',
      session_id: null,
      last_invoice_id: null,

      tabs: [0],
      tabIndex: 0,
      tabActive: 0,
      is_show_screen: false,
      is_refund_available: false,
      printEleShow: false,
      is_allow_invoice_return: true,
      default_payment_method: [],
      props_data: {
        default_payment_method: [],
        pos_default_customer: [],
        payment_methods_list: [],
        is_apply_taxes_setting: false,
        is_company_has_tax_no: false,
        is_specail_category: false,
        request_invoice: false,
        default_print_invoice_size: '80mm',
        default_print_refund_size: '80mm',
      },
      is_full_screen: false,
    }
  },
  watch: {
    "data.name": function (val) {
      if (!val) {
        this.categories = this.getCategories();
      }
    },
    tabIndex: function (val) {
      if (this.$refs["screen" + val] && this.$refs["screen" + val][0] && this.$refs["screen" + val][0].$refs["barcodeInput" + val]) {
        this.$nextTick(() => {
          let _ref = this.$refs["screen" + val][0].$refs["barcodeInput" + val];
          if (_ref) {
            _ref.focus()
          }

        });
      }

    }
  },

  methods: {
    selectTab(event) {
      this.tabIndex = this.tabs[event];
    },
    onLogout() {
      this.$store
          .dispatch(LOGOUT)
          .then(() => this.$router.push({name: "login"}));
    },
    printEleShowComponent() {
      // this.printEleShow = true;
      // this.printInvoice();
    },


    async getAuthUser() {
      await ApiService.get(`/get_auth`).then((response) => {
        this.user = response.data.data;
        this.props_data.is_specail_category = response.data.data.is_specail_category ? response.data.data.is_specail_category : false;
        // this.currency_id = response.data.data.currency_id;
        if (this.user) {
          this.data.user_id = this.user.id;
          this.isDisabled = false;
        }
      });
    },
    // async getPosCurrency() {
    //   await ApiService.get(`${this.mainRouteDependency}/currency_for_pos`).then((response) => {
    //     this.currency = response.data.data.currency_name;
    //     this.currency_id = response.data.data.currency_id;
    //     this.currency_code = response.data.data.currency_code;
    //   });
    // },
    async getPreferences() {
      await ApiService.get(`/v2/base/dependencies/pos/preferences`).then((response) => {

        if (response.data.data.session) {
          let _session = response.data.data.session;
          if (_session && _session.id) {
            this.session_id = _session.id;
            this.is_allow_invoice_return = _session.pos_store ? _session.pos_store.is_allow_invoice_return : false;

            /**
             * get data setting for size printing invoice type (invoice, refund)
             */
            if (_session.pos_store) {
              let _pos_store = _session.pos_store;
              this.props_data.default_print_invoice_size = _pos_store.default_print_invoice_size ? _pos_store.default_print_invoice_size : '80mm';
              this.props_data.default_print_refund_size = _pos_store.default_print_refund_size ? _pos_store.default_print_refund_size : '80mm';

            }
            /**
             * this is function to set value link to invoice url
             * to print last invoice dependent on:
             * id
             * is_apply_taxes_invoice
             */
            this.callBackToGetUrlInvoice({
              id: _session.last_invoice_id,
              is_apply_taxes_invoice: _session.is_apply_taxes_invoice_for_last_invoice
            });

          } else {
            window.location = '/pos/pos-session/create';
          }
        } else {
          window.location = '/pos/pos-session/create';
        }
        if (response.data.data.defaultPaymentMethod) {
          this.props_data.default_payment_method = response.data.data.defaultPaymentMethod ? response.data.data.defaultPaymentMethod : [];
        }
        if (response.data.data.customers) {
          this.props_data.pos_default_customer = response.data.data.customers ? response.data.data.customers : [];
        }
        if (response.data.data.currency) {
          let _currency = response.data.data.currency;
          this.currency = _currency.currency_name;
          this.currency_id = _currency.currency_id;
          this.currency_code = _currency.currency_code;
        }
        if (response.data.data.is_apply_taxes_setting) {
          this.props_data.is_apply_taxes_setting = response.data.data.is_apply_taxes_setting;
        }
        if (response.data.data.is_company_has_tax_no) {
          this.props_data.is_company_has_tax_no = response.data.data.is_company_has_tax_no;
        }
        if (response.data.data.payment_methods) {
          this.props_data.payment_methods_list = response.data.data.payment_methods;
        }
        if (response.data.data.request_invoice) {
          this.props_data.request_invoice = response.data.data.request_invoice;
        }
      });
    },
    // async getCurrentSession() {
    //   await ApiService.get(`${this.mainRouteDependency}/current_session`).then((response) => {
    //     if (response.data.data && response.data.data.id) {
    //       this.session_id = response.data.data.id;
    //       this.is_allow_invoice_return = response.data.data.pos_store ? response.data.data.pos_store.is_allow_invoice_return : false;
    //     } else {
    //       window.location = '/pos/pos-session/create';
    //     }
    //   });
    // },
    closeTab(tab_index) {
      for (let i = 0; i < this.tabs.length; i++) {
        if (this.tabs[i] === tab_index) {
          if (this.tabIndex == tab_index) {
            this.tabIndex = this.tabs[this.tabs.length - 1];
            this.tabActive = this.tabs.length - 1;
          }
          this.tabs.splice(i, 1);
        }
      }
    },
    newTab() {
      let _count = this.tabs[this.tabs.length - 1] + 1;
      this.tabs.push(_count);
      setTimeout(() => {
        this.tabIndex = _count;
        this.tabActive = this.tabs.length - 1;
        this.$nextTick(() => {
          let _ref = this.$refs["screen" + _count][0].$refs["barcodeInput" + _count];
          if (_ref) {
            _ref.focus()
          }

        });

      }, 100);
    },
    printInvoice() {
      // this.invoice_url = '/print/point-sale-invoice'; //this.is_refund_available == false ? '/print/point-sale-invoice' : '/print/point-sale-invoice-refund';
      window.open(this.invoice_url, "_blank");


    },
    callBackToGetUrlInvoice(object_data = null) {
      this.invoice_url = '/print/point-sale-invoice';
      if (object_data && object_data.id) {
        this.last_invoice_id = object_data.id;
      }

      if (this.props_data.default_print_invoice_size == 'a4') {
        if (this.last_invoice_id) {
          if (object_data.is_apply_taxes_invoice) {
            /** invoice A4 with tax */
            this.invoice_url = `/print/sales-tax-invoice/${this.last_invoice_id}/print`;
          } else {
            /** print invoice A4 without tax */
            this.invoice_url = `/print/sales/${this.last_invoice_id}/print`;
          }
        }
      }
    },
    setTypeInvoiceScreen(type) {
      this.is_refund_available = type;
    },

    ToggleFullScreen() {
      if (this.is_full_screen) {
        this.closeFullscreen();
      } else {
        this.openFullscreen();
      }
    },
    openFullscreen() {
      let elem = document.getElementById("body-pos-screen");
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) { /* Safari */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) { /* IE11 */
        elem.msRequestFullscreen();
      }
      if (elem.requestFullscreen || elem.webkitRequestFullscreen || elem.msRequestFullscreen)
      this.changeIsFullScreen(true);
    },
    closeFullscreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) { /* Safari */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) { /* IE11 */
        document.msExitFullscreen();
      }
      if (document.exitFullscreen || document.webkitExitFullscreen || document.msExitFullscreen)
      this.changeIsFullScreen(false);

    },
    changeIsFullScreen(is_full_screen) {
      this.is_full_screen = is_full_screen;
    },
  },

  mounted() {
    let that = this;
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.point_of_sales")}]);
    let promise4 = this.getPreferences();
    let promise = this.getAuthUser();
    // let promise2 = this.getCurrentSession();
    // let promise3 = this.getPosCurrency();
    Promise.all([promise, promise4]).then(() => {
      this.is_show_screen = true;
      // this.invoice_url = this.is_refund_available == false ? '/print/point-sale-invoice' : '/print/point-sale-invoice-refund';

    });

    setTimeout(() => {
      this.isDisabled = false;
    }, 3500);
    this.date_now = this.$moment().format('DD/MM/YYYY, h:mm a');

    window.addEventListener("keypress", function (e) {
      /**
       * ctrl + b (open new screen tab)
       */
      if (String.fromCharCode(e.keyCode) == '\x02') {
        that.newTab()
      }
    });
    document.addEventListener("fullscreenchange",  (event) => {
      // document.fullscreenElement will point to the element that
      // is in fullscreen mode if there is one. If there isn't one,
      // the value of the property is null.
      if (document.fullscreenElement && document.fullscreenElement.id) {
        that.changeIsFullScreen(true);
      } else {
        that.changeIsFullScreen(false);
      }
    });
  },
}
</script>

<style>
body {
  background: #fff;
}

#invoice table, #invoice thead, #invoice tr, #invoice th, #invoice tbody, #invoice td {
  border: 1px solid #000 !important;
}

.text-center {
  text-align: center !important;
}

.b-b-white {
  border-bottom: 1px solid #cccccc;
}

.fs-14 {
  font-size: 14px;
}

.fs-12 {
  font-size: 12px;
}

.card-image {
  overflow: hidden;
  height: 122px;
  position: relative;
}

.cursor {
  cursor: pointer;
}

.item-price {
  position: absolute;
  background-color: #ffffff;
  color: #000000;
  padding: 4px;
  border-radius: 20px;
  width: auto;
  height: 27px;
  text-align: center;
  right: 9px;
  top: 9px;
  box-shadow: 0 0 3px #000;
}

.btn.dropdown-toggle.btn-secondary {
  border-radius: 0;
}

/**
calc
 */

.buttons {
  display: grid;
  grid-template-columns: 64px 64px 64px 64px;
}

.buttons-2 {
  display: grid;
  grid-template-columns: 86px 86px 86px;
  width: 258px;
  margin: 0 auto;
}

.grid-v-2 {
  grid-column: 3/1;
}

.grid-h-2 {
  grid-row-start: 3;
  grid-row-end: 5;
  grid-column-start: 4;
}

.button > div {
  border-top: 1px solid #999;
  border-right: 1px solid #999;
}

.button {
  border: 0.5px solid #999;
  line-height: 50px;
  text-align: center;
  font-size: 25px;
  cursor: pointer;
  margin: 8px;
  border-radius: 5px;
}

.button-st-2 {
  line-height: 69px !important;
  font-size: 20px;
}

#equal {
  background-color: #0c6f58;
  color: #fff;
}

.button:hover {
  background-color: rgb(226, 156, 25);
  color: #fff;
  transition: 0.5s ease-in-out;
}

.hidden-line {
  white-space: nowrap;
  overflow: hidden;
}

.cursor-pointer {
  cursor: pointer;
}

.table-fixed-header tr {
  width: 100%;
  display: inline-table;
  height: 60px;
  table-layout: fixed;
}

.table-fixed-header table {
  height: 100%;
  display: block;
}

.table-fixed-header tbody {
  overflow-y: scroll;
  height: 192px;
  width: 100%;
  position: absolute;
}

.table-fixed-header th, .table-fixed-header td {
  vertical-align: inherit !important;
}

.table-fixed-header .form-control-sm {
  padding: 0.15rem;
}

.table-fixed-header .btn-sm, .table-fixed-header .btn-group-sm > .btn {
  padding: 0;
}

.default-client {
  border: 1px solid #ff8d2b !important;
}

.min-w-105 {
  min-width: 105px;
}

.fs-14 {
  font-size: 14px;
}

.close-hove:hover {
  color: #000000;
}

.screens-as-tabs .nav-tabs .nav-link.active,
.screens-as-tabs .nav-tabs .nav-item.show .nav-link {
  color: #3699FF;
  background-color: #ffffff;
  border-color: #3699FF #3699FF #ffffff;
  box-shadow: 0 -4px 4px -4px #95c0ed;
}

.screens-as-tabs .nav-tabs .nav-link {
  border-color: #E4E6EF #E4E6EF #E4E6EF;
}

.calc-input {
  text-align: center;
  font-size: 28px;
  border: 0;
  border-bottom: 1px solid;
  border-radius: 0;
  width: 183px;
  margin: 0 auto;
}

.overflow-hidden-with-radius {
  overflow: hidden;
  border-radius: 0.42rem;
}

.grid-h-3 {
  grid-row-start: 2;
  grid-row-end: 5;
  grid-column-start: 4;
}

.dropdown-categories-list {
  width: 670px;
  padding: 15px;
  text-align: start;
}

#body-pos-screen.card.card-custom > .card-header {
  min-height: auto;
}
.btn.btn-outline-primary:hover i, .btn.btn-outline-primary:focus i, .btn.btn-outline-primary.focus i {
  color: unset !important;
}
body{
  height: max-content;
}
</style>