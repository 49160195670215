<template>
  <div>
    <b-card no-body class="mb-5">
      <b-card-header header-tab="header" class="p-1" role="tab">
        <b-button block v-b-toggle.accordion-2 variant="link"
                  class="text-left font-weight-bold text-dark pl-8 pr-8 fs-102 collapsed-with-icon">
          {{ $t('advanced_filter') }}
        </b-button>
      </b-card-header>

      <b-collapse id="accordion-2" visible accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <div class="m-form m-form--fit m--margin-bottom-20">
            <div class="row mb-5">

              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="source">{{ $t('journal_transactions.source') }}</label>
                <select name="" id="source" v-model="filters.document_type" type="text" class="custom-select">
                  <option value="">{{ $t('all') }}</option>
                  <option v-for="(row, index) in source_list" :value="row.slug" :key="'source'+index">{{
                      row.title
                    }}
                  </option>
                </select>
              </div>
              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="source">{{ $t('journal_transactions.journal_account') }}</label>
                <treeselect
                    :options="accounts_list"
                    :load-options="loadOptions"
                    :multiple="false"
                    :value="filters.f_account_ids"
                    @input="updateValue($event)"
                    :searchable="true"
                    :show-count="true"
                    :no-children-text="$t('No_sub_options')"
                    :no-options-text="$t('No_options_available')"
                    :no-results-text="$t('No_results_found')"
                    :placeholder="$t('Select')">
                </treeselect>
              </div>
              <div class="form-group col-md-6 mt-2 mb-2">
                <label>{{ $t('journal_transactions.branch') }}</label>
                <multiselect v-model="branches_ids"
                             :placeholder="$t('journal_transactions.branch')"
                             label="name"
                             track-by="id"
                             :options="branches"
                             :multiple="false"
                             @input="getIds('branches')"
                             :taggable="false"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false">
                </multiselect>
              </div>
              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="from_date">{{ $t('journal_transactions.from_date') }}</label>
                <input v-model="filters.from_date" type="date" id="from_date" class="form-control">
              </div>
              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="to_date">{{ $t('journal_transactions.to_date') }}</label>
                <input v-model="filters.to_date" type="date" id="to_date" class="form-control">
              </div>


              <div class="form-group d-inline-flex col-md-6 mt-10">
                <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                  {{ $t('display_report') }}
                </button>
                <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="resetFilter">
                  <i class="fas fa-trash-restore"></i> {{ $t('reset_search') }}
                </button>
              </div>
            </div>
          </div>
        </b-card-body>
      </b-collapse>
    </b-card>

    <!-- End Filter -->
    <div class="card card-custom">
      <div class="card-body">
        <b-tabs content-class="mt-3">

          <b-tab :title="$t('summery')" active>
            <div class="mt-3">
              <div class="row mb-10">
                <div class="col-md-12 mt-10" id="summaryTableTitle">
                  <h4 class="text-center">{{ $t('journal_transactions.journal_transactions') }}</h4>
                  <h5 class="text-center" v-if="filters.from_date && filters.to_date">{{ $t('from') }}
                    {{ filters.from_date }} {{ $t('to') }} {{ filters.to_date }}</h5>
                </div>

              </div>
              <div class="row mb-10">
                <div class="col-12" v-if="data_report_summery">
                  <div class="table-responsive">
                    <div class="row justify-content-end p-4">
                      <b-dropdown right :text="$t('export')" class="mr-2 ml-2">
                        <button class="dropdown-item" @click="convertTableToExcelReport('summaryTable')">
                          <span><i class="fa fa-file-excel"></i> <span>{{ $t('excel') }}</span></span>
                        </button>

                        <button class="dropdown-item" @click="printData('summaryTable')">
                          <span><i class="fa fa-print"></i> <span>{{ $t('print') }}</span></span>
                        </button>
                        <button class="dropdown-item" @click="getAllDataForExportSummary('sectionForExportSummary', 'excel')">
                          <span><i class="fa fa-file-excel"></i> <span>{{ $t('all_data') }}</span></span>
                        </button>
                      </b-dropdown>
                    </div>
                    <table class="table table-bordered" id="summaryTable">
                      <thead>
                      <tr>
                        <th>
                          <span v-if="filters.group_by == 'journal'">{{ $t('journal_transactions.journal_id') }}</span>
                          <span v-if="filters.group_by == 'branch'">{{ $t('journal_transactions.branch') }}</span>
                        </th>
                        <th>{{ $t('journal_transactions.debit') }}</th>
                        <th>{{ $t('journal_transactions.credit') }}</th>

                      </tr>
                      </thead>
                      <tbody>
                      <template v-for="(row, index) in data_report_summery">
                        <tr>
                          <td>{{ row.label }}</td>
                          <td>{{ row.total_debits }}</td>
                          <td>{{ row.total_credits }}</td>
                        </tr>
                      </template>

                      </tbody>
                      <tfoot>
                      <tr>
                        <td><b>{{ $t('journal_transactions.total') }}</b></td>
                        <th>{{ summery_total_sum.total_debits ? summery_total_sum.total_debits : 0 }}</th>
                        <th>{{ summery_total_sum.total_credits ? summery_total_sum.total_credits : 0 }}</th>
                      </tr>
                      </tfoot>
                    </table>
                  </div>

                </div>
                <div class="col-md-12">
                  <div class="text-center">
                    <button class="btn btn-warning" @click="loadMoreSummary">
                      <p class="mb-0">
                        <b-icon icon="arrow-counterclockwise" font-scale="1"></b-icon>
                        {{ $t('load_more') }}
                      </p>
                    </button>
                  </div>
                </div>


              </div>
            </div>
          </b-tab>
          <b-tab :title="$t('details')">
            <div class="mt-3">
              <div class="row mb-10">
                <div class="col-md-12 mt-10" id="summaryTableDetailsTitle">
                  <h4 class="text-center">{{ $t('journal_transactions.journal_transactions') }}</h4>
                  <h5 class="text-center" v-if="filters.from_date && filters.to_date">{{ $t('from') }}
                    {{ filters.from_date }} {{ $t('to') }} {{ filters.to_date }}</h5>
                </div>

              </div>
              <div class="row mb-10">
                <div class="col-12" v-if="data_report_details">
                  <div class="table-responsive">
                    <div class="row justify-content-end p-4">
                      <b-dropdown right :text="$t('export')" class="mr-2 ml-2">
                        <button class="dropdown-item" @click="convertTableToExcelReport('detailsTable')">
                          <span><i class="fa fa-file-excel"></i> <span>{{ $t('excel') }}</span></span>
                        </button>

                        <button class="dropdown-item" @click="printData('detailsTable')">
                          <span><i class="fa fa-print"></i> <span>{{ $t('print') }}</span></span>
                        </button>
                        <button class="dropdown-item" @click="getAllDataForExportDetails('sectionForExportDetails', 'excel')">
                          <span><i class="fa fa-file-excel"></i> <span>{{ $t('all_data') }}</span></span>
                        </button>
                      </b-dropdown>
                    </div>
                    <table class="table table-bordered" id="detailsTable">
                      <thead>
                      <tr>
                        <th>{{ $t('journal_transactions.date') }}</th>
                        <th>{{ $t('journal_transactions.journal_id') }}</th>
                        <th>{{ $t('journal_transactions.account_code') }}</th>
                        <th>{{ $t('journal_transactions.account') }}</th>
                        <th>{{ $t('journal_transactions.description') }}</th>
                        <th>{{ $t('journal_transactions.source') }}</th>
                        <th>{{ $t('journal_transactions.branch') }}</th>
                        <th>{{ $t('journal_transactions.debit') }}</th>
                        <th>{{ $t('journal_transactions.credit') }}</th>

                      </tr>
                      </thead>
                      <tbody>
                      <template v-for="(row, index) in data_report_details">
                        <tr>
                          <td colspan="9">{{ row.label }}</td>
                        </tr>
                        <tr v-for="(row2, index2) in row.items" :key="'a'+getRandom()+index2">
                          <td>{{ row2.journal_date }}</td>
                          <td>{{ row2.journal_id }}</td>
                          <td>{{ row2.account_code }}</td>
                          <td>{{ row2.account_name }}</td>
                          <td>{{ row2.description }}</td>
                          <td>{{ row2.document }}</td>
                          <td>{{ row2.branch_name }}</td>
                          <td>{{ row2.debit }}</td>
                          <td>{{ row2.credit }}</td>
                        </tr>
                        <tr>
                          <td colspan="7">{{ $t('journal_transactions.total') }}</td>
                          <td>{{ row.total_debits }}</td>
                          <td>{{ row.total_credits }}</td>
                        </tr>
                      </template>

                      </tbody>
                      <tfoot>
                      <tr>
                        <td colspan="7"><b>{{ $t('journal_transactions.total') }}</b></td>
                        <th>{{ details_total_sum.total_debits ? details_total_sum.total_debits : 0 }}</th>
                        <th>{{ details_total_sum.total_credits ? details_total_sum.total_credits : 0 }}</th>
                      </tr>
                      </tfoot>
                    </table>
                  </div>

                </div>
                <div class="col-md-12">
                  <div class="text-center">
                    <button class="btn btn-warning" @click="loadMore">
                      <p class="mb-0">
                        <b-icon icon="arrow-counterclockwise" font-scale="1"></b-icon>
                        {{ $t('load_more') }}
                      </p>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </b-tab>
          <template #tabs-end>
            <li class="nav-item  d-flex my-auto">
              <select id="" v-model="filters.group_by" class="custom-select border-0 pt-0 pb-0 h-auto shadow-none"
                      name="">
                <option :value="null" disabled>{{ $t('select_filter') }}</option>
                <option value="journal">{{ $t('journal_transactions.journal') }}</option>
                <option value="branch">{{ $t('journal_transactions.branch') }}</option>

              </select>

            </li>
          </template>
        </b-tabs>


      </div>
    </div>

    <div class="export-container">
      <div class="section-for-export">
        <table class="table table-bordered" id="sectionForExportSummary">
          <thead>
          <tr>
            <th>
              <span v-if="filters.group_by == 'journal'">{{ $t('journal_transactions.journal_id') }}</span>
              <span v-if="filters.group_by == 'branch'">{{ $t('journal_transactions.branch') }}</span>
            </th>
            <th>{{ $t('journal_transactions.debit') }}</th>
            <th>{{ $t('journal_transactions.credit') }}</th>

          </tr>
          </thead>
          <tbody>
          <template v-for="(row, index) in dataForExportSummary">
            <tr>
              <td>{{ row.label }}</td>
              <td>{{ row.total_debits }}</td>
              <td>{{ row.total_credits }}</td>
            </tr>
          </template>

          </tbody>
          <tfoot>
          <tr>
            <td><b>{{ $t('journal_transactions.total') }}</b></td>
            <th>{{ dataTotalsForExportSummary.total_debits ? dataTotalsForExportSummary.total_debits : 0 }}</th>
            <th>{{ dataTotalsForExportSummary.total_credits ? dataTotalsForExportSummary.total_credits : 0 }}</th>
          </tr>
          </tfoot>
        </table>
        <table class="table table-bordered" id="sectionForExportDetails">
          <thead>
          <tr>
            <th>{{ $t('journal_transactions.date') }}</th>
            <th>{{ $t('journal_transactions.journal_id') }}</th>
            <th>{{ $t('journal_transactions.account_code') }}</th>
            <th>{{ $t('journal_transactions.account') }}</th>
            <th>{{ $t('journal_transactions.description') }}</th>
            <th>{{ $t('journal_transactions.source') }}</th>
            <th>{{ $t('journal_transactions.branch') }}</th>
            <th>{{ $t('journal_transactions.debit') }}</th>
            <th>{{ $t('journal_transactions.credit') }}</th>

          </tr>
          </thead>
          <tbody>
          <template v-for="(row, index) in dataForExportDetails">
            <tr>
              <td colspan="9">{{ row.label }}</td>
            </tr>
            <tr v-for="(row2, index2) in row.items" :key="'asdf'+getRandom()+index2+index">
              <td>{{ row2.journal_date }}</td>
              <td>{{ row2.journal_id }}</td>
              <td>{{ row2.account_code }}</td>
              <td>{{ row2.account_name }}</td>
              <td>{{ row2.description }}</td>
              <td>{{ row2.document }}</td>
              <td>{{ row2.branch_name }}</td>
              <td>{{ row2.debit }}</td>
              <td>{{ row2.credit }}</td>
            </tr>
            <tr>
              <td colspan="7">{{ $t('journal_transactions.total') }}</td>
              <td>{{ row.total_debits }}</td>
              <td>{{ row.total_credits }}</td>
            </tr>
          </template>

          </tbody>
          <tfoot>
          <tr>
            <td colspan="7"><b>{{ $t('journal_transactions.total') }}</b></td>
            <th>{{ dataTotalsForExportDetails.total_debits ? dataTotalsForExportDetails.total_debits : 0 }}</th>
            <th>{{ dataTotalsForExportDetails.total_credits ? dataTotalsForExportDetails.total_credits : 0 }}</th>
          </tr>
          </tfoot>
        </table>
      </div>
    </div>

    <div class="export-container">
      <div class="section-for-export" id="sectionForExportHeader">
        <report-main-header :print-type="'JournalTransactionsReport'"></report-main-header>
      </div>
    </div>

  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import TableToExcel from "@linways/table-to-excel";
import ReportMainHeader from "@/view/content/printing-heads/ReportMainHeader.vue";
import cssStypeForPrintReport from "@/core/config/mix/cssStypeForPrintReport";

export default {
  name: "index-journal-transactions",
  components: {ReportMainHeader},
  data() {
    return {
      mainRoute: '/v2/reports/journals',
      mainRouteDependency: 'base/dependency',

      source_list: [],
      accounts_list: [],

      data_report_details: [],
      details_total_sum: [],

      data_report_summery: [],
      summery_total_sum: [],


      dataForExportDetails: [],
      dataTotalsForExportDetails: [],

      dataForExportSummary: [],
      dataTotalsForExportSummary: [],


      filters: {
        document_type: null,
        f_account_ids: null,
        from_date: null,
        to_date: null,
        f_branches_ids: null,
        group_by: 'journal',
      },
      branches_ids: null,
      branches: [],
      json_meta: [
        [{
          key: "charset",
          value: "utf-8",
        },],
      ],
      page: 1,
      page_summary: 1,
    }
  },
  watch:{
    'filters.group_by': function (val){
      this.doFilter();
    }
  },

  beforeMount() {
    this.getDocumentTypeList();
    this.getAccountParents();
    this.getBranches();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{
      title: this.$t('MENU.accounting_reports'),
      route: '/reports/accounting-reports'
    }, {title: this.$t("MENU.journal_transactions_reports")}]);
  },
  methods: {
    ...cssStypeForPrintReport,
    getIds(related_with) {
      switch (related_with) {
        case 'branches':
          this.filters.f_branches_ids = this.branches_ids.id;
          break;
      }
    },
    getRandom() {
      return Math.floor(Math.random() * 10000);
    },
    doFilter() {
      this.page = 1;
      this.page_summary = 1;
      this.getReportSummary();
      this.getReportDetails();

    },
    resetFilter() {
      this.filters.document_type = null;
      this.filters.f_account_ids = null;
      this.filters.from_date = null;
      this.filters.to_date = null;
      this.filters.f_branches_ids = null;
      this.branches_ids = null;
      this.doFilter();
    },

    getDocumentTypeList() {
      ApiService.get(this.mainRouteDependency + "/doc_type_list").then((response) => {
        this.source_list = response.data.data;
      });
    },
    getAccountParents() {
      ApiService.get(`${this.mainRouteDependency}/${'account_parents'}`).then((response) => {
        this.accounts_list = response.data.data;
      });
    },
    getBranches() {
      ApiService.get(this.mainRouteDependency + "/branches").then((response) => {
        this.branches = response.data.data;
      });
    },
    loadMore() {
      this.page = this.page + 1;
      this.getReportDetails();
    },
    loadMoreSummary() {
      this.page_summary = this.page_summary + 1;
      this.getReportSummary();
    },
    loadOptions() {
    },
    updateValue(value) {
      this.filters.f_account_ids = value
    },


    /*****
     * details
     */

    getReportDetails() {
      let _limit = (this.page ? this.page : 1) * 25;
      ApiService.get(`${this.mainRoute}/details`, {params: {...this.filters, limit: _limit}}).then((response) => {
        this.data_report_details = response.data.data.data;
        this.details_total_sum = response.data.data.totals;
      });
    },
    getReportSummary() {
      let _limit = (this.page_summary ? this.page_summary : 1) * 25;
      ApiService.get(`${this.mainRoute}/summary`, {params: {...this.filters, limit: _limit}}).then((response) => {
        this.data_report_summery = response.data.data.data;
        this.summery_total_sum = response.data.data.totals;
      });
    },

    getAllDataForExportDetails(tableId, _export) {
      ApiService.get(`${this.mainRoute}/details`, {params: {...this.filters, is_export: 1}}).then((response) => {
        this.dataForExportDetails = response.data.data.data;
        this.dataTotalsForExportDetails = response.data.data.totals;

        this.convertTableToExcelReport(tableId);
      });
    },

    getAllDataForExportSummary(tableId, _export) {
      ApiService.get(`${this.mainRoute}/summary`, {params: {...this.filters, is_export: 1}}).then((response) => {
        this.dataForExportSummary = response.data.data.data;
        this.dataTotalsForExportSummary = response.data.data.totals;

        this.convertTableToExcelReport(tableId);
      });
    },


    convertTableToExcelReport(tableId) {
      this.setReportLog('excel', 'journal transactions report');
      setTimeout(() => {
        TableToExcel.convert(document.getElementById(tableId), {
          name: this.$t('MENU.journal_transactions_reports') + '.xlsx',
          sheet: {
            name: 'Sheet 1'
          }
        });
      }, 100)
    },
    printData(tableId) {
      this.setReportLog('pdf', 'journal transactions report');
      let divToPrint = document.getElementById(tableId);
      let divTitleToPrint = document.getElementById('sectionForExportHeader');
      let newWin = window.open("");
      newWin.document.write('<meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">');
      newWin.document.write("<link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css'>");
      newWin.document.write('<style>');
      newWin.document.write(this.getCssStyleForPrintReport());
      newWin.document.write('</style>');
      newWin.document.write(divTitleToPrint.outerHTML + divToPrint.outerHTML);
      setTimeout(() => {
        newWin.print();
        // newWin.close();
      }, 100)
    },
    /**
     * register log
     */
    setReportLog(key, description) {
      ApiService.post("reports/system_activity_log/log_type", {
        key: key,
        description: description,
      });
    }
  },
};
</script>
