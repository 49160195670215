<template>
    <div>

        <div>
            <!--begin::Row-->
            <div class="row m-0">
              <template v-for="(li, index) in links">
                <div class="col-md-2 mt-3" v-if="$can(li.permission)" >
                  <router-link :to="li.link" class="new-cards col bg-light-warning px-6 py-6 rounded-xl w-100 text-center d-flex my-auto justify-content-center">
                    <div class="font-weight-bold font-size-h5 text-center">
                      <div class="font-weight-bold">{{$t(li.label)}}</div>
                    </div>
                  </router-link>

                </div>

              </template>

            </div>
            <!--end::Row-->
        </div>
    </div>
</template>

<script>
    export default {
        name: "QuickLinks",
      data(){
          return{
            links:[
              {permission:'pos_session.create', link:'/pos/pos-session/create',label:'start_session'},
              {permission:'sales_invoices.create', link:'/sales/sales_invoices/create',label:'create_invoice'},
              {permission:'point_sales.list', link:'/point-of-sales/point-of-sales',label:'pos_session_n'},
              {permission:'sales_invoices.list', link:'/sales/sales_invoices',label:'invoices'},
              {permission:'customers.list', link:'customers/customers',label:'clients_n'},
              {permission:'customers.create', link:'customers/customers/create',label:'new_client'},
            ]
          }
      }
    }
</script>

<style scoped>

</style>