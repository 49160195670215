<template>
  <div>
    <!--begin::customer-->

    <div class="col-lg-12 mb-5">
      <label>{{ $t('row_numbers') }}</label>
      <div class="row">
        <div class="col-sm-2">
          <input type="number"  class="form-control" @input="changeRowNumbers" :value="row_numbers"/>
        </div>
      </div>
    </div>

    <div class="w-100" v-if="is_show" style="height: 500px;overflow-y: scroll;">
      <div id="ticket" style="width: 1411.5px;height: auto;float: left;direction: ltr;display: block;background-color: rgb(255, 255, 255);">
        <div :style="{'width': '1411.5px', height: i_height,'display': 'flex', 'page-break-after': 'always'}" v-for="(_row, _index) in row_numbers" :key="'row_numbers'+_index">
          <div :style="style_card" v-for="(row, index) in label_per_row" :key="'label_per_row'+index">
            <div :style="{'padding-left': i_padding, 'padding-right': i_padding, 'border': '1px solid #000000','border-radius': '7px','width': '100%','float': 'left', 'font-size': font_size, 'font-weight': '700'}">
              <div style="text-align: center;margin-bottom: 5px;">{{ data.company_name }}</div>
<!--              <div style="text-align: center"><hr style="width: 50%;margin: 0 auto;border: 1px solid #000000;"></div>-->
<!--              <div style="display: flex;justify-content: space-between;align-items: center;">-->
<!--                <div style="text-align: start;">-->
<!--                  <p style="margin: 0;"><span>Exp: </span><span>{{ data.num_valid_days }} </span> <span>{{ data.interval_type_name }}</span></p>-->
<!--                  <p style="width: 100%;text-align: left;margin: 0;"><span>Price:</span><span>{{ data.price }}</span> <span>{{ currencyCode }}</span></p>-->
<!--                </div>-->
<!--                <span>{{ data.name }}</span>-->
<!--              </div>-->

              <div  style="display: block;">
                <div style="text-align: start;display: flex;justify-content: space-between;">
                  <p style="margin: 0;width: 100%;text-align: start;"><span>Exp: </span><span>{{ data.num_valid_days }} </span> <span>{{ data.interval_type_name }}</span></p>
                  <p style="width: 100%;margin: 0;text-align: end;"><span>Price: </span> <span>{{ data.price }}</span> <span>{{ currencyCode }}</span></p>
                </div>
                <div style="display: block;text-align: center;"><span>{{ data.name }}</span></div>
              </div>

              <div style="text-align: center;">
                <barcode v-if="data.barcode" :value="data.barcode" :options="barcode_options"  tag="svg"></barcode>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="card-footer pl-0 pr-0 pb-0">
      <div class="row pt-5">
        <div class="col-lg-2 d-flex">
          <button type="reset" class="btn btn-secondary" @click="cancel">{{ $t('cancel') }}</button>
          <button type="reset" class="btn btn-primary ml-3 mr-3" @click="printTicket">{{ $t('print') }}</button>
        </div>
      </div>
    </div>

    <!--end::customer-->
  </div>
</template>
<script>
import ApiService from "@/core/services/api.service";

export default {
  name: "modal-product-information",
  props: {
    hideModal: {type: Function},
    id: null,
    currencyCode: null,

  },
  data() {
    return {
      mainRouteDependency: 'base/dependency',
      data: {},
      items_configuration: {},
      style_card: {},
      label_per_row: 1,
      is_show: false,
      row_numbers: 1,
      i_height: 0,
      i_padding: 0,
      font_size: 0,
      barcode_options: {},
    };
  },

  methods: {
    cancel() {
      this.$emit('hide-modal');
    },
    async getData() {
      await ApiService.get(this.mainRouteDependency + "/item/" + this.id).then((response) => {
        this.data = response.data.data;
      });
    },
    async getItemsConfiguration() {
      await ApiService.get("/items/items_configuration").then((response) => {
        this.items_configuration = response.data.data;

        let _height = response.data.data.height ? response.data.data.height : 0;
        let _width = response.data.data.width ? response.data.data.width : 0;
        let _margin = response.data.data.margin ? response.data.data.margin : 0;
        let _padding = response.data.data.padding ? response.data.data.padding : 0;
        let _unit_standard = response.data.data.unit_standard ? response.data.data.unit_standard : 'px';
        this.label_per_row = response.data.data.label_per_row ? response.data.data.label_per_row : 1;
        this.font_size = response.data.data.font_size ? response.data.data.font_size : 7;


        let _height_unit = _height + _unit_standard;
        let _width_unit = _width + _unit_standard;

        let _margin_unit = _margin + 'px';
        this.i_padding = _padding + 'px';
        this.font_size = this.font_size + 'px';

        this.i_height =  (parseFloat(_height) + parseFloat(_margin) + parseFloat(_padding)).toFixed(2);
        this.i_height =  (this.i_height).toString() + _unit_standard;

        this.style_card = {
          'width': _width_unit,
          'height': _height_unit,
          'margin': _margin_unit,
          'float': 'left',
          'direction': 'ltr',
          'display': 'flex',
          'background-color': '#FFFFFF'
        };

        this.barcode_options = {
            height: response.data.data.barcode_height ? response.data.data.barcode_height : 1,
            width: response.data.data.barcode_width ? response.data.data.barcode_width : 1,
            fontSize: response.data.data.barcode_font_size ? response.data.data.barcode_font_size : 1,
            marginTop: response.data.data.barcode_margin ? response.data.data.barcode_margin : 0,
            margin: 0,
        };

        this.is_show = true;
      });
    },
    printTicket() {
      if (document.getElementById('ticket')) {
        const content = document.getElementById('ticket').innerHTML;

        const printWindow = window.open('', '_blank');

        printWindow.document.write('<html><head><title>Print</title><meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">');
        printWindow.document.write('</head><style>body{margin: 0;padding: 0;}</style><body>');
        printWindow.document.write(content);
        printWindow.document.write('</body></html>');


        setTimeout(() => {
          printWindow.print();
          // printWindow.close();
        }, 300);

      }
    },
    changeRowNumbers(event){
      this.row_numbers = event.target.value ? parseInt(event.target.value) : 1;
    }
  },

  mounted() {
    let _promise_1 = this.getData();
    Promise.all([_promise_1]).then(()=>{
      this.getItemsConfiguration();
    })

  },
};
</script>