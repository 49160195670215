<template>
  <div>
    <!--begin::customer-->
    <div>
      <h3 class="card-label m-0">
        {{ $t('purchase_quotations.purchase_quotations') }}
        <span class="text-muted pt-2 font-size-sm d-block"></span>
      </h3>
      <div class="row">
        <div class="col-lg-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-12 mb-5">
                <label>{{ $t('purchase_quotations.title') }}<span class="text-danger">*</span></label>
                <input type="text" v-model="data.title" class="form-control" :class="validation && validation.title ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.title" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.title[0] }}
                            </span>
              </div>
              <div class="col-lg-12 mb-5">
                <label>{{ $t('purchase_quotations.supplier') }}</label>
                <div class="form-group row">
                  <div class="col-sm-8">
                    <!-- <select name="" id="supplier_id" v-model="data.supplier_id" class="custom-select" :class="validation && validation.supplier_id ? 'is-invalid' : ''">
                        <option v-for="row in suppliers" :value="row.id" :key="row.id">{{ row.full_name }}</option>
                    </select> -->
                    <multiselect v-model="supplier"
                                 :placeholder="$t('purchase_quotations.supplier')"
                                 label="name"
                                 track-by="id"
                                 :options="suppliers"
                                 :multiple="false"
                                 :taggable="false"
                                 :show-labels="false"
                                 :show-no-options="false"
                                 :show-no-results="false"
                                 @search-change="getSuppliers($event)">
                    </multiselect>
                    <span v-if="validation && validation.supplier_id" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.supplier_id[0] }}
                                    </span>
                  </div>
                  <div class="col-sm-4">
                    <button class="btn btn-primary" @click="showModal">{{ $t('add_new') }}</button>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div class="card card-custom mt-5">
            <div class="card-body row">
              <div class="col-lg-6 mb-5">
                <label>{{ $t('purchase_quotations.currency') }}</label>
                <div class="input-group mb-3">
                  <select name="" id="currency_id" v-model="data.currency_id" class="custom-select" :class="validation && validation.currency_id ? 'is-invalid' : ''">
                    <option v-for="row in currencies" :value="row.id" :key="row.id">{{ row.name }}</option>
                  </select>
                  <div class="input-group-prepend">
                    <a class="btn btn-primary" href="/settings/currencies" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                  </div>
                </div>
                <span v-if="validation && validation.currency_id" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.currency_id[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('exchange_rate') }}</label>
                <input type="number" min="0" v-model="data.exchange_rate" class="form-control" :class="validation && validation.exchange_rate ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.exchange_rate" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.exchange_rate[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('purchase_quotations.shipping_with') }}</label>
                <select name="" id="currency_id" v-model="data.shipping_with" class="custom-select" :class="validation && validation.shipping_with ? 'is-invalid' : ''">
                  <option v-for="row in shipping_with_list" :value="row.id" :key="row.id">{{ row.title }}</option>
                </select>

                <span v-if="validation && validation.shipping_with" class="fv-plugins-message-container invalid-feedback">
                  {{ validation.shipping_with[0] }}
              </span>
              </div>
              <div class="col-lg-6 mb-5" v-if="data.shipping_with == 1">
                <label>{{ $t('purchase_quotations.shipping_fees') }}</label>
                <input type="number" min="0" v-model="data.shipping_fees" class="form-control" :class="validation && validation.shipping_fees ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.shipping_fees" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.shipping_fees[0] }}
                            </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-6 mb-5">
                <label>{{ $t('purchase_quotations.purchase_quotation_code') }}</label>
                <input type="text" v-model="data.purchase_quotation_code" class="form-control" :class="validation && validation.purchase_quotation_code ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.purchase_quotation_code" class="fv-plugins-message-container invalid-feedback">
                                    {{ validation.purchase_quotation_code[0] }}
                                </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('purchase_quotations.purchase_quotation_date') }}<span class="text-danger">*</span></label>
                <input type="date" v-model="data.purchase_quotation_date" class="form-control" :class="validation && validation.purchase_quotation_date ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.purchase_quotation_date" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.purchase_quotation_date[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('purchase_quotations.purchase_due_date') }}<span class="text-danger">*</span></label>
                <input type="date" v-model="data.purchase_due_date" class="form-control" :class="validation && validation.purchase_due_date ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.purchase_due_date" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.purchase_due_date[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('purchase_quotations.branch') }}</label>
                <!-- <select name="" id="branch" v-model="data.branch_id" type="text" class="custom-select" :class="validation && validation.branch_id ? 'is-invalid' : ''">
                    <option v-for="(row, index) in branches" :value="row.id" :key="index">{{ row.name }}</option>
                </select> -->
                <div class="input-group mb-3">
                  <multiselect v-model="branch"
                               :placeholder="$t('purchase_quotations.branch')"
                               label="name"
                               track-by="id"
                               :options="branches"
                               :multiple="false"
                               :taggable="false"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false">
                  </multiselect>
                  <div class="input-group-prepend">
                    <a class="btn btn-primary" href="/settings/branches/create" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                  </div>
                </div>
                <span v-if="validation && validation.branch_id" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.branch_id[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('status') }}</label>
                <select name="" id="f_status" v-model="data.status" type="text" class="custom-select" :class="validation && validation.status ? 'is-invalid' : ''">
                  <option v-for="(row, index) in status_list" :value="row.id" :key="index">{{ row.title }}</option>
                </select>
                <span v-if="validation && validation.status" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.status[0] }}
                                    </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-10">
        <div class="col-md-12">
          <div class="bg-f3f3f3 d-flex justify-content-between mt-2 p-2">
            <button type="button" class="btn btn-primary" @click="addItemRowToList"><i class="fa fa-plus-square mb-1"></i> <span>{{ $t('add_more') }}</span></button>

          </div>
        </div>
        <div class="col-md-12">
          <div class="table-responsive bg-white">
            <table class="table table-row-bordered table-custom-padding" @keyup.alt.enter="addItemRowToList" @keyup.alt.46="removeItemRowFromList">
              <thead>
              <tr>
                <th width="250px">{{ $t('items.item_details') }}</th>
                <th width="300px">
                  <div class="d-flex justify-content-between">
                    <span>{{ $t('purchase_quotations.item') }}</span>
                    <a href="javascript:;" class="btn btn-primary btn-sm p-1" @click="showItemModal">
                      <b-icon icon="plus"></b-icon>
                    </a>
                  </div>
                </th>
                <th>{{ $t('purchase_quotations.unit_price') }}</th>
                <th>{{ $t('purchases_refunds.unit') }}</th>
                <th>{{ $t('purchase_quotations.qty') }}</th>
                <th>{{ $t('purchase_quotations.subtotal') }}</th>
                <th></th>
              </tr>

              </thead>
              <tbody>
              <tr v-for="(row, index) in items_list" :key="index" :class="getClassValidationRepeaterByIndex(index)">
                <td>
                  <a :id="'popover-target-'+index" href="javascript:;" v-if="row.item" class="d-flex cursor-pointer">
                    <img v-if="is_show_image_in_repeater" :src="row.item.image_url" style="width: 70px; height: 70px;" alt="">
                    <div class="pl-2 pr-2 text-dark">
                      <p class="mb-1" v-if="row.item.sku_code">{{ $t('items.sku_code') }}: {{ row.item.sku_code }}</p>
                      <p class="mb-1" v-if="row.item.name">{{ $t('items.name') }}: {{ row.item.name }}</p>
                      <p class="mb-1" v-if="row.item.sale_price">{{ $t('items.sale_price') }}: {{ row.item.sale_price }}</p>
                      <p class="mb-1" v-if="row.item.purchase_price">{{ $t('items.purchase_price') }}: {{ row.item.purchase_price }}</p>
                      <p class="mb-1" v-if="row.item.sum_available_qty">{{ $t('items.sum_available_qty') }}: {{ row.item.sum_available_qty }}</p>
                    </div>
                  </a>
                </td>
                <td>
                  <div class="d-flex search-item-group">
                    <select name="" id="" v-model="row.f_by" class="custom-select" style="width: 100px;">
                      <option value="name">{{ $t('items.name') }}</option>
                      <option value="sku_code">{{ $t('items.sku_code') }}</option>
                    </select>
                    <multiselect v-model="row.item"
                                 :placeholder="$t('purchase_quotations.item')"
                                 :label="row.f_by? row.f_by:'name'"
                                 track-by="id"
                                 :options="items"
                                 :multiple="false"
                                 :taggable="false"
                                 :show-labels="false"
                                 @input="selectItem(index)"
                                 :show-no-options="false"
                                 :show-no-results="false"
                                 :internal-search="false"
                                 @search-change="getItems(row.f_by, $event)">
                    </multiselect>
                  </div>
                  <span class="form-text text-muted" style="text-align:end">{{ $t('type_at_least_three_letters') + ' ' + $t('purchases_requests.item') }}.</span>
                  <span v-if="validation && validation[`items_list.${index}.item`]" class="fv-plugins-message-container invalid-feedback">
                            {{ validation[`items_list.${index}.item`][0] }}
                          </span>
                </td>
                <td>
                  <input v-model="row.unit_price" @input="setValue(index)" min="0" type="number" class="form-control">
                  <span v-if="validation && validation[`items_list.${index}.unit_price`]" class="fv-plugins-message-container invalid-feedback">
                            {{ validation[`items_list.${index}.unit_price`][0] }}
                          </span>
                </td>
                <td>
                  <multiselect v-model="row.unit"
                               :placeholder="$t('purchases_refunds.unit')"
                               label="name"
                               track-by="id"
                               :options="(row.item && row.item.units) ? row.item.units:[]"
                               :multiple="false"
                               :taggable="false"
                               :show-labels="false"
                               @input="selectUnit(index)"
                               :show-no-options="false"
                               :show-no-results="false">
                  </multiselect>
                  <span v-if="validation && validation[`items_list.${index}.unit`]" class="fv-plugins-message-container invalid-feedback">
                            {{ validation[`items_list.${index}.unit`][0] }}
                          </span>

                  <input v-model="row.units_number" @input="setValue(index)" v-if="row.item && row.item.unit && row.unit && row.item.unit.id != row.unit.id" min="0" type="number" class="form-control mt-3">

                </td>
                <td>
                  <input v-model="row.qty" @input="setValue(index)" :disabled="row.item && row.item.unit && row.unit && row.item.unit.id != row.unit.id" min="0" type="number" class="form-control">
                  <span v-if="validation && validation[`items_list.${index}.qty`]" class="fv-plugins-message-container invalid-feedback">
                        {{ validation[`items_list.${index}.qty`][0] }}
                      </span>
                </td>
                <td class="text-center">{{ row.subtotal }}</td>
                <td>
                  <v-icon style="color: #dc3545;" small v-if="items_list.length > 1" @click="removeItemRowFromList(index)">mdi-delete</v-icon>
                </td>
              </tr>
              </tbody>
              <tfoot>

              <tr>
                <td class="border-0" colspan="4"></td>
                <th>{{ $t('purchase_quotations.total') }}</th>
                <th class="text-center"><span>{{ data.total }}</span></th>
                <th class="border-0"></th>
              </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
      <div class="mt-5">
        <b-tabs content-class="mt-3" class="nav-custom-link">
          <b-tab :title="$t('notes')">
            <div class="card card-custom">
              <div class="card-body row">
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('purchase_quotations.notes') }}</label>
                  <textarea name="" id="notes" v-model="data.notes" class="form-control" :class="validation && validation.notes ? 'is-invalid' : ''"></textarea>
                  <span v-if="validation && validation.notes" class="fv-plugins-message-container invalid-feedback">
                                    {{ validation.notes[0] }}
                                </span>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('purchase_quotations.attachment') }}</label>
                  <div class="form-group">
                    <upload-file
                        @file="listenerAttachment"
                        :inner-id="'attachment'"
                        :placeholder="$t('purchase_quotations.attachment')"
                        :upload="dir_upload"
                        :start-link="'base'"
                        v-bind:valueprop="{name:'attachment',path:attachment_url}" v-if="reloadUploadAttachment">
                    </upload-file>
                  </div>
                </div>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>

      <div class="pl-0 pr-0">
        <div class="row">
          <div class="col-lg-6 mt-10">
            <button type="reset" class="btn btn-primary mr-2" @click="save($event)">{{ $t('save') }}</button>
            <button type="reset" class="btn btn-secondary">{{ $t('cancel') }}</button>
          </div>
        </div>
      </div>
    </div>
    <!--end::customer-->
    <b-modal ref="modal" size="lg" hide-footer :title="$t('suppliers.add_new_supplier')">
      <supplier-form @hide-modal="hideModal()" @handling-data="getDataAfterCreateNewSupplier"></supplier-form>
    </b-modal>
    <b-modal ref="modalItem" size="lg" hide-footer :title="$t('items.add_new_item')">
      <item-form @hide-modal="hideItemModal()" @handling-data="getDataAfterCreateNewItem"></item-form>
    </b-modal>
  </div>
</template>
<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import SupplierForm from "@/view/content/forms/SupplierForm";
import ItemForm from "@/view/content/forms/ItemForm";
import {mapGetters, mapState} from "vuex";
import timeZoneStructure from "@/core/config/mix/timeZoneStructure";

export default {
  name: "form-purchases-quotation-requests",
  components: {'supplier-form': SupplierForm, 'item-form': ItemForm},
  data() {
    return {
      mainRoute: 'purchases/purchases_quotations',
      mainRouteQuotationRequests: 'purchases/quotation-requests',
      mainRouteDependency: 'base/dependency',
      dir_upload: 'purchases',
      mainSettingsRoute: 'purchases/purchases-settings',

      data: {
        title: null,
        purchase_quotation_code: null,
        attachment: null,
        status: 1,
        purchase_quotation_date: null,
        supplier_id: null,
        branch_id: null,
        purchase_due_date: null,
        notes: null,

        currency_id: null,
        shipping_with: null,
        shipping_fees: null,
        exchange_rate: null,

        total: 0,

      },
      idEditing: this.$route.params.id ? this.$route.params.id : null,
      isEditing: false,
      reloadUploadAttachment: true,
      attachment_url: '',

      status_list: [],
      shipping_with_list: [
        {id: 1, title: this.$t('purchase_quotations.supplier')},
        {id: 2, title: this.$t('purchase_quotations.company')},
      ],

      validation: null,
      items_list_form: {id: null, item: null, qty: null, unit_price: null, subtotal: 0, unit: null, units_number: null},
      items_list: [],
      items: [],
      branches: [],
      suppliers: [],
      supplier: null,
      branch: null,
      currency: null,
      currencies: [],
      repeater_validation: [],
      is_show_image_in_repeater: false,
    };
  },
  computed: {
    ...mapState({user_personal_info: state => state.profile.user_personal_info}),
    ...mapGetters(["currentUserPersonalInfo"]),
  },
  watch: {

    supplier: function (val) {
      if (val) {
        this.data.supplier_id = val.id;
      }
    },
    branch: function (val) {
      if (val) {
        this.data.branch_id = val.id;
      }
    },
    'data.shipping_with': function (val) {
      if (val != 1) {
        this.data.shipping_fees = null;
      }
    },
    "data.currency_id": function (val) {
      if (val) {
        this.getExchangeRate(val);
      } else {
        this.data.exchange_rate = null;
      }
    },
  },
  methods: {
    ...timeZoneStructure,
    // setValue() {
    //   if (this.items_list && this.items_list.length > 0) {
    //
    //     this.items_list.map((row) => {
    //       let _qty = row.qty ? parseFloat(row.qty) : 0;
    //       if (_qty < 0) {
    //         row.qty = 0;
    //       }
    //       let _price = row.unit_price ? parseFloat(row.unit_price) : 0;
    //       if (_price < 0) {
    //         row.unit_price = 0;
    //       }
    //       row.subtotal = (_qty * _price).toFixed(2);
    //
    //       return row;
    //     });
    //
    //     this.data.total = this.$_.sumBy(this.items_list, (row) => {
    //       return row.subtotal ? parseFloat(row.subtotal) : 0;
    //     });
    //   }
    // },
    validationQTYChecking(_status = true) {
      let _main_validation_status = false;
      this.validation = [];
      this.items_list.forEach((row, index) => {
        let _validation_status = false;

        if (!row.item && _status) {
          this.validation[`items_list.${index}.item`] = [];
          this.validation[`items_list.${index}.item`][0] = this.$t('the_item_field_must_be_required');
          _validation_status = true;
        }

        // if (parseFloat(row.unit_price) <= 0) {
        //   this.validation[`items_list.${index}.unit_price`] = [];
        //   this.validation[`items_list.${index}.unit_price`][0] = this.$t('unit_price_must_be_greater_than_0');
        //   _validation_status = true;
        // }else if(!row.unit_price && _status){
        //   this.validation[`items_list.${index}.unit_price`] = [];
        //   this.validation[`items_list.${index}.unit_price`][0] = this.$t('unit_price_must_be_greater_than_0');
        //   _validation_status = true;
        // }

        if (!row.unit && _status) {
          this.validation[`items_list.${index}.unit`] = [];
          this.validation[`items_list.${index}.unit`][0] = this.$t('the_unit_field_must_be_required');
          _validation_status = true;
        }

        if (parseFloat(row.qty) > 0){
          if (parseFloat(row.subtotal) <= 0) {
            _validation_status = true;
          }else if(!row.subtotal && _status){
            _validation_status = true;
          }
        }


        if (_validation_status && _status) {
          let _index = this.repeater_validation.indexOf(index);
          if (_index < 0) {
            this.repeater_validation.push(index);
          }
        } else {
          let _index = this.repeater_validation.indexOf(index);
          if (_index > -1) {
            this.repeater_validation.splice(_index, 1);
          }
        }

      });
      if (this.repeater_validation.length > 0) {
        _main_validation_status = true;
      }
      if (_main_validation_status) {
        let _items = [...this.items_list];
        this.items_list = [];
        this.items_list = _items;
        if (_status)
          this.$errorAlertMessage(this.$t('unable_to_send_due_to_data_entry_error'));
      }
      return _main_validation_status;

    },
    save(event) {
      if (this.validationQTYChecking()) {
        event.preventDefault();
        return false;
      } else {
        if (this.isEditing) {
          this.update();
        } else {
          this.create();
        }
      }
    },

    create() {
      let _items_list = [...this.items_list];
      _items_list = _items_list.filter((row) => (parseFloat(row.qty) > 0 && parseFloat(row.unit_price) > 0));

      ApiService.post(`${this.mainRoute}`, {
        items_list: _items_list,
        ...this.data,
      })
          .then((response) => {
            this.validation = null;
            this.$successAlert(response.data.message);
            this.$router.push({name: 'purchase-quotations.index'});
          })
          .catch((error) => {
            this.$errorAlert(error);
            this.validation = error.response ? error.response.data.errors : null;
          });
    },


    update() {
      let _items_list = [...this.items_list];
      _items_list = _items_list.filter((row) => (parseFloat(row.qty) > 0 && parseFloat(row.unit_price) > 0));

      ApiService.put(`${this.mainRoute}/${this.$route.params.id}`, {
        items_list: _items_list,
        ...this.data,
      })
          .then((response) => {
            this.validation = null;
            this.$router.push({name: 'purchase-quotations.index'});
            this.$successAlert(response.data.message);
          })
          .catch((error) => {
            this.$errorAlert(error);
            this.validation = error.response ? error.response.data.errors : null;
          });
    },
    getClassValidationRepeaterByIndex(index) {
      if (index != null) {
        if (this.repeater_validation.includes(index))
            // return 'tr-validation-error';
          return 'border-validation';
      }
      return '';
    },
    selectItem(index) {
      this.items_list[index].unit = null;
      this.items_list[index].units_number = null;
      this.items_list[index].qty = null;
      if (this.items_list[index].item && this.items_list[index].item.units && this.items_list[index].item.units.length > 0) {
        this.items_list[index].unit = this.items_list[index].item.units.find((row) => row.is_main);
      }
      this.setValue(index);
    },
    selectUnit(index) {
      this.items_list[index].units_number = null;
      this.items_list[index].qty = null;

      this.setValue(index);
    },

    setValue(index) {

      this.zeroForNegative();

      if (this.items_list[index].item && this.items_list[index].unit && this.items_list[index].item.unit) {
        if (this.items_list[index].item.unit.id != this.items_list[index].unit.id) {
          if (this.items_list[index].item.is_min) {
            this.items_list[index].qty = ((this.items_list[index].unit.number ? parseFloat(this.items_list[index].unit.number) : 0) * (this.items_list[index].units_number ? parseFloat(this.items_list[index].units_number) : 0)).toFixed(3);
          } else {
            this.items_list[index].qty = ((this.items_list[index].units_number ? parseFloat(this.items_list[index].units_number) : 0) / (this.items_list[index].unit.number ? parseFloat(this.items_list[index].unit.number) : 0)).toFixed(3);
          }
        } else {
          this.items_list[index].units_number = 0;
        }
      }


      let _qty = this.items_list[index].qty ? parseFloat(this.items_list[index].qty) : 0;
      if (_qty < 0) {
        this.items_list[index].qty = 0;
      }
      let _price = this.items_list[index].unit_price ? parseFloat(this.items_list[index].unit_price) : 0;
      if (_price < 0) {
        this.items_list[index].unit_price = 0;
      }
      this.items_list[index].subtotal = (_qty * _price).toFixed(2);


      this.data.total = this.$_.sumBy(this.items_list, (row) => {
        return row.subtotal ? parseFloat(row.subtotal) : 0;
      });

      this.data.total = (this.data.total).toFixed(2);
    },
    zeroForNegative() {
      this.items_list = this.items_list.map((row) => {
        if (String(row.qty).length > 9) {
          row.qty = String(row.qty).slice(0, 9);
        }

        if (isNaN(row.qty)) {
          row.qty = 0;
        } else if (parseFloat(row.qty) < 0) {
          row.qty = 0;
        }


        if (isNaN(row.units_number)) {
          row.units_number = 0;
        } else if (parseFloat(row.units_number) < 0) {
          row.units_number = 0;
        }

        if (isNaN(row.unit_price)) {
          row.unit_price = 0;
        } else if (parseFloat(row.unit_price) < 0) {
          row.unit_price = 0;
        }

        return row;

      });
    },


    async getData() {
      this.reloadUploadAttachment = false;
      ApiService.get(`${this.mainRoute}/${this.$route.params.id}`).then((response) => {
        this.isEditing = true;
        this.data.title = response.data.data.title;
        this.data.purchase_quotation_code = response.data.data.purchase_quotation_code;
        this.data.attachment = response.data.data.attachment;
        this.data.status = response.data.data.status;
        this.data.purchase_quotation_date = response.data.data.purchase_quotation_date;
        this.data.supplier_id = response.data.data.supplier_id;
        this.data.branch_id = response.data.data.branch_id;
        this.data.purchase_due_date = response.data.data.purchase_due_date;
        this.data.notes = response.data.data.notes;
        this.data.total = response.data.data.total;

        this.supplier = response.data.data.supplier;
        this.branch = response.data.data.branch;

        this.data.currency_id = response.data.data.currency_id;
        this.data.shipping_with = response.data.data.shipping_with;
        this.data.shipping_fees = response.data.data.shipping_fees;
        this.data.exchange_rate = response.data.data.exchange_rate;

        this.attachment_url = response.data.data.attachment_url;
        this.items_list = response.data.data.items_list;

        if (response.data.data.items_list && response.data.data.items_list.length <= 0) {
          this.addItemRowToList();
        }
        this.reloadUploadAttachment = true;
      });
    },

    async getDataForQuotationRequests() {
      this.reloadUploadAttachment = false;
      if (this.$route.params.quotation_requests_id)
        ApiService.get(`${this.mainRouteQuotationRequests}/${this.$route.params.quotation_requests_id}`).then((response) => {
          this.data.title = response.data.data.title;
          // this.data.purchase_quotation_code = response.data.data.purchase_quotation_code;
          this.data.attachment = response.data.data.attachment;
          // this.data.status = response.data.data.status;
          this.data.purchase_quotation_date = response.data.data.purchase_quotation_request_date;
          this.data.supplier_id = response.data.data.supplier_id;
          this.data.branch_id = response.data.data.branch_id;
          this.data.purchase_due_date = response.data.data.purchase_request_due_date;
          this.data.notes = response.data.data.notes;

          this.supplier = response.data.data.supplier;
          this.branch = response.data.data.branch;


          this.attachment_url = response.data.data.attachment_url;


          if (response.data.data.items_list && response.data.data.items_list.length <= 0) {
            this.addItemRowToList();
          } else {
            this.items_list = response.data.data.items_list.map((row) => {
              return {id: null, item: row.item, qty: row.qty, unit_price: 0, subtotal: 0};
            });
          }
          this.reloadUploadAttachment = true;
        });
    },
    getStatus() {
      ApiService.get(this.mainRouteDependency + "/status", {params: {type: 'purchases_quotation_requests'}}).then((response) => {
        this.status_list = response.data.data;
      });
    },
    getItems(f_by = null, filter = null) {
      let _f_by = f_by ? f_by : 'name';
      if (filter && filter.length >= 3 && _f_by)
        ApiService.get(this.mainRouteDependency + "/items", {params: {[_f_by]: filter, 'item_type': 1}}).then((response) => {
          this.items = response.data.data;
        });
    },
    getBranches() {
      ApiService.get(this.mainRouteDependency + "/branches").then((response) => {
        this.branches = response.data.data;
      });
    },
    async getSuppliers(filter) {
      if (filter && filter.length >= 3)
        await ApiService.get(this.mainRouteDependency + "/suppliers", {params: {filter: filter}}).then((response) => {
          this.suppliers = response.data.data;
        });
    },
    loadOptions() {
    },


    addItemRowToList() {
      this.items_list.unshift(this.items_list_form);
      this.items_list_form = {id: null, item: null, qty: null, unit_price: null, subtotal: 0, unit: null, units_number: null};
    },
    removeItemRowFromList(index = 0) {
      if (this.items_list.length > 1) {
        this.items_list.splice(index, 1);
      }
    },
    listenerAttachment(event) {
      if (event) {
        this.attachment_url = event.pathDB;
        this.data.attachment = event.name;
      }
    },

    showItemModal() {
      this.$refs['modalItem'].show()
    },
    hideItemModal() {
      this.$refs['modalItem'].hide();
    },

    getDataAfterCreateNewItem(object) {
      let promise = this.getItems();
      Promise.all([promise]).then(() => {
        if (object) {
          this.items_list[0].item = object;
        }
      })
    },

    showModal() {
      this.$refs['modal'].show()
    },
    hideModal() {
      this.$refs['modal'].hide();
    },
    getDataAfterCreateNewSupplier(object) {
      let promise = this.getSuppliers();
      Promise.all([promise]).then(() => {
        this.data.supplier_id = object.id;
      })
    },
    getCode() {
      ApiService.get(this.mainRouteDependency + "/code-setting/18").then((response) => {
        this.data.purchase_quotation_code = response.data.data.code;
      });
    },
    async getCurrencies() {
      await ApiService.get(this.mainRouteDependency + "/currencies").then((response) => {
        this.currencies = response.data.data;
      });
    },
    async getDefaultCurrency() {
      await ApiService.get(this.mainRouteDependency + "/default-currency").then((response) => {
        this.data.currency_id = response.data.data.id;
      });
    },
    getExchangeRate(id) {
      ApiService.get(`${this.mainRouteDependency}/exchange_rate/` + id).then((response) => {
        this.data.exchange_rate = response.data.data.initial_val;
      });
    },

    defaultDataForUser() {
      ApiService.get(this.mainRouteDependency + "/default_data_for_user").then((response) => {
        this.data.currency_id = response.data.data.currency_id;
        this.data.branch_id = response.data.data.branch_id;
        this.branch = response.data.data.branch;

      });
    },
    getSettings() {
      ApiService.get(this.mainSettingsRoute).then((response) => {
        this.is_show_image_in_repeater = response.data.data.is_show_image_in_repeater? response.data.data.is_show_image_in_repeater : false;
      });
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.purchase_quotations"), route: '/purchases/purchase-quotations'}, {title: (this.idEditing ? this.$t('update') : this.$t('create'))}]);

    // this.getItems();
    // let promise = this.getDefaultCurrency();
    this.getSettings();
    this.getStatus();
    this.getBranches();
    this.getSuppliers();
    this.getCurrencies();

    // Promise.all([promise]).then(() => {
    if (this.idEditing) {
      this.getData();
    } else if (this.$route.params.quotation_requests_id) {
      this.getCode();
      // this.data.purchase_quotation_date = new Date().toISOString().slice(0, 10);
      // this.data.purchase_due_date = new Date().toISOString().slice(0, 10);
      this.getTimeZone().then((res)=>{
        this.data.purchase_quotation_date = res;
        this.data.purchase_due_date = res;
      });
      this.getDataForQuotationRequests();
      this.defaultDataForUser();
    } else {
      if (this.items_list.length <= 0) {
        this.addItemRowToList();
      }
      this.defaultDataForUser();
      this.getCode();
      this.getTimeZone().then((res)=>{
        this.data.purchase_quotation_date = res;
        this.data.purchase_due_date = res;
      });
      // this.data.purchase_quotation_date = new Date().toISOString().slice(0, 10);
      // this.data.purchase_due_date = new Date().toISOString().slice(0, 10);

    }
    // });


  },
};
</script>