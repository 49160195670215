<template>
  <div>
    <b-card no-body class="mb-5">
      <b-card-header header-tab="header" class="p-1" role="tab">
        <b-button block v-b-toggle.accordion-2 variant="link" class="text-left font-weight-bold text-dark pl-8 pr-8 fs-102 collapsed-with-icon">
          {{ $t('advanced_filter') }}
        </b-button>
      </b-card-header>

      <b-collapse id="accordion-2" visible accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <div class="m-form m-form--fit m--margin-bottom-20">
            <div class="row mb-5">

              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="item_id">{{ $t('product') }}</label>
                <div class="d-flex search-item-group">
                  <select v-model="f_by" class="custom-select" name="" style="width: 100px;">
                    <option selected value="name">{{ $t('items.name') }}</option>
                    <option value="sku_code">{{ $t('items.sku_code') }}</option>
                  </select>
                  <multiselect id="item_id"
                               v-model="items_ids"
                               :internal-search="false"
                               :multiple="true"
                               :options="items"
                               :label="f_by?f_by:'sku_code'"
                               :placeholder="$t('product')"
                               :show-labels="false"
                               @input="getIds('items')"
                               :show-no-options="false"
                               :show-no-results="false"
                               :taggable="false"
                               track-by="id"
                               @search-change="getItems(f_by, $event)">
                  </multiselect>
                </div>
              </div>
              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="inventory_id">{{ $t('inventory_transaction_report.inventory') }}</label>
                <multiselect v-model="inventories_ids"
                             :placeholder="$t('inventory_transaction_report.inventory')"
                             label="name"
                             track-by="id"
                             :options="inventories"
                             @input="getIds('inventories')"
                             :multiple="true"
                             :taggable="false"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false">
                </multiselect>
              </div>
              <div class="form-group col-md-6 mt-2 mb-2">
                <label>{{ $t('customer_transactions_report.currency') }}</label>
                <multiselect v-model="currencies_ids"
                             :placeholder="$t('customer_transactions_report.currency')"
                             label="name"
                             track-by="id"
                             :options="currencies"
                             :multiple="true"
                             @input="getIds('currencies')"
                             :taggable="false"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false">
                </multiselect>
              </div>
              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="from_date">
                  {{ $t('inventory_transaction_report.from_date') }}
                </label>
                <input type="date" id="from_date" v-model="filters.from_date" class="form-control">
              </div>
              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="to_date">{{ $t('inventory_transaction_report.to_date') }}</label>
                <input type="date" id="to_date" v-model="filters.to_date" class="form-control">
              </div>
              <div class="col-md-6 mt-2 mb-2 pt-10">
                <div>
                  <b-button-group>
                    <b-button @click="changeIntervalType('current_day')" :variant="filters.period=='current_day'? 'primary' : 'outline-primary'">{{ $t('customer_transactions_report.current_date') }}</b-button>
                    <b-button @click="changeIntervalType('current_month')" :variant="filters.period=='current_month'? 'primary' : 'outline-primary'">{{ $t('customer_transactions_report.current_month') }}</b-button>
                    <b-button @click="changeIntervalType('current_year')" :variant="filters.period=='current_year'? 'primary' : 'outline-primary'">{{ $t('customer_transactions_report.current_year') }}</b-button>
                    <b-button @click="changeIntervalType('financial_year')" :variant="filters.period=='financial_year'? 'primary' : 'outline-primary'">{{ $t('customer_transactions_report.financial_year') }}</b-button>
                    <!--                    <b-button  @click="changeFinancialYear" :variant="filters.financial_year==1? 'primary' : 'outline-primary'">{{$t('customer_transactions_report.financial_year')}}</b-button>-->
                  </b-button-group>

                </div>
                <div class="mt-5">
                  <b-button-group>
                    <b-button @click="changeIntervalType('yesterday')" :variant="filters.period=='yesterday'? 'primary' : 'outline-primary'">{{ $t('customer_transactions_report.yesterday') }}</b-button>
                    <b-button @click="changeIntervalType('last_month')" :variant="filters.period=='last_month'? 'primary' : 'outline-primary'">{{ $t('customer_transactions_report.last_month') }}</b-button>
                    <b-button @click="changeIntervalType('last_year')" :variant="filters.period=='last_year'? 'primary' : 'outline-primary'">{{ $t('customer_transactions_report.last_year') }}</b-button>
                  </b-button-group>

                </div>
              </div>
              <div class="form-group d-inline-flex col-md-6 mt-10">
                <b-button class="mt-auto mr-2" variant="primary" @click="doFilter"> {{ $t('display_report') }}</b-button>
                <b-button class="mt-auto mr-2" variant="danger" @click="resetFilter"><i class="fas fa-trash-restore"></i> {{ $t('reset_search') }}</b-button>

              </div>
            </div>
          </div>
        </b-card-body>
      </b-collapse>
    </b-card>


    <div class="card card-custom">
      <div class="card-body">
        <div class="col-md-12 mb-5">
          <div class="m-form m-form--fit m--margin-bottom-20">
            <b-tabs content-class="mt-3">
              <b-tab :title="$t('details')">
                <div class="row">
                  <div class="col-12 mt-10" id="summaryTableTitle">
                    <h4 class="text-center">{{ $t('MENU.detailed_stock_transactions_per_product') }}</h4>
                    <h5 class="text-center"><span v-if="filters.from_date">{{ $t('from') }} {{ filters.from_date }}</span> <span v-if="filters.to_date">{{ $t('to') }} {{ filters.to_date }}</span></h5>
                  </div>
                  <div class="col-12 mt-10 d-flex justify-content-end">
                    <b-dropdown right :text="$t('export')" class="mr-2 ml-2">
                      <button class="dropdown-item" @click="convertTableToExcelReport('detailsTable')">
                        <span><i class="fa fa-file-excel"></i> <span>{{ $t('excel') }}</span></span>
                      </button>
                      <button class="dropdown-item" @click="printData('detailsTable')">
                        <span><i class="fa fa-print"></i> <span>{{ $t('print') }}</span></span>
                      </button>
                      <button class="dropdown-item" @click="printPDFData('detailsTable', 'pdf')">
                        <span><i class="fa fa-file-pdf"></i> <span>{{ $t('export_pdf') }}</span></span>
                      </button>
                      <button class="dropdown-item" @click="getAllDataForExport('sectionForExportDetails', 'excel')">
                        <span><i class="fa fa-file-excel"></i> <span>{{ $t('all_data') }}</span></span>
                      </button>
                    </b-dropdown>
                  </div>
                  <div class="col-12 mt-10">
                    <div class="table-responsive">
                      <table class="table table-bordered text-center" id="detailsTable">
                        <thead>
                        <tr>
                          <th>#</th>
                          <th>{{ $t('detailed_stock_transactions_per_product.operation') }}</th>
                          <th>{{ $t('detailed_stock_transactions_per_product.source') }}</th>
                          <th>{{ $t('detailed_stock_transactions_per_product.inventory') }}</th>
                          <th>{{ $t('detailed_stock_transactions_per_product.quantity') }}</th>
                          <th>{{ $t('detailed_stock_transactions_per_product.unit_price') }}</th>
                          <th>{{ $t('detailed_stock_transactions_per_product.stock_after') }}</th>
                          <th>{{ $t('detailed_stock_transactions_per_product.average_cost_price') }}</th>
                          <th>{{ $t('detailed_stock_transactions_per_product.total_transaction_value') }}</th>
                          <th>{{ $t('detailed_stock_transactions_per_product.total_price') }}</th>
                          <th>{{ $t('detailed_stock_transactions_per_product.stock_value_after') }}</th>

                        </tr>
                        </thead>
                        <tbody>
                        <template v-for="(row, index) in data">
                          <tr>
                            <td colspan="11" style="text-align: start !important;">{{ row.label }}</td>
                          </tr>
                          <tr>
                            <td colspan="6" style="text-align: start !important;">{{ $t('detailed_stock_transactions_per_product.stock_before') }}</td>
                            <td>{{ row.stock_on_hand_qty_before }}</td>
                            <td colspan="4"></td>
                          </tr>
                          <tr v-for="(_row, _index) in row.items" :key="'date'+index+'row'+_index">
                            <td>{{ _index + 1 }}</td>
                            <td>{{ _row.date }}</td>
                            <td>{{ _row.invoice_type }} {{ _row.invoice_code }}</td>
                            <td>{{ _row.inventory }}</td>
                            <td>{{ _row.quantity }}</td>
                            <td>{{ _row.price }}</td>
                            <td>{{ _row.stock_on_hand_qty_after }}</td>
                            <td>{{ _row.avg_purchase_price }}</td>
                            <td>{{ _row.total_transaction_value }}</td>
                            <td>{{ _row.total_price }}</td>
                            <td>{{ _row.stack_on_hand_after_value }}</td>
                          </tr>
                        </template>

                        </tbody>
                      </table>
                    </div>
                    <div class="col-12 text-center" v-if="page">
                      <button class="btn btn-warning" @click="loadMore">
                        <p class="mb-0">
                          <b-icon icon="arrow-counterclockwise" font-scale="1"></b-icon>
                          {{ $t('load_more') }}
                        </p>
                      </button>
                    </div>
                  </div>
                </div>
              </b-tab>
              <template #tabs-end>
                <li class="nav-item  d-flex my-auto">
                  <select id="" v-model="filters.group_by" class="custom-select border-0 pt-0 pb-0 h-auto shadow-none" name="">
                    <option :value="null" disabled>{{ $t('select_filter') }}</option>
<!--                    <option value="day">{{ $t('daily') }}</option>-->
<!--                    <option value="week">{{ $t('weekly') }}</option>-->
<!--                    <option value="month_year">{{ $t('monthly') }}</option>-->
<!--                    <option value="year">{{ $t('yearly') }}</option>-->
<!--                    <option value="customer_id">{{ $t('customer') }}</option>-->
<!--                    <option value="user_id">{{ $t('employee') }}</option>-->
                    <option value="product">{{ $t('item') }}</option>
                  </select>

                </li>
              </template>
            </b-tabs>


          </div>
        </div>
        <!-- End Filter -->

      </div>
    </div>
    <!--end::customer-->

    <div class="export-container">
      <div class="section-for-export">
        <table class="table table-bordered text-center" id="sectionForExportDetails">
          <thead>
          <tr>
            <th>#</th>
            <th>{{ $t('detailed_stock_transactions_per_product.operation') }}</th>
            <th>{{ $t('detailed_stock_transactions_per_product.source') }}</th>
            <th>{{ $t('detailed_stock_transactions_per_product.inventory') }}</th>
            <th>{{ $t('detailed_stock_transactions_per_product.quantity') }}</th>
            <th>{{ $t('detailed_stock_transactions_per_product.unit_price') }}</th>
            <th>{{ $t('detailed_stock_transactions_per_product.stock_after') }}</th>
            <th>{{ $t('detailed_stock_transactions_per_product.average_cost_price') }}</th>
            <th>{{ $t('detailed_stock_transactions_per_product.total_transaction_value') }}</th>
            <th>{{ $t('detailed_stock_transactions_per_product.total_price') }}</th>
            <th>{{ $t('detailed_stock_transactions_per_product.stock_value_after') }}</th>

          </tr>
          </thead>
          <tbody>
          <template v-for="(row, index) in dataList">
            <tr>
              <td colspan="11" style="text-align: start !important;">{{ row.label }}</td>
            </tr>
            <tr>
              <td colspan="6" style="text-align: start !important;">{{ $t('detailed_stock_transactions_per_product.stock_before') }}</td>
              <td>{{ row.stock_on_hand_qty_before }}</td>
              <td colspan="4"></td>
            </tr>

            <tr v-for="(_row, _index) in row.items" :key="'dataList'+index+'row'+_index">
              <td>{{ _index + 1 }}</td>
              <td>{{ _row.date }}</td>
              <td>{{ _row.invoice_type }} {{ _row.invoice_code }}</td>
              <td>{{ _row.inventory }}</td>
              <td>{{ _row.quantity }}</td>
              <td>{{ _row.price }}</td>
              <td>{{ _row.stock_on_hand_qty_after }}</td>
              <td>{{ _row.avg_purchase_price }}</td>
              <td>{{ _row.total_transaction_value }}</td>
              <td>{{ _row.total_price }}</td>
              <td>{{ _row.stack_on_hand_after_value }}</td>
            </tr>
          </template>

          </tbody>
        </table>
      </div>
    </div>
    <div class="selector-export" id="selectorExport"></div>

    <div class="export-container">
      <div class="section-for-export" id="sectionForExportHeader">
        <report-main-header :print-type="'DetailedStockTransactionsPerProduct'"></report-main-header>
      </div>
    </div>

  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import TableToExcel from "@linways/table-to-excel";
import ReportMainHeader from "@/view/content/printing-heads/ReportMainHeader.vue";
import cssStypeForPrintReport from "@/core/config/mix/cssStypeForPrintReport";
import exportPDFInnerAction from "@/core/plugins/export-jspdf-inner-action";

export default {
  name: "index-detailed-stock-transactions-per-product-report",
  components: {ReportMainHeader},
  data() {
    return {
      mainRoute: '/reports/stock-reports/products/transactions',
      mainRouteDependency: 'base/dependency',
      items: [],
      data: [],
      totals: [],
      dataList: [],
      totalsList: [],

      filters: {
        from_date: null,
        to_date: null,
        group_by: 'product',

        f_inventories_ids: [],
        f_items_ids: [],
        f_doc_type:null,
        f_currencies_ids:[],
        period: null,
        range: null,
        f_time_period: 0,
        financial_year: 0,
      },
      inventories_ids: [],
      items_ids: [],
      currencies_ids: [],
      currencies: [],


      inventories: [],

      period_date: ['day', 'week', 'month', 'year'],
      period_numbers: {
        day: 31,
        week: 4,
        month: 12,
        year: 10,
      },
      period_counter: [],
      currency_default_id: null,
      branch_default: null,
      inner_period: null,

      json_meta: [
        [{
          key: "charset",
          value: "utf-8",
        },],
      ],
      page: 0,
      f_by: 'name',
      periodLabel: 'item',
    }
  },
  watch: {
    "filters.group_by": function (val) {
      if (val)
        this.doFilter();
      switch (val) {
        // case 'day':
        //   this.periodLabel = 'day';
        //   break;
        // case 'week':
        //   this.periodLabel = 'week';
        //   break;
        // case 'month_year':
        //   this.periodLabel = 'month';
        //   break;
        // case 'year':
        //   this.periodLabel = 'year';
        //   break;
        // case 'customer_id':
        //   this.periodLabel = 'customer';
        //   break;
        // case 'user_id':
        //   this.periodLabel = 'employee';
        //   break;
        case 'product':
          this.periodLabel = 'item';
          break;

      }
    },
    // item: function (val) {
    //   if (val && val.id) {
    //     this.filters.item_id = val.id;
    //   } else {
    //     this.filters.item_id = null;
    //   }
    // },
    // inventory: function (val) {
    //   if (val && val.id) {
    //     this.filters.inventory_id = val.id;
    //   } else {
    //     this.filters.inventory_id = null;
    //   }
    // }
  },
  computed: {

    json_fields: function () {
      let fields = {};
      fields[this.$t('inventory_transaction_report.time')] = 'time';
      fields[this.$t('inventory_transaction_report.type')] = 'type';
      fields[this.$t('inventory_transaction_report.product_name')] = 'product_name';
      fields[this.$t('inventory_transaction_report.inward')] = 'inward';
      fields[this.$t('inventory_transaction_report.outward')] = 'outward';
      fields[this.$t('inventory_transaction_report.warehouse')] = 'inventory_name';
      fields[this.$t('inventory_transaction_report.stock_level_after')] = 'stock_level_after';

      return fields;
    },


  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.store_reports"), route: '/store/reports'}, {title: this.$t("MENU.detailed_stock_transactions_per_product")}]);
    this.getInventories();
    this.getCurrencies();
  },
  methods: {
    ...cssStypeForPrintReport,
    loadMore() {
      this.page = this.page ? (this.page + 1) : 1;
      this.getData();
    },
    doFilter() {
      this.page = 1;
      this.getData();
    },
    resetFilter() {
      this.filters.from_date = null;
      this.filters.to_date = null;
      this.filters.f_doc_type = null;

      this.filters.period = null;
      this.filters.range = null;
      this.filters.f_time_period = 0;
      this.filters.financial_year = 0;
      this.filters.f_inventories_ids = [];
      this.filters.f_items_ids = [];
      this.filters.f_currencies_ids = [];

      this.currencies_ids = []
      this.inventories_ids = [];
      this.items_ids = [];

      this.inner_period = null;

      this.doFilter();

    },
    getIds(related_with) {

      switch (related_with) {
        case 'currencies':
          this.filters.f_currencies_ids = this.currencies_ids.map((row) => row.id);
          break;
        case 'inventories':
          this.filters.f_inventories_ids = this.inventories_ids.map((row) => row.id);
          break;
        case 'items':
          this.filters.f_items_ids = this.items_ids.map((row) => row.id);
          break;
      }

    },
    changeFinancialYear() {
      if (this.filters.financial_year == 1)
        this.filters.financial_year = 0;
      else
        this.filters.financial_year = 1;
    },
    changeIntervalType(action_type, range = null) {
      if (this.filters.period == action_type) {
        this.filters.period = null;
        this.filters.f_time_period = 0;
      } else {
        this.filters.period = action_type;
        this.filters.f_time_period = 1;
      }

      // this.filters.range = range;
      // if (range == 1){
      //   this.inner_period = 'last_'+action_type;
      //   if (action_type == 'year')
      //     this.filters.range = this.$moment().subtract(1, 'year').year();
      // }else{
      //   this.inner_period = action_type;
      // }


      // this.period_counter = [];
      // if (this.filters.period){
      //   let _counter = this.period_numbers[this.filters.period];
      //   if (this.filters.period == 'year') {
      //     let _current_year = new Date().getFullYear();
      //     this.period_counter.push(parseInt(_current_year));
      //     for (let cc = 1; cc <= _counter; cc++){
      //       this.period_counter.push(parseInt(_current_year) - cc);
      //     }
      //   }else {
      //     for (let cc = 1; cc <= _counter; cc++){
      //       this.period_counter.push(cc);
      //     }
      //   }
      //
      // }else {
      //   this.period_counter = [];
      // }

    },

    dataReport() {
      this.setReportLog('excel', 'detailed stock transactions per product');
      return this.data;
    },
    getData() {
      let _limit = (this.page ? this.page : 1) * 15;
      ApiService.get(this.mainRoute, {params: {...this.filters, limit: _limit}}).then((response) => {
        this.data = response.data.data;
      }).then().catch((error) => {
        this.$errorAlert(error)
      });
    },
    getAllDataForExport(tableId, _export) {
      ApiService.get(this.mainRoute, {params: {...this.filters, is_export: 1, export_all: 1}}).then((response) => {
        this.dataList = response.data.data;
          this.convertTableToExcelReport(tableId);
      });
    },
    convertTableToExcelReport(tableId) {
      this.setReportLog('excel', 'detailed stock transactions per product');
      setTimeout(() => {
        TableToExcel.convert(document.getElementById(tableId), {
          name: this.$t('MENU.detailed_stock_transactions_per_product') + '.xlsx',
          sheet: {
            name: 'Sheet 1'
          }
        });
      }, 100)
    },
    getItems(f_by = null, filter = null) {
      let _f_by = f_by ? f_by : 'name';
      if (filter && filter.length >= 3 && _f_by)
        ApiService.get(this.mainRouteDependency + "/items", {params: {[_f_by]: filter}}).then((response) => {
          this.items = response.data.data;
        });
    },
    getInventories() {
      ApiService.get(this.mainRouteDependency + "/inventories").then((response) => {
        this.inventories = response.data.data;
      });
    },
    getCurrencies() {
      ApiService.get(this.mainRouteDependency + "/currencies").then((response) => {
        this.currencies = response.data.data;
      });
    },

    async getCustomers(filter) {
      if (filter && filter.length >= 3)
        await ApiService.get(this.mainRouteDependency + "/customers", {params: {filter: filter}}).then((response) => {
          this.customers = response.data.data;
        });
    },
    getUsers(filter) {
      if (filter && filter.length >= 3)
        ApiService.get(this.mainRouteDependency + "/users", {params: {filter: filter}}).then((response) => {
          this.users = response.data.data;
        });
    },

    printData(tableId) {
      this.setReportLog('pdf', 'detailed stock transactions per product');
      let divToPrint = document.getElementById(tableId);
      let divTitleToPrint = document.getElementById('sectionForExportHeader');
      let newWin = window.open("");
      newWin.document.write('<meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">');
      newWin.document.write("<link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css'>");
      newWin.document.write('<style>');
      newWin.document.write(this.getCssStyleForPrintReport());
      newWin.document.write('</style>');
      newWin.document.write(divTitleToPrint.outerHTML + divToPrint.outerHTML);
      setTimeout(() => {
        newWin.print();
        // newWin.close();
      }, 100)
    },
    printPDFData(tableId, type) {
      this.setReportLog('pdf', 'detailed stock transactions per product');

      // let divToPrint = document.getElementById(tableId);
      // let divTitleToPrint = document.getElementById(tableId + 'Title');
      // let newWin = window.open("");
      // newWin.document.write("<link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css'>" + divTitleToPrint.outerHTML + divToPrint.outerHTML);
      // setTimeout(() => {
      //     newWin.print();
      //     newWin.close();
      // }, 100)
      exportPDFInnerAction(tableId, type, this.$t('MENU.detailed_stock_transactions_per_product'), this.$t('MENU.detailed_stock_transactions_per_product'));
    },

    /**
     * register log
     */
    setReportLog(key, description) {
      ApiService.post("reports/system_activity_log/log_type", {
        key: key,
        description: description,
      });
    }
  },
};
</script>
