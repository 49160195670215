<template>
  <div>
    <!--begin::customer-->

    <h3 class="card-label">
      {{ $t('treasury_checking.treasury_checking') }}
      <span class="text-muted pt-2 font-size-sm d-block"></span>
    </h3>

    <div class="row mt-3">
      <div class="col-md-12">
        <div class="card card-custom">
          <div class="card-body">
            <div class="form-group row">

              <div class="col-lg-3 mb-5">
                <label>{{ $t('treasury_checking.treasury') }}<span class="text-danger">*</span></label>
                <multiselect v-model="treasury"
                             :placeholder="$t('treasury_checking.treasury')"
                             label="name"
                             track-by="id"
                             :options="treasuries"
                             :multiple="false"
                             :taggable="false"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false"
                             @input="changeToGetData">
                </multiselect>
                <span v-if="validation && validation.treasury_id" class="fv-plugins-message-container invalid-feedback">
                    {{ validation.treasury_id[0] }}
                </span>
              </div>
              <div class="col-lg-3 mb-5">
                <label>{{ $t('treasury_checking.currency') }}<span class="text-danger">*</span></label>
                <select name="" id="currency_id" v-model="data.currency_id" @change="changeToGetData" class="custom-select">
                  <option v-for="row in currencies" :value="row.id" :key="row.id">{{ row.name }}</option>
                </select>
              </div>


              <div class="col-lg-3 mb-5">
                <label>{{ $t('status') }}</label>
                <select name="" id="status" v-model="data.status" disabled type="text" class="custom-select" :class="validation && validation.status ? 'is-invalid' : ''">
                  <option v-for="(row, index) in status_list" :value="row.id" :key="index">{{ row.title }}</option>
                </select>
                <span v-if="validation && validation.status" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.status[0] }}
                            </span>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-lg-3 mb-5">
                <label>{{ $t('treasury_checking.old_balance') }}</label>
                <input type="number" v-model="data.old_balance" :disabled="true" class="form-control" :class="validation && validation.old_balance ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.old_balance" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.old_balance[0] }}
                            </span>
              </div>

              <div class="col-lg-3 mb-5">
                <label>{{ $t('treasury_checking.new_balance') }}</label>
                <input type="number" v-model="data.new_balance"  class="form-control" :class="validation && validation.new_balance ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.new_balance" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.new_balance[0] }}
                            </span>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>



    <div class="row mt-10">
      <div class="col-lg-6">
        <button class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
      </div>
    </div>
    <!--end::customer-->
  </div>
</template>
<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "form-treasury-checking",
  data() {
    return {
      mainRoute: 'finances/treasury-checking',
      mainRouteDependency: 'base/dependency',

      data: {
        treasury_id: null,
        currency_id: null,
        currency_code: null,
        old_balance: null,
        new_balance: null,
        status: 1,
      },

      idEditing: this.$route.params.id ? this.$route.params.id : null,
      isEditing: false,
      validation: null,
      currency_name: null,
      status_list: [],
      treasury: null,
      treasuries: [],
      currencies: [],
    };
  },

  methods: {
    save() {
      if (this.isEditing) {
        this.update();
      } else {
        this.create();
      }
    },

    create() {
      ApiService.post(`${this.mainRoute}`, {
        ...this.data,
      })
          .then((response) => {
            this.validation = null;
            this.$successAlert(response.data.message);
            this.goBackIndex();
          })
          .catch((error) => {
            this.$errorAlert(error);
            this.validation = error.response ? error.response.data.errors : null;
          });
    },


    update() {
      ApiService.put(`${this.mainRoute}/${this.$route.params.id}`, {
        ...this.data,
      }).then((response) => {
        this.validation = null;
        this.goBackIndex();
        this.$successAlert(response.data.message);
      })
          .catch((error) => {
            this.$errorAlert(error);
            this.validation = error.response ? error.response.data.errors : null;
          });
    },
    goBackIndex() {
      this.$router.push({
        name: 'treasury-checking.index', query: {
          treasury_id: this.$route.query.treasury_id,
        }
      });
    },
    async getData() {

      ApiService.get(`${this.mainRoute}/${this.$route.params.id}`).then((response) => {
        this.isEditing = true;
        this.data.treasury_id = response.data.data.treasury_id;
        this.treasury = {
          id: response.data.data.treasury_id,
          name: response.data.data.treasury_name,
        };
        this.data.currency_id = response.data.data.currency_id;
        this.data.currency_code = response.data.data.currency_code;
        this.data.old_balance = response.data.data.old_balance;
        this.data.new_balance = response.data.data.new_balance;
        this.data.status = response.data.data.status;
        this.currency_name = response.data.data.currency_name;
      });
    },
    getDataBalance() {
      ApiService.get(`${this.mainRouteDependency}/treasury_balance_by_currency`, {
        params: {
          treasury_id: this.data.treasury_id,
          currency_id: this.data.currency_id,
        }
      }).then((response) => {
        this.data.old_balance = response.data.data.balance;
        this.data.currency_code = response.data.data.currency_code;
        this.currency_name = response.data.data.currency_name;
      });
    },
    getStatus() {
      ApiService.get(this.mainRouteDependency + "/status").then((response) => {
        this.status_list = response.data.data;
      });
    },
    getTreasuries() {
      ApiService.get(this.mainRouteDependency + "/treasuries").then((response) => {
        this.treasuries = response.data.data;
        if (this.filters.treasury_id) {
          this.treasury = response.data.data.find((row) => row.id == this.filters.treasury_id);
        }
      });
    },
    getCurrencies() {
      ApiService.get(this.mainRouteDependency + "/currencies").then((response) => {
        this.currencies = response.data.data;
      });
    },
    changeToGetData(){
      this.data.treasury_id = this.treasury ? this.treasury.id : null;
      if (this.data.treasury_id && this.data.currency_id){
        this.getDataBalance();
      }
    }
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.treasury_checking"), route: '/finances/treasury-checking'}, {title: (this.idEditing ? this.$t('update') : this.$t('create'))}]);
    this.getTreasuries();
    this.getCurrencies();
    this.getStatus();
    if (this.idEditing) {
      this.getData();
    }

  },
};
</script>