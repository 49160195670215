<!-- sales / generalSales / Form.vue-->
<template>
  <div>
    <!--begin::customer-->
    <!--    <div class="card card-custom">-->
    <div v-if="PlanMessage? true: false" class="card-header d-block border-0 pt-6 pb-0">
      <div>
        <b-alert show variant="danger">{{ PlanMessage }}</b-alert>
      </div>
    </div>

    <!--      <div class="card-header flex-wrap border-0 pt-6 pb-0">-->
    <!--        <div class="card-title m-0">-->
    <h3 class="card-label">
      {{ $t('MENU.general_sales') }}
      <span class="text-muted pt-2 font-size-sm d-block"></span>
    </h3>

<!--    <b-alert v-if="data.is_confirmed" :show="true" variant="warning">-->
    <b-alert v-if="is_confirmed"  :show="true" variant="warning">
      <h5>{{ $t('this_invoice_cant_be_editing') }}</h5>
    </b-alert>

    <b-tabs content-class="mt-3" class="nav-custom-link">
      <b-tab :title="$t('sales_invoices.basic_information')" @click="toggleTab('basic_information')">
        <div class="row">
          <div class="col-md-6">
            <div class="card card-custom">
              <div class="card-body row">
                <div class="col-lg-12 mb-5">
                  <label>{{ $t('sales_invoices.customer') }}<span class="text-danger">*</span></label>
                  <div class="row">
                    <div class="col-sm-8">
                      <multiselect
                          id="customer_id"
                          v-model="customer"
                          :placeholder="$t('sales_invoices.customer')"
                          label="fullname"
                          track-by="id"
                          :options="customers"
                          :multiple="false"
                          :taggable="false"
                          :show-labels="false"
                          :show-no-options="false"
                          :show-no-results="false"
                          @search-change="getCustomers($event)">
                      </multiselect>
                      <span class="form-text text-muted" style="text-align:end">{{ $t('type_at_least_three_letters') + ' ' + $t('sales_invoices.customer') }}.</span>
                      <span v-if="validation && validation.customer_id" class="fv-plugins-message-container invalid-feedback">
                                                {{ validation.customer_id[0] }}
                                            </span>
                    </div>
                    <div class="col-sm-4">
                      <button class="btn btn-primary" @click="showModal">{{ $t('add_new') }}</button>
                    </div>

                  </div>
                </div>
              </div>
            </div>

            <b-card no-body v-if="customer" class="mt-5">
              <b-card-header header-tab="header" class="p-1" role="tab">
                <b-button block v-b-toggle.accordion-50 variant="link" class="text-left font-weight-bold text-dark pl-8 pr-8 fs-102 collapsed-with-icon">
                  {{ $t('customer_data_details') }}
                </b-button>
              </b-card-header>

              <b-collapse id="accordion-50" accordion="my-accordion-1" role="tabpanel">
                <b-card-body class="row">
                  <div class="col-lg-12 mb-5">
                    <p><b>{{ $t('name') }}: </b> {{ customer.fullname }}</p>
                    <p><b>{{ $t('mobile') }}: </b> {{ customer.mobile }}</p>
                    <p><b>{{ $t('email') }}: </b> {{ customer.email }}</p>
                    <p><b>{{ $t('tax_no') }}: </b> {{ customer.tax_register }}</p>
                  </div>
                  <div class="col-md-12 mb-5">
                    <div class="bg-FFB803 repeater p-3 d-flex justify-content-between">
                      <h6 class="my-auto text-white">{{ $t('customers.balances') }}</h6>
                    </div>
                    <table class="table table-row-bordered">
                      <thead>
                      <tr>
                        <th>{{ $t('customers.balance') }}</th>
                        <th>{{ $t('customers.debit') }}</th>
                        <th>{{ $t('customers.currency') }}</th>
                        <th>{{ $t('customers.last_validated_date') }}</th>
                        <th></th>
                      </tr>

                      </thead>
                      <tbody>
                      <template v-if="customer.balances && customer.balances.length > 0">
                        <tr v-for="(row, index) in customer.balances" :key="index">
                          <td>{{ row.credit }}</td>
                          <td>{{ row.debit }}</td>
                          <td>{{ row.currency_name }}</td>
                          <td>{{ row.last_validated_date }}</td>
                        </tr>
                      </template>
                      <tr v-else>
                        <td colspan="4">{{ $t('no_balances') }}</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </b-card-body>
              </b-collapse>
            </b-card>

            <!--            <b-card no-body class="mt-5">-->
            <!--              <b-card-header header-tab="header" class="p-1" role="tab">-->
            <!--                <b-button block v-b-toggle.accordion-1 variant="link" class="text-left font-weight-bold text-dark pl-8 pr-8 fs-102 collapsed-with-icon">-->
            <!--                  {{ $t('sales_invoices.shipping_address') }}-->
            <!--                </b-button>-->
            <!--              </b-card-header>-->

            <!--              <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">-->
            <!--                <b-card-body class="row">-->
            <!--                  <div class="col-lg-6 mb-5">-->
            <!--                    <label>{{ $t('sales_invoices.country') }}</label>-->
            <!--                    <div class="input-group">-->
            <!--                      <multiselect-->
            <!--                          :class="validation && validation.country_code2 ? 'is-invalid' : ''"-->
            <!--                          v-model="shipping_address.country"-->
            <!--                          :placeholder="$t('sales_invoices.country')"-->
            <!--                          label="name"-->
            <!--                          track-by="code2"-->
            <!--                          :options="countries"-->
            <!--                          :multiple="false"-->
            <!--                          :taggable="false"-->
            <!--                          :show-labels="false"-->
            <!--                          :show-no-options="false"-->
            <!--                          :show-no-results="false"-->
            <!--                          @search-change="getCountries($event)"-->
            <!--                          :internal-search="false">-->
            <!--                      </multiselect>-->
            <!--                    </div>-->
            <!--                    <span class="form-text text-muted" style="text-align:end">{{ $t('type_at_least_three_letters') + ' ' + $t('branches.country') }}.</span>-->
            <!--                  </div>-->
            <!--                  <div class="col-lg-6 mb-5">-->
            <!--                    <label>{{ $t('sales_invoices.city') }}</label>-->
            <!--                    <div class="input-group">-->
            <!--                      <multiselect-->
            <!--                          :class="validation && validation.city ? 'is-invalid' : ''"-->
            <!--                          v-model="shipping_address.city"-->
            <!--                          :placeholder="$t('sales_invoices.city')"-->
            <!--                          label="name"-->
            <!--                          track-by="code2"-->
            <!--                          :options="cities"-->
            <!--                          :multiple="false"-->
            <!--                          :taggable="true"-->
            <!--                          :show-labels="false"-->
            <!--                          :show-no-options="false"-->
            <!--                          :show-no-results="false"-->
            <!--                      >-->
            <!--                      </multiselect>-->
            <!--                      <div class="input-group-prepend">-->
            <!--                        <a class="btn btn-primary" :href="shipping_address.country_code2?'/settings/cities/'+shipping_address.country_code2:'#'" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>-->
            <!--                      </div>-->
            <!--                    </div>-->
            <!--                  </div>-->
            <!--                  <div class="col-lg-6 mb-5">-->
            <!--                    <label>{{ $t('sales_invoices.address') }}</label>-->
            <!--                    <input type="text" v-model="shipping_address.address" class="form-control" :class="validation && validation.address ? 'is-invalid' : ''"/>-->
            <!--                    <span v-if="validation && validation.address" class="fv-plugins-message-container invalid-feedback">-->
            <!--                          {{ validation.address[0] }}-->
            <!--                      </span>-->
            <!--                  </div>-->
            <!--                </b-card-body>-->
            <!--              </b-collapse>-->
            <!--            </b-card>-->
            <b-card no-body class="mt-5" v-if="data.customer_id">
              <b-card-header header-tab="header" class="p-1" role="tab">
                <b-button block v-b-toggle.accordion-6 variant="link" class="text-left font-weight-bold text-dark pl-8 pr-8 fs-102 collapsed-with-icon">
                  {{ $t('shipping_address') }}
                </b-button>
              </b-card-header>
              <b-collapse id="accordion-6" accordion="my-accordion" role="tabpanel">
                <b-card-body class="row">

                  <div class="col-lg-12 mb-5">
                    <label>{{ $t('customer_address') }}</label>
                    <div class="input-group">
                      <multiselect
                          v-model="customer_address"
                          :placeholder="$t('customer_address')"
                          label="label"
                          track-by="id"
                          :options="customer_addresses"
                          :multiple="false"
                          :taggable="false"
                          :show-labels="false"
                          :show-no-options="false"
                          :show-no-results="false">
                      </multiselect>
                      <div class="input-group-prepend">
                        <a class="btn btn-primary" href="javascript:;" @click="showCustomerAddressModal"><i class="fa fa-plus" style="padding: 0"></i></a>
                      </div>
                    </div>

                  </div>
                  <div class="col-lg-12 mb-5" v-if="customer_address">
                    <p><b>{{ $t('customers.country') }}:</b> {{ customer_address.country_name }}</p>
                    <p><b>{{ $t('customers.city') }}:</b> {{ customer_address.city_name }}</p>
                    <p><b>{{ $t('customers.state') }}:</b> {{ customer_address.state }}</p>
                    <p><b>{{ $t('customers.postal_code') }}:</b> {{ customer_address.postal_code }}</p>
                    <p><b>{{ $t('customers.address_1') }}:</b> {{ customer_address.address_1 }}</p>
                    <p><b>{{ $t('customers.address_2') }}:</b> {{ customer_address.address_2 }}</p>
                  </div>
                </b-card-body>
              </b-collapse>
            </b-card>
            <b-card no-body class="mt-5">
              <b-card-header header-tab="header" class="p-1" role="tab">
                <b-button block v-b-toggle.accordion-2 variant="link" class="text-left font-weight-bold text-dark pl-8 pr-8 fs-102 collapsed-with-icon">
                  {{ $t('sales_invoices.billing_address') }}
                </b-button>
              </b-card-header>

              <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                <b-card-body class="row">
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('sales_invoices.country') }}</label>
                    <div class="input-group">
                      <multiselect
                          :class="validation && validation.country_code2 ? 'is-invalid' : ''"
                          v-model="billing_address.country"
                          :placeholder="$t('sales_invoices.country')"
                          label="name"
                          track-by="code2"
                          :options="countries"
                          :multiple="false"
                          :taggable="false"
                          :show-labels="false"
                          :show-no-options="false"
                          :show-no-results="false"
                          @search-change="getCountries($event)"
                          :internal-search="false">
                      </multiselect>
                    </div>
                    <span class="form-text text-muted" style="text-align:end">{{ $t('type_at_least_three_letters') + ' ' + $t('branches.country') }}.</span>
                  </div>
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('sales_invoices.city') }}</label>
                    <div class="input-group">
                      <multiselect
                          :class="validation && validation.city ? 'is-invalid' : ''"
                          v-model="billing_address.city"
                          :placeholder="$t('sales_invoices.city')"
                          label="name"
                          track-by="code2"
                          :options="cities"
                          :multiple="false"
                          :taggable="true"
                          :show-labels="false"
                          :show-no-options="false"
                          :show-no-results="false"
                      >
                      </multiselect>
                      <div class="input-group-prepend">
                        <a class="btn btn-primary" :href="billing_address.country_code2?'/settings/cities/'+billing_address.country_code2:'#'" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('sales_invoices.address') }}</label>
                    <input type="text" v-model="billing_address.address" class="form-control" :class="validation && validation.address ? 'is-invalid' : ''"/>
                    <span v-if="validation && validation.address" class="fv-plugins-message-container invalid-feedback">
                          {{ validation.address[0] }}
                      </span>
                  </div>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card v-if="(idEditing && isSystemUser)" no-body class="mt-5">
              <b-card-header header-tab="header" class="p-1" role="tab">
                <b-button block v-b-toggle.accordion-7 variant="link" class="text-left font-weight-bold text-dark pl-8 pr-8 fs-102 collapsed-with-icon">
                  {{ $t('taxes') }}
                </b-button>
              </b-card-header>
              <b-collapse id="accordion-7" accordion="my-accordion" role="tabpanel">
                <b-card-body class="row">

                  <div class="col-lg-12 mb-5">
                    <label>{{ $t('general_tax') }}</label>
                    <div class="input-group">
                      <multiselect
                          v-model="general_tax"
                          :placeholder="$t('general_tax')"
                          label="name"
                          track-by="id"
                          :options="taxes"
                          :multiple="false"
                          :taggable="false"
                          :show-labels="false"
                          :show-no-options="false"
                          :show-no-results="false"
                          @input="setGeneralTax"
                          @search-change="getTaxes($event)">
                      </multiselect>
                    </div>

                  </div>
                </b-card-body>
              </b-collapse>
            </b-card>

            <!--            <b-card no-body class="mt-5">-->
            <!--              <b-card-header header-tab="header" class="p-1" role="tab">-->
            <!--                <b-button block v-b-toggle.accordion-15 variant="link" class="text-left font-weight-bold text-dark pl-8 pr-8 fs-102 collapsed-with-icon">-->
            <!--                  {{ $t('applicable_taxes')}}-->
            <!--                </b-button>-->
            <!--              </b-card-header>-->

            <!--              <b-collapse id="accordion-15" accordion="my-accordion" role="tabpanel">-->
            <!--                <b-card-body class="row">-->
            <!--                  <div class="col-lg-12 mb-5" v-for="(taxes_rules, index) in taxes_rules_for_tax_setting" :key="'tax_rule'+index">-->
            <!--                    <b-form-checkbox :id="'checkbox-'+index" v-model="data.apply_tax_rules" :name="'checkbox-'+index" :value="{tax_rule_id: taxes_rules.id}">-->
            <!--                      {{taxes_rules.name}}-->
            <!--                    </b-form-checkbox>-->
            <!--                  </div>-->


            <!--                </b-card-body>-->
            <!--              </b-collapse>-->
            <!--            </b-card>-->
          </div>


          <div class="col-md-6">
            <div class="card card-custom">
              <div class="card-body row">
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('sales_invoices.invoice_code') }}</label>
                  <input type="text" v-model="data.invoice_code" disabled class="form-control" :class="validation && validation.invoice_code ? 'is-invalid' : ''"/>
                  <span v-if="validation && validation.invoice_code" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.invoice_code[0] }}
                            </span>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('sales_invoices.reference_number') }}</label>
                  <input type="text" v-model="data.reference_number" class="form-control" :class="validation && validation.reference_number ? 'is-invalid' : ''"/>
                  <span v-if="validation && validation.reference_number" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.reference_number[0] }}
                            </span>
                </div>

<!--                <div class="col-lg-6 mb-5" v-if="!idEditing">-->
<!--                  <b-form-group :label="$t('sales_invoices.save_invoice_as')" v-slot="{ ariaDescribedby }">-->
<!--                    <b-form-radio-group-->
<!--                        v-model="data.default_status"-->
<!--                        :options="default_status_list"-->
<!--                        :aria-describedby="ariaDescribedby"-->
<!--                        name="default_status"-->
<!--                        value-field="id"-->
<!--                        text-field="title"-->
<!--                        @input="getCode"-->
<!--                        stacked></b-form-radio-group>-->
<!--                  </b-form-group>-->
<!--                </div>-->

                <div class="col-lg-6 mb-5">
                  <label>{{ $t('sales_invoices.invoice_date') }}</label>
                  <input type="datetime-local" v-model="data.invoice_date"  class="form-control" :class="validation && validation.invoice_date ? 'is-invalid' : ''"/>
                  <span v-if="validation && validation.invoice_date" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.invoice_date[0] }}
                                    </span>
                </div>


                <div class="col-lg-6 mb-5">
                  <label>{{ $t('sales_invoices.currency') }}<span class="text-danger">*</span></label>
                  <div class="input-group">
                    <select name="" id="currency_id" v-model="data.currency_id" class="custom-select" :class="validation && validation.currency_id ? 'is-invalid' : ''">
                      <option v-for="row in currencies" :value="row.id" :key="row.id">{{ row.name }}</option>
                    </select>
                    <div class="input-group-prepend">
                      <a class="btn btn-primary" href="/settings/currencies" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                    </div>
                  </div>
                  <span v-if="validation && validation.currency_id" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.currency_id[0] }}
                                    </span>
                </div>

                <div class="col-lg-6 mb-5">
                  <label>{{ $t('sales_invoices.payment_terms') }}</label>
                  <div class="row">
                    <div class="col-sm-9">
                      <input type="number" v-model="data.payment_terms" class="form-control" :class="validation && validation.payment_terms ? 'is-invalid' : ''"/>
                      <span v-if="validation && validation.payment_terms" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.payment_terms[0] }}
                                    </span>
                    </div>
                    <span class="col-sm-3">{{ $t('purchases_invoices.day_s') }}</span>
                  </div>
                </div>

                <div class="col-lg-6 mb-5">
                  <label>{{ $t('status') }}</label>
                  <select name="" id="f_status" disabled v-model="data.status" type="text" class="custom-select" :class="validation && validation.status ? 'is-invalid' : ''">
                    <option v-for="(row, index) in status_list" :value="row.id" :key="index">{{ row.title }}</option>
                  </select>
                  <span v-if="validation && validation.status" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.status[0] }}
                                    </span>
                </div>

                <div class="col-lg-6 mb-5">
                  <label>{{ $t('sales_invoices.user_assigned_to_invoice') }}</label>
                  <div class="input-group">
                    <multiselect v-model="user"
                                 :class="validation && validation.interested_user_id ? 'is-invalid' : ''"
                                 :placeholder="$t('sales_invoices.user_assigned_to_invoice')"
                                 label="name"
                                 track-by="id"
                                 :options="users"
                                 :multiple="false"
                                 :taggable="false"
                                 :show-labels="false"
                                 :show-no-options="false"
                                 :show-no-results="false"
                                 @search-change="getUsers($event)">
                    </multiselect>
                    <div class="input-group-prepend">
                      <a class="btn btn-primary" href="/users/users/create" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                    </div>
                  </div>
                  <span class="form-text text-muted" style="text-align:end">{{ $t('type_at_least_three_letters') + ' ' + $t('sales_invoices.user') }}.</span>
                  <span v-if="validation && validation.interested_user_id" class="fv-plugins-message-container invalid-feedback">
                          {{ validation.interested_user_id[0] }}
                      </span>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('branch') }}</label>
                  <div class="input-group">
                    <multiselect v-model="branch"
                                 :placeholder="$t('branch')"
                                 label="name"
                                 track-by="id"
                                 :options="branches"
                                 :multiple="false"
                                 :taggable="false"
                                 :show-labels="false"
                                 :show-no-options="false"
                                 :show-no-results="false">
                    </multiselect>
                    <div class="input-group-prepend">
                      <a class="btn btn-primary" href="/settings/branches/create" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                    </div>
                  </div>
                  <span v-if="validation && validation.branch_id" class="fv-plugins-message-container invalid-feedback">
                    {{ validation.branch_id[0] }}
                  </span>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('sales_invoices.zero_sales_invoice') }}:</label>
                  <b-form-checkbox size="lg" v-model="data.is_zero_invoice" name="check-button" switch></b-form-checkbox>
                </div>

                <div class="col-lg-6 mb-5">
                  <b-form-group :label="$t('sales_invoices.deferred_invoice')" v-slot="{ ariaDescribedby }">
                    <b-form-radio v-model="data.is_deferred" :aria-describedby="ariaDescribedby" name="deferred" :value="true">{{ $t('deferred') }}</b-form-radio>
                    <b-form-radio v-model="data.is_deferred" :aria-describedby="ariaDescribedby" name="cash" :value="false">{{ $t('cash') }}</b-form-radio>
                  </b-form-group>
                  <!--                      <label>{{ $t('sales_invoices.deferred_invoice') }}:</label>-->
                  <!--                      <b-form-checkbox size="lg" v-model="data.is_deferred" name="check-button" switch></b-form-checkbox>-->
                </div>

<!--                <div class="col-lg-6 mb-5" v-if="data.status == 1">-->
<!--                  <label>{{ $t('confirm_invoice') }}:</label>-->
<!--                  <b-form-checkbox size="lg" v-model="is_confirmed" name="check-button" switch></b-form-checkbox>-->
<!--                </div>-->

                <div class="col-lg-6 mb-5">
                  <label>{{ $t('is_apply_taxes_invoice') }}:</label>
                  <b-form-checkbox size="lg" :disabled="true" v-model="data.is_apply_taxes_invoice" name="check-button" switch></b-form-checkbox>
                </div>

              </div>
            </div>


          </div>
        </div>


        <div class="row mt-5">
          <div class="col-md-12">
            <div class="bg-f3f3f3 repeater p-3 d-flex justify-content-between">

              <button type="button" class="btn btn-primary" @click="addItemRowToList"><i class="fa fa-plus-square mb-1"></i> <span>{{ $t('add_more') }}</span></button>
               <button v-if="(idEditing && isSystemUser)" type="button" class="btn btn-primary ml-1 mr-1" @click="adjustItemsList">{{ $t('adjust') }}</button>

            </div>
            <div class="table-responsive bg-white pb-5">
              <!--                    <table class="table table-row-bordered w-max-content" @keyup.enter="addItemRowToList" @keyup.46="removeItemRowFromList">-->
              <table class="table table-row-bordered  table-custom-padding" @keyup.alt.enter="addItemRowToList" @keyup.alt.46="removeItemRowFromList">
                <thead>
                <tr>
                  <th width="30px">#</th>
                  <th width="250px">{{ $t('sales_invoices.description') }}</th>
                  <th width="120px">{{ $t('sales_invoices.unit_price') }}</th>
                  <th width="120px">{{ $t('sales_invoices.unit') }}</th>
                  <th width="80px">{{ $t('sales_invoices.qty') }}</th>
                  <th v-if="isApplyTaxesInvoice">{{ $t('sales_invoices.tax') }}</th>
                  <!--                        <th>{{$t('sales_refund.tax2') }}</th>-->
                  <th width="120px">{{ $t('discount') }}</th>
                  <!--                        <th>{{ $t('sales_invoices.discount_value') }}</th>-->
                  <th width="80px" v-if="isApplyTaxesInvoice">{{ $t('subtotal_before_tax') }}</th>
                  <th width="80px" v-if="isApplyTaxesInvoice">{{ $t('sales_invoices.amount_tax') }}</th>
                  <th width="80px">{{ $t('sales_invoices.total_amount_before_discount') }}</th>
                  <th width="80px">{{ $t('sales_invoices.total_amount_after_discount') }}</th>
                  <th width="10px"></th>
                </tr>

                </thead>
                <tbody>
                <tr v-for="(row, index) in items_list" :key="index" :class="getClassValidationRepeaterByIndex(index)">
                  <td>{{ getCounterIndex(index) }}</td>
                  <td>
                    <!-- <input v-model="row.description" type="text" class="form-control"> -->
                    <textarea v-model="row.description" class="form-control" rows="3" cols="15"></textarea>
                    <span v-if="validation && validation[`items_list.${index}.description`]" class="fv-plugins-message-container invalid-feedback">
                          {{ validation[`items_list.${index}.description`][0] }}
                        </span>
                  </td>
                  <td>
                    <input v-model="row.unit_price" @input="changeRepeaterValue(index)" type="number" class="form-control" :class="validation && validation[`items_list.${index}.unit_price`] ? 'is-invalid' : ''">
                    <span v-if="validation && validation[`items_list.${index}.unit_price`]" class="fv-plugins-message-container invalid-feedback">
                          {{ validation[`items_list.${index}.unit_price`][0] }}
                        </span>
                  </td>
                  <td>
                    <multiselect v-model="row.unit" v-b-tooltip.hover.leftbottom :title="$t('type_at_least_three_letters') + ' ' + $t('sales_invoices.unit')"
                                 :placeholder="$t('sales_invoices.unit')"
                                 label="name"
                                 track-by="id"
                                 :options="units"
                                 :multiple="false"
                                 :taggable="false"
                                 :show-labels="false"
                                 :show-no-options="false"
                                 @input="setValueUnit(index)"
                                 :show-no-results="false">
                    </multiselect>
                    <span v-if="validation && validation[`items_list.${index}.unit`]" class="fv-plugins-message-container invalid-feedback">
                          {{ validation[`items_list.${index}.unit`][0] }}
                        </span>
                    <!--                    <input v-model="row.units_number" v-if="row.unit && row.unit.parent_id" min="0" @input="changeRepeaterValue(index)" type="number" class="form-control mt-3">-->

                  </td>
                  <td>
                    <input v-model="row.qty" @input="changeRepeaterValue(index)" type="number" class="form-control">
                    <span v-if="validation && validation[`items_list.${index}.qty`]" class="fv-plugins-message-container invalid-feedback">
                          {{ validation[`items_list.${index}.qty`][0] }}
                        </span>
                  </td>

                  <td v-if="isApplyTaxesInvoice">
                    <!--                    <template>-->
                    <!--                      <div class="d-flex mb-2" v-for="(_row, _index) in row.tax_applied">-->
                    <multiselect v-model="row.tax_applied" v-b-tooltip.hover.leftbottom :title="$t('type_at_least_three_letters') + ' ' + $t('sales_invoices.tax')"
                                 :placeholder="$t('sales_invoices.tax')"
                                 label="name"
                                 track-by="id"
                                 :options="taxes"
                                 :multiple="true"
                                 :taggable="false"
                                 :show-labels="false"
                                 :show-no-options="false"
                                 :show-no-results="false"
                                 @input="changeRepeaterValue(index)">
                      <!--                                     @search-change="getTaxes($event)">-->
                    </multiselect>
                    <!--                        <a href="javascript:;" @click="removeTaxFromOneRow(index, _index)">-->
                    <!--                          <i class="fa fa-trash text-danger m-2"></i>-->
                    <!--                        </a>-->

                    <!--                        <a v-if="_index ==  row.tax_applied.length - 1" href="javascript:;" @click="addMoreTaxesInOneRow(index)">-->
                    <!--                          <i class="fa fa-plus text-primary m-2"></i>-->
                    <!--                        </a>-->

                    <!--                      </div>-->

                    <!--                    </template>-->
                  </td>
                  <td>
                    <div class="d-flex">
                      <select name="" id="discount_type" @change="changeDiscountInRepeater(index)" v-model="row.discount_type" class="custom-select" style="width: 83px;">
                        <option v-for="row in discount_types" :value="row.id" :key="row.id">{{ row.name }}</option>
                      </select>
                      <input v-model="row.discount_value" @input="changeDiscountInRepeater(index)" type="number" class="form-control">

                    </div>
                  </td>
                  <td v-if="isApplyTaxesInvoice">{{ row.subtotal_before_tax }}</td>
                  <td v-if="isApplyTaxesInvoice">{{ row.amount_tax }}</td>
                  <td>{{ row.subtotal_before_discount && row.subtotal_before_discount > 0 ? parseToShortFloat(row.subtotal_before_discount) : row.subtotal_before_discount }}</td>
                  <td>{{ row.subtotal_after_discount && row.subtotal_after_discount > 0 ? parseToShortFloat(row.subtotal_after_discount) : row.subtotal_after_discount }}</td>
                  <td>
                    <v-icon style="color: #dc3545;" class="m-40-4" small v-if="items_list.length > 1" @click="removeItemRowFromList(index)">mdi-delete</v-icon>
                  </td>
                </tr>
                </tbody>
                <tfoot>
                <tr>
                  <td :colspan="isApplyTaxesInvoice ? '7' : '4'"></td>
                  <th colspan="2">{{ $t('sales_invoices.total_amount_before_discount') }}</th>
                  <!-- <th colspan="2"><span>{{ data.total_amount_before_discount ? data.total_amount_before_discount : '0' }} {{ currency_code }}</span></th> -->
                  <th colspan="2"><span>{{ data.total_amount_before_discount && data.total_amount_before_discount > 0 ? parseToShortFloat(data.total_amount_before_discount) : '0' }} {{ currency_code }}</span></th>
                  <th></th>
                </tr>
                <tr>
                  <td class="border-0" :colspan="isApplyTaxesInvoice ? '7' : '4'"></td>
                  <th colspan="2">{{ $t('sales_invoices.total_amount_after_discount') }}</th>
                  <!-- <th colspan="2"><span>{{ data.total_amount_after_discount ? data.total_amount_after_discount : '0' }} {{ currency_code }}</span></th> -->
                  <th colspan="2"><span>{{ data.total_amount_after_discount && data.total_amount_after_discount > 0 ? parseToShortFloat(data.total_amount_after_discount) : '0' }} {{ currency_code }}</span></th>
                  <th></th>
                </tr>
                <tr>
                  <td class="border-0" :colspan="isApplyTaxesInvoice ? '7' : '4'"></td>
                  <th colspan="2">{{ $t('sales_invoices.total_discount') }}</th>
                  <th colspan="2"><span>{{ data.invoice_total_discount ? data.invoice_total_discount : '0' }}  {{ currency_code }}</span></th>
                  <th></th>
                </tr>
                <tr>
                  <td class="border-0" :colspan="isApplyTaxesInvoice ? '7' : '4'"></td>
                  <th colspan="2">{{ $t('sales_invoices.total_after_shipping') }}</th>
                  <th colspan="2"><span>{{ data.shipping_fees ? data.shipping_fees : '0' }}  {{ currency_code }}</span></th>
                  <th></th>
                </tr>
                <tr v-if="isApplyTaxesInvoice">
                  <td class="border-0" :colspan="isApplyTaxesInvoice ? '7' : '4'"></td>
                  <th colspan="2">{{ $t('sales_invoices.total_tax') }}</th>
                  <th colspan="2"><span>{{ data.invoice_total_taxes ? data.invoice_total_taxes : '0' }}  {{ currency_code }}</span></th>
                  <th></th>
                </tr>
                <tr>
                    <td class="border-0" :colspan="isApplyTaxesInvoice ? '7' : '4'"></td>
                    <th colspan="2">{{ $t('sales_invoices.total_before_additional_discount') }}</th>
                    <th colspan="2"><span>{{ data.total_before_additional_discount ? data.total_before_additional_discount : '0' }} {{ currency_code }}</span></th>
                    <th></th>
                  </tr>
                  <tr>
                    <td class="border-0" :colspan="isApplyTaxesInvoice ? '7' : '4'"></td>
                    <th colspan="2">{{ $t('sales_invoices.additional_discount') }}</th>
                    <th colspan="2"><span>{{ data.additional_discount ? data.additional_discount : '0' }} {{ currency_code }}</span></th>
                    <th></th>
                  </tr>
                <tr>
                  <td class="border-0" :colspan="isApplyTaxesInvoice ? '7' : '4'"></td>
                  <th colspan="2">{{ $t('sales_invoices.final_total') }} <i class="fa fa-exclamation-circle ml-2 mr-2" v-b-tooltip.hover.leftbottom :title="$t('sales_invoices.total_amount_before_discount') + ' - ' + $t('sales_invoices.total_discount') + ' + ' + $t('sales_invoices.total_after_shipping') + ' + ' + $t('sales_invoices.total_tax')+ ' - ' + $t('sales_invoices.additional_discount') "></i></th>
                  <!-- <th colspan="2"><span>{{ data.invoice_total ? data.invoice_total : '0' }}  {{ currency_code }}</span></th> -->
                  <th colspan="2"><span>{{ data.is_zero_invoice == false ? data.invoice_total : sum_invoice_total }} {{ currency_code }}</span></th>
                  <th></th>
                </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>


        <div class="mt-5">
          <b-tabs content-class="mt-3" class="nav-custom-link">
            <b-tab :title="$t('discount_and_settlement')">
              <div class="card card-custom">
                <div class="card-body row">


                  <div class="col-lg-3 mb-5">
                    <label>{{ $t('sales_invoices.discount_type') }}</label>
                    <select name="" id="discount_types" v-model="data.discount_type" @input="reCalcGeneralDiscount" class="custom-select" :class="validation && validation.discount_type ? 'is-invalid' : ''">
                      <option v-for="row in discount_types" :value="row.id" :key="row.id">{{ row.name }}</option>
                    </select>
                    <span v-if="validation && validation.discount_type" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.discount_type[0] }}
                                    </span>
                  </div>
                  <div class="col-lg-3 mb-5">
                    <label>{{ $t('sales_invoices.discount_value') }}</label>
                    <input type="number" v-model="data.discount_value" @input="reCalcGeneralDiscount" class="form-control" :class="validation && validation.discount_value ? 'is-invalid' : ''"/>
                    <span v-if="validation && validation.discount_value" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.discount_value[0] }}
                                    </span>
                    <span v-if="is_show_validation_dis_value" class="fv-plugins-message-container invalid-feedback">
                                        {{ $t('discount_must_be_less_than_or_equal_total_invoice') }}
                                    </span>
                  </div>
                  <div class="col-lg-3 mb-5">
                    <label>{{ $t('sales_invoices.adjustment') }}</label>
                    <input type="number" v-model="data.adjustment_value" class="form-control" @input="calcItemListForTotal" :class="validation && validation.adjustment_value ? 'is-invalid' : ''"/>
                    <span v-if="validation && validation.adjustment_value" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.adjustment_value[0] }}
                                    </span>
                  </div>
                  <div class="col-lg-3 mb-5">
                    <label>{{ $t('sales_invoices.deposite_value') }}</label>
                    <input type="number" v-model="data.deposite_value" class="form-control" :class="validation && validation.deposite_value ? 'is-invalid' : ''"/>
                    <span v-if="validation && validation.deposite_value" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.deposite_value[0] }}
                                    </span>
                  </div>
                </div>
              </div>
            </b-tab>
            <b-tab :title="$t('sales_invoices.additional_discount')">
                <div class="card card-custom">
                  <div class="card-body row">


                    <div class="col-lg-3 mb-5">
                      <label>{{ $t('sales_invoices.additional_discount_type') }}</label>
                      <select name="" id="discount_types" v-model="data.additional_discount_type" @change="calcAdditionalDiscount" class="custom-select" :class="validation && validation.additional_discount_type ? 'is-invalid' : ''">
                        <option v-for="row in discount_types" :value="row.id" :key="row.id">{{ row.name }}</option>
                      </select>
                      <span v-if="validation && validation.additional_discount_type" class="fv-plugins-message-container invalid-feedback">
                          {{ validation.additional_discount_type[0] }}
                      </span>
                    </div>
                    <div v-if="data.additional_discount_type == 2" class="col-lg-3 mb-5">
                      <label>{{ $t('sales_invoices.additional_discount_rate') }}</label>
                      <input type="number" v-model="data.additional_discount_rate"  @input="calcAdditionalDiscount" class="form-control" :class="validation && validation.additional_discount_rate ? 'is-invalid' : ''"/>
                      <span v-if="validation && validation.additional_discount_rate" class="fv-plugins-message-container invalid-feedback">
                          {{ validation.additional_discount_rate[0] }}
                      </span>
                    </div>
                    <div v-if="data.additional_discount_type == 3" class="col-lg-3 mb-5">
                      <label>{{ $t('sales_invoices.additional_discount_value') }}</label>
                      <input type="number" v-model="data.additional_discount"  @input="calcAdditionalDiscount" class="form-control" :class="validation && validation.additional_discount ? 'is-invalid' : ''"/>
                      <span v-if="validation && validation.additional_discount" class="fv-plugins-message-container invalid-feedback">
                          {{ validation.additional_discount[0] }}
                      </span>
                    </div>
                  </div>
                </div>
              </b-tab>
            <b-tab :title="$t('delivery_information')">
              <div class="card card-custom">
                <div class="card-body row">
                  <div class="col-lg-3 mb-5">
                    <label>{{ $t('sales_invoices.shipping_fees') }}</label>
                    <input type="number" v-model="data.shipping_fees" @input="calcItemListForTotal" class="form-control" :class="validation && validation.shipping_fees ? 'is-invalid' : ''"/>
                    <span v-if="validation && validation.shipping_fees" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.shipping_fees[0] }}
                                    </span>
                  </div>
                </div>
              </div>
            </b-tab>

            <b-tab :title="$t('current_payment')" :disabled="(data.is_paid == 1 || data.is_paid == 2) && isEditing">
              <div class="card card-custom">
                <div class="card-body row">

                  <div class="col-lg-12 mb-5">
                    <b-form-checkbox id="checkbox-paid" v-model="data.initially_paid" @input="changeInvoiceSavingStatus" name="checkbox-paid" :value="1">
                      {{ $t('already_paid') }}
                    </b-form-checkbox>
                  </div>

                  <div class="col-lg-3 mb-5">
                    <label>{{ $t('payment_sales_invoices.payment_method') }}</label>
                    <select name="" id="payment_method" v-model="data.initial_payment_method" @change="onPaymentMethodChanged(data.initial_payment_method)" class="custom-select" :class="validation && validation.initial_payment_method ? 'is-invalid' : ''">
                      <option v-for="row in payment_methods" :value="row.id" :key="row.id" :disabled="row.id==0">{{ row.title }}</option>
                    </select>
                    <span v-if="validation && validation.initial_payment_method" class="fv-plugins-message-container invalid-feedback">
                            {{ validation.initial_payment_method[0] }}
                        </span>
                  </div>

                  <!-- <div class="col-lg-3 mb-5" v-if="initial_payment_method == 2 || initial_payment_method == 3"> -->
                  <div class="col-lg-3 mb-5" v-if="cash_type == 'bank'">
                    <label>{{ $t('payment_sales_invoices.banks') }}<span v-if="data.initially_paid == 1" class="text-danger">*</span></label>
                    <div class="input-group">
                      <multiselect v-model="bank"
                                   :class="validation && validation.initial_payment_method_type_id ? 'is-invalid' : ''"
                                   :placeholder="$t('payment_sales_invoices.banks')"
                                   label="name"
                                   track-by="id"
                                   :options="banks"
                                   :multiple="false"
                                   :taggable="false"
                                   :show-labels="false"
                                   :show-no-options="false"
                                   :show-no-results="false"
                                   @search-change="getBanks($event)">
                      </multiselect>
                      <div class="input-group-prepend">
                        <a class="btn btn-primary" href="/finances/banks/create" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                      </div>
                    </div>
                    <span class="form-text text-muted" style="text-align:end">{{ $t('type_at_least_three_letters') + ' ' + $t('payment_sales_invoices.banks') }}.</span>
                    <span v-if="validation && validation.initial_payment_method_type_id" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.initial_payment_method_type_id[0] }}
                            </span>
                  </div>

                  <div class="col-lg-3 mb-5" v-else>
                    <label>{{ $t('payment_sales_invoices.treasuries') }}<span v-if="data.initially_paid == 1" class="text-danger">*</span></label>
                    <div class="input-group">
                      <multiselect v-model="treasury"
                                   :class="validation && validation.initial_payment_method_type_id ? 'is-invalid' : ''"
                                   :placeholder="$t('payment_sales_invoices.treasuries')"
                                   label="name"
                                   track-by="id"
                                   :options="treasuries"
                                   :multiple="false"
                                   :taggable="false"
                                   :show-labels="false"
                                   :show-no-options="false"
                                   :show-no-results="false">
                      </multiselect>
                      <div class="input-group-prepend">
                        <a class="btn btn-primary" href="/finances/treasuries/create" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                      </div>
                    </div>
                    <span class="form-text text-muted" style="text-align:end">{{ $t('type_at_least_three_letters') + ' ' + $t('payment_sales_invoices.treasuries') }}.</span>
                    <span v-if="validation && validation.initial_payment_method_type_id" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.initial_payment_method_type_id[0] }}
                            </span>
                  </div>

                  <div class="col-lg-3 mb-5">
                    <label>{{ $t('payment_sales_invoices.amount') }}<span v-if="data.initially_paid == 1" class="text-danger">*</span></label>
                    <input type="number" v-model="data.initial_payment_amount" class="form-control" :class="validation && validation.initial_payment_amount ? 'is-invalid' : ''"/>
                    <span v-if="validation && validation.initial_payment_amount" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.initial_payment_amount[0] }}
                            </span>
                  </div>

                </div>
              </div>
            </b-tab>

            <!--                  <b-tab :title="$t('deposit')">-->
            <!--                    <div class="card card-custom">-->
            <!--                      <div class="card-body row">-->
            <!--                      </div>-->
            <!--                    </div>-->
            <!--                  </b-tab>-->
            <b-tab :title="$t('notes')">
              <div class="card card-custom">
                <div class="card-body row">

                  <div class="col-lg-12 mb-5">
                    <label>{{ $t('sales_invoices.notes') }}</label>
                    <EditorTextarea ref="editor" :value-data.sync="data.notes" :dir-upload="'sales'"></EditorTextarea>
<!--                    <textarea name="" id="notes" v-model="data.notes" class="form-control" :class="validation && validation.notes ? 'is-invalid' : ''"></textarea>-->
                    <span v-if="validation && validation.notes" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.notes[0] }}
                            </span>
                  </div>
                </div>
              </div>
            </b-tab>
            <b-tab :title="$t('sales_invoices.amount_in_letters')">
              <div class="card card-custom">
                <div class="card-body row">
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('sales_invoices.word_invoice_total') }}</label>
                    <textarea name="" id="notes" v-model="data.word_invoice_total" class="form-control" :class="validation && validation.word_invoice_total ? 'is-invalid' : ''"></textarea>
                    <span v-if="validation && validation.word_invoice_total" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.word_invoice_total[0] }}
                            </span>
                  </div>
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('sales_invoices.word_invoice_total_en') }}</label>
                    <textarea name="" id="notes" v-model="data.word_invoice_total_en" class="form-control" :class="validation && validation.word_invoice_total_en ? 'is-invalid' : ''"></textarea>
                    <span v-if="validation && validation.word_invoice_total_en" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.word_invoice_total_en[0] }}
                            </span>
                  </div>
                </div>
              </div>
            </b-tab>
          </b-tabs>


        </div>
      </b-tab>
      <b-tab v-if="idEditing" :title="$t('activity_log.activity_log')" @click="toggleTab('activity_log')">
        <div class="card card-custom mt-5">
          <div class="card-body">
            <br>
            <activity-log :inner-key="key" :id="idEditing"></activity-log>
          </div>
        </div>
      </b-tab>
    </b-tabs>


    <div class="pl-0 pr-0" v-if="tab_name != 'activity_log'">
      <div class="row">

<!--        <div v-if="!data.is_confirmed || (is_adjusted && idEditing)" class="col-lg-6 mt-10">-->
        <div v-if="!is_confirmed ||  (is_adjusted && idEditing)" class="col-lg-6 mt-10">
          <button class="btn btn-primary mr-2" @click="save($event, 1)">{{ $t('save') }}</button>
          <button v-if="!isEditing" class="btn btn-primary mr-2" @click="save($event, 2)">{{ $t('save_and_create_new') }}</button>
          <button v-if="!isEditing" class="btn btn-primary mr-2" @click="save($event, 3)">{{ $t('save_and_redirect_to_main_page') }}</button>

          <!--          <button type="reset" class="btn btn-secondary">{{ $t('cancel') }}</button>-->
        </div>

      </div>
    </div>
    <!--    </div>-->
    <!--end::customer-->
    <b-modal ref="modal" size="lg" hide-footer :title="$t('customers.add_new_customer')">
      <customer-form @hide-modal="hideModal()" @handling-data="getDataAfterCreateNewCustomer"></customer-form>
    </b-modal>
    <b-modal ref="modalCustomerAddress" size="lg" hide-footer :title="$t('add_new_customer_address')">
      <customer-address-info @hide-modal="hideCustomerAddressModal()" @handling-data="getDataAfterCreateNewCustomerAddress" :customer-id="data.customer_id"></customer-address-info>
    </b-modal>
  </div>
</template>
<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import CustomerForm from "@/view/content/forms/CustomerForm";
import ActivityLog from "@/view/content/widgets/details/ActivityLog";
import {mapGetters, mapState} from "vuex";
import CustomerAddressInfo from "@/view/content/forms/CustomerAddressInfo.vue";
import Vue from "vue";
import changeStatus from "@/core/config/mix/changeStatus";
import dataTypeNumbers from "@/core/config/mix/dataTypeNumbers";
import timeZoneStructure from "@/core/config/mix/timeZoneStructure";
import EditorTextarea from "@/view/content/widgets/inputs/EditorTextarea.vue";

export default {
  name: "form-general-sales-order",
  components: {EditorTextarea, 'customer-form': CustomerForm, 'activity-log': ActivityLog, 'customer-address-info': CustomerAddressInfo},
  data() {
    return {
      mainRoute: 'sales/general_sales',
      mainRouteDependency: 'base/dependency',
      mainRouteForSalesQuotations: 'sales/general_sales_quotations',
      mainTaxSettingsRoute: 'sales/tax_setting_sales',
      key: 'GeneralSales',

      data: {
        branch_id: null,
        invoice_code: null,
        reference_number: null,
        invoice_subtotal: 0,
        invoice_total: 0,
        invoice_total_discount: 0,
        invoice_total_shipping: 0,
        invoice_total_taxes: 0,
        total_amount_before_discount: 0,
        total_amount_after_discount: 0,
        invoice_total_before_taxes: 0,
        invoice_total_after_taxes: 0,
        total_subtotal_before_discount_out_tax: 0,
        total_subtotal_after_discount_out_tax: 0,
        shipping_fees: null,
        customer_id: null,
        currency_id: null,
        invoice_date: null,
        notes: null,
        payment_terms: null,
        // exchange_rate: null,
        interested_user_id: null,
        status: 1,
        apply_tax_rules: [],
        discount_type: 1,
        discount_value: null,
        adjustment_value: null,
        deposite_value: null,
        /**
         * payments data
         * @param val
         */
        initial_payment_method: 1,
        initial_payment_amount: null,
        initial_payment_method_type_type: null,
        initial_payment_method_type_id: null,
        initially_paid: 0,
        is_paid: null,
        // is_confirmed: false,
        is_zero_invoice: false,
        is_deferred: false,
        word_invoice_total: null,
        word_invoice_total_en: null,
        is_apply_taxes_invoice: false,
        total_out_tax: 0,
        total_tax_type_val: [],
        default_status: 1,

        additional_discount: 0,
        total_before_additional_discount: 0,
        additional_discount_type: 1,
        additional_discount_rate: 0,
      },
      branches: [],
      branch: null,
      sum_invoice_total: 0,
      idEditing: this.$route.params.id ? this.$route.params.id : null,
      isEditing: this.$route.params.id ? true : false,
      idClone: this.$route.params.clone_id ? this.$route.params.clone_id : null,
      sales_request_id: this.$route.params.quotation_requests_id ? this.$route.params.quotation_requests_id : null,
      quotation_type: this.$route.params.quotation_type ? this.$route.params.quotation_type : null,
      isDisabled: false,
      customers: [],
      currencies: [],
      status_list: [],
      // inventories: [],
      // inventory: null,
      items: [],
      taxes: [],
      units: [],
      users: [],
      user: null,
      // accounts: [],
      // account: null,
      // branch: null,
      // branches: [],
      validation: null,
      tax_default: null,
      unit_default: null,
      customer: null,
      items_list_form: {id: null, unique_key: null, description: null, unit_price: null, qty: null, unit: null, tax: null, discount_type: 1, discount_value: null, subtotal_before_tax: null, subtotal_after_tax: null, subtotal: null, amount_tax: null, subtotal_before_discount: null, subtotal_after_discount: null,
        tax_applied: [], amount_excluded_tax: 0, amount_included_tax: 0, total_item_after_discount_with_excluded: null, data_validation: [], subtotal_before_discount_out_tax: 0, subtotal_after_discount_out_tax: 0},
      items_list: [],
      discount_types: [
        {id: 1, name: '-'},
        {id: 2, name: '%'},
        {id: 3, name: this.$t('discount_types.value')},
      ],
      tab_name: 'basic_information',
      PlanMessage: null,
      countries: [],
      cities: [],

      shipping_address: {
        country: null,
        country_code2: null,
        city: null,
        city_id: null,
        address_type: 1,
        address_class: 1,
        is_active: true,
        address: null,
      },
      shipping_address_dummy: {
        country: null,
        country_code2: null,
        city: null,
        city_id: null,
        address_type: 1,
        address_class: 1,
        is_active: true,
        address: null,
      },
      billing_address: {
        country: null,
        country_code2: null,
        city: null,
        city_id: null,
        address_type: 1,
        address_class: 2,
        is_active: true,
        address: null,
      },
      billing_address_dummy: {
        country: null,
        country_code2: null,
        city: null,
        city_id: null,
        address_type: 1,
        address_class: 2,
        is_active: true,
        address: null,
      },
      currency_code: null,
      customer_address: null,
      customer_addresses: [],
      taxes_rules_for_tax_setting: [],

      /**
       * payments out data
       * @param val
       */
      payment_methods: [],
      treasuries: [],
      treasury: null,
      banks: [],
      bank: null,
      initial_payment_method: 1,

      is_confirmed: false,
      routeChangeStatus: 'sales/general_sale/change-status',
      is_show_validation_dis_value: false,
      is_allow_tax_calc: false,
      is_adjusted: false,
      general_tax_id: null,
      general_tax: null,
      repeater_validation: [],
      default_status_list: [],
      cash_type: 'treasury',
    };
  },
  computed: {
    ...mapState({user_personal_info: state => state.profile.user_personal_info}),
    ...mapGetters(["currentUserPersonalInfo"]),
    isSystemUser: function () {
      return this.currentUserPersonalInfo.is_system_user
    },
    isApplyTaxesInvoice() {
      let _status = this.data.is_apply_taxes_invoice;
      // if (_status){
      //   this.setTaxForEveryItem()
      // }else{
      //   this.removeTaxFromEveryItem()
      // }
      // this.calcItemListForTotal();
      return _status;
    },
  },
  watch: {
    branch: function (val) {
      if (val) {
        this.data.branch_id = val.id;
      } else {
        this.data.branch_id = null;
      }
    },
    'data.is_zero_invoice': function (val) {
      this.sum_invoice_total = this.data.invoice_total;

      if (this.idEditing) {
        this.calcItemListForTotal();
      }
      if (val == false) {
        this.sum_invoice_total = this.data.invoice_total;
      } else {
        this.sum_invoice_total = 0;
      }
    },

    "shipping_address.country": function (val) {
      if (val) {
        this.shipping_address.country_code2 = val.code2;
        this.getCities(val.code2);
      } else {
        this.shipping_address.country_code2 = null;
      }
    },
    "shipping_address.city": function (val) {
      if (val) {
        this.shipping_address.city_id = val.id;
      }
    },
    "billing_address.country": function (val) {
      if (val) {
        this.billing_address.country_code2 = val.code2;
        this.getCities(val.code2);
      } else {
        this.billing_address.country_code2 = null;
      }
    },
    "billing_address.city": function (val) {
      if (val) {
        this.billing_address.city_id = val.id;
      }
    },
    // account: function (val) {
    //   if (val) {
    //     this.data.account_id = val.id;
    //   }
    // },
    // items_list: {
    //   handler(val) {
    //     if (val.length > 0) {
    //       this.calcItemListForTotal();
    //     }
    //   },
    //   deep: true
    // },
    '$route'(val) {
      if (val.params.id) {
        this.idEditing = val.params.id;
        this.getData();
      }
    },
    customer: function (val) {
      if (val) {
        this.data.customer_id = val.id;
        this.getCustomerAddresses();
      } else {
        this.data.customer_id = null;
      }
    },
    'data.customer_id': function (val) {
      if (val) {
        let _cu = this.customers.filter(row => row.id == val);
        if (_cu[0]) {

          _cu = _cu[0]
          if (_cu.currency_id) {
            this.data.currency_id = _cu.currency_id;
          }
        }
      }
    },
    // inventory: function (val) {
    //   if (val) {
    //     this.data.inventory_id = val.id;
    //   } else {
    //     this.data.inventory_id = null;
    //   }
    // },
    "data.currency_id": function (val) {
      if (val) {
        let _currency = this.currencies.find((row) => row.id == val);
        if (_currency) {
          this.currency_code = _currency.code;
        } else {
          this.currency_code = null;
        }
      } else {
        this.currency_code = null;
      }
      // if (val) {
      //   this.getExchangeRate(val);
      // } else {
      //   this.data.exchange_rate = null;
      // }
    },

    /**
     * payments watches
     * @param val
     */
    "data.payment_method": function (val) {
      if (val) {
        this.payment_method = val;
      }
    },
    treasury: function (val) {
      if (val && val.id) {
        this.data.initial_payment_method_type_type = 'Treasuries';
        this.data.initial_payment_method_type_id = val.id;
        // this.cash_type = 'treasury';
      }
    },
    user: function (val) {
      if (val) {
        this.data.interested_user_id = val.id;
      } else {
        this.data.interested_user_id = null;
      }
    },
    bank: function (val) {
      if (val && val.id) {
        this.data.initial_payment_method_type_type = 'Bank';
        this.data.initial_payment_method_type_id = val.id;
        // this.cash_type = 'bank';
      }
    },
    "data.shipping_fees": function (val) {
      if (val) {
        if (String(val).length > 12) {
          this.data.shipping_fees = String(val).slice(0, 12);
        }
      }
    },

  },
  methods: {
    ...changeStatus,
    ...dataTypeNumbers,
    ...timeZoneStructure,
    changeInvoiceSavingStatus() {
      if (this.data.initially_paid == 1) {
        this.data.default_status = 2;
      } else {
        this.data.default_status = 1;
      }
      this.getCode();
    },
    // changeInitiallyPaid() {
    //   if (this.data.default_status == 2) {
    //     this.data.initially_paid = 1;
    //   } else {
    //     this.data.initially_paid = 0;
    //   }
    // },
    getBranches() {
      ApiService.get(this.mainRouteDependency + "/branches").then((response) => {
        this.branches = response.data.data;
      });
    },
    setGeneralTax(row) {
      if (row) {
        this.general_tax_id = row.id;
      } else {
        this.general_tax_id = null;
      }
    },
    getCounterIndex(index) {
      return this.items_list.length - index;
    },
    getClassValidationRepeaterByIndex(index) {
      if (index != null) {
        if (this.repeater_validation.includes(index))
            // return 'tr-validation-error';
          return 'border-validation';
      }
      return '';
    },
    validationQTYChecking(_status = true) {
      let _main_validation_status = false;
      this.validation = [];
      this.items_list.forEach((row, index) => {
        let _validation_status = false;
        if (parseFloat(row.qty) <= 0) {
          this.validation[`items_list.${index}.qty`] = [];
          this.validation[`items_list.${index}.qty`][0] = this.$t('quantity_must_be_greater_than_0');
          _validation_status = true;
        } else if (!row.qty && _status) {
          this.validation[`items_list.${index}.qty`] = [];
          this.validation[`items_list.${index}.qty`][0] = this.$t('quantity_must_be_greater_than_0');
          _validation_status = true;
        }

        if (parseFloat(row.unit_price) <= 0) {
          this.validation[`items_list.${index}.unit_price`] = [];
          this.validation[`items_list.${index}.unit_price`][0] = this.$t('unit_price_must_be_greater_than_0');
          _validation_status = true;
        } else if (!row.unit_price && _status) {
          this.validation[`items_list.${index}.unit_price`] = [];
          this.validation[`items_list.${index}.unit_price`][0] = this.$t('unit_price_must_be_greater_than_0');
          _validation_status = true;
        }

        if (!row.description && _status) {
          this.validation[`items_list.${index}.description`] = [];
          this.validation[`items_list.${index}.description`][0] = this.$t('the_description_field_must_be_required');
          _validation_status = true;
        }
        if (!row.unit && _status) {
          this.validation[`items_list.${index}.unit`] = [];
          this.validation[`items_list.${index}.unit`][0] = this.$t('the_unit_field_must_be_required');
          _validation_status = true;
        }

        if (parseFloat(row.amount_tax) <= 0 && row.tax_applied.length > 0) {
          _validation_status = true;
        } else if (!row.amount_tax && _status && row.tax_applied.length > 0) {
          _validation_status = true;
        }

        if (parseFloat(row.subtotal_before_discount) <= 0) {
          _validation_status = true;
        } else if (!row.subtotal_before_discount && _status) {
          _validation_status = true;
        }

        if (_validation_status && _status) {
          let _index = this.repeater_validation.indexOf(index);
          if (_index < 0) {
            this.repeater_validation.push(index);
          }
        } else {
          let _index = this.repeater_validation.indexOf(index);
          if (_index > -1) {
            this.repeater_validation.splice(_index, 1);
          }
        }

      });
      if (this.repeater_validation.length > 0) {
        _main_validation_status = true;
      }
      if (_main_validation_status) {
        let _items = [...this.items_list];
        this.items_list = [];
        this.items_list = _items;
        if (_status)
          this.$errorAlertMessage(this.$t('unable_to_send_due_to_data_entry_error'));
      }
      return _main_validation_status;

    },
    // validationQTYChecking(_status = true) {
    //   let _validation_status = false;
    //   this.validation = [];
    //   this.items_list.forEach((row, index) => {
    //     if (parseFloat(row.qty) <= 0) {
    //       this.validation[`items_list.${index}.qty`] = [];
    //       this.validation[`items_list.${index}.qty`][0] = this.$t('quantity_must_be_greater_than_0');
    //       _validation_status = true;
    //     }else if(!row.qty && _status){
    //       this.validation[`items_list.${index}.qty`] = [];
    //       this.validation[`items_list.${index}.qty`][0] = this.$t('quantity_must_be_greater_than_0');
    //       _validation_status = true;
    //
    //     }
    //   });
    //   if (_validation_status){
    //     let _items = [...this.items_list];
    //     this.items_list = [];
    //     this.items_list = _items;
    //     if (_status)
    //       this.$errorAlertMessage(this.$t('zero_quantities_cannot_be_accepted'));
    //   }
    //   return _validation_status;
    // },
    refactorValidation() {
      this.validationQTYChecking(false);
    },
    redirectActionAfterSave(_action, id) {
      switch (_action) {
        case 1:
          this.isEditing = true;
          this.$router.push({name: 'general-sales.edit', params: {id: id}});
          break;
        case 2:
          this.$router.push({query: {_pgid: Date.now()}});
          // this.$router.push({name: 'general-sales.create'});
          break;
        case 3:
          this.$router.push({name: 'general-sales.index'});
          break;
        default:
          this.isEditing = true;
          this.$router.push({name: 'general-sales.edit', params: {id: id}});
          break;
      }
    },
    save(event, _action) {
      if (this.validationQTYChecking()) {
        event.preventDefault();
        return false;
      } else {
        if (this.isEditing) {
          this.update();
        } else {
          this.create(_action);
        }
      }
    },

    create(_action) {
      let address = [
        this.shipping_address,
        this.billing_address,
      ];
      ApiService.post(`${this.mainRoute}`, {
        items_list: this.items_list,
        address: address,
        ...this.data,
      })
          .then((response) => {
            this.changeStatus(response.data.data.id);
            // Vue.prototype.$postStatus = true;
            this.validation = null;
            this.$successAlert(response.data.message);
            // this.$router.push({name: 'general-sales.index'});
            this.redirectActionAfterSave(_action, response.data.data.id);
          })
          .catch((error) => {
            Vue.prototype.$postStatus = true;
            this.validation = error.response ? error.response.data.errors : null;
            this.$errorAlert(error);
          });
    },


    update() {
      let address = [
        this.shipping_address,
        this.billing_address,
      ];
      ApiService.put(`${this.mainRoute}/${this.$route.params.id}`, {
        items_list: this.items_list,
        is_adjusted: this.is_adjusted,
        address: address,
        ...this.data,
      })
          .then((response) => {
            this.changeStatus(this.$route.params.id);
            // Vue.prototype.$postStatus = true;
            this.validation = null;
            this.$router.push({name: 'general-sales.index'});
            this.$successAlert(response.data.message);
          })
          .catch((error) => {
            Vue.prototype.$postStatus = true;
            this.$errorAlert(error);
            this.validation = error.response ? error.response.data.errors : null;
          });
    },

    async getData() {
      let _id = (this.idEditing ? this.idEditing : (this.idClone ? this.idClone : null));
      if (_id)
        await ApiService.get(`${this.mainRoute}/${_id}`).then((response) => {

          this.data.invoice_subtotal = response.data.data.invoice_subtotal;
          this.data.invoice_total = response.data.data.invoice_total;
          this.data.invoice_total_discount = response.data.data.invoice_total_discount;
          this.data.invoice_total_shipping = response.data.data.invoice_total_shipping;
          this.data.invoice_total_taxes = response.data.data.invoice_total_taxes;


          this.data.additional_discount = response.data.data.additional_discount ? response.data.data.additional_discount : 0;
          this.data.total_before_additional_discount = response.data.data.total_before_additional_discount ? response.data.data.total_before_additional_discount : 0;
          this.data.additional_discount_type = response.data.data.additional_discount_type ? response.data.data.additional_discount_type : 1;
          this.data.additional_discount_rate = response.data.data.additional_discount_rate ? response.data.data.additional_discount_rate : 0;


          this.branch = response.data.data.branch;
          this.data.total_out_tax = response.data.data.total_out_tax ? response.data.data.total_out_tax : 0;
          this.data.total_tax_type_val = response.data.data.total_tax_type_val ? response.data.data.total_tax_type_val : [];

          this.data.total_amount_before_discount = response.data.data.total_amount_before_discount;
          this.data.total_amount_after_discount = response.data.data.total_amount_after_discount;

          this.data.invoice_total_before_taxes = response.data.data.invoice_total_before_taxes;
          this.data.invoice_total_after_taxes = response.data.data.invoice_total_after_taxes;

          this.data.total_subtotal_before_discount_out_tax = response.data.data.total_subtotal_before_discount_out_tax;
          this.data.total_subtotal_after_discount_out_tax = response.data.data.total_subtotal_after_discount_out_tax;

          this.data.shipping_fees = response.data.data.shipping_fees;

          this.data.customer_id = response.data.data.customer_id;


          this.data.currency_id = response.data.data.currency_id;

          this.data.invoice_date = response.data.data.invoice_date;
          this.data.notes = response.data.data.notes;


          this.data.payment_terms = response.data.data.payment_terms;

          this.customer = response.data.data.customer;
          // this.data.exchange_rate = response.data.data.exchange_rate;
          this.items_list = response.data.data.items_list.reverse();
          this.items_list = this.items_list.map((row)=>{
            row.unique_key = this.getUniqueKeyValue();
            return row;
          });


          // this.inventory = response.data.data.inventory;

          this.shipping_address = response.data.data.shipping_address ? response.data.data.shipping_address : this.shipping_address_dummy;
          this.billing_address = response.data.data.billing_address ? response.data.data.shipping_address : this.billing_address_dummy;

          this.data.apply_tax_rules = response.data.data.apply_tax_rules;

          this.data.discount_type = response.data.data.discount_type;
          this.data.discount_value = response.data.data.discount_value;
          this.data.deposite_value = response.data.data.deposite_value;
          this.data.adjustment_value = response.data.data.adjustment_value;

          this.data.initial_payment_method = response.data.data.initial_payment_method;
          this.data.initial_payment_amount = response.data.data.initial_payment_amount;
          this.data.treasury_id = response.data.data.treasury_id;
          this.data.initial_payment_method_type_type = response.data.data.initial_payment_method_type_type;
          this.data.initial_payment_method_type_id = response.data.data.initial_payment_method_type_id;

          this.cash_type = response.data.data.initial_payment_method_type_type == 'Treasuries' ? 'treasury' : 'bank';


          this.bank = response.data.data.bank;
          this.treasury = response.data.data.treasury;
          this.data.interested_user_id = response.data.data.interested_user_id;
          this.user = response.data.data.user;

          this.data.is_zero_invoice = response.data.data.is_zero_invoice;
          this.data.is_deferred = response.data.data.is_deferred;
          this.data.reference_number = response.data.data.reference_number;

          this.data.word_invoice_total = response.data.data.word_invoice_total;
          this.data.word_invoice_total_en = response.data.data.word_invoice_total_en;
          this.data.is_apply_taxes_invoice = response.data.data.is_apply_taxes_invoice;

          
          if (!this.idClone) {
            this.data.default_status = response.data.data.default_status ? response.data.data.default_status : 1;
            this.data.initially_paid = response.data.data.initially_paid;
            this.data.is_paid = response.data.data.is_paid;
            this.is_confirmed = (response.data.data.status == 2 || response.data.data.status == 5)
            this.data.status = response.data.data.status;
          }

          if (response.data.data.items_list && response.data.data.items_list.length <= 0) {
            this.addItemRowToList();
          }
          if (this.idEditing) {
            this.isEditing = true;
            this.data.invoice_code = response.data.data.invoice_code;
          }
        });
    },

    async getDataForPurchaseRequest() {
      let _routeQuotation;
      _routeQuotation = this.quotation_type == 'general_sales_quotations' ? this.mainRouteForSalesQuotations : this.mainRouteForSalesQuotations
      // console.log(_routeQuotation);
      // console.log(this.sales_request_id);
      if (this.sales_request_id)
        await ApiService.get(`${_routeQuotation}/${this.sales_request_id}`).then((response) => {
          this.data.branch_id = response.data.data.branch_id;
          this.branch = response.data.data.branch;
          this.data.notes = response.data.data.notes;
          this.customer = response.data.data.customer;
          this.data.customer_id = response.data.data.customer_id;

          this.items_list = [];

          if (response.data.data.items_list && response.data.data.items_list.length > 0) {
            response.data.data.items_list.forEach((row) => {
              // this.items_list_form.item = row.item;
              this.items_list_form.description = row.description;
              this.items_list_form.unit_price = row.unit_price;
              this.items_list_form.qty = row.qty;
              this.addItemRowToList();
            });

            this.items_list.forEach((row, index) => {
              this.setValue(index);
            });


          } else {
            this.addItemRowToList();
          }
          this.reloadUploadAttachment = true;
        });
    },

    getCurrencies() {
      ApiService.get(this.mainRouteDependency + "/currencies").then((response) => {
        this.currencies = response.data.data;
      });
    },
    async getUsers(filter) {
      if (filter && filter.length >= 3) {
        await ApiService.get(`${this.mainRouteDependency}/users`, {params: {filter: filter}}).then((response) => {
          this.users = response.data.data;
        });
      } else {
        this.users = [];
      }
    },
    // getInventories() {
    //   ApiService.get(this.mainRouteDependency + "/inventories").then((response) => {
    //     this.inventories = response.data.data;
    //
    //   });
    // },
    // getBranches() {
    //   ApiService.get(this.mainRouteDependency + "/branches").then((response) => {
    //     this.branches = response.data.data;
    //
    //   });
    // },
    async getCustomers(filter) {
      if (filter && filter.length >= 3) {
        await ApiService.get(`${this.mainRouteDependency}/customers`, {params: {filter: filter}}).then((response) => {
          this.customers = response.data.data;
        });
      } else {
        this.customers = [];
      }
    },
    // getItems(f_by = null, filter = null) {
    //   if (filter && filter.length > 3 && f_by)
    //     ApiService.get(this.mainRouteDependency + "/items", {params: {[f_by]: filter}}).then((response) => {
    //       this.items = response.data.data;
    //     });
    // },
// getTaxes(filter) {
    //   if (filter && filter.length >= 3) {
    //     ApiService.get(`${this.mainRouteDependency}/taxes?type=4`, {params: {filter: filter}}).then((response) => {
    //       this.taxes = response.data.data;
    //     });
    //   } else {
    //     this.taxes = [];
    //   }
    // },
    getTaxes() {
      ApiService.get(`${this.mainRouteDependency}/taxes?type=4`).then((response) => {
        this.taxes = response.data.data;
      });
    },
    // getUnits(filter) {
    //   if (filter && filter.length >= 3) {
    //     ApiService.get(`${this.mainRouteDependency}/units`, {params: {filter: filter}}).then((response) => {
    //       this.units = response.data.data;
    //     });
    //   } else {
    //     this.units = [];
    //   }
    // },
    getUnits() {
      ApiService.get(`${this.mainRouteDependency}/units`).then((response) => {
        this.units = response.data.data;
      });
    },

    // getShippingDetails() {
    //   ApiService.get(this.mainRouteDependency + "/shipping_details").then((response) => {
    //     this.shipping_details_list = response.data.data;
    //   });
    // },


    loadOptions() {
    },
    // updateValue(value) {
    //   this.data.account_id = value;
    // },

    // addMoreTaxesInOneRow(index){
    //   this.items_list[index].tax_applied.push({});
    // },
    // removeTaxFromOneRow(parent_index, index){
    //   if (this.items_list[parent_index].tax_applied.length > 1) {
    //     this.items_list[parent_index].tax_applied.splice(index, 1);
    //   }
    // },

    addItemRowToList() {
      this.items_list_form.unique_key = this.getUniqueKeyValue();
      this.items_list_form.tax = this.tax_default;
      if (this.tax_default) {
        this.items_list_form.tax_applied.push(this.tax_default);
      }

      this.items_list_form.unit = this.unit_default;
      this.items_list.unshift(this.items_list_form);
      this.items_list_form = {id: null, unique_key: null, description: null, unit_price: null, qty: null, unit: this.items_list_form.unit, tax: this.items_list_form.tax, discount_type: 1, discount_value: null, subtotal_before_tax: null, subtotal_after_tax: null, subtotal: null, amount_tax: null, subtotal_before_discount: null, subtotal_after_discount: null,
        tax_applied: [], amount_excluded_tax: 0, amount_included_tax: 0, total_item_after_discount_with_excluded: null, data_validation: [], subtotal_before_discount_out_tax: 0, subtotal_after_discount_out_tax: 0};
      this.refactorValidation();
    },
    getUniqueKeyValue() {
      return Math.floor(Math.random() * 1000000000);
    },
    removeItemRowFromList(index = 0) {
      if (this.items_list.length > 1) {
        this.items_list.splice(index, 1);
        if (this.validation[`items_list.${index}.qty`]) {
          delete this.validation[`items_list.${index}.qty`];
        }
        this.refactorValidation();
      }
    },

    selectItem(index) {
      this.items_list[index].unit_price = this.items_list[index].item ? this.items_list[index].item.sale_price : 0;
      this.setValue(index);
    },

    setValueUnit(index) {
      // this.items_list[index].qty = 0;
      this.changeRepeaterValue(index);
    },

    setValue(index) {
      this.zeroForNegative();
      let promise = this.getItemTax(index);

      Promise.all([promise]).then((res) => {
        if (res[0] == undefined){
          return false;
        }
        index = this.items_list.findIndex(it => (it.unique_key == res[0]));
        let _tax = 0;
        // if (this.items_list[index].unit) {
        //   if (this.items_list[index].unit.parent_id) {
        //     this.items_list[index].qty = (this.items_list[index].unit.sub_value_total ? this.items_list[index].unit.sub_value_total : 0) * (this.items_list[index].units_number ? this.items_list[index].units_number : 0);
        //   } else {
        //     this.items_list[index].units_number = 0;
        //   }
        // }


        let _subtotal = (this.items_list[index].unit_price ? this.items_list[index].unit_price : 0) * (this.items_list[index].qty ? this.items_list[index].qty : 0);
        this.items_list[index].subtotal_before_tax = (_subtotal - parseFloat(this.items_list[index].amount_included_tax)).toFixed(2);
        this.items_list[index].subtotal_after_tax = (_subtotal + parseFloat(this.items_list[index].amount_excluded_tax)).toFixed(2);

        let _discount = 0;
        if (this.items_list[index].discount_type == 1 || !this.items_list[index].discount_type) {
          _discount = 0;
        } else {
          _discount = ((this.items_list[index].discount_type == 2 && this.items_list[index].discount_value) ? (parseFloat(this.items_list[index].discount_value) / 100) * _subtotal : (this.items_list[index].discount_value ? parseFloat(this.items_list[index].discount_value) : 0));
        }


        /**
         * This condition worked with tax_applied
         * If tax_applied length > 0
         * subtotal_before_discount_out_tax = 0;
         * subtotal_after_discount_out_tax = 0;
         *
         * ELSE
         * subtotal_before_discount_out_tax = _subtotal
         * subtotal_after_discount_out_tax = _subtotal - _discount
         *
         */
        if (this.items_list[index].tax_applied && this.items_list[index].tax_applied.length > 0){
          this.items_list[index].subtotal_before_discount_out_tax = 0;
          this.items_list[index].subtotal_after_discount_out_tax = 0;
        }else{
          this.items_list[index].subtotal_before_discount_out_tax = _subtotal;
          this.items_list[index].subtotal_after_discount_out_tax = _subtotal - _discount;
        }

        /**
         *
         * calc data subtotal before discount  = unit price * quantity
         * calc data subtotal after discount  = (unit price * quantity) - discount
         */
        this.items_list[index].subtotal_before_discount = _subtotal;
        this.items_list[index].subtotal_after_discount = _subtotal - _discount;


        // this is used to get tax setting value
        // let _tax_rule_value = null;
        // let _tax_rule_id = (this.data.apply_tax_rules && this.data.apply_tax_rules[0])? this.data.apply_tax_rules[0].tax_rule_id : null;
        // if (_tax_rule_id){
        //   let _tax_rule = this.taxes_rules_for_tax_setting.find((row)=> row.id == _tax_rule_id);
        //   let _tax_rule_details = _tax_rule.tax_rule_details[0];
        //   _tax_rule_value = _tax_rule_details ? _tax_rule_details.value : null;
        // }


        // dependant on value calculate the tax
        // if (this.items_list[index] && this.items_list[index].tax) {
        //   let _tax_percentage_include_tax = this.items_list[index].tax ? this.items_list[index].tax.include_tax : 0;
        //
        //   let _tax_percentage_first = this.items_list[index].tax ? this.items_list[index].tax.value_rate : 0;
        //     _tax_percentage_first = _tax_percentage_first ? parseFloat(_tax_percentage_first): 0;
        //
        //   let _tax_first = 0;
        //   if (_tax_percentage_include_tax == 0){
        //     _tax_first = this.items_list[index].unit_price - (this.items_list[index].unit_price / (1 + (_tax_percentage_first / 100)));
        //     _tax += (_tax_first * (this.items_list[index].qty ? this.items_list[index].qty : 1));
        //   }else {
        //     if (_tax_rule_value == 1){
        //       _tax_first = _subtotal * (_tax_percentage_first / 100);
        //       _tax += _tax_first;
        //     }else if (_tax_rule_value == 2){
        //       _tax_first = (_subtotal - _discount) * (_tax_percentage_first / 100);
        //       _tax += _tax_first;
        //     }
        //   }
        //   _subtotal = _subtotal - _tax_first;
        // }


        // _subtotal = _subtotal - _discount;
        // let _tax = _subtotal * (this.items_list[index].tax ? parseFloat(this.items_list[index].tax.value_rate) / 100 : 0);


        // this.items_list[index].subtotal_before_tax = (_subtotal).toFixed(2);
        // this.items_list[index].amount_tax = (_tax).toFixed(2);
        this.items_list[index].total_item_after_discount_with_excluded = (parseFloat(this.items_list[index].subtotal_after_discount) + parseFloat(this.items_list[index].amount_excluded_tax)).toFixed(2);
        this.items_list[index].subtotal_before_discount = this.items_list[index].subtotal_before_discount ? (this.items_list[index].subtotal_before_discount).toFixed(2) : 0;
        this.items_list[index].subtotal_after_discount = this.items_list[index].subtotal_after_discount ? (this.items_list[index].subtotal_after_discount).toFixed(2) : 0;

        this.items_list[index].subtotal = (_subtotal - _discount + _tax).toFixed(2);
        this.calcItemListForTotal();
      })


    },


    showModal() {
      this.$refs['modal'].show()
    },
    hideModal() {
      this.$refs['modal'].hide();
    },
    showCustomerAddressModal() {
      this.$refs['modalCustomerAddress'].show()
    },
    hideCustomerAddressModal() {
      this.$refs['modalCustomerAddress'].hide();
    },

    getDataAfterCreateNewCustomer(object) {
      let promise = this.getCustomers();
      Promise.all([promise]).then(() => {
        this.data.customer_id = object.id;
      })
    },
    getDataAfterCreateNewCustomerAddress(object) {
      let promise = this.getCustomerAddresses();
      Promise.all([promise]).then(() => {
        if (object) {
          this.customer_address = object;
        }
      })
    },
    getCustomerAddresses() {
      if (this.data.customer_id)
        ApiService.get(this.mainRouteDependency + "/customer_addresses/" + this.data.customer_id).then((response) => {
          this.customer_addresses = response.data.data;
          this.customer_address = response.data.data.find(row => row.is_default == true);
        });
    },

    toggleTab(tab_name) {
      this.tab_name = tab_name
    },
    getCode() {
      ApiService.get(this.mainRouteDependency + "/code-setting/4?default_status="+this.data.default_status).then((response) => {
        this.data.invoice_code = response.data.data.code;
      });
    },

    // getAccounts(filter) {
    //   if (filter && filter.length >= 3)
    //     return ApiService.get(this.mainRouteDependency + `/account_parents_leaf_nodes`, {params: {name: filter}}).then((response) => {
    //       this.accounts = response.data.data;
    //     });
    // },
    // getDefaultAccount() {
    //   ApiService.get(`${this.mainRouteDependency}/get_default_account/sales`).then((response) => {
    //     this.account = response.data.data;
    //   });
    // },
    // getExchangeRate(id) {
    //   ApiService.get(`${this.mainRouteDependency}/exchange_rate/` + id).then((response) => {
    //     this.data.exchange_rate = response.data.data.initial_val;
    //   });
    // },
    getPlanMessage() {
      ApiService.get(this.mainRouteDependency + `/check_limits/sales_invoice`).then((response) => {
        this.PlanMessage = response.data.data;
      });
    },


    calcItemListForTotal() {
      if (this.items_list.length > 0) {
        // this.data.invoice_subtotal = this.$_.sumBy(this.items_list, (row) => {
        //   return row.subtotal_before_tax ? parseFloat(row.subtotal_before_tax) : 0;
        // });

        /**
         * sum amount before discount
         */
        this.data.total_amount_before_discount = this.$_.sumBy(this.items_list, (row) => {
          return (row.subtotal_before_discount ? parseFloat(row.subtotal_before_discount) : 0);
        });
        /**
         * sum amount after discount
         */
        this.data.total_amount_after_discount = this.$_.sumBy(this.items_list, (row) => {
          return (row.subtotal_after_discount ? parseFloat(row.subtotal_after_discount) : 0);
        });

        this.data.invoice_subtotal = this.$_.sumBy(this.items_list, (row) => {
          return ((row.unit_price ? row.unit_price : 0) * (row.qty ? row.qty : 0));
        });


        this.data.invoice_total_before_taxes = this.$_.sumBy(this.items_list, (row) => {
          return row.subtotal_before_tax ? parseFloat(row.subtotal_before_tax) : 0;
        });

        this.data.invoice_total_after_taxes = this.$_.sumBy(this.items_list, (row) => {
          return row.subtotal_after_tax ? parseFloat(row.subtotal_after_tax) : 0;
        });

        // this.data.invoice_total = this.$_.sumBy(this.items_list, (row) => {
        //   // return row.subtotal ? parseFloat(row.subtotal) : 0;
        //   return row.subtotal_before_tax ? parseFloat(row.subtotal_before_tax) : 0;
        // });
        //


        let _discount_value = this.$_.sumBy(this.items_list, (row) => {
          if (!row.discount_type || row.discount_type == 1) {
            return 0;
          }
          // let _subtotal = row.subtotal_before_tax ? parseFloat(row.subtotal_before_tax) : 0;
          let _subtotal = ((row.unit_price ? row.unit_price : 0) * (row.qty ? row.qty : 0));
          return ((row.discount_type == 2 && row.discount_value) ? (parseFloat(row.discount_value) / 100) * _subtotal : (row.discount_value ? parseFloat(row.discount_value) : 0));

        });

        this.data.invoice_total_discount = _discount_value;
        this.data.invoice_total_shipping = (this.data.shipping_fees ? parseFloat(this.data.shipping_fees) : 0);

        let _amount_excluded_tax = this.$_.sumBy(this.items_list, (row) => {
          return row.amount_excluded_tax ? parseFloat(row.amount_excluded_tax) : 0;
        });

        this.data.invoice_total_taxes = this.$_.sumBy(this.items_list, (row) => {
          return row.amount_tax ? parseFloat(row.amount_tax) : 0;
        });

        /***
         * summation all amount_tax if the tax include tax (1)
         * @private
         */

        // let _include_taxes = 0;
        // if(this.isEditing){
        //   _include_taxes = this.$_.sumBy(this.items_list, (row) => {
        //     let _arr_include_taxes = row.include_taxes;
        //     let _include_tax = null
        //     if (_arr_include_taxes && _arr_include_taxes[0]){
        //       _include_tax = _arr_include_taxes[0];
        //     }
        //     if (_include_tax && _include_tax.include_tax == 1){
        //       return row.amount_tax ? parseFloat(row.amount_tax) : 0;
        //     }
        //     return 0;
        //   });
        // }else {
        //   _include_taxes = this.$_.sumBy(this.items_list, (row) => {
        //     if(row.tax){
        //       if(row.tax.include_tax == 1){
        //         return row.amount_tax ? parseFloat(row.amount_tax) : 0;
        //       }
        //     }
        //     return 0;
        //   });
        // }


        this.data.total_out_tax = this.$_.sumBy(this.items_list, (row) => {
          if (!row.tax_applied || row.tax_applied.length == 0) {
            if (row.unit_price && row.qty) {
              return parseFloat(row.unit_price) * parseFloat(row.qty);
            }
          }
          return 0;
        });


        this.data.total_subtotal_before_discount_out_tax = this.$_.sumBy(this.items_list, (row) => {
          return row.subtotal_before_discount_out_tax ? parseFloat(row.subtotal_before_discount_out_tax) : 0;
        });
        this.data.total_subtotal_after_discount_out_tax = this.$_.sumBy(this.items_list, (row) => {
          return row.subtotal_after_discount_out_tax  ? parseFloat(row.subtotal_after_discount_out_tax ) : 0;
        });

        this.data.total_subtotal_before_discount_out_tax = this.data.total_subtotal_before_discount_out_tax ? this.data.total_subtotal_before_discount_out_tax.toFixed(2) : 0;
        this.data.total_subtotal_after_discount_out_tax = this.data.total_subtotal_after_discount_out_tax ? this.data.total_subtotal_after_discount_out_tax.toFixed(2) : 0;




        this.setTotalTaxTypeVal();
        this.data.invoice_total_taxes = this.data.invoice_total_taxes ? parseFloat(this.data.invoice_total_taxes) : 0;
        let _shipping_fees = this.data.shipping_fees ? parseFloat(this.data.shipping_fees) : 0;
        // this.data.invoice_total = (this.data.invoice_total + _shipping_fees);
        this.data.invoice_total = (this.data.total_amount_before_discount + _shipping_fees - _discount_value + _amount_excluded_tax - Math.abs(this.data.adjustment_value));

         /**
         * Calculation of additinal discount
         * 
         */
         this.data.total_before_additional_discount = this.data.invoice_total;
        this.calcAdditionalDiscount();
        this.data.invoice_total = this.data.invoice_total - (this.data.additional_discount ? parseFloat(this.data.additional_discount) : 0);

        /*************************************/

        this.data.invoice_subtotal = this.data.invoice_subtotal ? this.data.invoice_subtotal.toFixed(2) : 0;
        this.data.invoice_total_before_taxes = this.data.invoice_subtotal ? this.data.invoice_total_before_taxes.toFixed(2) : 0;
        this.data.invoice_total_after_taxes = this.data.invoice_subtotal ? this.data.invoice_total_after_taxes.toFixed(2) : 0;
        this.data.invoice_total_discount = this.data.invoice_total_discount ? this.data.invoice_total_discount.toFixed(2) : 0;
        this.data.invoice_total_shipping = this.data.invoice_total_shipping ? this.data.invoice_total_shipping.toFixed(2) : 0;
        this.data.invoice_total_taxes = this.data.invoice_total_taxes ? this.data.invoice_total_taxes.toFixed(2) : 0;
        this.data.invoice_total = this.data.invoice_total ? this.data.invoice_total.toFixed(2) : 0;
        this.data.total_out_tax = this.data.total_out_tax ? this.data.total_out_tax.toFixed(2) : 0;

        if (this.idEditing && this.data.invoice_total !== null && this.data.invoice_total !== undefined
            && this.data.is_zero_invoice === false && !isNaN(this.data.invoice_total) && typeof this.data.invoice_total === 'number') {
          // Initialize sum_invoice_total
          this.sum_invoice_total = 0;

          const formattedInvoiceTotal = parseFloat(this.data.invoice_total.toFixed(2));

          // Check if formattedInvoiceTotal is greater than 0
          if (!isNaN(formattedInvoiceTotal) && formattedInvoiceTotal > 0) {
            this.sum_invoice_total = formattedInvoiceTotal;
          } else {
            // Calculate sum_invoice_total using other values if formattedInvoiceTotal is not greater than 0
            this.sum_invoice_total = (this.data.total_amount_before_discount + _shipping_fees - _discount_value + (this.data.invoice_total_taxes ? parseFloat(this.data.invoice_total_taxes) : 0));
          }
        } else {
          this.sum_invoice_total = this.$_.sumBy(this.items_list, (row) => {
            if (this.data.is_zero_invoice == 1) {
              return 0
            }
            return row.subtotal_before_tax ? parseFloat(row.subtotal_before_tax) : 0;
          });
        }

      }
    },
    setTotalTaxTypeVal() {
      this.data.total_tax_type_val = [];
      this.items_list.forEach((row) => {
        if (row.tax_applied && row.tax_applied.length > 0) {
          row.tax_applied.forEach((_row) => {
            let index_tax = this.data.total_tax_type_val.findIndex(it => _row.tax_id == it.tax_id);
            if (index_tax >= 0) {
              this.data.total_tax_type_val[index_tax].tax_value = (this.data.total_tax_type_val[index_tax].tax_value ? parseFloat(this.data.total_tax_type_val[index_tax].tax_value) : 0) + (row.tax_value ? parseFloat(row.tax_value) : 0);
              this.data.total_tax_type_val[index_tax].amount_applied = (this.data.total_tax_type_val[index_tax].amount_applied ? parseFloat(this.data.total_tax_type_val[index_tax].amount_applied) : 0) + (row.subtotal_before_tax ? parseFloat(row.subtotal_before_tax) : 0);
            } else {
              this.data.total_tax_type_val.push({tax_id: _row.tax_id, tax_value: _row.tax_value, amount_applied: row.subtotal_before_tax});
            }
          });

        }
      });
    },
    getCountries(filter) {
      if (filter && filter.length >= 3) {
        ApiService.get(`${this.mainRouteDependency}/countries`, {params: {filter: filter}}).then((response) => {
          this.countries = response.data.data;
        });
      }
    },
    getCities(id) {
      if (id)
        ApiService.get(this.mainRouteDependency + `/cities/${id}`).then((response) => {
          this.cities = response.data.data;
        });
    },
    getStatus() {
      ApiService.get(this.mainRouteDependency + "/status", {params: {type: 'sales_invoices'}}).then((response) => {
        this.status_list = response.data.data;
      });
    },

    getSalesDefaultStatus() {
      ApiService.get(this.mainRouteDependency + "/sales_default_status").then((response) => {
        this.default_status_list = response.data.data;
      });
    },

    async defaultDataForUser() {
      await ApiService.get(this.mainRouteDependency + "/default_data_for_user").then((response) => {
        this.data.currency_id = response.data.data.currency_id;
        // this.data.inventory_id = response.data.data.inventory_id;
        // this.inventory = response.data.data.inventory;
        this.unit_default = response.data.data.unit;
        this.tax_default = response.data.data.tax;
        this.data.branch_id = response.data.data.branch_id;
        this.branch = response.data.data.branch;
      });
    },
    async getTaxesRulesForTaxSetting() {
      await ApiService.get(this.mainRouteDependency + "/taxes_rules_for_tax_setting/1").then((response) => {
        this.taxes_rules_for_tax_setting = response.data.data;
        response.data.data.forEach((row) => {
          if (row.auto_apply) {
            this.data.apply_tax_rules.push({tax_rule_id: row.id});
          }
        })

      });
    },

    /**
     * payment functions
     * @param payment_method
     */
    onPaymentMethodChanged(payment_method) {
      this.payment_method = payment_method;
      this.initial_payment_method = payment_method;
      let _payment_selected = this.payment_methods.find((row) => row.id == payment_method);
      if(_payment_selected){
        this.cash_type = _payment_selected.cash_type
      }
      this.bank = null;
      this.treasury = null;
    },
    getPaymentMethods() {
      ApiService.get(this.mainRouteDependency + "/payments_methods").then((response) => {
        this.payment_methods = response.data.data;
      });
    },
    getTreasuries() {
      ApiService.get(`${this.mainRouteDependency}/treasuries`).then((response) => {
        this.treasuries = response.data.data;
      });

    },
    getBanks(filter) {
      if (filter && filter.length >= 3) {
        ApiService.get(`${this.mainRouteDependency}/banks`, {params: {filter: filter}}).then((response) => {
          this.banks = response.data.data;
        });
      } else {
        this.banks = [];
      }
    },
    getTaxSettings() {
      ApiService.get(this.mainTaxSettingsRoute).then((response) => {
        this.is_allow_tax_calc = response.data.data.is_apply_taxes_setting;
        this.data.is_apply_taxes_invoice = response.data.data.is_apply_taxes_setting;
        // this.data.default_status = response.data.data.default_status ? response.data.data.default_status : 1;
      });
    },
    changeStatus(id) {
      if (id && this.is_confirmed) {
      // if (id && this.data.default_status == 2) {
        Vue.prototype.$postStatus = true;
        this.changeStatusToConfirm(this.routeChangeStatus, id, 2);
      }
    },

    changeDiscountInRepeater(index) {
      this.data.discount_type = 1;
      this.data.discount_value = 0;
      this.setValue(index);
    },
    zeroForNegative() {
      this.items_list = this.items_list.map((row) => {
        // if (isNaN(row.unit_price) || !Number(row.unit_price)) {
        //   row.unit_price = 0;
        // } else if (parseFloat(row.unit_price) < 1) {
        //   row.unit_price = 0;
        // }
        if (String(row.unit_price).length > 12) {
          row.unit_price = String(row.unit_price).slice(0, 12);
        }
        if (String(row.qty).length > 9) {
          row.qty = String(row.qty).slice(0, 9);
        }
        if (String(row.discount_value).length > 9) {
          row.discount_value = String(row.discount_value).slice(0, 9);
        }

        if (isNaN(row.unit_price)) {
          row.unit_price = 0; // Set a default value of 0.1 if it's not a valid number
        } else if (parseFloat(row.unit_price) < 0) {
          row.unit_price = 0; // Set a minimum value of 0.1
        }

        if (isNaN(row.qty)) {
          row.qty = 0;
        } else if (parseFloat(row.qty) < 0) {
          row.qty = 0;
        }

        if (isNaN(row.units_number)) {
          row.units_number = 0;
        } else if (parseFloat(row.units_number) < 0) {
          row.units_number = 0;
        }

        // if (isNaN(row.discount_value) || !Number(row.discount_value)) {
        //   row.discount_value = 0;
        // }
        // if (parseFloat(row.discount_value) < 0) {
        //   row.discount_value = 0;
        // }


        if (isNaN(row.discount_value)) {
          row.discount_value = 0;
        } else if (parseFloat(row.discount_value) < 0) {
          row.discount_value = 0;
        }
        // if (parseFloat(row.discount_value) > parseFloat(row.subtotal_before_discount)) {
        //   row.discount_value = row.subtotal_before_discount;
        // }
        if ((row.discount_type == 1 || !row.discount_type) && (row.discount_value != 0 && row.discount_value != null)) {
          row.discount_type = 2;
        }
        if (row.discount_type == 2) {
          if (parseFloat(row.discount_value) > 100) {
            row.discount_value = 100;
          }
        } else if (row.discount_type == 3 && parseFloat(row.discount_value) > 0 && parseFloat(row.subtotal_before_discount) > 0) {
          if (parseFloat(row.discount_value) > parseFloat(row.subtotal_before_discount)) {
            row.discount_value = row.subtotal_before_discount;
          }
        }

        if (isNaN(row.tax_percentage) || !Number(row.tax_percentage)) {
          row.tax_percentage = null;
        }
        if (parseFloat(row.tax_percentage) < 0) {
          row.tax_percentage = null;
        }
        if (parseFloat(row.tax_percentage) > 100) {
          row.tax_percentage = 100;
        }
        return row;

      });
    },
    changeRepeaterValue(index) {
      this.reCalcGeneralDiscount();
      this.setValue(index);
    },
    //recalculation discount for general
    reCalcGeneralDiscount: _.debounce(function () {
      this.calcItemListForTotal();
      this.is_show_validation_dis_value = false;
      let _dis_type = this.data.discount_type;
      let _all_dis_value = this.data.discount_value ? parseFloat(this.data.discount_value) : 0;
      let _invoice_total = this.data.invoice_total ? parseFloat(this.data.invoice_total) : 0;
      let _total_amount_before_discount = this.data.total_amount_before_discount ? parseFloat(this.data.total_amount_before_discount) : 0;
      let _dis_value = this.data.discount_value ? parseFloat(this.data.discount_value) : 0;
      if (_dis_value <= 0) {
        return false;
      }
      if (_all_dis_value > _invoice_total && _dis_type == 3) {
        this.is_show_validation_dis_value = true;
        return false;
      } else if (_dis_value > 100 && _dis_type == 2) {
        this.is_show_validation_dis_value = true;
        return false;
      }
      this.items_list = this.items_list.map((row, index) => {
        row.discount_type = _dis_type;
        if (_dis_type == 1) {
          row.discount_value = 0;
        } else if (_dis_type == 3) {
          let _subtotal_before_discount = row.subtotal_before_discount ? parseFloat(row.subtotal_before_discount) : 0;
          let _dis = parseFloat((_subtotal_before_discount / _total_amount_before_discount) * parseFloat(_dis_value)).toFixed(2);
          if (_subtotal_before_discount){
            row.discount_value = parseFloat(_dis).toFixed(2);
          }
          // row.discount_value = _dis_value ? parseFloat((parseFloat(_dis_value)).toFixed(2)) : 0;
        } else {
          row.discount_value = _dis_value ? _dis_value : 0;
        }

        this.setValue(index);
        return row;
      });

      this.calcItemListForTotal();
    }, 100),

    /**
     * this is function used to 
     * check on data for additional discount calculation
     * @param e 
     */
     calcAdditionalDiscount(e) {
      this.data.additional_discount_rate = Math.abs(this.data.additional_discount_rate) > 100 ? 100 : Math.abs(this.data.additional_discount_rate);
      this.data.additional_discount = (Math.abs(this.data.additional_discount) > Math.abs(this.data.total_before_additional_discount) )? Math.abs(this.data.total_before_additional_discount) : Math.abs(this.data.additional_discount);

     
      if(this.data.additional_discount_type == 1){
        this.data.additional_discount_rate = 0;
        this.data.additional_discount = 0;
      }else if(this.data.additional_discount_type == 2){
      let _total_before_additional_discount = this.data.total_before_additional_discount ? parseFloat(this.data.total_before_additional_discount) : 0;
      let _additional_discount_per = (this.data.additional_discount_rate ? parseFloat(this.data.additional_discount_rate) : 0) / 100;
      this.data.additional_discount = Math.abs(_total_before_additional_discount * (_additional_discount_per)).toFixed(2);
      }else if(this.data.additional_discount_type == 3){
        this.data.additional_discount_rate = 0;
        this.data.additional_discount = Math.abs(this.data.additional_discount);
      }
      if(e){
        this.calcItemListForTotal();
      }
      
    },

    async getItemTax(index) {
      let unique_key = this.items_list[index].unique_key ? this.items_list[index].unique_key : null;
      if (!unique_key){
        this.items_list[index].unique_key = this.getUniqueKeyValue();
        unique_key = this.items_list[index].unique_key;
      }

      if (this.isApplyTaxesInvoice) {
        let tax_ids = (this.items_list[index].tax_applied && this.items_list[index].tax_applied.length > 0) ? this.items_list[index].tax_applied.map(row => row.id) : [];
        let quantity = this.items_list[index].qty ? this.items_list[index].qty : null;
        let unit_price = this.items_list[index].unit_price ? this.items_list[index].unit_price : null;
        let discount = this.items_list[index].discount_value ? this.items_list[index].discount_value : null;
        let discount_type = this.items_list[index].discount_type ? this.items_list[index].discount_type : null;

        if (unique_key && tax_ids.length > 0 && quantity && unit_price) {
            // if (quantity && unit_price && this.isApplyTaxesInvoice){
            return await ApiService.get(`/v2/items/calculate-subtotal-by-taxes`,
                {
                  params: {
                    tax_ids: tax_ids,
                    quantity: quantity, sale_price: unit_price,
                    discount: discount, discount_type: discount_type, row_index: unique_key
                  }
                }).then((response) => {
              let _inner_index = this.items_list.findIndex(it => (it.unique_key == response.data.data.row_index));

              this.items_list[_inner_index].amount_tax = response.data.data.tax_total ? this.parseToShortFloat(response.data.data.tax_total) : 0;
              this.items_list[_inner_index].tax_applied = response.data.data.applied_taxes ? response.data.data.applied_taxes : [];
              this.items_list[_inner_index].amount_excluded_tax = response.data.data.excluded_taxes_total ? this.parseToShortFloat(response.data.data.excluded_taxes_total) : 0;
              this.items_list[_inner_index].amount_included_tax = response.data.data.included_taxes_total ? this.parseToShortFloat(response.data.data.included_taxes_total) : 0;

              // this.items_list[_inner_index].data_validation = {tax_total: response.data.data.tax_total, sub_total: response.data.data.sub_total};

              let _subtotal_before_tax = this.parseToShortFloat(parseFloat(response.data.data.sub_total) - parseFloat(response.data.data.included_taxes_total))
              this.items_list[_inner_index].data_validation = {tax_total: response.data.data.tax_total, subtotal_before_tax:  _subtotal_before_tax};


              this.calcItemListForTotal();

              return response.data.data.row_index;
            });
          }else {
          return unique_key;
        }

      } else {
        this.items_list[index].amount_tax = 0;
        this.items_list[index].tax_applied = [];
        this.items_list[index].amount_excluded_tax = 0;
        this.items_list[index].amount_included_tax = 0;
        this.items_list[index].data_validation = [];

        this.calcItemListForTotal();

        return unique_key;
      }
    },

    async adjustItemsList() {
      if (this.idEditing) {
        let _taxes = [];
        this.items_list.forEach((row) => {
          _taxes.push({item_id: row.id, taxes: row.tax_applied.map((_row) => _row.id)});
        });
        await ApiService.patch(`/v2/items/general-invoices/${this.idEditing}/recalculate` + (this.general_tax_id ? `?general_tax_id=${this.general_tax_id}` : ''), {taxes: _taxes}).then((response) => {
          this.is_adjusted = true;
          response.data.data.forEach((row, index) => {
            let _inner_index = this.items_list.findIndex(it => (it.id == row.item_id));

            this.items_list[_inner_index].amount_tax = row.tax_total ? this.parseToShortFloat(row.tax_total) : 0;
            this.items_list[_inner_index].tax_applied = row.applied_taxes;
            this.items_list[_inner_index].amount_excluded_tax = row.excluded_taxes_total ? this.parseToShortFloat(row.excluded_taxes_total) : 0;
            this.items_list[_inner_index].amount_included_tax = row.included_taxes_total ? this.parseToShortFloat(row.included_taxes_total) : 0;

            let _subtotal_before_tax = this.parseToShortFloat(parseFloat(row.sub_total) - parseFloat(row.included_taxes_total))
            this.items_list[_inner_index].data_validation = {tax_total: row.tax_total, subtotal_before_tax:  _subtotal_before_tax};

          });
          this.calcItemListForTotal();

        });
      }
    },

  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.general_sales"), route: '/sales/general_sales'}, {title: (this.idEditing ? this.$t('update') : this.$t('create'))}]);


    // this.getTaxesRulesForTaxSetting();
    this.getTaxSettings();
    this.getStatus();
    this.getCurrencies();
    this.getTaxes();
    this.getUnits();

    this.getSalesDefaultStatus();

    // this.getInventories();
    // this.getAccounts();
    // this.getBranches();

    // if (!this.data.account_id) {
    //   this.getDefaultAccount();
    // }

    /**
     * payment function execution
     */
    this.getPaymentMethods();
    this.getTreasuries();

    if (this.idClone) {
      this.getData();
    }
    let promise = this.defaultDataForUser();
    if (this.idEditing) {
      this.getData();
    } else {


      this.getPlanMessage();

      this.getCode();

      this.getDataForPurchaseRequest();

      this.getTimeZone('date_time').then((res)=>{
        this.data.invoice_date = res;
      });
      // this.data.invoice_date = this.$moment(new Date().toISOString()).format('yyyy-MM-DDThh:mm');
      // this.data.issue_date = new Date().toISOString().substr(0, 10);


      Promise.all([promise]).then(() => {
        if (this.items_list.length <= 0) {
          this.addItemRowToList();
        }
      });
    }

    // if (!this.idClone && !this.idEditing) {
    //   this.data.invoice_date = this.$moment(new Date().toISOString()).format('yyyy-MM-DDThh:mm');
    //   // this.data.issue_date = new Date().toISOString().slice(0, 10);
    //
    // }
  },
  created() {
    this.getBranches();
  }
};
</script>
