<template>
    <div>
        <!--begin::Dashboard-->
        <div class="card card-custom">
<!--            <div class="card-header flex-wrap border-0 pt-6 pb-0">-->
<!--                <div class="card-title">-->
<!--                    <h3 class="card-label">-->
<!--                        <span>{{ user_name}}: </span><span>{{ today_date }}</span>-->
<!--                    </h3>-->
<!--                </div>-->
<!--            </div>-->
            <div class="card-body">
                <div class="row">
                    <div class="col-md-6 mb-5" v-if="$can('dashboard.filters')">
                        <div class="form-group ml-0 mr-0">
                            <div class="input-group">
                                <label class="mb-0 mr-2 mt-2">{{$t('customer_search')}}</label>
                                <input type="text" class="form-control" v-model="customer_name" :placeholder="$t('customer_name')" aria-describedby="basic-addon2">

                                <div class="input-group-append" @click="search">
                                      <span class="btn btn-primary input-group-text line-height-0 py-0">
                                          <i class="fa fa-search"></i>
                                      </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mb-5" v-if="$can('dashboard.filters')">
                        <div class="form-group ml-0 mr-0">
                            <div class="input-group">
                                <label class="mb-0 mr-2 mt-2">{{$t('invoice_search')}}</label>
                                <input type="text" class="form-control" v-model="invoice_number" :placeholder="$t('invoice_number')" aria-describedby="basic-addon2">

                                <div class="input-group-append" @click="searchInvoice">
                                      <span class="btn btn-primary input-group-text line-height-0 py-0">
                                          <i class="fa fa-search"></i>
                                      </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 mb-5">
                        <QuickLinks v-if="$can('dashboard.quick_links')"/>
                        <div class="btn-group" role="group" aria-label="First group">
                        </div>
                    </div>

                    <div class="col-md-12 mb-5" v-if="$can('dashboard.report_sales')">
                        <hr>
                        <h3 class="mb-10">{{ $t('sales')}}</h3>
                        <b-tabs content-class="mt-3">
                            <b-tab active>
                                <template #title>
                                    <i class="fa fa-chart-bar mr-2"></i> {{$t('invoices')}}
                                </template>

                                <div class="row">
                                    <div class="col-md-12 mt-10 d-flex">
                                        <h3 style="line-height: 2;">{{$t('invoices')}}</h3>
                                        <router-link to="/daily-brief-report-invoices" class="btn btn-primary mr-3 ml-3" style="height: fit-content;">
                                            {{$t('details')}}
                                        </router-link>
                                    </div>
                                    <div class="col-md-6 mt-10">
                                        <LineChart :data-line-invoice="line_invoice" :currency-name="currency_name"/>
                                    </div>
                                    <div class="col-md-6 mt-10">
                                        <DoughnutChart :data-paid-unpaid="paid_unpaid" :currency-name="currency_name"/>
                                    </div>
                                </div>
                            </b-tab>
                            <b-tab>
                                <template #title>
                                    <i class="fa fa-chart-line mr-2"></i> {{$t('profit_and_loss')}}
                                </template>

                                <div class="row">
                                    <div class="col-md-12 mt-10">
                                        <ProfitAndLoss :data-profit-loss="profit_loss" :currency-name="currency_name"/>
                                    </div>
                                </div>
                            </b-tab>
                            <b-tab>
                                <template #title>
                                    <i class="fa fa-file-alt mr-2"></i> {{$t('recent_invoices')}}
                                </template>

                                <div class="row">
                                    <div class="col-md-12 mt-10">
                                        <RecentInvoices :invoices-paid="invoices_paid" :currency-name="currency_name"/>
                                    </div>
                                </div>
                            </b-tab>
                            <b-tab>
                                <template #title>
                                    <i class="fa fa-exclamation-triangle mr-2"></i> {{$t('overdue_invoices')}}
                                </template>

                                <div class="row">
                                    <div class="col-md-12 mt-10">
                                        <OverdueInvoices :invoices-unpaid="invoices_unpaid" :currency-name="currency_name"/>
                                    </div>
                                </div>
                            </b-tab>
                        </b-tabs>


                    </div>
                </div>
            </div>
        </div>

        <div class="card card-custom mt-5" v-if="$can('dashboard.report_payments_sales')">
            <div class="card-header flex-wrap border-0 pt-6 pb-0">
                <div class="card-title">
                    <h3>
                        {{ $t('payment') }}
                    </h3>
                </div>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-12 mb-5">
                        <b-tabs content-class="mt-3">
                            <b-tab active>
                                <template #title>
                                    <i class="fa fa-chart-bar mr-2"></i> {{$t('payment_statistics')}}
                                </template>

                                <div class="row">
                                    <div class="col-md-12 mt-10">
                                        <LineChartPayment :data-line-payment="line_payment" :currency-name="currency_name"/>
                                    </div>
                                </div>
                            </b-tab>
                            <b-tab>
                                <template #title>
                                    <i class="fa fa-credit-card mr-2"></i> {{$t('recent_payments')}}
                                </template>

                                <div class="row">
                                    <div class="col-md-12 mt-10">
                                        <RecentPayment :recent-payment="recent_payment" :currency-name="currency_name"/>
                                    </div>
                                </div>
                            </b-tab>
                        </b-tabs>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="card card-custom mt-5" v-if="$can('dashboard.report_low_stock_products_last_appointments')">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-12 mb-5">
                        <LowStockProductsLastAppointments :low-stock-item="low_stock_item"
                                                          :low-stock-item-count="lowStockItemCount"
                                                          :latest-appointments="latest_appointments"
                                                          :latest-appointments-count="latestAppointmentsCount"/>
                    </div>
                </div>
            </div>
        </div>
        <div class="card card-custom mt-5" v-if="$can('dashboard.dashboard_activity_log')">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-12 mb-5">
                        <ActivityLog />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--end::Dashboard-->
</template>

<script>

    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";
    import DoughnutChart from './components/Doughnut.vue'
    import LineChart from './components/Line.vue'
    import LineChartPayment from './components/LineChartPayment.vue'
    import ProfitAndLoss from './components/ProfitAndLoss'
    import RecentInvoices from './components/RecentInvoices'
    import OverdueInvoices from './components/OverdueInvoices'
    import QuickLinks from './components/QuickLinks'
    import RecentPayment from './components/RecentPayment'
    import LowStockProductsLastAppointments from './components/LowStockProductsLastAppointments'
    import ActivityLog from './components/ActivityLog'


    export default {
        name: "dashboard",
        components: {DoughnutChart, LineChart, LineChartPayment, ProfitAndLoss, RecentInvoices, OverdueInvoices, QuickLinks, RecentPayment, LowStockProductsLastAppointments, ActivityLog},
        data() {
            return {
                mainRoute: 'dashboard',
                user_name: null,
                today_date: null,
                customer_name: null,
                currency_name: null,
                invoice_number: null,
                paid_unpaid: [],
                line_invoice: [],
                profit_loss: [],
                invoices_paid: [],
                invoices_unpaid: [],
                line_payment: [],
                recent_payment: [],
                low_stock_item: [],
                latest_appointments: [],
                lowStockItemCount: 0,
                latestAppointmentsCount: 0,
            }
        },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.DASHBOARD")}]);
            this.today_date = this.$moment().format('DD-MM-YYYY');
            this.getAuthUser();
            this.getPaidUnpaidInvoice();
            this.getLineChartInvoice();
            this.getProfitLoss();
            this.getLinePayment();
            this.getInvoicesPaidList();
            this.getInvoicesUnpaidList();
            this.getRecentPayment();
            this.getLowStockItem();
            this.getLatestAppointments();
        },
        methods: {
            async getAuthUser() {
                await ApiService.get(`/get_auth`).then((response) => {
                    this.user = response.data.data;
                    this.currency_name = response.data.data.currency_name;
                    if (this.user) {
                        this.user_name = this.user.name;
                    }
                });
            },

            async getPaidUnpaidInvoice() {
                await ApiService.get(`${this.mainRoute}/invoice/paid_unpaid_invoice`).then((response) => {
                    this.paid_unpaid = response.data.data;
                });
            },
            async getLineChartInvoice() {
                await ApiService.get(`${this.mainRoute}/invoice/line_chart_invoice`).then((response) => {
                    this.line_invoice = response.data;
                });
            },
            async getLinePayment() {
                await ApiService.get(`${this.mainRoute}/invoice/line_chart_payment`).then((response) => {
                    this.line_payment = response.data;
                });
            },
            async getInvoicesPaidList() {
                await ApiService.get(`${this.mainRoute}/invoice/recent_invoice`).then((response) => {
                    this.invoices_paid = response.data;
                });
            },
            async getInvoicesUnpaidList() {
                await ApiService.get(`${this.mainRoute}/invoice/over_due_invoice`).then((response) => {
                    this.invoices_unpaid = response.data;
                });
            },
            async getProfitLoss() {
                await ApiService.get(`${this.mainRoute}/invoice/line_profit_loss`).then((response) => {
                    this.profit_loss = response.data;
                });
            },
            async getRecentPayment() {
                await ApiService.get(`${this.mainRoute}/invoice/recent_payment`).then((response) => {
                    this.recent_payment = response.data;
                });
            },
            async getLowStockItem() {
                await ApiService.get(`${this.mainRoute}/invoice/low_stock_item`).then((response) => {
                    this.low_stock_item = response.data;
                    this.lowStockItemCount = response.data.length;
                });
            },
            async getLatestAppointments() {
                await ApiService.get(`${this.mainRoute}/invoice/latest_appointments`).then((response) => {
                    this.latest_appointments = response.data;
                    this.latestAppointmentsCount = response.data.length;
                });
            },
            search() {
                this.$router.push({path: '/customers/customers', query: {customer_name: this.customer_name}});
            },
            searchInvoice() {
                this.$router.push({path: '/sales/sales_invoices', query: {invoice_number: this.invoice_number}});
            }
        }
    };
</script>
