<template>
  <b-input-group class="b-form-spinbutton form-control custom-spinbutton d-flex align-items-stretch" style="flex-wrap: inherit;">
    <b-input-group-prepend>
      <b-button @click="decreaseValue" :disabled="disabled" variant="outline-dark" class="btn btn-sm b-spinbutton border-0 rounded-0 py-0 btn-outline-dark btn-sm" style="border-radius: 50% !important;" size="sm">
        <b-icon icon="dash" font-scale="1.6"/>
      </b-button>
    </b-input-group-prepend>

    <!--    <b-form-input-->
    <!--        :id="id"-->
    <!--        :size="size"-->
    <!--        :value="current_value"-->
    <!--        :disabled="disabled"-->
    <!--        type="number"-->
    <!--        :min="min"-->
    <!--        class="flex-grow-1 align-self-center border-left border-right"-->
    <!--        number-->
    <!--        @update="valueChange"-->
    <!--    />-->
    <input v-model.number="current_value" @input="inputChange" @keypress="onlyNumber" @blur="checkMinValue" :disabled="disabled" :min="min" style="width: 70px;" type="number" class="btn flex-grow-1 align-self-center border-left border-right"/>
    <b-input-group-append>
      <b-button @click="increaseValue" :disabled="disabled" variant="outline-dark" class="btn btn-sm border-0 rounded-0 py-0 btn-outline-dark btn-sm" style="border-radius: 50% !important;" size="sm">
        <b-icon icon="plus" font-scale="1.6"/>
      </b-button>
    </b-input-group-append>
  </b-input-group>
</template>

<script>
import {BIcon, BIconDash, BIconPlus} from 'bootstrap-vue'
import dataTypeNumbers from "@/core/config/mix/dataTypeNumbers";

export default {
  name: 'TheFormSpinbuttonWithInput',

  components: {
    BIcon,

    /* eslint-disable vue/no-unused-components */
    BIconDash,
    BIconPlus
  },

  props: {
    id: {
      type: String,
      required: true
    },

    step: {
      required: false,
      default: 1,
    },

    inValue: {
      default: 0,
      required: true
    },

    disabled: {
      type: Boolean,
      default: false,
      required: false
    },
    indexInList: {
      type: Number,
      required: true
    },
    min: {
      default: null,
      required: false
    },
    max: {
      default: null,
      required: false
    },
    handleData: {
      type: Function,
      required: false
    },
  },
  data() {
    return {
      current_value: 0,
    }
  },
  watch: {
    'inValue': function (val) {
      this.valueChange(val)
    }
  },
  methods: {
    ...dataTypeNumbers,
    valueChange(val) {
      let newValue = this.parseToShortFloatFixed(val);

      if (this.min != null) {
        if (parseFloat(newValue) < parseFloat(this.min)) {
          this.current_value = this.min;
          this.$emit('update:in-value', this.min)
          this.$emit('handle-data', {value: this.parseToShortFloatFixed(this.min), index: this.indexInList})
        } else {
          this.current_value = newValue;
          this.$emit('update:in-value', newValue)
          this.$emit('handle-data', {value: this.parseToShortFloatFixed(newValue), index: this.indexInList})
        }
      } else {
        this.current_value = newValue;
        this.$emit('update:in-value', newValue)
        this.$emit('handle-data', {value: this.parseToShortFloatFixed(newValue), index: this.indexInList})
      }

      if (this.max != null) {
        if (parseFloat(newValue) > parseFloat(this.max)) {
          this.current_value = this.max;
          this.$emit('update:in-value', this.max)
          this.$emit('handle-data', {value: this.parseToShortFloatFixed(this.max), index: this.indexInList})
        } else {
          this.current_value = newValue;
          this.$emit('update:in-value', newValue)
          this.$emit('handle-data', {value: this.parseToShortFloatFixed(newValue), index: this.indexInList})
        }
      } else {
        this.current_value = newValue;
        this.$emit('update:in-value', newValue)
        this.$emit('handle-data', {value: this.parseToShortFloatFixed(newValue), index: this.indexInList})
      }

    },
    increaseValue() {
      let _step = this.step == 0 ? 1 : parseFloat(this.step);
      this.valueChange(parseFloat(this.current_value) + _step);
    },
    decreaseValue() {
      if (parseFloat(this.current_value) > 0){
        let _step = this.step == 0 ? 1 : parseFloat(this.step)
        this.valueChange(parseFloat(this.current_value) - _step);
      }
    },
    inputChange(event) {
      let _val = event.target.value;
      this.valueChange(_val);
    },
    checkMinValue(event) {
      // console.log(event);
      // let _val = event.target.value;
      // this.valueChange(_val);
    },
    onlyNumber($event) {
      //console.log($event.keyCode); //keyCodes value
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
        $event.preventDefault();
      }
    }
  },
  created() {
    this.current_value = this.inValue;
  }
}
</script>

<style scoped>
/* Remove up and down arrows inside number input */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
  height: 100%;
  text-align: center;
  border: 0 !important;
}
</style>