<template>
  <div>
    <!--begin::customer-->
    <div>

      <h3 class="card-label">
        {{ $t('MENU.general_item_configurations') }}
        <span class="text-muted pt-2 font-size-sm d-block"></span>
      </h3>

      <div class="row">
        <div class="col-md-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-6 mb-10">
                <label>{{ $t('general_item_configurations.inventory_validation') }}</label>
                <select name="" id="inventory_validation" v-model="data.inventory_validation" class="custom-select" :class="validation && validation.inventory_validation ? 'is-invalid' : ''">
                  <option v-for="(row, index) in inventory_validation_list" :value="row.id" :key="'a'+index">{{ row.title }}</option>
                </select>
                <span v-if="validation && validation.inventory_validation" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.inventory_validation[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-10">
                <label>{{ $t('general_item_configurations.cost_method') }}</label>
                <select name="" id="cost_method" v-model="data.cost_method" class="custom-select" :class="validation && validation.cost_method ? 'is-invalid' : ''">
                  <option v-for="(row, index) in cost_method_list" :value="row.id" :key="'b'+index">{{ row.title }}</option>
                </select>
                <span v-if="validation && validation.cost_method" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.cost_method[0] }}
                            </span>
              </div>


            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-6 mb-10">
                <label>{{ $t('general_item_configurations.average_start_date') }}</label>
                <input type="date" v-model="data.average_start_date" class="form-control"/>
                <span v-if="validation && validation.average_start_date" class="fv-plugins-message-container invalid-feedback">
                    {{ validation.average_start_date[0] }}
                </span>
              </div>
              <div class="col-lg-6 mb-10">
                <label>{{ $t('general_item_configurations.average_end_date') }}</label>
                <input type="date" v-model="data.average_end_date" class="form-control"/>
                <span v-if="validation && validation.average_end_date" class="fv-plugins-message-container invalid-feedback">
                    {{ validation.average_end_date[0] }}
                </span>
              </div>

            </div>
          </div>
        </div>
      </div>


      <div class="pr-0 pl-0">
        <div class="row">
          <div class="col-lg-6 mt-5">
            <button type="reset" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
          </div>
        </div>
      </div>

    </div>
    <!--end::customer-->
  </div>
</template>
<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import Vue from "vue";
import {VueEditor} from "vue2-editor";
import {mapGetters} from "vuex";

export default {
  name: "form-general-item-configurations",
  components: {
    VueEditor
  },
  data() {
    return {
      mainRoute: 'items/general_items_configuration',
      mainRouteDependency: 'base/dependency',

      data: {
        inventory_validation: null,
        cost_method: null,
        average_start_date: null,
        average_end_date: null,
      },
      inventory_validation_list: [],
      cost_method_list: [],
      validation: null,
    };
  },
  computed: {
      ...mapGetters(["currentUserPersonalInfo"]),
      isSystemUser: function () {
        return this.currentUserPersonalInfo.is_system_user
      },
  },
  methods: {
    save() {
      // if (this.isEditing) {
      //     this.update();
      // } else {
      //     this.create();
      // }
      this.create();
    },

    create() {
      ApiService.post(`${this.mainRoute}`, {
        ...this.data
      }).then((response) => {
        this.validation = null;
        Vue.prototype.$postStatus = true;
        this.$successAlert(response.data.message);
      })
          .catch((error) => {
            this.validation = error.response ? error.response.data.errors : null;
            Vue.prototype.$postStatus = true;
            this.$errorAlert(error);
          });
    },

    async getData() {
      ApiService.get(`${this.mainRoute}`).then((response) => {
        this.data.inventory_validation = response.data.data.inventory_validation;
        this.data.cost_method = response.data.data.cost_method;
        this.data.average_start_date = response.data.data.average_start_date;
        this.data.average_end_date = response.data.data.average_end_date;
      });
    },
    getInventoryValidationList() {
      ApiService.get(this.mainRouteDependency + "/inventory_validations").then((response) => {
        this.inventory_validation_list = response.data.data;
      });
    },
    getCostMethodList() {
      ApiService.get(this.mainRouteDependency + "/cost_methods").then((response) => {
        this.cost_method_list = response.data.data;
      });
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.general_item_configurations")}]);
    this.getInventoryValidationList();
    this.getCostMethodList();

    // if (this.idEditing) {
    this.getData();
    // }

  },
};
</script>
