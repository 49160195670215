<template>
  <div class="w-100">

    <h2 class="mb-5">{{$t('items.stores')}}</h2>
    <div class="mb-5">
      <b-form-checkbox size="lg" v-model="is_used_all" @change="selectAllStores" :name="'is_used_1'">{{$t('items.item_is_available_for_sale_in_all_stores')}}</b-form-checkbox>
    </div>
    <div class="table-responsive">
      <table class="table">
        <thead>
        <tr>
          <th></th>
          <th>{{ $t('pos_devices.store') }}</th>
          <th>{{ $t('items.sale_price') }}</th>
          <th>{{ $t('items.min_sale_price') }}</th>
          <th>{{ $t('items.wholesale_price') }}</th>
          <th>{{ $t('items.half_wholesale_price') }}</th>
          <th>{{ $t('items.branch_price') }}</th>
          <th>{{ $t('items.is_committed') }}</th>
          <th>{{ $t('status') }}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(_store, _index) in stores_list" :key="'_store'+_index">
          <td>
            <b-form-checkbox size="lg" v-model="_store.is_used" @change="checkIsSelectedAll" :name="'is_used'+_index"></b-form-checkbox>
          </td>
          <td>{{ getStoreName(_store.store_id) }}</td>
          <td>
            <input v-model="_store.sale_price" @input="rowEditing(_index)" :class="validation && validation[_store.store_id+'.sale_price'] ? 'is-invalid' : ''"
                   :placeholder="$t('items.sale_price')" class="form-control"
                   min="0" type="number"/>
            <span v-if="validation && validation[_store.store_id+'.sale_price']" class="fv-plugins-message-container invalid-feedback">
              {{ validation[_store.store_id+'.sale_price'][0] }}
            </span>
          </td>
          <td>
            <input v-model="_store.min_sale_price" @input="rowEditing(_index)" :class="validation && validation[_store.store_id+'.min_sale_price'] ? 'is-invalid' : ''"
                   :placeholder="$t('items.min_sale_price')" class="form-control"
                   min="0" type="number"/>
            <span v-if="validation && validation[_store.store_id+'.min_sale_price']" class="fv-plugins-message-container invalid-feedback">
        {{ validation[_store.store_id+'.min_sale_price'][0] }}
      </span>
          </td>
          <td>
            <input v-model="_store.wholesale_price" @input="rowEditing(_index)"
                   :class="validation && validation[_store.store_id+'.wholesale_price'] ? 'is-invalid' : ''"
                   :placeholder="$t('items.wholesale_price')" class="form-control"
                   min="0" type="number"/>
            <span v-if="validation && validation[_store.store_id+'.wholesale_price']" class="fv-plugins-message-container invalid-feedback">
        {{ validation[_store.store_id+'.wholesale_price'][0] }}
      </span>
          </td>
          <td>
            <input v-model="_store.half_wholesale_price" @input="rowEditing(_index)"
                   :class="validation && validation[_store.store_id+'.half_wholesale_price'] ? 'is-invalid' : ''"
                   :placeholder="$t('items.half_wholesale_price')" class="form-control"
                   min="0" type="number"/>
            <span v-if="validation && validation[_store.store_id+'.half_wholesale_price']"
                  class="fv-plugins-message-container invalid-feedback">
        {{ validation[_store.store_id+'.half_wholesale_price'][0] }}
      </span>
          </td>
          <td>
            <input v-model="_store.branch_price" @input="rowEditing(_index)" :class="validation && validation[_store.store_id+'.branch_price'] ? 'is-invalid' : ''"
                   :placeholder="$t('items.branch_price')" class="form-control"
                   min="0" type="number"/>
            <span v-if="validation && validation[_store.store_id+'.branch_price']" class="fv-plugins-message-container invalid-feedback">
        {{  validation[_store.store_id+'.branch_price'][0] }}
      </span>
          </td>
          <td>
            <b-form-checkbox size="lg" v-model="_store.is_committed" @input="rowEditing(_index)" :name="'is-committed-data'+_index"
                             switch></b-form-checkbox>
          </td>
          <td>
            <b-form-checkbox size="lg" v-model="_store.is_active" @input="rowEditing(_index)" :name="'is_active'+_index" switch></b-form-checkbox>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <div class="col-lg-12 mb-8 text-center" v-if="$can('item_supported_stores.create')">
      <button type="button" class="btn btn-primary m-2" @click="save($event)">{{ $t('save') }}</button>
    </div>

  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import {mapGetters, mapState} from "vuex";
import Vue from "vue";

export default {
  name: "supported-stores",
  props: ['itemId', 'isActive', 'isCommitted' , 'pricesList'],
  data() {
    return {
      mainRoute: 'items/item_supported_stores',
      subMainRoute: 'items/item_supported_store',
      mainRouteDependency: 'base/dependency',
      stores_list: [],
      stores: [],
      store: null,
      validation: null,
      is_used_all: true,
    };
  },
  computed: {
    ...mapState({user_personal_info: state => state.profile.user_personal_info}),
    ...mapGetters(["currentUserPersonalInfo"]),

  },

  watch: {
    isActive: function (val) {
      this.data.is_active = val;
    },
    isCommitted: function (val) {
      this.data.is_committed = val;
    },
  },
  methods: {
    rowEditing(index){
      this.stores_list[index].is_editing = true;
    },
    selectAllStores(){
      this.stores_list = this.stores_list.map((row)=>{
        row.is_used = this.is_used_all;
        return row;
      })
    },
    checkIsSelectedAll(){
      let _stores_list = this.stores_list.filter((row) => row.is_used == false);
      if (_stores_list.length > 0)
        this.is_used_all = false;
      else
        this.is_used_all = true;
    },
    getStoreName(id){
      let _store = this.stores.find((_row)=>_row.id == id);
      if (_store){
        return _store.name;
      }
      return '';
    },
    save(event) {
      // if (this.isEditing) {
      //   this.update();
      // } else {
      this.create(event);
      // }
    },

    create(event) {
    
    let that  = this;
    this.validation = {};
      let _data = this.stores_list.filter((row) => row.is_used).map((row)=>{
        if(!row.sale_price){
          that.validation[`${row.store_id}.sale_price`] =  [this.$t('sale_price_field_is_require')];
        }
        return {
          id: row.id,
          item_id: row.item_id,
          store_id: row.store_id,
          sale_price: row.sale_price,
          min_sale_price: row.min_sale_price,
          wholesale_price: row.wholesale_price,
          half_wholesale_price: row.half_wholesale_price,
          branch_price: row.branch_price,
          is_committed: row.is_committed,
          is_active: row.is_active,
          is_editing: row.is_editing,
        }
      })
      
      if(Object.keys(this.validation).length > 0){
        event.preventDefault();
        return false;
      }
      ApiService.post(`${this.mainRoute}`, {
        ..._data,
      }).then((response) => {
        this.validation = null;
        Vue.prototype.$postStatus = true;
        this.$successAlert(response.data.message);
      })
          .catch((error) => {
            Vue.prototype.$postStatus = true;
            this.$errorAlert(error);
            this.validation = error.response ? error.response.data.errors : null;
          });
    },

    async getData() {
      await ApiService.get(this.mainRoute,{params: {item_id: this.itemId}}).then((response) => {
        this.data_list = response.data.data;
      });
    },
    getStores() {
      ApiService.get(this.mainRouteDependency + "/pos-stores-list").then((response) => {
        this.stores = response.data.data;
        response.data.data.forEach((row, index) => {
          let _store = this.data_list.find((_row)=>_row.store_id == row.id);
          if (_store){
            this.stores_list.push({
              id: _store.id,
              item_id: _store.item_id,
              store_id: _store.store_id,
              sale_price: _store.sale_price,
              min_sale_price: _store.min_sale_price,
              wholesale_price: _store.wholesale_price,
              half_wholesale_price: _store.half_wholesale_price,
              branch_price: _store.branch_price,
              is_committed: _store.is_committed,
              is_active: _store.is_active,
              is_editing: false,
              is_used: true,
            });
          }else{
            this.stores_list.push({
              id: null,
              item_id: this.itemId,
              store_id: row.id,
              sale_price: this.pricesList.sale_price,
              min_sale_price: this.pricesList.min_sale_price,
              wholesale_price: this.pricesList.wholesale_price,
              half_wholesale_price: this.pricesList.half_wholesale_price,
              branch_price: this.pricesList.branch_price,
              is_committed: this.isCommitted,
              is_active: this.isActive,
              is_editing: false,
              is_used: (this.data_list.length == 0),
            });
          }

        })
        this.is_used_all = ((this.data_list.length ==  this.stores_list.length) || (this.data_list.length == 0));
      });
    },
  },

  mounted() {
    let _promise = this.getData();
    Promise.all([_promise]).then(()=>{
      this.getStores();
    })

  },
}
</script>

<style scoped>

</style>