<template>
  <div>

    <div class="card-toolbar mb-5">
      <button @click="openAdvancedSearch" class="btn btn-outline-primary font-weight-bolder ml-5">
        <span><i class="la la-filter"></i> <span>{{ $t('advanced_filter') }}</span></span>
      </button>
    </div>

    <div class="card card-custom mb-5" v-if="showAdvancedSearch">
      <div class="card-body">
        <div class="m-form m-form--fit m--margin-bottom-20">
          <div class="row mb-5">

            <div class="col-lg-6 mb-5">
              <label>{{ $t('bank_checking_history.bank') }}</label>
              <multiselect v-model="bank"
                           :placeholder="$t('bank_checking.bank')"
                           label="name"
                           track-by="id"
                           :options="banks"
                           :multiple="false"
                           :taggable="false"
                           :show-labels="false"
                           :show-no-options="false"
                           :show-no-results="false">
              </multiselect>
            </div>

            <div class="form-group col-md-6 mb-5">
              <label for="from_date">{{ $t('bank_checking_history.from_date') }}</label>
              <input v-model="filters.from_date" type="date" id="from_date" class="form-control">
            </div>
            <div class="form-group col-md-6 mb-5">
              <label for="to_date">{{ $t('bank_checking_history.to_date') }}</label>
              <input v-model="filters.to_date" type="date" id="to_date" class="form-control">
            </div>

<!--            <div class="form-group col-md-6 mb-5">-->
<!--              <label>{{ $t('bank_checking_history.validator_user') }}</label>-->
<!--              <multiselect v-model="user"-->
<!--                           :placeholder="$t('bank_checking_history.validator_user')"-->
<!--                           label="name"-->
<!--                           track-by="id"-->
<!--                           :options="users"-->
<!--                           :multiple="false"-->
<!--                           :taggable="false"-->
<!--                           :show-labels="false"-->
<!--                           :show-no-options="false"-->
<!--                           :show-no-results="false"-->
<!--                           @search-change="getUsers($event)">-->
<!--              </multiselect>-->
<!--            </div>-->

            <div class="form-group d-inline-flex col-md-6 mt-6 mb-0">
              <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                            <span><i class="fas fa-search"></i>
                                <span>{{ $t('search') }}</span>
                            </span>
              </button>
              <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="m_reset">
                            <span><i class="fas fa-trash-restore"></i>
                                <span>{{ $t('reset_search') }}</span>
                            </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--begin::customer-->
    <div class="card card-custom">
      <div class="card-body">
        <div class="col-12 mb-5 p-0 d-flex justify-content-end">
          <b-dropdown right v-if="$can('bank_checking.export_data')" :text="$t('export')" class="mr-2 ml-2">
            <button class="dropdown-item" @click="getDataForExport('sectionForExport', 'excel')">
              <i class="la la-file-excel"></i>{{ $t('excel') }}
            </button>
            <!--            <button class="dropdown-item" @click="getDataForExport('sectionForExport', 'PDF')">-->
            <!--              <span><i class="fa fa-print"></i> <span>{{ $t('print') }}</span></span>-->
            <!--            </button>-->
            <button class="dropdown-item" @click="getDataForExport('sectionForExport', 'print')">
              <span><i class="fa fa-print"></i> <span>{{ $t('print') }}</span></span>
            </button>
            <button class="dropdown-item" @click="getDataForExport('sectionForExport', 'pdf')">
              <span><i class="fa fa-file-pdf"></i> <span>{{ $t('export_pdf') }}</span></span>
            </button>
          </b-dropdown>
        </div>
        <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">
        </v-server-table>

      </div>
    </div>
    <!--end::customer-->
    <div class="export-container">
      <div class="section-for-export" id="sectionForExport">
        <table class="table">
          <thead>
          <tr>
            <th v-for="(col, index) in header" :key="index">
              {{ head_col[col] }}
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(row, index) in dataList" :key="index">
            <td v-for="(col, index2) in columns" :key="index2">
              {{ row[col] }}
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import TableToExcel from "@linways/table-to-excel";

export default {
  name: "index-bank-checking-history",
  data() {
    return {
      mainRoute: 'finances/bank-check/history',
      mainRouteDependency: 'base/dependency',

      showAdvancedSearch: false,

      filter: {
        sortBy: 'id',
      },
      filters: {
        bank_id: null,
        from_date: null,
        to_date: null,
        user_id: null,
      },
      // status_list: [],
      columns: ['bank_name', 'current_amount_balance', 'balance', 'currency_name', 'confirmed_date'],
      header: ['bank_name', 'current_amount_balance', 'balance', 'currency_name', 'confirmed_date'],
      head_col: {
        bank_name: this.$t('bank_checking_history.bank'),
        current_amount_balance: this.$t('bank_checking_history.current_amount'),
        balance : this.$t('bank_checking_history.bank_checking_amount'),
        currency_name: this.$t('bank_checking_history.currency_name'),
        confirmed_date: this.$t('bank_checking_history.confirmed_date'),
      },
      data: [],
      dataList: [],
      bank: null,
      user: null,
      banks: [],

      users: [],

    }
  },
  watch: {
    bank: function (val) {
      if (val) {
        this.filters.bank_id = val.id;
      } else {
        this.filters.bank_id = null;
      }
    },
    user: function (val) {
      if (val) {
        this.filters.user_id = val.id;
      } else {
        this.filters.user_id = null;
      }
    },
  },
  computed: {
    options: function () {
      let that = this;
      return {
        texts: {
          loadingError: that.$t('Something_went_wrong'),
          filter: "",
          filterBy: that.$t('Filter') + ' {column}',
          count: ' ',
          filterPlaceholder: that.$t('Search_query'),
          loading: that.$t('Loading') + "...",
        },
        headings: {
          bank_name: that.$t('bank_checking_history.bank'),
          current_amount_balance: that.$t('bank_checking_history.current_amount'),
          balance : that.$t('bank_checking_history.bank_checking_amount'),
          currency_name: that.$t('bank_checking_history.currency_name'),
          confirmed_date: that.$t('bank_checking_history.confirmed_date'),
        },
        sortable: ['name'],
        filterByColumn: false,
        filterable: false,
        customFilters: [{
          name: 'alphabet',

        }],
        orderBy: {'column': 'id'},

        alwaysShowPerPageSelect: false,
        perPage: 10,
        pagination: {chunk: 5, dropdown: false},
        skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
        sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
        perPageValues: [5, 10, 15, 25],

        requestFunction(data) {
          let _params = {
            ascending: data.ascending,
            byColumn: data.byColumn,
            limit: data.perPage,
            orderBy: data.orderBy,
            page: data.page,
            filter: data.query,
            ...that.filters,
          }
          return ApiService.query(`${that.mainRoute}`, {..._params});

        },
        responseAdapter(resp) {
          return {
            data: resp.data.data ? resp.data.data.data : [],
            count: resp.data.data ? resp.data.data.total : 0,
          }
        }
      }

    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.bank_checking"), route: '/finances/bank-checking'}, {title: this.$t("MENU.bank_checking_history")}]);
    this.getBanks();
  },
  methods: {
    openAdvancedSearch() {
      this.showAdvancedSearch = !this.showAdvancedSearch;
    },
    doFilter() {
      this.$refs.table.refresh();
    },
    resetFilter() {
      this.filters.bank_id = null;
      this.filters.from_date = null;
      this.filters.to_date = null;
      this.filters.user_id = null;
      this.user = null;
      this.bank = null;

      this.$refs.table.refresh();
    },
    getFetch() {
      this.$refs.table.refresh();
    },

    getBanks() {
      ApiService.get(this.mainRouteDependency + "/banks").then((response) => {
        this.banks = response.data.data;
        if (this.filters.bank_id) {
          this.bank = response.data.data.find((row) => row.id == this.filters.bank_id);
        }
      });
    },
    getUsers(filter) {
      if (filter && filter.length >= 3)
        ApiService.get(this.mainRouteDependency + "/users", {params: {filter: filter}}).then((response) => {
          this.users = response.data.data;
        });
    },
    /**
     * actions export
     * @param tableId
     * @param _export
     */
    getDataForExport(tableId, _export) {
      if (_export != 'excel') {
        let route = this.$router.resolve({path: '/print/print-bank-checking-history/' + _export, query: this.filters});
        window.open(route.href, '_blank');
      } else {
        ApiService.get(this.mainRoute, {params: {...this.filters, export: 1}}).then((response) => {
          this.dataList = response.data.data;
          // if (_export == 'PDF')
          //   this.printData(tableId);
          // if (_export == 'excel')
          this.convertTableToExcelReport(tableId);
        });
      }
    },

    printData(tableId) {
      let newWin = window.open("");
      newWin.document.write("<link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css'>");

      setTimeout(() => {
        let divToPrint = document.getElementById(tableId);
        newWin.document.writeln(divToPrint.outerHTML);

        newWin.print();
        newWin.close();
      }, 100)
    },

    convertTableToExcelReport(tableId) {
      setTimeout(() => {
        TableToExcel.convert(document.getElementById(tableId), {
          name: this.$t('MENU.bank_checking_history') + '.xlsx',
          sheet: {
            name: 'Sheet 1'
          }
        });
      }, 100)
    },
  },
};
</script>
<style scoped>
.export-container {
  overflow: scroll;
  width: 1px;
  height: 1px;
}
</style>