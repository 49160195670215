<template>
  <div>
    <b-card no-body class="mb-5">
      <b-card-header header-tab="header" class="p-1" role="tab">
        <b-button block v-b-toggle.accordion-2 variant="link"
                  class="text-left font-weight-bold text-dark pl-8 pr-8 fs-102 collapsed-with-icon">
          {{ $t('advanced_filter') }}
        </b-button>
      </b-card-header>

      <b-collapse id="accordion-2" visible accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <div class="m-form m-form--fit m--margin-bottom-20">
            <div class="row mb-5">

              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="level">{{ $t('chart_accounts_directory.account_level') }}</label>
                <select name="" id="level" v-model="filters.natural_account" type="text" class="custom-select">
                  <option v-for="(row, index) in level_list" :value="row.id" :key="'level'+index">{{
                      row.title
                    }}
                  </option>
                </select>
              </div>
              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="normal">{{ $t('chart_accounts_directory.account_normal') }}</label>
                <select name="" id="normal" v-model="filters.type_account" class="custom-select">
                  <option v-for="row in normal_list" :value="row.id" :key="row.id">
                    {{ row.title }}
                  </option>
                </select>
              </div>

              <div class="form-group col-md-6 mt-2 mb-2">
<!--                <b-checkbox v-model="filters.except_customers_and_suppliers" :checked="1" :unchecked-value="0">{{ $t('chart_accounts_directory.account_expects') }}</b-checkbox>-->
                <label for="account_expects">{{ $t('chart_accounts_directory.account_expects') }}</label>
<!--                <select name="" id="except_customers_and_suppliers" v-model="filters.except_customers_and_suppliers" class="custom-select">-->
<!--                  <option v-for="row in expects_list" :value="row.id" :key="row.id">-->
<!--                    {{ row.title }}-->
<!--                  </option>-->
<!--                </select>-->

                <multiselect v-model="expects_data"
                             :placeholder="$t('chart_accounts_directory.account_expects')"
                             label="title"
                             track-by="id"
                             :options="expects_list"
                             :multiple="true"
                             :taggable="false"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false">
                </multiselect>
              </div>

              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="order">{{ $t('chart_accounts_directory.account_order') }}</label>
                <select name="" id="order" v-model="filters.order_by" class="custom-select">
                  <option v-for="row in order_list" :value="row.id" :key="row.id">
                    {{ row.title }}
                  </option>
                </select>
              </div>


              <div class="form-group d-inline-flex col-md-6 mt-10">
                <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                  {{ $t('display_report') }}
                </button>
                <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="resetFilter">
                  <i class="fas fa-trash-restore"></i> {{ $t('reset_search') }}
                </button>
              </div>
            </div>
          </div>
        </b-card-body>
      </b-collapse>
    </b-card>

    <!-- End Filter -->
    <div class="card card-custom">
      <div class="card-body">
        <div class="mt-3">
          <div class="row mb-10">
            <div class="col-md-12 mt-10" id="summaryTableDetailsTitle">
              <h4 class="text-center">{{ $t('chart_accounts_directory.chart_accounts_directory') }}</h4>
              <h5 class="text-center" v-if="filters.from_date && filters.to_date">{{ $t('from') }}
                {{ filters.from_date }} {{ $t('to') }} {{ filters.to_date }}</h5>
            </div>

          </div>
          <div class="row mb-10">
            <div class="col-12" v-if="data_report_details">
              <div class="table-responsive">
                <div v-if="$can('report_chart_accounts.list')" class="row justify-content-end p-4">
                  <b-dropdown right :text="$t('export')" class="mr-2 ml-2">
                    <button class="dropdown-item" @click="convertTableToExcelReport('summaryTableDetails')">
                      <i class="la la-file-excel"></i>{{ $t('excel') }}
                    </button>

                    <!--                                                <button class="dropdown-item" @click="printData('summaryTableDetails')">-->
                    <!--                                                    <span><i class="fa fa-print"></i> <span>{{$t('print')}}</span></span>-->
                    <!--                                                </button>-->
                  </b-dropdown>
                </div>
                <table class="table table-bordered" id="summaryTableDetails">
                  <thead>
                  <tr>
                    <th>{{ $t('chart_accounts_directory.account_code') }}</th>
                    <th>{{ $t('chart_accounts_directory.account_name') }}</th>
                    <th>{{ $t('chart_accounts_directory.account_type') }}</th>
                    <th>{{ $t('chart_accounts_directory.account_level') }}</th>
                    <th>{{ $t('chart_accounts_directory.cost_center') }}</th>

                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(row, index) in data_report_details" :key="index">
                    <td>{{ row.code }}</td>
                    <td>{{ row.name }}</td>
                    <td>{{ row.type_account }}</td>
                    <td>{{ row.account_level }}</td>
                    <td>{{ row.cost_center }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>

            </div>
          </div>

          <div class="text-center">
            <button class="btn btn-warning" @click="loadMore">
              <p class="mb-0">
                <b-icon icon="arrow-counterclockwise" font-scale="1"></b-icon>
                {{ $t('load_more') }}
              </p>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!--end::supplier-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import TableToExcel from "@linways/table-to-excel";

export default {
  name: "index-chart-accounts-directory",
  data() {
    return {
      mainRoute: '/v2/reports/accounting/chart-accounts',
      mainRouteDependency: 'base/dependency',


      data_report_details: [],

      level_list: [
        {id: 1, title: this.$t('chart_accounts_directory.consolidated')},
        {id: 2, title: this.$t('chart_accounts_directory.simplified')},
      ],
      order_list: [
        {id: 'asc', title: this.$t('chart_accounts_directory.asc')},
        {id: 'desc', title: this.$t('chart_accounts_directory.desc')},
      ],

      normal_list: [
        // {id: 1, title:this.$t('chart_accounts_directory.debit')},
        // {id: 2, title:this.$t('chart_accounts_directory.credit')},
      ],

      expects_list: [
        // {id: 1, title:this.$t('chart_accounts_directory.customer')},
        // {id: 2, title:this.$t('chart_accounts_directory.supplier')},
        // {id: 3, title:this.$t('chart_accounts_directory.treasury')},
        // {id: 4, title:this.$t('chart_accounts_directory.inventory')},
      ],
      expects_data: [],



      filters: {
        // - natural_account 1 => Credit / 2 => Debit
        natural_account: null,
        // - type_account 1 => Consolidated / 2 =. Simplified
        type_account: null,
        // - except_customers_and_suppliers:  []
        except_customers_and_suppliers: null,
        expects: [],
        order_by: null,
      },
      page: 1,
    }
  },
  watch: {
    expects_data: function (val) {
      this.filters.expects = [];
      if (val && val.length) {
        val.map((row) => {
          this.filters.expects.push(row.id);
        });
      }
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{
      title: this.$t('MENU.accounting_reports'),
      route: '/reports/accounting-reports'
    }, {title: this.$t("MENU.chart_accounts_directory")}]);
    this.getNaturalAccount();
    this.getCustomType();
  },
  methods: {
    loadMore() {
      this.page = this.page + 1;

      this.getReportDetails();
    },
    getRandom() {
      return Math.floor(Math.random() * 10000);
    },
    doFilter() {
      this.getReportDetails();

    },
    resetFilter() {
      this.filters.natural_account = null;
      this.filters.type_account = null;
      this.filters.except_customers_and_suppliers = null;
      this.filters.expects = [];
      this.filters.order_by = null;
      this.expects_data = [];

      this.page = 1;
      this.doFilter();
    },

    updateValue(value) {
      this.filters.account_id = value
    },

    loadOptions() {
    },
    /*****
     * details
     */

    getReportDetails() {
      let _limit = (this.page ? this.page : 1) * 25;
      ApiService.get(this.mainRoute, {params: {...this.filters, limit: _limit}}).then((response) => {
        this.data_report_details = response.data.data;
      });
    },
    convertTableToExcelReport(tableId) {
      this.setReportLog('excel', 'chart accounts directory');
      TableToExcel.convert(document.getElementById(tableId), {
        name: this.$t("MENU.chart_accounts_directory") + '.xlsx',
        sheet: {
          name: 'Sheet 1'
        }
      });
    },
    printData(tableId) {
      this.setReportLog('pdf', 'chart accounts directory');
      let divToPrint = document.getElementById(tableId);
      let divTitleToPrint = document.getElementById(tableId + 'Title');
      let newWin = window.open("");
      newWin.document.write("<link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css'>" + divTitleToPrint.outerHTML + divToPrint.outerHTML);
      setTimeout(() => {
        newWin.print();
        newWin.close();
      }, 100)
    },
    getNaturalAccount() {
      ApiService.get(this.mainRouteDependency + "/natural_account").then((response) => {
        this.normal_list = response.data.data;
      });
    },
    getCustomType() {
      ApiService.get(this.mainRouteDependency + "/custom_type").then((response) => {
        this.expects_list = response.data.data;
      });
    },
    /**
     * register log
     */
    setReportLog(key, description) {
      ApiService.post("reports/system_activity_log/log_type", {
        key: key,
        description: description,
      });
    }
  },
};
</script>
