<template>
  <div>
    <!--begin::customer-->
    <div class="form-group row">

      <div class="col-lg-6 mb-5">
        <label for="inventory">{{ $t('reset_or_recalculate_quantity.inventory') }}</label>

        <multiselect id="inventory"
                     v-model="inventory"
                     :multiple="false"
                     :options="inventories"
                     :placeholder="$t('inventories.inventories')"
                     :show-labels="false"
                     :show-no-options="false"
                     :show-no-results="false"
                     :taggable="true"
                     label="name"
                     track-by="id"
                     @input="getIds('inventory')">
        </multiselect>
        <span v-if="validation && validation.inventory_id" class="fv-plugins-message-container invalid-feedback">
                        {{ validation.inventory_id[0] }}
                    </span>
      </div>

      <div class="col-lg-6 mb-5">
        <label for="product_id">{{ $t('reset_or_recalculate_quantity.items') }}</label>
        <div class="d-flex search-item-group">
          <select id="" v-model="f_by" class="custom-select" name="" style="width: 100px;">
            <option selected value="name">{{ $t('items.name') }}</option>
            <option value="sku_code">{{ $t('items.sku_code') }}</option>
          </select>
          <multiselect id="product_id"
                       v-model="products"
                       :internal-search="false"
                       :label="f_by?f_by:'sku_code'"
                       :multiple="true"
                       :options="items"
                       :placeholder="$t('purchases_invoices.item')"
                       :show-labels="false"
                       :show-no-options="false"
                       :show-no-results="false"
                       :taggable="false"
                       track-by="id"
                       @input="getIds('items')"
                       @search-change="getItems(f_by, $event)">
          </multiselect>
        </div>
        <span v-if="validation && validation.items" class="fv-plugins-message-container invalid-feedback">
                        {{ validation.items[0] }}
                    </span>
      </div>
      <div class="col-lg-6 mb-5">
        <label>{{ $t('reset_or_recalculate_quantity.quantity_type') }}</label>
          <select name="" id="quantity_type" v-model="data.quantity_type" class="custom-select">
              <option v-for="row in quantity_type_list" :value="row.id" :key="row.id">
                  {{ row.title }}
              </option>
          </select>

        <span v-if="validation && validation.quantity_type" class="fv-plugins-message-container invalid-feedback">
                        {{ validation.quantity_type[0] }}
                    </span>
      </div>
      <div class="col-lg-6 mb-5">
        <label for="reset_type">{{ $t('reset_or_recalculate_quantity.reset_type') }}</label>
<!--          <select name="" id="reset_type" v-model="data.reset_type" class="custom-select">-->
<!--              <option v-for="row in reset_type_list" :value="row.id" :key="row.id">-->
<!--                  {{ row.title }}-->
<!--              </option>-->
<!--          </select>-->
        <b-form-group label="" v-slot="{ ariaDescribedby }" class="mt-2">
          <b-form-radio-group
              id="reset_type"
              v-model="data.reset_type"
              :options="reset_type_list"
              value-field="id"
              text-field="title"
              :aria-describedby="ariaDescribedby"
              name="reset_type"
          ></b-form-radio-group>
        </b-form-group>
        <span v-if="validation && validation.reset_type" class="fv-plugins-message-container invalid-feedback">
                        {{ validation.reset_type[0] }}
                    </span>
      </div>
    </div>

    <div class="card-footer pl-0 pr-0 pb-0">
      <div class="row">
        <div class="col-lg-6">
          <button type="reset" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
          <button type="reset" class="btn btn-secondary" @click="cancel">{{ $t('cancel') }}</button>
        </div>
      </div>
    </div>

    <!--end::customer-->
  </div>
</template>
<script>
import ApiService from "@/core/services/api.service";
import Vue from "vue";

export default {
  name: "form-reset-or-recalculate-quantity",
  props: {
    hideModal: {type: Function},
    handlingData: {type: Function},
  },
  data() {
    return {
      mainRoute: 'settings/inventory_statistic/re-calculate-qty',
      mainRouteDependency: 'base/dependency',
      data: {
        items: [],
        inventory_id: null,
        quantity_type: 1,
        reset_type: 1
      },
      validation: null,
      quantity_type_list: [],
      reset_type_list: [],
      f_by: 'name',
      inventory: null,
      inventories: [],
      products: [],
      items: [],
    };
  },

  methods: {
    save() {
      this.create();
    },
    cancel() {
      this.$emit('hide-modal');
      this.resetAfterSave();
      this.finishedSave();
    },
    finishedSave(su_object) {
      this.$emit('handling-data', su_object);
    },
    create() {
      ApiService.put(this.mainRoute, {
        ...this.data,
      }).then((response) => {
        this.$successAlert(response.data.message);
        this.cancel();
        Vue.prototype.$postStatus = true;
      }).catch(error => {
        this.$errorAlert(error);
        Vue.prototype.$postStatus = true;
      });
    },
    resetAfterSave() {
      this.data = {
        items: [],
        inventory_id: null,
        quantity_type: 1,
        reset_type: 1
      };
      this.inventory = null;
      this.products = [];
    },
    getIds(type){
      switch (type) {
        case 'items':
          this.data.items = this.products.map((row)=>row.id);
          break;
        case 'inventory':
          this.data.inventory_id = this.inventory.id;
          break;
      }
    },
    getInventories() {
      ApiService.get(this.mainRouteDependency + `/inventories`).then((response) => {
        this.inventories = response.data.data;
      });
    },
    getQuantityType() {
      ApiService.get(this.mainRouteDependency + `/quantity_type`).then((response) => {
        this.quantity_type_list = response.data.data;
      });
    },
    getResetType() {
      ApiService.get(this.mainRouteDependency + `/reset_type`).then((response) => {
        this.reset_type_list = response.data.data;
      });
    },

    getItems(f_by = null, filter = null) {
      let _f_by = f_by ? f_by : 'name';
      if (filter && filter.length >= 3 && _f_by)
        ApiService.get(this.mainRouteDependency + "/items", {params: {[_f_by]: filter}}).then((response) => {
          this.items = response.data.data;
        });
    },
  },

  mounted() {
    this.getInventories();
    this.getQuantityType();
    this.getResetType();
  },
};
</script>