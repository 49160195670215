<template>
    <!-- begin:: Aside -->
    <div>
        <div class="brand brandWhite flex-column-auto" id="kt_brand" ref="kt_brand">
        <!-- <div class="bg-light flex-column-auto" id="kt_brand" ref="kt_brand"> -->
            <div class="brand-logo">
                <router-link to="/">
                    <!-- <img :src="siteLogo()" alt="Logo"/> -->
                    <!-- <img :src="company.logo_url ? company.logo_url : siteLogo()" alt="Logo" class="mt-15" width="110px"/> -->
                    <img v-if="company && company.logo_url" :src="company.logo_url ? company.logo_url : ''" alt="Logo" class="mt-25 mr-10 logo" width="120px"/>
                </router-link>
            </div>
            <div class="brand-tools" v-if="allowMinimize">
                <button
                        class="brand-toggle btn btn-sm px-0"
                        id="kt_aside_toggle"
                        ref="kt_aside_toggle"
                >
                    <span class="svg-icon svg-icon svg-icon-xl">
                    <inline-svg class="svg-icon" src="/media/svg/icons/Navigation/Angle-double-left.svg" />
                    </span>
                </button>
            </div>
        </div>
        <!-- <div class="brand flex-column-auto mt-20">
            <div class="text-white calender-data-date"><i class="fa fa-calendar text-white"></i> <span class="pl-1 pr-1">{{ date_new }}</span></div>
            <div class="text-white calender-data-time"><i class="fa fa-clock text-white"></i> <span class="pl-1 pr-1">{{ time_new }}</span></div>
        </div> -->

        <!-- <div class="brand brandWhite flex-column-auto mt-20">
            <div class="text-green calender-data-date"><i class="fa fa-calendar text-green"></i> <span class="pl-1 pr-1">{{ date_new }}</span></div>
            <div class="text-green calender-data-time"><i class="fa fa-clock text-green"></i> <span class="pl-1 pr-1">{{ time_new }}</span></div>
        </div> -->

    </div>
    <!-- end:: Aside -->
</template>

<style lang="scss" scoped>
    .aside-toggle {
        outline: none;
    }
    .brandWhite{
        // background-color: #F9F9F9 !important;
        background-color: #fff !important;
        position: relative;
    }
    .text-green  {
        color: #0C6F58;
    }
    .logo {
        height: 10rem;
    }
    .brand {
        background-color: #fff;
        // color: #0C6F58;
    }
</style>

<script>
    import {mapGetters} from "vuex";
    import objectPath from "object-path";
    import KTLayoutBrand from "@/assets/js/layout/base/brand.js";
    import KTLayoutAsideToggle from "@/assets/js/layout/base/aside-toggle.js";
    import ApiService from "@/core/services/api.service";

    export default {
        name: "KTBrand",
        data() {
            return {
                mainRouteSettings: 'settings/company',
                date_new: null,
                time_new: null,
                // company: [],
            }
        },
        mounted() {
            // this.getCompany();
            this.date_new = this.$moment().format('YYYY-MM-DD');
            let that = this;
            setInterval(() => {
                that.time_new = that.$moment().format('hh:mm:ss');
            }, 1000);

            // Init Brand Panel For Logo
            KTLayoutBrand.init(this.$refs["kt_brand"]);

            // Init Aside Menu Toggle
            KTLayoutAsideToggle.init(this.$refs["kt_aside_toggle"]);
        },
        methods: {
            // getCompany() {
            //     ApiService.get(this.mainRouteSettings + `/getCompany`).then((response) => {
            //         this.company = response.data.data;
            //     });
            //
            // },
            siteLogo() {
                const menuAsideLeftSkin = this.layoutConfig("brand.self.theme");
                // set brand logo
                const logoObject = this.layoutConfig("self.logo");

                let logo;
                if (typeof logoObject === "string") {
                    logo = logoObject;
                }
                if (typeof logoObject === "object") {
                    logo = objectPath.get(logoObject, menuAsideLeftSkin + "");
                }
                if (typeof logo === "undefined") {
                    const logos = this.layoutConfig("self.logo");
                    logo = logos[Object.keys(logos)[0]];
                }
                return process.env.BASE_URL + logo;
            }
        },
        computed: {
            ...mapGetters({layoutConfig: "layoutConfig", company: "getCompanyData"}),
            allowMinimize() {
                return !!this.layoutConfig("aside.self.minimize.toggle");
            }
        }
    };
</script>
