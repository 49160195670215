<template>
  <div>
    <vue-editor ref="editor" :value="valueData"  @input="updateValue($event)" :editor-toolbar="customToolbar" use-custom-image-handler
                @image-added="handleImageForEditor"></vue-editor>
  </div>
</template>

<script>
import {VueEditor} from "vue2-editor";
import uploadImagesForVueEditor from "@/core/config/mix/uploadImagesForVueEditor";

export default {
  name: 'editor-textarea',
  components: {VueEditor},
  props:['valueData', 'dirUpload'],
  data() {
    return {
      customToolbar: [
        [{'font': []}],
        [{'header': [false, 1, 2, 3, 4, 5, 6,]}],
        [{'size': ['small', false, 'large', 'huge']}],
        ['bold', 'italic', 'underline', 'strike'],
        [{'align': ''}, {'align': 'center'}, {'align': 'right'}, {'align': 'justify'}],
        [{'header': 1}, {'header': 2}],
        ['blockquote'],
        [{'list': 'ordered'}, {'list': 'bullet'}, {'list': 'check'}],
        [{'indent': '-1'}, {'indent': '+1'}],
        [{'color': []}, {'background': []}],
        ['image'],
        [{'direction': 'rtl'}],
        ['clean'],
      ],
    }
  },
  methods: {
    ...uploadImagesForVueEditor,
    updateValue(event){
      this.$emit('update:valueData', event);
    }
  }
}
</script>


<style scoped>

</style>