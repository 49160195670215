<template>
  <div>
    <!--begin::customer-->
    <div>
      <div v-if="PlanMessage? true: false" class="card-header d-block border-0 pt-6 pb-0">
        <div>
          <b-alert show variant="danger">{{ PlanMessage }}</b-alert>
        </div>
      </div>
      <h3 class="card-label">
        {{ $t('employees.employees_management') }}
        <span class="text-muted pt-2 font-size-sm d-block"></span>
      </h3>

      <b-tabs content-class="mt-3" class="nav-custom-link">
        <b-tab :title="$t('employees.basic_information')" @click="toggleTab('basic_information')" active>
          <div class="row">
            <div class="col-md-6">
              <div class="card card-custom">
                <div class="card-body row">
                  <div class="col-lg-12 mb-5 d-flex justify-content-center">
                    <div class="form-group">
                      <label class="d-block text-center">{{ $t('employees.upload_image') }}</label>
                      <upload-avatar
                          @file="listenerImage"
                          :inner-id="'image'"
                          :placeholder="$t('employees.upload_image')"
                          :upload="dir_upload"
                          :start-link="'base'"
                          v-bind:valueprop="{name:'image',path:image_url}" v-if="reloadUploadImage">
                      </upload-avatar>
                    </div>
                  </div>
                  <div class="col-lg-6  mb-5">
                    <label>{{ $t('employees.first_name') }}<span class="text-danger">*</span></label>
                    <input type="text" v-model="data.first_name" class="form-control"
                           :class="validation && validation.first_name ? 'is-invalid' : ''"/>
                    <span v-if="validation && validation.first_name"
                          class="fv-plugins-message-container invalid-feedback">
                                    {{ validation.first_name[0] }}
                                    </span>
                  </div>
                  <div class="col-lg-6  mb-5">
                    <label>{{ $t('employees.second_name') }}</label>
                    <input type="text" v-model="data.second_name" class="form-control"
                           :class="validation && validation.second_name ? 'is-invalid' : ''"/>
                    <span v-if="validation && validation.second_name"
                          class="fv-plugins-message-container invalid-feedback">
                    {{ validation.second_name[0] }}
                    </span>
                  </div>
                  <div class="col-lg-6  mb-5">
                    <label>{{ $t('employees.surname') }}<span class="text-danger">*</span></label>
                    <input type="text" v-model="data.surname" class="form-control"
                           :class="validation && validation.surname ? 'is-invalid' : ''"/>
                    <span v-if="validation && validation.surname" class="fv-plugins-message-container invalid-feedback">
                                    {{ validation.surname[0] }}
                                    </span>
                  </div>
                  <div class="col-lg-6  mb-5">
                    <label>{{ $t('employees.identity_type') }}</label>
                    <div class="input-group">
                      <select name="" id="identity_type" v-model="data.identity_type" type="text" class="custom-select"
                              :class="validation && validation.identity_type ? 'is-invalid' : ''">
                        <option v-for="(row, index) in type_identities" :value="row.id" :key="index">{{
                            row.title
                          }}
                        </option>
                      </select>
                      <span v-if="validation && validation.identity_type"
                            class="fv-plugins-message-container invalid-feedback">
                                          {{ validation.identity_type[0] }}
                                        </span>
                    </div>
                  </div>
                  <div class="col-lg-6  mb-5">
                    <label>{{ $t('employees.identity_no') }}</label>
                    <div class="input-group">
                      <input type="number" v-model="data.identity_no" class="form-control"
                             :class="validation && validation.identity_no ? 'is-invalid' : ''"/>
                      <span v-if="validation && validation.identity_no"
                            class="fv-plugins-message-container invalid-feedback">
                                          {{ validation.identity_no[0] }}
                                        </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="card card-custom">
                <div class="card-body row">
                  <div class="col-lg-6  mb-5">
                    <label>{{ $t('employees.employee_code') }}</label>
                    <div class="input-group">
                      <input type="text" v-model="data.employee_code" class="form-control"
                             :class="validation && validation.employee_code ? 'is-invalid' : ''"/>
                      <span v-if="validation && validation.employee_code"
                            class="fv-plugins-message-container invalid-feedback">
                                          {{ validation.employee_code[0] }}
                                        </span>
                    </div>
                  </div>
                  <div class="col-lg-6  mb-5">
                    <label>{{ $t('employees.job_title') }}</label>
                    <input type="text" v-model="data.job_title" class="form-control"
                           :class="validation && validation.job_title ? 'is-invalid' : ''"/>
                    <span v-if="validation && validation.job_title" class="fv-plugins-message-container invalid-feedback">
                                    {{ validation.job_title[0] }}
                                    </span>
                  </div>
                  <div class="col-lg-6  mb-5">
                    <label>{{ $t('employees.nationality') }}</label>
                    <input type="text" v-model="data.nationality" class="form-control"
                           :class="validation && validation.nationality ? 'is-invalid' : ''"/>
                    <span v-if="validation && validation.nationality"
                          class="fv-plugins-message-container invalid-feedback">
                                    {{ validation.nationality[0] }}
                                    </span>
                  </div>
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('employees.mobile') }}<span class="text-danger">*</span></label>
                    <vue-tel-input v-model="data.mobile"
                                   :class="validation && validation.mobile ? 'is-invalid' : ''"></vue-tel-input>
                    <span v-if="validation && validation.mobile" class="fv-plugins-message-container invalid-feedback">
                                            {{ validation.mobile[0] }}
                                        </span>
                  </div>
                  <div class="col-lg-6  mb-5">
                    <label>{{ $t('employees.email') }}</label>
                    <div class="input-group">
                      <input type="email" class="form-control" :class="validation && validation.email ? 'is-invalid' : ''"
                             v-model="data.email"/>
                      <span v-if="validation && validation.email" class="fv-plugins-message-container invalid-feedback">
                                          {{ validation.email[0] }}
                                        </span>
                    </div>
                  </div>
                  <div class="col-lg-6  mb-5">
                    <label>{{ $t('employees.employment_type') }}</label>
                    <div class="input-group">
                      <select name="" id="employment_type" v-model="data.employment_type" type="text"
                              class="custom-select" :class="validation && validation.employment_type ? 'is-invalid' : ''">
                        <option v-for="(row, index) in employment_types" :value="row.id" :key="index">{{
                            row.title
                          }}
                        </option>
                      </select>
                      <span v-if="validation && validation.employment_type"
                            class="fv-plugins-message-container invalid-feedback">
                                          {{ validation.employment_type[0] }}
                                        </span>
                    </div>
                  </div>
                  <div class="col-lg-6  mb-5">
                    <label>{{ $t('employees.employment_level') }}</label>
                    <div class="input-group">
                      <select name="" id="employment_level" v-model="data.employment_level" type="text"
                              class="custom-select"
                              :class="validation && validation.employment_level ? 'is-invalid' : ''">
                        <option v-for="(row, index) in employment_levels" :value="row.id" :key="index">{{
                            row.title
                          }}
                        </option>
                      </select>
                      <span v-if="validation && validation.employment_level"
                            class="fv-plugins-message-container invalid-feedback">
                                          {{ validation.employment_level[0] }}
                                        </span>
                    </div>
                  </div>
                  <div class="col-lg-6 mb-5 mt-5">
                    <label>{{ $t('status') }}:</label>
                    <b-form-checkbox size="lg" v-model="data.is_active" name="check-button" switch></b-form-checkbox>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="mt-5">
            <b-tabs content-class="mt-3" class="nav-custom-link">
              <b-tab :title="$t('employees.address_info')">
                <div class="card card-custom">
                  <div class="card-body row">
                    <div class="col-lg-3 mb-5">
                      <label>{{ $t('employees.country') }}</label>
                      <div class="input-group">
                        <multiselect
                            :class="validation && validation.country_code ? 'is-invalid' : ''"
                            v-model="country"
                            :placeholder="$t('employees.country')"
                            label="name"
                            track-by="code2"
                            :options="countries"
                            :multiple="false"
                            :taggable="false"
                            :show-labels="false"
                            :show-no-options="false"
                            :show-no-results="false"
                            @search-change="getCountries($event)"
                            :internal-search="false"
                        >
                        </multiselect>
                        <span v-if="validation && validation.country_code" class="fv-plugins-message-container invalid-feedback">
                      {{ validation.country_code[0] }}
                    </span>
                      </div>
                    </div>
                    <div class="col-lg-3 mb-5">
                      <label>{{ $t('employees.city') }}</label>
                      <div class="input-group">
                        <multiselect
                            :class="validation && validation.city_id ? 'is-invalid' : ''"
                            v-model="city"
                            :placeholder="$t('employees.city')"
                            label="name"
                            track-by="id"
                            :options="cities"
                            :multiple="false"
                            :taggable="false"
                            :show-labels="false"
                            :show-no-options="false"
                            :show-no-results="false"
                        >
                        </multiselect>
                        <div class="input-group-prepend">
                          <a class="btn btn-primary" :href="data.country_code?'/settings/cities/'+data.country_code:'#'" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                        </div>
                        <span v-if="validation && validation.city_id" class="fv-plugins-message-container invalid-feedback">
                      {{ validation.city_id[0] }}
                    </span>
                      </div>
                    </div>

                    <div class="col-lg-3 mb-5">
                      <label>{{ $t('employees.state') }}</label>
                      <div class="input-group">
                        <input type="text" class="form-control" v-model="data.state"
                               :class="validation && validation.state ? 'is-invalid' : ''"/>
                        <span v-if="validation && validation.state" class="fv-plugins-message-container invalid-feedback">
                                              {{ validation.state[0] }}
                                              </span>
                      </div>
                    </div>
                    <div class="col-lg-3 mb-5">
                      <label>{{ $t('employees.postal_code') }}</label>
                      <div class="input-group">
                        <input type="text" class="form-control" v-model="data.postal_code"
                               :class="validation && validation.postal_code ? 'is-invalid' : ''"/>
                        <span v-if="validation && validation.postal_code"
                              class="fv-plugins-message-container invalid-feedback">
                              {{ validation.postal_code[0] }}
                              </span>
                      </div>
                    </div>

                    <div class="col-lg-3 mb-5">
                      <label>{{ $t('employees.address1') }}</label>
                      <div class="input-group">
                        <input v-model="data.address1" type="text" class="form-control"
                               :class="validation && validation.address1 ? 'is-invalid' : '' "/>
                        <span v-if="validation && validation.address1"
                              class="fv-plugins-message-container invalid-feedback">
                                          {{ validation.address1[0] }}
                                        </span>
                      </div>
                    </div>
                    <div class="col-lg-3 mb-5">
                      <label>{{ $t('employees.address2') }}</label>
                      <div class="input-group">
                        <input type="text" v-model="data.address2" class="form-control"
                               :class=" validation && validation.address2 ? 'is-invalid' : ''"/>
                        <span v-if="validation && validation.address2"
                              class="fv-plugins-message-container invalid-feedback">
                                {{ validation.address2[0] }}
                              </span>
                      </div>
                    </div>

                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>


        </b-tab>
        <b-tab :title="$t('employees.information_account')" :disabled="!idEditing"
               @click="toggleTab('accounting_information')">
          <div class="row">
            <div class="col-md-6">
              <div class="card card-custom">
                <div class="card-body row">
                  <div class="col-lg-6  mb-5">
                    <label>{{ $t('employees.email') }}<span class="text-danger">*</span></label>
                    <div class="input-group">
                      <input type="email" class="form-control"
                             :class="validation && validation.user_email ? 'is-invalid' : ''" v-model="data.user_email"/>
                      <span v-if="validation && validation.user_email"
                            class="fv-plugins-message-container invalid-feedback">
                                          {{ validation.user_email[0] }}
                                        </span>
                    </div>
                  </div>
                  <div class="col-lg-6  mb-5">
                    <label>{{ $t('employees.password') }}</label>
                    <div class="input-group">
                      <input type="password" class="form-control"
                             :class="validation && validation.password ? 'is-invalid' : ''" v-model="data.password"/>
                      <span v-if="validation && validation.password"
                            class="fv-plugins-message-container invalid-feedback">
                                          {{ validation.password[0] }}
                                        </span>
                    </div>
                  </div>
                  <div class="col-lg-6  mb-5">
                    <div>
                      <label>{{ $t('employees.roles') }}</label>
                      <div class="input-group mb-3">
                        <multiselect
                            v-model="role"
                            :placeholder="$t('employees.roles')"
                            label="name"
                            track-by="id"
                            :options="roles"
                            :multiple="true"
                            :taggable="false"
                            :show-labels="false"
                            :show-no-options="false"
                            :show-no-results="false"
                        >
                        </multiselect>
                        <div class="input-group-prepend">
                          <a class="btn btn-primary" href="/users/roles" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 mb-5">
                    <div class="form-group">
                      <label>{{ $t('employees.send_credetial_email') }}:</label>
                      <b-form-checkbox size="lg" v-model="data.send_credetial_email" name="check-button"
                                       switch></b-form-checkbox>
                    </div>
                  </div>
                  <div class="col-md-6 mb-5">
                    <div class="form-group">
                      <label>{{ $t('employees.allow_access_system') }}:</label>
                      <b-form-checkbox size="lg" v-model="data.allow_access_system" name="check-button"
                                       switch></b-form-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="card card-custom">
                <div class="card-body row">
                  <div class="col-lg-6  mb-5">
                    <label>{{ $t('employees.account_type') }}</label>
                    <div class="input-group">
                      <select name="" id="is_general" v-model="data.is_general" type="text"
                              class="custom-select"
                              :class="validation && validation.is_general ? 'is-invalid' : ''">
                        <option v-for="(row, index) in account_type_list" :value="row.id" :key="index">{{
                            row.title
                          }}
                        </option>
                      </select>
                      <span v-if="validation && validation.is_general"
                            class="fv-plugins-message-container invalid-feedback">
                                          {{ validation.is_general[0] }}
                                        </span>
                    </div>
                  </div>
                  <div class="col-lg-6  mb-5">
                    <label>{{ $t('employees.display_language') }}</label>
                    <select name="" id="language_id" v-model="data.language_id" class="custom-select"
                            :class="validation && validation.language_id ? 'is-invalid' : ''">
                      <option v-for="row in languages" :value="row.id" :key="row.id">
                        {{ row.name }}
                      </option>
                    </select>
                    <span v-if="validation && validation.language_id"
                          class="fv-plugins-message-container invalid-feedback">
                                            {{ validation.language_id[0] }}
                                        </span>
                  </div>
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('employees.manager') }}</label>
                    <div class="input-group">
                      <multiselect
                          v-model="manager"
                          :placeholder="$t('employees.manager')"
                          label="name"
                          track-by="id"
                          :options="users"
                          :multiple="false"
                          :taggable="false"
                          :show-labels="false"
                          :show-no-options="false"
                          :show-no-results="false"
                          @input="setManager"
                          @search-change="getUsers($event)">
                      </multiselect>
                      <div class="input-group-prepend">
                        <a class="btn btn-primary" href="/users/users/create" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                      </div>
                    </div>
                    <span class="form-text text-muted" style="text-align:end">{{ $t('type_at_least_three_letters') + ' ' + $t('employees.manager') }}.</span>
                    <span v-if="validation && validation.manager_id"
                          class="fv-plugins-message-container invalid-feedback">
                      {{ validation.manager_id[0] }}
                  </span>
                  </div>
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('employees.inventory') }}</label>
                    <div class="input-group">
                      <multiselect v-model="inventory"
                                   :placeholder="$t('employees.inventory')"
                                   label="name"
                                   track-by="id"
                                   :options="inventories"
                                   @input="getMultiSelectInventory"
                                   :multiple="true"
                                   :class="validation && validation.multi_inventories ? 'is-invalid' : ''"
                                   :taggable="false"
                                   :show-labels="false"
                                   :show-no-options="false"
                                   :show-no-results="false">
                      </multiselect>
                      <div class="input-group-prepend">
                        <a class="btn btn-primary" href="/settings/inventories/create" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                      </div>
                    </div>
                    <span v-if="validation && validation.multi_inventories"
                          class="fv-plugins-message-container invalid-feedback">
                      {{ validation.multi_inventories[0] }}
                  </span>
                  </div>
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('employees.branch') }}</label>
                    <div class="input-group">
                      <multiselect v-model="branch"
                                   :placeholder="$t('employees.branch')"
                                   label="name"
                                   track-by="id"
                                   :options="branches"
                                   @input="getMultiSelectBranch"
                                   :multiple="true"
                                   :class="validation && validation.multi_branches ? 'is-invalid' : ''"
                                   :taggable="false"
                                   :show-labels="false"
                                   :show-no-options="false"
                                   :show-no-results="false">
                      </multiselect>
                      <div class="input-group-prepend">
                        <a class="btn btn-primary" href="/settings/branches/create" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                      </div>
                    </div>
                    <span v-if="validation && validation.multi_branches"
                          class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.multi_branches[0] }}
                                    </span>
                  </div>

                </div>
              </div>
            </div>

          </div>


        </b-tab>
        <b-tab :title="$t('employees.financial_info')" :disabled="!idEditing" @click="toggleTab('financial_info')">

          <div class="row">
            <div class="col-md-6">
              <div class="card card-custom">
                <div class="card-body row">
                  <div class="col-lg-6 mt-5">
                    <label>{{ $t('employees.start_salary') }}</label>
                    <input type="number" v-model="data.start_salary" class="form-control" min="0"
                           :class="validation && validation.start_salary ? 'is-invalid' : ''"/>
                    <span v-if="validation && validation.start_salary"
                          class="fv-plugins-message-container invalid-feedback">
                                            {{ validation.start_salary[0] }}
                                        </span>
                  </div>
                  <div class="col-lg-6 mt-5">
                    <label>{{ $t('employees.current_salary') }}</label>
                    <input type="number" v-model="data.current_salary" class="form-control"
                           :class="validation && validation.current_salary ? 'is-invalid' : ''"/>
                    <span v-if="validation && validation.current_salary"
                          class="fv-plugins-message-container invalid-feedback">
                      {{ validation.current_salary[0] }}
                  </span>
                  </div>
                  <div class="col-lg-6 mt-5">
                    <label>{{ $t('employees.increase_every') }}</label>
                    <select name="" id="increase_every" v-model="data.increase_every" class="custom-select"
                            :class="validation && validation.increase_every ? 'is-invalid' : ''">
                      <option v-for="row in increased_everys" :value="row.id" :key="row.id">{{ row.title }}</option>
                    </select>
                    <span v-if="validation && validation.increase_every"
                          class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.increase_every[0] }}
                                    </span>
                  </div>
                  <div class="col-lg-6 mt-5">
                    <label>{{ $t('employees.currency') }}</label>
                    <div class="input-group">
                      <select name="" id="currency_id" v-model="data.currency_id" class="custom-select"
                              :class="validation && validation.currency_id ? 'is-invalid' : ''">
                        <option v-for="row in currencies" :value="row.id" :key="row.id">{{ row.name }}</option>
                      </select>
                      <div class="input-group-prepend">
                        <a class="btn btn-primary" href="/settings/currencies" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                      </div>
                    </div>
                    <span v-if="validation && validation.currency_id" class="fv-plugins-message-container invalid-feedback">
                      {{ validation.currency_id[0] }}
                  </span>
                  </div>
                  <div class="col-lg-6 mt-5">
                    <label>{{ $t('employees.increase_percentage') }}</label>
                    <input type="number" v-model="data.increase_percentage" class="form-control"
                           :class="validation && validation.increase_percentage ? 'is-invalid' : ''"/>
                    <span v-if="validation && validation.increase_percentage"
                          class="fv-plugins-message-container invalid-feedback">
                                            {{ validation.increase_percentage[0] }}
                                        </span>
                  </div>

                  <div class="col-md-6 mt-5">
                      <label>{{ $t('employees.is_sales_man') }}</label>
                      <b-form-checkbox size="lg" v-model="data.is_sales_man" @input="changeIsSalesMan" name="check-button"
                                       switch></b-form-checkbox>
                  </div>
                  <template v-if="data.is_sales_man">
                    <div class="col-lg-6 mt-5">
                      <label>{{ $t('employees.commission_type') }}</label>
                      <select v-model="data.commission_info.commission_type" @change="changeCommissionType" name="commission_type" id="commission_type" class="custom-select">
                        <option v-for="(co_type, index) in commission_type_list" :value="co_type.id" :key="'co_type_'+index">{{co_type.title}}</option>
                      </select>
                      <span v-if="validation && validation.commission_value" class="fv-plugins-message-container invalid-feedback">
                        {{ validation.commission_value[0] }}
                    </span>
                    </div>
                    <div class="col-lg-6 mt-5">
                      <label>{{ $t('employees.commission_value') }}</label>
                      <input type="number" v-model="data.commission_info.commission_value" @input="changeCommissionValue" :disabled="data.commission_info.commission_type == 0" class="form-control"
                             :class="validation && validation.commission_value ? 'is-invalid' : ''"/>
                      <span v-if="validation && validation.commission_value" class="fv-plugins-message-container invalid-feedback">
                        {{ validation.commission_value[0] }}
                    </span>
                    </div>
                  </template>

                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="card card-custom">
                <div class="card-body row">
                  <div class="col-lg-6 mt-5">
                    <label>{{ $t('employees.start_date') }}</label>
                    <input type="date" v-model="data.start_date" class="form-control"
                           :class="validation && validation.start_date ? 'is-invalid' : ''"/>
                    <span v-if="validation && validation.start_date"
                          class="fv-plugins-message-container invalid-feedback">
                                            {{ validation.start_date[0] }}
                                        </span>
                  </div>
                  <div class="col-lg-6 mt-5">
                    <label>{{ $t('employees.expired_date') }}</label>
                    <input type="date" v-model="data.expired_date" class="form-control"
                           :class="validation && validation.expired_date ? 'is-invalid' : ''"/>
                    <span v-if="validation && validation.expired_date"
                          class="fv-plugins-message-container invalid-feedback">
                                            {{ validation.expired_date[0] }}
                                        </span>
                  </div>
                  <div class="col-lg-6 mt-5">
                    <label>{{ $t('employees.house_alternative') }}</label>
                    <input type="number" v-model="data.house_alternative" class="form-control"
                           :class="validation && validation.house_alternative ? 'is-invalid' : ''"/>
                    <span v-if="validation && validation.house_alternative"
                          class="fv-plugins-message-container invalid-feedback">
                                            {{ validation.house_alternative[0] }}
                                        </span>
                  </div>
                  <div class="col-lg-6 mt-5">
                    <label>{{ $t('employees.travel_alternative') }}</label>
                    <input type="number" v-model="data.travel_alternative" class="form-control"
                           :class="validation && validation.travel_alternative ? 'is-invalid' : ''"/>
                    <span v-if="validation && validation.travel_alternative"
                          class="fv-plugins-message-container invalid-feedback">
                                            {{ validation.travel_alternative[0] }}
                                        </span>
                  </div>
                  <div class="col-lg-6 mt-5">
                    <label>{{ $t('employees.health_alternative') }}</label>
                    <input type="number" v-model="data.health_alternative" class="form-control"
                           :class="validation && validation.health_alternative ? 'is-invalid' : ''"/>
                    <span v-if="validation && validation.health_alternative"
                          class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.health_alternative[0] }}
                                    </span>
                  </div>

                </div>
              </div>
            </div>
          </div>


        </b-tab>
        <b-tab :title="$t('employees.attachments')" :disabled="!idEditing" @click="toggleTab('attachments')">
          <div class="card card-custom">
            <div class="card-body">
                <attachments :employee-id="idEditing"></attachments>
            </div>
          </div>
        </b-tab>
        <b-tab :title="$t('employees.notes')" :disabled="!idEditing" @click="toggleTab('notes')">
          <div class="card card-custom">
            <div class="card-body">
                <notes :employee-id="idEditing"></notes>
            </div>
          </div>
        </b-tab>

        <b-tab :title="$t('activity_log.activity_log')" @click="toggleTab('activity_log')">
          <div class="card card-custom">
            <div class="card-body">
              <activity-log :employee-id="idEditing"></activity-log>
            </div>
          </div>
        </b-tab>

      </b-tabs>


      <div class="pl-0 pr-0">
        <div class="row">
          <div class="col-lg-6 mt-10">
            <button type="reset" class="btn btn-primary mr-2" @click="save()" v-if="tab_name != 'address_info'">
              {{ $t('save') }}
            </button>
            <button type="reset" class="btn btn-secondary">{{ $t('cancel') }}</button>
          </div>
        </div>
      </div>
    </div>
    <!--end::customer-->
  </div>
</template>
<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import Attachments from "./Attachments";
import Notes from "./Notes";
import ActivityLog from "./ActivityLog";
import {mapGetters, mapState} from "vuex";
import timeZoneStructure from "@/core/config/mix/timeZoneStructure";


export default {
  name: "form-employees",
  components: {'attachments': Attachments, 'Notes': Notes, 'activity-log': ActivityLog},
  data() {
    return {
      dir_upload: 'employees',
      mainRoute: 'employee/employees',
      mainRouteDependency: 'base/dependency',
      tab_name: 'basic_information',

      data: {
        first_name: null,
        second_name: null,
        surname: null,
        nationality: null,
        employment_type: 1,
        identity_type: 1,
        identity_no: null,
        employee_code: null,
        employment_level: 1,
        increase_every: 0,
        country_code: null,
        email: null,
        mobile: null,
        city_id: null,
        state: null,
        postal_code: null,
        address1: null,
        address2: null,
        start_salary: null,
        current_salary: null,
        increase_percentage: null,
        house_alternative: null,
        travel_alternative: null,
        health_alternative: null,
        currency_id: null,
        language_id: null,
        is_active: true,
        image: null,
        multi_inventories: null,

        multi_branches: null,


        //accounting_data
        password: null,
        role_id: [],
        allow_access_system: false,
        send_credetial_email: false,
        user_email: null,
        // invoice_bonds: [],
        start_date: null,
        expired_date: null,
        emp_user_id: null,
        job_title: null,
        is_general: 0,
        manager_id: null,
        commission_info: { commission_type: 0, commission_value: 0 },
        is_sales_man: false,

      },
      idEditing: this.$route.params.id ? this.$route.params.id : null,
      isEditing: false,
      image_url: null,

      type_identities: [],
      employment_types: [],
      employment_levels: [],
      increased_everys: [],
      inventories: [],
      branches: [],
      roles: [],

      countries: [],
      cities: [],

      languages: [],
      currencies: [],
      validation: null,

      role: [],

      reloadUploadImage: true,
      country: null,
      city: null,
      PlanMessage: null,

      inventory: [],
      branch: [],
      account_type_list: [
        {id: '0', title: this.$t('employees.special')},
        {id: '1', title: this.$t('employees.general')},
      ],
      commission_type_list: [
        {id: 0, title: this.$t('employees.none')},
        {id: 1, title: this.$t('employees.percentage')},
        {id: 2, title: this.$t('employees.fixed_value')},
      ],

      manager: null,
      users: [],


    };
  },
  computed: {
    ...mapState({user_personal_info: state => state.profile.user_personal_info}),
    ...mapGetters(["currentUserPersonalInfo"]),
  },
  watch: {
    "data.mobile": function (val) {
      if (val !== null && val.length < 6) {
        this.data.mobile = null;
      }
    },

    "country": function (val) {
      if (val) {
        this.data.country_code = val.code2;
        if (val.code2) {
          this.getCities(val.code2);
        }
      } else {
        this.data.country_code = null;
      }
    },
    "city": function (val) {
      if (val) {
        this.data.city_id = val.id;
      } else {
        this.data.city_id = null;
      }
    },
    'data.first_name': function (val) {
      if (val) {
        this.data.first_name = val.replace(/[\d]/g, "");
      }
    },
    'data.second_name': function (val) {
      if (val) {
        this.data.second_name = val.replace(/[\d]/g, "");
      }
    },
    'data.surname': function (val) {
      if (val) {
        this.data.surname = val.replace(/[\d]/g, "");
      }
    },
    'data.state': function (val) {
      if (val) {
        this.data.state = val.replace(/[\d]/g, "");
      }
    },

  },
  methods: {
    ...timeZoneStructure,

    toggleTab(tab_name) {
      this.tab_name = tab_name
    },
    changeIsSalesMan(){
      if (!this.data.is_sales_man){
        this.data.commission_info.commission_type = 0;
        this.data.commission_info.commission_value = 0;
      }
    },
    changeCommissionType(){
      if (this.data.commission_info.commission_type == 0){
        this.data.commission_info.commission_value = 0;
      }
    },
    changeCommissionValue(){
      this.data.commission_info.commission_value = Math.abs(this.data.commission_info.commission_value)
    },
    getRoleById() {
      if (typeof this.role !== 'undefined') {
        let role_ids = [];
        this.role.filter((role) => {
          role_ids.push(role.id);
        });
        this.data.role_id = role_ids;
      }
    },
    save() {
      this.getRoleById();
      if (this.isEditing) {
        this.update();
      } else {
        this.create();
      }
    },

    create() {
      let _data = this.data;
      ApiService.post(`${this.mainRoute}`, {
        tab_name: this.tab_name,
        ..._data,
      })
          .then((response) => {
            this.validation = null;
            this.$successAlert(response.data.message);
            this.$router.push({name: 'employees.index'});
          })
          .catch((error) => {
            this.$errorAlert(error);
            this.validation = error.response ? error.response.data.errors : null;
          });
    },


    update() {
      let _data = this.data;
      ApiService.put(`${this.mainRoute}/${this.$route.params.id}`, {
        tab_name: this.tab_name,
        ..._data,
      })
          .then((response) => {
            this.validation = null;
            // this.$router.push({name: 'employees.index'});
            this.$successAlert(response.data.message);
          })
          .catch((error) => {
            this.$errorAlert(error);
            this.validation = error.response ? error.response.data.errors : null;
          });
    },

    async getData() {
      this.reloadUploadImage = false;

      ApiService.get(`${this.mainRoute}/${this.$route.params.id}`).then((response) => {
        this.isEditing = true;
        this.data.first_name = response.data.data.first_name;
        this.data.second_name = response.data.data.second_name;
        this.data.surname = response.data.data.surname;
        this.data.nationality = response.data.data.nationality;
        this.data.employment_type = response.data.data.employment_type;
        this.data.identity_type = response.data.data.identity_type;
        this.country = response.data.data.country;
        this.city = response.data.data.city;
        this.data.identity_no = response.data.data.identity_no;
        this.data.employee_code = response.data.data.employee_code;
        this.data.employment_level = response.data.data.employment_level;
        this.data.country_code = response.data.data.country_code;
        this.data.email = response.data.data.email;
        this.data.mobile = response.data.data.mobile;
        this.data.city_id = response.data.data.city_id;
        this.data.state = response.data.data.state;
        this.data.postal_code = response.data.data.postal_code;
        this.data.address1 = response.data.data.address1;
        this.data.address2 = response.data.data.address2;

        this.data.start_salary = response.data.data.start_salary;
        this.data.increase_every = response.data.data.increase_every;
        this.data.current_salary = response.data.data.current_salary;
        this.data.increase_percentage = response.data.data.increase_percentage;
        this.data.house_alternative = response.data.data.house_alternative;
        this.data.travel_alternative = response.data.data.travel_alternative;
        this.data.health_alternative = response.data.data.health_alternative;
        this.data.currency_id = response.data.data.currency_id;
        this.data.language_id = response.data.data.language_id;
        this.data.job_title = response.data.data.job_title;
        this.data.is_active = response.data.data.is_active;

        this.data.image = response.data.data.image;
        this.data.password = response.data.data.password;
        this.data.role_id = response.data.data.role_id;
        this.data.allow_access_system = response.data.data.allow_access_system;
        this.data.send_credetial_email = response.data.data.send_credetial_email;
        this.data.start_date = response.data.data.start_date;
        this.data.expired_date = response.data.data.expired_date;
        this.data.emp_user_id = response.data.data.emp_user_id;
        this.role = response.data.data.emp_user ? response.data.data.emp_user.role_id : [];
        this.data.user_email = response.data.data.emp_user ? response.data.data.emp_user.email : null;
        this.data.is_general = response.data.data.is_general;
        this.image_url = response.data.data.image_url;

        this.data.manager_id = response.data.data.emp_user ? response.data.data.emp_user.manager_id : null;
        this.manager = response.data.data.emp_user ? response.data.data.emp_user.manager : null;

        this.data.multi_inventories = response.data.data.multi_inventories;
        this.inventory = this.inventories.filter((row) => response.data.data.multi_inventories.includes(row.id));

        this.data.multi_branches = response.data.data.multi_branches;
        this.branch = this.branches.filter((row) => response.data.data.multi_branches.includes(row.id));

        this.reloadUploadImage = true;

        this.data.commission_info = response.data.data.commission_info ? response.data.data.commission_info : { commission_type: 0, commission_value: 0 };
        this.data.is_sales_man = response.data.data.is_sales_man ? response.data.data.is_sales_man : false;
      });
    },

    getCode() {
      ApiService.get(this.mainRouteDependency + "/code-setting/11").then((response) => {
        this.data.employee_code = response.data.data.code;
      });
    },
    getTypeIdentities() {
      ApiService.get(this.mainRouteDependency + "/identity_type").then((response) => {
        this.type_identities = response.data.data;
      });
    },
    getEmploymentTypes() {
      ApiService.get(this.mainRouteDependency + "/employment_type").then((response) => {
        this.employment_types = response.data.data;
      });
    },
    getEmploymentLevels() {
      ApiService.get(this.mainRouteDependency + "/employment_level").then((response) => {
        this.employment_levels = response.data.data;
      });
    },
    getIncreasedEverys() {
      ApiService.get(this.mainRouteDependency + "/increase_every").then((response) => {
        this.increased_everys = response.data.data;
      });
    },
    getCountries(filter) {
      if (filter && filter.length >= 3) {
        ApiService.get(this.mainRouteDependency + "/countries", {params: {filter: filter}}).then((response) => {
          this.countries = response.data.data;
        });
      }
    },
    getCurrencies() {
      ApiService.get(this.mainRouteDependency + "/currencies").then((response) => {
        this.currencies = response.data.data;
      });
    },

    getLanguages() {
      ApiService.get(this.mainRouteDependency + "/languages").then((response) => {
        this.languages = response.data.data;
      });
    },

    getCities(id) {
      ApiService.get(this.mainRouteDependency + `/cities/${id}`).then((response) => {
        this.cities = response.data.data;
      });
    },
    getRoles() {
      ApiService.get(this.mainRouteDependency + "/roles").then((response) => {
        this.roles = response.data.data.map((role) => {
          return {
            id: role.id,
            name: role.name,
          };
        });
      });
    },
    async getBranches() {
      await ApiService.get(this.mainRouteDependency + "/branches").then((response) => {
        this.branches = response.data.data;
      });
    },
    async getInventories() {
      await ApiService.get(this.mainRouteDependency + "/inventories").then((response) => {
        this.inventories = response.data.data;
      });
    },
    loadOptions() {
    },

    listenerImage(event) {
      if (event) {
        this.image_url = event.pathDB;
        this.data.image = event.name;
      }
    },
    getPlanMessage() {
      ApiService.get(this.mainRouteDependency + `/check_limits/employees`).then((response) => {
        this.PlanMessage = response.data.data;
      });
    },

    getMultiSelectInventory() {
      this.data.multi_inventories = [];
      this.inventory.filter((row) => {
        this.data.multi_inventories.push(row.id);
      });
    },
    getMultiSelectBranch() {
      this.data.multi_branches = [];
      this.branch.filter((row) => {
        this.data.multi_branches.push(row.id);
      });
    },

    getUsers(filter) {
      if (filter && filter.length >= 3)
        ApiService.get(this.mainRouteDependency + "/users", {params: {filter: filter}}).then((response) => {
          this.users = response.data.data;
        });
    },
    setManager(event) {
      if (event)
        this.data.manager_id = event.id;
      else
        this.data.manager_id = null;
    },

    defaultDataForUser() {
      ApiService.get(this.mainRouteDependency + "/default_data_for_user").then((response) => {
        this.country = response.data.data.country;
        this.city = response.data.data.city;
        this.data.country_code = response.data.data.country_id;
        this.data.city_id = response.data.data.city_id;
        this.data.currency_id = response.data.data.currency_id;
        this.data.language_id = response.data.data.language_id;

        // this.data.multi_inventories = response.data.data.multi_inventories;
        // this.inventory = this.inventories.filter((row) => response.data.data.multi_inventories.includes(row.id));
        //
        // this.data.multi_branches = response.data.data.multi_branches;
        // this.branch = this.branches.filter((row) => response.data.data.multi_branches.includes(row.id));
      });
    }
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{
      title: this.$t("MENU.employees_management"),
      route: '/employees/employees'
    }, {title: (this.idEditing ? this.$t('update') : this.$t('create'))}]);


    let promise = this.getInventories();
    let promise2 = this.getBranches();

    if (this.idEditing) {
      Promise.all([promise, promise2]).then(() => {
        this.getData();
      });

    } else {
      this.defaultDataForUser();
      this.getPlanMessage();
      this.getCode();
      // this.data.expired_date = new Date().toISOString().substr(0, 10);
      // this.data.start_date = new Date().toISOString().substr(0, 10);
      this.getTimeZone().then((res)=>{
        this.data.expired_date = res;
        this.data.start_date = res;
      });
    }
    this.getTypeIdentities();
    this.getEmploymentTypes();
    this.getEmploymentLevels();
    this.getIncreasedEverys();
    // this.getCountries();
    this.getLanguages();
    this.getCurrencies();
    this.getRoles();

  },
};
</script>