<template>
  <div>
    <!--begin::customer-->

    <h3 class="card-label">
      {{ $t('customer_checking.customer_checking') }}
      <span class="text-muted pt-2 font-size-sm d-block"></span>
    </h3>

    <div class="row mt-3">
      <div class="col-md-12">
        <div class="card card-custom">
          <div class="card-body">
            <div class="form-group row">
              <div class="col-lg-3 mb-5">
                <label>{{ $t('customer_checking.customer') }}<span class="text-danger">*</span></label>
                <multiselect
                    id="customer_id"
                    v-model="customer"
                    :placeholder="$t('customer_checking.customer')"
                    label="fullname"
                    track-by="id"
                    :options="customers"
                    :multiple="false"
                    :taggable="false"
                    :show-labels="false"
                    :show-no-options="false"
                    :show-no-results="false"
                    @input="changeToGetData"
                    @search-change="getCustomers($event)">
                </multiselect>
                <span v-if="validation && validation.customer_id" class="fv-plugins-message-container invalid-feedback">
                    {{ validation.customer_id[0] }}
                </span>
              </div>
              <div class="col-lg-3 mb-5">
                <label>{{ $t('customer_checking.currency') }}<span class="text-danger">*</span></label>
                <select name="" id="currency_id" v-model="data.currency_id" @change="changeToGetData" class="custom-select">
                  <option v-for="row in currencies" :value="row.id" :key="row.id">{{ row.name }}</option>
                </select>
              </div>
              <div class="col-lg-3 mb-5">
                <label>{{ $t('status') }}</label>
                <select name="" id="status" v-model="data.status" disabled type="text" class="custom-select" :class="validation && validation.status ? 'is-invalid' : ''">
                  <option v-for="(row, index) in status_list" :value="row.id" :key="index">{{ row.title }}</option>
                </select>
                <span v-if="validation && validation.status" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.status[0] }}
                            </span>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-lg-3 mb-5">
                <label>{{ $t('customer_checking.credit') }}</label>
                <input type="number" v-model="data.old_credit" :disabled="true" class="form-control" :class="validation && validation.credit ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.credit" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.credit[0] }}
                            </span>
              </div>
              <div class="col-lg-3 mb-5">
                <label>{{ $t('customer_checking.new_credit') }}</label>
                <input type="number" v-model="data.credit"  class="form-control" :class="validation && validation.credit ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.credit" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.credit[0] }}
                            </span>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-lg-3 mb-5">
                <label>{{ $t('customer_checking.debit') }}</label>
                <input type="number" v-model="data.old_debit" :disabled="true" class="form-control" :class="validation && validation.debit ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.debit" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.debit[0] }}
                            </span>
              </div>
              <div class="col-lg-3 mb-5">
                <label>{{ $t('customer_checking.new_debit') }}</label>
                <input type="number" v-model="data.debit" class="form-control" :class="validation && validation.debit ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.debit" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.debit[0] }}
                            </span>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>



    <div class="row mt-10">
      <div class="col-lg-6">
        <button class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
      </div>
    </div>
    <!--end::customer-->
  </div>
</template>
<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "form-customer-checking",
  data() {
    return {
      mainRoute: 'customers/customer-checking',
      mainRouteDependency: 'base/dependency',

      data: {
        customer_id: null,
        currency_id: null,
        currency_code: null,
        credit: 0,
        debit: 0,
        old_credit: 0,
        old_debit: 0,
        status: 1,
      },

      idEditing: this.$route.params.id ? this.$route.params.id : null,
      isEditing: false,
      validation: null,
      currency_name: null,
      status_list: [],
      customer: null,
      customers: [],
      currencies: [],

    };
  },

  methods: {
    save() {
      if (this.isEditing) {
        this.update();
      } else {
        this.create();
      }
    },

    create() {
      ApiService.post(`${this.mainRoute}`, {
        ...this.data,
      })
          .then((response) => {
            this.validation = null;
            this.$successAlert(response.data.message);
            this.goBackIndex();
          })
          .catch((error) => {
            this.$errorAlert(error);
            this.validation = error.response ? error.response.data.errors : null;
          });
    },


    update() {
      ApiService.put(`${this.mainRoute}/${this.$route.params.id}`, {
        ...this.data,
      }).then((response) => {
        this.validation = null;
        this.goBackIndex();
        this.$successAlert(response.data.message);
      })
          .catch((error) => {
            this.$errorAlert(error);
            this.validation = error.response ? error.response.data.errors : null;
          });
    },
    goBackIndex() {
      this.$router.push({
        name: 'customer-checking.index', query: {
          customer_id: this.$route.query.customer_id,
        }
      });
    },
    async getData() {

      ApiService.get(`${this.mainRoute}/${this.$route.params.id}`).then((response) => {
        this.isEditing = true;
        this.data.customer_id = response.data.data.customer_id;
        this.customer = {
          id: response.data.data.customer_id,
          fullname: response.data.data.customer_name,
        };
        this.data.currency_code = response.data.data.currency_code;
        this.data.currency_id = response.data.data.currency_id;
        this.data.credit = response.data.data.credit;
        this.data.debit = response.data.data.debit;
        this.data.old_credit = response.data.data.old_credit;
        this.data.old_debit = response.data.data.old_debit;
        this.currency_name = response.data.data.currency_name;
      });
    },
    getDataBalance() {
      ApiService.get(`${this.mainRouteDependency}/customer_balance_by_currency`, {
        params: {
          customer_id: this.data.customer_id,
          currency_id: this.data.currency_id,
        }
      }).then((response) => {
        this.data.old_credit = response.data.data.credit;
        this.data.old_debit = response.data.data.debit;
        this.data.currency_code = response.data.data.currency_code;
        this.currency_name = response.data.data.currency_name;
      });
    },
    getStatus() {
      ApiService.get(this.mainRouteDependency + "/status").then((response) => {
        this.status_list = response.data.data;
      });
    },
    getCurrencies() {
      ApiService.get(this.mainRouteDependency + "/currencies").then((response) => {
        this.currencies = response.data.data;
      });
    },
    getCustomers(filter) {
      if (filter && filter.length >= 3)
        ApiService.get(`${this.mainRouteDependency}/customers`, {params: {filter: filter}}).then((response) => {
          this.customers = response.data.data;
        });
    },
    changeToGetData(){
      this.data.customer_id = this.customer ? this.customer.id : null;
      console.log(this.data.customer_id , this.data.currency_id)
      if (this.data.customer_id && this.data.currency_id){
        this.getDataBalance();
      }
    }
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.customer_checking"), route: '/customers/customer-checking'}, {title: (this.idEditing ? this.$t('update') : this.$t('create'))}]);

    this.getStatus();
    this.getCurrencies();

    if (this.idEditing) {
      this.getData();
    }

  },
};
</script>