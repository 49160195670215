<template>
    <div>

        <div class="card-toolbar mb-5">
            <router-link  v-if="$can('expenses.create')" to="/finances/expenses/create" class="btn btn-primary font-weight-bolder">
                <v-icon>mdi-plus</v-icon>
                {{ $t('expenses.add_expenses')}}
            </router-link>
            <button @click="openAdvancedSearch" class="btn btn-outline-primary font-weight-bolder ml-5" >
                <span><i class="la la-filter"></i> <span>{{$t('advanced_filter')}}</span></span>
            </button>
        </div>
        
        <div class="card card-custom mb-5" v-if="showAdvancedSearch">
            <div class="card-body">
                <div class="m-form m-form--fit m--margin-bottom-20">
                    <div class="row mb-5">
                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="code">{{$t('expenses.code')}}</label>
                            <input v-model="filters.code" type="text" id="code" class="form-control">
                        </div>
                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="from_date">{{$t('expenses.from_date')}}</label>
                            <input v-model="filters.from_date" type="date" id="from_date" class="form-control">
                        </div>
                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="to_date">{{$t('expenses.to_date')}}</label>
                            <input v-model="filters.to_date" type="date" id="to_date" class="form-control">
                        </div>

                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label>{{$t('expenses.currency')}}</label>
                            <select name="" id="currency_id" v-model="filters.currency_id" class="custom-select">
                                <option v-for="row in currencies" :value="row.id" :key="row.id">
                                    {{ row.name }}
                                </option>
                            </select>
                        </div>
                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label>{{$t('expenses.category')}}</label>
                            <treeselect
                                    :options="expenses_categories"
                                    :load-options="loadOptions"
                                    :multiple="false"
                                    :value="filters.expenses_category_id"
                                    @input="updateValue"
                                    :searchable="true"
                                    :show-count="true"
                                    :no-children-text="$t('No_sub_options')"
                                    :no-options-text="$t('No_options_available')"
                                    :no-results-text="$t('No_results_found')"
                                    :placeholder="$t('Select')">
                            </treeselect>
                        </div>
                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="f_status">{{$t('status')}}</label>
                            <select name="" id="f_status" v-model="filters.status" type="text" class="custom-select">
                                <option value="">{{$t('all')}}</option>
                                <option v-for="(row, index) in status_list" :value="row.id" :key="index">{{ row.title }}</option>
                            </select>
                        </div>


                        <div class="form-group d-inline-flex col-md-6 mt-6 mb-0">
                            <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                            <span><i class="fas fa-search"></i>
                                <span>{{$t('search')}}</span>
                            </span>
                            </button>
                            <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="m_reset">
                            <span><i class="fas fa-trash-restore"></i>
                                <span>{{$t('reset_search')}}</span>
                            </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <!--begin::customer-->
        <div class="card card-custom">
            
            <div class="card-body">

                <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">

                    <template slot="status" slot-scope="props">
                        <button v-if="$can('expenses.update')" @click="changeStatus(props.row.id, props.row.status)" class="btn btn-secondary m-btn m-btn--icon w-auto">
                            {{$t('change_status')}} ({{ props.row.status_name }})
                        </button>
                        <button v-else @click="changeStatus(props.row.id, props.row.status)" class="btn btn-secondary m-btn m-btn--icon w-auto">
                            {{$t('change_status')}} ({{ props.row.status_name }})
                        </button>
                    </template>
                    <template slot="actions" slot-scope="props">
                        <!--               -->
                        <v-btn icon color="pink" v-if="$can('expenses.update')" :to="`/finances/expenses/edit/${props.row.id}`">
                            <v-icon small class="mr-2 text-info" v-b-tooltip.hover :title="$t('edit')" color="blue darken-2">mdi-pencil</v-icon>
                        </v-btn>
                        <!--               -->
                        <v-icon small class="text-danger" v-b-tooltip.hover :title="$t('delete')" color="red darken-2" v-if="$can('expenses.delete') && props.row.status == 1" @click="deleteItem(props.row)">mdi-delete</v-icon>

                        <v-btn v-b-tooltip.hover :title="$t('view')" icon color="pink" :to="`/finances/expenses/view/${props.row.id}`">
                            <v-icon small class="mr-2">mdi-eye</v-icon>
                        </v-btn>
                    </template>
                </v-server-table>

            </div>
        </div>
        <!--end::customer-->
        <b-modal ref="status-modal" hide-footer :title="$t('change_status')">
            <change-status-form @hide-modal="hideModal()"
                                :status-list="status_list"
                                :url="routeChangeStatus"
                                :current-id="innerId"
                                :current-status="statusId"
                                @handling-data="getDataAfterChangeStatus">
            </change-status-form>
        </b-modal>
    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";
    import ChangeStatusFrom from "@/view/content/forms/ChangeStatusForm";

    export default {
        name: "index-expenses",
        components: {'change-status-form': ChangeStatusFrom,},
        data() {
            return {
                mainRoute: 'finances/expenses',
                routeChangeStatus: 'finances/expense/change-status',
                mainRouteDependency: 'base/dependency',

                showAdvancedSearch: false,

                filter: {
                    sortBy: 'id',
                },
                filters: {
                    name: null,
                    code: null,
                    currency_id: null,
                    from_date: null,
                    to_date: null,
                    status: null,
                    expenses_category_id: null,
                },
                status_list: [],
                columns: ['id','name', 'category', 'code', 'expenses_date', 'amount', 'currency', 'tax_name', 'status', 'actions'],
                data: [],
                currencies: [],
                expenses_categories: [],

                innerId: null,
                statusId: null,

            }
        },
        computed: {
            options: function () {
                let that = this;
                return {
                    texts: {
                        loadingError: that.$t('Something_went_wrong'),
                        filter: "",
                        filterBy: that.$t('Filter') + ' {column}',
                        count: ' ',
                        filterPlaceholder: that.$t('Search_query'),
                        loading: that.$t('Loading') + "...",
                    },
                    headings: {
                        id: that.$t('expenses.id'),
                        name: that.$t('expenses.name'),
                        category: that.$t('expenses.category'),
                        code: that.$t('expenses.code'),
                        expenses_date: that.$t('expenses.date'),
                        amount: that.$t('expenses.amount'),
                        currency: that.$t('expenses.currency'),
                        tax_name: that.$t('expenses.tax'),
                        status: that.$t('status'),
                        actions: that.$t('actions'),
                    },
                    sortable: ['name'],
                    filterByColumn: false,
                    filterable: false,
                    customFilters: [{
                        name: 'alphabet',

                    }],
                    orderBy: {'column': 'id'},

                    alwaysShowPerPageSelect: false,
                    perPage: 10,
                    pagination: {chunk: 5, dropdown: false},
                    skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
                    sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
                    perPageValues: [5, 10, 15, 25],

                    requestFunction(data) {
                        let _params = {
                            ascending: data.ascending,
                            byColumn: data.byColumn,
                          perPage: data.perPage,
                            orderBy: data.orderBy,
                            page: data.page,
                            filter: data.query,
                            ...that.filters,
                        }
                        return ApiService.query(`${that.mainRoute}`, {..._params});

                    },
                    responseAdapter(resp) {
                        return {
                            data: resp.data.data.data,
                            count: resp.data.data.total,
                        }
                    }
                }

            },
        },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.expenses_management")}]);
            this.getCurrencies();
            this.getStatusList();
            this.getCategories();
        },
        methods: {
            changeStatus(id, status) {
                this.innerId = id;
                this.statusId = status;
                this.showModal();
            },
            openAdvancedSearch() {
                this.showAdvancedSearch = !this.showAdvancedSearch;
            },
            doFilter() {
                this.$refs.table.refresh();
            },
            resetFilter() {
                this.filters.name = null;
                this.filters.currency_id = null;
                this.filters.code = null;
                this.filters.from_date = null;
                this.filters.to_date = null;
                this.filters.status = null;
                this.filters.expenses_category_id = null;

                this.$refs.table.refresh();
            },
            getFetch() {
                this.$refs.table.refresh();
            },

            deleteItem(item) {
                this.$confirmAlert('', this.actionDelete, item);
            },
            getCurrencies() {
                ApiService.get(this.mainRouteDependency + "/currencies").then((response) => {
                    this.currencies = response.data.data;
                });
            },
            getStatusList() {
                ApiService.get(this.mainRouteDependency + "/exp_status").then((response) => {
                    this.status_list = response.data.data;
                });
            },
            actionDelete(item) {
                ApiService.delete(`${this.mainRoute}/${item.id}`).then((response) => {
                    this.getFetch();
                    this.$successAlert(response.data.message)
                }).catch((error) => {
                    this.$errorAlert(error);
                })
            },
            getCategories() {
                ApiService.get(this.mainRouteDependency + "/expenses_categories").then((response) => {
                    this.expenses_categories = response.data.data;
                });
            },
            loadOptions() {
            },
            updateValue(value) {
                this.filters.expenses_category_id = value
            },

            showModal() {
                this.$refs['status-modal'].show()
            },
            hideModal() {
                this.$refs['status-modal'].hide();
            },
            getDataAfterChangeStatus() {
                this.innerId = null;
                this.statusId = null;
                this.doFilter();
            },
        },
    };
</script>
