<template>
  <div>

    <div class="card-toolbar mb-5 d-flex justify-content-between">
      <div>
      <router-link v-if="$can('treasury_checking.create')" to="/finances/treasury-checking/create" class="btn btn-primary font-weight-bolder">
          <v-icon>mdi-plus</v-icon>
          {{ $t('treasury_checking.add_treasury_checking')}}
      </router-link>
        <button @click="openAdvancedSearch" class="btn btn-outline-primary font-weight-bolder ml-5">
          <span><i class="la la-filter"></i> <span>{{ $t('advanced_filter') }}</span></span>
        </button>
      </div>
      <div>
        <router-link v-if="$can('treasury_checking.treasury_checking_history')" to="/finances/treasury-checking-history"
                     class="btn btn-primary font-weight-bolder h-100">
          {{ $t('MENU.treasury_checking_history') }}
        </router-link>
      </div>
    </div>

    <div class="card card-custom mb-5" v-if="showAdvancedSearch">
      <div class="card-body">
        <div class="m-form m-form--fit m--margin-bottom-20">
          <div class="row mb-5">

            <div class="col-lg-6 mb-5">
              <label>{{ $t('treasury_checking.treasury') }}</label>
              <multiselect v-model="treasury"
                           :placeholder="$t('treasury_checking.treasury')"
                           label="name"
                           track-by="id"
                           :options="treasuries"
                           :multiple="false"
                           :taggable="false"
                           :show-labels="false"
                           :show-no-options="false"
                           :show-no-results="false">
              </multiselect>
            </div>

            <div class="form-group col-md-6 mb-5">
              <label for="from_date">{{ $t('treasury_checking.from_date') }}</label>
              <input v-model="filters.from_date" type="date" id="from_date" class="form-control">
            </div>
            <div class="form-group col-md-6 mb-5">
              <label for="to_date">{{ $t('treasury_checking.to_date') }}</label>
              <input v-model="filters.to_date" type="date" id="to_date" class="form-control">
            </div>
            <!--                        <div class="form-group col-md-6 mt-2 mb-2">-->
            <!--                            <label for="last_validated_date">{{$t('treasury_checking.last_validated_date')}}</label>-->
            <!--                            <input v-model="filters.last_validated_date" type="date" id="last_validated_date" class="form-control">-->
            <!--                        </div>-->

            <div class="form-group col-md-6 mb-5">
              <label>{{ $t('treasury_checking.currency') }}</label>
              <select name="" id="currency_id" v-model="filters.currency_id" class="custom-select">
                <option v-for="row in currencies" :value="row.id" :key="row.id">{{ row.name }}</option>
              </select>
            </div>
            <!--                        <div class="form-group col-md-6 mb-5">-->
            <!--                            <label>{{$t('treasury_checking.validator_user')}}</label>-->
            <!--                            <multiselect v-model="user"-->
            <!--                                         :placeholder="$t('treasury_checking.validator_user')"-->
            <!--                                         label="name"-->
            <!--                                         track-by="id"-->
            <!--                                         :options="users"-->
            <!--                                         :multiple="false"-->
            <!--                                         :taggable="false"-->
            <!--                                         :show-labels="false"-->
            <!--                                         :show-no-options="false"-->
            <!--                                         :show-no-results="false"-->
            <!--                                         @search-change="getUsers($event)">-->
            <!--                            </multiselect>-->
            <!--                        </div>-->

            <div class="form-group d-inline-flex col-md-6 mt-6 mb-0">
              <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                            <span><i class="fas fa-search"></i>
                                <span>{{ $t('search') }}</span>
                            </span>
              </button>
              <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="m_reset">
                            <span><i class="fas fa-trash-restore"></i>
                                <span>{{ $t('reset_search') }}</span>
                            </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--begin::customer-->

    <div class="card card-custom">
      <div class="card-body">

        <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">


          <template slot="status" slot-scope="props">
            <button v-if="$can('treasury_checking.change_status')" @click="showModal(props.row.id, props.row.status)"
                    :key="props.row.id" class="btn btn-secondary m-btn m-btn--icon w-auto">
              {{ $t('change_status') }} ({{ props.row.status_name }})
            </button>
          </template>

          <template slot="actions" slot-scope="props">
            <template v-if="props.row.status != 2">
              <v-btn icon color="pink" v-if="$can('treasury_checking.update')"
                     :to="`/finances/treasury-checking/edit/${props.row.id}`">
                <v-icon small class="mr-2 text-info" v-b-tooltip.hover :title="$t('edit')">mdi-pencil</v-icon>
              </v-btn>
            </template>

          </template>
        </v-server-table>

      </div>
    </div>
    <b-modal ref="modal" size="md" hide-footer :title="$t('change_status')">
      <change-status-form @hide-modal="hideModal()"
                          :status-list="status_list"
                          :url="routeChangeStatus"
                          :current-id="innerId"
                          :current-status="statusId"
                          :ignore-selected-status="ignore_status_from_list[statusId]"
                          @handling-data="getDataAfterChangeStatus">
      </change-status-form>
    </b-modal>


  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import ChangeStatusFrom from "@/view/content/forms/ChangeStatusForm.vue";

export default {
  name: "index-treasury-checking",
  components: {'change-status-form': ChangeStatusFrom},
  data() {
    return {
      mainRoute: 'finances/treasury-checking',
      subMainRoute: 'finances/treasury-check',
      mainRouteDependency: 'base/dependency',
      routeChangeStatus: 'finances/treasury-check/change-status',

      showAdvancedSearch: false,

      filter: {
        sortBy: 'id',
      },
      filters: {
        treasury_id: this.$route.query.treasury_id ? this.$route.query.treasury_id : null,
        currency_id: null,
        from_date: null,
        to_date: null,
        // last_validated_date: null,
        // validator_user: null,
      },
      // status_list: [],
      // columns: ['treasury_name', 'current_amount', 'currency_name', 'balance_last_validated_date', 'actual_amount', 'actions'],
      columns: ['treasury_name', 'old_balance', 'new_balance', 'created_at', 'confirmed_date', 'status', 'actions'],
      data: [],
      treasury: null,
      user: null,
      treasuries: [],
      currencies: [],
      users: [],

      amount: null,
      is_increase_decrease: null, // 1 => increase, 2 => decrease
      treasury_validated_id: null,
      validation: null,
      status_list: [],
      innerId: null,
      statusId: null,
      ignore_status_from_list: {
        2: [1, 4],
      }

    }
  },
  watch: {
    treasury: function (val) {
      if (val) {
        this.filters.treasury_id = val.id;
      } else {
        this.filters.treasury_id = null;
      }
    },
    // user: function (val) {
    //   if (val) {
    //     this.filters.validator_user = val.id;
    //   } else {
    //     this.filters.validator_user = null;
    //   }
    // },
  },
  computed: {
    options: function () {
      let that = this;
      return {
        texts: {
          loadingError: that.$t('Something_went_wrong'),
          filter: "",
          filterBy: that.$t('Filter') + ' {column}',
          count: ' ',
          filterPlaceholder: that.$t('Search_query'),
          loading: that.$t('Loading') + "...",
        },
        headings: {
          treasury_name: that.$t('treasury_checking.treasury'),
          old_balance: that.$t('treasury_checking.old_balance'),
          new_balance: that.$t('treasury_checking.new_balance'),
          created_at: that.$t('treasury_checking.created_date'),
          confirmed_date: that.$t('treasury_checking.confirmed_date'),
          status: that.$t('status'),
          actions: that.$t('actions'),
        },
        sortable: ['name'],
        filterByColumn: false,
        filterable: false,
        customFilters: [{
          name: 'alphabet',

        }],
        orderBy: {'column': 'id'},

        alwaysShowPerPageSelect: false,
        perPage: 10,
        pagination: {chunk: 5, dropdown: false},
        skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
        sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
        perPageValues: [5, 10, 15, 25],

        requestFunction(data) {
          let _params = {
            ascending: data.ascending,
            byColumn: data.byColumn,
            limit: data.perPage,
            orderBy: data.orderBy,
            page: data.page,
            filter: data.query,
            ...that.filters,
          }
          return ApiService.query(`${that.mainRoute}`, {..._params});

        },
        responseAdapter(resp) {
          return {
            data: resp.data.data.data,
            count: resp.data.data.total,
          }
        }
      }

    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.treasury_checking")}]);
    this.getCurrencies();
    this.getTreasuries();
    this.getStatus();
  },
  methods: {
    openAdvancedSearch() {
      this.showAdvancedSearch = !this.showAdvancedSearch;
    },
    doFilter() {
      this.$refs.table.refresh();
    },
    resetFilter() {
      this.filters.treasury_id = null;
      this.filters.currency_id = null;
      this.filters.from_date = null;
      this.filters.to_date = null;
      // this.filters.last_validated_date = null;
      // this.filters.validator_user = null;
      // this.user = null;
      this.treasury = null;

      this.$refs.table.refresh();
    },
    getFetch() {
      this.$refs.table.refresh();
    },
    getCurrencies() {
      ApiService.get(this.mainRouteDependency + "/currencies").then((response) => {
        this.currencies = response.data.data;
      });
    },
    getTreasuries() {
      ApiService.get(this.mainRouteDependency + "/treasuries").then((response) => {
        this.treasuries = response.data.data;
        if (this.filters.treasury_id) {
          this.treasury = response.data.data.find((row) => row.id == this.filters.treasury_id);
        }
      });
    },
    // getUsers(filter) {
    //   if (filter && filter.length >= 3)
    //     ApiService.get(this.mainRouteDependency + "/users", {params: {filter: filter}}).then((response) => {
    //       this.users = response.data.data;
    //     });
    // },
    goToEdit(treasury_id, currency_id) {
      this.$router.push({
        name: 'treasury-checking.edit', query: {
          treasury_id: treasury_id,
          currency_id: currency_id,
        }
      });
    },
    getDataAfterChangeStatus() {
      this.innerId = null;
      this.statusId = null;
      this.doFilter();
    },
    showModal(id, status) {
      this.innerId = id;
      this.statusId = status;
      this.$refs['modal'].show();
    },
    hideModal() {
      this.$refs['modal'].hide();
      this.innerId = null;
      this.statusId = null;
    },
    getStatus() {
      ApiService.get(this.mainRouteDependency + "/status").then((response) => {
        this.status_list = response.data.data;
      });
    },
  //   makeApprove(treasury_validation_id) {
  //     this.$confirmAlert(this.$t('do_you_want_make_it_approved'), this.actionApprove, treasury_validation_id);
  //   },
  //
  //   actionApprove(treasury_validation_id) {
  //     ApiService.patch(`${this.subMainRoute}/approved/${treasury_validation_id}`).then((response) => {
  //       this.getFetch();
  //       this.$successAlert(response.data.message)
  //     }).catch((error) => {
  //       this.$errorAlert(error);
  //     })
  //   },
  //   actionIncreaseDecrease(treasury_validated_id, is_increase_decrease, amount) {
  //     this.amount = amount;
  //     this.treasury_validated_id = treasury_validated_id;
  //     this.is_increase_decrease = is_increase_decrease;
  //     this.showModal();
  //   },
  //   showModal() {
  //     this.$refs['increase-decrease'].show();
  //   },
  //   hideModal() {
  //     this.$refs['increase-decrease'].hide();
  //     this.amount = null;
  //     this.treasury_validated_id = null;
  //     this.is_increase_decrease = null;
  //   },
  //   saveIncreaseDecrease() {
  //     ApiService.patch(`${this.subMainRoute}/update_amount/${this.treasury_validated_id}`, {
  //       amount: this.amount,
  //       is_increase_decrease: this.is_increase_decrease,
  //     }).then((response) => {
  //       this.getFetch();
  //       this.hideModal();
  //       this.getFetch();
  //       this.$successAlert(response.data.message)
  //     }).catch((error) => {
  //       this.$errorAlert(error);
  //     })
  //   },
  },
};
</script>
