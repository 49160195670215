<template>
  <div id="invoice">
    <div v-if="show" :style="{'padding': '30px 15px','background-color': '#ffffff',
         'direction': (lang == 'ar'? 'rtl': 'ltr'), 'text-align': (lang == 'ar'? 'right': 'left') }">
      <h5 class="text-center">{{ $t('invoice_sales_print.sales_refund') }}</h5>
      <div class="text-center" style="margin: 10px auto;width: 70px;">
        <qr-code v-if="data.invoice_code" :text="data.invoice_code" :size="size"></qr-code>
      </div>
      <p class="text-center">{{ data.inventory ? data.inventory.name : '' }}</p>
      <p class="text-center">{{ (data.inventory && data.inventory.city_name) ? data.inventory.city_name + ',' : '' }} {{ data.inventory ? data.inventory.address_1 : '' }}</p>
      <p class="text-center">{{ data.inventory ? data.inventory.mobile : '' }}</p>

      <p><b>{{ $t('tax_no') }}:</b> <span>{{ data.tax_no }}</span></p>

      <h5>{{ $t('invoice_sales_print.to') }}:</h5>
      <p>{{ data.customer ? data.customer.name : '' }}</p>
      <p>{{ (data.customer && data.customer.city_name) ? data.customer.city_name : '' }} </p>
      <p>{{ (data.customer && data.customer.city_name) ? data.customer.city_name + ',' : '' }} {{ (data.customer) ? data.customer.postal_code : '' }}</p>

      <p><b>{{ $t('invoice_sales_print.invoice_refund_no') }}</b> <span>{{ data.id }}</span></p>
      <p><b>{{ $t('invoice_sales_print.invoice_refund_date') }}</b> <span>{{ data.invoice_refund_receipt_date }}</span></p>

      <table class="table" style="margin-top: 15px">
        <thead>
        <tr style="background:#CAD3C8">
          <th>{{ $t('invoice_sales_print.item') }}</th>
          <th>{{ $t('invoice_sales_print.price') }}</th>
          <th>{{ $t('invoice_sales_print.quantity') }}</th>
          <th>{{ $t('invoice_sales_print.total') }}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(row, index) in items_list" :key="index">
          <td style="overflow: hidden;"><span style="width:50px;display:block;word-wrap: unset;white-space: nowrap;text-overflow: ellipsis;">{{ row.description }}</span></td>
          <td>{{ row.unit_price }}</td>
          <td>{{ row.qty }}</td>
          <td>{{ row.subtotal_before_tax }}</td>
        </tr>
        </tbody>
      </table>


      <table class="table" style="margin-top: 15px">
        <tbody>
        <tr>
          <th>{{ $t('invoice_sales_print.sub_total') }}</th>
          <td>{{ data.currency_name }} {{ data.invoice_total_before_taxes }}</td>
        </tr>
        <tr>
          <th>{{$t('invoice_sales_print.value_added')}}</th>
          <td>{{data.currency_name}} {{data.total_taxes}}</td>
        </tr>
        <tr style="background:#CAD3C8">
          <th>{{ $t('invoice_sales_print.total') }}</th>
          <td>{{ data.currency_name }} {{ data.invoice_refund_total }}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import {jsPDF} from "jspdf";
import html2canvas from "html2canvas";
import VueQRCodeComponent from 'vue-qrcode-component';
import jsPdfExport from "@/core/config/jsPdfExport";

export default {
  name: "print-general-sales-refund",
  components: {
    'qr-code': VueQRCodeComponent
  },
  data() {
    return {
      lang: this.$i18n.locale,
      mainRoute: 'sales/general_sales_refunds',
      general_sales_invoice_id: this.$route.params.general_sales_invoice_id ? this.$route.params.general_sales_invoice_id : null,
      type: this.$route.params.type ? this.$route.params.type : null,
      data: {},
      items_list: [],
      size: 70,
      show: false,
    }
  },
  computed:{
    fileName: function (){
      let _invoice_label = this.$i18n.locale ==  'ar' ? 'مرتجع فاتورة مبيعات' : 'Sales invoice refund';
      let _customer_name = (this.data.customer && this.data.customer.fullname) ? this.data.customer.fullname : '';
      let _invoice_code = (this.data.invoice_code) ? this.data.invoice_code : '';
      return `${_invoice_label} - ${_customer_name} - ${_invoice_code}`;
    }
  },
  methods: {
    async getData() {
      await ApiService.get(`${this.mainRoute}/${this.general_sales_invoice_id}/print`).then((response) => {
        this.data = response.data.data;
        this.items_list = response.data.data.items_list;
        this.show = true;
      });
    },
    printCustomerInvoice() {
      // let element = document.getElementById('invoice');
      // let width = element.clientWidth - 20;
      // let height = element.clientHeight;
      // // let check_lang = this.lang == 'ar'? 'arabic': 'english';
      // let pdf = new jsPDF({
      //   orientation: 'p',
      //   unit: 'px',
      //   format: [width, height]
      // });
      // html2canvas(element).then(canvas => {
      //   let image = canvas.toDataURL('image/png', 1.0);
      //   pdf.addImage(image, 'PNG', 40, 15);
      //   //  pdf.save(`${ 'sales-refund '}${check_lang}${'.pdf'}`);
      //   pdf.autoPrint();
      //   let _url = pdf.output("bloburl");
      //   location.href = _url;
      // });
      let _id = 'invoice';
      this.exportPDF(_id);
    },
    exportPDF(_id) {
      let that = this;
      let element = document.getElementById(_id);
      let width = element.clientWidth - 205;
      let height = element.clientHeight;

      // let pdf = new jsPDF({
      //   orientation: 'p',
      //   unit: 'px',
      //   format: [width, height]
      // });
      //
      // const AmiriRegular = pdf.loadFile("https://rawcdn.githack.com/MrRio/jsPDF/cbc85b4bb66d5c1a2d50d199e8bf33836f2f12d7/test/reference/Amiri-Regular.ttf", true);
      // pdf.addFileToVFS("Amiri-Regular.ttf", AmiriRegular);
      //
      // pdf.addFont("Amiri-Regular.ttf", "Amiri", "normal");
      let pdf = jsPdfExport("p", "px", [width, height]);
      pdf.html(element, {
        html2canvas: {
          scale: 0.5,
        },
        callback: function (pdf) {
          if (that.type == 'pdf') {
            pdf.save(that.fileName);
          } else {
            pdf.autoPrint();
            pdf.output('dataurlnewwindow');
          }

          // var iframe = document.createElement('iframe');
          // iframe.setAttribute('style', 'position:absolute;top:0;right:0;height:100%; width:600px');
          // document.body.appendChild(iframe);
          // iframe.src = pdf.output('datauristring');
        }
      });
    },
  },
  mounted() {
    let promise = this.getData();
    Promise.all([promise]).then(() => {
      this.printCustomerInvoice();
    });

    // window.print();
  }
}
</script>

<style>
#invoice {
  font-family: "Amiri";
  width: 412px;
}
html, body {
  width: 312px;
  margin: 0 auto;
}

table, thead, tr, th, tbody, td {
  border: 1px solid #000 !important;
}
.ql-editor img{
  max-width: 100%;
}
</style>