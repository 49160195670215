<template>

  <div>
    <!--begin::customer-->

    <h3 class="card-label">
      {{ $t('MENU.financial_list') }}
      <span class="text-muted pt-2 font-size-sm d-block"></span>
    </h3>

    <div class="row mt-3">
      <div class="col-md-6">
        <div class="card card-custom">
          <div class="card-body">
            <div class="form-group row">
              <div class="col-lg-6 mb-5">
                <label>{{ $t('financial_list.name') }}<span class="text-danger">*</span></label>
                <input type="text" v-model="data.name" class="form-control" :class="validation && validation.name ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.name" class="fv-plugins-message-container invalid-feedback">
                    {{ validation.name[0] }}
                </span>
              </div>

              <div class="col-lg-6 mb-5">
                <label>{{ $t('financial_list.type') }}<span class="text-danger">*</span></label>
                <select name="" id="type" v-model="data.type" class="custom-select">
                  <option v-for="row in types_list" :value="row.id" :key="row.id">{{ row.title }}</option>
                </select>
                <span v-if="validation && validation.type" class="fv-plugins-message-container invalid-feedback">
                    {{ validation.type[0] }}
                </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('financial_list.slug') }}</label>
                <input type="text" v-model="data.slug" :disabled="true" class="form-control" :class="validation && validation.slug ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.slug" class="fv-plugins-message-container invalid-feedback">
                    {{ validation.slug[0] }}
                </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('status') }}</label>
                <b-form-checkbox size="lg" v-model="data.is_active" :name="'is_active'" switch>
                </b-form-checkbox>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('financial_list.is_custom_interval') }}</label>
                <b-form-checkbox size="lg" v-model="data.is_custom_interval" :name="'is_custom_interval'" switch>
                </b-form-checkbox>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="col-md-6">
        <div class="card card-custom">
          <div class="card-body">
            <div class="form-group row">
              <div class="col-lg-12 mb-5">
                <label>{{ $t('financial_list.users') }}</label>
                <multiselect v-model="users_objs"
                             :placeholder="$t('financial_list.users')"
                             label="name"
                             track-by="id"
                             :options="users"
                             :multiple="true"
                             :taggable="false"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false"
                             @search-change="getUsers($event)"
                             @input="changeToGetData">
                </multiselect>
                <span v-if="validation && validation.bank_id" class="fv-plugins-message-container invalid-feedback">
                    {{ validation.bank_id[0] }}
                </span>
              </div>
              <div class="col-lg-12 mb-5">
                <label>{{ $t('financial_list.description') }}</label>
                <textarea v-model="data.description" rows="6" class="form-control"></textarea>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>



    <div class="row mt-10">
      <div class="col-lg-6">
        <button class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
      </div>
    </div>
    <!--end::customer-->
  </div>
</template>
<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "form-financials-list",
  data() {
    return {
      mainRoute: 'accounting/financials-list',
      mainRouteDependency: 'base/dependency',

      data: {
        name: null,
        slug: null,
        type: null,
        user_ids: [],
        is_active: true,
        is_custom_interval: false,
        description: null,
      },

      idEditing: this.$route.params.id ? this.$route.params.id : null,
      isEditing: false,
      validation: null,
      users: [],
      types_list: [],
      users_objs: [],

    };
  },

  methods: {
    save() {
      if (this.isEditing) {
        this.update();
      } else {
        this.create();
      }
    },

    create() {
      ApiService.post(`${this.mainRoute}`, {
        ...this.data,
      })
          .then((response) => {
            this.validation = null;
            this.$successAlert(response.data.message);
            this.goBackIndex();
          })
          .catch((error) => {
            this.$errorAlert(error);
            this.validation = error.response ? error.response.data.errors : null;
          });
    },


    update() {
      ApiService.put(`${this.mainRoute}/${this.$route.params.id}`, {
        ...this.data,
      }).then((response) => {
        this.validation = null;
        this.goBackIndex();
        this.$successAlert(response.data.message);
      })
          .catch((error) => {
            this.$errorAlert(error);
            this.validation = error.response ? error.response.data.errors : null;
          });
    },
    goBackIndex() {
      this.$router.push({
        name: 'financial-list.index'
      });
    },
    async getData() {

      ApiService.get(`${this.mainRoute}/${this.$route.params.id}`).then((response) => {
        this.isEditing = true;
        this.data.name = response.data.data.name;
        this.data.slug = response.data.data.slug;
        this.data.type = response.data.data.type;
        this.data.user_ids = response.data.data.user_ids;
        this.data.is_active = response.data.data.is_active;
        this.data.is_custom_interval = response.data.data.is_custom_interval;
        this.data.description = response.data.data.description;
        this.users_objs = response.data.data.users;
      });
    },


    getTypes() {
      ApiService.get(this.mainRouteDependency + "/financial_types").then((response) => {
        this.types_list = response.data.data;
      });
    },
    getUsers(filter) {
      if (filter && filter.length >= 3) {
        ApiService.get(`${this.mainRouteDependency}/users`, {params: {filter: filter}}).then((response) => {
          this.users = response.data.data;
        });
      }
    },

    changeToGetData(){
      this.data.user_ids = this.users_objs.map((_user) => _user.id);
    }
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.bank_checking"), route: '/finances/bank-checking'}, {title: (this.idEditing ? this.$t('update') : this.$t('create'))}]);
    this.getTypes();
    if (this.idEditing) {
      this.getData();
    }

  },
};
</script>