<template>
  <div>
    <b-card class="mb-5" no-body>
      <b-card-header class="p-1" header-tag="header" role="tab">
        <b-button v-b-toggle.accordion-2 block
                  class="text-left font-weight-bold text-dark pl-8 pr-8 fs-102 collapsed-with-icon"
                  variant="link">
          {{ $t('advanced_filter') }}
        </b-button>
      </b-card-header>
      <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel" visible>
        <b-card-body>
          <div class="m-form m-form--fit m--margin-bottom-20">
            <div class="row mb-5">
              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="item_id">{{ $t('product') }}</label>
                <div class="d-flex search-item-group">
                  <select v-model="f_by" class="custom-select" name="" style="width: 100px;">
                    <option selected value="name">{{ $t('items.name') }}</option>
                    <option value="sku_code">{{ $t('items.sku_code') }}</option>
                  </select>
                  <multiselect id="item_id"
                               v-model="items_ids"
                               :internal-search="false"
                               :multiple="true"
                               :options="items"
                               :label="f_by?f_by:'sku_code'"
                               :placeholder="$t('product')"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false"
                               :taggable="false"
                               track-by="id"
                               @input="getIds('items')"
                               @search-change="getItems(f_by, $event)">
                  </multiselect>
                </div>
              </div>
              <div class="form-group col-md-6 mt-2 mb-2">
                <label>{{ $t('customer_transactions_report.branches') }}</label>
                <multiselect v-model="branches_ids"
                             :placeholder="$t('customer_transactions_report.branches')"
                             label="name"
                             track-by="id"
                             :options="branches"
                             :multiple="true"
                             @input="getIds('branches')"
                             :taggable="false"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false">
                </multiselect>
              </div>

              <div class="form-group col-md-6 mt-2 mb-2">
                <label>{{ $t('customer_transactions_report.invoice_type') }}</label>
                <select name="" id="invoice_type" v-model="filters.invoice_type" class="custom-select">
                  <option v-for="row in invoice_type_list" :disabled="row.disabled" :value="row.key" :key="row.key">
                    {{ row.name }}
                  </option>
                </select>
              </div>
              <div class="form-group col-md-6 mt-2 mb-2">
                <label>{{ $t('customer_transactions_report.status') }}</label>
                <multiselect
                    v-model="status_ids"
                    :placeholder="$t('customer_transactions_report.status')"
                    label="title"
                    track-by="id"
                    :options="status_list"
                    :multiple="true"
                    @input="getIds('status')"
                    :taggable="false"
                    :show-labels="false"
                    :show-no-options="false"
                    :show-no-results="false">
                </multiselect>
              </div>
              <div class="form-group col-md-6 mt-10 mb-2">
                <b-form-checkbox id="all_items" v-model="filters.is_all_items" @input="getAllIds('items')"
                                 name="all_items" :value="1" :unchecked-value="0">
                  {{ $t('customer_transactions_report.all_items') }}
                </b-form-checkbox>
                <b-form-checkbox id="all_branches" v-model="filters.is_all_branches" @input="getAllIds('branches')"
                                 name="all_branches" :value="1" :unchecked-value="0">
                  {{ $t('customer_transactions_report.all_branches') }}
                </b-form-checkbox>

              </div>

              <div class="form-group col-md-6 mt-2 mb-2">
                <label>{{ $t('items.category') }}</label>
                <treeselect
                    :load-options="loadOptions"
                    :multiple="true"
                    :no-children-text="$t('No_sub_options')"
                    :no-options-text="$t('No_options_available')"
                    :no-results-text="$t('No_results_found')"
                    :options="categories"
                    :placeholder="$t('Select')"
                    :searchable="true"
                    :show-count="true"
                    :value="filters.categories_ids"
                    @input="updateValue">
                </treeselect>
              </div>

              <div class="form-group col-md-6 mt-2 mb-2">
                <label>{{ $t('customer_transactions_report.inventories') }}</label>
                <multiselect v-model="inventories_ids"
                             :placeholder="$t('customer_transactions_report.inventories')"
                             label="name"
                             track-by="id"
                             :options="inventories"
                             :multiple="true"
                             @input="getIds('inventories')"
                             :taggable="false"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false">
                </multiselect>
              </div>


              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="from_date">{{ $t('inventory_transaction_report.from_date') }}</label>
                <input id="from_date" v-model="filters.from_date" class="form-control" type="date">
              </div>

              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="to_date">{{ $t('inventory_transaction_report.to_date') }}</label>
                <input id="to_date" v-model="filters.to_date" class="form-control" type="date">
              </div>

              <!--              <div class="form-group col-md-3 mt-2 mb-2">-->
              <!--                <label>{{ $t('customer_transactions_report.interval_type') }}</label>-->
              <!--                <select name="" id="period" v-model="filters.period" @input="changeIntervalType" class="custom-select">-->
              <!--                  <option v-for="row in period_date" :value="row" :key="row">{{ $t(row) }}</option>-->
              <!--                </select>-->
              <!--              </div>-->
              <!--              <div class="form-group col-md-3 mt-2 mb-2">-->
              <!--                <label>{{ $t('customer_transactions_report.interval_no') }}</label>-->
              <!--                <select name="" id="range" v-model="filters.range" class="custom-select">-->
              <!--                  <option v-for="row in period_counter" :value="row" :key="row">{{ row }}</option>-->
              <!--                </select>-->
              <!--              </div>-->
              <!--              <div class="col-md-6 mt-2 mb-2 pt-10">-->
              <!--                <div class="d-flex justify-space-between">-->
              <!--                  <b-form-radio id="current_date" v-model="filters.period" @input="changeIntervalType" class="w-100" name="period" value="day">-->
              <!--                    {{$t('customer_transactions_report.current_date')}}-->
              <!--                  </b-form-radio>-->
              <!--                  <b-form-radio id="current_week" v-model="filters.period" @input="changeIntervalType" class="w-100" name="period" value="week">-->
              <!--                    {{$t('customer_transactions_report.current_week')}}-->
              <!--                  </b-form-radio>-->
              <!--                  <b-form-radio id="current_month" v-model="filters.period" @input="changeIntervalType" class="w-100" name="period" value="month">-->
              <!--                    {{$t('customer_transactions_report.current_month')}}-->
              <!--                  </b-form-radio>-->
              <!--                  <b-form-radio id="current_year" v-model="filters.period" @input="changeIntervalType" class="w-100" name="period" value="year">-->
              <!--                    {{$t('customer_transactions_report.current_year')}}-->
              <!--                  </b-form-radio>-->
              <!--                  <b-form-checkbox id="financial_year" v-model="filters.financial_year" class="w-100"  name="financial_year" :value="1" :unchecked-value="0">-->
              <!--                    {{$t('customer_transactions_report.financial_year')}}-->
              <!--                  </b-form-checkbox>-->
              <!--                  &lt;!&ndash;                  <b-form-radio id="financial_year" v-model="filters.period" @input="changeIntervalType" class="w-100" name="period" value="financial_year">&ndash;&gt;-->
              <!--                  &lt;!&ndash;                    {{$t('customer_transactions_report.financial_year')}}&ndash;&gt;-->
              <!--                  &lt;!&ndash;                  </b-form-radio>&ndash;&gt;-->
              <!--                </div>-->
              <!--              </div>-->
              <div class="col-md-6 mt-2 mb-2 pt-10">
                <div>
                  <b-button-group>
                    <b-button @click="changeIntervalType('current_day')"
                              :variant="filters.period=='current_day'? 'primary' : 'outline-primary'">
                      {{ $t('customer_transactions_report.current_date') }}
                    </b-button>
                    <b-button @click="changeIntervalType('current_month')"
                              :variant="filters.period=='current_month'? 'primary' : 'outline-primary'">
                      {{ $t('customer_transactions_report.current_month') }}
                    </b-button>
                    <b-button @click="changeIntervalType('current_year')"
                              :variant="filters.period=='current_year'? 'primary' : 'outline-primary'">
                      {{ $t('customer_transactions_report.current_year') }}
                    </b-button>
                    <b-button @click="changeIntervalType('financial_year')"
                              :variant="filters.period=='financial_year'? 'primary' : 'outline-primary'">
                      {{ $t('customer_transactions_report.financial_year') }}
                    </b-button>
                    <!--                    <b-button  @click="changeFinancialYear" :variant="filters.financial_year==1? 'primary' : 'outline-primary'">{{$t('customer_transactions_report.financial_year')}}</b-button>-->
                  </b-button-group>

                </div>
                <div class="mt-5">
                  <b-button-group>
                    <b-button @click="changeIntervalType('yesterday')"
                              :variant="filters.period=='yesterday'? 'primary' : 'outline-primary'">
                      {{ $t('customer_transactions_report.yesterday') }}
                    </b-button>
                    <b-button @click="changeIntervalType('last_month')"
                              :variant="filters.period=='last_month'? 'primary' : 'outline-primary'">
                      {{ $t('customer_transactions_report.last_month') }}
                    </b-button>
                    <b-button @click="changeIntervalType('last_year')"
                              :variant="filters.period=='last_year'? 'primary' : 'outline-primary'">
                      {{ $t('customer_transactions_report.last_year') }}
                    </b-button>
                  </b-button-group>

                </div>
              </div>
              <div class="form-group d-inline-flex col-md-4 mt-10">
                <b-button class="mt-auto mr-2" variant="primary" @click="doFilter"> {{ $t('display_report') }}
                </b-button>
                <b-button class="mt-auto mr-2" variant="danger" @click="resetFilter"><i
                    class="fas fa-trash-restore"></i> {{ $t('reset_search') }}
                </b-button>

              </div>
            </div>
          </div>
        </b-card-body>
      </b-collapse>
    </b-card>

    <!--begin::customer-->
    <div class="card card-custom mt-5">
      <div class="card-body">
        <div class="m-form m-form--fit m--margin-bottom-20">
          <b-tabs content-class="mt-3">
            <b-tab :title="$t('summery')" :active="tab == 'summery' || tab == null">
              <!--              <div class="row">-->
              <!--                <div class="col-md-12 mt-10">-->
              <!--                  <DailyProductSalesChart :currency-name="currency_name" :data-line-invoice.sync="daily_sales"/>-->
              <!--                </div>-->
              <!--              </div>-->
              <div class="row">
                <div id="summaryTableTitle" class="col-12 mt-10">
                  <h4 class="text-center">{{ $t('daily_product_sales.daily_product_sales') }}</h4>
                  <h5 class="text-center"><span v-if="filters.from_date">{{ $t('from') }}
                      {{ filters.from_date }}</span> <span v-if="filters.to_date">{{ $t('to') }}
                      {{ filters.to_date }}</span>
                  </h5>
                </div>
                <div class="col-12 mt-10 d-flex justify-content-end">
                  <b-dropdown :text="$t('export')" class="mr-2 ml-2" right>
                    <!--                    <download-excel-->
                    <!--                        :fetch="dataReport"-->
                    <!--                        :fields="json_fields"-->
                    <!--                        :name="$t('daily_product_sales.daily_product_sales')+'.xls'"-->
                    <!--                        class="dropdown-item">-->
                    <!--                      <i class="la la-file-excel"></i>{{ $t('excel') }}-->
                    <!--                    </download-excel>-->
                    <button class="dropdown-item" @click="convertTableToExcelReport('summaryTable')">
                      <i class="la la-file-excel"></i>{{ $t('excel') }}
                    </button>
                    <button class="dropdown-item" @click="printData('summaryTable', 'print')">
                      <span><i class="fa fa-print"></i> <span>{{ $t('print') }}</span></span>
                    </button>
                    <button class="dropdown-item" @click="printPDFData('summaryTable', 'pdf')">
                      <span><i class="fa fa-file-pdf"></i> <span>{{ $t('export_pdf') }}</span></span>
                    </button>
                    <button class="dropdown-item"
                            @click="getAllDataForExportSummery('sectionForExportSummaryTable', 'excel')">
                      <span>
                        <i class="fa fa-file-excel"></i> {{ $t('all_data') }}
                      </span>
                    </button>
                  </b-dropdown>
                </div>
                <div class="col-12 mt-10">
                  <div class="table-responsive">
                    <table id="summaryTable" class="table table-bordered text-center">
                      <thead>
                      <tr>
                        <th>{{ $t('daily_product_sales.item') }}</th>
                        <th>{{ $t('daily_product_sales.code') }}</th>
                        <th>{{ $t('daily_product_sales.quantity') }}</th>
                        <th>{{ $t('daily_product_sales.total_amount') }}</th>
                        <th>{{ $t('daily_product_sales.subtotal_before_tax') }}</th>
                        <th>{{ $t('daily_product_sales.total_tax') }}</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(row, index) in dataSummery" :key="index">
                        <td>{{ row.item_name }}</td>
                        <td>{{ row.sku_code }}</td>
                        <td>{{ row.item_qty }}</td>
                        <td>{{ row.total_amount }}</td>
                        <td>{{ row.subtotal_before_tax }}</td>
                        <td>{{ row.total_tax }}</td>
                      </tr>
                      </tbody>
                      <tbody>
                      <!--                      <tr v-if="dataTotalSummery">-->
                      <!--                        <td colspan="2" style="text-align: start;">{{ $t('total') }}</td>-->
                      <!--                        <td>{{ dataTotalSummery.qty }}</td>-->
                      <!--                        <td>{{ dataTotalSummery.amount }}</td>-->
                      <!--                      </tr>-->
                      </tbody>
                    </table>
                  </div>
                  <div v-if="page_summery" class="col-12 text-center">
                    <button class="btn btn-warning" @click="loadMoreSummery">
                      <p class="mb-0">
                        <b-icon font-scale="1" icon="arrow-counterclockwise"></b-icon>
                        {{ $t('load_more') }}
                      </p>
                    </button>
                  </div>

                </div>
              </div>
            </b-tab>

            <b-tab :title="$t('details')" :active="tab == 'details'">
              <!--              <div class="row">-->
              <!--                <div class="col-md-12 mt-10">-->
              <!--                  <DailyProductSalesChart :currency-name="currency_name" :data-line-invoice.sync="daily_sales"/>-->
              <!--                </div>-->
              <!--              </div>-->
              <div class="row">
                <div id="summaryTableTitle" class="col-12 mt-10">
                  <h4 class="text-center">{{ $t('daily_product_sales.daily_product_sales_details') }}</h4>
                  <h5 class="text-center"><span v-if="filters.from_date">{{ $t('from') }}
                      {{ filters.from_date }}</span> <span v-if="filters.to_date">{{ $t('to') }}
                      {{ filters.to_date }}</span>
                  </h5>
                </div>
                <div class="col-12 mt-10 d-flex justify-content-end">
                  <b-dropdown :text="$t('export')" class="mr-2 ml-2" right>
                    <!-- <download-excel
                            class="dropdown-item"
                            :fetch="dataReportDetails"
                            :fields="json_fields_details"
                            :name="$t('daily_product_sales.daily_product_sales_details')+'.xls'">
                        <i class="la la-file-excel"></i>{{$t('excel')}}
                    </download-excel> -->
                    <button class="dropdown-item" @click="convertTableToExcelReport('summaryTableDetails')">
                      <i class="la la-file-excel"></i>{{ $t('excel') }}
                    </button>
                    <button class="dropdown-item" @click="printData('summaryTableDetails', 'print')">
                      <span><i class="fa fa-print"></i> <span>{{ $t('print') }}</span></span>
                    </button>
                    <button class="dropdown-item" @click="printPDFData('summaryTableDetails', 'pdf')">
                      <span><i class="fa fa-file-pdf"></i> <span>{{ $t('export_pdf') }}</span></span>
                    </button>
                    <button class="dropdown-item" @click="getAllDataForExport('sectionForExportDetailsTable', 'excel')">
                      <span>
                        <i class="fa fa-file-excel"></i> {{ $t('all_data') }}
                      </span>
                    </button>
                  </b-dropdown>
                </div>
                <div class="col-12 mt-10">
                  <div class="table-responsive">
                    <table id="summaryTableDetails" class="table table-bordered">
                      <thead>
                      <tr id="myRow">
                        <th>#</th>
                        <th>{{ $t('daily_product_sales.code') }}</th>
                        <th>{{ $t('daily_product_sales.branch') }}</th>
                        <th>{{ $t('daily_product_sales.inventory') }}</th>
                        <th>{{ $t('daily_product_sales.quantity') }}</th>
                        <th>{{ $t('daily_product_sales.total_amount') }}</th>
                        <th>{{ $t('daily_product_sales.subtotal_before_tax') }}</th>
                        <th>{{ $t('daily_product_sales.total_tax') }}</th>
                      </tr>
                      </thead>
                      <tbody>
                      <template v-for="(row, index) in data">
                        <tr>
                          <td colspan=""></td>
                          <td colspan="">{{ row.sku_code }}</td>
                          <td colspan="6">{{ row.item_name }}</td>
                        </tr>
                        <tr v-for="(row2, index2) in row.invoices" :key="'c'+getRandom()+index2">
                          <td>{{ index2 + 1 }}</td>
                          <td>{{ row2.invoice_code }}</td>
                          <td>{{ row2.branch_name }}</td>
                          <td>{{ row2.inventory_name }}</td>
                          <td>{{ row2.item_qty }}</td>
                          <td>{{ row2.total_amount }}</td>
                          <td>{{ row2.subtotal_before_tax }}</td>
                          <td>{{ row2.total_tax }}</td>
                        </tr>
                        <tr>
                          <td colspan="4">{{ $t('daily_brief_report_invoices.total') }}</td>
                          <td>{{ row.total_qty }}</td>
                          <td>{{ row.total_amount }}</td>
                          <td></td>
                          <td></td>
                        </tr>
                      </template>

                      </tbody>
                      <!-- <tr>
                          <td colspan="6"><b>{{$t('total')}}</b></td>
                          <th>{{details_total_sum}}</th>
                      </tr> -->
                      <tfoot>
                      <tr v-if="dataTotal">
                        <td colspan="4">{{ $t('total') }}</td>
                        <th>{{ dataTotal.qty }}</th>
                        <th>{{ dataTotal.amount }}</th>
                        <td></td>
                        <td></td>
                      </tr>
                      </tfoot>
                    </table>
                  </div>

                  <div v-if="page" class="col-12 text-center">
                    <button class="btn btn-warning" @click="loadMore">
                      <p class="mb-0">
                        <b-icon font-scale="1" icon="arrow-counterclockwise"></b-icon>
                        {{ $t('load_more') }}
                      </p>
                    </button>
                  </div>


                </div>
              </div>
            </b-tab>

            <!-- <b-tab> -->

            <!-- </b-tab> -->

          </b-tabs>
        </div>
      </div>

    </div>
    <div class="export-container">
      <div class="section-for-export">
        <table id="sectionForExportSummaryTable" class="table table-bordered text-center">
          <thead>
          <tr>
            <th>{{ $t('daily_product_sales.item') }}</th>
            <th>{{ $t('daily_product_sales.code') }}</th>
            <th>{{ $t('daily_product_sales.quantity') }}</th>
            <th>{{ $t('daily_product_sales.total_amount') }}</th>
            <th>{{ $t('daily_product_sales.subtotal_before_tax') }}</th>
            <th>{{ $t('daily_product_sales.total_tax') }}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(row, index) in dataListSummery" :key="index">
            <td>{{ row.item_name }}</td>
            <td>{{ row.sku_code }}</td>
            <td>{{ row.item_qty }}</td>
            <td>{{ row.total_amount }}</td>
            <td>{{ row.subtotal_before_tax }}</td>
            <td>{{ row.total_tax }}</td>
          </tr>
          </tbody>
          <tbody>
          <!--          <tr v-if="dataTotalListSummery">-->
          <!--            <td colspan="2" style="text-align: start;">{{ $t('total') }}</td>-->
          <!--            <td>{{ dataTotalListSummery.qty }}</td>-->
          <!--            <td>{{ dataTotalListSummery.amount }}</td>-->
          <!--          </tr>-->
          </tbody>
        </table>
        <table id="sectionForExportDetailsTable" class="table table-bordered">
          <thead>
          <tr id="myRow">
            <th>#</th>
            <th>{{ $t('daily_product_sales.code') }}</th>
            <th>{{ $t('daily_product_sales.branch') }}</th>
            <th>{{ $t('daily_product_sales.inventory') }}</th>
            <th>{{ $t('daily_product_sales.quantity') }}</th>
            <th>{{ $t('daily_product_sales.total_amount') }}</th>
            <th>{{ $t('daily_product_sales.subtotal_before_tax') }}</th>
            <th>{{ $t('daily_product_sales.total_tax') }}</th>
          </tr>
          </thead>
          <tbody>
          <template v-for="(row, index) in dataList">
            <tr>
              <td colspan=""></td>
              <td colspan="">{{ row.sku_code }}</td>
              <td colspan="6">{{ row.item_name }}</td>
            </tr>
            <tr v-for="(row2, index2) in row.invoices" :key="'c'+getRandom()+index2">
              <td>{{ index2 + 1 }}</td>
              <td>{{ row2.invoice_code }}</td>
              <td>{{ row2.branch_name }}</td>
              <td>{{ row2.inventory_name }}</td>
              <td>{{ row2.item_qty }}</td>
              <td>{{ row2.total_amount }}</td>
              <td>{{ row2.subtotal_before_tax }}</td>
              <td>{{ row2.total_tax }}</td>
            </tr>
            <tr>
              <td colspan="4">{{ $t('daily_brief_report_invoices.total') }}</td>
              <td>{{ row.total_qty }}</td>
              <td>{{ row.total_amount }}</td>
              <td></td>
              <td></td>
            </tr>
          </template>

          </tbody>
          <!-- <tr>
              <td colspan="6"><b>{{$t('total')}}</b></td>
              <th>{{details_total_sum}}</th>
          </tr> -->
          <tfoot>
          <tr v-if="dataTotalList">
            <td colspan="4">{{ $t('total') }}</td>
            <th>{{ dataTotalList.qty }}</th>
            <th>{{ dataTotalList.amount }}</th>
            <td></td>
            <td></td>
          </tr>
          </tfoot>
        </table>
      </div>
    </div>

    <div id="selectorExport" class="selector-export"></div>
    <!--end::customer-->

    <div class="export-container">
      <div class="section-for-export" id="sectionForExportHeader">
        <report-main-header :print-type="'DailyProductSales'"></report-main-header>
      </div>
    </div>

  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import DailyProductSalesChart from '../components/dailyProductSalesChart.vue'
import TableToExcel from "@linways/table-to-excel";
import exportPDFInnerAction from "@/core/plugins/export-jspdf-inner-action";
import ReportMainHeader from "@/view/content/printing-heads/ReportMainHeader.vue";
import cssStypeForPrintReport from "@/core/config/mix/cssStypeForPrintReport";

export default {
  name: "index-sales-by-product-report",
  components: {ReportMainHeader, DailyProductSalesChart},

  data() {
    return {
      mainRoute: '/reports/item-sales-report',
      mainRouteSummery: '/reports/item-sales-report/summary',
      mainRouteDependency: 'base/dependency',
      tab: this.$route.query.tab ? this.$route.query.tab : null,
      items: [],
      inventories: [],
      branches: [],
      data: [],
      dataTotal: [],
      dataSummery: [],
      dataTotalSummery: [],
      categories: [],
      data_more_details: [],
      details_total_sum: [],

      dataList: [],
      dataTotalList: [],
      dataListSummery: [],
      dataTotalListSummery: [],

      filters: {
        items_ids: [],
        branches_ids: [],
        inventories_ids: [],
        categories_ids: [],
        status_ids: [],
        invoice_type: null,
        is_all_items: 1,
        is_all_branches: 1,
        from_date: null,
        to_date: null,

        period: null,
        range: null,
        f_time_period: 0,
        financial_year: 0,
      },
      items_ids: [],
      branches_ids: [],
      inventories_ids: [],
      categories_ids: [],
      status_ids: [],
      status_list: [],
      invoice_type_list: [
        {key: 'sales_invoice', name: this.$t('invoices_types_trans.sales_invoice'), disabled: false},
        // {key: 'general_sales_invoice', name: this.$t('invoices_types_trans.general_sales_invoice'), disabled: false},
        {key: 'pos', name: this.$t('invoices_types_trans.pos'), disabled: false},
        {key: 'sales_refund', name: this.$t('invoices_types_trans.sales_refund'), disabled: false},
        {key: 'sales_credit', name: this.$t('invoices_types_trans.sales_credit'), disabled: false},
        {key: 'sales_debit', name: this.$t('invoices_types_trans.sales_debit'), disabled: false},
        // {key: 'general_sales_refund', name: this.$t('invoices_types_trans.general_sales_refund'), disabled: false},
        // {key: 'general_sales_credit', name: this.$t('invoices_types_trans.general_sales_credit'), disabled: false},
        // {key: 'general_sales_debit', name: this.$t('invoices_types_trans.general_sales_debit'), disabled: false},
        // {key: 'sales_payment', name: this.$t('invoices_types_trans.sales_payment'), disabled: false},
        // {key: 'expenses_income_transaction', name: this.$t('invoices_types_trans.expenses_income_transaction'), disabled: false},
      ],
      period_date: ['day', 'week', 'month', 'year'],
      period_numbers: {
        day: 31,
        week: 4,
        month: 12,
        year: 10,
      },
      period_counter: [],
      inner_period: null,
      currency_default_id: null,
      branch_default: null,

      daily_sales: {data: {}},
      currency_name: null,
      json_meta: [
        [{
          key: "charset",
          value: "utf-8",
        },],
      ],
      page_summery: 1,
      page: 1,
      f_by: 'name',
    }
  },
  computed: {

    json_fields: function () {
      let fields = {};
      fields[this.$t('daily_product_sales.day')] = 'label';
      fields[this.$t('daily_product_sales.qty')] = 'qty';
      fields[this.$t('daily_product_sales.discount')] = 'discount_val';
      fields[this.$t('daily_product_sales.price')] = 'subtotal_price';

      return fields;
    },


  },

  methods: {
    ...cssStypeForPrintReport,
    getIds(related_with) {

      switch (related_with) {
        case 'items':
          this.filters.items_ids = this.items_ids.map((row) => row.id);
          this.filters.is_all_items = 0;
          break;
        case 'branches':
          this.filters.branches_ids = this.branches_ids.map((row) => row.id);
          this.filters.is_all_branches = 0;
          break;
        case 'status':
          this.filters.status_ids = this.status_ids.map((row) => row.id);
          break;
        case 'inventories':
          this.filters.inventories_ids = this.inventories_ids.map((row) => row.id);
          break;
      }
    },
    getAllIds(related_with) {
      switch (related_with) {
        case 'items':
          if (this.filters.is_all_items == 1) {
            this.filters.items_ids = [];
            this.items_ids = [];
          }
          break;
        case 'branches':
          if (this.filters.is_all_branches == 1) {
            this.filters.branches_ids = [];
            this.branches_ids = [];
          }
          break;

      }
    },

    changeFinancialYear() {
      if (this.filters.financial_year == 1)
        this.filters.financial_year = 0;
      else
        this.filters.financial_year = 1;
    },
    changeIntervalType(action_type, range = null) {
      if (this.filters.period == action_type) {
        this.filters.period = null;
        this.filters.f_time_period = 0;
      } else {
        this.filters.period = action_type;
        this.filters.f_time_period = 1;
      }

      // this.filters.range = range;
      // if (range == 1){
      //   this.inner_period = 'last_'+action_type;
      //   if (action_type == 'year')
      //     this.filters.range = this.$moment().subtract(1, 'year').year();
      // }else{
      //   this.inner_period = action_type;
      // }


      // this.period_counter = [];
      // if (this.filters.period){
      //   let _counter = this.period_numbers[this.filters.period];
      //   if (this.filters.period == 'year') {
      //     let _current_year = new Date().getFullYear();
      //     this.period_counter.push(parseInt(_current_year));
      //     for (let cc = 1; cc <= _counter; cc++){
      //       this.period_counter.push(parseInt(_current_year) - cc);
      //     }
      //   }else {
      //     for (let cc = 1; cc <= _counter; cc++){
      //       this.period_counter.push(cc);
      //     }
      //   }
      //
      // }else {
      //   this.period_counter = [];
      // }

    },

    convertTableToExcelReport(table_id = "summaryTableDetails") {
      this.setReportLog('excel', 'daily product sales');

      TableToExcel.convert(document.getElementById(table_id), {
        name: this.$t('daily_product_sales.daily_product_sales') + '.xlsx',
        sheet: {
          name: 'Sheet 1'
        }
      });
    },
    dataReportDetails() {
      this.setReportLog('excel', 'daily product sales');

      let _footer = {invoice_code: this.$t('total'), item_qty: this.dataTotal.qty, total_amount: this.dataTotal.amount};
      let _data = [];
      this.data_report_details.forEach((row) => {
        _data.push({
          invoice_code: row.sku_code,
          branch_name: row.item_name,
          inventory_name: '',
          item_qty: '',
          total_amount: '',
          subtotal_before_tax: '',
          total_tax: '',
        });
        row.details.forEach((sub_row) => {
          _data.push({
            invoice_code: sub_row.invoice_code,
            branch_name: sub_row.branch_name,
            inventory_name: sub_row.inventory_name,
            item_qty: sub_row.item_qty,
            total_amount: sub_row.total_amount,
            subtotal_before_tax: sub_row.subtotal_before_tax,
            total_tax: sub_row.total_tax,
          });
        });
        _data.push({
          invoice_code: this.$t('total'),
          branch_name: '',
          inventory_name: '',
          item_qty: row.total_qty,
          total_amount: row.total_amount,
          subtotal_before_tax: '',
          total_tax: '',
        });
      });
      _data.push(_footer);

      return _data;
    },

    getRandom() {
      return Math.floor(Math.random() * 10000);
    },

    updateValue(value) {
      this.filters.categories_ids = value
    },
    loadOptions() {
    },

    selectFilter() {
      this.page_summery = 1;
      this.page = 1;
      this.getDataSummery();
      this.getData();
      // this.getDailyProductSalesChart();
      // this.getMoreDetails();
    },

    loadMore() {
      this.page = this.page ? (this.page + 1) : 1;
      this.getData();
      // this.getDailyProductSalesChart();
      // this.getMoreDetails();
    },
    loadMoreSummery() {
      this.page_summery = this.page_summery ? (this.page_summery + 1) : 1;
      this.getDataSummery();
      // this.getDailyProductSalesChart();
      // this.getMoreDetails();
    },

    doFilter() {
      this.page_summery = 1;
      this.page = 1;
      this.getDataSummery();
      this.getData();
      // this.getDailyProductSalesChart();
      // this.getMoreDetails();
    },
    resetFilter() {
      this.filters.from_date = null;
      this.filters.to_date = null;
      this.filters.invoice_type = null;
      this.filters.is_all_items = 1;
      this.filters.is_all_branches = 1;

      this.filters.period = null;
      this.filters.range = null;
      this.filters.f_time_period = 0;
      this.filters.financial_year = 0;

      this.filters.items_ids = [];
      this.filters.branches_ids = [];
      this.filters.status_ids = [];
      this.filters.inventories_ids = [];
      this.filters.categories_ids = [];

      this.items_ids = []
      this.branches_ids = []
      this.status_ids = [];
      this.inventories_ids = [];
      this.categories_ids = [];

      this.status_ids = this.status_list.filter((row) => row.id == 2 || row.id == 5);
      this.filters.status_ids = [];
      this.getIds('status');

      this.inner_period = null;

      // if(this.branch_default){
      //   this.branches_ids.push(this.branch_default);
      //   this.getIds('branches');
      // }
      this.getDataSummery();
      this.getData();
    },
    dataReport() {
      this.setReportLog('excel', 'daily product sales');
      return this.data;
    },

    // getDailyProductSalesChart() {
    //   ApiService.get(this.mainRoute + '?data_type=for_chart', {
    //     params: {
    //       ...this.filters,
    //       page: (this.page ? this.page : 1)
    //     }
    //   }).then((response) => {
    //     this.daily_sales = response.data;
    //   });
    // },
    getData() {
      let _limit = (this.page ? this.page : 1) * 25;
      ApiService.get(this.mainRoute, {
        params: {
          ...this.filters,
          limit: _limit
        }
      }).then((response) => {
        this.data = response.data.data.items;
        this.dataTotal = response.data.data.total;
      }).catch((error) => {
        this.$errorAlert(error)
      });
    },
    getAllDataForExport(tableId, _export) {
      ApiService.get(this.mainRoute, {params: {...this.filters, limit: 999999999999999}}).then((response) => {
        this.dataList = response.data.data.items;
        this.dataTotalList = response.data.data.total;
        setTimeout(() => {
          // if (_export == 'pdf' || _export == 'print')
          //   this.printData(tableId, _export);
          if (_export == 'excel')
            this.convertTableToExcelReport(tableId);

        }, 100)
      });
    },

    getDataSummery() {
      let _limit = (this.page_summery ? this.page_summery : 1) * 25;
      ApiService.get(this.mainRouteSummery, {
        params: {
          ...this.filters,
          limit: _limit
        }
      }).then((response) => {
        this.dataSummery = response.data.data;
      }).catch((error) => {
        this.$errorAlert(error)
      });
    },
    getAllDataForExportSummery(tableId, _export) {
      ApiService.get(this.mainRouteSummery, {params: {...this.filters, limit: 999999999999999}}).then((response) => {
        this.dataListSummery = response.data.data;
        setTimeout(() => {
          // if (_export == 'pdf' || _export == 'print')
          //   this.printData(tableId, _export);
          if (_export == 'excel')
            this.convertTableToExcelReport(tableId);

        }, 100)
      });
    },
    // getMoreDetails() {
    //   ApiService.get(this.mainRoute, {
    //     params: {
    //       ...this.filters,
    //       page: (this.page ? this.page : 1),
    //     }
    //   }).then((response) => {
    //     this.data_more_details = response.data.data;
    //     let _total_sum = this.$_.sumBy(response.data.data, (row) => {
    //       return row.total.sum_total ? parseFloat(row.total.sum_total) : 0;
    //     });
    //     this.details_total_sum = _total_sum.toFixed(2);
    //   });
    // },

    getItems(f_by = null, filter = null) {
      if (filter && filter.length >= 3 && f_by)
        ApiService.get(this.mainRouteDependency + "/items", {params: {[f_by]: filter}}).then((response) => {
          this.items = response.data.data;
        });
    },


    getCategories() {
      ApiService.get(this.mainRouteDependency + "/categories").then((response) => {
        this.categories = response.data.data;
      });
    },
    getInventories() {
      ApiService.get(this.mainRouteDependency + "/inventories").then((response) => {
        this.inventories = response.data.data;
      });
    },
    // defaultDataForUser() {
    //   ApiService.get(this.mainRouteDependency + "/default_data_for_user").then((response) => {
    //     this.filters.currency_id = response.data.data.currency_id;
    //     this.currency_default_id = response.data.data.currency_id;
    //
    //     // if (response.data.data.branch_id){
    //     //   this.branch_default = response.data.data.branch;
    //     //   this.branches_ids.push(response.data.data.branch);
    //     //   this.getIds('branches');
    //     // }
    //
    //   });
    // },
    getStatus() {
      ApiService.get(this.mainRouteDependency + "/sales_status").then((response) => {
        this.status_list = response.data.data;
        //     let _allowed = [1,2,5];
        // this.status_list = response.data.data.filter((row)=>_allowed.includes(row.id));
        let _status = response.data.data.filter((row) => row.id == 2 || row.id == 5);
        if (_status) {
          this.status_ids.push(..._status);
          this.getIds('status');
        }
      });
    },
    getBranches() {
      ApiService.get(this.mainRouteDependency + "/branches").then((response) => {
        this.branches = response.data.data;
      });
    },
    printData(tableId) {
      this.setReportLog('pdf', 'daily product sales');
      let divToPrint = document.getElementById(tableId);
      let divTitleToPrint = document.getElementById('sectionForExportHeader');
      let newWin = window.open("");
      newWin.document.write('<meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">');
      newWin.document.write("<link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css'>");
      newWin.document.write('<style>');
      newWin.document.write(this.getCssStyleForPrintReport());
      newWin.document.write('</style>');
      newWin.document.write(divTitleToPrint.outerHTML + divToPrint.outerHTML);
      setTimeout(() => {
        newWin.print();
        // newWin.close();
      }, 100)
    },

    printPDFData(tableId, type) {
      this.setReportLog('pdf', 'daily product sales');
      exportPDFInnerAction(tableId, type, this.$t('daily_product_sales.daily_product_sales'), this.$t('daily_product_sales.daily_product_sales'));
    },
    setReportLog(key, description) {
      ApiService.post("reports/system_activity_log/log_type", {
        key: key,
        description: description,
      });
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{
      title: this.$t("MENU.sales_reports"),
      route: '/reports/sales-reports-links'
    }, {title: this.$t("MENU.daily_product_sales")}]);

    this.getCategories();
    this.getInventories();
    // this.defaultDataForUser();
    this.getBranches();
    this.getStatus();
  },
};
</script>
<style>
.html2pdf__overlay {
  opacity: 0 !important;
  z-index: -1000;

}

.selector-export {
  opacity: 0 !important;
  z-index: -1000;
  width: 0.0001px;
  height: 0.0001px;
  overflow: auto;
}
</style>
